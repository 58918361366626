import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { format } from 'date-fns';

// Material components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import useEventController from 'store/event/controller';

// Icons, Assets and Styles
import { Edit3, Trash2 } from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

function CardEvent({ event }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  // Controller
  const Event = useEventController(dispatch);

  const { current: { permissions } } = useSelector((store) => store.user);
  const permissionEdit = !permissions || !permissions.events || permissions.events.edit;

  const [deleted, setDeleted] = useState(false);
  
  const { start } = event.creating.idealization.dates;
  const date = start && start.toDate ? start.toDate() : start;

  return (
    <Card className={classes.card} style={{ border: '1px dashed #586eba' }}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Grid container wrap="nowrap" justify="space-between" alignItems="flex-start">
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            onClick={() => history.push(`/create/${event.id}`)}
            className={classes.title}
          >
            {date && (
              <div className={classes.date}>
                <div className={classes.dateDay}>{date.getDate()}</div>
                <div className={classes.dateMonth}>
                  {`${MONTHS[date.getMonth()]} OF ${date.getFullYear()}`}
                </div>
              </div>
            )}
            <Typography variant="h5" style={{ fontWeight: 800, wordBreak: 'break-word' }}>
              {event.creating.idealization.name || 'No name event'}
            </Typography>
          </Grid>
          {permissionEdit && (
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setDeleted(true)}
                style={{ height: 'fit-content' }}
              >
                <Trash2 size={16} className={classes.editIcon} />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" style={{ marginTop: 16 }}>
          <Edit3 size={20} className={classes.locationIcon} />
          <Typography className={classes.location}>
            {`Last update ${format(event.updatedAt.toDate(), "dd/MM/yyyy' at 'HH:mm")}`}
          </Typography>
        </Grid>
      </CardContent>
      
      {deleted && (
        <ConfirmDialog
          title="Do you really want to delete this draft event?"
          onConfirm={() => Event.forceDestroy(event.id)}
          onClose={() => setDeleted(false)}
        />
      )}
    </Card>
  );
}

CardEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dates: PropTypes.shape({
      start: PropTypes.object,
    }),
    address: PropTypes.shape({
      localName: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
    test: PropTypes.bool,
  }).isRequired,
};

export default CardEvent;
