import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 130px)',
    margin: '0 0 0 130px',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  row: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    margin: 0,
  },
  noData: {
    fontSize: 20,
    color: theme.palette.common.grey,
    marginBottom: 32,
    maxWidth: 450,
    textAlign: 'center',
  },
}));
