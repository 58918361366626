import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { HelpCircle } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

// Components
import RegisterAttachments from './attachments';
import RegisterData from './supplier-data';
import RegisterEmail from './email';

function ExpansionRegister({ supplier }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();

  const { current: { permissions } } = useSelector((store) => store.user);

  const [openEmail, setOpenEmail] = useState(false);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  const sendMail = () => {
    dispatch(Log.store(`Sent a register e-mail to the supplier ${supplier.name}`));
    dispatch(Supplier.sendRegisterMail(supplier));
    dispatch(Supplier.update(supplier.id, { register: { answered: false, sending: true } }));
  };

  if (!permissionEdit && !supplier.interrupted && (!supplier.register || !supplier.register.cnpj)) {
    return (
      <Grid container justify="center" className={classes.container}>
        <Typography style={{ marginBottom: 16, textAlign: 'center' }}>
          WAITING FOR THE SUPPLIER'S REGISTRATION
        </Typography>
      </Grid>
    );
  }

  if (supplier.status > 2 && (!supplier.register || !supplier.register.cnpj)) {
    return (
      <Grid container justify="center" className={classes.container}>
        <Typography style={{ marginBottom: 16, textAlign: 'center' }}>
          SUPPLIER REGISTRATION WAS NOT REQUESTED
        </Typography>
      </Grid>
    );
  }

  if (!supplier.interrupted && (!supplier.register || !supplier.register.cnpj)) {
    return (
      <Grid container spacing={1}>
        <Grid container justify="center" style={{ marginBottom: 16 }}>
          <Grid item xs={12}>
            <RegisterAttachments supplier={supplier} />
          </Grid>
        </Grid>

        {supplier.register && (
          <Typography style={{ marginBottom: 16, textAlign: 'center' }}>
            Waiting for the supplier to fill the registration data to proceed with the contracting.
          </Typography>
        )}

        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => history.push(`/manager/${eventId}/procurement/negotiations/register/${supplier.id}`)}
          >
            I already have the data
          </Button>
        </Grid>

        {!supplier.register ? (
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={sendMail}
              disabled={(supplier.register && !supplier.register.sendAt) || !supplier.contact.email}
            >
              {supplier.register && !supplier.register.sendAt ? 'Requesting ' : 'Request '}
              registered
              <Tooltip title="An email will be sent to the supplier requesting some information to proceed with the contracting">
                <div style={{ display: 'flex' }}>
                  <HelpCircle size={16} style={{ marginLeft: 8 }} />
                </div>
              </Tooltip>
            </Button>
            {!supplier.contact.email && (
              <Typography onClick={() => setOpenEmail(true)} className={classes.link}>
                A contact email is required to request registration.
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={sendMail}
              disabled={(supplier.register && !supplier.register.sendAt) || !supplier.contact.email}
            >
              {supplier.register && !supplier.register.sendAt ? 'Requesting ' : 'Request '}
              again
            </Button>
          </Grid>
        )}

        {openEmail && <RegisterEmail supplier={supplier.id} onClose={() => setOpenEmail(false)} />}
      </Grid>
    );
  }

  return <RegisterData supplier={supplier} />;
}

ExpansionRegister.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contact: PropTypes.object,
    register: PropTypes.object,
    settings: PropTypes.object,
    interrupted: PropTypes.bool,
    status: PropTypes.number,
  }).isRequired,
};

export default ExpansionRegister;
