import React, { useState } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

function OnBoarding({ id, title, image, button, children }) {
  const initialOpen = window.localStorage.getItem(id);
  
  const [hide, setHide] = useState(initialOpen === 'hide');

  const onClose = () => {
    window.localStorage.setItem(id, 'hide');
    setHide(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={!hide}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      {image && <img alt="imagem inicial" src={image} style={{ width: '100%' }} />}
      <DialogTitle id="confirm-dialog-title">
        <Grid container justify="center" alignItems="center" wrap="nowrap">
          <span style={{ fontWeight: 600 }}>{title}</span>
        </Grid>  
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary">
          {button || 'LET\'S GO!'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OnBoarding;
