import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

const Label = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('tasks-label')
      .onSnapshot((ref) => {
        const labels = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(Company.loadLabels(labels));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('tasks-label').add({ ...data, updatedAt: now, createdAt: now })
      .then(() => {
        Mixpanel.track('Create a label');
        dispatch(Alert.show({ message: 'Label created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('tasks-label').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a label');
        dispatch(Alert.show({
          message: 'Label updated successfully.', type: 'success',
        }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => async (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('tasks-label').doc(id)
      .delete()
      .then(async () => {
        Mixpanel.track('Delete a label');
        dispatch(Alert.show({
          message: 'Label successfully deleted.', type: 'success',
        }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Label;
