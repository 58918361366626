import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Log from 'store/log/controller';
import useEventController from 'store/event/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function EventForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();
  const params = new URLSearchParams(history.location.search);
  const next = params.get('next');
  
  // Controllers
  const Event = useEventController(dispatch);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ticket, setTicket] = useState('');
  const [error, setError] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '',
    dates: { start: null, end: null },
    creating: {
      idealization: {
        description: '',
        objective: '',
        benchmarking: [],
        dates: { start: null, end: null },
        name: '',
        site: '',
        learnings: '',
      },
      persona: [{ description: '', interests: [], expected: '', engagement: '', data: '' }],
      contents: { themes: [], approach: '', approachComplement: '', engagement: [] },
      format: { streaming: '', subscribers: '', ticket: '', type: '', future: '' },
      goals: { successList: [''], success: '' },
    },
  });

  const { current: company, events } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);
  const permissionEdit = !permissions || !permissions.events || permissions.events.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (eventId && events) {
      const ev = events.find((e) => e.id === eventId);
      if (ev) {
        setInitialForm({
          ...ev, dates: { start: ev.dates.start.toDate(), end: ev.dates.end.toDate() },
        });
        setTicket(ev.creating.format.ticket);
      } else {
        history.goBack();
      }
    }
  }, [eventId, events, history]);

  const submit = async (data) => {
    if (ticket === '') setError(true);
    else {
      const evData = {
        ...data,
        company: company.id,
        createStep: 4,
        creating: {
          ...data.creating,
          idealization: { ...data.creating.idealization, name: data.name, dates: data.dates },
          format: { ...data.creating.format, ticket },
        },
      }

      if (eventId) {
        dispatch(Log.store(`Edited the event ${data.name}`));
        await Event.update(eventId, evData);
        history.goBack();
      } else {
        dispatch(Log.store(`Created a new event called ${data.name}`));
        const id = await Event.store(evData);
        history.push(`/${next || 'planner'}/${id}${next === 'manager' ? '/financial' : '/action-plan'}`);
      }
    }
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      onClose={history.goBack}
      aria-labelledby="view-event-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>
            {eventId
              ? 'Editing basic event information'
              : `Some information is needed to get start ${
                  next === 'maker' ? 'execution' : 'planning'
                } of the event`}
          </span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form id="form-event" schema={formSchema} initialState={initialForm} onSubmit={submit}>
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>{inputs.name}</Grid>
              <Grid item xs={12} sm={6}>{inputs.dates.start}</Grid>
              <Grid item xs={12} sm={6}>{inputs.dates.end}</Grid>
              <Grid item xs={12}>{inputs.creating.idealization.site}</Grid>

              <Grid item xs={12}>
                <Typography gutterBottom variant="overline" className={classes.dividerText}>
                  FORMAT
                </Typography>
                <Divider variant="middle" className={classes.divider} />
              </Grid>

              <Grid
                container
                item
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Typography className={classes.label} style={{ fontSize: 16 }}>
                  The tickets will be
                </Typography>
                <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                  <Button
                    variant={ticket === 'payed' ? 'contained' : 'outlined'}
                    onClick={() => setTicket('payed')}
                  >
                    Paid
                  </Button>
                  <Button
                    variant={ticket === 'free' ? 'contained' : 'outlined'}
                    onClick={() => setTicket('free')}
                  >
                    Free
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid container item style={{ marginBottom: 16 }}>
                {error && (
                  <FormHelperText error>Define the ticket format</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                {inputs.creating.format.type}
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-event"
          color="primary"
          variant="outlined"
          className={classes.button}
        >
          {eventId ? 'SAVE' : `START ${next === 'manager' ? 'EXECUTION' : 'PLANNING'}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EventForm;
