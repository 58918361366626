import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';
import Task from 'store/productivity/task/controller';
import Step from 'store/productivity/step/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import { transferFiles } from 'common/form/upload/utils';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import Checklists from './checklist';
import styles from './style';

function TaskForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, checkId, taskId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [checklistMenu, setChecklistMenu] = useState(null);
  const [checklists, setChecklists] = useState([]);
  const [parent, setParent] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '',
    description: '',
    startAt: new Date(),
    endAt: null,
    priority: 0,
    attachments: [],
    responsibles: [],
  });

  const { labels, users, tasks, steps } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (!steps) dispatch(Step.index());
  }, [dispatch, steps, tasks]);

  useEffect(() => {
    if (checklist && checkId) {
      const check = checklist.find((c) => c.id === checkId);
      if (check) setParent(check);
    }
  }, [checklist, checkId]);

  useEffect(() => {
    if (tasks && taskId) {
      const task = tasks.find((t) => t.id === taskId);
      if (task) {
        setInitialForm({
          ...task,
          startAt: task.startAt ? task.startAt.toDate() : null,
          endAt: task.endAt ? task.endAt.toDate() : null,
        });
        setChecklists(task.checklists);
      }
    }
  }, [tasks, taskId]);

  const submit = async (data) => {
    setSubmitted(true);
    const attachments = await transferFiles(
      data.attachments,
      `/productivity-temp/${eventId}/tasks`,
      `/productivity/${eventId}/tasks`,
    );

    if (taskId) {
      const step = steps.find((s) => s.type === 'backlog');
      dispatch(Log.store(`Edited task "${data.name}"`));
      dispatch(Task.update(taskId, {
        ...data,
        step: step.id,
        labels: [parent.tag],
        checklists,
        parent: parent ? parent.id : null,
        attachments,
      }));
    } else {
      const step = steps.find((s) => s.type === 'backlog');
      dispatch(Log.store(`Create a new task "${data.name}"`));
      dispatch(Task.store({
        ...data,
        step: step.id,
        labels: [parent.tag],
        event: eventId,
        checklists,
        parent: parent ? parent.id : null,
        workingAt: null,
        completedAt: null,
        attachments,
      }));
    }

    setSubmitted(false);
    history.goBack();
  };

  const closeForm = () => {
    dispatch(FormSave.remove('form-task'));
    history.goBack();
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={closeForm}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>{taskId ? 'Editing a task' : 'Create a new task'}</span>
          <IconButton onClick={closeForm} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {labels && users && checklist && steps ? (
          <Form
            id="form-task"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Checklist task</Typography>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Typography
                        className={classes.value}
                        onClick={(e) => setChecklistMenu(e.currentTarget)}
                      >
                        {parent ? parent.label : 'Select a checklist'}
                      </Typography>
                    </Grid>
                    <FormHelperText>
                      This task must be linked to an action plan checklist 
                    </FormHelperText>

                    <Menu
                      anchorEl={checklistMenu}
                      open={!!checklistMenu}
                      onClose={() => setChecklistMenu(null)}
                    >
                      {checklist.map((item) => (
                        <MenuItem
                          key={item.id}
                          onClick={() => {
                            setParent(item);
                            setChecklistMenu(null);
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.name}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.description}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} sm={6}>
                    {inputs.startAt}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {inputs.endAt}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.priority}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.labels}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.responsibles}
                  </Grid>
                </Grid>

                <Checklists value={checklists} onChange={setChecklists} />

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.attachments}
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        ) : (
          <Loading ready={!!labels && !!users && !!checklist && !!steps} content={400} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="form-task"
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={submitted}
        >
          {submitted && taskId ? 'Saving' : taskId && 'Save'}
          {submitted && !taskId ? 'Creating' : !taskId && 'Create'}
          {submitted && (
            <CircularProgress size={16} color="primary" style={{ marginLeft: 8 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskForm;
