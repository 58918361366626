import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Commons, Components and styles
import PieChart from 'common/charts/pie-chart';
import { getProductValue } from 'common/utils/suppliers';
import { productWithSuppliers, categoryWithProducts } from 'common/utils/relationships';

function generateData(categories) {
  const categoriesChart = categories
    .filter((c) => c.products.length > 0)
    .map((category) => {
      const { id, name, color } = category;
      let value = 0;

      category.products.forEach((product) => {
        let valueProduct = 0;
        const suppliers2Step = product.suppliers.filter((s) => s.status === 1 && !s.interrupted);
        const suppliers4Step = product.suppliers.filter((s) => s.status >= 2 && !s.interrupted);

        if (suppliers4Step.length === 0) {
          valueProduct = suppliers2Step.reduce(
            (count, s) => count + getProductValue(s, product.id),
            0,
          );
          if (valueProduct > 0) valueProduct /= suppliers2Step.length;
        } else {
          valueProduct = suppliers4Step.reduce(
            (count, s) => count + getProductValue(s, product.id),
            0,
          );
        }

        value += valueProduct;
      });

      return {
        id,
        name,
        value,
        color,
      };
    });

  categoriesChart.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return -1;
  });

  const categoriesChartWithNegotiation = categoriesChart.filter((c) => c.value > 0);

  return categoriesChartWithNegotiation.length > 0
    ? categoriesChartWithNegotiation
    : categoriesChart;
}

function CategoriesChart() {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { products, suppliers } = useSelector((state) => state.event);
  const { categories } = useSelector((state) => state.company);
  const isLoaded = !!suppliers && !!products && !!categories;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isLoaded) {
      const productsWithSuppliers = products.map((p) => productWithSuppliers(p, suppliers));
      setData(
        categories
          .filter((c) => c.parent === 'suppliers')
          .map((c) => categoryWithProducts(c, productsWithSuppliers)),
      );
    }
  }, [isLoaded, categories, products, suppliers]);

  if (data.length === 0) return false;

  return (
    <PieChart
      data={generateData(data)}
      direction={!upSm ? 'center' : null}
      title="By category"
      loaded={isLoaded}
    />
  );
}

export default CategoriesChart;
