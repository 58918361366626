import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useEventController from 'store/event/controller';
import Task from 'store/productivity/task/controller';
import Label from 'store/productivity/label/controller';
import Step from 'store/productivity/step/controller';
import Checklist from 'store/productivity/checklist/controller';

// Icons, Commons, Components and Styles
import { Settings } from 'react-feather';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import CompletedTasksMe from './components/completed-tasks/me';
import CompletedTasksTeam from './components/completed-tasks/team';
import LogActivities from './components/log-activities';
import ChecklistEvent from './components/checklist';
import ChartTasks from './components/chart-tasks';
import ChartProductivity from './components/chart-productivity';
import styles from './style';

function Productivity() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams();

  // Controller
  const Event = useEventController(dispatch);

  const { current: company, tasks, labels, steps } = useSelector((store) => store.company);
  const { current: event, checklist } = useSelector((store) => store.event);

  const permissionView = company.plan !== 'free';

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
    }
  }, [event, eventId, Event]);

  useEffect(() => {
    if (!tasks) dispatch(Task.index());
    if (!labels) dispatch(Label.index());
    if (!steps) dispatch(Step.index());
  }, [tasks, labels, steps, dispatch]);

  useEffect(() => {
    if (!checklist) dispatch(Checklist.index(eventId));
  }, [checklist, eventId, dispatch]);

  const [time, setTime] = useState('today');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabIcons = [{
    id: 'settings',
    label: 'Settings',
    icon: <Settings />,
    click: () => history.push(`/planner/${eventId}/productivity/settings`),
  }];

  const tabsToolbar = [
    { title: 'Dashboard', selected: true },
    { title: 'Tasks', path: `/planner/${eventId}/productivity/tasks` },
  ];

  return (
    <div className={classes.container}>
      <Toolbar
        avatar
        defaultIcons
        position="fixed"
        sidebar="/planner/productivity"
        title="PRODUCTIVITY"
        icons={tabIcons}
        tabs={tabsToolbar}
      />

      <Grid
        container
        className={classes.row}
        style={{
          paddingLeft: mobile ? 32 : 48,
          paddingRight: mobile ? 32 : 48,
          height: mobile ? 'auto' : 330,
          paddingTop: mobile ? 64 : 80,
        }}
      >
        <Grid item xs={12} sm={9} style={{ paddingTop: mobile ? 64 : 32 }}>
          <Grid container spacing={4} style={{ paddingRight: mobile ? 0 : 64 }}>
            <Grid item xs={12} sm={6}>
              <CompletedTasksMe time={time} setTime={setTime} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompletedTasksTeam time={time} setTime={setTime} />
            </Grid>
          </Grid>
        </Grid>
        {!mobile && (
          <Grid item xs={12} sm={3} style={{ position: 'relative', paddingTop: 32 }}>
            <LogActivities />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        className={classes.row}
        style={{
          height: mobile ? 'fit-content' : 'calc(100% - 330px)',
          padding: mobile ? '0 16px' : '0 48px 0 48px',
        }}
      >
        <Grid item xs={12} sm={9} spacing={4} style={{ position: 'relative', height: '100%' }}>
          <Grid
            container
            spacing={4}
            alignItems="stretch"
            style={{ paddingRight: mobile ? 0 : 64, height: 'calc(100% + 8px)', margin: '-8px -32px 0' }}
          >
            <Grid item xs={12} sm={6} style={{ height: '100%' }}>
              <ChartTasks />
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: '100%' }}>
              <ChartProductivity />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} style={{ height: '100%' }}>
          <ChecklistEvent />
        </Grid>
        {mobile && (
          <Grid item xs={12} style={{ position: 'relative', paddingTop: 32 }}>
            <LogActivities />
          </Grid>
        )}
      </Grid>

      <Onboarding id="plan" title="Plan your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="plan"
          frameborder="0"
          src="https://www.youtube.com/embed/lGYodgSyAL8"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      <Tools />
    </div>
  );
}

export default Productivity;
