import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(0.5)}px`,
    },
  },
  logo: {
    height: 40,
    margin: theme.spacing(),
    marginLeft: 0,
  },
  backButton: {
    margin: `0 ${theme.spacing()}px 0 0`,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  iconButton: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  tabsContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 112px)',
      justifyContent: 'center',
      marginLeft: 48,
    },
  },
  subtabsContainer: {
    position: 'absolute',
    width: 'calc(100% - 20px)',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    top: 56,
    background: 'white',
  },
  snippetInput: {
    padding: '8px 24px',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  avatar: {
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
    margin: `0 0 0 ${theme.spacing(0.5)}px`,
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing(),
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    fontSize: 16,
    color: `${theme.palette.common.grey} !important`,
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      margin: `0 ${theme.spacing(0.5)}px`,
      padding: 4,
      fontSize: 14,
    },
  },
  buttonDisabled: {
    color: `${theme.palette.common.black} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    fontWeight: 600,
  },
  subtabs: {
    marginLeft: 48,
    marginTop: -8,
    width: 'fit-content',
  },
  divider: {
    display: 'inline',
    width: 'fit-content',
    fontSize: 32,
    lineHeight: 1,
    marginTop: -6,
    color: theme.palette.secondary.main,
    padding: `0 ${theme.spacing()}px`
  },
  popover: {
    marginTop: 52,
  },
  logout: {
    background: lighten(theme.palette.error.main, 0.85),
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.75),
    },
  },

  // Event
  btnEvent: {
    padding: '6px 24px 6px 16px',
    borderRadius: 30,
    maxWidth: 200,
    height: 40,
    border: `1px solid ${theme.palette.primary.main}`,
    '& svg': {
      marginTop: -3,
    },
  },
  eventLabel: {
    textAlign: 'left',
    fontWeight: 600,
    marginRight: 8,
    lineHeight: 1,
    fontSize: 15,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& > p': {
      fontSize: 10,
      textTransform: 'none',
      fontWeight: 300,
      margin: '4px 0 0',
      color: theme.palette.common.grey,
    },
  },
  eventInfo: {
    marginRight: -16,
    minWidth: 24,
    color: theme.palette.secondary.main,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    }
  },
}));
