import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileUploader from 'react-firebase-file-uploader';
import { storage } from 'services/firebase';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';
import User from 'store/user/controller';

// Icons and styles
import {
  Plus, MoreVertical, RefreshCcw, Trash2,
} from 'react-feather';
import styles from './style';

function UploadButton({
  // eslint-disable-next-line react/prop-types
  children, onStart, onSuccess, className,
}) {
  const dispatch = useDispatch();

  const { current: user } = useSelector((store) => store.user);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={className}>
      {children}
      <FileUploader
        hidden
        filename={new Date().getTime()}
        accept="images/*"
        storageRef={storage.ref(`/profile/${user.id}/`)}
        onUploadStart={onStart}
        onUploadSuccess={onSuccess}
        onUploadError={() => {
          dispatch(Alert.show({ message: 'The image could not be changed.' }));
        }}
      />
    </label>
  );
}

function UploadAvatar() {
  const classes = styles();
  const dispatch = useDispatch();

  const { current: user } = useSelector((store) => store.user);

  const [avatarMenu, setAvatarMenu] = useState(null);
  const [uploading, setUploading] = useState(false);

  const changeAvatar = async (fileName) => {
    const url = await storage.ref(`/profile/${user.id}/`).child(fileName).getDownloadURL();
    dispatch(User.update({ avatar: url }));
    setUploading(false);
    setAvatarMenu(null);
  };

  const removeAvatar = async () => {
    dispatch(User.update({ avatar: null }));
    setAvatarMenu(null);
  };

  if (user.avatar) {
    return (
      <>
        <Fab
          color="secondary"
          className={classes.avatarAction}
          onClick={(e) => setAvatarMenu(e.currentTarget)}
        >
          <MoreVertical />
        </Fab>

        <Menu
          open={!!avatarMenu}
          anchorEl={avatarMenu}
          onClose={() => setAvatarMenu(null)}
        >
          <ListItem button disabled={uploading}>
            <UploadButton
              onStart={() => setUploading(true)}
              onSuccess={changeAvatar}
              className={classes.labelListUpload}
            >
              {uploading ? (
                <>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <CircularProgress size={18} style={{ marginRight: 16 }} />
                  </ListItemIcon>
                  <ListItemText primary="Sending..." />
                </>
              ) : (
                <>
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <RefreshCcw size={18} style={{ marginRight: 16 }} />
                  </ListItemIcon>
                  <ListItemText primary="Change image" />
                </>
              )}
            </UploadButton>
          </ListItem>
          <ListItem button onClick={removeAvatar}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <Trash2 size={18} style={{ marginRight: 16 }} />
            </ListItemIcon>
            <ListItemText primary="Remove image" />
          </ListItem>
        </Menu>
      </>
    );
  }

  return (
    <Fab color="secondary" className={classes.avatarAction} disabled={uploading}>
      <UploadButton
        onStart={() => setUploading(true)}
        onSuccess={changeAvatar}
        className={classes.labelFabUpload}
      >
        {uploading ? <CircularProgress size={24} /> : <Plus />}
      </UploadButton>
    </Fab>
  );
}

export default UploadAvatar;
