import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  containerPage: {
    width: 'calc(100% - 130px)',
    height: '100%',
    marginLeft: 130,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  container: {
    height: '100%',
    marginBottom: 0,
  },
  root: {
    margin: 0,
    padding: '0 16px',
    marginTop: 100,
    height: 'calc(100vh - 100px)',
    overflowX: 'visible',
    overflowY: 'hidden',
    minWidth: '100%',
    width: 'inherit',
    flexWrap: 'nowrap',
  },
  steps: {
    position: 'relative',
    minWidth: 250,
    width: '20%',
    height: '100%',
    margin: 0,
    padding: '0 !important',
  },
  addSupplier: {
    position: 'absolute',
    right: 12,
    top: -2,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    zIndex: 5,
  },
  textStatus: {
    color: theme.palette.tertiary.main,
    fontWeight: 'bold',
    position: 'absolute',
    top: 0,
    left: 12,
    right: 12,
    zIndex: 5,
    background: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 80%,rgba(255,255,255,0) 100%)',
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: 40,
      height: 3,
      borderRadius: 2,
      left: 'calc(50% - 20px)',
      top: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      marginBottom: 4,
    },
  },
  stepDescription: {
    fontSize: 14,
    color: theme.palette.common.grey,
    textAlign: 'center',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing()}px`,
  },
  cardGrid: {
    top: 0,
    width: '100%',
    overflow: 'overlay',
    height: '100%',
    padding: `${theme.spacing(4)}px ${theme.spacing()}px 0`,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      width: '100%',
      height: 'calc(100vh - 138px)',
      padding: `${theme.spacing()}px`,
    },
  },
  cardStyle: {
    marginBottom: 20,
    display: 'inline-block',
    width: '100%',
  },
  tab: {
    fontSize: 10,
    alignItems: 'center',
  },
  tabs: {
    justifyContent: 'space-between',
    marginTop: 90,
  },
  // menuArchive: {
  //   marginTop: 52,
  //   marginLeft: theme.spacing(),
  // },
  // menuArchiveList: {
  //   width: 350,
  //   maxHeight: 300,
  // },
  // archived: {
  //   height: 'auto',
  // },
  // archivedProduct: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginRight: 16,
  // },
  // archivedCategory: {
  //   width: theme.spacing(0.5),
  //   height: theme.spacing(0.5),
  //   marginRight: theme.spacing(0.25),
  //   borderRadius: theme.spacing(0.25),
  // },
  // archivedProductName: {
  //   fontSize: 12,
  //   fontWeight: 200,
  // },
  // archivedSupplier: {
  //   fontSize: 18,
  //   fontWeight: 600,
  // },
  // archivedInterrupted: {
  //   color: theme.palette.error.main,
  //   borderColor: theme.palette.error.main,
  //   fontSize: 10,
  //   marginTop: theme.spacing(0.5),
  //   height: 24,
  // },
}));
