import { addMonths } from 'date-fns';

const now = new Date();
const month = addMonths(now, 6).getMonth() + 1;

export default function (companyId) {
  return {
    deletedAt: true,
    createdAt: new Date(`2020-${(`0${now.getMonth() + 1}`).slice(-2)}-13 18:52:29`),
    costCenter: [

    ],
    dates: {
      start: new Date(`2020-${month}-17 18:00:00`),
      end: new Date(`2020-${month}-19 20:00:00`),
    },
    test: true,
    address: {
      number: '200',
      localName: 'Innovation Center Novale Hub',
      postalCode: '89254-193',
      complement: '',
      // neighborhood: 'Três Rios do Sul',
      city: 'Jaraguá do Sul',
      state: 'SC',
      country: 'Brasil',
      street: 'Rua Cesare Valentini',
    },
    updatedAt: new Date(`2020-${(`0${now.getMonth() + 1}`).slice(-2)}-13 18:52:29`),
    company: companyId,
    name: 'Test event',
  };
}
