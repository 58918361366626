import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import createImporter from 'services/importer';
import BrazilianBanks from 'services/brazilian-banks.json';

// Material UI Components
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Trash2, Edit2, Plus, LogIn,
} from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function AccountList() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const { accounts, cashFlow } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const [importer, setImporter] = useState(null);
  const [deleteAccount, setDeleteAccount] = useState(false);

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Bank number',
          key: 'bank',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]{3}$/i,
            error: 'Enter the 3-digit bank code',
          }],
        },
        {
          label: 'Agency',
          key: 'agency',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]*(-[0-9]{1}|)$/i,
            error: 'Enter the agency digit',
          }],
        },
        {
          label: 'Account',
          key: 'code',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]*(-[0-9]{1}|)$/i,
            error: 'Enter the account number and digit',
          }],
        },
        {
          label: 'Initial value',
          key: 'initialValue',
          validators: [{
            validate: 'regex_matches',
            regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
            error: 'It is not a valid number (remove the coin \'$\', if any)',
          }],
        },
      ],
    }));
  }, []);

  const importAccounts = () => {
    const convertNumber = (number) => parseFloat(
      number.trim().split('.')
        .join('')
        .split(',')
        .join('.'),
    );

    importer.requestDataFromUser().then(async ({ data }) => {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];
        const initialValue = convertNumber(item.initialValue);
        dispatch(Account.store({ ...item, initialValue }));
      }

      importer.displaySuccess('All imported data!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
  };

  return (
    <Grid container direction="column">
      {!!accounts && !!cashFlow ? (
        <>
          <List style={{ paddingBottom: 24 }}>
            {accounts.map((account) => (
              <ListItem key={account.id} style={!mobile ? {} : { padding: '8px 0' }}>
                <ListItemText
                  primary={BrazilianBanks.find((b) => b.code === account.bank).name}
                  secondary={`${account.agency} ${account.code ? `/ ${account.code}` : ''}`}
                  primaryTypographyProps={{ style: { fontWeight: 900 } }}
                  secondaryTypographyProps={{ style: { fontSize: 13 } }}
                />
                {account.autoPayment && (
                  <Chip
                    label="Pagamentos via plataforma"
                    color="primary"
                    style={{ marginRight: 32 }}
                  />
                )}
                {permissionEdit && (
                  <ListItemSecondaryAction style={!mobile ? {} : { right: 0 }}>
                    <Grid container alignItems="center">
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        style={{ marginLeft: 16 }}
                        onClick={() => history.push(`/register/accounts/${account.id}`)}
                      >
                        <Edit2 size={16} />
                      </IconButton>
                      {!cashFlow.find((c) => c.account === account.id) && (
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          style={{ marginLeft: 8 }}
                          onClick={() => setDeleteAccount(account)}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                      )}
                    </Grid>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          {accounts.length === 0 && (
            <Grid className={classes.noData}>
              <Typography>
                No account registered
              </Typography>
            </Grid>
          )}
          {permissionEdit && (
            <Grid container justify="center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => history.push('/register/accounts/new')}
                className={classes.button}
              >
                <Plus size={20} style={{ marginRight: 8 }} />
                Add account
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={importAccounts}
                disabled={!accounts || !cashFlow}
                className={classes.button}
              >
                <LogIn style={{ transform: 'rotate(180deg)', marginRight: 8 }} size={20} />
                Import accounts
              </Button>
            </Grid>
          )}

          {deleteAccount && (
            <ConfirmDialog
              title={`Do you really want to delete the account ${
                BrazilianBanks.find((b) => b.code === deleteAccount.bank).name
              }?`}
              onConfirm={() => {
                dispatch(Log.store(`Deleted a bank account ${
                  BrazilianBanks.find((a) => a.code === deleteAccount.bank).name
                }`));
                dispatch(Account.destroy(deleteAccount.id));
              }}
              onClose={() => setDeleteAccount(false)}
            />
          )}
        </>
      ) : (
        <Loading ready={!!accounts && !!cashFlow} content={150} />
      )}
    </Grid>
  );
}

export default AccountList;
