import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ptBR } from 'date-fns/locale';
import {
  startOfMonth,
  lastDayOfMonth,
  subMonths,
  addMonths,
  subDays,
  addDays,
  format,
  isSameDay,
} from 'date-fns';

// Material UI Components
import Badge from '@material-ui/core/Badge';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { ChevronLeft, ChevronRight } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import { filter } from '../../utils';
import styles from './style';

function CashFlowCalendar() {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const { cashFlow, contacts, categories } = useSelector((store) => store.company);

  const [baseDay, setBaseDay] = useState(new Date());

  const getCash = (date) => {
    let flow = cashFlow.filter((c) => c.event === eventId);
    if (history.location.search !== '') flow = filter(flow, history.location.search, categories);

    return flow.filter((cash) => {
      let cashDate = cash.dueAt.toDate();
      if (cash.completed.on) {
        cashDate = cash.completed.date.toDate();
      }

      return isSameDay(cashDate, date);
    });
  };

  const handlePrev = () => setBaseDay(subMonths(baseDay, 1));

  const handleNext = () => setBaseDay(addMonths(baseDay, 1));

  const Calendar = () => {
    const firstDay = startOfMonth(baseDay);
    const lastDay = lastDayOfMonth(baseDay);
    const lastDayWeek = lastDay.getDay();
    const lastDayLastMonth = lastDayOfMonth(subMonths(baseDay, 1));

    const days = [];

    for (let i = firstDay.getDay() - 1; i >= 0; i -= 1) {
      const date = subDays(lastDayLastMonth, i);
      days.push({ date, disabled: true, cash: getCash(date) });
    }

    for (let i = 0; i < lastDay.getDate(); i += 1) {
      const date = addDays(firstDay, i);
      days.push({ date, cash: getCash(date), today: isSameDay(new Date(), date) });
    }

    if (lastDayWeek < 6) {
      for (let i = 1; i <= 6 - lastDayWeek; i += 1) {
        const date = addDays(lastDay, i);
        days.push({ date, disabled: true, cash: getCash(date) });
      }
    }

    return (
      <Grid container>
        {days.map((day) => (
          <Grid item className={classes.day} key={day.date}>
            <Typography
              className={`${classes.dayLabel} ${
                day.disabled && classes.dayLabelDisabled
              } ${day.today && classes.dayLabelToday}`}
            >
              {format(day.date, day.disabled ? 'dd MMM' : 'dd', { locale: ptBR })}
            </Typography>
            <Grid container>
              {day.cash.map((cash) => {
                const contact = contacts.find((c) => c.id === cash.contact);
                const isDue = !cash.completed.on && (
                  cash.dueAt.toDate() < new Date() && !isSameDay(cash.dueAt.toDate(), new Date())
                );
                let style = { border: '1px dashed rgb(157, 156, 202)' };
                let tooltip = `${cash.type === 'in' ? 'Receivement' : 'Payment'}`;

                if (cash.completed.on) {
                  tooltip += ' confirmed';
                  style = {
                    background: '#4bb75c33',
                    border: '1px solid #4bb75c',
                  };
                } else if (isDue) {
                  tooltip += ' late';
                  style = {
                    background: '#dc1f2633',
                    border: '1px dashed #dc1f26',
                  };
                } else {
                  tooltip += ' not yet done';
                }

                return (
                  <Tooltip title={tooltip}>
                    <Grid
                      container
                      direction="column"
                      className={`${classes.cash} ${cash.completed.on && classes.cashCompleted}`}
                      style={style}
                      onClick={() => history.push(`/manager/${eventId}/financial/cash-flow/${cash.id}`)}
                    >
                      <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                        {cash.type === 'in' ? (
                          <Typography className={classes.typeReceive}>Ⓔ</Typography>
                        ) : (
                          <Typography className={classes.typePay}>Ⓢ</Typography>
                        )}
                        {cash.installments ? (
                          <Badge
                            color="secondary"
                            className={classes.badge}
                            badgeContent={`${
                              cash.installments.indexOf(null) + 1
                            }/${cash.installments.length}`}
                          >
                            {numberToReal(cash.completed.on ? cash.completed.value : cash.value)}
                          </Badge>
                        ) : numberToReal(cash.completed.on ? cash.completed.value : cash.value)}
                      </Grid>

                      {contact && (
                        <Grid container>
                          <Typography className={classes.contact}>{contact.name}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Tooltip>
                );
              })}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  const weekLabels = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  return (
    <Paper className={classes.root}>
      <Grid container justify="center" spacing={2} alignItems="center" style={{ padding: '24px 0' }}>
        <Grid item>
          <IconButton color="primary" onClick={handlePrev}>
            <ChevronLeft />
          </IconButton>
        </Grid>
        {/* <Grid item>
          <Button color="primary" variant="outlined" onClick={() => setBaseDay(new Date())}>
            HOJE
          </Button>
        </Grid> */}
        <Grid item>
          <Typography className={classes.calendarTitle}>
            {format(baseDay, 'MMMM yyyy', { locale: ptBR })}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={handleNext}>
            <ChevronRight />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        {weekLabels.map((label) => (
          <Grid item className={classes.weekDay} key={label}>{label}</Grid>
        ))}
      </Grid>
      {cashFlow && contacts && <Calendar />}
    </Paper>
  );
}

export default CashFlowCalendar;
