import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import {
  Folder, Image, File, CheckCircle, Check, Edit2, X, RefreshCw,
} from 'react-feather';
import ButtonUpload from './button';
import styles from './style';

function UploadDocument({
  index, document, files, fileProps, onChange,
}) {
  const classes = styles();

  const [edit, setEdit] = useState(document.editing || false);
  const [label, setLabel] = useState(document.label || '');
  const [error, setError] = useState(false);

  const uploaded = !!document.uploaded || !!document.url || false;

  useEffect(() => {
    setError(!!files.find((f, i) => f.label === label && i !== index)
      || label === '');
  }, [files, label, index]);

  const editFile = (doc) => {
    const newFiles = [...files];
    newFiles[index] = doc;
    onChange(newFiles);
    setLabel('');
    setEdit(false);
  };

  const removeFile = () => {
    const newFiles = [...files];
    if (newFiles[index].default) {
      newFiles[index].uploaded = false;
      newFiles[index].url = null;
    } else {
      newFiles.splice(index, 1);
    }
    onChange(newFiles);
  };

  const getIcon = () => {
    const icons = [Image, Folder, File];
    const extensions = [
      'jpg,jpeg,gif,raw,png,tiff,tif,svg',
      'zip,rar,tar',
      'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
    ];

    if (document.extension) {
      for (let i = 0; i < extensions.length; i += 1) {
        if (extensions[i].indexOf(document.extension) >= 0) return icons[i];
      }
    }

    return icons[2];
  };

  const Icon = getIcon();

  return (
    <Grid
      container
      justify="space-between"
      className={`${classes.uploadFile} ${uploaded && classes.uploadFileConfirm}`}
      key={document.name}
    >
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {uploaded && (
          <CheckCircle
            size={24}
            className={classes.checkedFile}
            style={{ marginRight: 16 }}
          />
        )}
        <Icon size={20} style={{ marginRight: 8 }} />
        {edit ? (
          <>
            <TextField
              error={error}
              placeholder="Type the file name"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              InputProps={{ style: { padding: '0 8px' } }}
            />
            <IconButton
              color="primary"
              disabled={error}
              onClick={() => editFile({ ...document, label, editing: false })}
            >
              <Check size={16} />
            </IconButton>
          </>
        ) : (
          <>
            <Typography
              style={document.url ? { cursor: 'pointer' } : {}}
              onClick={() => !!document.url && window.open(document.url)}
            >
              {document.label}
            </Typography>
            {!document.default && (
              <IconButton
                color="primary"
                onClick={() => {
                  setLabel(document.label);
                  setEdit(true);
                }}
                style={{ marginLeft: 16 }}
              >
                <Edit2 size={16} />
              </IconButton>
            )}
          </>
        )}
      </Grid>

      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {!document.uploading && !uploaded ? (
          <ButtonUpload
            buttonProps={{ style: { padding: 8 } }}
            fileProps={{ ...fileProps, index }}
            documents={files}
            onChange={onChange}
          />
        ) : (
          !uploaded && (
            <div style={{ padding: '8px 24px', height: 32 }}>
              <CircularProgress color="primary" size={18} />
            </div>
          )
        )}

        {uploaded && (
          <>
            <ButtonUpload
              buttonProps={{ icon: <RefreshCw size={18} />, style: { padding: 8 } }}
              fileProps={{ ...fileProps, index }}
              documents={files}
              onChange={onChange}
            />
            <IconButton color="primary" style={{ padding: 8 }} onClick={removeFile}>
              <X size={18} />
            </IconButton>
          </>
        )}
      </Grid>
    </Grid>
  );
}

UploadDocument.propTypes = {
  index: PropTypes.number.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    extension: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    default: PropTypes.bool,
    uploading: PropTypes.bool,
    uploaded: PropTypes.bool,
    editing: PropTypes.bool,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileProps: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UploadDocument;
