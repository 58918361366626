import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  containerPage: {
    width: 'calc(100% - 130px)',
    height: '100%',
    marginLeft: 130,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  container: {
    paddingTop: 130,
    position: 'relative',
  },
  tabsContainer: {
    position: 'absolute',
    top: 100,
    left: 40,
    width: 'calc(100% - 80px)',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(),
      width: 'calc(100% - 16px)',
    },
  },
  tabs: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)',
    background: 'transparent',
  },
  tab: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px ${theme.spacing(0.75)}px 70px`,
    marginLeft: -48,
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    border: 0,
    backgroundColor: lighten(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tabWHover: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
    },
  },
  tabMobile: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: 'calc(100% - 16px)',
  },
  tabSelected: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    border: 0,
    marginLeft: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  tabTitle: {
    color: 'white',
    fontWeight: 500,
  },
  search: {
    '& div': {
      color: 'white',
      height: 24,
      '&:before, &:after': {
        content: 'none',
      },
    }
  },
  searchClose: {
    color: 'white',
    opaciy: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  badge: {
    right: -8,
    top: -8,
  },
}));
