import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Components
import SidebarMenu from './menu';

function Sidebar({ open, onClose, actived }) {
  const theme = useTheme();
  const drawer = useMediaQuery(theme.breakpoints.down('sm'));

  if (drawer) {
    return (
      <Drawer open={open} onClose={onClose}>
        <SidebarMenu actived={actived} onClose={onClose} />
      </Drawer>
    );
  }


  return <SidebarMenu actived={actived} onClose={onClose} />;
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actived: PropTypes.string.isRequired,
};

export default Sidebar;
