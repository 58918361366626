import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Step from 'store/productivity/step/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

function TaskForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { stepId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '', type: '', order: 0, default: false,
  });

  const { steps } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (steps && stepId) {
      const step = steps.find((l) => l.id === stepId);

      if (step) {
        setInitialForm({ name: step.name, type: step.type, default: step.default || false });
        setEdit(true);
      } else {
        history.goBack();
      }
    }
  }, [steps, stepId, history]);

  const submit = async (data) => {
    if (edit) {
      dispatch(Log.store(`Changed the step ${initialForm.name}`));
      dispatch(Step.update(stepId, data));
    } else {
      const [lastStep] = steps.sort((a, b) => b.order - a.order);
      dispatch(Log.store(`Created the step ${data.name}`));
      dispatch(Step.store({ ...data, order: lastStep.order + 1 }));
    }
    history.goBack();
  };

  const formSchema = FormConfig({ isDefault: initialForm.default });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>{edit ? `Editing step ${initialForm.name}` : 'Create new step'}</span>
          <IconButton onClick={history.goBack} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {steps ? (
          <Form
            id="form-step"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} sm={6}>
                  {inputs.name}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {inputs.type}
                </Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!steps} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="form-step"
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskForm;
