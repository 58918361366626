import axios from 'axios';

export default function sendNotification(uid, data) {
  const token = process.env.NODE_ENV === 'development'
    ? 'OTg3ZmNkNTUtYzU1ZS00OTAxLTkxMDctZGRiYTZmYjAyNTFj'
    : 'ZDdkZWQ3ZDUtMDBhMy00NzM4LWFkZmMtMTVjODc2MmJiNjU5';

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Basic ${token}`,
  };

  const appId = process.env.NODE_ENV === 'development'
    ? 'd3a6c993-1efb-42bc-8b2d-75a301fb62b8'
    : 'c18b4876-f5ec-496e-ad86-91dcf0ab56f5';

  const message = {
    app_id: appId,
    filters: [{
      field: 'tag', key: 'uid', relation: '=', value: uid,
    }],
    headings: { en: data.title },
    contents: { en: data.description },
    url: data.link,
    web_buttons: data.buttons,
  };

  axios.post('https://onesignal.com/api/v1/notifications', message, { headers })
    .then((res) => {
      console.log('Response:', res);
    });
}
