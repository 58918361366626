import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function CEPMasked({ inputRef, ...rest }) {
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

CEPMasked.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CEPMasked;
