import { makeStyles, fade, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 'calc(100% - 130px)',
    height: '100%',
    marginLeft: 130,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  message: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    background: fade('#fff', 0.9),
  },
  messageContent: {
    width: '100%',
    maxWidth: 600,
  },
  title: {
    fontSize: 40,
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: theme.spacing(),
    lineHeight: 1,
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.common.grey,
    margin: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  btnX: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
  },
  row: {
    width: '100%',
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    margin: 0,
  },
  feedback: {
    width: 'fit-content',
    maxWidth: '100%',
    fontSize: 18,
    background: lighten(theme.palette.warning.main, 0.8),
    borderRadius: 4,
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
      transform: 'none',
    },
    '& > span': {
      marginRight: theme.spacing(),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: theme.spacing(),
      },
    },
  },
}));
