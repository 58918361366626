import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  editButton: {
    // position: 'absolute',
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    // top: 3,
    // right: 0,
  },
  budget: {
    margin: '16px 32px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 16,
      justifyContent: 'center',
    },
  },
  budgetIconContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    padding: 22,
    borderRadius: 50,
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'relative',
      left: 50,
      top: -12,
      height: 6,
      width: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: '10px solid white',
      [theme.breakpoints.down('xs')]: {
        left: 32,
        top: -10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 80,
      padding: 17,
    },
  },
  budgetIcon: {
    width: 50,
    height: 50,
    fill: theme.palette.tertiary.main,
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  budgetContent: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: 'calc(100% - 132px)',
    justifyContent: 'center',
    marginLeft: 32,
  },
  budgetLabel: {
    overflow: 'hidden',
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:after': {
      content: '" "',
      display: 'block',
      width: 60,
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.primary.main,
      margin: '4px 0 8px',
      [theme.breakpoints.down('xs')]: {
        width: 40,
        height: 2,
        borderRadius: 1,
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  budgetValue: {
    overflow: 'hidden',
    fontSize: '2.2rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  budgetSubvalue: {
    overflow: 'hidden',
    fontSize: 20,
    textAlign: 'right',
    color: theme.palette.secondary.main,
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(),
  },
}));
