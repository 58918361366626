import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';

// Material Components
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Commons and Styles
import Loading from 'common/loading';
import { filter } from '../../utils';
import MobileTable from './mobile';
import DesktopTable from './desktop';
import styles from './style';

function CashFlowTable() {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const { eventId } = useParams();

  const { categories, cashFlow, contacts } = useSelector((store) => store.company);

  const [cashInOut, setCashInOut] = useState([]);

  useEffect(() => {
    if (cashFlow && categories) {
      let flow = cashFlow.filter((c) => c.event === eventId);
      if (location.search !== '') flow = filter(flow, location.search, categories);

      setCashInOut(
        flow.filter((c) => c.completed.on)
          .sort((a, b) => b.completed.date.toDate() - a.completed.date.toDate())
          .map((cash) => ({
            ...cash, categories: cash.categories.map((cId) => categories.find((c) => c.id === cId)),
          })),
      );
    }
  }, [cashFlow, categories, eventId, location.search]);

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        {cashFlow && categories && contacts ? (
          <>
            {mobile
              ? <MobileTable data={cashInOut} />
              : <DesktopTable data={cashInOut} />}
          </>
        ) : (
          <Loading ready={cashFlow && categories} content={100} />
        )}
      </div>
    </Paper>
  );
}

export default CashFlowTable;
