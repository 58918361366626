import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import _ from 'lodash';

// Material components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Controllers
import Product from 'store/supplier/product/controller';
import Log from 'store/log/controller';

// Commons, Icons, Utils and Styles
import {
  Plus, Trash2, Edit2, MoreVertical,
} from 'react-feather';
import { installmentWithCashFlow } from 'common/utils/relationships';
import { numberToReal } from 'common/utils/numbers';
import ConfirmDialog from 'common/confirm-dialog';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import ProductResponsible from './components/responsibles';
import { generateTableData, filter } from './utils';
import styles from './style';

function TableDesktop() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { eventId } = useParams();

  const { users, categories, cashFlow } = useSelector((state) => state.company);
  const { products, suppliers } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);
  const isLoaded = !!suppliers && !!products && !!categories && !!cashFlow;

  const [tableData, setTableData] = useState([]);
  const [delProduct, setDelProduct] = useState(false);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  useEffect(() => {
    if (isLoaded) {
      const supp = suppliers.map((s) => {
        const supplier = _.cloneDeep(s);

        if (s.payment && s.payment.installments) {
          supplier.payment.installments = s.payment.installments.map(
            (i) => installmentWithCashFlow(i, cashFlow),
          );
        }

        return supplier;
      });

      const data = generateTableData({
        suppliers: supp, products, categories, users,
      });

      if (location.search === '') setTableData(data);
      else setTableData(filter(data, location.search));
    }
  }, [isLoaded, suppliers, products, categories, cashFlow, users, location.search]);

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (e, id) => {
    setOpenMenu(id);
    setAnchorMenu(e.currentTarget);
  };

  const closeMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenMenu(false);
    setAnchorMenu(null);
  };

  const rows = [
    {
      id: 'name',
      align: 'left',
      label: 'DESCRIPTION',
      style: { minWidth: 150 },
    },
    {
      id: 'responsible',
      align: 'center',
      label: 'RESPONSIBLE',
    },
    {
      id: 'valueForecast',
      align: 'center',
      label: 'FORECAST',
    },
    {
      id: 'valueUnit',
      align: 'center',
      label: 'UNIT',
    },
    {
      id: 'amount',
      align: 'center',
      label: 'AMOUNT',
    },
    {
      id: 'valueCurrent',
      align: 'center',
      label: 'CURRENT',
    },
    {
      id: 'payment',
      align: 'center',
      label: 'PAYMENT',
    },
    {
      id: 'delivery',
      align: 'center',
      label: 'DELIVERY',
    },
  ];

  if (!isLoaded) return <Loading ready={isLoaded} content={100} />;

  if (tableData.length === 0) {
    if (location.search === '') {
      return (
        <Grid container style={{ marginBottom: 16 }}>
          <Grid container justify="center" alignItems="center">
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`/manager/${eventId}/procurement/items/products/new`)}
            >
              Create
            </Button>
            <Typography variant="h5" className={classes.withoutData}>
            The necessary products for your event.
            </Typography>
          </Grid>
          {/* <Grid container justify="center" alignItems="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push(`/manager/${eventId}/procurement/products/select`)}
            >
              Selecione
            </Button>
            <Typography variant="h5" className={classes.withoutData}>
              os produtos de eventos que você já organizou.
            </Typography>
          </Grid> */}
        </Grid>
      );
    }

    return (
      <Typography variant="h5" className={classes.withoutData}>
        No product matching this filter.
      </Typography>
    );
  }

  return (
    <>
      <Table aria-labelledby="products">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableHeader }} style={{ width: 56 }} />
            {rows.map((row) => (
              <TableCell
                classes={{ root: classes.tableHeader }}
                key={row.id}
                align={row.align}
                padding="checkbox"
                style={row.style || {}}
              >
                {row.label}
              </TableCell>
            ))}
            <TableCell classes={{ root: classes.tableHeader }} style={{ width: 24 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((category) => (
            <React.Fragment key={category.id}>
              <TableRow>
                <TableCell
                  colSpan={10}
                  className={classes.tableCategory}
                  style={{ color: category.color }}
                >
                  <Grid container alignItems="center">
                    <div className={classes.categoryColor} style={{ background: category.color }} />
                    {category.name}
                    {permissionEdit && (
                      <IconButton
                        color="primary"
                        aria-label="Edit"
                        aria-haspopup="true"
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                          history.push(`/manager/${eventId}/procurement/items/categories/${category.id}`);
                          closeMenu();
                        }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>

              {category.products.map((product) => {
                let currentValueColor = '#4bb75c';
                let paymentTootlip = 'Payment made';
                let deliveryTooltip = 'Performed Delivery';

                if (!product.currentValue.isInsideForecast) {
                  if (product.steps.reduce((c, v) => c + v, 0) > 1) {
                    currentValueColor = '#dc1f26';
                  } else currentValueColor = '#f59658';
                }

                if (!product.payment.payed) {
                  if (product.payment.isDue) paymentTootlip = 'Late payment';
                  else paymentTootlip = 'Next payment';
                }

                if (!product.delivery.done) {
                  if (product.delivery.isDue) deliveryTooltip = 'Late delivery';
                  else deliveryTooltip = null;
                }

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={product.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(
                      `/manager/${eventId}/procurement/negotiations?products=${product.id}`,
                    )}
                  >
                    <TableCell className={classes.tableCell}>
                      <Tooltip title={product.formatedSteps.title}>
                        <div className={classes.steps}>
                          {product.formatedSteps.color.map((color) => (
                            <div
                              key={`${product.id}-steps-${color}`}
                              className={classes.step}
                              style={{ background: color }}
                            />
                          ))}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>{product.name}</TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Grid container justify="center" alignItems="center">
                        <ProductResponsible responsibles={product.responsibles} />
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {product.valueForecast ? numberToReal(product.valueForecast) : ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {product.unitValue ? numberToReal(product.unitValue) : ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {product.unitValue ? product.amount : ''}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{ whiteSpace: 'nowrap', color: currentValueColor }}
                    >
                      {!product.currentValue.isInsideForecast ? (
                        <Tooltip
                          title={
                            product.steps.reduce((c, v) => c + v, 0) > 1
                              ? 'The closed value is higher than the predicted value.'
                              : 'The average of the values in negotiation is higher than the predicted value.'
                          }
                        >
                          <span>{numberToReal(product.currentValue.value)}</span>
                        </Tooltip>
                      ) : (
                        product.currentValue.value > 0 && (
                          <span>{numberToReal(product.currentValue.value)}</span>
                        )
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{
                        whiteSpace: 'nowrap',
                        color: product.payment.payed ? '#4bb75c' : product.payment.isDue && '#dc1f26',
                      }}
                    >
                      {product.payment.date && (
                        <Tooltip title={paymentTootlip}>
                          <div>
                            {format(product.payment.date, 'dd/MM/yyyy', { locale: ptBR })}
                          </div>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCell}
                      style={{
                        whiteSpace: 'nowrap',
                        color: product.delivery.done
                          ? '#4bb75c'
                          : product.delivery.isDue && '#dc1f26',
                      }}
                    >
                      {product.delivery.date && (
                        deliveryTooltip ? (
                          <Tooltip title={deliveryTooltip}>
                            <div>
                              {format(product.delivery.date, 'dd/MM/yyyy', { locale: ptBR })}
                            </div>
                          </Tooltip>
                        ) : (
                          <div>
                            {format(product.delivery.date, 'dd/MM/yyyy', { locale: ptBR })}
                          </div>
                        )
                      )}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {permissionEdit && (
                        <IconButton
                          color="primary"
                          aria-label="Opções"
                          aria-haspopup="true"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleMenu(e, product.id);
                          }}
                        >
                          <MoreVertical />
                        </IconButton>
                      )}
                      <Menu
                        anchorEl={anchorMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={openMenu === product.id}
                        onClose={closeMenu}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/manager/${eventId}/procurement/items/new?products=${product.id}`);
                            closeMenu();
                          }}
                        >
                          <ListItemIcon>
                            <Plus />
                          </ListItemIcon>
                          <ListItemText primary="Novo fornecedor" />
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/manager/${eventId}/procurement/items/products/${product.id}`);
                            closeMenu();
                          }}
                        >
                          <ListItemIcon>
                            <Edit2 />
                          </ListItemIcon>
                          <ListItemText primary="Editar" />
                        </MenuItem>
                        {product.steps.length === 0 && (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              setDelProduct(product);
                              closeMenu();
                            }}
                          >
                            <ListItemIcon>
                              <Trash2 />
                            </ListItemIcon>
                            <ListItemText primary="Excluir" />
                          </MenuItem>
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      {delProduct && (
        <ConfirmDialog
          title={`Do you really want to delete the product ${delProduct.name}?`}
          onClose={() => setDelProduct(false)}
          onConfirm={() => {
            dispatch(Log.store(`Deleted product ${delProduct.name}`));
            dispatch(Product.destroy(delProduct.id));
          }}
        />
      )}
    </>
  );
}

export default TableDesktop;
