import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isSameDay, format } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker } from '@material-ui/pickers';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { CheckSquare } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

function Dates({ id, name, dates }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(null);
  const [openDuration, setOpenDuration] = useState(null);
  const [date, setDate] = useState(null);
  const [durationEdit, setDurationEdit] = useState({ value: 1, type: 'hours' });
  const [dateEdit, setDateEdit] = useState('');

  const update = () => {
    if (dateEdit === 'start') {
      dispatch(Log.store(`Changed task start date "${name}"`));
      dispatch(Task.update(id, { startAt: date }));
    } else if (dateEdit === 'end') {
      dispatch(Log.store(`Changed the task's end date "${name}"`));
      dispatch(Task.update(id, { endAt: date }));
    }
    setOpenDialog(null);
  };

  const updateDuration = () => {
    dispatch(Log.store(`Changed task duration "${name}"`));
    dispatch(Task.update(id, { duration: durationEdit }));
    setOpenDuration(null);
  };

  const labelDuration = (data) => {
    let sufix = '';
    if (data.type === 'minutes') sufix = 'minute';
    if (data.type === 'hours') sufix = 'hour';
    if (data.type === 'days') sufix = 'day';

    return `${data.value} ${sufix}${data.value > 1 ? 's' : ''}`;
  };

  const today = new Date();
  const {
    startAt, endAt, completedAt, workingAt, duration,
  } = dates;

  const startLate = startAt < today && !isSameDay(startAt, today) && !workingAt;
  let startClass = classes.normal;
  if (startLate) startClass = classes.late;
  else if (workingAt || completedAt) startClass = classes.confirmed;

  const endLate = endAt < today && !isSameDay(endAt, today) && !completedAt;
  let endClass = classes.normal;
  if (endLate) endClass = classes.late;
  else if (completedAt) endClass = classes.confirmed;

  return (
    <Grid container>
      <Tooltip title={startLate ? 'Task start is delayed' : ''}>
        <Grid item style={{ marginRight: 16 }}>
          <Typography className={classes.label}>Start date</Typography>
          <Grid
            container
            alignItems="center"
            className={startClass}
            onClick={(e) => {
              setOpenDialog(e.currentTarget);
              setDateEdit('start');
              setDate(startAt);
            }}
          >
            {(workingAt || completedAt) && (
              <CheckSquare size={18} style={{ marginRight: 8, color: '#4bb75c' }} />
            )}
            <Typography className={classes.date}>
              {format(workingAt || startAt, 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>

      <Tooltip title={endLate ? 'Delivery is delayed' : ''}>
        <Grid item style={{ marginRight: 16 }}>
          <Typography className={classes.label}>Delivery date</Typography>
          <Grid
            container
            alignItems="center"
            className={endClass}
            onClick={(e) => {
              setOpenDialog(e.currentTarget);
              setDateEdit('end');
              setDate(endAt);
            }}
          >
            {completedAt && <CheckSquare size={18} style={{ marginRight: 8, color: '#4bb75c' }} />}
            <Typography className={classes.date}>
              {format(completedAt || endAt, 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
      
      <Grid item>
        <Typography className={classes.label}>Duration</Typography>
        <Grid
          container
          alignItems="center"
          className={classes.normal}
          onClick={(e) => {
            setOpenDuration(e.currentTarget);
            setDurationEdit(duration || { value: 1, type: 'hours' });
          }}
        >
          <Typography className={classes.date}>
            {duration ? labelDuration(duration) : 'Not defined'}
          </Typography>
        </Grid>
      </Grid>

      <Menu
        anchorEl={openDialog}
        open={!!openDialog}
        onClose={() => setOpenDialog(null)}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <DateTimePicker
          ampm={false}
          variant="static"
          value={date}
          onChange={setDate}
        />
        <Grid container justify="space-between" alignItems="center">
          <Button size="small" color="secondary" onClick={() => setOpenDialog(null)}>
            CANCEL
          </Button>
          <Button size="small" color="primary" onClick={update}>
            OK
          </Button>
        </Grid>
      </Menu>

      <Menu
        anchorEl={openDuration}
        open={!!openDuration}
        onClose={() => setOpenDuration(null)}
        MenuListProps={{ style: { padding: 16 } }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Duration"
          value={durationEdit.value}
          onChange={(e) => {
            setDurationEdit({ ...durationEdit, value: e.target.value });
          }}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <Select
                  value={durationEdit.type}
                  style={{ paddingLeft: 8 }}
                  className={classes.select}
                  classes={{ select: classes.select }}
                  onChange={(e) => {
                    setDurationEdit({ ...durationEdit, type: e.target.value });
                  }}
                >
                  <MenuItem value="minutes">Minutes(s)</MenuItem>
                  <MenuItem value="hours">Hour(s)</MenuItem>
                  <MenuItem value="days">Day(s)</MenuItem>
                </Select>
              </InputAdornment>
            ),
          }}
        />
        <Grid container justify="space-between" alignItems="center" style={{ marginTop: 16 }}>
          <Button size="small" color="secondary" onClick={() => setOpenDuration(null)}>
            CANCEL
          </Button>
          <Button size="small" color="primary" onClick={updateDuration}>
            OK
          </Button>
        </Grid>
      </Menu>
    </Grid>
  );
}

Dates.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    startAt: PropTypes.shape(),
    endAt: PropTypes.shape(),
    workingAt: PropTypes.shape(),
    completedAt: PropTypes.shape(),
  }).isRequired,
};

export default Dates;
