import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chart: {
    height: 'calc(100% - 30px) !important',
    width: '100%',
    '& svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  nodata: {
    color: theme.palette.common.grey,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18,
  },
}));
