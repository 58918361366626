import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material components
import Grid from '@material-ui/core/Grid';

// Commons, Assets and styles
import { CashIn, CashOut, ROI } from 'assets/custom-icons';
import { numberToReal } from 'common/utils/numbers';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import styles from './style';

function Budget({ scenary }) {
  const classes = styles();

  const [revenue, setRevenue] = useState(0);
  const [cost, setCost] = useState(0);
  const [result, setResult] = useState(0);

  useEffect(() => {
    if (scenary) {
      const revenueValue = scenary.revenue.reduce((t, r) => t + r.total, 0);
      const costValue = scenary.cost.reduce((t, r) => t + r.total, 0);

      setRevenue(revenueValue);
      setCost(costValue);
      setResult(revenueValue - costValue);
    }
  }, [scenary]);

  return (
    <>
      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <CashIn className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <div className={classes.budgetLabel}>SALES FORECAST</div>
            {scenary ? (
              <div className={`${classes.budgetValue} ${classes.colorSuccess}`}>
                {numberToReal(revenue)}
              </div>
            ) : (
              <Loading ready={!!scenary} content={50} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <CashOut className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <div className={classes.budgetLabel}>COST FORECAST</div>
            {scenary ? (
              <div className={`${classes.budgetValue} ${classes.colorError}`}>
                {numberToReal(cost)}
              </div>
            ) : (
              <Loading ready={!!scenary} content={50} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <ROI className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <Grid container alignItems="flex-start" justify="space-between">
              <div className={classes.budgetLabel}>EXPECTED RESULT</div>
            </Grid>
            {scenary ? (
              <>
                <div className={classes.budgetValue}>{numberToReal(result)}</div>
                {scenary.budget > 0 && (
                  <Tooltip title="Result with initial investment">
                    <div className={classes.budgetSubvalue}>{numberToReal(scenary.budget + result)}</div>
                  </Tooltip>
                )}
              </>
            ) : (
              <Loading ready={!!scenary} content={50} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Budget.propTypes = {
  scenary: PropTypes.shape({
    cost: PropTypes.array,
    revenue: PropTypes.array,
  }),
};

Budget.defaultProps = {
  scenary: null,
};

export default Budget;
