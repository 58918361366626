import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import User from 'store/user/controller';
import Log from 'store/log/controller';

// Icons, Assets and Styles
import { X, Eye, Edit3, Edit2, Users } from 'react-feather';
import {
  Planning, Financial, Suppliers, Productivity, Marketing, Manage,
} from 'assets/custom-icons/modules';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function FormEvent() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { userId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const permissions = {
    events: { edit: true, view: ['all'], drafts: { view: true } },
    team: { edit: true, view: true },
    planner: {
      financial: { edit: true, view: true },
    },
    manager: {
      financial: { edit: true, view: true },
      sponsors: { edit: true, view: true },
      procurement: { edit: true, view: true },
    },
  };

  const [submited, setSubmited] = useState(false);
  const [initialForm, setInitialForm] = useState({ name: '', email: '', permissions });
  // const [error, setError] = useState({ name: false, email: '' });

  const { current: company, users } = useSelector((state) => state.company);
  const { store } = useSelector((state) => state.user);

  useEffect(() => {
    if (users && userId) {
      const user = users.find((u) => u.id === userId);
      if (user) setInitialForm((f) => _.merge({}, f, user));
    }
  }, [userId, users]);

  useEffect(() => {
    if (!store.creating && submited && !store.error) history.goBack();

    // if (store.error) setError((e) => ({ ...e, email: store.error }));
    // else setError((e) => ({ ...e, email: '' }));

    if (submited !== store.creating) setSubmited(store.creating);
  }, [store, submited, history]);

  const submit = (user) => {
    if (userId) {
      dispatch(Log.store(`Changed collaborators permissions ${user.name}`));
      dispatch(User.update(user, userId));
      history.goBack();
    } else {
      dispatch(Log.store(`Created teh collaborator ${user.name}`));
      dispatch(User.store({ user, company }));
    }
  };

  const formSchema = FormConfig({ userId, eventsAllow: initialForm.permissions.events.view });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open
      disableEscapeKeyDown
      onClose={() => history.goBack()}
      aria-labelledby="new-user-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>New contributor</span>
          <IconButton onClick={() => history.goBack()} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form id="new-user" schema={formSchema} initialState={initialForm} onSubmit={submit}>
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>{inputs.name}</Grid>
              <Grid item xs={12}>{inputs.email}</Grid>

              <Grid item xs={12}>
                <Typography className={classes.divider}>PERMISSIONS</Typography>
              </Grid>
              <Grid item xs={12}>
                {/* Events */}
                <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
                  <Grid container>{inputs.permissions.events.view}</Grid>
                  <Grid item style={{ display: 'flex', margin: '6px 0 0 16px' }}>
                    <Tooltip title="Permission to edit">
                      <Grid container alignItems="center" wrap="nowrap">
                        <Edit2 size={16} />
                        {inputs.permissions.events.edit}
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
                {/* Events: Draft */}
                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.subitem}>
                    <Edit3 size={18} style={{ marginRight: 16 }} />
                    <Typography>Show events drafts</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.events.drafts.view}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Team */}
                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 32 }}>
                  <Grid container alignItems="center" className={classes.item}>
                    <Users size={18} style={{ marginRight: 16 }} />
                    <Typography>Team</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.team.view}
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Edit2 size={16} />
                      {inputs.permissions.team.edit}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Planner */}
                <Grid container alignItems="center" className={classes.item} style={{ marginTop: 32 }}>
                  <Marketing style={{ width: 22, height: 22, marginRight: 16 }} />
                  <Typography>Plan</Typography>
                </Grid>
                {/* Planner: Financeiro */}
                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.subitem}>
                    <Financial style={{ width: 22, height: 22, marginRight: 16 }} />
                    <Typography>Financial Plan</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.planner.financial.view}
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Edit2 size={16} />
                      {inputs.permissions.planner.financial.edit}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Manager */}
                <Grid container alignItems="center" className={classes.item} style={{ marginTop: 32 }}>
                  <Manage style={{ width: 22, height: 22, marginRight: 16 }} />
                  <Typography>Manage</Typography>
                </Grid>
                {/* Manager: Financeiro */}
                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.subitem}>
                    <Financial style={{ width: 22, height: 22, marginRight: 16 }} />
                    <Typography>Financial</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.manager.financial.view}
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Edit2 size={16} />
                      {inputs.permissions.manager.financial.edit}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Manager: Sponsors */}
                {/* <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.subitem}>
                    <Partners style={{ width: 22, height: 22, marginRight: 16 }} />
                    <Typography>Patrocínios</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.manager.sponsors.view}
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Edit2 size={16} />
                      {inputs.permissions.manager.sponsors.edit}
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* Manager: Procurement */}
                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.subitem}>
                    <Suppliers style={{ width: 22, height: 22, marginRight: 16 }} />
                    <Typography>Procurement</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Eye size={16} />
                      {inputs.permissions.manager.procurement.view}
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Edit2 size={16} />
                      {inputs.permissions.manager.procurement.edit}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="new-user"
          variant="outlined"
          color="primary"
          disabled={submited}
          className={classes.button}
        >
          {submited && <CircularProgress size={18} style={{ marginRight: 8 }} />}
          {userId ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormEvent;
