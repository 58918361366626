import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  button: {
    margin: 20,
  },
  dialog: {
    paddingRight: '0px !important',
  },
  container: {
    marginBottom: theme.spacing(),
  },
  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  categoryColor: {
    position: 'absolute',
    width: 5,
    height: 'calc(100% - 22px)',
    borderRadius: 3,
    top: 10,
    left: theme.spacing(-1),
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  actionMenu: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  actions: {
    justifyContent: 'center',
  },
}));
