import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Event from 'store/event/controller';
import Log from 'store/log/controller';

// Icons, Components and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import styles from './style';

function DialogForecast({ onClose }) {
  const classes = styles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(0);
  const [defaultForecast, setDefaultForecast] = useState(false);

  const { current: event } = useSelector((state) => state.event);

  useEffect(() => {
    if (event.settings && event.settings.suppliers && event.settings.suppliers.budget) {
      const { type, value: currentValue } = event.settings.suppliers.budget;
      setValue(currentValue);
      setDefaultForecast(type === 'default');
    }
  }, [event]);

  const submit = (data) => {
    dispatch(Log.store('Changed estimated budget'));
    dispatch(
      Event.update(event.id, {
        settings: {
          suppliers: {
            budget: {
              type: data.defaultForecast ? 'default' : 'products',
              value: data.value,
            },
          },
        },
      }),
    );
    onClose();
  };

  const formSchema = [
    {
      name: 'defaultForecast',
      label: 'Set a closed budget',
      type: 'switch',
      onChange: (v, state) => {
        setDefaultForecast(v);
        return state;
      },
    },
    {
      name: 'value',
      label: 'Estimated budget',
      type: 'currency',
      disabled: !defaultForecast,
    },
  ];

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby="forecast-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Budget estimate</span>
          <IconButton onClick={onClose} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form
          id="form-forecast"
          onSubmit={submit}
          schema={formSchema}
          initialState={{ value, defaultForecast }}
        >
          {(inputs) => (
            <Grid container alignItems="center" spacing={1}>
              <Grid container item justify="center" xs={12} sm={6}>
                {inputs.defaultForecast}
              </Grid>
              <Grid item xs={12} sm={6}>
                {inputs.value}
              </Grid>
              {!defaultForecast && (
                <Grid item xs={12}>
                  <FormHelperText style={{ textAlign: 'center' }}>
                    The budget will be calculated based on the expected values of the products.
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-forecast"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogForecast.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DialogForecast;
