import React from 'react';

// Material UI Components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Components
import TeamDesktop from './desktop';
import TeamMobile from './mobile';

function TeamTable() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (mobile) return <TeamMobile />;
  return <TeamDesktop />;
}

export default TeamTable;
