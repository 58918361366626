import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Commons and Styles
import { numberToReal, currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import { Plus, Trash2 } from 'react-feather';
import styles from './style';

function Exchange({ edit, budget, onChange }) {
  const classes = styles();

  const [exchange, setExchange] = useState({ value: 0, description: '' });
  const [add, setAdd] = useState(false);

  useEffect(() => {
    if (!edit) setAdd(false);
  }, [edit]);

  useEffect(() => {
    if (budget.exchange) setExchange(budget.exchange);
  }, [budget]);

  const handleExchange = (key, value) => {
    onChange({ ...budget, exchange: { ...exchange, [key]: value } });
  };

  return (
    <>
      {(exchange.value > 0 || edit) && (
        <Grid item xs={12} className={classes.dividerText}>
          <Typography gutterBottom variant="overline">
          EXCHANGE
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      )}
      {exchange.value > 0 || (add && edit) ? (
        <Grid container spacing={1}>
          {add || edit ? (
            <Grid container item alignItems="center" xs={12}>
              <TextField
                variant="outlined"
                label="Valor"
                value={currencyInput(exchange.value)}
                onChange={(e) => handleExchange('value', revertCurrencyInput(e.target.value))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { className: classes.field },
                }}
                style={{ flex: 1 }}
              />
              <IconButton
                color="primary"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  onChange({ ...budget, exchange: { value: 0, description: '' } });
                  setAdd(false);
                }}
              >
                <Trash2 size={16} />
              </IconButton>
            </Grid>
          ) : (
            exchange.value > 0 && (
              <Grid item xs={12} sm={4}>
                <Typography variant="overline" className={classes.infoTitle}>
                  Value
                </Typography>
                <Typography className={classes.infoText}>
                  {numberToReal(exchange.value)}
                </Typography>
              </Grid>
            )
          )}

          {add || edit ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                label="Description"
                value={exchange.description}
                onChange={(e) => handleExchange('description', e.target.value)}
              />
            </Grid>
          ) : (
            exchange.value > 0 && exchange.description !== '' && (
              <Grid item xs={12} sm={8}>
                <Typography variant="overline" className={classes.infoTitle}>
                  Description
                </Typography>
                <Typography className={classes.infoText}>{exchange.description}</Typography>
              </Grid>
            )
          )}
        </Grid>
      ) : (
        !add
        && edit && (
          <Button color="primary" onClick={() => setAdd(true)}>
            <Plus size={16} style={{ marginRight: 8 }} />
            Add exchange
          </Button>
        )
      )}
    </>
  );
}

Exchange.propTypes = {
  edit: PropTypes.bool.isRequired,
  budget: PropTypes.shape({
    discount: PropTypes.object,
    exchange: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
};

Exchange.defaultProps = {
  budget: null,
};

export default Exchange;
