import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Scenary from 'store/scenary/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X, Plus } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Form from 'common/form';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function ProductForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { scenaryId } = useParams();
  const params = new URLSearchParams(history.location.search);
  const duplicated = params.get('duplicated');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);

  const [cost, setCost] = useState([
    { label: 'Suppliers', value: '', amount: '', total: 0 },
    { label: 'People', value: '', amount: '', total: 0 },
    { label: 'Marketing', value: '', amount: '', total: 0 },
  ]);
  const [revenue, setRevenue] = useState([
    { label: 'Sponsors', value: '', amount: '', total: 0 },
    { label: 'Tickets', value: '', amount: '', total: 0 },
  ]);
  const [initialForm, setInitialForm] = useState({ budget: 0, cost, revenue });

  const { scenaries } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.suppliers
    || permissions.suppliers.negotiations.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if ((scenaryId || duplicated) && scenaries) {
      const scenary = scenaries.find((e) => e.id === scenaryId || e.id === duplicated);

      if (!scenary) history.goBack();

      setEdit(!!scenaryId);
      setInitialForm({
        budget: scenary.budget,
        cost: scenary.cost,
        revenue: scenary.revenue,
      });
      setCost(scenary.cost);
      setRevenue(scenary.revenue);
    }
  }, [scenaries, scenaryId, history, duplicated]);

  const addSubitem = (prefix) => {
    const state = { ...initialForm };

    let obj = _.get(state, prefix);
    if (!obj) obj = [];
    obj.push({
      label: '', value: '', amount: '', total: 0,
    });
    _.set(state, prefix, obj);

    setInitialForm(state);
    if (prefix.indexOf('cost') >= 0) setCost(state.cost);
    else if (prefix.indexOf('revenue') >= 0) setRevenue(state.revenue);
  };

  const removeItem = (prefix, index) => {
    const state = { ...initialForm };

    const obj = _.get(state, prefix);
    obj.splice(index, 1);
    _.set(state, prefix, obj);

    setInitialForm(state);
    if (prefix.indexOf('cost') >= 0) setCost(state.cost);
    else if (prefix.indexOf('revenue') >= 0) setRevenue(state.revenue);
  };

  const submit = (data) => {
    if (edit) {
      dispatch(Log.store(`Edit scenario ${data.name}`));
      dispatch(Scenary.update(scenaryId, data));
    } else {
      dispatch(Log.store(`Created scenario ${data.name}`));
      dispatch(Scenary.store(data));
    }
    history.goBack();
  };

  const formSchema = FormConfig({
    cost, revenue, setCost, setRevenue,
  });

  const renderItem = (inputs, prefix, level) => (item, i) => {
    const inputLabel = _.get(inputs, `${prefix}[${i}].label`);
    const inputAmount = _.get(inputs, `${prefix}[${i}].amount`);
    const inputValue = _.get(inputs, `${prefix}[${i}].value`);

    return (
      <>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          key={item.label}
          style={{ margin: '8px 0' }}
        >
          <Grid item style={{ paddingRight: 8, color: '#777' }}>{`${level}${i + 1}.`}</Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={(!item.subitems || item.subitems.length === 0) ? 4 : 9}>
              {inputLabel}
            </Grid>
            {(!item.subitems || item.subitems.length === 0) && (
              <>
                <Grid item xs={6} sm={2}>{inputAmount}</Grid>
                <Grid item xs={6} sm={3}>{inputValue}</Grid>
              </>
            )}
            <Grid item xs={12} sm={3}>
              <Typography
                style={{
                  paddingLeft: 20,
                  fontWeight: (!item.subitems || item.subitems.length === 0) ? 400 : 600,
                }}
              >
                {numberToReal(item.total)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ width: 'fit-content', marginLeft: 8 }}>
            <Tooltip title="Add subtem">
              <IconButton
                color="primary"
                style={{ padding: 8 }}
                onClick={() => addSubitem(`${prefix}[${i}].subitems`)}
              >
                <Plus size={16} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove cost">
              <IconButton className={classes.btnRemove} onClick={() => removeItem(prefix, i)}>
                <X size={16} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {item.subitems && item.subitems.map(
          renderItem(inputs, `${prefix}[${i}].subitems`, `${level}${i + 1}.`),
        )}
      </>
    );
  };

  const total = initialForm.budget + revenue.reduce((t, s) => t + s.total, 0) - cost.reduce((t, s) => t + s.total, 0);

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="view-product-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>{edit ? `Editing ${initialForm.name}` : 'Create new scenario'}</span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {scenaries ? (
          <Form
            id="form-product"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
            onChange={setInitialForm}
          >
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  {inputs.name}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {inputs.budget}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container item alignItems="center" justify="center" direction="column">
                    <Typography style={{ fontWeight: 600, textAlign: 'center', fontSize: 14 }}>
                      FINAL BALANCE
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 600,
                        textAlign: 'center',
                        fontSize: 22,
                        color: total >= 0 ? '#4bb75c' : '#dc1f26',
                      }}
                    >
                      {numberToReal(total)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.title}>COST FORECAST</Typography>
                </Grid>
                <Grid container item wrap="nowrap" style={{ paddingBottom: 0, fontWeight: 600 }}>
                  <div style={{ width: 22 }} />
                  <Grid container item alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={4}>Cost name</Grid>
                    <Grid item xs={6} sm={2}>Amount</Grid>
                    <Grid item xs={6} sm={3}>Unit vaule</Grid>
                    <Grid item xs={12} sm={3}>Total</Grid>
                  </Grid>
                  <div style={{ width: 72 }} />
                </Grid>
                <Grid container item>
                  <Grid item xs={12}>
                    {cost.map(renderItem(inputs, 'cost', ''))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth color="primary" onClick={() => addSubitem('cost')}>
                      <Plus size={16} style={{ marginRight: 8 }} />
                      Add cost
                    </Button>
                  </Grid>
                  <Grid container item wrap="nowrap" style={{ marginTop: 12 }}>
                    <Grid container>
                      <Grid item xs={6} sm={7} style={{ padding: '0 16px' }}>
                        <Typography style={{ fontWeight: 600, textAlign: 'right' }}>
                          TOTAL
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={5} style={{ fontWeight: 600, padding: '0 16px' }}>
                        {numberToReal(cost.reduce((t, s) => t + s.total, 0))}
                      </Grid>
                    </Grid>
                    <div style={{ width: 72 }} />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.title}>SALES FORECAST</Typography>
                </Grid>
                <Grid container item>
                  <Grid item xs={12}>
                    {revenue.map(renderItem(inputs, 'revenue', ''))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth color="primary" onClick={() => addSubitem('revenue')}>
                      <Plus size={16} style={{ marginRight: 8 }} />
                      Add a receipe
                    </Button>
                  </Grid>
                  <Grid container item wrap="nowrap" style={{ marginTop: 12 }}>
                    <Grid container>
                      <Grid item xs={6} sm={7} style={{ padding: '0 16px' }}>
                        <Typography style={{ fontWeight: 600, textAlign: 'right' }}>
                          TOTAL
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={5} style={{ fontWeight: 600, padding: '0 16px' }}>
                        {numberToReal(revenue.reduce((t, s) => t + s.total, 0))}
                      </Grid>
                    </Grid>
                    <div style={{ width: 72 }} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!scenaries} content={250} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-product"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductForm;
