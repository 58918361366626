import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Styles
import styles from '../style';

function RegisterAddress({ address }) {
  const classes = styles();

  return (
    <>
      <Grid item xs={12} className={classes.dividerText}>
        <Typography gutterBottom variant="overline">
          ADDRESS
        </Typography>
        <Divider variant="middle" className={classes.divider} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Typography variant="overline" className={classes.infoTitle}>
            ZIP code
          </Typography>
          <Typography className={classes.infoText}>{address.postalCode}</Typography>
        </Grid>
        <Grid item xs={12} sm={address.complement ? 9 : 7}>
          <Typography variant="overline" className={classes.infoTitle}>
            Street
          </Typography>
          <Typography className={classes.infoText}>{address.street}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="overline" className={classes.infoTitle}>
            Number
          </Typography>
          <Typography className={classes.infoText}>{address.number}</Typography>
        </Grid>

        {address.complement && (
          <Grid item xs={12} sm={10}>
            <Typography variant="overline" className={classes.infoTitle}>
              Complement
            </Typography>
            <Typography className={classes.infoText}>{address.complement}</Typography>
          </Grid>
        )}

        {/* <Grid item xs={12} sm={5}>
          <Typography variant="overline" className={classes.infoTitle}>
            Neighborhood
          </Typography>
          <Typography className={classes.infoText}>{address.neighborhood}</Typography>
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <Typography variant="overline" className={classes.infoTitle}>
            City
          </Typography>
          <Typography className={classes.infoText}>{address.city}</Typography>
        </Grid>
        <Grid item xs={5} sm={1}>
          <Typography variant="overline" className={classes.infoTitle}>
            State
          </Typography>
          <Typography className={classes.infoText}>{address.state}</Typography>
        </Grid>
        <Grid item xs={7} sm={2}>
          <Typography variant="overline" className={classes.infoTitle}>
            Country
          </Typography>
          <Typography className={classes.infoText}>{address.country}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

RegisterAddress.propTypes = {
  address: PropTypes.shape({
    postalCode: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    complement: PropTypes.string,
    // neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default RegisterAddress;
