import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';
import Supplier from 'store/supplier/controller';

const CashFlow = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('cash-flow')
      .onSnapshot((ref) => {
        const cashFlow = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(Company.loadCashFlow(cashFlow));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;
    const { current: event } = getState().event;

    firestore.collection('companies').doc(company.id)
      .collection('cash-flow').add({
        ...data, updatedAt: now, event: event.id, createdAt: now, created: 'manual',
      })
      .then(() => {
        Mixpanel.track('Create a cash flow');
        dispatch(Alert.show({ message: `${data.type === 'in' ? 'Inflow' : 'Outflow'} created successfully.`, type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  storeSupplier: (supplier, index, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company, costCenter, categories } = getState().company;
    const { current: event } = getState().event;
    const centers = [];

    if (event.costCenter) {
      event.costCenter.forEach((centerId) => {
        const center = costCenter.find((c) => c.id === centerId);
        if (center) {
          const isMatch = (category) => {
            if (center.categories[0] === 'all' || center.categories.indexOf(category.id) >= 0) {
              return true;
            }

            if (category.parent) {
              const parent = categories.find((c) => c.id === category.parent);
              if (parent) return isMatch(parent);
            }

            return false;
          };

          if (isMatch) centers.push(centerId);
        }
      });
    }

    console.log(data);

    firestore.collection('companies').doc(company.id)
      .collection('cash-flow').add({
        ...data,
        costCenter: centers,
        created: 'supplier',
        event: event.id,
        createdAt: now,
        updatedAt: now,
      })
      .then((doc) => {
        const { installments } = supplier.payment;
        installments[index].cashFlow = doc.id;
        Mixpanel.track('Create a supplier cash flow');
        dispatch(Supplier.update(supplier.id, { payment: { installments } }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('cash-flow').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a cash flow');
        dispatch(Alert.show({
          message: `${data.type === 'in' ? 'Inflow' : 'Outflow'} updated successfully.`,
          type: 'success',
        }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id, type) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('cash-flow').doc(id)
      .delete()
      .then(() => {
        if (type) {
          dispatch(Alert.show({
            message: `${type === 'in' ? 'Inflow' : 'Outflow'} successfully deleted.`,
            type: 'success',
          }));
        }
        Mixpanel.track('Delete a cash flow');
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default CashFlow;
