import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Commons and Styles
import { CornerUpLeft } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function DesktopTable({ data }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const isFiltered = location.search !== '';
  const { eventId } = useParams();

  const { contacts, suppliers } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [reopen, setReopen] = useState(null);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  const openCash = () => {
    dispatch(Log.store(`Reopened an ${reopen.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(reopen.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === reopen.id) match = true;
        });
      }
      return match;
    });

    if (supplier) dispatch(Supplier.update(supplier.id, { status: 3 }));
    dispatch(CashFlow.update(reopen.id, { completed: { on: false, date: null, value: 0 } }));
  };

  return (
    <Table aria-labelledby="products">
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.tableHeader }} style={{ width: 16 }} />
          <TableCell classes={{ root: classes.tableHeader }} align="center">
            Date
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }} align="center">
            Category
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }}>
            Payee / Payer
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }}>Objetos</TableCell>
          <TableCell classes={{ root: classes.tableHeader }} align="right">
            Value
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }} style={{ width: 24 }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((cash) => {
          const contact = contacts.find((c) => c.id === cash.contact);

          return (
            <TableRow
              hover
              key={cash.id}
              onClick={() => history.push(`/manager/${eventId}/financial/cash-flow/${cash.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell
                className={classes.tableCell}
                style={{ lineHeight: '10px' }}
                align="center"
              >
                {cash.type === 'in' ? (
                  <Typography className={classes.typeReceive}>Ⓔ</Typography>
                ) : (
                  <Typography className={classes.typePay}>Ⓢ</Typography>
                )}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ fontWeight: 600, fontSize: 16 }}
                align="center"
              >
                {format(cash.completed.date.toDate(), 'dd MMM yyyy', { locale: ptBR })}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  wrap="nowrap"
                  direction={cash.categories.length === 1 ? 'column' : 'row'}
                >
                  {cash.categories.map((category) => category && (
                    <div className={classes.category} key={category.id}>
                      <Tooltip title={category.name}>
                        <div
                          className={classes.categoryColors}
                          style={{ background: category.color }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </Grid>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="left"
                style={{ whiteSpace: 'normal' }}
              >
                {contact ? contact.name : 'Not defined'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="left"
                style={{ whiteSpace: 'normal' }}
              >
                {cash.description}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ fontWeight: 600, fontSize: 16 }}
                align="right"
              >
                {cash.installments ? (
                  <Badge
                    color="primary"
                    className={classes.badge}
                    badgeContent={`${
                      cash.installments.indexOf(null) + 1
                    }/${cash.installments.length}`}
                  >
                    {numberToReal(cash.completed.value)}
                  </Badge>
                ) : numberToReal(cash.completed.value)}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {permissionEdit && (
                  <Tooltip title={`Reopen an ${cash.type === 'in' ? 'inflow' : 'outflow'}`}>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      disabled={!suppliers}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReopen(cash);
                      }}
                    >
                      <CornerUpLeft size={18} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          );
        })}
        {data.length === 0 && (
          <TableRow>
            <TableCell colSpan={6} className={classes.noData}>
              <Typography variant="h5">
                {!isFiltered
                  ? 'Start receiving and paying your bills'
                  : 'No entry or exit found with this filter'}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {reopen && (
        <ConfirmDialog
          title={`Are you sure you want to reopen this ${
            reopen.type === 'in' ? 'in' : 'out'
          }?`}
          onConfirm={openCash}
          onClose={() => setReopen(null)}
        />
      )}
    </Table>
  );
}

DesktopTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DesktopTable;
