import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Material components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons, Assets and styles
import { Plus } from 'react-feather';
import { CashIn, CashOut, ROI } from 'assets/custom-icons';
import { numberToReal } from 'common/utils/numbers';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import { filter } from '../../utils';
import styles from './style';

function CashFlow() {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hiddenPrevision = params.get('dateMax')
    && new Date(parseInt(params.get('dateMax'), 10)) < new Date();
  const { eventId } = useParams();

  const { cashFlow, categories } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [cashIn, setCashIn] = useState({ current: 0, estimated: 0 });
  const [cashOut, setCashOut] = useState({ current: 0, estimated: 0 });

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (cashFlow && categories) {
      let flow = cashFlow.filter((c) => c.event === eventId);
      if (location.search !== '') flow = filter(flow, location.search, categories);

      setCashIn({
        current: flow
          .filter((cash) => cash.type === 'in' && cash.completed.on)
          .reduce((t, c) => t + c.completed.value, 0),
        estimated: flow
          .filter((cash) => cash.type === 'in')
          .reduce((t, c) => t + (c.completed.on ? c.completed.value : c.value), 0),
      });
      setCashOut({
        current: flow
          .filter((cash) => cash.type === 'out' && cash.completed.on)
          .reduce((t, c) => t + c.completed.value, 0),
        estimated: flow
          .filter((cash) => cash.type === 'out')
          .reduce((t, c) => t + (c.completed.on ? c.completed.value : c.value), 0),
      });
    }
  }, [cashFlow, categories, eventId, location.search]);

  return (
    <>
      <Grid container justify="center">
        <Grid item className={classes.container}>
          <Grid item style={{ display: 'flex' }}>
            <Grid item className={classes.iconContainer}>
              <CashIn className={classes.icon} />
            </Grid>
            <Grid item className={classes.content}>
              <Grid container justify="space-between">
                <div className={classes.label}>REVENUE</div>
                {!mobile && permissionEdit && (
                  <Tooltip title="Add inflow">
                    <Link to={`/manager/${eventId}/financial/cash-flow?type=in`}>
                      <IconButton color="primary" className={classes.iconButtonContained}>
                        <Plus size={16} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </Grid>
              {!cashFlow ? <Loading ready={!!cashFlow} content={50} /> : (
                <div className={`${classes.value} ${classes.colorSuccess}`}>
                  {numberToReal(cashIn.current)}
                </div>
              )}
            </Grid>
          </Grid>
          {!hiddenPrevision && (
            <Grid item className={`${classes.estimated} ${classes.colorSuccess}`}>
              prevision of
              <b>{` ${numberToReal(cashIn.estimated)}`}</b>
            </Grid>
          )}
        </Grid>

        <Grid item className={classes.container}>
          <Grid item style={{ display: 'flex' }}>
            <Grid item className={classes.iconContainer}>
              <CashOut className={classes.icon} />
            </Grid>
            <Grid item className={classes.content}>
              <Grid container justify="space-between">
                <div className={classes.label}>EXPENSES</div>
                {!mobile && permissionEdit && (
                  <Tooltip title="Add expense">
                    <Link to={`/manager/${eventId}/financial/cash-flow?type=out`}>
                      <IconButton color="primary" className={classes.iconButtonContained}>
                        <Plus size={16} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </Grid>
              {!cashFlow ? (
                <Loading ready={!!cashFlow} content={50} />
              ) : (
                <div className={`${classes.value} ${classes.colorError}`}>
                  {numberToReal(cashOut.current)}
                </div>
              )}
            </Grid>
          </Grid>
          {!hiddenPrevision && (
            <Grid item className={`${classes.estimated} ${classes.colorError}`}>
              estimate of
              <b>{` ${numberToReal(cashOut.estimated)}`}</b>
            </Grid>
          )}
        </Grid>

        <Grid item className={classes.container}>
          <Grid item style={{ display: 'flex' }}>
            <Grid item className={classes.iconContainer}>
              <ROI className={classes.icon} />
            </Grid>
            <Grid item className={classes.content}>
              <Grid container justify="space-between">
                <div className={classes.label}>BALANCE</div>
              </Grid>
              {!cashFlow ? (
                <Loading ready={!!cashFlow} content={50} />
              ) : (
                <div className={classes.value}>
                  {numberToReal(cashIn.current - cashOut.current)}
                </div>
              )}
            </Grid>
          </Grid>
          {!hiddenPrevision && (
            <Grid item className={classes.estimated}>
              estimate of
              <b>{` ${numberToReal(cashIn.estimated - cashOut.estimated)}`}</b>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* <Grid container justify="center">
        <Grid item style={{ display: 'flex' }}>
          <Grid item className={classes.iconContainerROI}>
            <ROIIcon className={classes.iconROI} />
          </Grid>
          <Grid item className={classes.content}>
            <div className={classes.labelROI}>
              <b>ROI</b>
              {' (RESULTADO)'}
            </div>
            <div className={classes.precision}>
              <Tooltip title="Aumentar precisão">
                <IconButton color="primary" className={classes.iconButtonOutlined}>
                  <ChevronsUp size={16} />
                </IconButton>
              </Tooltip>
              <b>65% de precisão</b>
            </div>
          </Grid>
        </Grid>

        <Grid item className={classes.contentROI}>
          {!cashFlow ? (
            <Loading ready={!!cashFlow} content={50} />
          ) : (
            <div className={classes.valueROI}>{numberToReal(cashIn.current - cashOut.current)}</div>
          )}
          <Grid item className={classes.estimated} style={{ marginBottom: 12 }}>
            estimativa de
            <b>{` ${numberToReal(cashIn.estimated - cashOut.estimated)}`}</b>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
}

export default CashFlow;
