import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import Label from 'store/productivity/label/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Trash2, Edit2, Plus } from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function Settings() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams();

  const { labels, tasks } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [destroy, setDestroy] = useState(null);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  const baseUrl = (url) => {
    let base = `/planner/${eventId}/productivity`;
    if (history.location.pathname.includes('kanban')) base += '/kanban';
    return base + url;
  };

  return (
    <Grid container>
      <Grid container justify="space-between" alignItems="center">
        <Typography className={classes.title}>Event areas</Typography>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.btnAdd}
          onClick={() => history.push(baseUrl('/labels/new'))}
        >
          <Plus size={18} />
          <p>Add</p>
        </Button>
      </Grid>
      <Grid container>
        {labels && tasks ? (
          <List style={{ width: '100%' }}>
            {labels.map((label) => (
              <ListItem key={label.id}>
                <ListItemIcon style={{ minWidth: 0, marginRight: 16 }}>
                  <div className={classes.labelColor} style={{ background: label.color }} />
                </ListItemIcon>
                <ListItemText primary={label.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    color="primary"
                    onClick={() => history.push(baseUrl(`/labels/${label.id}`))}
                  >
                    <Edit2 size={16} />
                  </IconButton>
                  {!tasks.find((t) => t.labels.indexOf(label.id) >= 0) && (
                    <IconButton color="primary" onClick={() => setDestroy(label)}>
                      <Trash2 size={16} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Loading ready={!!labels && !!tasks} content={150} />
        )}

        {destroy && (
          <ConfirmDialog
            title={`Do you really want to delete the tag ${destroy.name}?`}
            onConfirm={() => {
              dispatch(Log.store(`Deleted the tag ${destroy.name}`));
              dispatch(Label.destroy(destroy.id));
            }}
            onClose={() => setDestroy(null)}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Settings;
