import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Event } from 'store/event/reducer';

const Scenary = {
  index: (eventId) => (dispatch) => {
    firestore.collection('events').doc(eventId).collection('scenaries')
      .onSnapshot((ref) => {
        const scenaries = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(Event.loadScenaries(scenaries));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('scenaries').add({
        ...data, updatedAt: now, createdAt: now,
      })
      .then(() => {
        Mixpanel.track('Create a scenary');
        dispatch(Alert.show({ message: 'Scenario created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('scenaries').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a scenary');
        dispatch(Alert.show({ message: 'Scenario updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('scenaries').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a shortcut');
        dispatch(Alert.show({ message: 'Scenario successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Scenary;
