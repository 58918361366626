import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    margin: `${theme.spacing(3)}px 0`,
    padding: theme.spacing(0.5),
  },
  tableWrapper: {
    width: `calc(100% - ${theme.spacing(2)})`,
    overflow: 'auto',
  },
  rowHeader: {
    height: 48,
  },
  cellHeader: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing()}px`,
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  row: {
    height: 64,
  },
  cell: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing()}px`,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.grey,
    borderBottom: 'none',
  },
  avatar: {
    display: 'inline-flex',
    background: theme.palette.primary.main,
    marginRight: theme.spacing() / 2,
    width: 32,
    height: 32,
    fontSize: theme.typography.pxToRem(14),
  },
  nameUser: {
    display: 'inline-flex',
    color: theme.palette.common.grey,
    fontSize: theme.typography.pxToRem(15),
  },
  // Expanded
  expasionPanel: {
    boxShadow: 'initial',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    lineHeight: 1.2,
  },
  text: {
    color: theme.palette.common.grey,
    fontSize: 18,
    wordBreak: 'break-word',
  },
  noData: {
    fontSize: 24,
    textAlign: 'center',
    color: theme.palette.common.grey,
  },
}));
