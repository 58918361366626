import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SwipeableViews from 'react-swipeable-views';

// Material UI components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus, CheckCircle, Archive, Edit, Folder, Search } from 'react-feather';
import Loading from 'common/loading';
import Card from '../components/card';
import { filter } from '../components/filter/utils';
import styles from './style';

function Negotiations({ userFilter, search }) {
  const classes = styles();

  const history = useHistory();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { eventId } = useParams();

  const { current: user } = useSelector((state) => state.user);
  const { checklist } = useSelector((state) => state.event);
  const { steps, tasks, labels } = useSelector((state) => state.company);

  const [tabIndex, setTabIndex] = useState(0);

  const [showLabel, setShowLabel] = useState(false);
  const [taskEvents, setTaskEvents] = useState([]);

  useEffect(() => {
    if (tasks) {
      let eventTasks = tasks.filter((t) => t.event === eventId);

      if (userFilter === 'me') {
        eventTasks = eventTasks.filter((t) => t.responsibles.indexOf(user.id) >= 0 || t.responsibles.length === 0);
      }
      if (search) {
        eventTasks = eventTasks.filter((t) => t.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
      }

      setTaskEvents(eventTasks);
    }
  }, [tasks, eventId, userFilter, user.id, search]);

  const isLoaded = !!tasks && !!labels && !!steps && !!checklist;

  const StepIcon = (type) => {
    const icons = {
      done: <CheckCircle size={20} className={classes.stepIcon} />,
      archive: <Archive size={20} className={classes.stepIcon} />,
      working: <Edit size={20} className={classes.stepIcon} />,
      backlog: <Folder size={20} className={classes.stepIcon} />,
      validate: <Search size={20} className={classes.stepIcon} />,
    };

    return icons[type];
  };

  if (upSm) {
    return steps ? (
      <Grid container direction="column" className={classes.container}>
        <Grid container className={classes.root} spacing={2} alignItems="flex-start">
          {steps.map((step) => (
            <Grid item className={classes.steps} key={step.name}>
              <Typography variant="h5" align="center" className={classes.textStatus}>
                {StepIcon(step.type)}
                {step.name}
                {/* <Button
                  fullWidth
                  color="secondary"
                  className={classes.btnAddTask}
                  onClick={() => history.push(`/planner/${eventId}/productivity/tasks/new?step=${step.id}`)}
                >
                  <Plus size={18} style={{ marginRight: 16 }} />
                  ADICIONAR TAREFA
                </Button> */}
              </Typography>
              <div className={classes.cardGrid}>
                {isLoaded
                  ? filter(taskEvents, history.location.search)
                    .filter((t) => t.step === step.id)
                    .sort((a, b) => b.priority - a.priority)
                    .map((task) => (
                      <Card
                        key={task.id}
                        task={task}
                        showLabel={showLabel}
                        setShowLabel={setShowLabel}
                      />
                    ))
                  : <Loading ready={isLoaded} content={200} />}
              </div>
            </Grid>
          ))}
          <Grid item className={classes.steps}>
            <Button
              fullWidth
              color="secondary"
              className={classes.btnAddStep}
              onClick={() => history.push(`/planner/${eventId}/productivity/tasks/steps/new`)}
            >
              <Plus style={{ marginRight: 16 }} />
              ADD STEP
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ) : <Loading ready={!!steps} content={400} />;
  }

  return steps ? (
    <>
      <Tabs
        value={tabIndex}
        textColor="primary"
        classes={{ flexContainer: classes.tabs }}
        onChange={(e, i) => setTabIndex(i)}
      >
        {steps.map((col) => (
          <Tab key={col.name} label={col.name} className={classes.tab} />
        ))}
      </Tabs>
      <Grid container>
        <SwipeableViews
          index={tabIndex}
          onChangeIndex={(i) => setTabIndex(i)}
          enableMouseEvents
        >
          {steps.map((step) => (
            <Grid key={step.name} item xs className={classes.cardGrid}>
            {isLoaded
              ? filter(taskEvents, history.location.search)
                .filter((s) => s.step === step.id)
                .sort((a, b) => b.priority - a.priority)
                .map((task) => (
                  <Card
                    key={task.id}
                    task={task}
                    showLabel={showLabel}
                    setShowLabel={setShowLabel}
                  />
                ))
              : <Loading ready={isLoaded} content={200} />}
            </Grid>
          ))}
        </SwipeableViews>
      </Grid>
    </>
  ) : <Loading ready={!!steps} content={400} />;
}

export default Negotiations;
