import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons and styles
import {
  CheckCircle, Archive, Edit, Folder, Search,
} from 'react-feather';
import styles from './style';

function FormConfig({ isDefault }) {
  const classes = styles();
  const { labelId } = useParams();
  const { labels } = useSelector((store) => store.company);

  const types = [
    {
      value: 'backlog',
      label: (
        <Grid container alignItems="center">
          <Folder size={18} style={{ color: '#777', marginRight: 16 }} />
          <Typography>To do</Typography>
        </Grid>
      ),
    },
    {
      value: 'working',
      label: (
        <Grid container alignItems="center">
          <Edit size={18} style={{ color: '#777', marginRight: 16 }} />
          <Typography>Working</Typography>
        </Grid>
      ),
    },
    {
      value: 'validate',
      label: (
        <Grid container alignItems="center">
          <Search size={18} style={{ color: '#777', marginRight: 16 }} />
          <Typography>Validate</Typography>
        </Grid>
      ),
    },
    {
      value: 'done',
      label: (
        <Grid container alignItems="center">
          <CheckCircle size={18} style={{ color: '#777', marginRight: 16 }} />
          <Typography>Done</Typography>
        </Grid>
      ),
    },
    {
      value: 'archive',
      label: (
        <Grid container alignItems="center">
          <Archive size={18} style={{ color: '#777', marginRight: 16 }} />
          <Typography>Archive</Typography>
        </Grid>
      ),
    },
    {
      value: 'other',
      className: classes.add,
      label: (
        <Grid container alignItems="center">
          <Typography>Not Defined</Typography>
        </Grid>
      ),
    },
  ];

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Category name',
      validation: {
        required: 'Category name is required',
        unique: {
          msg: 'There is a category with this name already',
          params: {
            list: labels
              ? labels
                .filter((c) => c.id !== labelId)
                .map((c) => c.name)
              : [],
          },
        },
      },
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      options: types,
      disabled: isDefault,
      validation: { required: 'Select a type' },
      helperText: isDefault && 'This is a standard step, its type cannot be changed',
    },
  ];
}

export default FormConfig;
