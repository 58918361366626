import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Controllers
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { ChevronUp } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import styles from './style';

function LogActivities() {
  const classes = styles();
  const dispatch = useDispatch();

  const { logs, users } = useSelector((store) => store.company);

  const [count, setCount] = useState(0);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (!logs) dispatch(Log.index());
  }, [logs, dispatch]);

  const seeMore = () => {
    setCount(count + 15);
    if (!expand) setExpand(true);
  };

  const close = () => {
    setCount(0);
    setExpand(false);
  };

  return (
    <Paper className={classes.root} style={expand ? { height: 'calc(100vh - 96px)' } : {}}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.title}
        style={{ cursor: expand ? 'pointer' : 'inherit' }}
        onClick={close}
      >
        <Typography style={{ fontWeight: 600 }}>LATEST ACTIVITIES</Typography>
        {expand && (
          <IconButton color="primary">
            <ChevronUp size={18} />
          </IconButton>
        )}
      </Grid>
      {users && logs ? (
        <>
          <List dense className={classes.list}>
            {logs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()).map((log, i) => {
              const user = users.find((u) => u.id === log.user);
              if (user) {
                const [firstName] = user.name.split(' ');

                if (i > count) return '';

                return (
                  <ListItem key={log.id}>
                    <ListItemAvatar>
                      <Tooltip title={user.name}>
                        <Avatar alt={user.name} src={user.avatar} className={classes.avatar}>
                          {user.initials}
                        </Avatar>
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${firstName} ${log.message[0].toLowerCase()}${log.message.slice(1)}`}
                      secondary={format(log.createdAt.toDate(), 'dd/MM/yyyy HH:mm', { locale: ptBR })}
                      secondaryTypographyProps={{ style: { fontSize: 12 } }}
                    />
                  </ListItem>
                );
              }

              return <></>;
            })}
          </List>
          {logs.length > count && (
            <Button fullWidth color="secondary" onClick={seeMore}>SEE MORE</Button>
          )}
        </>
      ) : (
        <Loading content={100} ready={!!users && !!logs} />
      )}
    </Paper>
  );
}

export default LogActivities;
