import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Material UI Components
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controller
import CompanyController from 'store/company/controller';

// Commons and Styles
import Form from 'common/form';
import styles from './style';

function Company() {
  const classes = styles();

  const dispatch = useDispatch();

  const [initialForm] = useState({
    plan: 'trial',
    trialDays: 15,
    trialStartAt: new Date(),
    register: {
      companyName: '',
      situation: '',
      legalNature: '',
      mainActivity: '',
      contact: {
        email: '',
        phone: '',
      },
      address: {},
    },
    infos: {
      teamSize: '',
      eventsAmount: '',
      relationWithEvents: '',
      objective: '',
    },
    responsiblePhone: '',
  });

  const [schema] = useState([
    {
      name: 'name',
      type: 'text',
      label: 'Company name',
      validation: { required: 'Company name is mandatory' },
    },
    {
      name: 'responsiblePhone',
      type: 'text',
      label: 'Phone number',
      mask: 'phone',
    },
    {
      name: 'infos.teamSize',
      type: 'select',
      label: 'How big is your event team?',
      options: [
        { label: 'Up to 5 people', value: '<5' },
        { label: 'Up to 20 people', value: '<20' },
        { label: 'Up to 50 people', value: '<50' },
        { label: 'More than 100 people', value: '>100' },
      ],
      validation: { required: 'Select your team size' },
    },
    {
      name: 'infos.eventsAmount',
      type: 'text',
      inputType: 'number',
      label: 'Approximately how many events do you do per year?',
      validation: { required: 'Enter your average annual events' },
    },
    {
      name: 'infos.relationWithEvents',
      type: 'select',
      label: 'What is the relationship between the events and your company?',
      options: [
        { label: 'Events are essential for us (Agencies, producers, etc.)', value: 'core' },
        { label: 'I do internal and external events', value: 'intern&extern' },
        { label: 'I do only internal events', value: 'intern' },
        { label: 'I do only external events', value: 'extern' },
      ],
      validation: { required: 'Tell us about your company\'s relationship with events' },
    },
    {
      name: 'infos.objective',
      type: 'text',
      multiline: true,
      label: 'What are your expectations with Hybri?',
    },
  ]);

  const submit = (data) => {
    dispatch(CompanyController.store(data));
  };

  return (
    <Grid container className={classes.container}>
      <Typography className={classes.title}>
        Tell us a little more about your comapny!
      </Typography>
      <Form id="new-company" schema={schema} initialState={initialForm} onSubmit={submit}>
        {(inputs) => (
          <Grid container spacing={1}>
            <Grid item xs={12}>{inputs.name}</Grid>
            <Grid item xs={12}>{inputs.responsiblePhone}</Grid>
            <Grid item xs={12}>{inputs.infos.teamSize}</Grid>
            <Grid item xs={12}>{inputs.infos.eventsAmount}</Grid>
            <Grid item xs={12}>{inputs.infos.relationWithEvents}</Grid>
            <Grid item xs={12}>{inputs.infos.objective}</Grid>
          </Grid>
        )}
      </Form>
      <Grid container justify="center">
        <Fab
          type="submit"
          form="new-company"
          color="primary"
          variant="extended"
          size="large"
          className={classes.button}
        >
          Continue
        </Fab>
      </Grid>
    </Grid>
  );
}

export default Company;
