import { addMonths } from 'date-fns';

const now = new Date();
const month1 = (`0${addMonths(now, now.getDate() > 13 ? 1 : 0).getMonth() + 1}`).slice(-2);
const month2 = (`0${addMonths(now, now.getDate() > 13 ? 2 : 1).getMonth() + 1}`).slice(-2);
const month3 = (`0${addMonths(now, now.getDate() > 13 ? 3 : 2).getMonth() + 1}`).slice(-2);

export default {
  '1Z2zBIe0hhCdQihPbEoq': {
    createdAt: new Date('2020-02-13 18:59:32'),
    updatedAt: new Date('2020-02-13 19:30:59'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '23596459000103',
      notes: '',
    },
    contact: {
      location: 'Brasília - DF',
      site: '',
      email: 'test@usevou.com',
      name: 'Rosângela Beatriz Oliveira',
      phone: '(61) 9857-05591',
    },
    budget: {
      products: {
        xn0Mw5lKy8POfcowoAkt: {
          value: 250,
          amount: 5,
        },
      },
      freight: 0,
      total: 1250,
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        value: 0,
        description: '',
      },
    },
    payment: {
      installments: [
        {
          due: new Date(`2020-${month1}-10 19:05:00`),
          value: 1187.5,
          cashFlow: 'GgPN0IW6ByLH7DhOXaFT',
        },
      ],
      type: 'cash',
      discount: {
        type: 'percent',
        value: 5,
      },
      method: 'billet',
      total: 1187.5,
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'xn0Mw5lKy8POfcowoAkt',
    ],
    status: 4,
    name: 'Natillas',
  },
  '3rDHz0hfIcIUeUpdBXvP': {
    updatedAt: new Date('2020-02-13 19:25:45'),
    status: 0,
    register: null,
    products: [
      'Gpbv73tg39cBDR00A4Ez',
    ],
    contact: {
      email: 'test@usevou.com',
      name: 'Maria Junqueira',
      phone: '',
      location: 'Florianópolis - SC',
      site: '',
    },
    name: 'PLANEJAZ',
    payment: null,
    responsible: null,
    archived: false,
    createdAt: new Date('2020-02-13 19:25:45'),
    interrupted: false,
  },
  '6L0YAKRgByJ1OITxXFRC': {
    interrupted: true,
    products: [
      'GYmbV0KTtykhorYkazBU',
      'xn0Mw5lKy8POfcowoAkt',
    ],
    status: 1,
    name: 'Vita Buena',
    createdAt: new Date('2020-02-13 19:02:43'),
    updatedAt: new Date('2020-02-13 19:56:53'),
    register: null,
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'André Henry Rezende',
      phone: '',
    },
    budget: {
      products: {
        GYmbV0KTtykhorYkazBU: {
          amount: 100,
          value: 8,
        },
        xn0Mw5lKy8POfcowoAkt: {
          amount: 5,
          value: 500,
        },
      },
      freight: 0,
      total: 3135,
      discount: {
        type: 'percent',
        value: 5,
      },
      exchange: {
        value: 0,
        description: '',
      },
    },
    payment: null,
    responsible: null,
    archived: false,
  },
  '7qRZiysimsnLiMVYkjp7': {
    createdAt: new Date('2020-02-13 19:03:20'),
    updatedAt: new Date('2020-02-13 20:04:31'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '17933937000201',
      notes: '',
    },
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'Tânia Marcela Nogueira',
      phone: '(68) 9817-54263',
    },
    budget: {
      discount: {
        type: 'percent',
        value: 10,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        GYmbV0KTtykhorYkazBU: {
          amount: 100,
          value: 10,
        },
      },
      freight: 0,
      total: 900,
    },
    payment: {
      total: 900,
      installments: [
        {
          value: 450,
          cashFlow: '15YYRoBtKhYDRF3VWH9R',
          due: new Date(`2020-${month1}-07 19:07:06`),
        },
        {
          value: 450,
          cashFlow: 'sMdw316Nzz7oEtBHbeW4',
          due: new Date(`2020-${month2}-07 19:07:06`),
        },
      ],
      type: 'times',
      discount: {
        type: 'money',
        value: 0,
      },
      method: 'transference',
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'GYmbV0KTtykhorYkazBU',
    ],
    status: 4,
    name: "Ride'n beer",
  },
  '8ULwDMixuCK4uaXKLALz': {
    updatedAt: new Date('2020-02-13 19:37:55'),
    register: null,
    contact: {
      location: 'Curitiba - PR',
      site: '',
      email: 'test@usevou.com',
      name: '',
      phone: '(49) 3325-8251 ',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        '04UMaUGTDjswDxi1qZKs': {
          amount: 1,
          value: 150,
        },
        qi7hn9UvwEIYAev9DDpG: {
          value: 50,
          amount: 2,
        },
        zTyyT6YS7uw0KmXlBkVO: {
          amount: 10,
          value: 20,
        },
      },
      freight: 0,
      total: 450,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'qi7hn9UvwEIYAev9DDpG',
      '04UMaUGTDjswDxi1qZKs',
      'zTyyT6YS7uw0KmXlBkVO',
    ],
    status: 1,
    name: 'WeProduce',
    createdAt: new Date('2020-02-13 19:37:18'),
  },
  AHmOMqSmXW1GXFNynBRC: {
    payment: {
      total: 500,
      type: 'cash',
      discount: {
        type: 'money',
        value: 0,
      },
      installments: [
        {
          value: 500,
          cashFlow: null,
          due: new Date(`2020-${month1}-13 19:22:18`),
        },
      ],
      method: 'cash',
    },
    responsible: null,
    archived: false,
    interrupted: true,
    products: [
      'SFBCtKpH59sHP3uPApIN',
    ],
    status: 2,
    name: 'FABRINQ',
    createdAt: new Date('2020-02-13 19:19:06'),
    updatedAt: new Date('2020-02-13 19:22:28'),
    register: null,
    contact: {
      location: 'Macapá - AP',
      site: '',
      email: 'test@usevou.com',
      name: 'Sabrina Spindola',
      phone: '',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        SFBCtKpH59sHP3uPApIN: {
          value: 500,
          amount: 1,
        },
      },
      freight: 0,
      total: 500,
    },
  },
  CpHCt844rW9K5Mf8YVTb: {
    updatedAt: new Date('2020-02-13 19:53:50'),
    register: {
      cnpj: '20431153000109',
      notes: '',
      attachments: [

      ],
      answered: true,
    },
    contact: {
      location: 'Salvador - BA',
      site: '',
      email: 'test@usevou.com',
      name: 'Roberta Bernardes',
      phone: '',
    },
    budget: {
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        WKzj3BtCzmGSkfBZjoQY: {
          value: 900,
          amount: 1,
        },
      },
      freight: 0,
      total: 900,
    },
    payment: {
      installments: [
        {
          value: 450,
          cashFlow: 'rIFnxNVvVfbBnW9rozVD',
          due: new Date(`2020-${month1}-13 19:51:10`),
        },
        {
          value: 450,
          cashFlow: 'jbqOQz4ssOKAf2H419pR',
          due: new Date(`2020-${month2}-13 19:51:10`),
        },
      ],
      type: 'times',
      discount: {
        type: 'money',
        value: 0,
      },
      method: 'card',
      total: 900,
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'WKzj3BtCzmGSkfBZjoQY',
    ],
    status: 3,
    name: 'Fluxon',
    createdAt: new Date('2020-02-13 19:50:53'),
  },
  D1eC5824r3Wl03p3j3hK: {
    payment: {
      method: 'billet',
      total: 5000,
      installments: [
        {
          value: 5000,
          cashFlow: '6EIceum9CjrZCibBbaWV',
          due: new Date(`2020-${month1}-13 19:40:21`),
        },
      ],
      type: 'cash',
      discount: {
        type: 'money',
        value: 0,
      },
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'TlqJB3agtetrFQ9YeSbZ',
    ],
    status: 4,
    name: 'Vou - Gestão de Eventos Eficiente',
    createdAt: new Date('2020-02-13 19:39:32'),
    updatedAt: new Date('2020-02-13 19:49:09'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '28301990000117',
      notes: '',
    },
    contact: {
      email: 'henrique@usevou.com',
      name: 'Henrique Maas',
      phone: '(47) 9993-79946',
      location: 'Jaraguá do Sul - SC',
      site: '',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        TlqJB3agtetrFQ9YeSbZ: {
          amount: 1,
          value: 5000,
        },
      },
      freight: 0,
      total: 5000,
    },
  },
  Et7AHcBuLRXIVs7LmfZU: {
    updatedAt: new Date('2020-02-13 19:30:09'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '15265388000120',
      notes: '',
    },
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'Rodrigo Fidélix',
      phone: '',
    },
    budget: {
      discount: {
        value: 1000,
        type: 'money',
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        VYrHao2FgQnyZEPnKXsj: {
          amount: 2,
          value: 3000,
        },
      },
      freight: 0,
      total: 5000,
    },
    payment: {
      total: 5000,
      installments: [
        {
          value: 2500,
          cashFlow: '9KYiRvZb6pqKMDxmsDSG',
          due: new Date(`2020-${month1}-15 19:28:22`),
        },
        {
          value: 2500,
          cashFlow: '2sAokrzeS0M2h2h0A2lL',
          due: new Date(`2020-${month2}-15 19:28:22`),
        },
      ],
      type: 'times',
      discount: {
        type: 'money',
        value: 0,
      },
      method: 'transference',
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'VYrHao2FgQnyZEPnKXsj',
    ],
    status: 3,
    name: 'Media 4Show',
    createdAt: new Date('2020-02-13 19:28:03'),
  },
  JUp3U2avcjw7oDDCu2Hu: {
    interrupted: false,
    products: [
      'AG3EHs1wCaiuwRDX8zFu',
      'Lhk1SbAWr3oAfM4B6gWv',
    ],
    status: 2,
    name: 'Divulguy',
    createdAt: new Date('2020-02-13 19:32:32'),
    updatedAt: new Date('2020-02-13 19:33:43'),
    register: null,
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'Diego Ribas',
      phone: '(48) 3325-1568 ',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        Lhk1SbAWr3oAfM4B6gWv: {
          amount: 10,
          value: 80,
        },
        AG3EHs1wCaiuwRDX8zFu: {
          value: 50,
          amount: 10,
        },
      },
      freight: 0,
      total: 1300,
    },
    payment: {
      total: 1300,
      type: 'times',
      discount: {
        type: 'money',
        value: 0,
      },
      installments: [
        {
          value: 400,
          cashFlow: null,
          due: new Date(`2020-${month1}-13 19:33:06`),
        },
        {
          value: 900,
          cashFlow: null,
          due: new Date(`2020-${month2}-13 19:33:06`),
        },
      ],
      method: 'transference',
    },
    responsible: null,
    archived: false,
  },
  KhDV1K2NN2ckPy5ktlPS: {
    responsible: null,
    createdAt: new Date('2020-02-13 19:10:14'),
    archived: false,
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:10:14'),
    register: null,
    status: 0,
    products: [
      'wi3pZQEJWnzedcOxm7oP',
    ],
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: '',
      phone: '(61) 3768-8229 ',
    },
    name: 'BonnaPedra',
    payment: null,
  },
  QiqLZhSEzzpn2xlAyG2h: {
    register: null,
    status: 0,
    products: [
      'xn0Mw5lKy8POfcowoAkt',
    ],
    contact: {
      email: 'test@usevou.com',
      name: 'Eduardo Leandro Manoel Rocha',
      phone: '(79) 9818-10854',
      location: 'Aracaju - SE',
      site: '',
    },
    name: 'Saudável Mente',
    payment: null,
    responsible: null,
    createdAt: new Date('2020-02-13 19:00:22'),
    archived: false,
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:00:22'),
  },
  UniU58EsffwyzcfvkFAz: {
    payment: null,
    responsible: null,
    archived: false,
    createdAt: new Date('2020-02-13 19:16:11'),
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:16:11'),
    status: 0,
    register: null,
    products: [
      'AMzRgPlC6aVhuNpZBEgA',
    ],
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'Mateus Martins',
      phone: '',
    },
    name: 'Carmina',
  },
  VW03BtpQAQ1RLUFQ8NDO: {
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '02964380000100',
      notes: '',
    },
    contact: {
      location: 'Paulo Afonso - BA',
      site: '',
      email: 'test@usevou.com',
      name: 'Isadora Jaqueline Alves',
      phone: '(75) 9895-90088',
    },
    budget: {
      products: {
        AMzRgPlC6aVhuNpZBEgA: {
          value: 5,
          amount: 500,
        },
      },
      freight: 0,
      total: 2250,
      discount: {
        type: 'percent',
        value: 10,
      },
      exchange: {
        value: 0,
        description: '',
      },
    },
    payment: {
      type: 'times',
      discount: {
        type: 'money',
        value: 0,
      },
      installments: [
        {
          value: 750,
          cashFlow: null,
          due: new Date(`2020-${month1}-10 19:17:22`),
        },
        {
          value: 750,
          cashFlow: null,
          due: new Date(`2020-${month2}-10 19:17:22`),
        },
        {
          value: 750,
          cashFlow: null,
          due: new Date(`2020-${month3}-10 19:17:22`),
        },
      ],
      method: 'card',
      total: 2250,
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'AMzRgPlC6aVhuNpZBEgA',
    ],
    status: 2,
    name: 'Prátice Ambientes',
    createdAt: new Date('2020-02-13 19:14:36'),
    updatedAt: new Date('2020-02-13 19:56:25'),
  },
  Z2OiQoUqPMQn5uI7kV7o: {
    status: 1,
    register: null,
    products: [
      'Lhk1SbAWr3oAfM4B6gWv',
      'AG3EHs1wCaiuwRDX8zFu',
    ],
    contact: {
      location: 'Belo Horizonte - MG',
      site: '',
      email: 'test@usevou.com',
      name: '',
      phone: '',
    },
    name: 'You ShowUP',
    payment: null,
    responsible: null,
    createdAt: new Date('2020-02-13 19:34:24'),
    archived: false,
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:34:28'),
  },
  a2VghhvSXkXWVceZfpkQ: {
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'qi7hn9UvwEIYAev9DDpG',
      '04UMaUGTDjswDxi1qZKs',
      'zTyyT6YS7uw0KmXlBkVO',
    ],
    status: 2,
    name: 'Plusmart Comunicação e Eventos',
    createdAt: new Date('2020-02-13 19:35:43'),
    updatedAt: new Date('2020-02-13 19:54:38'),
    register: {
      answered: true,
      cnpj: '01469655000176',
      notes: '',
      attachments: [

      ],
    },
    contact: {
      email: 'test@usevou.com',
      name: 'Bernard Vogen',
      phone: '',
      location: 'Teresina - PI',
      site: '',
    },
    budget: {
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        zTyyT6YS7uw0KmXlBkVO: {
          amount: 10,
          value: 10,
        },
        '04UMaUGTDjswDxi1qZKs': {
          amount: 1,
          value: 80,
        },
        qi7hn9UvwEIYAev9DDpG: {
          amount: 5,
          value: 20,
        },
      },
      freight: 0,
      total: 280,
    },
    payment: {
      total: 280,
      type: 'cash',
      discount: {
        value: 0,
        type: 'money',
      },
      installments: [
        {
          value: 280,
          cashFlow: null,
          due: new Date(`2020-${month1}-17 19:36:00`),
        },
      ],
      method: 'transference',
    },
  },
  b8ao21xdC18fhmC7rwzW: {
    createdAt: new Date('2020-02-13 19:11:24'),
    updatedAt: new Date('2020-02-13 19:30:49'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '07924834000132',
      notes: '',
    },
    contact: {
      location: 'Luziânia - GO',
      site: '',
      email: 'test@usevou.com',
      name: 'Tiago Raimundo',
      phone: '',
    },
    budget: {
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        wi3pZQEJWnzedcOxm7oP: {
          amount: 1,
          value: 400,
        },
        SFBCtKpH59sHP3uPApIN: {
          amount: 1,
          value: 700,
        },
      },
      freight: 0,
      total: 1100,
    },
    payment: {
      total: 990,
      installments: [
        {
          value: 990,
          cashFlow: '78UwBKnupk3KNyluvaoz',
          due: new Date(`2020-${month1}-10 19:11:00`),
        },
      ],
      type: 'cash',
      discount: {
        type: 'percent',
        value: 10,
      },
      method: 'cash',
    },
    responsible: null,
    archived: true,
    interrupted: false,
    products: [
      'SFBCtKpH59sHP3uPApIN',
      'wi3pZQEJWnzedcOxm7oP',
    ],
    status: 4,
    name: 'Montessor Moveis',
  },
  dK9WvLwJfevDLRfa6Qov: {
    archived: false,
    interrupted: false,
    products: [
      'WKzj3BtCzmGSkfBZjoQY',
    ],
    status: 1,
    name: 'LinKey',
    createdAt: new Date('2020-02-13 19:52:03'),
    updatedAt: new Date('2020-02-13 19:52:14'),
    register: null,
    contact: {
      location: 'Campinas - SP',
      site: '',
      email: 'test@usevou.com',
      name: '',
      phone: '(11) 9956-82158',
    },
    budget: {
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        WKzj3BtCzmGSkfBZjoQY: {
          amount: 1,
          value: 1500,
        },
      },
      freight: 0,
      total: 1500,
    },
    payment: null,
    responsible: null,
  },
  hDuoERqxK3OslBMoUiD6: {
    register: null,
    contact: {
      email: 'test@usevou.com',
      name: 'Fabiano Mendes',
      phone: '(89) 3384-1584 ',
      location: '',
      site: '',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        Gpbv73tg39cBDR00A4Ez: {
          value: 400,
          amount: 5,
        },
      },
      freight: 0,
      total: 2000,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: true,
    products: [
      'Gpbv73tg39cBDR00A4Ez',
    ],
    status: 1,
    name: 'Classik',
    createdAt: new Date('2020-02-13 19:23:48'),
    updatedAt: new Date('2020-02-13 19:56:49'),
  },
  iaaP8YwVJ7ClSTjNZUH3: {
    updatedAt: new Date('2020-02-13 19:46:59'),
    register: null,
    contact: {
      email: 'test@usevou.com',
      name: '',
      phone: '(11) 4256-2585 ',
      location: '',
      site: '',
    },
    budget: {
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        TlqJB3agtetrFQ9YeSbZ: {
          amount: 1,
          value: 10000,
        },
      },
      freight: 0,
      total: 10000,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'TlqJB3agtetrFQ9YeSbZ',
    ],
    status: 1,
    name: 'TecnoHouse',
    createdAt: new Date('2020-02-13 19:46:42'),
  },
  jCqWa9TxLt0BgXXrSrfi: {
    payment: {
      method: 'card',
      total: 150,
      installments: [
        {
          value: 150,
          cashFlow: 'WI2ddg60JbuIe55RTiI0',
          due: new Date(`2020-${month1}-13 19:47:54`),
        },
      ],
      type: 'cash',
      discount: {
        type: 'money',
        value: 0,
      },
    },
    responsible: null,
    archived: true,
    interrupted: false,
    products: [
      'F5M1dPH9XNQXsRDVbwx4',
    ],
    status: 4,
    name: 'MailChimp',
    createdAt: new Date('2020-02-13 19:47:40'),
    updatedAt: new Date('2020-02-13 20:04:39'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '07434322000198',
      notes: '',
    },
    contact: {
      location: 'Rio de Janeiro - RJ',
      site: '',
      email: 'test@usevou.com',
      name: '',
      phone: '(11) 4256-2585 ',
    },
    budget: {
      products: {
        F5M1dPH9XNQXsRDVbwx4: {
          amount: 1,
          value: 150,
        },
      },
      freight: 0,
      total: 150,
      discount: {
        value: 0,
        type: 'money',
      },
      exchange: {
        description: '',
        value: 0,
      },
    },
  },
  jkbgy97AkIP26Rjze6xD: {
    createdAt: new Date('2020-02-13 19:09:52'),
    updatedAt: new Date('2020-02-13 19:24:28'),
    register: null,
    contact: {
      location: '',
      site: '',
      email: 'test@usevou.com',
      name: 'Simone Maya Priscila da Cruz',
      phone: '',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        wi3pZQEJWnzedcOxm7oP: {
          value: 500,
          amount: 1,
        },
      },
      total: 500,
      freight: 0,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'wi3pZQEJWnzedcOxm7oP',
    ],
    status: 1,
    name: 'Monte Brasil',
  },
  lIE9vITFksQMUqAUESwd: {
    createdAt: new Date('2020-02-13 19:26:36'),
    updatedAt: new Date('2020-02-13 19:27:12'),
    register: null,
    contact: {
      email: 'test@usevou.com',
      name: '',
      phone: '(11) 9965-72535',
      location: '',
      site: '',
    },
    budget: {
      discount: {
        type: 'percent',
        value: 10,
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        VYrHao2FgQnyZEPnKXsj: {
          value: 3000,
          amount: 2,
        },
      },
      freight: 0,
      total: 5400,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'VYrHao2FgQnyZEPnKXsj',
    ],
    status: 1,
    name: 'InformUp',
  },
  lNj3e6a6Xp0RJJFaayiz: {
    payment: null,
    responsible: null,
    archived: false,
    createdAt: new Date('2020-02-13 19:38:42'),
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:38:42'),
    status: 0,
    register: null,
    products: [
      'qi7hn9UvwEIYAev9DDpG',
      '04UMaUGTDjswDxi1qZKs',
      'zTyyT6YS7uw0KmXlBkVO',
    ],
    contact: {
      location: 'Gramado - RS',
      site: '',
      email: 'test@usevou.com',
      name: 'Franciely Nobrega',
      phone: '',
    },
    name: 'Neocentury',
  },
  mJEpUDglOBC9jeJS5pvx: {
    status: 1,
    products: [
      'SFBCtKpH59sHP3uPApIN',
    ],
    register: null,
    contact: {
      location: 'Joinville - SC',
      site: '',
      email: 'test@usevou.com',
      name: 'Julia Figueiroa',
      phone: '',
    },
    name: 'La Eleganza',
    payment: null,
    responsible: null,
    createdAt: new Date('2020-02-13 19:16:35'),
    archived: false,
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:20:20'),
  },
  nQhCJXaatZ72Ls9o1Ow5: {
    status: 0,
    register: null,
    products: [
      'VYrHao2FgQnyZEPnKXsj',
    ],
    contact: {
      location: 'Florianópolis - SC',
      site: '',
      email: 'test@usevou.com',
      name: 'Stephany Gonçalves',
      phone: '(48) 9965-78211',
    },
    name: 'Propagou Comunicação',
    payment: null,
    responsible: null,
    archived: false,
    createdAt: new Date('2020-02-13 19:31:55'),
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:31:55'),
  },
  poUIeYNFql5UehkCyzs9: {
    updatedAt: new Date('2020-02-13 19:03:58'),
    register: null,
    status: 0,
    products: [
      'GYmbV0KTtykhorYkazBU',
    ],
    contact: {
      email: 'test@usevou.com',
      name: 'Thomas Cláudio Samuel Nunes',
      phone: '',
      location: 'Belém - PA',
      site: '',
    },
    name: 'Outlet Beer',
    payment: null,
    responsible: null,
    createdAt: new Date('2020-02-13 19:03:58'),
    archived: false,
    interrupted: false,
  },
  tF81YrgUKR6aqRd1QSZN: {
    updatedAt: new Date('2020-02-13 19:55:33'),
    register: {
      attachments: [

      ],
      answered: true,
      cnpj: '06111559000175',
      notes: '',
    },
    contact: {
      email: 'test@usevou.com',
      name: 'Manoel de Oliveira',
      phone: '(89) 9915-91515',
      location: '',
      site: '',
    },
    budget: {
      discount: {
        type: 'percent',
        value: 10,
      },
      exchange: {
        description: '',
        value: 0,
      },
      products: {
        Gpbv73tg39cBDR00A4Ez: {
          amount: 5,
          value: 375,
        },
      },
      freight: 0,
      total: 1687.5,
    },
    payment: {
      installments: [
        {
          value: 1500,
          cashFlow: 'gKxfB4OrfUqE8lzRgNxC',
          due: new Date(`2020-${month2}-10 19:20:00`),
        },
      ],
      type: 'cash',
      discount: {
        type: 'money',
        value: 187.5,
      },
      method: 'cash',
      total: 1500,
    },
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'Gpbv73tg39cBDR00A4Ez',
    ],
    status: 3,
    name: 'Toldos e Tendas Figueira',
    createdAt: new Date('2020-02-13 19:19:48'),
  },
  zRqgea759RIITAS8SZKR: {
    payment: null,
    responsible: null,
    archived: false,
    createdAt: new Date('2020-02-13 19:50:01'),
    interrupted: false,
    updatedAt: new Date('2020-02-13 19:50:07'),
    status: 1,
    register: null,
    products: [
      'F5M1dPH9XNQXsRDVbwx4',
    ],
    contact: {
      email: 'test@usevou.com',
      name: '',
      phone: '',
      location: '',
      site: '',
    },
    name: 'MailerLite',
  },
  zeX49Dv7fldn2dxU4sC0: {
    updatedAt: new Date('2020-02-13 19:25:00'),
    register: null,
    contact: {
      email: 'test@usevou.com',
      name: '',
      phone: '(84) 9821-84873',
      location: 'Natal - RN',
      site: '',
    },
    budget: {
      discount: {
        type: 'money',
        value: 0,
      },
      exchange: {
        value: 0,
        description: '',
      },
      products: {
        AMzRgPlC6aVhuNpZBEgA: {
          value: 7,
          amount: 500,
        },
      },
      freight: 0,
      total: 3500,
    },
    payment: null,
    responsible: null,
    archived: false,
    interrupted: false,
    products: [
      'AMzRgPlC6aVhuNpZBEgA',
    ],
    status: 1,
    name: 'Bergamo',
    createdAt: new Date('2020-02-13 19:15:27'),
  },
};
