import validator from 'validator';
import _ from 'lodash';
import { isSameDay } from 'date-fns';

const validationFunctions = {
  required: (value, params) => {
    if (!value) return false;
    if (typeof value === 'number' && value === 0) return false;
    if (value.length === 0) return false;
    if (params && params.key && !value[params.key]) return false;
    return true;
  },

  unique: (value, { list }) => list.map((i) => i.toLowerCase())
    .indexOf(value.trim().split(/\.|\/|-/).join('').toLowerCase()) < 0,

  email: (value) => validator.isEmail(value.trim()),

  phone: (value) => {
    const len = value.trim().length;
    return len === 0 || len === 14 || len === 15;
  },

  url: (value) => (value && validator.isURL(value.trim())) || true,

  minValue: (value, { min }, state) => {
    let minValue = min;
    if (typeof min === 'string') minValue = _.get(state, min);
    return value >= minValue;
  },

  maxValue: (value, { max }, state) => {
    let maxValue = max;
    if (typeof max === 'string') maxValue = _.get(state, max);
    return value <= maxValue;
  },

  minDate: (value, { min }, state) => {
    let minValue = min;
    if (typeof min === 'string') minValue = _.get(state, min);
    return value > minValue || isSameDay(value, minValue);
  },

  maxDate: (value, { max }, state) => {
    let maxValue = max;
    if (typeof max === 'string') maxValue = _.get(state, max);
    return value < maxValue || isSameDay(value, maxValue);
  },

  maxLength: (value, { max }) => value.length <= max,

  past: (value) => {
    let date = value;
    if (date && date.toDate) date = date.toDate();
    return date && (date > new Date() || isSameDay(date, new Date()));
  },

  future: (value) => {
    let date = value;
    if (date && date.toDate) date = date.toDate();
    return date && (date < new Date() || isSameDay(date, new Date()));
  },

  pattern: (value, { regex }) => regex.test(value.trim()),

  anyOf: (value, params, state) => {
    let isValid = false;
    params.types.forEach((type) => {
      if (validationFunctions[type](value, params, state)) isValid = true;
    });
    return isValid;
  },

  defaultUploads: (value) => value && value.find((v) => v.default) && !value.find(
    (v) => v.default && !v.uploading && !v.uploaded && !v.url,
  ),

  custom: (value, { valid }) => valid(value),
};

export default function (value, messages, state) {
  let error = false;

  Object.keys(messages).forEach((key) => {
    if (key === 'error' && messages.error) {
      error = messages.error;
    } else if (validationFunctions[key]) {
      const isValid = validationFunctions[key](value, messages[key].params, state);
      if (!isValid && !error) error = messages[key].msg || messages[key];
    }
  });

  return error;
}
