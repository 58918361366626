import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 500,
    margin: 'auto',
    padding: `${theme.spacing(2)}px ${theme.spacing()}px`,
  },
  title: {
    width: '100%',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: `${theme.spacing(2)}px 0`,
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    margin: `${theme.spacing(2)}px 0`,
  },
}));
