import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { acceptBanks } from 'services/tecnospeed';

// Material UI Components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  X, CheckCircle, Clock, Download, Edit2, Check, File, CornerUpLeft,
} from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import Form from 'common/form';
import ConfirmDialog from 'common/confirm-dialog';
import { numberToReal } from 'common/utils/numbers';
import styles from './style';

function CashFlowForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, cashId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    accounts, categories, cashFlow, costCenter, contacts, suppliers,
  } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [editNotes, setEditNotes] = useState(false);
  const [cash, setCash] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [reopen, setReopen] = useState(null);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  useEffect(() => {
    if (cashId && cashFlow && categories && accounts && costCenter && contacts) {
      const flow = cashFlow.find((c) => c.id === cashId);
      if (flow) {
        const cashCategories = [];
        flow.categories.forEach((cId) => {
          const category = categories.find((c) => c.id === cId);
          if (category) cashCategories.push(category);
        });

        setCash({
          ...flow,
          contact: contacts.find((a) => a.id === flow.contact),
          categories: cashCategories,
          account: accounts.find((a) => a.id === flow.account),
          costCenter: flow.costCenter
            ? flow.costCenter.map((id) => costCenter.find((c) => c.id === id))
            : [],
        });
      }
    }
  }, [cashId, cashFlow, categories, accounts, costCenter, contacts]);

  const submitNotes = ({ notes }) => {
    dispatch(CashFlow.update(cashId, { notes: notes || '' }));
    setEditNotes(false);
  };

  const confirmPayment = () => {
    dispatch(Log.store(`Confirmed ${cash.type === 'in' ? 'inflow' : 'ouflow'} of ${
      numberToReal(cash.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === cashId) match = true;
        });
      }
      return match;
    });

    if (supplier) {
      const lastCash = supplier.payment.installments.filter((i) => {
        const cf = cashFlow.find((c) => c.id === i.cashFlow);
        return cf && !cf.completed.on;
      }).length === 1;
      if (lastCash) dispatch(Supplier.update(supplier.id, { status: 4 }));
    }

    dispatch(CashFlow.update(cash.id, {
      completed: { ...cash.completed, status: null, on: true },
    }));
  };

  const openCash = () => {
    dispatch(Log.store(`Reopenned ${reopen.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(reopen.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === reopen.id) match = true;
        });
      }
      return match;
    });

    if (supplier) dispatch(Supplier.update(supplier.id, { status: 3 }));
    dispatch(CashFlow.update(reopen.id, {
      completed: { ...reopen.completed, status: null, on: false },
    }));
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      scroll="body"
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
          <Grid container alignItems="center">
            {cash && (
              <>
                <Grid item className={classes.valueTicket}>
                  {cash.type === 'in' ? (
                    <div style={{ display: 'flex', marginRight: 16, color: '#4bb75c' }}>Ⓔ</div>
                  ) : (
                    <div style={{ display: 'flex', marginRight: 16, color: '#dc1f26' }}>Ⓢ</div>
                  )}
                  {cash.installments ? (
                    <Badge
                      color="secondary"
                      className={classes.installments}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={`${
                        cash.installments.indexOf(null) + 1
                      }/${cash.installments.length}`}
                    >
                      {numberToReal(cash.completed.on ? cash.completed.value : cash.value)}
                    </Badge>
                  ) : numberToReal(cash.completed.on ? cash.completed.value : cash.value)}
                </Grid>

                <Grid item className={classes.ticketDate}>
                  {cash.completed.on ? (
                    <>
                      <p>{`${cash.type === 'in' ? 'RECEIPT' : 'PAID'} AT`}</p>
                      <Grid container alignItems="center" style={{ width: 'fit-content' }}>
                        <Tooltip title={`${cash.type === 'in' ? 'Receivement' : 'Payment'} confirmed`}>
                          <div style={{ display: 'flex' }}>
                            <CheckCircle style={{ marginRight: 16, color: '#4bb75c' }} />
                          </div>
                        </Tooltip>
                        {format(cash.completed.date.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <p>DUE DATE</p>
                      <Grid container alignItems="center" style={{ width: 'fit-content' }}>
                        <Tooltip title={`${cash.type === 'in' ? 'Receivement' : 'Payment'} not computed`}>
                          <div style={{ display: 'flex' }}>
                            <Clock style={{ marginRight: 16, color: '#777' }} />
                          </div>
                        </Tooltip>
                        {format(cash.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <IconButton onClick={history.goBack} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {cash ? (
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {cash.categories.map((category) => (
                <div
                  key={category.id}
                  className={classes.category}
                  style={{
                    background: category.color,
                    color: theme.palette.getContrastText(category.color),
                  }}
                >
                  {category.name}
                </div>
              ))}
            </Grid>

            {cash.contact && (
              <Grid item xs={12} style={{ marginTop: 16 }}>
                <Typography className={classes.infoTitle}>
                  {cash.type === 'in' ? 'PAYING' : 'BENEFITED'}
                </Typography>
                <Typography className={classes.infoValue}>
                  {cash.contact.name}
                </Typography>
              </Grid>
            )}

            <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
              <Grid item xs={12}>
                <Typography className={classes.title}>{cash.description}</Typography>
              </Grid>
              {!cash.completed.on && !cash.completed.status && permissionEdit && (
                <Tooltip title="Edit Account">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (history.location.pathname.indexOf('dashboard') >= 0) {
                        history.push(`/dashboard/${eventId}/cash-flow/${cash.id}/edit`);
                      } else {
                        history.push(`/manager/${eventId}/financial/cash-flow/${cash.id}/edit`);
                      }
                    }}
                  >
                    <Edit2 size={18} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>

            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              wrap="nowrap"
              style={{ marginTop: 32 }}
            >
              {editNotes ? (
                <Form
                  id="form-notes"
                  onSubmit={submitNotes}
                  initialState={cash}
                  schema={[{ name: 'notes', type: 'text', multiline: true }]}
                >
                  {(inputs) => (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.infoTitle}>NOTES</Typography>
                        {inputs.notes}
                      </Grid>
                      <Grid container>
                        <Button
                          type="submit"
                          color="primary"
                          className={classes.btnGreen}
                          style={{ margin: 4 }}
                        >
                          <Check size={16} style={{ marginRight: 8 }} />
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          className={classes.btnRed}
                          onClick={() => setEditNotes(false)}
                          style={{ margin: 4 }}
                        >
                          <X size={16} style={{ marginRight: 8 }} />
                          Cancel
                        </Button>
                      </Grid>
                    </>
                  )}
                </Form>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography className={classes.infoTitle}>NOTES</Typography>
                    <Typography
                      className={`${classes.infoValue} ${!cash.notes && classes.infoDefault}`}
                      style={{ fontSize: 18 }}
                    >
                      {cash.notes || 'Write down some notes regarding that account'}
                    </Typography>
                  </Grid>
                  {permissionEdit && (
                    <Tooltip title="Edit notes">
                      <IconButton
                        color="primary"
                        onClick={() => setEditNotes(true)}
                        style={{ marginLeft: 16 }}
                      >
                        <Edit2 size={18} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>

            {cash.completed.on && (
              <>
                <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                  <Typography className={classes.infoTitle}>INITIAL VALUE</Typography>
                  <Typography className={classes.infoValue}>
                    {numberToReal(cash.value)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                  <Typography className={classes.infoTitle}>DUE DATE</Typography>
                  <Typography className={classes.infoValue}>
                    {format(cash.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                  </Typography>
                </Grid>
              </>
            )}

            {cash.emmittedAt && (
              <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
                <Typography className={classes.infoTitle}>ISSUED</Typography>
                <Typography className={classes.infoValue}>
                  {format(cash.emmittedAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6} style={{ marginTop: 32 }}>
              <Typography className={classes.infoTitle}>ACCOUNT</Typography>
              <Grid className={classes.infoValue}>
                {cash.account ? (
                  <>
                    <p>{acceptBanks.find((b) => b.code === cash.account.bank).name}</p>
                    <span>{cash.account.agency}</span>
                    <span>{cash.account.code}</span>
                  </>
                ) : 'Not defined'}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={cash.emmittedAt ? 12 : 6} style={{ marginTop: 32 }}>
              <Typography className={classes.infoTitle}>COST CENTER</Typography>
              <Grid className={classes.infoValue} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {cash.costCenter && cash.costCenter.length > 0
                  ? cash.costCenter.map((center) => (
                    <div key={center.id} className={classes.costCenter}>
                      {`${center.code ? `${center.code} - ` : ''}${center.name}`}
                    </div>
                  )) : 'Not defined'}
              </Grid>
            </Grid>

            {cash.completed.on && cash.completed.receipt && cash.completed.receipt.length > 0 && (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
                className={`${classes.document} ${classes.documentGreen}`}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <File size={18} style={{ marginRight: 16 }} />
                  {`Registered ${cash.type === 'in' ? 'received' : 'paid'}`}
                </div>
                <Tooltip title="Download attachment">
                  <IconButton color="primary" onClick={() => window.open(cash.completed.receipt[0].url)}>
                    <Download size={18} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            {cash.attachments && (
              <Grid item xs={12} style={{ marginTop: 32 }}>
                <Typography className={classes.infoTitle}>ATTACHMENT</Typography>
                <Grid container style={{ marginTop: 8 }}>
                  {cash.attachments && cash.attachments.map((attach) => (
                    <Grid
                      key={attach.name}
                      container
                      justify="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      className={classes.document}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <File size={18} style={{ marginRight: 16 }} />
                        {attach.label}
                      </div>
                      <Tooltip title="Download attachment">
                        <IconButton color="primary" onClick={() => window.open(attach.url)}>
                          <Download size={18} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Loading ready={!!cash} content={200} />
        )}

        {openDelete && (
          <ConfirmDialog
            title="Do you really want to delete this account?"
            onConfirm={() => {
              dispatch(Log.store(`Deleted an ${cash.type === 'in' ? 'inflow' : 'outflow'} of ${
                numberToReal(cash.value)
              }`));
              dispatch(CashFlow.destroy(cashId));
              setOpenDelete(false);
              history.goBack();
            }}
            onClose={() => setOpenDelete(false)}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {cash && permissionEdit && (
          <Grid item>
            {!cash.completed.on && cash.created === 'manual' && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.btnRed}
                onClick={() => setOpenDelete(true)}
              >
                Delete
              </Button>
            )}
            {!cash.completed.on ? (
              <>
                {!cash.completed.status ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={cash.type === 'in' ? classes.btnGreen : classes.btnRed}
                    onClick={() => {
                      if (history.location.pathname.indexOf('dashboard') >= 0) {
                        history.push(`/dashboard/${eventId}/${
                          cash.type === 'in' ? 'receive' : 'pay'
                        }/${cash.id}`);
                      } else {
                        history.push(`/manager/${eventId}/financial/${
                          cash.type === 'in' ? 'receive' : 'pay'
                        }/${cash.id}`);
                      }
                    }}
                  >
                    {cash.type === 'in' ? 'RECEIVE' : 'PAY'}
                  </Button>
                ) : (
                  <>
                    <Grid container justify="center">
                      <Typography>Awaiting payment confirmation</Typography>
                    </Grid>
                    <Grid container justify="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btnGreen}
                        onClick={confirmPayment}
                      >
                        <Check size={18} style={{ marginRight: 8 }} />
                        Confirm
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btnRed}
                        onClick={() => setReopen(cash)}
                      >
                        <X size={18} style={{ marginRight: 8 }} />
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                className={classes.btn}
                disabled={!suppliers}
                onClick={() => setReopen(cash)}
              >
                {!suppliers ? (
                  <CircularProgress size={18} style={{ marginRight: 16 }} />
                ) : (
                  <CornerUpLeft size={18} style={{ marginRight: 16 }} />
                )}
                {`Reopen an ${cash.type === 'in' ? 'inflow' : 'outflow'}`}
              </Button>
            )}

            {reopen && (
              <ConfirmDialog
                title={`Are you sure you want to reopen this ${
                  reopen.type === 'in' ? 'inflow' : 'outflow'
                }?`}
                onConfirm={openCash}
                onClose={() => setReopen(null)}
              />
            )}
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CashFlowForm;
