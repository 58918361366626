import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { gantt } from 'dhtmlx-gantt';
import { format, lastDayOfWeek, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import styles from './style';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

function Chart({ tasks, viewMode, onChange, scrollPos, setScrollPos }) {
  const classes = styles();
  const history = useHistory();

  const ganttContainer = useRef(null);

  const { current: event } = useSelector((store) => store.event);

  const formatWeeks = (date) => {
    const startStr = format(date, 'dd MMM', { locale: ptBR });
    const end = lastDayOfWeek(date);
    const endStr = format(end, 'dd MMM', { locale: ptBR });
    return `${startStr} - ${endStr}`;
  };

  useEffect(() => {
    gantt.config.xml_date = '%Y-%m-%d %H:%i';
    gantt.config.start_on_monday = false;
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Days',
          scale_height: 80,
          min_column_width: 80,
          scales: [
            { unit: 'month', step: 1, format: '%F of %Y' },
            { unit: 'day', step: 1, format: '<strong>%d %M</strong>%D' },
          ],
        },
        {
          name: 'Weeks',
          scale_height: 80,
          min_column_width: 32,
          scales: [
            { unit: 'month', step: 1, format: '%F of %Y' },
            { unit: 'week', step: 1, format: formatWeeks },
            { unit: 'day', step: 1, format: '%D' },
          ],
        },
        {
          name: 'Months',
          scale_height: 80,
          min_column_width: 50,
          scales: [
            { unit: 'month', step: 1, format: '%F of %Y' },
          ],
        },
      ],
    });

    gantt.locale = {
      date: {
        month_full: ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
          'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'],
        month_short: ['jan', 'feb', 'mar', 'apr', 'may', 'jun',
          'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        day_full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
          'Thursday', 'Friday', 'Saturday'],
        day_short: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      },
      labels: {
        /* link confirmation */
        link: 'Link',
        confirm_link_deleting: 'will be deleted',
        link_start: '(start)',
        link_end: '(end)',

        type_task: 'Task',
        type_project: 'Project',
        type_milestone: 'Milestone',


        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        weeks: 'Weeks',
        months: 'Months',
        years: 'Years',
      },
    };

    gantt.templates.timeline_cell_class = (task, date) => {
      if (date.getDay() === 0 || date.getDay() === 6) {
        return classes.weekend;
      }
      return '';
    };

    gantt.showLightbox = (id) => history.push(`/planner/${event.id}/action-plan/tasks/${id}`);

    gantt.createDataProcessor((type, action, item, id) => new Promise(
      (resolve) => {
        onChange(type, action, item, id);
        return resolve();
      },
    ));

    return () => { gantt.clearAll() };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { x, y } = gantt.getScrollState();
    if (y !== scrollPos) gantt.scrollTo(x, scrollPos);

    gantt.attachEvent("onGanttScroll", () => {
      const { y } = gantt.getScrollState();
      if (scrollPos !== y) setScrollPos(y);
    });
  }, [scrollPos, setScrollPos]);

  useEffect(() => {
    if (event && tasks.data.length > 0) {
      const [firstTask, ...rest] = tasks.data.filter((t) => t.start_date)
        .sort((a, b) => a.start_date - b.start_date);
      const [lastTask] = rest.reverse();

      let start = new Date();
      let end = event.dates.end.toDate();

      if (firstTask && firstTask.start_date < start) {
        start = firstTask.start_date;
      }

      if (lastTask) {
        const endTask = addDays(lastTask.start_date, lastTask.duration);
        if (endTask > end) end = endTask;
      }

      gantt.config.start_date = start;
      gantt.config.end_date = end;
      gantt.config.show_tasks_outside_timescale = true;
    }
  }, [event, tasks]);

  useEffect(() => {
    gantt.init(ganttContainer.current);
  }, [ganttContainer]);

  useEffect(() => {
    gantt.clearAll();
    gantt.parse(tasks);
  }, [tasks]);

  useEffect(() => {
    gantt.ext.zoom.setLevel(viewMode);
  }, [viewMode]);

  return (
    <div ref={ganttContainer} style={{ width: '100%', height: '100%' }} />
  );
}

Chart.propTypes = {
  tasks: PropTypes.shape({
    data: PropTypes.array,
    links: PropTypes.array,
  }).isRequired,
  viewMode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Chart;
