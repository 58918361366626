import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI components
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Controllers
import useEventController from 'store/event/controller';
import Step from 'store/productivity/step/controller';
import Task from 'store/productivity/task/controller';
import Label from 'store/productivity/label/controller';
import Checklist from 'store/productivity/checklist/controller';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Filter, Settings, Search, Plus, X } from 'react-feather';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import FilterDialog from './components/filter';
import Kanban from './kanban';
import Timeline from './timeline';
import styles from './style';

function Negotiations() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const filterResponsibles = urlParams.get('responsibles');

  // Controller
  const Event = useEventController(dispatch);

  const { eventId } = useParams();

  const { current: event, checklist } = useSelector((state) => state.event);
  const { current: company, steps, tasks, labels } = useSelector((state) => state.company);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterBadge, setFilterBadge] = useState(0);
  const [viewMode, setViewMode] = useState('timeline');
  const [userFilter, setUserFilter] = useState(filterResponsibles ? 'team' : 'me');
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const permissionView = company.plan !== 'free';

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const filters = [
      'products',
      'categories',
      'priorities',
      'responsibles',
      'valueMin',
      'currentBiggerForecast',
      'paymentLate',
      'deliveryLate',
    ];

    let badge = 0;
    filters.forEach((f) => {
      if (params.get(f)) badge += 1;
    });

    setFilterBadge(badge);
  }, [history.location.search]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
    }
  }, [event, eventId, dispatch, Event]);

  useEffect(() => {
    if (!tasks) dispatch(Task.index());
    if (!labels) dispatch(Label.index());
    if (!steps) dispatch(Step.index());
  }, [tasks, labels, steps, dispatch]);

  useEffect(() => {
    if (!checklist) dispatch(Checklist.index(eventId));
  }, [checklist, eventId, dispatch]);

  const toolbarIcons = [
    {
      id: 'settings',
      label: 'Settings',
      icon: <Settings />,
      click: () => history.push(`/planner/${eventId}/productivity/tasks/settings`),
    },
  ];

  const tabsToolbar = [
    { title: 'Dashboard', path: `/planner/${eventId}/productivity` },
    { title: 'Tasks', selected: true },
  ];

  const viewModes = [
    { id: 'timeline', name: 'Timeline' },
    { id: 'kanban', name: 'Kanban' },
  ];

  const userFilters = [
    { id: 'me', name: 'My tasks' },
    { id: 'team', name: 'Team tasks' },
  ];

  let matchTab = false;
  let matchTabFilter = false;

  return (
    <div className={classes.containerPage}>
      <Toolbar
        avatar
        defaultIcons
        position="fixed"
        sidebar="/planner/productivity"
        tabs={tabsToolbar}
        icons={toolbarIcons}
        title="PRODUCTIVITY"
      />

      <div style={{ height: 100,  display: mobile ? 'block' : 'none' }} />

      <Grid container className={classes.container} wrap="nowrap" style={{ display: mobile ? 'none' : 'flex' }}>
        <Grid container className={classes.tabsContainer} wrap="nowrap">
          <Fab
            color="primary"
            style={{ marginRight: 24, width: 48, height: 48, boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)' }}
            onClick={() => history.push(`/planner/${eventId}/productivity/tasks/new`)}
          >
            <Plus size={20} />
          </Fab>
          <div className={classes.tabs}>
            {viewModes.map((tab, i) => {
              const index = matchTab ? viewModes.length - i : i;
              const match = viewMode === tab.id;
              if (match) matchTab = true;

              let padding = '16px 18px';
              if (!match) {
                if (!matchTab) padding = '16px 60px 16px 18px';
                else padding = '16px 18px 16px 60px';
              }

              return (
                <button
                  key={tab.id}
                  type="button"
                  className={match ? classes.tabSelected : classes.tab}
                  style={{
                    zIndex: match ? viewModes.length : index,
                    padding,
                    marginLeft: !match && (matchTab ? '-48px' : 0),
                    marginRight: !match && (!matchTab ? '-48px' : 0),
                  }}
                  onClick={!match ? () => setViewMode(tab.id) : null}
                >
                  <Typography className={classes.tabTitle} style={{ lineHeight: 1 }}>
                    {tab.name}
                  </Typography>
                </button>
              );
            })}
          </div>

          <div style={{ right: 0, position: 'absolute', display: 'flex' }}>
            <Fab
              color="primary"
              variant="extended"
              style={{ height: 48, boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)' }}
              onClick={() => {
                setShowSearch(true);
                document.getElementById('search').focus();
              }}
            >
              <Search size={20} />
            </Fab>
            <div
              className={classes.tab}
              style={{
                display: 'flex',
                marginRight: 24,
                alignItems: 'center', 
                maxWidth: showSearch ? 300 : 1,
                padding: showSearch ? '12px 32px 12px 70px' : '0 24px',
                transition: 'all 0.2s ease-in'
              }}
            >
              <TextField
                id="search"
                placeholder="Task name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={classes.search}
              />
              <X
                size={16}
                className={classes.searchClose}
                onClick={() => {
                  setShowSearch(false);
                  setSearch('');
                }}
              />
            </div>

            <Fab
              color="primary"
              style={{ marginRight: 24, width: 48, height: 48, boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)' }}
              onClick={() => setOpenFilter(true)}
            >
              <Badge color="secondary" badgeContent={filterBadge} classes={{ badge: classes.badge }}>
                <Filter size={20} />
              </Badge>
            </Fab>
            <div className={classes.tabs}>
              {userFilters.map((tab, i) => {
                const index = matchTabFilter ? userFilters.length - i : i;
                const match = userFilter === tab.id;
                if (match) matchTabFilter = true;

                let padding = '16px 18px';
                if (!match) {
                  if (!matchTabFilter) padding = '16px 60px 16px 18px';
                  else padding = '16px 18px 16px 60px';
                }

                return (
                  <button
                    key={tab.id}
                    type="button"
                    className={match ? classes.tabSelected : classes.tab}
                    style={{
                      zIndex: match ? userFilters.length : index,
                      padding,
                      marginLeft: !match && (matchTabFilter ? '-48px' : 0),
                      marginRight: !match && (!matchTabFilter ? '-48px' : 0),
                    }}
                    onClick={!match ? () => setUserFilter(tab.id) : null}
                  >
                    <Typography className={classes.tabTitle} style={{ lineHeight: 1 }}>
                      {tab.name}
                    </Typography>
                  </button>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      {viewMode === 'timeline' && <Timeline userFilter={userFilter} search={search} />}
      {viewMode === 'kanban' && <Kanban userFilter={userFilter} search={search} />}

      {openFilter && <FilterDialog onClose={() => setOpenFilter(false)} />}

      <Onboarding id="plan" title="Plan your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="plan"
          frameborder="0"
          src="https://www.youtube.com/embed/lGYodgSyAL8"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      <Tools />
    </div>
  );
}

export default Negotiations;
