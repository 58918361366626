import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Check, X } from 'react-feather';
import { getCurrentTotal } from 'common/utils/suppliers';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import styles from './style';

// Components
import Products from './products';
import Exchange from './exchange';

const INITIAL_BUDGET = {
  discount: { value: 0, type: 'money' },
  exchange: { value: 0, description: '' },
  freight: 0,
  products: {},
  total: 0,
};

function Budget({ supplier, edit, setEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [budget, setBudget] = useState(INITIAL_BUDGET);
  const [products, setProducts] = useState([]);
  const [enableConfirm, setEnableConfirm] = useState(false);

  const load = useCallback(() => {
    setBudget(_.cloneDeep(supplier.budget) || INITIAL_BUDGET);
    setProducts(_.cloneDeep(supplier.products));
  }, [supplier]);

  useEffect(() => {
    load();
  }, [supplier, load]);

  useEffect(() => {
    let isValid = true;

    // Budget validations
    if (budget.total < 0) isValid = false;
    if (Object.keys(budget.products).length === 0) isValid = false;
    products.forEach((p) => {
      if (!budget.products[p.id]) isValid = false;
      else if (budget.products[p.id].value === 0 || budget.products[p.id].amount === 0) {
        isValid = false;
      }
    });

    setEnableConfirm(isValid);
  }, [budget, products]);

  const cancel = () => {
    load();
    setEdit(false);
  };

  const submit = () => {
    dispatch(Log.store(`Changed the supplier ${supplier.name}`));
    dispatch(
      Supplier.update(supplier.id, {
        budget: { ...budget, total: getCurrentTotal({ budget }) },
        products: products.map((product) => product.id),
      }),
    );
    setEdit(false);
  };

  const forecast = products ? products.reduce((c, p) => c + p.valueForecast, 0) : 0;
  const subTotal = getCurrentTotal({ budget });

  return (
    <Grid container justify="center">
      <Products
        edit={edit}
        budget={budget}
        products={products}
        onChange={({ newBudget, newProducts }) => {
          if (newBudget) {
            const newTotal = getCurrentTotal({ budget: newBudget });
            setBudget({ ...newBudget, total: newTotal });
          }
          if (newProducts) setProducts(newProducts);
        }}
      />

      <Exchange edit={edit} budget={budget} onChange={setBudget} />

      <Grid container item xs={12} alignItems="center" direction="column">
        <Typography className={classes.titleTotal}>SUBTOTAL</Typography>
        <Tooltip
          title={`${subTotal > forecast ? 'Over' : 'Within'} the predicted value of ${numberToReal(
            forecast,
          )}`}
        >
          <Typography
            className={`${classes.total} ${
              subTotal > forecast ? classes.colorError : classes.colorSuccess
            }`}
          >
            {numberToReal(subTotal)}
          </Typography>
        </Tooltip>
      </Grid>

      {edit && (
        <Grid container justify="center" className={classes.containerBtns}>
          <Button
            color="secondary"
            variant="contained"
            onClick={cancel}
            className={classes.buttonCancel}
          >
            <X size={16} style={{ marginRight: 8 }} />
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={submit}
            className={classes.buttonConfirm}
            disabled={!enableConfirm}
          >
            <Check size={16} style={{ marginRight: 8 }} />
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

Budget.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    budget: PropTypes.object,
    payment: PropTypes.object,
    delivery: PropTypes.object,
    products: PropTypes.array,
    interrupted: PropTypes.bool,
    status: PropTypes.number,
  }).isRequired,
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setEdit: PropTypes.func.isRequired,
};

export default Budget;
