import { Model } from '@blink-mind/core';

export const downloadFile = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export function generateSimpleModel(data) {
  const topics = [];

  data.forEach((topic) => {
    const subKeys = data.filter((d) => d.parent === topic.id).map((d) => d.id);
    topics.push({
      key: topic.id,
      parentKey: topic.parent || null,
      subKeys,
      collapse: false,
      style: null,
      blocks: [{ type: 'CONTENT', data: topic.label }],
    },)
  });

  const theme = {
    name: 'default',
    randomColor: false,
    background: '#FFFFFF',
    highlightColor: '#77c868',
    marginH: 60,
    marginV: 20,
    contentStyle: {
      lineHeight: '1.5'
    },
    linkStyle: {
      lineRadius: 5,
      lineType: 'curve',
      lineWidth: '3px'
    },
    rootTopic: {
      contentStyle: {
        border: 'none',
        fontSize: '18px',
        borderRadius: '50px',
        padding: '8px 24px',
        background: '#18630B',
        color: 'white',
      },
      subLinkStyle: {
        lineType: 'curve',
        lineWidth: '4px',
        lineColor: '#B6F0AC'
      }
    },
    primaryTopic: {
      contentStyle: {
        border: 'none',
        borderRadius: '50px',
        fontSize: '14px',
        padding: '10px 15px',
        background: '#77c868',
        color: 'white',
      },
      subLinkStyle: {
        lineType: 'curve',
        lineWidth: '2px',
        lineColor: '#B6F0AC'
      }
    },
    normalTopic: {
      contentStyle: {
        border: 'none',
        borderRadius: '20px',
        fontSize: '12px',
        padding: '4px 12px',
      },
      subLinkStyle: {
        lineType: 'curve',
        lineWidth: '2px',
        lineColor: '#B6F0AC'
      }
    }
  };

  return Model.create({
    rootTopicKey: 'root',
    config: { theme },
    topics,
  });
}
