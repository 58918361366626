import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// Material Components
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import useEventController from 'store/event/controller';

// Commons, Assets and Styles
import { Filter } from 'react-feather';
import { Suppliers, Financial as FinancialIcon, Partners } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import TabsTable from './components/tabs-table';
// import Tooltip from 'common/tooltip';
import styles from './style';

// Components
import Balance from './components/balance';
import FilterFinancial from './components/filter';
import CashFlowChart from './components/chart';
// import ReportsTable from './components/reports-table';

function Financial() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Event = useEventController(dispatch);

  const history = useHistory();
  const { eventId } = useParams();

  const { current: event } = useSelector((store) => store.event);
  const { current: { permissions } } = useSelector((store) => store.user);
  const { current: company, cashFlow } = useSelector((store) => store.company);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterBadge, setFilterBadge] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const permissionView = company.plan !== 'free' && company.plan !== 'planner' && (
    !permissions || !permissions.manager || permissions.manager.financial.view
  );

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
      setIsLoading(false);
    }
  }, [event, eventId, dispatch, Event]);

  // Load data to use in this module
  useEffect(() => {
    if (event && !isLoading) {
      if (!cashFlow) dispatch(CashFlow.index());
      setIsLoading(true);
    }
  }, [event, cashFlow, dispatch, isLoading]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const filters = [
      'onlyType',
      'dateMin',
      'categories',
      'costCenter',
      'valueMin',
      'dueLate',
    ];

    let badge = 0;
    filters.forEach((filter) => {
      if (params.get(filter)) badge += 1;
    });

    setFilterBadge(badge);
  }, [history.location.search]);

  const toolbarIcons = [
    {
      label: 'Filter',
      icon: <Filter />,
      badge: filterBadge,
      click: () => setOpenFilter(true),
    },
  ];

  return (
    <div className={classes.container}>
      <Toolbar
        avatar
        defaultIcons
        title="FINANCIAL"
        position="fixed"
        sidebar="/manager/financial"
        icons={toolbarIcons}
      />

      <Grid
        container
        justify="center"
        className={classes.row}
        style={{ padding: mobile ? '72px 0 40px' : '112px 0 0' }}
      >
        <Balance />
      </Grid>

      <Grid
        container
        justify="center"
        className={classes.row}
        style={{ paddingTop: 0, paddingBottom: 40 }}
      >
        <CashFlowChart />
      </Grid>

      <TabsTable />

      <Tools />

      <Onboarding id="manager" title="Follow the management of your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="manager"
          frameborder="0"
          src="https://www.youtube.com/embed/x17RaEoauh0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      {openFilter && <FilterFinancial onClose={() => setOpenFilter(false)} />}
    </div>
  );
}

export default Financial;
