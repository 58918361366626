import React /* , { useState, useEffect } */ from 'react';
// import _ from 'lodash';
import { useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
// import banks from 'services/brazilian-banks.json';

// import { addDays } from 'date-fns';
import { acceptBanks } from 'services/tecnospeed';
import styles from './style';

// const INITIAL_ACCOUNT_TYPES = [
//   { value: 'CONTA_CORRENTE', label: 'Conta corrente' },
//   { value: 'CONTA_POUPANCA', label: 'Conta poupança' },
// ];

function FormConfig({
  payed /* , method, setAutoPayment */, setMethod,
}) {
  const classes = styles();
  const location = useLocation();
  const { eventId, cashId } = useParams();

  const { accounts } = useSelector((store) => store.company);

  // const [bank, setBank] = useState('');
  // const [accountTypes, setAccountTypes] = useState(INITIAL_ACCOUNT_TYPES);

  // useEffect(() => {
  //   const types = [...INITIAL_ACCOUNT_TYPES];
  //   if (bank === '104' || bank === '001') {
  //     types.push({ value: 'CONTA_FACIL', label: 'Conta fácil' });
  //   }
  //   if (bank === '104') {
  //     types.push({ value: 'ENTIDADES_PUBLICAS', label: 'Entidades públicas' });
  //   }
  //   setAccountTypes(types);
  // }, [bank]);

  return [
    {
      name: 'account',
      type: 'select',
      label: 'Account',
      className: classes.accountMenu,
      options: accounts
        ? accounts.map((a) => ({
          value: a.id,
          className: classes.accountMenu,
          label: (
            <>
              {acceptBanks.find((b) => b.code === a.bank).name}
              <span>{`${a.agency} / ${a.code}`}</span>
            </>
          ),
        }))
        : [],
      addOption: {
        label: 'Add account',
        url: location.pathname.indexOf('dashboard') >= 0
          ? '/dashboard/accounts/new'
          : `/manager/${eventId}/financial/accounts/new`,
      },
      // onChange: (value, state) => {
      //   const account = accounts.find((a) => a.id === value);
      //   if (account) setAutoPayment(account.autoPayment);
      //   return state;
      // },
    },
    {
      name: 'payment.method',
      type: 'select',
      label: 'Method',
      options: payed ? [
        { label: 'Bill', value: 'billet' },
        { label: 'Bank transfer', value: 'transference' },
        { label: 'Credit / Debit Card', value: 'card' },
        { label: 'Cash', value: 'cash' },
      ] : [
        { label: 'Bill', value: 'billet' },
        { label: 'Transfer', value: 'transference' },
      ],
      onChange: (value, state) => {
        setMethod(value);
        return state;
      },
      validation: { required: 'Select the payment method' },
    },
    // {
    //   name: 'payment.billet',
    //   type: 'text',
    //   label: 'Código do boleto',
    //   accept: /[\d]*/g,
    //   maxLength: 48,
    //   onChange: (value, state) => {
    //     if (value.trim().length === 47) {
    //       const infos = value.slice(-14);

    //       const valueString = infos.slice(-10);
    //       const payValue = parseFloat(`${valueString.slice(0, 8)}.${valueString.slice(8)}`);
    //       _.set(state, 'completed.value', payValue);

    //       const dateNumber = parseInt(infos.slice(0, 4), 10);
    //       const dateBase = new Date('1997-10-07 23:59:59.999');
    //       const payAt = addDays(dateBase, dateNumber);
    //       _.set(state, 'completed.date', payAt);
    //     }
    //     return state;
    //   },
    //   validation:
    //     method === 'billet' ? { required: 'Digite o código do boleto' } : {},
    // },
    // {
    //   name: 'payment.transference.name',
    //   type: 'text',
    //   label: 'Nome do titular',
    //   validation:
    //     method === 'transference'
    //       ? { required: 'O nome do titular é obrigatório para efeturar a transferência' }
    //       : {},
    // },
    // {
    //   name: 'payment.transference.cpfCnpj',
    //   type: 'text',
    //   mask: 'document',
    //   label: 'CPF / CNPJ',
    //   validation:
    //     method === 'transference'
    //       ? {
    //         required: 'O CPF ou CNPJ é obrigatório para efeturar a transferência',
    //         pattern: {
    //           msg: 'O documento não é válido',
    //           params: {
    //             regex: /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
    //           },
    //         },
    //       }
    //       : {},
    // },
    // {
    //   name: 'payment.transference.bankName',
    //   type: 'autosuggest',
    //   label: 'Banco',
    //   placeholder: 'Pesquise pelo banco',
    //   match: 'anyPosition',
    //   keyList: 'slug',
    //   list: banks.map((b) => ({ ...b, slug: `${b.code} - ${b.name}` })),
    //   onChange: (value, state) => {
    //     if (value.code) {
    //       setBank(value.code);
    //       _.set(state, 'payment.transference.bankCode', value.code);
    //       _.set(state, 'payment.transference.bankName', value.name);
    //     } else {
    //       setBank('');
    //       _.set(state, 'payment.transference.bankCode', '');
    //       _.set(state, 'payment.transference.bankName', value);
    //     }
    //     return state;
    //   },
    // },
    // {
    //   name: 'payment.transference.bankCode',
    //   type: 'text',
    //   validation:
    //     method === 'transference'
    //       ? { required: 'Selecione o banco do destinatário' }
    //       : {},
    // },
    // {
    //   name: 'payment.transference.agency',
    //   type: 'text',
    //   label: 'Agência',
    //   accept: /^[0-9]+(=?-\d?|-|)/g,
    //   validation:
    //     method === 'transference'
    //       ? { required: 'A agência é obrigatória' }
    //       : {},
    // },
    // {
    //   name: 'payment.transference.accountNumber',
    //   type: 'text',
    //   label: 'Conta',
    //   accept: /^[0-9]+(=?-\d?|-|)/g,
    //   validation:
    //     method === 'transference'
    //       ? { required: 'A conta é obrigatória' }
    //       : {},
    // },
    // {
    //   name: 'payment.transference.accountType',
    //   type: 'select',
    //   label: 'Tipo da Conta',
    //   options: accountTypes,
    //   validation:
    //     method === 'transference'
    //       ? { required: 'Selecione o tipo da conta' }
    //       : {},
    // },
    {
      name: 'completed.value',
      type: 'currency',
      label: 'Value',
      validation: { required: 'Value to be paid' },
    },
    {
      name: 'completed.date',
      type: 'datetime',
      keyboard: true,
      label: 'Date',
      pickerProps: { disableFuture: payed },
      validation: {
        required: 'Choose the dates to be paid',
        future: payed && 'Date must be less than or equal to today',
        past: !payed && 'Date must be greater than or equal to today',
      },
    },
    // {
    //   name: 'completed.status',
    //   type: 'hidden',
    //   value: payed,
    //   onChange: (value, state) => {
    //     if (!value && method === 'cash') _.set(state, 'payment.method', 'billet');
    //     return state;
    //   },
    // },
    {
      name: 'completed.on',
      type: 'switch',
    },
    {
      name: 'completed.receipt',
      type: 'upload',
      inputProps: {
        path: `/financial-temp/${eventId}/cash-flow/receipts`,
        formats: 'document/*;image/*',
        defaultDocuments: [{ label: 'Attach receipt', name: cashId }],
      },
      render: (documents) => documents,
    },
  ];
}

export default FormConfig;
