import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { DollarSign } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
// import { getCurrentTotal } from 'common/utils/suppliers';
import styles from './style';

function Payed({ contact }) {
  const classes = styles();

  const { delivery } = contact;

  // const total = getCurrentTotal(contact);
  const { total } = contact.payment;

  let message = null;

  if (delivery) {
    const { estimated, done } = delivery;
    if (done) {
      const date = format(done.toDate(), 'dd MMM yyyy', { locale: ptBR });
      message = `Delivered ${date}`;
    } else if (estimated) {
      const date = format(estimated.toDate(), 'dd MMM yyyy', { locale: ptBR });
      message = `Delivery scheduled for ${date}`;
    }
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.moreContent}>
        <DollarSign size={16} className={classes.moreInfoIcon} />
        <Typography className={classes.moreInfoSuccess}>
          {numberToReal(total)}
        </Typography>
      </Grid>
      {message && (
        <Grid container alignItems="center" className={classes.moreContent}>
          <Typography variant="overline" className={classes.moreInfoTitle}>
            {message}
          </Typography>
        </Grid>
      )}
    </>
  );
}

Payed.propTypes = {
  contact: PropTypes.shape({
    payment: PropTypes.object,
    delivery: PropTypes.object,
  }).isRequired,
};

export default Payed;
