import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X, Check } from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

// Components
import Labels from './labels';
import Responsibles from './responsibles';
import Steps from './steps';
import Priority from './priority';
import Subtask from './subtask';
import Dates from './dates';
import Checklists from './checklists';
import Attachments from './attachments';

function SupplierView() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { taskId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    users, steps, labels, tasks,
  } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [isLoaded, setIsLoaded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editText, setEditText] = useState('');
  const [editError, setEditError] = useState(false);
  const [task, setTask] = useState(null);
  const [taskDel, setTaskDel] = useState(false);

  const permissionEdit = !permissions || !permissions.productivity
    || permissions.productivity.edit;

  useEffect(() => {
    setIsLoaded(!!steps && !!labels && !!tasks && !!checklist);
  }, [checklist, steps, labels, tasks]);

  useEffect(() => {
    if (taskId && isLoaded) {
      const data = _.cloneDeep(tasks.find((t) => t.id === taskId));

      if (data) {
        data.step = steps.find((s) => s.id === data.step);
        data.responsibles = data.responsibles.map((r) => users.find((u) => u.id === r));
        data.parent = checklist.find((c) => c.id === data.parent);
        data.labels = data.labels.map((lId) => labels.find((l) => l.id === lId));
        data.startAt = data.startAt.toDate();
        data.endAt = data.endAt.toDate();
        data.workingAt = data.workingAt ? data.workingAt.toDate() : null;
        data.completedAt = data.completedAt ? data.completedAt.toDate() : null;
      } else {
        history.goBack();
      }

      setTask(data);
    }
  }, [checklist, history, isLoaded, labels, steps, taskId, tasks, users]);

  const submitName = (e) => {
    if (e) e.preventDefault();

    if (editText === '') {
      setEditError(true);
    } else {
      dispatch(Log.store(`Changed the name of task "${task.name}" to "${editText}"`));
      dispatch(Task.update(taskId, { name: editText }));
      setEdit(false);
      setEditText('');
    }
  };

  const submitDescription = (e) => {
    if (e) e.preventDefault();
    dispatch(Log.store(`Changed the description of task "${task.name}"`));
    dispatch(Task.update(taskId, { description: editText }));
    setEdit(false);
    setEditText('');
  };

  const removeTask = () => {
    dispatch(Log.store(`Removed task "${task.name}"`));
    dispatch(Task.destroy(task.id));
    history.goBack();
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      scroll="body"
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="view-supplier-dialog"
    >
      <DialogContent className={classes.dialogContent}>
        {task ? (
          <Grid container>
            <Grid container justify="space-between" wrap="nowrap" alignItems="flex-start">
              <Grid container direction="column" alignItems="flex-start">
                <Steps id={task.id} name={task.name} workingAt={task.workingAt} step={task.step} />
                <Priority id={task.id} name={task.name} priority={task.priority} />
              </Grid>
              <IconButton onClick={history.goBack} aria-label="Sair">
                <X />
              </IconButton>
            </Grid>
            <Grid container style={{ margin: '32px 0 16px' }}>
              {edit !== 'name' ? (
                <Typography
                  className={classes.taskName}
                  onClick={permissionEdit ? () => {
                    setEdit('name');
                    setEditText(task.name);
                  } : () => {}}
                >
                  {task.name}
                </Typography>
              ) : (
                <form onSubmit={submitName} style={{ width: '100%' }}>
                  <TextField
                    autoFocus
                    fullWidth
                    multiline
                    size="small"
                    variant="outlined"
                    error={editError}
                    InputProps={{ className: classes.taskName }}
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 27) {
                        setEdit(false);
                        setEditText('');
                      }
                      if (e.keyCode === 13) {
                        submitName(e);
                      }
                    }}
                  />
                  <Grid container>
                    <Button onClick={() => submitName()} className={classes.btnGreen}>
                      <Check size={16} style={{ marginRight: 8 }} />
                      Save
                    </Button>
                    <Button
                      className={classes.btnRed}
                      onClick={() => {
                        setEdit(false);
                        setEditText('');
                      }}
                    >
                      <X size={16} style={{ marginRight: 8 }} />
                      Cancelar
                    </Button>
                  </Grid>
                </form>
              )}
            </Grid>
            <Grid container justify="space-between" wrap="nowrap" alignItems="center" style={{ margin: '16px 0' }}>
              <Labels id={task.id} name={task.name} labels={task.labels} />
              <Responsibles id={task.id} name={task.name} responsibles={task.responsibles} />
            </Grid>
            <Grid container style={{ margin: '16px 0 32px' }}>
              <Subtask id={task.id} name={task.name} parent={task.parent} />
            </Grid>
            <Grid container style={{ marginBottom: 32 }}>
              <Dates
                id={task.id}
                name={task.name}
                dates={{
                  startAt: task.startAt,
                  endAt: task.endAt,
                  workingAt: task.workingAt,
                  completedAt: task.completedAt,
                  duration: task.duration,
                }}
              />
            </Grid>
            <Grid container direction="column" style={{ marginBottom: 32 }}>
              <Typography className={classes.title} style={{ marginBottom: 8 }}>
                DESCRIPTION
              </Typography>
              {edit !== 'description' ? (
                <Typography
                  onClick={permissionEdit ? () => {
                    setEdit('description');
                    setEditText(task.description);
                  } : () => {}}
                  style={{ color: '#777', whiteSpace: 'pre-line', lineHeight: 1.5, cursor: 'pointer' }}
                >
                  {task.description || 'Adicione uma descrição'}
                </Typography>
              ) : (
                <form onSubmit={submitDescription} style={{ width: '100%' }}>
                  <TextField
                    autoFocus
                    multiline
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    InputProps={{ style: { lineHeight: 1.5 } }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 27) {
                        setEdit(false);
                        setEditText('');
                      }
                      if (e.keyCode === 13 && !e.shiftKey) {
                        submitDescription(e);
                      }
                    }}
                  />
                  <Grid container>
                    <Button onClick={submitDescription} className={classes.btnGreen}>
                      <Check size={16} style={{ marginRight: 8 }} />
                      Save
                    </Button>
                    <Button
                      className={classes.btnRed}
                      onClick={() => {
                        setEdit(false);
                        setEditText('');
                      }}
                    >
                      <X size={16} style={{ marginRight: 8 }} />
                      Cancel
                    </Button>
                  </Grid>
                </form>
              )}
            </Grid>
            <Grid container>
              <Checklists id={task.id} name={task.name} checklists={task.checklists} />
            </Grid>
            <Grid container>
              <Attachments id={task.id} name={task.name} attachments={task.attachments} />
            </Grid>
            <Grid container justify="center" style={{ marginTop: 32 }}>
              {!task.workingAt && !task.completedAt && (
                <Button className={classes.btnRed} variant="outlined" onClick={() => setTaskDel(true)}>
                  REMOVED TASK
                </Button>
              )}
            </Grid>

            {taskDel && (
              <ConfirmDialog
                title="Do you really want to delete this task?"
                onConfirm={removeTask}
                onClose={() => setTaskDel(false)}
              />
            )}
          </Grid>
        ) : (
          <Loading ready={!!task} content={400} />
        )}
      </DialogContent>
    </Dialog>
  );
}
export default SupplierView;
