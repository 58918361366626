import React, { useState } from 'react';
import _ from 'lodash';
import matchHighlight from 'autosuggest-highlight/match';
import parseHighlight from 'autosuggest-highlight/parse';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { acceptBanks } from 'services/tecnospeed';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { Paperclip } from 'react-feather';
import styles from './style';

function FormConfig({
  edit, type, completed, repeat, created,
}) {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const {
    categories, accounts, costCenter, contacts,
  } = useSelector((store) => store.company);

  const [emmittedAt, setEmmittedAt] = useState(null);
  const [completedOn, setCompletedOn] = completed;
  const [repeatOn, setRepeatOn] = repeat;

  const typeLabel = type === 'in' ? 'in' : 'out';

  const getCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        getChildren(c, `${label} > ${c.name}`);
      });
    };

    if (categories) {
      categories.filter((c) => c.type === type && !c.parent).forEach((category) => {
        cat.push(category);
        getChildren(category, category.name);
      });
    }

    return cat;
  };

  return [
    {
      name: 'type',
      type: 'hidden',
      value: type,
      onChange: (value, state) => {
        if (!edit) _.set(state, 'category', '');
        return state;
      },
    },
    {
      name: 'contact',
      type: 'autosuggest',
      label: type === 'in' ? 'Payer' : 'Payee',
      placeholder: 'Search the contact list',
      keyList: 'name',
      list: contacts,
      disabled: created !== 'manual',
      addMore: {
        label: 'Add contact',
        onClick: () => {
          if (history.location.pathname.indexOf('dashboard') >= 0) {
            history.push('/dashboard/contact');
          } else {
            history.push(`/manager/${eventId}/financial/contact`);
          }
        },
      },
      renderItem: (item, { query, isHighlighted }) => {
        const matches = matchHighlight(item.name, query);
        const parts = parseHighlight(item.name, matches);

        return (
          <MenuItem
            selected={isHighlighted}
            component="div"
            className={item.id && item.id === 'autosuggestAddMore' ? classes.add : ''}
          >
            <Grid container direction="column">
              <Grid container justify="space-between" alignItems="center">
                <p>
                  {parts.map((part, index) => (
                    <span
                      key={String(index)}
                      style={{
                        fontWeight: part.highlight && item.id !== 'autosuggestAddMore' ? 600 : 300,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </p>
                <span style={{ fontSize: 12, color: '#777', fontWeight: 500 }}>
                  {item.document}
                </span>
              </Grid>
              {item.address && item.address.postalCode && (
                <span style={{ fontSize: 10, color: '#777', fontWeight: 300 }}>
                  {/* {`${item.address.neighborhood},  */}
                  {`${item.address.city} - ${item.address.state}`}
                </span>
              )}
            </Grid>
          </MenuItem>
        );
      },
      validation: {
        required: {
          msg: `Select one ${type === 'in' ? 'payer' : 'payee'} or register a new`,
          params: { key: 'name' },
        },
      },
    },
    {
      name: 'categories',
      type: 'select-chip',
      label: <Typography className={classes.title}>CATEGORIES</Typography>,
      className: classes.categoryMenu,
      disabled: created !== 'manual',
      options: getCategories().map((c) => ({
        value: c.id,
        label: (
          <Grid container>
            <div className={classes.categoryColor} style={{ background: c.color }} />
            <div className={classes.categoryLabel}>
              {c.parentLabel && <span>{c.parentLabel}</span>}
              <p>{c.name}</p>
            </div>
          </Grid>
        ),
        chip: { label: c.name },
      })),
      addOption: {
        label: 'Add category',
        url: history.location.pathname.indexOf('dashboard') >= 0
          ? `/dashboard/categories/new?type=${type}`
          : `/manager/${eventId}/financial/categories/new?type=${type}`,
      },
      onChange: (value, state) => {
        const matchCenter = costCenter.filter((c) => {
          const matchEvent = c.events.indexOf(eventId) >= 0 || c.events[0] === 'all';
          const matchCategory = c.categories.indexOf(value) >= 0 || c.categories[0] === 'all';
          return matchEvent && matchCategory;
        });

        if (matchCenter.length > 0) {
          _.set(state, 'costCenter', matchCenter.map((c) => c.id));
        }

        return state;
      },
      validation: { required: `Select a category for ${typeLabel}` },
    },
    {
      name: 'account',
      type: 'select',
      label: 'Account',
      className: classes.accountMenu,
      options: accounts
        ? accounts.map((a) => ({
          value: a.id,
          className: classes.accountMenu,
          label: (
            <>
              {acceptBanks.find((b) => b.code === a.bank).name}
              <span>{`${a.agency} ${a.code}`}</span>
            </>
          ),
        }))
        : [],
      addOption: {
        label: 'Add account',
        url: history.location.pathname.indexOf('dashboard') >= 0
          ? '/dashboard/accounts/new'
          : `/manager/${eventId}/financial/accounts/new`,
      },
    },
    {
      name: 'description',
      type: 'text',
      multiline: true,
      disabled: created !== 'manual',
      label: `Description of ${typeLabel}`,
      validation: { required: `Set a description for ${typeLabel}` },
    },
    {
      name: 'notes',
      type: 'text',
      multiline: true,
      label: 'Observations',
    },
    {
      name: 'value',
      type: 'currency',
      label: 'Value',
      disabled: created !== 'manual',
      validation: { required: `The value of ${typeLabel} is required` },
    },
    {
      name: 'emmittedAt',
      type: 'datetime',
      keyboard: true,
      label: 'Emission',
      onChange: (value, state) => {
        setEmmittedAt(value);
        return state;
      },
    },
    {
      name: 'dueAt',
      type: 'datetime',
      keyboard: true,
      label: 'Due date',
      disabled: created !== 'manual',
      pickerProps: { min: emmittedAt || new Date('1900-01-01') },
      validation: { required: 'Set the due date' },
    },
    {
      name: 'costCenter',
      type: 'select-chip',
      label: (
        <>
          <Typography className={classes.title}>COST CENTER</Typography>
          <Typography className={classes.description}>
          Select which cost centers this event will be part of
          </Typography>
        </>
      ),
      options: costCenter
        ? costCenter
          .sort((a, b) => (a.code ? a.code - b.code : a.name - b.name))
          .map((e) => ({ value: e.id, label: `${e.code ? `${e.code} - ` : ''}${e.name}` }))
        : [],
    },
    {
      name: 'repeat.on',
      type: 'checkbox',
      disabled: completedOn,
      label: <Typography className={classes.heading}>Repeat</Typography>,
      onChange: (value, state) => {
        setRepeatOn(value);
        return state;
      },
    },
    {
      name: 'repeat.type',
      type: 'select',
      label: 'Repeat',
      options: [
        { label: 'Daily', value: 'dayly' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
        { label: 'Yearly', value: 'yearly' },
      ],
      validation: repeatOn && { required: 'Select the repeat type' },
    },
    {
      name: 'repeat.times',
      type: 'text',
      inputType: 'number',
      label: 'Repeat times',
      validation: {
        required: repeatOn,
        min: {
          msg: 'To repeat it is necessary to be at least once',
          params: { min: 1 },
        },
      },
    },
    {
      name: 'completed.on',
      type: 'checkbox',
      disabled: repeatOn,
      label: (
        <Typography className={classes.heading}>
          {type === 'in' ? 'Received' : 'Paid'}
        </Typography>
      ),
      onChange: (value, state) => {
        setCompletedOn(value);
        return state;
      },
    },
    {
      name: 'completed.date',
      type: 'datetime',
      keyboard: true,
      label: 'Date',
      pickerProps: { disableFuture: true },
      validation: completedOn && {
        required: `Set the date of the ${type === 'in' ? 'received' : 'payment'}`,
        future: 'Date must be less than or equal to today',
      },
    },
    {
      name: 'completed.value',
      type: 'currency',
      label: 'Value',
      validation: completedOn && {
        required: `The value ${type === 'in' ? 'received' : 'paid'} is required`,
      },
    },
    {
      name: 'completed.receipt',
      type: 'upload',
      inputProps: {
        path: `/financial-temp/${eventId}/cash-flow/receipts`,
        formats: 'document/*;image/*',
        defaultDocuments: [{ label: 'Attach receipt', name: `${Date.now()}` }],
      },
      render: (documents) => documents,
    },
    {
      name: 'attachments',
      type: 'upload',
      inputProps: {
        path: `/financial-temp/${eventId}/cash-flow/attachments`,
        formats: 'document/*;image/*',
      },
      render: (documents, button) => (
        <>
          <Grid item container alignItems="center" justify="space-between">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Paperclip size={18} style={{ marginRight: 8, width: 24 }} />
              <Typography className={classes.dividerText}>ATTACHMENTS</Typography>
            </div>
            {button}
          </Grid>

          <Grid container item xs={12}>{documents}</Grid>
        </>
      ),
    },
  ];
}

export default FormConfig;
