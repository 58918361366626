import React from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

const Chart = React.memo(
  ({ option, handleEmphasis }) => (
    <ReactEcharts
      option={option}
      style={{ width: 300 }}
      notMerge
      lazyUpdate
      showLoading={false}
      onEvents={{
        mouseover: (e) => handleEmphasis && handleEmphasis(e.data.id),
        mouseout: () => handleEmphasis && handleEmphasis(null),
      }}
      opts={{ renderer: 'svg' }}
    />
  ),
  (prevProps, nextProps) => {
    const { option: prevOption } = prevProps;
    const { option: nextOption } = nextProps;

    return (
      isEqual(prevOption.series[0].data, nextOption.series[0].data)
      && isEqual(prevOption.color, nextOption.color)
    );
  },
);

Chart.propTypes = {
  option: PropTypes.shape({
    series: PropTypes.arrayOf(PropTypes.object),
    color: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleEmphasis: PropTypes.func.isRequired,
};

export default Chart;
