import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Product from 'store/supplier/product/controller';

// Icons, Commons and Styles
import { X, ChevronDown, Plus } from 'react-feather';
import Loading from 'common/loading';
import styles from './style';

function ProductForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { products, categories } = useSelector((state) => state.company);

  const [openMenu, setOpenMenu] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!products) dispatch(Product.indexCompany());
  }, [products, dispatch]);

  const handleExpanded = (product) => {
    if (expanded === product) setExpanded(null);
    else setExpanded(product);
  };

  const addProducts = () => {};

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="view-product-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Select the products you need for this event</span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {products && categories ? (
          <Grid container>
            <Grid container justify="space-between" alignItems="center">
              <Typography className={classes.title}>PRODUCTS</Typography>
              <IconButton
                color="primary"
                onClick={(e) => setOpenMenu(e.currentTarget)}
              >
                <Plus />
              </IconButton>
              <Menu
                anchorEl={openMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!openMenu}
                PaperProps={{ style: { maxHeight: 350, width: 300 } }}
                onClose={() => setOpenMenu(null)}
              >
                {categories
                  .filter((c) => products
                    .filter((p) => !selected.find((s) => s.id === p.id))
                    .find((p) => p.category === c.id))
                  .map((category) => [
                    <ListSubheader
                      key={category.id}
                      className={classes.listHeader}
                      style={{ color: category.color }}
                    >
                      {category.name}
                    </ListSubheader>,
                    products
                      .filter((p) => p.category === category.id
                        && !selected.find((s) => s.id === p.id))
                      .map((product) => (
                        <ListItem
                          button
                          key={product.id}
                          onClick={() => {
                            setSelected([...selected, { ...product, isReady: false }]);
                            setOpenMenu(null);
                          }}
                        >
                          <ListItemText primary={product.name} />
                        </ListItem>
                      )),
                  ])}
              </Menu>
            </Grid>
            {selected.map((product) => (
              <ExpansionPanel
                key={product.id}
                className={classes.expansion}
                expanded={expanded === product.id}
                onChange={() => handleExpanded(product.id)}
              >
                <ExpansionPanelSummary
                  expandIcon={<ChevronDown />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography className={classes.titleExpansion}>
                    {product.name}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography color="textSecondary">
                    The click event of the nested action will propagate up and expand the panel
                    unless you explicitly stop it.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}

          </Grid>
        ) : (
          <Loading ready={!!products} content={250} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={addProducts}
        >
          Add products
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductForm;
