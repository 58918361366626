import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1.25),
  },
  actions: {
    justifyContent: 'center',
  },
  btnRemove: {
    color: theme.palette.error.main,
  },
  menu: {
    maxWidth: 350,
    maxHeight: 400,
  },
  allItem: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  description: {
    color: theme.palette.common.grey,
    fontSize: 11,
  },
  categoryLabel: {
    lineHeight: 1,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 10,
      textTransform: 'uppercase',
    },
    '& p': {
      marginTop: 4,
    },
  },
  categoryColor: {
    width: 8,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  subtitle: {
    marginTop: theme.spacing(),
    '& span': {
      fontSize: 14,
      fontWeight: 300,
      color: theme.palette.common.grey,
      paddingLeft: theme.spacing(0.5),
    },
    '& > span': {
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
