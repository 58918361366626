import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

import CashFlow from 'store/financial/cash-flow/controller';
import Event from 'store/event/controller';

const CostCenter = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('cost-center')
      .onSnapshot((ref) => {
        const costCenter = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(Company.loadCostCenter(costCenter));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const {
      current: company, cashFlow, events, categories,
    } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('cost-center').add({ ...data, updatedAt: now, createdAt: now })
      .then((doc) => {
        cashFlow.forEach((c) => {
          const eventMatch = data.events.indexOf(c.event) >= 0 || data.events[0] === 'all';
          let categoryMatch = false;

          const isMatchCategory = (id) => {
            const category = categories.find((cat) => cat.id === id);
            if (category) {
              if (c.categories.indexOf(id) < 0) {
                let match = false;
                const children = categories.filter((cat) => cat.parent === id);
                children.forEach((cat) => {
                  if (isMatchCategory(cat.id)) match = true;
                });
                return match;
              }
              return true;
            }
            return false;
          };

          data.categories.forEach((category) => {
            if (category === 'all' || isMatchCategory(category)) {
              categoryMatch = true;
            }
          });

          const centers = c.costCenter || [];

          if (eventMatch && categoryMatch) {
            if (centers.indexOf(doc.id) < 0) {
              dispatch(CashFlow.update(c.id, { costCenter: [...centers, doc.id] }));
            }
          } else if (centers.indexOf(doc.id) >= 0) {
            const index = centers.indexOf(doc.id);
            centers.splice(index, 1);
            dispatch(CashFlow.update(c.id, { costCenter: centers }));
          }
        });

        events.forEach((event) => {
          const centers = event.costCenter || [];

          if (centers.indexOf(doc.id) < 0) {
            if (data.events.indexOf(event.id) >= 0 || data.events[0] === 'all') {
              centers.push(doc.id);
              dispatch(Event.update(event.id, { costCenter: centers }));
            }
          } else if (data.events.indexOf(event.id) < 0) {
            const index = centers.indexOf(doc.id);
            centers.splice(index, 1);
            dispatch(Event.update(event.id, { costCenter: centers }));
          }
        });

        Mixpanel.track('Create a cost center');
        dispatch(Alert.show({ message: 'Cost Center created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('cost-center').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a cost center');
        dispatch(Alert.show({
          message: 'Cost Center updated successfully.', type: 'success',
        }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => async (dispatch, getState) => {
    const { current: company, events, cashFlow } = getState().company;

    for (let i = 0; i < events.length; i += 1) {
      const { costCenter } = events[i];
      if (costCenter && costCenter.indexOf(id) >= 0) {
        const index = costCenter.indexOf(id);
        costCenter.splice(index, 1);
        // eslint-disable-next-line no-await-in-loop
        await firestore.collection('events').doc(events[i].id)
          .set({ costCenter }, { merge: true });
      }
    }

    for (let i = 0; i < cashFlow.length; i += 1) {
      const { costCenter } = cashFlow[i];
      if (costCenter && costCenter.indexOf(id) >= 0) {
        const index = costCenter.indexOf(id);
        costCenter.splice(index, 1);
        // eslint-disable-next-line no-await-in-loop
        await firestore.collection('companies').doc(company.id)
          .collection('cash-flow').doc(cashFlow[i].id)
          .set({ costCenter }, { merge: true });
      }
    }

    firestore.collection('companies').doc(company.id)
      .collection('cost-center').doc(id)
      .delete()
      .then(async () => {
        Mixpanel.track('Delete a cost center');
        dispatch(Alert.show({
          message: 'Cost Center successfully deleted.', type: 'success',
        }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default CostCenter;
