import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { UserPlus } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

function Responsibles({ id, name, responsible }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { users } = useSelector((store) => store.company);

  const [openMenu, setOpenMenu] = useState(null);

  const update = (uid) => {
    if ((responsible && responsible.id !== uid) || !responsible) {
      dispatch(Log.store(`Changed managers of task "${name}"`));
      dispatch(Supplier.update(id, { responsible: uid }));
    }
    setOpenMenu(null);
  };

  return (
    <>
      <Grid item className={classes.container} onClick={(e) => setOpenMenu(e.currentTarget)}>
        {responsible ? (
          <Tooltip title={responsible.name} key={responsible.id}>
            <Avatar className={classes.avatar} alt={responsible.name} src={responsible.avatar}>
              {responsible.initials}
            </Avatar>
          </Tooltip>
        ) : (
          <Tooltip title="Add manager">
            <IconButton className={classes.addButton} onClick={(e) => setOpenMenu(e.currentTarget)}>
              <UserPlus size={18} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => setOpenMenu(null)}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginLeft: 8 }}
      >
        {users.filter((u) => !u.deletedAt).map((user) => (
          <MenuItem key={user.id} onClick={() => update(user.id)}>
            <Avatar alt={user.name} src={user.avatar} className={classes.avatarMenu}>
              {user.initials}
            </Avatar>
            {user.name}
          </MenuItem>
        ))}
        <MenuItem className={classes.removeResponsible} onClick={() => update(null)}>
          Remove manager
        </MenuItem>
      </Menu>
    </>
  );
}

Responsibles.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  responsible: PropTypes.string.isRequired,
};

export default Responsibles;
