import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { isSameDay, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { useTheme, lighten } from '@material-ui/core/styles';

// Icons and Styles
import { CheckSquare, Calendar, Paperclip } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

function Task({ task, showLabel, setShowLabel }) {
  const classes = styles();
  const theme = useTheme();
  const history = useHistory();
  const { eventId } = useParams();

  const { labels, users } = useSelector((store) => store.company);

  const [checklist, setChecklist] = useState({ total: 0, checked: 0 });

  useEffect(() => {
    if (task.checklists) {
      let total = 0;
      let checked = 0;

      task.checklists.forEach((list) => {
        total += list.items.length;
        checked += list.items.filter((i) => i.checked).length;
      });

      setChecklist({ total, checked });
    }
  }, [task]);

  const Color = (priority) => {
    const colors = [
      '#a2a2a2',
      '#1FB3DC',
      '#1F45DC',
      '#941FDC',
      '#DC1F94',
      '#DC1F26',
    ];

    return colors[priority];
  };

  const PriorityLabel = (priority) => {
    const label = [
      'não definida',
      'muito baixa',
      'baixa',
      'média',
      'alta',
      'muito alta',
    ];

    return `Prioridade ${label[priority]}`;
  };

  return (
    <Card
      className={classes.card}
      style={{ background: lighten(theme.palette.primary.main, 0.9) }}
      // style={{ border: `1px solid ${!task.completedAt ? Color(task.priority) : 'transparent'}` }}
    >
      <CardActionArea
        className={classes.actionArea}
        onClick={() => history.push(`/planner/${eventId}/productivity/tasks/${task.id}`)}
      >
        <CardContent>
          <Grid container direction="column">
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid container direction="column">
                {task.labels.length > 0 ? task.labels.map((lId) => {
                  const label = labels.find((l) => l.id === lId);

                  return (
                    <Tooltip key={lId} title={showLabel ? null : label.name}>
                      <Grid
                        className={classes.label}
                        style={{
                          color: theme.palette.getContrastText(label.color),
                          backgroundColor: label.color,
                          maxWidth: showLabel ? 300 : 32,
                          width: showLabel ? 'fit-content' : 32,
                          maxHeight: showLabel ? 32 : 8,
                          height: showLabel ? 'fit-content' : 8,
                          padding: showLabel ? '4px 8px' : 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowLabel(!showLabel);
                        }}
                      >
                        {showLabel ? label.name : ''}
                      </Grid>
                    </Tooltip>
                  );
                }) : (
                  <Grid container className={classes.taskName} style={{ marginTop: 0 }}>
                    <p>{task.name}</p>
                  </Grid>
                )}
              </Grid>
              {!task.completedAt && (
                <Tooltip title={PriorityLabel(task.priority)}>
                  <div
                    className={classes.priority}
                    style={{
                      background: Color(task.priority),
                      color: theme.palette.getContrastText(Color(task.priority)),
                    }}
                  >
                    {task.priority}
                  </div>
                </Tooltip>
              )}
            </Grid>
            {task.labels.length > 0 && (
              <Grid container className={classes.taskName}>
                <p>{task.name}</p>
              </Grid>
            )}

            <Grid container justify="space-between" alignItems="center" wrap="nowrap" style={{ marginTop: 16 }}>
              <Grid container wrap="nowrap">
                {task.endAt.toDate() < new Date()
                  && !isSameDay(task.endAt.toDate(), new Date())
                  && !task.completedAt
                  && (
                    <Tooltip title="Tarefa atrasada">
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={classes.due}
                        style={{ marginRight: 16 }}
                      >
                        <Calendar size={16} style={{ marginRight: 8, color: '#fff' }} />
                        {format(task.endAt.toDate(), 'dd MMM', { locale: ptBR })}
                      </Grid>
                    </Tooltip>
                  )}
                {checklist.total > 0 && (
                  <Grid
                    container
                    alignItems="center"
                    className={checklist.total === checklist.checked ? classes.checked : ''}
                    style={{ color: checklist.total === checklist.checked ? '#fff' : '#777' }}
                  >
                    <CheckSquare size={16} style={{ marginRight: 8 }} />
                    {`${checklist.checked} / ${checklist.total}`}
                  </Grid>
                )}
                {task.attachments.length > 0 && (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ width: 'fit-content', color: '#777' }}
                  >
                    <Paperclip size={16} style={{ marginRight: 8 }} />
                    {task.attachments.length}
                  </Grid>
                )}
              </Grid>

              <Grid item style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                {task.responsibles.map((uId) => {
                  const user = users.find((u) => u.id === uId);

                  return (
                    <Tooltip title={user.name} key={uId}>
                      <Avatar className={classes.avatar} src={user.avatar}>
                        {user.initials}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

Task.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    step: PropTypes.string,
    priority: PropTypes.number,
    endAt: PropTypes.shape({ toDate: PropTypes.func.isRequired }),
    completedAt: PropTypes.shape({ toDate: PropTypes.func }),
    labels: PropTypes.arrayOf(PropTypes.string),
    responsibles: PropTypes.arrayOf(PropTypes.string),
    attachments: PropTypes.arrayOf(PropTypes.object),
    checklists: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showLabel: PropTypes.bool.isRequired,
  setShowLabel: PropTypes.func.isRequired,
};

export default Task;
