import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

// Styles
import styles from './style';

function ConfirmDialog({ title, onClose, onConfirm }) {
  const classes = styles();

  const confirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} className={classes.buttonDestroy} autoFocus>
          No
        </Button>
        <Button onClick={confirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
