import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  isSameDay, differenceInHours, differenceInMinutes, differenceInCalendarDays, format,
} from 'date-fns';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Channel from 'store/chat/channel/controller';
import Message from 'store/chat/message/controller';

// Icons and Styles
import {
  Plus, MessageSquare, ArrowLeft, Image, Folder, FileText, ArrowUpLeft, X,
} from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import Conversation from './conversation';
import ConversationForm from './conversation/form';
import styles from './style';

function Chat({ open, onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: user } = useSelector((store) => store.user);
  const { channels, users } = useSelector((store) => store.company);

  const [add, setAdd] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [privateChat, setPrivateChat] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);

  useEffect(() => {
    if (!channels) dispatch(Channel.index());
    else {
      channels.forEach((channel) => {
        if (!channel.message) dispatch(Message.index(channel.id));
        if (!channel.unreads) dispatch(Message.unread(channel.id));
      });
    }
  }, [channels, dispatch]);

  useEffect(() => {
    if (privateChat) {
      const chat = channels.find(
        (c) => !c.group && c.members.indexOf(privateChat) >= 0,
      );
      if (chat) setSelectedChannel(chat);
    }
  }, [privateChat, channels]);

  const close = () => {
    setSelectedChannel(null);
    setAdd(false);
    onClose();
  };

  const isLoaded = channels && !channels.find((c) => !c.messages);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={close}>
        <Grid container wrap="nowrap" style={{ overflow: 'hidden', height: '100%' }}>
          <Grid container direction="column" alignItems="center" className={classes.sidebar}>
            <IconButton color="secondary" onClick={close} className={classes.add}>
              <X />
            </IconButton>
            {isLoaded && channels
              .filter((c) => c.group)
              .sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate())
              .map((channel) => {
                const { group, unreads: channelUnread } = channel;
                const members = channel.members.filter((m) => m !== user.id)
                  .map((m) => users.find((u) => u.id === m));

                const name = group ? group.name : members[0].name;
                const image = group ? group.avatar : members[0].avatar;
                const initials = group ? group.initials : members[0].initials;

                return (
                  <div key={channel.id} className={classes.channel}>
                    <Badge color="secondary" badgeContent={channelUnread}>
                      <Tooltip title={name} placement="right">
                        <Avatar
                          src={image}
                          alt={name}
                          className={classes.avatar}
                          onClick={() => setSelectedChannel(channel)}
                          style={{ cursor: 'pointer' }}
                        >
                          {initials}
                        </Avatar>
                      </Tooltip>
                    </Badge>
                  </div>
                );
              })}
            <Tooltip title="Create channel" placement="right">
              <Fab color="secondary" size="small" onClick={() => setAdd(true)} className={classes.add}>
                <Plus />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid container direction="column">
            <Grid container justify="space-between" alignItems="center" className={classes.toolbar}>
              {mobile ? (
                <IconButton color="primary" onClick={close}>
                  <ArrowLeft />
                </IconButton>
              ) : <div />}
              <Typography className={classes.title}>CHAT</Typography>
              <Tooltip title="Start a private chat">
                <IconButton color="primary" onClick={(e) => setOpenMenu(e.currentTarget)}>
                  <MessageSquare />
                </IconButton>
              </Tooltip>
              <Menu
                open={!!openMenu}
                anchorEl={openMenu}
                onClose={() => setOpenMenu(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {users.filter((u) => u.id !== user.id && !u.deletedAt).map((u) => (
                  <MenuItem
                    key={u.id}
                    onClick={() => {
                      setOpenMenu(null);
                      setPrivateChat(u.id);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={u.avatar} alt={u.name} className={classes.avatar}>
                        {u.initials}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={u.name} />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid container className={classes.content}>
              <List style={{ width: '100%' }}>
                {isLoaded ? channels
                  .sort((a, b) => {
                    if (b.lastMessage && a.lastMessage) {
                      return b.lastMessage.sendAt.toDate() - a.lastMessage.sendAt.toDate();
                    }
                    if (a.lastMessage) return 1;
                    return 1;
                  })
                  .map((channel) => {
                    const { group, lastMessage, unreads: channelUnread } = channel;
                    const members = channel.members.filter((m) => m !== user.id)
                      .map((m) => users.find((u) => u.id === m));

                    const name = group ? group.name : members[0].name;
                    const image = group ? group.avatar : members[0].avatar;
                    const initials = group ? group.initials : members[0].initials;

                    let date = '';
                    let sender = '';
                    let content = '';
                    if (lastMessage) {
                      content = lastMessage.content;
                      const now = new Date();
                      const sendAt = lastMessage.sendAt.toDate();

                      if (isSameDay(now, sendAt)) {
                        const diffHours = differenceInHours(now, sendAt);
                        if (diffHours === 0) {
                          const diffMinutes = differenceInMinutes(now, sendAt);
                          if (diffMinutes === 0) date = 'Agora';
                          else date = `Há ${diffMinutes} minuto${diffMinutes > 1 ? 's' : ''}`;
                        } else date = `Há ${diffHours} hora${diffHours > 1 ? 's' : ''}`;
                      } else {
                        const diffDays = differenceInCalendarDays(now, sendAt);
                        if (diffDays === 1) date = 'Ontem';
                        else date = format(sendAt, 'dd/MM/yyyy');
                      }

                      if (lastMessage.sender === user.id) sender = 'Você: ';
                      if (group && lastMessage.sender !== user.id) {
                        const sdr = users.find((u) => u.id === lastMessage.sender);
                        if (sdr) sender = `${sdr.name.split(' ')[0]}: `;
                      }

                      if (lastMessage.attachments && lastMessage.attachments.length > 0) {
                        const icons = [Image, Folder, FileText];
                        const labels = ['Imagem', 'Pasta', 'Arquivo'];
                        const extensions = [
                          'jpg,jpeg,gif,raw,png,tiff,tif,svg',
                          'zip,rar,tar',
                          'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
                        ];

                        const [extension] = lastMessage.attachments[0].name.split('.').reverse();

                        let Icon = FileText;
                        let label = 'File';

                        if (extension) {
                          for (let i = 0; i < extensions.length; i += 1) {
                            if (extensions[i].indexOf(extension) >= 0) {
                              Icon = icons[i];
                              label = labels[i];
                            }
                          }
                        }

                        content = (
                          <span style={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                            <Icon size={14} style={{ margin: '1px 8px' }} />
                            {label}
                          </span>
                        );
                      }
                    }


                    return [
                      <Divider component="li" key={`divider-${channel.id}`} />,
                      <ListItem
                        button
                        key={channel.id}
                        style={{ padding: '8px 24px' }}
                        onClick={() => setSelectedChannel(channel)}
                      >
                        <ListItemAvatar>
                          <Avatar src={image} alt={name} className={classes.avatar}>
                            {initials}
                          </Avatar>
                        </ListItemAvatar>
                        <Grid container direction="column" style={{ overflow: 'hidden' }}>
                          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                            <Typography className={classes.chatName}>{name}</Typography>
                            <Typography className={classes.date}>{date}</Typography>
                          </Grid>
                          {lastMessage && (
                            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                              <Typography className={classes.message}>
                                {sender}
                                {content}
                              </Typography>
                              {channelUnread > 0 && (
                                <Chip color="secondary" label={channelUnread} className={classes.chip} />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>,
                    ];
                  }) : (
                    <Loading ready={!!isLoaded} content={400} />
                )}
                {isLoaded && <Divider component="li" />}
                {isLoaded && channels.length === 0 && (
                  <Typography style={{ color: '#777', fontSize: 18, padding: 24 }}>
                    <ArrowUpLeft style={{ marginRight: 16, color: '#4eb1d4' }} />
                    Start by creating communication channels with your team
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>

      <Conversation
        chat={selectedChannel}
        chatUser={privateChat}
        messages={selectedChannel && selectedChannel.messages}
        onClose={() => {
          setSelectedChannel(null);
          setPrivateChat(null);
        }}
        openChat={(id) => {
          const chat = channels.find(
            (c) => !c.group && c.members.indexOf(id) >= 0,
          );
          setSelectedChannel(chat || null);
          if (!chat) setPrivateChat(id);
        }}
      />

      <ConversationForm open={add} onClose={() => setAdd(false)} />
    </>
  );
}

Chat.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chat;
