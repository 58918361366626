import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 130px)',
    marginLeft: 130,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  row: {
    width: '100%',
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    margin: 0,
  },
  noData: {
    color: theme.palette.common.grey,
    fontSize: 18,
  },
}));
