import React from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons, Components and Styles
import { Faster, Slower, NegotiationTime } from 'assets/custom-icons';
import Tooltip from 'common/tooltip';
import styles from './style';

function Budget() {
  const classes = styles();

  return (
    <Grid container wrap="nowrap" alignItems="center" justify="center">
      <Grid item className={classes.iconContainer}>
        <NegotiationTime className={classes.icon} />
      </Grid>
      <Grid container direction="column" justify="center">
        <Grid item style={{ marginLeft: 16 }}>
          <Typography className={classes.title}>Average time negotiation</Typography>
          <Typography className={classes.value}>
            15
            <span>days</span>
          </Typography>
          <Grid container alignItems="center" className={classes.details}>
            <Tooltip title="Negociação mais rápida">
              <Grid item>
                <Faster size={18} style={{ marginRight: 8, fill: '#777777' }} />
                <Typography>10 days</Typography>
              </Grid>
            </Tooltip>
            <Tooltip title="Negociação mais demorada">
              <Grid item style={{ marginLeft: 48 }}>
                <Slower size={18} style={{ marginRight: 8, fill: '#777777' }} />
                <Typography>17 days</Typography>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Budget;
