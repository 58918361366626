// Main Components
import Productivity from 'app/planner/productivity';
import ProductivityTasks from 'app/planner/productivity/tasks';

// Children
import TaskForm from 'app/planner/productivity/children/task/form';
import TaskView from 'app/planner/productivity/children/task/view';
import ChecklistView from 'app/planner/productivity/children/checklist';
import ChecklistForm from 'app/planner/productivity/children/checklist/form';
import SettingsView from 'app/planner/productivity/children/settings';
import LabelsForm from 'app/planner/productivity/children/label';
import StepsForm from 'app/planner/productivity/children/step';

// Routes Components
import PrivateRoute from 'routes/components/PrivateRoute';

const commonRoutes = (basePath) => [
  {
    path: `${basePath}/settings`,
    component: SettingsView,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/labels/new`,
    component: LabelsForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/labels/:labelId`,
    component: LabelsForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/steps/new`,
    component: StepsForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/steps/:stepId`,
    component: StepsForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/checklist`,
    component: ChecklistView,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/checklist/new`,
    component: ChecklistForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${basePath}/checklist/:taskId`,
    component: ChecklistForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
];

export default [
  {
    path: '/planner/:eventId/productivity/tasks',
    component: ProductivityTasks,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      ...commonRoutes('/planner/:eventId/productivity/tasks'),
      {
        path: '/planner/:eventId/productivity/tasks/new',
        component: TaskForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/planner/:eventId/productivity/tasks/:taskId',
        component: TaskView,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
  {
    path: '/planner/:eventId/productivity',
    component: Productivity,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      ...commonRoutes('/planner/:eventId/productivity'),
    ],
  },
];
