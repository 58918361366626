import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  isSameDay, subDays, addDays, isWithinInterval,
} from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Medal } from 'assets/custom-icons';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import styles from './style';

function CompletedTasksMe({ time, setTime }) {
  const classes = styles();
  const { eventId } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { tasks } = useSelector((store) => store.company);
  const { current: user } = useSelector((store) => store.user);

  const [variants, setVariants] = useState({
    today: [0, 0],
    week: [0, 0],
  });

  useEffect(() => {
    if (tasks && eventId) {
      const now = new Date();
      const ourTasks = tasks.filter(
        (t) => t.responsibles.indexOf(user.id) >= 0 && t.event === eventId,
      );

      // Today
      const todayTasks = ourTasks.filter(
        (t) => (t.startAt.toDate() < now && !t.completedAt)
          || isSameDay(t.startAt.toDate(), now)
          || (t.completedAt && isSameDay(t.completedAt.toDate(), now)),
      );
      const todayTasksCompleted = todayTasks.filter((t) => !!t.completedAt);

      // Week
      const startWeek = subDays(now, now.getDay());
      const endWeek = addDays(now, 6 - now.getDay());

      const weekTasks = ourTasks.filter((t) => {
        const start = t.startAt.toDate();
        const completed = t.completedAt ? t.completedAt.toDate() : null;
        const range = { start: startWeek, end: endWeek };

        if (isWithinInterval(start, range)) return true;

        if (start < startWeek) {
          if (!completed) return true;
          if (isWithinInterval(completed, range)) return true;
        }

        return false;
      });
      const weekTasksCompleted = weekTasks.filter((t) => !!t.completedAt);

      setVariants({
        today: [todayTasksCompleted.length, todayTasks.length],
        week: [weekTasksCompleted.length, weekTasks.length],
      });
    }
  }, [tasks, eventId, user.id]);

  return (
    <Grid
      container
      direction="column"
      alignItems={mobile ? 'center' : 'flex-start'}
      style={{ paddingRight: mobile ? 0 : 24 }}
    >
      <Grid container direction={mobile ? 'column' : 'row'} justify="space-between" alignItems="center">
        <Typography className={classes.title}>YOUR PERFORMANCE</Typography>
        <ButtonGroup size="small" color="secondary" aria-label="small outlined primary button group">
          <Button
            variant={time === 'today' ? 'contained' : 'outlined'}
            onClick={() => setTime('today')}
          >
            Today
          </Button>
          <Button
            variant={time === 'week' ? 'contained' : 'outlined'}
            onClick={() => setTime('week')}
          >
            This week
          </Button>
        </ButtonGroup>
      </Grid>
      {tasks ? (
        <>
          <Grid container justify="space-between" alignItems="flex-end">
            <Typography className={classes.value}>
              {variants[time][0]}
              <span>{`/ ${variants[time][1]} tasks`}</span>
            </Typography>
            {variants[time][0] === variants[time][1] && (
              <Tooltip title="Meta atingida">
                <Medal className={classes.congratulations} />
              </Tooltip>
            )}
          </Grid>
          <Grid container wrap="nowrap" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={variants[time][1] > 0
                ? (variants[time][0] / variants[time][1]) * 100
                : 0}
              classes={{ root: classes.progress, bar: classes.progressBar }}
            />
            <Typography className={classes.percent}>
              {variants[time][1]
                ? `${Math.round((variants[time][0] / variants[time][1]) * 100)}%`
                : 0}
            </Typography>
          </Grid>
        </>
      ) : (
        <Loading content={123} ready={!!tasks} />
      )}
    </Grid>
  );
}

CompletedTasksMe.propTypes = {
  time: PropTypes.string.isRequired,
  setTime: PropTypes.func.isRequired,
};

export default CompletedTasksMe;
