import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Supplier from 'store/supplier/controller';

import { Plus } from 'react-feather';
import { categoryWithProducts } from 'common/utils/relationships';
import Form from 'common/form';
import styles from './style';

export function EditNotes({ supplier, onSubmit }) {
  const dispatch = useDispatch();

  const formSchema = [{
    name: 'notes',
    type: 'text',
    multiline: true,
  }];

  const submit = ({ notes }) => {
    dispatch(Supplier.update(supplier.id, { notes }));
    if (onSubmit) onSubmit();
  };

  return (
    <Form
      id="form-supplier-notes"
      onSubmit={submit}
      schema={formSchema}
      initialState={supplier}
    >
      {(inputs) => inputs.notes}
    </Form>
  );
}

export function EditName({ supplier, onSubmit }) {
  const dispatch = useDispatch();

  const formSchema = [{
    name: 'name',
    type: 'text',
    inputProps: { style: { padding: 8, fontSize: '2.125rem', fontWeight: 'bold' } },
    validation: { required: 'Supplier name is required' },
  }];

  const submit = ({ name }) => {
    dispatch(Supplier.update(supplier.id, { name }));
    if (onSubmit) onSubmit();
  };

  return (
    <Form
      id="form-supplier-name"
      onSubmit={submit}
      schema={formSchema}
      initialState={supplier}
    >
      {(inputs) => inputs.name}
    </Form>
  );
}

export function EditProducts({ supplier, onSubmit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();

  const { products } = useSelector((store) => store.event);
  const { categories } = useSelector((store) => store.company);

  const [menuEl, setMenuEl] = useState(null);

  const addProduct = (id) => {
    const newProducts = supplier.products.map((p) => p.id || p);
    newProducts.push(id);

    if (supplier.budget) {
      const newBudget = _.cloneDeep(supplier.budget);
      newBudget.products[id] = { amount: 0, value: 0 };

      dispatch(Supplier.update(supplier.id, {
        products: newProducts,
        budget: newBudget,
      }));
    }

    dispatch(Supplier.update(supplier.id, { products: newProducts }));

    setMenuEl(null);
    if (onSubmit) onSubmit();
  };

  return (
    <>
      <Button
        color="secondary"
        className={classes.categoryAdd}
        onClick={(e) => setMenuEl(e.currentTarget)}
      >
        <Plus />
      </Button>

      {menuEl && (
        <Menu
          anchorEl={menuEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(menuEl)}
          PaperProps={{ style: { maxHeight: 350, width: 300 } }}
          onClose={() => setMenuEl(false)}
        >
          <MenuItem
            onClick={() => history.push(`/manager/${eventId}/procurement/negotiations/products/new`)}
            className={classes.add}
          >
            Add product
          </MenuItem>
          {categories
            .filter((c) => c.type === 'out')
            .map((c) => categoryWithProducts(c, products))
            .filter((c) => c.products.filter((p) => supplier.products.indexOf(p.id) < 0).length > 0)
            .map((category) => [
              <ListSubheader
                key={category.id}
                className={classes.categoryLabel}
                style={{ color: category.color }}
              >
                {category.name}
              </ListSubheader>,
              category.products
                .filter((p) => supplier.products.indexOf(p.id) < 0)
                .map((product) => (
                  <ListItem
                    button
                    key={product.id}
                    onClick={() => addProduct(product.id)}
                  >
                    <ListItemText primary={product.name} />
                  </ListItem>
                )),
            ])}
        </Menu>
      )}
    </>
  );
}

const propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    products: PropTypes.array,
    budget: PropTypes.object,
  }).isRequired,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: null,
};

EditNotes.propTypes = propTypes;
EditNotes.defaultProps = defaultProps;
EditName.propTypes = propTypes;
EditName.defaultProps = defaultProps;
EditProducts.propTypes = propTypes;
EditProducts.defaultProps = defaultProps;
