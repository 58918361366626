import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import getCNPJ from 'services/receitaws';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Plus } from 'react-feather';

function FormConfig({ path, supplierEvent }) {
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingCNPJ, setLoadingCNPJ] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const newFiles = [...supplierEvent.attachments];

    if (supplierEvent.settings && supplierEvent.settings.registerAttachments) {
      supplierEvent.settings.registerAttachments.forEach((file) => {
        if (!newFiles.find((f) => f.document === file)) {
          newFiles.push({ label: file, name: file });
        }
      });
    }

    setFiles(newFiles);
  }, [supplierEvent.settings, supplierEvent.attachments]);

  const searchCNPJ = (cnpj) => {
    const search = cnpj.trim().split(/\.|\/|-/).join('');

    setLoadingCNPJ(true);

    return getCNPJ(search)
      .then((data) => {
        setLoadingCNPJ(false);
        return data;
      })
      .catch(() => {
        setLoadingCNPJ(false);
      });
  };

  const handleCEP = async (value, state) => {
    const cep = value.replace('-', '').trim();

    if (cep.length === 8) {
      setLoadingCEP(true);
      await axios.get(`https://viacep.com.br/ws/${cep}/json`).then((response) => {
        const {
          logradouro, bairro, localidade, uf,
        } = response.data;

        _.set(state, 'address.street', logradouro);
        // _.set(state, 'address.neighborhood', bairro);
        _.set(state, 'address.city', localidade);
        _.set(state, 'address.state', uf);
        _.set(state, 'address.country', 'Brasil');

        setLoadingCEP(false);
      });
    }

    return state;
  };

  return [
    {
      name: 'document',
      type: 'text',
      label: 'CNPJ',
      mask: 'cnpj',
      disabled: loadingCNPJ,
      InputPropsDefault: {
        endAdornment: loadingCNPJ && (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ marginRight: 4 }} />
          </InputAdornment>
        ),
      },
      validation: {
        required: 'Supplier CNPJ is required',
      },
      onChange: async (value, state) => {
        if (value.trim().length === 18) {
          const data = await searchCNPJ(value);
          if (data) {
            _.set(state, 'name', data.businessName);
            _.set(state, 'companyName', data.companyName);
            _.set(state, 'situation', data.situation);
            _.set(state, 'legalNature', data.legalNature);
            _.set(state, 'mainActivity', data.mainActivity);
            _.set(state, 'contact.email', data.contact.email);
            _.set(state, 'contact.phone', data.contact.phone);
            _.set(state, 'address', data.address);
          }
        }
        return state;
      },
    },
    {
      name: 'name',
      type: 'text',
      label: 'Fantasy name',
      validation: { required: 'Supplier name is required' },
    },
    {
      name: 'companyName',
      type: 'text',
      label: 'Company name',
      disabled: true,
      validation: { required: true },
    },
    {
      name: 'situation',
      type: 'text',
      label: 'CNPJ status',
      disabled: true,
    },
    {
      name: 'legalNature',
      type: 'text',
      label: 'Legal Nature',
      disabled: true,
    },
    {
      name: 'mainActivity.code',
      type: 'text',
      label: 'Code',
      disabled: true,
    },
    {
      name: 'mainActivity.description',
      type: 'text',
      label: 'Description',
      disabled: true,
    },
    {
      name: 'contact.email',
      type: 'text',
      label: 'E-mail',
      validation: {
        required: 'Contact e-mail is required',
        email: 'Must be a valid e-mail',
      },
    },
    {
      name: 'contact.phone',
      type: 'text',
      mask: 'phone',
      label: 'Phone / Cellphone',
      validation: { phone: 'Must be a valid phone' },
    },
    {
      name: 'contact.site',
      type: 'text',
      label: 'Website',
      validation: { url: 'Must be a valid website' },
    },
    {
      name: 'address.postalCode',
      type: 'text',
      label: 'ZIP code',
      mask: 'cep',
      disabled: loadingCEP,
      InputPropsDefault: {
        endAdornment: loadingCEP && (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ marginRight: 4 }} />
          </InputAdornment>
        ),
      },
      onChange: handleCEP,
      validation: { required: true },
    },
    {
      name: 'address.street',
      type: 'text',
      label: 'Street',
      disabled: loadingCEP,
      validation: { required: true },
    },
    {
      name: 'address.number',
      type: 'text',
      inputType: 'number',
      label: 'Number',
      validation: { required: true },
    },
    {
      name: 'address.complement',
      type: 'text',
      label: 'Complement',
      disabled: loadingCEP,
    },
    // {
    //   name: 'address.neighborhood',
    //   type: 'text',
    //   label: 'Neighborhood',
    //   validation: { required: true },
    // },
    {
      name: 'address.city',
      type: 'text',
      label: 'City',
      disabled: loadingCEP,
      validation: { required: true },
    },
    {
      name: 'address.state',
      type: 'text',
      label: 'State',
      disabled: loadingCEP,
      validation: { required: true },
      maxLength: 2,
    },
    {
      name: 'address.country',
      type: 'text',
      label: 'Country',
      disabled: loadingCEP,
      validation: { required: true },
    },
    {
      name: 'notes',
      type: 'text',
      multiline: true,
      label: 'Notes',
    },
    {
      name: 'attachments',
      type: 'upload',
      inputProps: {
        path,
        defaultDocuments: files,
        formats: 'document/*;image/*',
        buttonProps: { label: 'Add other files', icon: <Plus size={16} /> },
      },
      validation: files.length > 0 ? { defaultUploads: 'Send required files' } : {},
    },
  ];
}

export default FormConfig;
