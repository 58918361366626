import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

function SwitchInput({
  value, label, color, onChange, disabled, helperText, error, labelPlacement,
}) {
  return (
    <>
      <FormControlLabel
        disabled={disabled}
        value="end"
        control={(
          <Switch
            color={color}
            checked={value}
            onChange={() => onChange(!value)}
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
        style={{ margin: 0 }}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

SwitchInput.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.string,
};

SwitchInput.defaultProps = {
  label: '',
  color: 'primary',
  helperText: '',
  labelPlacement: 'bottom',
  disabled: false,
  error: false,
};

export default SwitchInput;
