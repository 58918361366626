import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Check, Plus } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

function Responsibles({ id, name, responsibles }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { users } = useSelector((store) => store.company);

  const [openMenu, setOpenMenu] = useState(null);
  const [selected, setSelected] = useState(responsibles.map((r) => r.id));

  const handleUser = (uId) => {
    const newSelected = _.cloneDeep(selected);
    const index = selected.indexOf(uId);

    if (index >= 0) newSelected.splice(index, 1);
    else newSelected.push(uId);

    setSelected(newSelected);
  };

  const update = () => {
    if (!_.isEqual(selected, responsibles.map((r) => r.id))) {
      dispatch(Log.store(`Changed task managers "${name}"`));
      dispatch(Task.update(id, { responsibles: selected }));
    }
    setOpenMenu(null);
  };

  return (
    <>
      <Grid item className={classes.container} onClick={(e) => setOpenMenu(e.currentTarget)}>
        {responsibles.filter((r, i) => (responsibles.length > 3 ? i < 2 : true)).map((user) => (
          <Tooltip title={user.name} key={user.id}>
            <Avatar className={classes.avatar} alt={user.name} src={user.avatar}>
              {user.initials}
            </Avatar>
          </Tooltip>
        ))}
        {responsibles.length > 3 && (
          <Tooltip title={`+ ${responsibles.length - 2} colaboradores`}>
            <Avatar className={classes.avatar}>{`+${responsibles.length - 2}`}</Avatar>
          </Tooltip>
        )}
        {responsibles.length === 0 && (
          <Tooltip title="Add responsible">
            <IconButton className={classes.addButton} onClick={(e) => setOpenMenu(e.currentTarget)}>
              <Plus size={18} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={update}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginLeft: 8 }}
      >
        {users.filter((u) => !u.deletedAt).map((user) => (
          <MenuItem key={user.id} onClick={() => handleUser(user.id)}>
            <Avatar alt={user.name} src={user.avatar} className={classes.avatarMenu}>
              {user.initials}
            </Avatar>
            {user.name}
            <Check
              size={18}
              className={classes.check}
              style={{ color: selected.indexOf(user.id) >= 0 ? '#4bb75c' : 'transparent' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Responsibles.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  responsibles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Responsibles;
