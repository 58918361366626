/**
 * Types
 */
export const TypesSuppliers = {
  LOAD_SUPPLIERS: '@event/LOAD_SUPPLIERS',
  LOAD_PRODUCTS: '@event/LOAD_PRODUCTS',
};

/**
 * Actions
 */
export const ActionsSuppliers = {
  loadSuppliers: (suppliers) => ({
    type: TypesSuppliers.LOAD_SUPPLIERS,
    payload: { suppliers },
  }),
  loadProducts: (products) => ({
    type: TypesSuppliers.LOAD_PRODUCTS,
    payload: { products },
  }),
};

/**
 * Reducer
 */
export const INITIAL_STATE_SUPPLIERS = {
  suppliers: null,
  products: null,
};

export const reducerSuppliers = (state, action) => {
  switch (action.type) {
    case TypesSuppliers.LOAD_SUPPLIERS: {
      const { suppliers } = action.payload;
      return { ...state, suppliers };
    }
    case TypesSuppliers.LOAD_PRODUCTS: {
      const { products } = action.payload;
      return { ...state, products };
    }
    default: {
      return state;
    }
  }
};
