import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  valueTicket: {
    padding: `${theme.spacing(1.5)}px 40px ${theme.spacing()}px 0`,
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)}px 0`,
    },
  },
  installments: {
    '& span': {
      position: 'relative',
      transform: 'scale(1)',
      margin: `${theme.spacing(0.5)}px 0 0 ${theme.spacing()}px`,
    },
  },
  ticketDate: {
    marginLeft: theme.spacing(),
    '& p': {
      fontSize: 12,
      lineHeight: 1,
      fontWeight: 600,
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(),
    },
    '& > div': {
      fontSize: 22,
      fontWeight: 600,
      color: theme.palette.common.black,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
      marginLeft: 0,
    },
  },
  title: {
    margin: `${theme.spacing()}px 0`,
    fontSize: 22,
    color: theme.palette.common.grey,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  category: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    borderRadius: 30,
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 600,
    margin: theme.spacing(0.5),
    marginLeft: 0,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.3)}px ${theme.spacing(0.75)}px`,
    },
  },
  infoTitle: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
  },
  infoValue: {
    color: theme.palette.common.black,
    fontSize: 22,
    '& p': {
      marginBottom: -12,
    },
    '& span': {
      fontSize: 12,
      color: theme.palette.common.grey,
      lineHeight: 1,
      marginRight: theme.spacing(0.5),
    },
  },
  infoDefault: {
    color: theme.palette.common.grey,
  },
  costCenter: {
    fontSize: 16,
    background: theme.palette.secondary.main,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.75)}px`,
    margin: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    marginLeft: 0,
    borderRadius: 20,
    color: 'white',
  },

  document: {
    background: lighten(theme.palette.secondary.main, 0.9),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  documentGreen: {
    marginTop: theme.spacing(2),
    background: lighten(theme.palette.success.main, 0.9),
    border: `1px solid ${theme.palette.success.main}`,
  },

  actions: {
    justifyContent: 'center',
  },
  btn: {
    margin: theme.spacing(1.25),
  },
  btnGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    margin: theme.spacing(1.25),
    '&:hover': {
      background: lighten(theme.palette.success.main, 0.8),
      borderColor: theme.palette.success.main,
    },
  },
  btnRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    margin: theme.spacing(1.25),
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.8),
      borderColor: theme.palette.error.main,
    },
  },
}));
