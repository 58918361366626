import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';

// Controllers
import { Actions as Form } from 'store/form/reducer';

import styles from './style';

function Select({
  id,
  state,
  label,
  value,
  error,
  disabled,
  className,
  helperText,
  options,
  addOption,
  onChange,
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <TextField
        fullWidth
        select
        variant="outlined"
        error={!!error}
        label={label}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const v = e.target.value;
          if (addOption && v === 'add') {
            dispatch(Form.save({ id, state }));
            history.push(addOption.url);
          } else {
            onChange(v);
          }
        }}
        SelectProps={{ classes: { select: className } }}
        InputProps={{ style: { maxHeight: 56 } }}
      >
        {addOption && (
          <MenuItem value="add" className={classes.add}>
            {addOption.label}
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value} className={item.className}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  addOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

Select.defaultProps = {
  addOption: null,
  label: '',
  error: false,
  disabled: false,
  className: null,
  helperText: '',
};

export default Select;
