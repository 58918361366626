import React, { useState, useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  differenceInCalendarDays, isSameDay, addDays, format, subDays,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { Plus, ChevronDown } from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import Task from '../components/card';
import { filter } from '../components/filter/utils';
import styles from './style';

function Timeline({ userFilter, search }) {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const [todayEl, setTodayEl] = useState(null);
  const scrollEl = useRef(null);

  const { tasks, steps, labels } = useSelector((store) => store.company);
  const { current: user } = useSelector((store) => store.user);

  const [days, setDays] = useState([]);
  const [stepsOrder, setStepsOrder] = useState([]);
  const [showLabel, setShowLabel] = useState(false);

  useEffect(() => {
    if (todayEl && scrollEl.current) {
      scrollEl.current.container.current.scrollLeft = todayEl.offsetLeft;
    }
  }, [todayEl, scrollEl]);

  useEffect(() => {
    if (steps) {
      const working = steps.filter((s) => s.type === 'working');
      const backlog = steps.filter((s) => s.type === 'backlog');
      const other = steps.filter((s) => s.type === 'other');
      const done = steps.filter((s) => s.type === 'done');
      const archive = steps.filter((s) => s.type === 'archive');

      setStepsOrder([...working, ...backlog, ...other, ...done, ...archive]);
    }
  }, [steps]);

  useEffect(() => {
    if (tasks && steps) {
      let ourTasks = filter(tasks, history.location.search).filter((t) => t.event === eventId);

      if (userFilter === 'me') {
        ourTasks = ourTasks.filter((t) => t.responsibles.indexOf(user.id) >= 0 || t.responsibles.length === 0);
      }
      if (search) {
        ourTasks = ourTasks.filter((t) => t.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
      }

      const newDays = [];
      const today = new Date();
      const orderTasks = ourTasks.sort(
        (a, b) => a.startAt.toDate().getTime() - b.startAt.toDate().getTime(),
      );
      const firstDay = orderTasks.length > 0
        ? orderTasks[0].startAt.toDate()
        : today;
      const lastDay = orderTasks.length > 0
        ? orderTasks[orderTasks.length - 1].startAt.toDate()
        : today;

      const diffPrevDays = differenceInCalendarDays(today, firstDay);
      let diffNextDays = differenceInCalendarDays(lastDay, firstDay);
      if (diffNextDays < 6) diffNextDays = 6;
      const diffDays = diffPrevDays + diffNextDays;

      for (let i = 0; i <= diffDays; i += 1) {
        const day = addDays(firstDay, i);
        const taskDays = ourTasks.filter(
          (t) => {
            const start = t.startAt.toDate();
            const completed = t.completedAt ? t.completedAt.toDate() : null;

            if (!completed) {
              if (isSameDay(today, day) && start < today) return true;
              if (isSameDay(start, day) && start > today) return true;
            }

            if (completed && isSameDay(completed, day)) return true;

            return false;
          },
        );

        newDays.push({ day, tasks: taskDays });
      }

      setDays(newDays);
    }
  }, [eventId, history.location.search, search, steps, tasks, user.id, userFilter]);

  return (
    <>
      <Tooltip title="Add task" placement="bottom">
        <Fab
          size="small"
          color="primary"
          className={classes.fab}
          onClick={() => history.push(`/planner/${eventId}/productivity/tasks/new`)}
        >
          <Plus />
        </Fab>
      </Tooltip>

      <ScrollContainer className={classes.timeline} vertical={false} ref={scrollEl}>
        {tasks && steps && labels
          ? days.map((date) => {
            const today = new Date();
            let label = format(date.day, 'EEEE', { locale: ptBR }).toUpperCase();

            if (isSameDay(date.day, subDays(today, 1))) label = 'YESTERDAY';
            if (isSameDay(date.day, today)) label = 'TODAY';
            if (isSameDay(date.day, addDays(today, 1))) label = 'TOMORROW';

            return (
              <Grid
                key={date.day}
                className={classes.day}
                ref={isSameDay(date.day, today) ? (el) => setTodayEl(el) : null}
              >
                <Grid className={classes.title}>
                  {label}
                  <p>{`(${format(date.day, 'dd MMM yyyy', { locale: ptBR })})`}</p>
                </Grid>
                <div className={classes.cardGrid}>
                  {stepsOrder.map((step) => {
                    const stepTasks = date.tasks.filter((t) => t.step === step.id)
                      .sort((a, b) => b.priority - a.priority);

                    if (stepTasks.length > 0) {
                      return (
                        <ExpansionPanel key={step.id} defaultExpanded className={classes.step}>
                          <ExpansionPanelSummary
                            expandIcon={<ChevronDown size={18} />}
                            className={classes.stepTitle}
                            classes={{ content: classes.stepTitleExpanded }}
                          >
                            <Typography>
                              {step.name}
                              <span>
                                {`(${stepTasks.length} tarefa${stepTasks.length > 1 ? 's' : ''})`}
                              </span>
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.stepCards}>
                            <Grid container>
                              {stepTasks.map((task) => (
                                <Task
                                  key={task.id}
                                  task={task}
                                  showLabel={showLabel}
                                  setShowLabel={setShowLabel}
                                />
                              ))}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      );
                    }

                    return '';
                  })}

                  {date.tasks.length === 0 && (
                    <Typography className={classes.noData}>
                      No task for that day
                    </Typography>
                  )}
                </div>
              </Grid>
            );
          })
          : (
            <Grid container alignItems="center" justify="center" style={{ height: '100%' }}>
              <Loading content={180} ready={!!tasks && !!steps && !!labels} />
            </Grid>
          )}
      </ScrollContainer>
    </>
  );
}

export default Timeline;
