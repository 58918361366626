import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';

// Material Components
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import { Calendar, X } from 'react-feather';
import styles from './style';

function EventsMenu({ element, onClose, mobile }) {
  const classes = styles();
  const history = useHistory();
  const location = useLocation();
  const { eventId: currentEvent } = useParams();
  const { events } = useSelector((state) => state.company);
  const [open, setOpen] = useState(false);
  const origin = { vertical: 'top', horizontal: 'center' };

  useEffect(() => {
    setOpen(element ? element.id === 'menu-events' : false);
  }, [element]);

  const close = () => {
    setOpen(false);
    onClose();
  };

  const changeEvent = (event) => {
    if (event !== currentEvent) {
      const path = location.pathname.replace(currentEvent, event);
      history.push(path);
    }
    onClose();
  };

  if (mobile) {
    return (
      <Dialog
        fullScreen
        fullWidth
        disableEscapeKeyDown
        aria-labelledby="view-notifications-dialog"
        classes={{ root: classes.dialog }}
        open={open}
        onClose={close}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
            <span>Events</span>
            <IconButton onClick={close} aria-label="Sair">
              <X />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {events.map((event) => {
            const date = format(event.dates.start.toDate(), 'dd MMM yyyy', {
              locale: ptBR,
            }).toLowerCase();
            return (
              <MenuItem
                key={`menu-events-${event.id}`}
                onClick={() => changeEvent(event.id)}
                className={`${classes.item} ${currentEvent === event.id && classes.highlightItem}`}
              >
                <ListItemIcon>
                  <Avatar>
                    <Calendar />
                  </Avatar>
                </ListItemIcon>
                <div className={classes.text}>
                  <Typography variant="subtitle1" noWrap className={classes.title}>
                    {event.name}
                  </Typography>
                  <Typography variant="body2" className={classes.noWrap}>
                    {event.address 
                      && `${event.address.localName} em ${event.address.city} - ${event.address.state}`}
                    {event.creating
                      && `${event.creating.format.type} ${event.creating.format.ticket === 'free'
                      ? 'gratuito' : 'pago'}`}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </DialogContent>
      </Dialog>
    );
  }

  if (element) {
    return (
      <Menu
        anchorEl={element}
        open={open}
        anchorOrigin={origin}
        transformOrigin={origin}
        className={classes.popover}
        MenuListProps={{ className: classes.menuList }}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
      >
        {events.filter((e) => !e.draft).map((event) => {
          return (
            <MenuItem
              key={`menu-events-${event.id}`}
              onClick={() => changeEvent(event.id)}
              className={`${classes.item} ${currentEvent === event.id && classes.highlightItem}`}
            >
              <ListItemIcon>
                <Avatar>
                  <Calendar />
                </Avatar>
              </ListItemIcon>
              <div className={classes.text}>
                <Typography variant="subtitle1" noWrap className={classes.title}>
                  {event.name}
                </Typography>

                <Typography variant="body2" className={classes.noWrap}>
                  {event.address 
                    && `${event.address.localName} em ${event.address.city} - ${event.address.state}`}
                  {event.creating
                    && `${event.creating.format.type} ${event.creating.format.ticket === 'free'
                    ? 'gratuito' : 'pago'}`}
                </Typography>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  return <></>;
}

EventsMenu.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

EventsMenu.defaultProps = {
  element: null,
  mobile: false,
};

export default {
  id: 'menu-events',
  icon: <Calendar style={{ minWidth: 24 }} />,
  label: 'Events',
  menu: EventsMenu,
};
