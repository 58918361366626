import React from 'react';
import PropTypes from 'prop-types';
import { Timestamp } from 'services/firebase';
import { addMonths } from 'date-fns';
import { useDispatch } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';

// Commons and Styles
import { numberToReal, currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import styles from './style';

function PaymentTypeInfos({
  edit, total, payment, onChange,
}) {
  const classes = styles();
  const dispatch = useDispatch();

  const paymentTypes = {
    '': 'Not defined',
    cash: 'Cash',
    times: 'Parcel',
  };

  const handleChange = (key, value) => {
    let { installments } = payment;

    if (key === 'type') {
      const [installment] = installments;

      const payed = !!installments.find((i) => i.cashFlow && i.cashFlow.completed.on);

      if (!payed) {
        if (value === 'cash') {
          installment.value = total;
          installments = [installment];
        } else if (installments.length === 1) {
          const due = installment.due && Timestamp.fromDate(addMonths(installment.due.toDate(), 1));

          installments = [
            { ...installment, value: total / 2 },
            { value: total / 2, due },
          ];
        }

        onChange({ ...payment, installments, [key]: value });
      } else {
        dispatch(Alert.show({
          message: 'It is not possible to change the payment method of a'
            + ' supplier that has already started to be paid.',
        }));
      }
    } else if (key === 'discount') {
      let v = total;

      if (value.type === 'money') {
        v -= value.value;
      } else if (value.type === 'percent') {
        v *= 1 - (value.value / 100);
      }

      installments[0].value = v;
      onChange({ ...payment, installments, [key]: value });
    } else {
      onChange({ ...payment, [key]: value });
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        {edit ? (
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Payment method"
            value={payment.type}
            onChange={(e) => handleChange('type', e.target.value)}
            InputProps={{ inputProps: { className: classes.field } }}
          >
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="times">Parcel</MenuItem>
          </TextField>
        ) : (
          <>
            <Typography variant="overline" className={classes.infoTitle}>
            Payment method
            </Typography>
            <Typography className={classes.infoText}>{paymentTypes[payment.type]}</Typography>
          </>
        )}
      </Grid>

      {payment.type === 'cash' && (payment.discount.value > 0 || edit) && (
        <Grid item xs={12} sm={6}>
          {edit ? (
            <TextField
              error={(payment.discount.type === 'money' && payment.discount.value > total)
                || (payment.discount.type === 'percent' && payment.discount.value > 100)}
              variant="outlined"
              label="Cash discount"
              value={currencyInput(payment.discount.value)}
              onChange={(e) => handleChange('discount', {
                ...payment.discount,
                value: revertCurrencyInput(e.target.value),
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Select
                      value={payment.discount.type}
                      style={{ paddingRight: 8 }}
                      className={classes.discountSelect}
                      classes={{ select: classes.discountSelect }}
                      onChange={(e) => handleChange('discount', { ...payment.discount, type: e.target.value })}
                    >
                      <MenuItem value="money">$</MenuItem>
                      <MenuItem value="percent">%</MenuItem>
                    </Select>
                  </InputAdornment>
                ),
                inputProps: { className: classes.field },
              }}
            />
          ) : (
            <>
              <Typography variant="overline" className={classes.infoTitle}>
              Cash discount
              </Typography>
              <Typography className={classes.infoText}>
                {payment.discount.type === 'money'
                  ? numberToReal(payment.discount.value)
                  : `${payment.discount.value}%`}
              </Typography>
            </>
          )}
        </Grid>
      )}
    </>
  );
}

PaymentTypeInfos.propTypes = {
  edit: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    discount: PropTypes.object,
    exchange: PropTypes.object,
    installments: PropTypes.array,
    method: PropTypes.string,
    total: PropTypes.number,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

PaymentTypeInfos.defaultProps = {
  payment: null,
};

export default PaymentTypeInfos;
