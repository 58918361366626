import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function CNPJMasked({ inputRef, ...props }) {
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

CNPJMasked.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CNPJMasked;
