import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';
import createImporter from 'services/importer';
import getCNPJ from 'services/receitaws';
import banks from 'services/brazilian-banks.json';

// Material UI Components
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Contact from 'store/contact/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Trash2, Edit2, Plus, ChevronDown, LogIn,
} from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function ContactsList() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const { contacts, cashFlow } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionProcurement = !permissions || !permissions.manager
    || permissions.manager.procurement.edit;
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const permissionEdit = permissionProcurement || permissionFinancial;

  const [importer, setImporter] = useState(null);
  const [deleteContact, setDeleteContact] = useState(false);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Tax Id or Social Security Number',
          key: 'document',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /(^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$)|(^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|(^[0-9]{14}$)|(^[0-9]{11}$)/,
            error: 'Not a valid number',
          }],
        },
        {
          label: 'Contact type',
          key: 'types',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^((Supplier|Partner|Sponsor|Participant|Client|Others)(, |,|))*$/,
            error: 'It must be one of these types (separated by a comma): Supplier, Partner, Sponsor, Participant, Client and Others',
          }],
        },
        {
          label: 'E-mail',
          key: 'email',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            error: 'Not a valid e-mail',
          }],
        },
        {
          label: 'Phone',
          key: 'phone',
          validators: [{
            validate: 'regex_matches',
            regex: /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/,
            error: 'Not a valid phone number',
          }],
        },
        {
          label: 'Website',
          key: 'site',
          validators: [{
            validate: 'regex_matches',
            regex: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
            error: 'Not a valid website',
          }],
        },
        {
          label: 'Zip code',
          key: 'cep',
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]{2}[.]*[0-9]{3}[-]*[0-9]{3}$/,
            error: 'Not a valid ZIPCODE',
          }],
        },
        {
          label: 'Address number',
          key: 'number',
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]$/,
            error: 'Not a valid number',
          }],
        },
        {
          label: 'Complement',
          key: 'complement',
        },
      ],
    }));
  }, []);

  const importContacts = async () => {
    const getAdress = async (value) => {
      const cep = value.replace('-', '').trim();
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);

      const {
        logradouro, bairro, localidade, uf,
      } = response.data;

      return {
        street: logradouro,
        // neighborhood: bairro,
        city: localidade,
        state: uf,
        country: 'Brasil',
      };
    };

    importer.requestDataFromUser().then(async ({ data }) => {
      data.forEach(async (item) => {
        const types = item.types.split(',').map((t) => t.trim());
        const contactDoc = item.document.trim().split(/\.|\/|-/).join('');
        console.log(contactDoc);
        if (!contacts.find((c) => c.id === contactDoc)) {
          if (contactDoc.length > 11) {
            try {
              const contactData = await getCNPJ(contactDoc);
              const contactCreate = {
                document: item.document,
                name: contactData.businessName,
                companyName: contactData.companyName,
                situation: contactData.situation,
                legalNature: contactData.legalNature,
                mainActivity: contactData.mainActivity,
                contact: {
                  email: item.email || contactData.contact.email,
                  phone: item.phone || contactData.contact.phone,
                  site: item.site,
                },
                address: contactData.address,
                types,
              };
              console.log(contactCreate);
              dispatch(Contact.store(contactCreate));
            } catch (error) {
              console.log(error);
            }
          } else {
            const address = await getAdress(item.cep);

            const contactCreate = {
              document: item.contact,
              name: item.name,
              contact: { email: item.email, phone: item.phone, site: item.site },
              address: { ...address, number: item.number, complement: item.complement },
              types,
            };

            console.log(contactCreate);
            dispatch(Contact.store(contactCreate));
          }
        }
      });

      importer.displaySuccess('All data imported!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
  };

  const contactTypes = [
    'Supllier', 'Partner', 'Sponsor', 'Participant', 'Client', 'Others',
  ];

  const handleExpanded = (id) => {
    if (expanded === id) setExpanded(null);
    else setExpanded(id);
  };

  const ContactView = (contact, type) => (
    <ExpansionPanel
      key={`${contact.id}-${type}`}
      className={classes.expansion}
      classes={{ expanded: classes.expansionExpanded }}
      expanded={expanded === `${contact.id}-${type}`}
      onChange={() => handleExpanded(`${contact.id}-${type}`)}
    >
      <ExpansionPanelSummary expandIcon={<ChevronDown size={16} />} style={{ padding: 0 }}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography className={classes.contactName}>
              {contact.name}
              {contact.types.length > 1 && (
                <span className={classes.contactTypes} style={{ paddingLeft: 8 }}>
                  {`(${contact.types.slice(0, -1).join(', ')} e ${contact.types.slice(-1)})`}
                </span>
              )}
            </Typography>
            <Typography className={classes.contactDoc}>{contact.document}</Typography>
          </Grid>
          <Grid item style={{ whiteSpace: 'nowrap' }}>
            {!mobile && contact.address && contact.address.postalCode && (
              <Typography className={classes.contactDoc}>
                {`${contact.address.city} / ${contact.address.state}`}
              </Typography>
            )}
            {permissionEdit && (
              <>
                <IconButton
                  color="primary"
                  aria-label="edit"
                  style={{ marginLeft: 16 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/register/contacts/${contact.id}`);
                  }}
                >
                  <Edit2 size={16} />
                </IconButton>
                {!cashFlow.find((c) => c.contact === contact.id) && (
                  <IconButton
                    color="primary"
                    aria-label="delete"
                    style={{ marginLeft: 8 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteContact(contact);
                    }}
                  >
                    <Trash2 size={16} />
                  </IconButton>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: mobile ? '0 8px 16px' : '0 16px 16px' }}>
        <Grid container spacing={1}>
          {contact.person === 'legal' && (
            <>
              <Grid item xs={12}>
                <Typography className={classes.label}>Company name</Typography>
                <Typography className={classes.value}>{contact.companyName}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography className={classes.label}>CNPJ status</Typography>
                <Typography className={classes.value}>{contact.situation}</Typography>
              </Grid>
              <Grid item sm={8}>
                <Typography className={classes.label}>Legal Nature</Typography>
                <Typography className={classes.value}>{contact.legalNature}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Main activity</Typography>
                <Grid container wrap="nowrap">
                  <Typography className={classes.value} style={{ whiteSpace: 'nowrap', marginRight: 16 }}>
                    {contact.mainActivity.code}
                  </Typography>
                  <Typography className={classes.value}>
                    {contact.mainActivity.description}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Typography className={classes.divider}>CONTACT</Typography>
          </Grid>
          <Grid item xs={12} sm={!contact.contact.phone && !contact.contact.site ? 12 : 6}>
            <Typography className={classes.label}>E-mail</Typography>
            <Typography className={classes.value}>{contact.contact.email}</Typography>
          </Grid>
          {contact.contact.phone && (
            <Grid item xs={12} sm={6}>
              <Typography className={classes.label}>Phone / Cellphone</Typography>
              <Typography className={classes.value}>{contact.contact.phone}</Typography>
            </Grid>
          )}
          {contact.contact.site && (
            <Grid item xs={12} sm={!contact.contact.phone ? 6 : 12}>
              <Typography className={classes.label}>Site</Typography>
              <Typography className={classes.value}>
                <a href={contact.contact.site} target="_blank" rel="noopener noreferrer">
                  {contact.contact.site}
                </a>
              </Typography>
            </Grid>
          )}
          {contact.transference && contact.transference.bank && (
            <>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography className={classes.divider}>BANK ACCOUNT</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>Bank</Typography>
                <Typography className={classes.value}>
                  {banks.find((b) => b.code === contact.transference.bank).name}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography className={classes.label}>Agency</Typography>
                <Typography className={classes.value}>{contact.transference.agency}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography className={classes.label}>Account</Typography>
                <Typography className={classes.value}>{contact.transference.code}</Typography>
              </Grid>
            </>
          )}
          {contact.address && contact.address.postalCode && (
            <>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography className={classes.divider}>ADDRESS</Typography>
              </Grid>

              <Grid item container spacing={1} justify="space-between">
                <Grid item>
                  <Typography className={classes.label}>ZIP code</Typography>
                  <Typography className={classes.value}>
                    {contact.address.postalCode}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.label}>Strett</Typography>
                  <Typography className={classes.value}>
                    {contact.address.street}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.label}>Number</Typography>
                  <Typography className={classes.value}>
                    {contact.address.number}
                  </Typography>
                </Grid>

                {contact.address.complement && (
                  <Grid item>
                    <Typography className={classes.label}>Complement</Typography>
                    <Typography className={classes.value}>
                      {contact.address.complement}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid item container spacing={1} justify="space-between">
                {/* <Grid item>
                  <Typography className={classes.label}>Neighbourhood</Typography>
                  <Typography className={classes.value}>
                    {contact.address.neighborhood}
                  </Typography>
                </Grid> */}
                <Grid item>
                  <Typography className={classes.label}>City</Typography>
                  <Typography className={classes.value}>
                    {contact.address.city}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.label}>State</Typography>
                  <Typography className={classes.value}>
                    {contact.address.state}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.label}>Country</Typography>
                  <Typography className={classes.value}>
                    {contact.address.country}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Grid container direction="column">
      {!!contacts && !!cashFlow ? (
        <>
          {contactTypes.map((type) => {
            const typeContacts = contacts.filter((c) => c.types.indexOf(type) >= 0);

            if (typeContacts.length > 0) {
              return (
                <ExpansionPanel key={type} className={classes.expansion}>
                  <ExpansionPanelSummary expandIcon={<ChevronDown />} style={{ padding: '8px 0' }}>
                    <span className={classes.title}>{type}</span>
                    <span className={classes.titleSecond}>
                      {`(${typeContacts.length})`}
                    </span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ padding: mobile ? 0 : '0 16px 16px' }}>
                    <Grid item xs={12}>
                      {typeContacts.map((contact) => ContactView(contact, type))}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            }

            return <></>;
          })}

          {contacts.length === 0 && (
            <Grid className={classes.noData}>
              <Typography>
                No contact registered
              </Typography>
            </Grid>
          )}

          {permissionEdit && (
            <Grid container justify="center" style={{ marginTop: 16 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => history.push('/register/contacts/new')}
                className={classes.button}
              >
                <Plus size={20} style={{ marginRight: 8 }} />
                Add contact
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={importContacts}
                disabled={!contacts || !cashFlow}
                className={classes.button}
              >
                <LogIn style={{ transform: 'rotate(180deg)', marginRight: 8 }} size={20} />
                Import contacts
              </Button>
            </Grid>
          )}

          {deleteContact && (
            <ConfirmDialog
              title={`Do you really want to delete the contact ${deleteContact.name}?`}
              onConfirm={() => {
                dispatch(Log.store(`Deleted contact ${deleteContact.name}`));
                dispatch(Contact.destroy(deleteContact.id));
              }}
              onClose={() => setDeleteContact(false)}
            />
          )}
        </>
      ) : (
        <Loading ready={!!contacts && !!cashFlow} content={150} />
      )}
    </Grid>
  );
}

export default ContactsList;
