import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS, functions } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

import Message from 'store/chat/message/controller';

const Channel = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;
    const { current: user } = getState().user;

    firestore.collection('companies').doc(company.id).collection('channels')
      .onSnapshot((ref) => {
        let channels = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        channels = channels.filter(
          (c) => c.members.indexOf('open') >= 0 || c.members.indexOf(user.id) >= 0,
        );
        dispatch(Company.loadChannels(channels));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').add({
        ...data, updatedAt: now, createdAt: now,
      })
      .then(() => {
        Mixpanel.track('Create a channel');
        dispatch(Alert.show({ message: 'Group created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  storeWithMessage: (channelData, messageData) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').add({
        ...channelData, updatedAt: now, createdAt: now,
      })
      .then((doc) => {
        Mixpanel.track('Create a channel');
        dispatch(Message.store(doc.id, messageData));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Edit a channel');
        dispatch(Alert.show({ message: 'Group updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a channel');
        dispatch(Alert.show({ message: 'Group deleted successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  addExternalMember: ({ member, chat, companyId }) => (dispatch) => {
    const addExternalMember = functions.httpsCallable('user-externalMemberChat');

    addExternalMember({ member, chat, companyId })
      .then(() => {
        Mixpanel.track('Create an external member');
        dispatch(Alert.show({ message: 'External member successfully added.', type: 'success' }));
      })
      .catch((error) => {
        const message = ERRORS[error.code] || error.message;
        dispatch(Alert.show({ message }));
      });
  },
};

export default Channel;
