import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import loading from 'assets/loading/loading.gif';

import { Container, ImageContainer, LoaderGif } from './style';

function Loading({ ready, content }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    let didCancel = false;

    if (show && ready) {
      setTimeout(() => {
        if (!didCancel) setShow(false);
      }, 300);
    } else if (!show && !ready) {
      if (!didCancel) setShow(true);
    }

    return () => {
      didCancel = true;
    };
  }, [ready, show]);

  return (
    <Container show={show} ready={ready} content={content}>
      <ImageContainer content={content}>
        <LoaderGif src={loading} alt="Page loading gif" />
      </ImageContainer>
    </Container>
  );
}

Loading.propTypes = {
  ready: PropTypes.bool.isRequired,
  content: PropTypes.number,
};

Loading.defaultProps = {
  content: 0,
};

export default Loading;
