import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

function PublicRoute({
  component: Component, path, exact, childrenRoutes,
}) {
  return (
    <div>
      <Route path={path} exact={exact}>
        <Component />
      </Route>

      {childrenRoutes
        && childrenRoutes.map(({ routerComponent: RouterComponent, ...route }) => (
          <RouterComponent
            key={route.path}
            path={route.path}
            component={route.component}
            load={route.load}
            exact={route.exact}
            childrenRoutes={route.childrenRoutes}
          />
        ))}
    </div>
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  childrenRoutes: PropTypes.arrayOf(PropTypes.shape(PublicRoute.propTypes)),
};

PublicRoute.defaultProps = {
  exact: false,
  childrenRoutes: [],
};

export default PublicRoute;
