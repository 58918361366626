import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import createImporter from 'services/importer';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import CostCenter from 'store/financial/cost-center/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Plus, Edit2, Trash2, LogIn,
} from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function CostCenterList() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const { costCenter, cashFlow, categories } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [importer, setImporter] = useState(null);
  const [deleteCostCenter, setDeleteCostCenter] = useState(false);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Identification number',
          key: 'code',
        },
        {
          label: 'Name',
          key: 'name',
          isRequired: true,
        },
        {
          label: 'Categories',
          key: 'categories',
        },
      ],
    }));
  }, []);

  const importCostCenter = () => {
    importer.requestDataFromUser().then(async ({ data }) => {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];

        let categoriesCost = [];
        const itemCategories = item.categories.split(',');
        if (!itemCategories[0]) categoriesCost = ['all'];
        itemCategories.forEach((itemCategory) => {
          const category = categories.find(
            (c) => c.name.toLowerCase() === itemCategory.trim().toLowerCase(),
          );
          if (category) categoriesCost.push(category.id);
        });

        dispatch(CostCenter.store({ ...item, categories: categoriesCost, events: [] }));
      }

      importer.displaySuccess('All data imported!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
  };

  return (
    <Grid container direction="column">
      {!!costCenter && !!cashFlow ? (
        <>
          <List style={{ paddingBottom: 24 }}>
            {costCenter.sort((a, b) => (a.code ? a.code - b.code : a.name - b.name))
              .map((center) => (
                <ListItem key={center.id} style={!mobile ? {} : { padding: '8px 0' }}>
                  <ListItemText
                    primary={`${center.code ? `${center.code} - ` : ''}${center.name}`}
                    primaryTypographyProps={{ style: { fontWeight: 900, fontSize: 18 } }}
                  />
                  {permissionEdit && (
                    <ListItemSecondaryAction style={!mobile ? {} : { right: 0 }}>
                      <Grid container alignItems="center">
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          style={{ marginLeft: 16 }}
                          onClick={() => history.push(`/register/cost-center/${center.id}`)}
                        >
                          <Edit2 size={16} />
                        </IconButton>
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          style={{ marginLeft: 8 }}
                          onClick={() => setDeleteCostCenter(center)}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                      </Grid>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
          </List>
          {costCenter.length === 0 && (
            <Grid className={classes.noData}>
              <Typography>
                No cost center registered
              </Typography>
            </Grid>
          )}
          {permissionEdit && (
            <Grid container justify="center">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => history.push('/register/cost-center/new')}
                className={classes.button}
              >
                <Plus size={20} style={{ marginRight: 8 }} />
                Add cost center
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={importCostCenter}
                disabled={!costCenter || !cashFlow}
                className={classes.button}
              >
                <LogIn style={{ transform: 'rotate(180deg)', marginRight: 8 }} size={20} />
                Import cost centers
              </Button>
            </Grid>
          )}

          {deleteCostCenter && (
            <ConfirmDialog
              title={`Do you really want to delete the cost center ${deleteCostCenter.name}?`}
              onConfirm={() => {
                dispatch(Log.store(`Deleted the cost center ${deleteCostCenter.name}`));
                dispatch(CostCenter.destroy(deleteCostCenter.id));
              }}
              onClose={() => setDeleteCostCenter(false)}
            />
          )}
        </>
      ) : (
        <Loading ready={!!costCenter && !!cashFlow} content={150} />
      )}
    </Grid>
  );
}

export default CostCenterList;
