import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '0 48px 32px',
  },
  tabsContainer: {
    position: 'absolute',
    top: -4,
    left: 77,
    width: 'calc(100% - 155px)',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(),
      width: 'calc(100% - 16px)',
    },
  },
  ganttContainer: {
    flex: 1,
    marginTop: 28,
    paddingTop: 16,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    '& .gantt_container': {
      border: 'none',
    },
    '& .gantt_layout_x': {
      // paddingTop: '40px !important',
    },
    '& .gantt_scale_cell': {
      lineHeight: 1.4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& .gantt_link_control, & .gantt_task_progress_drag': {
      display: 'none !important',
    },
  },
  tabs: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)',
  },
  tab: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px ${theme.spacing(0.75)}px 70px`,
    marginLeft: -48,
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    border: 0,
    backgroundColor: lighten(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tabWHover: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
    },
  },
  tabMobile: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: 'calc(100% - 16px)',
  },
  tabSelected: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    border: 0,
    marginLeft: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  tabTitle: {
    color: 'white',
    fontWeight: 500,
  },
  select: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 500,
    minWidth: 150,
    height: 24,
    '& svg': {
      color: 'white',
    },
    '&:before, &:after': {
      content: 'none',
    },
  },
  rightFab: {
    position: 'absolute',
    right: 0,
  },
  addButton: {
    position: 'fixed',
    right: theme.spacing(),
    bottom: theme.spacing(),
    zIndex: 1300,
  },
  // Gantt
  weekend: {
    background: '#f4f7f4 !important',
  },
  // Checklist
  checklist: {
    position: 'absolute',
    top: 28,
    bottom: 32,
    width: 360,
    background: 'white',
    boxShadow: theme.shadows[2],
    overflowY: 'scroll'
  },
  checklistHeader: {
    height: 96,
    borderBottom: '1px solid #cecece',
    // marginTop: 40,
  },
  checklistTasks: {
    width: '100%',
    height: 'calc(100% - 96px)',
    overflow: 'overlay',
    boxShadow: theme.shadows[2],
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  checklistItems: {
    padding: `0 ${theme.spacing()}px`,
    height: 35,
    borderBottom: '1px solid #ebebeb',
  },
  checklistItemName: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  iconButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    opacity: 0.6,
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 1,
    },
  },
  avatar: {
    width: 20,
    height: 20,
    background: theme.palette.primary.main,
    fontSize: 10,
    marginRight: -5,
  },
  expandIcon: {
    opacity: 0.6,
    cursor: 'pointer',
    marginRight: 8,
    '&:hover': {
      opacity: 1,
    },
  },
  btnOrder: {
    padding: '0 4px',
    minWidth: 0,
    width: 24,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none',
    },
  },
  noData: {
    padding: theme.spacing(),
    color: '#777',
    fontSize: 18,
    textAlign: 'center',
  },
}));
