import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import styles from './style';

function DeliveryView({ supplier }) {
  const classes = styles();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography variant="overline" className={classes.infoTitle}>
        Expected delivery date
        </Typography>
        <Typography className={classes.infoText}>
          {supplier.delivery.estimated
            ? format(supplier.delivery.estimated.toDate(), 'dd MMM yyyy', { locale: ptBR })
            : 'Not defined'}
        </Typography>
      </Grid>

      {supplier.delivery.done && (
        <Grid item xs={12} sm={6}>
          <Typography variant="overline" className={classes.infoTitle}>
            Delivery date
          </Typography>
          <Typography className={classes.infoText}>
            {format(supplier.delivery.done.toDate(), 'dd MMM yyyy', { locale: ptBR })}
          </Typography>
        </Grid>
      )}

      {!supplier.delivery.done && supplier.delivery.code && (
        <Grid item xs={12} sm={6}>
          <Typography variant="overline" className={classes.infoTitle}>
            Tracking code
          </Typography>
          <Typography className={classes.infoText}>
            {supplier.delivery.code}
          </Typography>
        </Grid>
      )}

      {!supplier.delivery.done && supplier.delivery.site && (
        <Grid item xs={12} sm={12}>
          <Typography variant="overline" className={classes.infoTitle}>
            Website for consult
          </Typography>
          <Typography className={classes.infoText}>
            <a href={supplier.delivery.site} target="_blank" rel="noopener noreferrer">
              {supplier.delivery.site}
            </a>
          </Typography>
        </Grid>
      )}
    </>
  );
}

DeliveryView.propTypes = {
  supplier: PropTypes.shape({
    delivery: PropTypes.object,
  }).isRequired,
};

export default DeliveryView;
