export default {
  eRvnUsYp4pmiLb2Nobrf: {
    agency: '2154',
    createdAt: new Date('2020-02-13 18:48:43'),
    bank: '001',
    initialValue: 10000,
    test: true,
    code: '165984-9',
    updatedAt: new Date('2020-02-13 18:48:43'),
  },
  nVEmCAmkf36DsggffRjM: {
    bank: '104',
    initialValue: 5000,
    test: true,
    code: '252984-8',
    updatedAt: new Date('2020-02-13 18:48:56'),
    agency: '2847',
    createdAt: new Date('2020-02-13 18:48:56'),
  },
};
