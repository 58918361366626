import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 130px)',
    marginLeft: 130,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  root: {
    width: 'calc(100% - 64px)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
    },
  },
  toolbar: {
    padding: '16px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(),
      flexWrap: 'wrap',
    },
  },
  toolbarButton: {
    marginRight: theme.spacing(),
  },
  toolbarTitle: {
    color: theme.palette.tertiary.main,
    marginRight: theme.spacing(),
    fontWeight: '600',
  },
  withoutData: {
    padding: theme.spacing(),
    color: theme.palette.common.grey,
    textAlign: 'center',
    fontWeight: 300,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeader: {
    color: `${theme.palette.tertiary.main} !important`,
    textTransform: 'uppercase !important',
    fontSize: '16px !important',
    padding: `${theme.spacing()}px !important`,
    whiteSpace: 'nowrap !important',
  },
  tableCell: {
    padding: `${theme.spacing(0.5)}px !important`,
    border: '0 !important',
    height: 65,
    fontSize: '14px !important',
    '&:last-child': {
      paddingRight: `${theme.spacing()}px !important`,
    },
  },
  tableCategory: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    textTransform: 'uppercase !important',
    color: theme.palette.common.black,
    paddingLeft: `${theme.spacing()}px !important`,
    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing()}px !important`,
      borderTop: '0 !important',
      borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    },
  },
  tableCategoryIcon: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px !important`,
  },
  categoryColor: {
    width: 5,
    height: 24,
    float: 'left',
    marginTop: -2,
    borderRadius: 3,
    marginRight: theme.spacing(0.5),
  },
  legend: {
    opacity: 1,
  },
  legendTooltip: {
    background: 'white',
    padding: theme.spacing(),
    boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)',
  },
  legendStep: {
    display: 'inline-flex',
    padding: '4px 8px',
  },
  legendStepText: {
    fontSize: 16,
    color: theme.palette.common.black,
    marginLeft: theme.spacing(),
    fontWeight: 500,
  },
  legendButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  steps: {
    display: 'flex',
  },
  step: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    margin: 'auto',
    marginLeft: '-40%',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(),
      marginLeft: -28,
    },
    '&:nth-child(1)': {
      marginLeft: 'auto',
    },
  },
  avatar: {
    display: 'inline-flex',
    margin: `0 ${theme.spacing(0.25)}px`,
    background: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing()}px ${theme.spacing(0.5)}px 0`,
    },
  },
  subinfo: {
    color: theme.palette.common.grey,
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: theme.spacing(0.5),
  },
  tooltip: {
    fontSize: 12,
  },
  contentExpandMobile: {
    marginBottom: theme.spacing(),
  },
  expasionPanel: {
    boxShadow: 'initial',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  buttonMobile: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  },
}));
