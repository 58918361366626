import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Material Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

// Styles and Options
import Loading from 'common/loading';
import { Typography } from '@material-ui/core';
import styles from './style';
import { getOptions, generateData } from './utils';

function CashFlowChart() {
  const classes = styles();

  const { eventId } = useParams();

  const { current: currentUser } = useSelector((store) => store.user);
  const { users, tasks } = useSelector((store) => store.company);

  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (tasks && users) {
      const evtTasks = tasks.filter((c) => c.event === eventId);
      setChartData(generateData(evtTasks, users));
    }
  }, [eventId, tasks, users]);

  const [firstCurrentUserName] = currentUser.name.split(' ');

  console.log(chartData);

  return (
    <Grid container direction="column" style={{ height: '100%' }}>
      {!tasks || !users ? (
        <Loading ready={!!tasks && users} content={300} />
      ) : (
        <>
          <Grid justify="space-between" alignItems="center">
            <Typography className={classes.title}>DAILY DELIVERIES</Typography>
          </Grid>

          <Grid
            container
            direction="column"
            wrap="nowrap"
            style={{ flex: 1, overflow: 'overlay', paddingTop: 24, margin: '0 -16px', width: 'calc(100% + 32px)' }}
          >
            <Grid container wrap="nowrap" className={`${classes.user} ${classes.currentUser}`}>
              <Grid container alignItems="center" wrap="nowrap" className={classes.userInfo}>
                <Avatar className={classes.avatar} src={currentUser.avatar} alt={currentUser.name}>
                  {currentUser.initials}
                </Avatar>
                <Typography className={classes.name}>{firstCurrentUserName}</Typography>
              </Grid>
              {chartData[currentUser.id] && chartData[currentUser.id].length > 0 ? (
                <ReactEcharts
                  option={getOptions(chartData[currentUser.id])}
                  className={classes.chart}
                  notMerge
                  lazyUpdate
                  showLoading={false}
                  opts={{ renderer: 'svg' }}
                />
              ) : (
                <Grid container justify="center" alignItems="center" style={{ width: '100%', height: 50 }}>
                  <Typography variant="h4" className={classes.nodata}>
                    No data to show on the chart.
                  </Typography>
                </Grid>
              )}
            </Grid>
            {users.filter((u) => !u.deletedAt && u.id !== currentUser.id).map((user) => {
              const [firstName] = user.name.split(' ');
              return (
                <Grid container wrap="nowrap" className={classes.user}>
                  <Grid container alignItems="center" wrap="nowrap" className={classes.userInfo}>
                    <Avatar className={classes.avatar} src={user.avatar} alt={user.name}>
                      {user.initials}
                    </Avatar>
                    <Typography className={classes.name}>{firstName}</Typography>
                  </Grid>
                  {chartData[user.id] && chartData[user.id].length > 0 ? (
                    <ReactEcharts
                      option={getOptions(chartData[user.id])}
                      className={classes.chart}
                      notMerge
                      lazyUpdate
                      showLoading={false}
                      opts={{ renderer: 'svg' }}
                    />
                  ) : (
                    <Grid container justify="center" alignItems="center" style={{ width: '100%', height: 100 }}>
                      <Typography variant="h4" className={classes.nodata}>
                        No data to show on the chart.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default CashFlowChart;
