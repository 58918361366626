import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

// Material UI components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Filter, Archive, Plus } from 'react-feather';
import { Suppliers, Financial, Partners } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Tools from 'app/tools';
// import ArchivedMenu from './components/archived';
import Card from './components/card';
import FilterDialog from './components/filter';
import { contacts } from './fake-data';
import styles from './style';

const COLUMNS = [
  {
    name: 'Contact',
    description:
      'Here are all the suppliers that must be contacted and quote requested.',
    add: true,
  },
  {
    name: 'Negotiation',
    description:
      'Hmmm, what will be the best option for my event? Here you can compare quotes and hire the supplier you like the most.',
  },
  {
    name: 'Register',
    description:
      'Is this the supplier you want? Input their employer tax information and let\'s hire them.',
  },
  {
    name: 'Closed',
    description:
      'Deal! Upload contract information.',
  },
  {
    name: 'Received',
    description: 'Everything is in order, you can be sure that your event will be a success.',
  },
];

function Negotiations() {
  const classes = styles();
  const history = useHistory();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { eventId } = useParams();

  const { current: company } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [openFilter, setOpenFilter] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [showCategories, setShowCategories] = useState(false);

  const permissionView = company.plan !== 'free' && company.plan !== 'planner' && (
    !permissions || !permissions.manager || permissions.manager.sponsors.view
  );

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [history, permissionView]);

  const tabsToolbar = [
    { title: 'Dashboard', path: `/manager/${eventId}/sponsors` },
    { title: 'Negotiations', selected: true },
  ];

  const toolbarIcons = [
    {
      label: 'Filter',
      icon: <Filter />,
      // badge: filterBadge,
      // click: () => ,
    },
    {
      id: 'archive',
      label: 'File',
      icon: <Archive />,
      // menu: ArchivedMenu,
    },
  ];
  
  const openFeedback = () => {
    document.getElementById('help-center-button').click();
  };

  return (
    <div className={classes.containerPage}>
      <Toolbar
        avatar
        defaultIcons
        title="Sponsorships"
        position="fixed"
        sidebar="/manager/sponsors"
        tabs={tabsToolbar}
        icons={toolbarIcons}
      />

      {upSm ? (
        <Grid container direction="column" className={classes.container}>
          <Grid container className={classes.root} spacing={2} alignItems="flex-start">
            {COLUMNS.map((col, key) => (
              <Grid item className={classes.steps} key={col.name}>
                <Typography variant="h5" align="center" className={classes.textStatus}>
                  {col.name}
                </Typography>
                <div className={classes.cardGrid}>
                  {col.add && (
                    <Plus
                      size={32}
                      strokeWidth="3"
                      className={classes.addSupplier}
                      onClick={() => {}}
                    />
                  )}
                  {contacts
                    .filter((s) => s.status === key && !s.archived)
                    .map((contact) => (
                      <Card
                        key={contact.id}
                        contact={contact}
                        showCategories={showCategories}
                        setShowCategories={setShowCategories}
                      />
                    ))}
                  <Typography className={classes.stepDescription}>
                    {col.description}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <>
          <Tabs
            value={tabIndex}
            textColor="primary"
            classes={{ flexContainer: classes.tabs }}
            onChange={(e, i) => setTabIndex(i)}
          >
            {COLUMNS.map((col) => (
              <Tab key={col.name} label={col.name} className={classes.tab} />
            ))}
          </Tabs>
          <Grid container>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={(i) => setTabIndex(i)}
              enableMouseEvents
            >
              {COLUMNS.map((col, key) => (
                <Grid key={col.name} item xs className={classes.cardGrid}>
                  {contacts
                    .filter((s) => s.status === key && !s.archived)
                    .map((contact) => (
                      <Card
                        key={contact.id}
                        contact={contact}
                        showCategories={showCategories}
                        setShowCategories={setShowCategories}
                      />
                    ))}
                  <Typography className={classes.stepDescription}>
                    {col.description}
                  </Typography>
                </Grid>
              ))}
            </SwipeableViews>
          </Grid>
        </>
      )}
 
      <Grid container alignItems="center" justify="center" className={classes.feedback}>
        <span>This module is under development</span>
        <Button variant="contained" color="primary" onClick={openFeedback}>
        send your feedback
        </Button>
      </Grid>

      <Tools />

      {openFilter && <FilterDialog onClose={() => setOpenFilter(false)} />}
    </div>
  );
}

export default Negotiations;
