import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawer: {
    left: 'inherit !important',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  content: {
    width: '100vw',
    maxWidth: 450,
    padding: `${theme.spacing()}px ${theme.spacing(1.5)}px`,
  },
  title: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: theme.spacing(),
  },
  subtitle: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },

  // Avatar
  avatar: {
    width: 150,
    height: 150,
    backgroundColor: theme.palette.primary.main,
    fontSize: 60,
  },
  avatarAction: {
    position: 'absolute',
    bottom: 8,
    width: 48,
    height: 48,
    right: 8,
  },
  labelListUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  labelFabUpload: {
    width: '100%',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  // Members
  avatarList: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(),
  },
}));
