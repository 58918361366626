import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  label: {
    fontSize: 10,
    lineHeight: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.tertiary.main,
    marginBottom: 4,
  },
  parentName: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  btnRemove: {
    marginLeft: theme.spacing(),
    color: theme.palette.common.grey,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
}));
