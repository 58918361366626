import Company from 'app/company';
// Main Components
import Events from 'app/home/events';
import EventSwitch from 'app/home/events/switch';
import EventForm from 'app/home/events/form';
import Team from 'app/home/team';
import TeamForm from 'app/home/team/form';
import Profile from 'app/home/profile';
import Register from 'app/home/register';

// Dashboard Components
// import GoalsForm from 'app/home/main-dashboard/goals';
// import CashFlowForm from 'app/event/financial/children/cash-flow/form';
// import CashFlowPay from 'app/event/financial/children/cash-flow/pay';
// import CashFlowReceive from 'app/event/financial/children/cash-flow/receive';
// import CashFlowView from 'app/event/financial/children/cash-flow/view';

// Categories Components
import CategoryForm from 'app/home/register/categories/form';

// Account Components
import AccountForm from 'app/home/register/accounts/form';

// Cost Center Components
import CostCenterForm from 'app/home/register/cost-center/form';

// Contact Components
import ContactForm from 'app/home/register/contacts/form';

// Routes Components
import PrivateRoute from '../components/PrivateRoute';


export default [
  {
    path: '/',
    component: Events,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/company/new',
    component: Company,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/events',
    component: Events,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      {
        path: '/events/new',
        component: EventForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/events/edit/:eventId',
        component: EventForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/events/init',
        component: EventSwitch,
        routerComponent: PrivateRoute,
        exact: true,
      }
    ],
  },
  {
    path: '/team',
    component: Team,
    routerComponent: PrivateRoute,
    childrenRoutes: [{
      path: '/team/new',
      component: TeamForm,
      routerComponent: PrivateRoute,
      exact: true,
    }, {
      path: '/team/:userId',
      component: TeamForm,
      routerComponent: PrivateRoute,
      exact: true,
    }],
  },
  {
    path: '/profile',
    component: Profile,
    routerComponent: PrivateRoute,
  },
  // Register
  {
    path: '/register',
    component: Register,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      // Categories
      {
        path: '/register/categories/new',
        component: CategoryForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/register/categories/:categoryId',
        component: CategoryForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      // Accounts
      {
        path: '/register/accounts/new',
        component: AccountForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/register/accounts/:accountId',
        component: AccountForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      // Cost Center
      {
        path: '/register/cost-center/new',
        component: CostCenterForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/register/cost-center/:centerId',
        component: CostCenterForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      // Contacts
      {
        path: '/register/contacts/new',
        component: ContactForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/register/contacts/:contactId',
        component: ContactForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
];
