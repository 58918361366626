import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  documentationHelper: {
    fontSize: 11,
    color: theme.palette.common.grey,
    lineHeight: 1.8,
    textTransform: 'uppercase',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontSize: 10,
    cursor: 'pointer',
    lineHeight: 1.7,
    textTransform: 'uppercase',
    marginTop: theme.spacing(0.5),
  },
  divider: {
    margin: `0 0 ${theme.spacing()}px`,
    background: theme.palette.secondary.main,
  },
  dividerText: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  container: {
    color: theme.palette.common.grey,
  },
  infoTitle: {
    color: theme.palette.primary.main,
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  infoText: {
    color: theme.palette.common.grey,
    fontSize: 16,
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  document: {
    background: lighten(theme.palette.secondary.main, 0.9),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    color: theme.palette.common.black,
  },

  // Chat
  chat: {
    boxShadow: 'none',
    border: `2px solid ${lighten(theme.palette.primary.main, 0.1)}`,
    borderRadius: 4,
    width: '100%',
    '&:before': {
      display: 'none',
    },
  },
  chatHeader: {
    background: lighten(theme.palette.primary.main, 0.1),
    color: 'white',
    textTransform: 'uppercase',
  },
  chatHeaderContent: {
    margin: '20px 0',
  },
  chatIcon: {
    color: 'white',
  },
  chatContent: {
    maxHeight: 300,
    overflow: 'auto',
  },
  chatMessage: {
    position: 'relative',
    width: '100%',
    maxWidth: 'fit-content',
    padding: theme.spacing(),
    margin: theme.spacing(),
    borderRadius: 4,
    '& > p': {
      fontSize: 12,
      color: theme.palette.common.grey,
      margin: 0,
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      borderBottom: `${theme.spacing()}px solid transparent`,
      top: 0,
    },
  },
  chatMessageSend: {
    background: lighten(theme.palette.primary.main, 0.8),
    textAlign: 'right',
    '&:after': {
      borderLeft: `${theme.spacing()}px solid ${lighten(theme.palette.primary.main, 0.8)}`,
      right: -12,
    },
  },
  chatMessageReceive: {
    background: lighten(theme.palette.secondary.main, 0.8),
    '&:after': {
      borderRight: `${theme.spacing()}px solid ${lighten(theme.palette.secondary.main, 0.8)}`,
      left: -12,
    },
  },
  chatSender: {
    padding: theme.spacing(),
  },
}));
