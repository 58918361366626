/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker, KeyboardDateTimePicker, DateTimePicker, DatePicker,
} from '@material-ui/pickers';

// Icons and styles
import { Calendar } from 'react-feather';
import styles from './style';

function DateTimeInput({
  value, size, onChange, disabled, label, pickerProps, keyboard, showTime, error, helperText,
}) {
  const classes = styles();

  const defaultProps = {
    size,
    label,
    value,
    disabled,
    onChange,
    onClick: (e) => e.stopPropagation(),
    error: !!error,
    ampm: false,
    autoOk: true,
    clearable: true,
    fullWidth: true,
    clearLabel: 'CLEAR',
    cancelLabel: 'CANCEL',
    inputVariant: 'outlined',
    format: showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy',
    KeyboardButtonProps: { className: classes.iconDate },
    keyboardIcon: <Calendar size={20} />,
    minDateMessage: '',
    maxDateMessage: '',
    invalidDateMessage: '',
    ...pickerProps,
  };

  let picker = <KeyboardDatePicker {...defaultProps} />;

  if (showTime && keyboard) picker = <KeyboardDateTimePicker {...defaultProps} />;
  if (showTime && !keyboard) picker = <DateTimePicker {...defaultProps} />;
  if (!showTime && !keyboard) picker = <DatePicker {...defaultProps} />;

  return (
    <>
      {picker}
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

DateTimeInput.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  showTime: PropTypes.bool,
  keyboard: PropTypes.bool,
  pickerProps: PropTypes.shape({}),
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

DateTimeInput.defaultProps = {
  value: null,
  showTime: false,
  keyboard: false,
  pickerProps: null,
  label: '',
  helperText: null,
  disabled: false,
  error: false,
};

export default DateTimeInput;
