export const contacts = [
  {
    id: 'contact1',
    name: 'Company S.A.',
    quotas: ['quota1'],
    status: 0,
  },
  {
    id: 'contact2',
    name: 'Company S.A.',
    quotas: ['quota2'],
    status: 0,
  },
  {
    id: 'contact3',
    name: 'Company S.A.',
    quotas: ['quota2'],
    status: 0,
  },
  {
    id: 'contact4',
    name: 'Company S.A.',
    quotas: ['quota3', 'quota2'],
    status: 0,
  },
  {
    id: 'contact5',
    name: 'Company S.A.',
    quotas: ['quota1', 'quota2'],
    status: 0,
  },
  // Negociando
  {
    id: 'contact6',
    name: 'Company S.A.',
    quotas: ['quota1'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 50000 },
      ],
      total: 50000,
    },
    status: 1,
  },
  {
    id: 'contact7',
    name: 'Company S.A.',
    quotas: ['quota1'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 50000 },
      ],
      total: 50000,
    },
    status: 1,
  },
  {
    id: 'contact8',
    name: 'Company S.A.',
    quotas: ['quota2'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 12500 },
        { due: new Date('2021-12-21'), value: 12500 },
      ],
      total: 25000,
    },
    status: 1,
  },
  // Cadastro
  {
    id: 'contact10',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000 },
      ],
      total: 10000,
    },
    register: { cnpj: '848518814' },
    status: 2,
  },
  {
    id: 'contact11',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 5000 },
        { due: new Date('2021-12-21'), value: 5000 },
      ],
      total: 10000,
    },
    register: { send: true },
    status: 2,
  },
  {
    id: 'contact12',
    name: 'Company S.A.',
    quotas: ['quota1'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000 },
        { due: new Date('2021-12-21'), value: 10000 },
        { due: new Date('2021-12-21'), value: 10000 },
        { due: new Date('2021-12-21'), value: 10000 },
        { due: new Date('2021-12-21'), value: 10000 },
      ],
      total: 50000,
    },
    status: 2,
  },
  // Fechado
  {
    id: 'contact13',
    name: 'Company S.A.',
    quotas: ['quota1'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 25000, cashFlow: 'cash1' },
        { due: new Date('2021-12-21'), value: 25000, cashFlow: 'cash2' },
      ],
      total: 50000,
    },
    register: { cnpj: '848518814' },
    status: 3,
  },
  {
    id: 'contact14',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000, cashFlow: 'cash3' },
      ],
      total: 10000,
    },
    register: { send: true },
    status: 3,
  },
  {
    id: 'contact15',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000, cashFlow: 'cash4' },
      ],
      total: 10000,
    },
    status: 3,
  },
  {
    id: 'contact16',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 5000, cashFlow: 'cash5' },
        { due: new Date('2021-12-21'), value: 5000, cashFlow: 'cash6' },
      ],
      total: 10000,
    },
    status: 3,
  },
  // Recebido
  {
    id: 'contact17',
    name: 'Company S.A.',
    quotas: ['quota2'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 25000, cashFlow: 'cash7' },
      ],
      total: 25000,
    },
    register: { cnpj: '848518814' },
    status: 4,
  },
  {
    id: 'contact18',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000, cashFlow: 'cash8' },
      ],
      total: 10000,
    },
    register: { send: true },
    status: 4,
  },
  {
    id: 'contact19',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000, cashFlow: 'cash9' },
      ],
      total: 10000,
    },
    status: 4,
  },
  {
    id: 'contact20',
    name: 'Company S.A.',
    quotas: ['quota3'],
    payment: {
      installments: [
        { due: new Date('2021-12-21'), value: 10000, cashFlow: 'cash10' },
      ],
      total: 10000,
    },
    status: 4,
  },
];

export const cashFlow = [
  {
    id: 'cash1',
    value: 25000,
    dueAt: new Date('2021-12-21'),
    completed: { on: true },
  },
  {
    id: 'cash2',
    value: 25000,
    dueAt: new Date('2021-12-21'),
    completed: { on: false },
  },
  {
    id: 'cash3',
    value: 10000,
    dueAt: new Date('2021-12-21'),
    completed: { on: false },
  },
  {
    id: 'cash4',
    value: 10000,
    dueAt: new Date('2021-12-21'),
    completed: { on: false },
  },
  {
    id: 'cash5',
    value: 5000,
    dueAt: new Date('2021-12-21'),
    completed: { on: false },
  },
  {
    id: 'cash6',
    value: 5000,
    dueAt: new Date('2021-12-21'),
    completed: { on: false },
  },
  {
    id: 'cash7',
    value: 25000,
    dueAt: new Date('2021-12-21'),
    completed: { on: true },
  },
  {
    id: 'cash8',
    value: 10000,
    dueAt: new Date('2021-12-21'),
    completed: { on: true },
  },
  {
    id: 'cash9',
    value: 10000,
    dueAt: new Date('2021-12-21'),
    completed: { on: true },
  },
  {
    id: 'cash10',
    value: 10000,
    dueAt: new Date('2021-12-21'),
    completed: { on: true },
  },
];

export const quotas = [
  {
    id: 'quota1',
    name: 'Cota Diamante',
    color: '#49E5CE',
    value: 50000,
    limit: 5,
    sales: 2,
  },
  {
    id: 'quota2',
    name: 'Cota Ouro',
    color: '#E0D425',
    value: 25000,
    limit: 7,
    sales: 1,
  },
  {
    id: 'quota3',
    name: 'Cota Pratra',
    color: '#D2D2D2',
    value: 10000,
    limit: 10,
    sales: 8,
  },
];
