import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

// Material UI components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import useEventController from 'store/event/controller';
import Scenary from 'store/scenary/controller';

// Icons, Commons and Styles
import { Planning, Financial, Productivity } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import styles from './style';

// Components
import Budget from './components/budget';
import Scenaries from './components/scenaries';
import CostChart from './components/charts/cost';
import RevenueChart from './components/charts/revenue';

function FinancialPlan() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { eventId } = useParams();

  // Controller
  const Event = useEventController(dispatch);

  const { current: company } = useSelector((state) => state.company);
  const { current: event, scenaries } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [isLoading, setisLoading] = useState(false);

  const [scenary, setScenary] = useState(null);

  const permissionView = company.plan !== 'free' && (
    !permissions || !permissions.planner || permissions.planner.financial.view
  );
  const permissionEdit = !permissions || !permissions.planner || permissions.planner.financial.edit;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
      setisLoading(false);
    }
  }, [Event, event, eventId]);

  // Load data to use in this module
  useEffect(() => {
    if (!isLoading && !scenaries) dispatch(Scenary.index(eventId));
  }, [isLoading, scenaries, eventId, dispatch]);

  useEffect(() => {
    if (scenaries && scenaries.length === 0 && permissionEdit) {
      history.push(`/planner/${eventId}/financial/scenaries/new`);
    } else if (scenaries) {
      setScenary(scenaries[0]);
    }
  }, [scenaries, eventId, dispatch, history, permissionEdit]);

  return (
    <Grid container>
      <Toolbar avatar sidebar="/planner/financial" title="FINANCIAL PLANNING" />

      <Grid container direction="column" wrap="nowrap" className={classes.container}>

        {scenaries && scenaries.length === 0 ? (
          <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100vh' }}>
            {permissionEdit ? (
              <>
                <Typography className={classes.noData}>
                  START BUDGETING YOUR EVENT'S COSTS AND REVENUE
                </Typography>
                <Button
                  size="large"
                  color="secondary"
                  variant="outlined"
                  onClick={() => history.push(`/planner/${eventId}/financial/scenaries/new`)}
                >
                  SIMULATE
                </Button>
              </>
            ) : (
              <Typography className={classes.noData}>
                YOU DO NOT HAVE PERMISSION TO MAKE FINANCIAL PLANNING
              </Typography>
            )}
          </Grid>
        ) : (
          <>
            <Grid container justify="center" className={classes.row} style={{ padding: 0, paddingTop: 40 }}>
              <Scenaries scenary={scenary} setScenary={setScenary} />
            </Grid>

            <Grid container justify="center" className={classes.row}>
              <Budget scenary={scenary} />
            </Grid>

            <Grid container spacing={3} justify="center" className={classes.row}>
              <RevenueChart scenary={scenary} />
              <CostChart scenary={scenary} />
            </Grid>
          </>
        )}

        <Tools />

        <Onboarding id="plan" title="Plan your event">
          <iframe
            allowfullscreen
            width="100%"
            height="315"
            title="plan"
            frameborder="0"
            src="https://www.youtube.com/embed/lGYodgSyAL8"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />  
        </Onboarding>

      </Grid>
    </Grid>
  );
}

export default FinancialPlan;
