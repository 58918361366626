import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { addDays } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Checklist from 'store/productivity/checklist/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';
import FormConfig from './form-config';

function CashFlowForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { taskId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { users, labels, tasks } = useSelector((store) => store.company);
  const { checklist } = useSelector((store) => store.event);

  const [edit, setEdit] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [startAt, setStartAt] = useState(new Date());
  const [schema, setSchema] = useState([]);
  const [initialForm, setInitialForm] = useState({
    label: '', startAt: new Date(), endAt: addDays(new Date(), 1),
  });

  useEffect(() => {
    if (checklist && taskId) {
      const task = checklist.find((c) => c.id === taskId);
      if (task) {
        setEdit(true);
        setInitialForm({
          label: task.label,
          startAt: task.startAt ? task.startAt.toDate() : new Date(),
          endAt: task.endAt ? task.endAt.toDate() : new Date(),
          responsible: task.responsible || '',
          tag: task.tag || '',
        });
      } else history.goBack();
    }
  }, [checklist, taskId, history]);

  useEffect(() => {
    if (tasks && checklist) {
      const disabled = !!tasks.find((t) => t.parent === taskId);
      setSchema(FormConfig({
        startAt, setStartAt, classes, users, checklist, taskId, labels, disabled,
      }));
    }
  }, [startAt, setStartAt, classes, users, checklist, taskId, labels, tasks]);

  const submit = async (data) => {
    if (edit) {
      dispatch(Log.store('Edited an action plan task'));
      dispatch(Checklist.update(taskId, data));
    } else {
      dispatch(Log.store('Created a new task in the action plan'));
      const [lastTask] = checklist.sort((a, b) => b.order - a.order);
      let order = 0;
      if (lastTask) order = lastTask.order + 1;
      dispatch(Checklist.store({ ...data, order }));
    }
    history.goBack();
  };

  const removeCash = () => {
    dispatch(Log.store('Deleted an action plan task'));
    dispatch(Checklist.destroy(taskId));
    history.goBack();
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>
            {!edit ? 'New action plan task' : `Edit task "${initialForm.label}"`}
          </span>
          <IconButton onClick={history.goBack} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {checklist ? (
          <Form id="form-cash-flow" onSubmit={submit} schema={schema} initialState={initialForm}>
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>{inputs.label}</Grid>
                <Grid item xs={12} sm={6}>{inputs.startAt}</Grid>
                <Grid item xs={12} sm={6}>{inputs.endAt}</Grid>
                <Grid item xs={12} sm={6}>{inputs.tag}</Grid>
                <Grid item xs={12} sm={6}>{inputs.responsible}</Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!checklist} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {edit && (
          <>
            <Button
              variant="outlined"
              className={classes.buttonDel}
              onClick={() => setDeleteTask(true)}
            >
              Remove
            </Button>
            {deleteTask && (
              <ConfirmDialog
                title="Do you really want to delete this task?"
                onConfirm={removeCash}
                onClose={() => setDeleteTask(false)}
              />
            )}
          </>
        )}
        <Button
          type="submit"
          form="form-cash-flow"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CashFlowForm;
