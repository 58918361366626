import { makeStyles } from '@material-ui/core/styles';
import { lighten, fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  appBar: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 12px 12px #FFFFFF',
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(0.5)}px`,
    },
  },
  logo: {
    height: 50,
    marginBottom: theme.spacing(2),
  },
  logoToolbar: {
    height: 36,
  },
  avatar: {
    position: 'relative',
    width: '60%',
    paddingTop: '60%',
    marginLeft: '20%',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  avatarText: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    fontSize: '3rem',
    lineHeight: '3rem',
    backgroundColor: theme.palette.primary.main,
  },
  avatarAction: {
    position: 'absolute',
    bottom: -4,
    right: -4,
  },
  logout: {
    background: lighten(theme.palette.error.main, 0.85),
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.75),
    },
  },
  sidebar: {
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    minHeight: '100vh',
    boxShadow: theme.shadows[2],
  },
  list: {
    position: 'relative',
    textAlign: 'left',
    padding: 0,
    margin: `${theme.spacing(4)}px ${theme.spacing(-2)}px 0`,
  },
  item: {
    position: 'relative',
    transition: 'background-color 1s ease',
    zIndex: 1,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    '& > a': {
      color: theme.palette.primary.main,
      display: 'flex',
      flexWrap: 'nowrap',
      textDecoration: 'none',
    },
  },
  itemActive: {
    position: 'relative',
    transition: 'background-color 1s ease',
    zIndex: 1,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    '& > a': {
      color: theme.palette.secondary.main,
      display: 'flex',
      flexWrap: 'nowrap',
      textDecoration: 'none',
      fontWeight: 600,
    },
  },
  icon: {
    height: 36,
    '& svg': {
      strokeWidth: 1.3,
    },
  },
  iconActive: {
    height: 36,
    ' & svg': {
      strokeWidth: 1.8,
    },
  },
  label: {
    fontSize: 24,
    lineHeight: '40px',
    marginLeft: theme.spacing(),
  },
  submenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 48,
    fontSize: 13,
    zIndex: 1,
    '& a': {
      color: theme.palette.primary.main,
      padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: -1,
    background: `linear-gradient(to right, ${fade(theme.palette.secondary.main, 0)} 0%, ${fade(theme.palette.secondary.main, 0.15)} 100%)`,
    transition: 'opacity 0.5s ease',
    '&:hover': {
      opacity: 0.4,
    },
  },
  backgroundActive: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
    zIndex: -1,
    background: `linear-gradient(to right, ${fade(theme.palette.secondary.main, 0)} 0%, ${fade(theme.palette.secondary.main, 0.10)} 100%)`,
  },
  marker: {
    position: 'absolute',
    width: 6,
    height: 72,
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    right: 0,
    borderRadius: 4,
    transition: 'top 0.2s cubic-bezier(0.265, 0.080, 1.000, 0.660)',
  },
  trial: {
    fontSize: 10,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: theme.spacing(0.5),
    '& p': {
      fontSize: 30,
      fontWeight: 600,
      color: theme.palette.warning.main,
    },
  },
}));
