import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import TextField from '@material-ui/core/TextField';

function Hidden({ value, onChange }) {
  useEffect(() => {
    onChange(value);
  }, [value]); // eslint-disable-line

  return <TextField type="hidden" value={value} />;
}

Hidden.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Hidden;
