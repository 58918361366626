import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  addDays, addMonths, addWeeks, addYears,
} from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';
import CashFlow from 'store/financial/cash-flow/controller';
import Contact from 'store/contact/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import { transferFiles } from 'common/form/upload/utils';
import { numberToReal } from 'common/utils/numbers';
import Form from 'common/form';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';
import FormConfig from './form-config';

function CashFlowForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const { eventId, cashId } = useParams();
  const params = new URLSearchParams(location.search);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    accounts, categories, cashFlow, costCenter, contacts,
  } = useSelector((store) => store.company);
  const { current: currentEvent } = useSelector((store) => store.event);
  const { current: { permissions } } = useSelector((store) => store.user);
  const isLoaded = !!cashFlow && !!accounts && !!categories && !!costCenter && !!contacts;

  const [edit, setEdit] = useState(false);
  const [deleteCash, setDeleteCash] = useState(false);
  const [type, setType] = useState(params.get('type') || 'in');
  const [initialForm, setInitialForm] = useState({
    account: '',
    categories: [],
    description: '',
    value: 0,
    emmittedAt: null,
    dueAt: new Date(),
    costCenter: [],
    completed: {
      on: false,
      value: 0,
      date: null,
      status: null,
    },
    repeat: {
      on: false,
      type: 'monthly',
      times: 1,
    },
  });
  const [repeatOn, setRepeatOn] = useState(false);
  const [completedOn, setCompletedOn] = useState(false);
  const [submited, setSubmited] = useState(false);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;
  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (!contacts) dispatch(Contact.index());
  }, [contacts, dispatch]);

  useEffect(() => {
    if (costCenter && currentEvent) {
      setInitialForm((f) => ({
        ...f, costCenter: currentEvent.costCenter || [],
      }));
    }
  }, [costCenter, currentEvent]);

  useEffect(() => {
    if (cashId && cashFlow && contacts) {
      const cash = cashFlow.find((c) => c.id === cashId);

      if (cash) {
        setType(cash.type);
        setInitialForm({
          ...cash,
          contact: contacts.find((c) => c.id === cash.contact) || null,
          dueAt: cash.dueAt.toDate(),
          emmittedAt: cash.emmittedAt ? cash.emmittedAt.toDate() : null,
        });
        setEdit(true);
      }
    }
  }, [cashId, cashFlow, contacts]);

  const handleType = (value) => {
    setType(value);
  };

  const submit = async (data) => {
    setSubmited(true);
    const cash = { ...data, contact: data.contact.id };
    delete cash.repeat;

    if (cash.attachments) {
      cash.attachments = await transferFiles(
        cash.attachments,
        `/financial-temp/${eventId}/cash-flow/attachments`,
        `/financial/${eventId}/cash-flow/attachments`,
      );
    }

    if (data.repeat && data.repeat.on) {
      const { repeat } = data;
      let { dueAt } = cash;

      for (let i = 0; i < repeat.times; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        dispatch(CashFlow.store({ ...cash, dueAt }));
        if (repeat.type === 'dayly') dueAt = addDays(dueAt, 1);
        if (repeat.type === 'weekly') dueAt = addWeeks(dueAt, 1);
        if (repeat.type === 'monthly') dueAt = addMonths(dueAt, 1);
        if (repeat.type === 'yearly') dueAt = addYears(dueAt, 1);
      }
    } else {
      if (cash.completed.receipt) {
        cash.completed.receipt = await transferFiles(
          cash.completed.receipt,
          `/financial-temp/${eventId}/cash-flow/receipts`,
          `/financial/${eventId}/cash-flow/receipts`,
        );
      }

      if (edit) {
        dispatch(Log.store(`Changed an ${type === 'in' ? 'inflow' : 'outflow'} of ${
          numberToReal(cash.value)
        }`));
        dispatch(CashFlow.update(cashId, cash));
      } else {
        dispatch(Log.store(`Created an ${type === 'in' ? 'inflow' : 'outflow'} of ${
          numberToReal(cash.value)
        }`));
        dispatch(CashFlow.store(cash));
      }
    }

    setSubmited(false);
    history.goBack();
  };

  const removeCash = () => {
    dispatch(Log.store(`Deleted an ${type === 'in' ? 'inflow' : 'outflow'} of ${
      numberToReal(initialForm.value)
    }`));
    dispatch(CashFlow.destroy(cashId));
    history.goBack();
  };

  const closeForm = () => {
    dispatch(FormSave.remove('form-cash-flow'));
    history.goBack();
  };

  let typeLabel = 'cash flow';
  if (type === 'in') typeLabel = 'in';
  else if (type === 'out') typeLabel = 'out';

  const formSchema = FormConfig({
    edit,
    type,
    completed: [completedOn, setCompletedOn],
    repeat: [repeatOn, setRepeatOn],
    created: edit ? initialForm.created : 'manual',
  });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={closeForm}
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>{!edit ? `New ${typeLabel}` : `Edit ${typeLabel}`}</span>
          <IconButton onClick={closeForm} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isLoaded ? (
          <Form id="form-cash-flow" onSubmit={submit} schema={formSchema} initialState={initialForm}>
            {(inputs) => (
              <Grid container spacing={1}>
                {inputs.type}
                {!edit && (
                  <>
                    <Grid item xs={6} style={{ paddingRight: 0 }}>
                      <Button
                        fullWidth
                        variant={type === 'in' ? 'contained' : 'outlined'}
                        className={type === 'in' ? classes.buttonInSelected : classes.buttonIn}
                        onClick={() => handleType('in')}
                      >
                        Inflow
                      </Button>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 0 }}>
                      <Button
                        fullWidth
                        variant={type === 'out' ? 'contained' : 'outlined'}
                        className={type === 'out' ? classes.buttonOutSelected : classes.buttonOut}
                        onClick={() => handleType('out')}
                      >
                        Outlow
                      </Button>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>{inputs.contact}</Grid>

                <Grid item xs={12}>{inputs.description}</Grid>

                <Grid item xs={12} sm={6}>{inputs.value}</Grid>
                <Grid item xs={12} sm={6}>{inputs.account}</Grid>

                <Grid item xs={12} sm={6}>{inputs.emmittedAt}</Grid>
                <Grid item xs={12} sm={6}>{inputs.dueAt}</Grid>

                <Grid item xs={12} style={{ marginTop: 16 }}>
                  {inputs.categories}
                </Grid>

                <Grid item xs={12}>{inputs.costCenter}</Grid>

                <Grid item xs={12}>{inputs.notes}</Grid>

                {!edit && (
                  <>
                    <Grid item xs={12} style={{ marginBottom: -8 }}>
                      <ExpansionPanel
                        className={classes.expand}
                        expanded={repeatOn}
                      >
                        <ExpansionPanelSummary classes={{ content: classes.expandSummary }}>
                          {inputs.repeat.on}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.expandDetail }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={9}>{inputs.repeat.type}</Grid>
                            <Grid item xs={12} sm={3}>{inputs.repeat.times}</Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: 8 }}>
                      <ExpansionPanel
                        className={classes.expand}
                        expanded={completedOn}
                      >
                        <ExpansionPanelSummary classes={{ content: classes.expandSummary }}>
                          {inputs.completed.on}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.expandDetail }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>{inputs.completed.value}</Grid>
                            <Grid item xs={12} sm={6}>{inputs.completed.date}</Grid>
                            <Grid item xs={12}>{inputs.completed.receipt}</Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </>
                )}

                {inputs.attachments}
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={isLoaded} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {edit && initialForm.created === 'manual' && (
          <>
            <Button
              variant="outlined"
              className={classes.buttonDel}
              onClick={() => setDeleteCash(true)}
            >
              Excluir
            </Button>
            {deleteCash && (
              <ConfirmDialog
                title={`Do you really want to remove this ${type === 'in' ? 'in' : 'out'}?`}
                onConfirm={removeCash}
                onClose={() => setDeleteCash(false)}
              />
            )}
          </>
        )}
        <Button
          type="submit"
          form="form-cash-flow"
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={submited}
        >
          {edit ? 'Save' : 'Create'}
          {submited && (
            <CircularProgress size={16} color="primary" style={{ marginLeft: 8 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CashFlowForm;
