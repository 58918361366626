import React, { useEffect } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import matchHighlight from 'autosuggest-highlight/match';
import parseHighlight from 'autosuggest-highlight/parse';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

// Controllers
import Contact from 'store/contact/controller';
import Product from 'store/supplier/product/controller';

import styles from './style';

function FormConfig() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, productId } = useParams();

  const { products, suppliers } = useSelector((store) => store.event);
  const {
    users, contacts, categories, products: productsCompany,
  } = useSelector((store) => store.company);

  useEffect(() => {
    if (!contacts) dispatch(Contact.index());
  }, [contacts, dispatch]);

  useEffect(() => {
    if (!productsCompany) dispatch(Product.indexCompany(eventId));
  }, [productsCompany, dispatch, eventId]);

  const getCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        getChildren(c, `${label} > ${c.name}`);
      });
    };

    if (categories) {
      categories.filter((c) => c.parent === 'suppliers').forEach((category) => {
        cat.push(category);
        getChildren(category, category.name);
      });
    }

    return cat;
  };

  const baseUrl = (to) => {
    let url = `/manager/${eventId}/procurement`;
    if (history.location.pathname.indexOf('negotiations') >= 0) url += '/negotiations';
    if (history.location.pathname.indexOf('items') >= 0) url += '/items';
    return url + to;
  };

  return [

    {
      name: 'name',
      type: 'autosuggest',
      label: 'Item name',
      keyList: 'name',
      list: productsCompany,
      onChange: (value, state) => {
        if (value && value.name) {
          _.set(state, 'name', value.name);
          _.set(state, 'category', value.category);
        }
        return state;
      },
      validation: {
        required: 'Product name is required',
        unique: {
          msg: 'A product with that name already exists',
          params: {
            list: products ? products.filter((p) => p.id !== productId).map((p) => p.name) : [],
          },
        },
      },
    },
    {
      name: 'category',
      type: 'select',
      label: 'Category',
      options: getCategories().map((category) => ({
        value: category.id,
        label: (
          <Grid container>
            <div className={classes.categoryColor} style={{ background: category.color }} />
            <div className={classes.categoryLabel}>
              {category.parentLabel && <span>{category.parentLabel}</span>}
              <p>{category.name}</p>
            </div>
          </Grid>
        ),
      })),
      addOption: {
        label: 'Add category',
        url: baseUrl('/categories/new'),
      },
      validation: { required: 'A product category is required' },
    },
    {
      name: 'valueForecast',
      type: 'currency',
      label: 'Expected Value',
    },
    {
      name: 'responsible',
      type: 'select',
      label: 'Responsible',
      className: classes.selectResponsible,
      options: users
        ? users
          .filter((u) => !u.deletedAt)
          .map((user) => ({
            value: user.id,
            label: (
              <Grid container wrap="nowrap" alignItems="center" justify="space-between">
                <ListItemAvatar>
                  <Avatar alt={user.name} src={user.avatar} className={classes.avatar}>
                    {user.initials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText className={classes.responsibleName} primary={user.name} />
              </Grid>
            ),
          }))
        : [],
    },
    {
      name: 'supplier',
      type: 'autosuggest',
      label: 'Trusted supplier',
      placeholder: 'Search for suppliers',
      match: 'anyPosition',
      keyList: 'name',
      list: contacts && suppliers
        ? contacts.filter((c) => c.types.indexOf('Supplier') >= 0
          && !suppliers.find((s) => s.register && s.register.cnpj === c.id))
        : null,
      helperText:
        'Add known vendors from previous events for your product.',
      renderItem: (item, { query, isHighlighted }) => {
        const matches = matchHighlight(item.name, query);
        const parts = parseHighlight(item.name, matches);

        return (
          <MenuItem selected={isHighlighted} component="div">
            <Grid container direction="column">
              <div>
                {parts.map((part, index) => (
                  <span key={String(index)} style={{ fontWeight: part.highlight ? 600 : 300 }}>
                    {part.text}
                  </span>
                ))}
              </div>
              {item.register && item.register.data && (
                <>
                  <span style={{ fontSize: 10, color: '#777', fontWeight: 500 }}>
                    {item.register.data.cnpj}
                  </span>
                  <span style={{ fontSize: 8, color: '#777', fontWeight: 300 }}>
                    {/* {`${item.register.data.address.neighborhood},  */}
                    {`${item.register.data.address.city
                    } - ${item.register.data.address.state}`}
                  </span>
                </>
              )}
            </Grid>
          </MenuItem>
        );
      },
    },
  ];
}

export default FormConfig;
