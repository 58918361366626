import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// Icons and Styles
import { Admin } from 'assets/custom-icons';
import Tooltip from 'common/tooltip';
import styles from './style';

function ConversationForm({
  members, onChange, admins, setAdmins,
}) {
  const classes = styles();

  const { current: currentUser } = useSelector((store) => store.user);
  const { users } = useSelector((store) => store.company);

  const handleSelected = (type, id) => {
    const newMembers = [...members];
    const newAdmins = [...admins];

    const indexMembers = newMembers.indexOf(id);
    const indexAdmins = newAdmins.indexOf(id);

    if (type === 'members') {
      if (indexMembers >= 0) {
        newMembers.splice(indexMembers, 1);
      } else newMembers.push(id);
    }

    if (indexAdmins >= 0) newAdmins.splice(indexAdmins, 1);
    else if (type === 'admins') newAdmins.push(id);

    onChange(newMembers);
    setAdmins(newAdmins);
  };

  return (
    <>
      <Grid container justify="flex-end">
        <Admin style={{ marginRight: 13 }} />
      </Grid>
      <List style={{ width: '100%' }}>
        {users.filter((u) => u.id !== currentUser.id && !u.deletedAt).map((user) => (
          <>
            <Divider component="li" />
            <ListItem
              button
              disabled={members[0] === 'open'}
              key={user.id}
              style={{ padding: '8px 0' }}
              onClick={() => handleSelected('members', user.id)}
            >
              <ListItemAvatar>
                <Grid container wrap="nowrap" alignItems="center">
                  <Checkbox
                    edge="end"
                    disabled={members[0] === 'open'}
                    onChange={() => handleSelected('members', user.id)}
                    checked={members.indexOf(user.id) >= 0 || members[0] === 'open'}
                    style={{ marginRight: 0 }}
                  />
                  <Avatar src={user.avatar} alt={user.name} className={classes.avatarList}>
                    {user.initials}
                  </Avatar>
                </Grid>
              </ListItemAvatar>
              <ListItemText primary={user.name} />
              {(members.indexOf(user.id) >= 0 || members[0] === 'open') && (
                <ListItemSecondaryAction>
                  <Tooltip title="Tornar admin" placement="left">
                    <Checkbox
                      edge="end"
                      onChange={() => handleSelected('admins', user.id)}
                      checked={admins.indexOf(user.id) >= 0}
                    />
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </>
        ))}
        <Divider component="li" />
      </List>
    </>
  );
}

ConversationForm.propTypes = {
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  admins: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAdmins: PropTypes.func.isRequired,
};

export default ConversationForm;
