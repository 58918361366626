import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons and Styles
import { ArrowLeft, Play, X, Send, CheckCircle } from 'react-feather';
import styles from './style';

function Reports({ open, onClose }) {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: company } = useSelector((store) => store.company);
  const { current: user } = useSelector((store) => store.user);

  const [feedback, setFeedback] = useState(null);
  const [sended, setSended] = useState(false);

  const materials = [
    {
      id: 'cocreate',
      name: 'Use the (CO)CREATE',
      description: 'Create and define your event ideas, objectives, format and goals.',
      url: 'https://www.youtube.com/watch?v=kY2mU8q5P5o',
    },
    {
      id: 'plan',
      name: 'Use the PLAN',
      description: 'Plan the action schedule, project the financial scenario and organize the team\'s tasks.',
      url: 'https://www.youtube.com/watch?v=lGYodgSyAL8',
    },
    {
      id: 'manage',
      name: 'Use the MANAGE',
      description: 'Centrally manage financial and trading.',
      url: 'https://www.youtube.com/watch?v=x17RaEoauh0',
    },
  ];

  const sendFeedback = () => {
    var body = new FormData();
    body.set('name', user.name);
    body.set('email', user.email);
    body.set('company', company.name);
    body.set('feedback', feedback);
    axios.post('https://hybri.io/sendMail.php', body);
    setSended(true);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid container direction="column" style={{ overflow: 'hidden' }}>
        <Grid container justify="space-between" alignItems="center" className={classes.toolbar}>
          <IconButton color="primary" onClick={onClose}>
            {mobile ? <ArrowLeft /> : <X />}
          </IconButton>
          <Typography className={classes.title}>
            HELP CENTER
          </Typography>
          <div style={{ width: 48 }} />
        </Grid>
        <Grid container className={classes.content}>
          <Grid container>
            <ExpansionPanel style={{ margin: 0, boxShadow: 'none', width: '100%' }} expanded>
              <ExpansionPanelSummary className={classes.expansionSummary}>
                {/* <section.icon className={classes.icon} /> */}
                HELP MATERIALS
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <List style={{ padding: 0, margin: '0 -24px', width: 'calc(100% + 48px)' }}>
                  {materials.map((material) => (
                    <ListItem
                      button
                      key={material.id}
                      style={{ padding: '16px 32px' }}
                      onClick={() => window.open(material.url)}
                    >
                      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                        <IconButton color="primary" style={{ marginRight: 16 }}>
                          <Play />
                        </IconButton>
                        <Grid container direction="column">
                          <Typography style={{ fontWeight: 600 }}>{material.name}</Typography>
                          <Typography style={{ fontSize: 13 }}>{material.description}</Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel style={{ margin: 0, boxShadow: 'none', width: '100%' }} expanded>
              <ExpansionPanelSummary className={classes.expansionSummary}>
                {/* <section.icon className={classes.icon} /> */}
                FEEDBACK
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container direction="column" alignItems="center">
                  {!sended ? (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        label="What are you thinking about hybri?"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        style={{ marginBottom: 16 }}
                        InputProps={{ inputProps: { style: { lineHeight: 1.5, minHeight: 80 } } }}
                      />
                      <Button variant="contained" color="primary" onClick={sendFeedback}>
                        <Send size={16} style={{ marginRight: 8 }} />
                        SUBMIT
                      </Button>
                    </>
                  ) : (
                    <>
                      <Grid container alignItems="center" justify="center" style={{ marginBottom: 16 }}>
                        <CheckCircle size={40} style={{ color: '#4bb75c', marginRight: 16 }} />
                        <Typography style={{ fontWeight: 600, fontSize: 20, width: 'fit-content' }}>
                          THANK YOU VERY MUCH!
                        </Typography>
                      </Grid>
                      <Typography style={{ marginBottom: 32, textAlign: 'center' }}>
                        Keep an eye on your email so we can follow our conversation over there ;)
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => setSended(false)}>
                        Send other feedback
                      </Button>
                    </>
                  )}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
}

Reports.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Reports;
