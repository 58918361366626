import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

const Log = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('log')
      .onSnapshot((ref) => {
        const logs = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        logs.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(Company.loadLogs(logs));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (message) => (dispatch, getState) => {
    const { current: company } = getState().company;
    const { current: user } = getState().user;
    const { current: event } = getState().event;

    const action = { message, user: user.id, createdAt: new Date(Date.now()) };
    if (event) action.event = event.id;

    firestore.collection('companies').doc(company.id).collection('log').add(action);
  },
};

export default Log;
