import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { reducer as alert } from './alert/reducer';
import { reducer as auth } from './auth/reducer';
import { reducer as company } from './company/reducer';
import { reducer as event } from './event/reducer';
import { reducer as form } from './form/reducer';
import { reducer as user } from './user/reducer';
// Supplier's Model
import { reducer as supplier } from './supplier/reducer';

const reducers = combineReducers({
  alert,
  auth,
  company,
  event,
  form,
  user,
  // Supplier's Model
  supplier,
});

export default createStore(reducers, applyMiddleware(thunk));
