export function sortSuppliers(a, b) {
  if (!a.interrupted && !b.interrupted) {
    switch (a.status) {
      case 0:
        if (a.updatedAt.toDate() < b.updatedAt.toDate()) return 1;
        break;
      case 1:
        if (a.budget && !b.budget) return 1;
        if (!a.budget && b.budget) return -1;

        if (a.updatedAt.toDate() < b.updatedAt.toDate()) return 1;
        break;
      case 2:
        if (a.register && !b.register) return 1;
        if (!a.register && b.register) return -1;

        if (a.register && b.register) {
          if (!a.register.answered && b.register.answered) return 1;
          if (a.register.answered && !b.register.answered) return -1;

          if (a.register.repproved && !b.register.repproved) return 1;
          if (!a.register.repproved && b.register.repproved) return -1;
        }

        if (a.updatedAt.toDate() < b.updatedAt.toDate()) return 1;
        break;
      case 3:
        // eslint-disable-next-line no-case-declarations
        const [aNextDue] = a.payment.installments
          .filter((i) => !i.payed)
          .sort((ia, ib) => ib.due.seconds - ia.due.seconds);

        // eslint-disable-next-line no-case-declarations
        const [bNextDue] = b.payment.installments
          .filter((i) => !i.payed)
          .sort((ia, ib) => ib.due.seconds - ia.due.seconds);

        if (!aNextDue && bNextDue) return 1;
        if (aNextDue && !bNextDue) return -1;

        if (aNextDue && bNextDue) {
          if (aNextDue.due.toDate() > bNextDue.due.toDate()) return 1;
          if (aNextDue.due.toDate() < bNextDue.due.toDate()) return -1;
        }

        if (a.updatedAt.toDate() < b.updatedAt.toDate()) return 1;
        break;
      case 4:
        if (!a.delivery && b.delivery) return 1;
        if (a.delivery && !b.delivery) return 1;

        if (a.delivery && b.delivery) {
          if (a.delivery.done && !b.delivery.done) return 1;
          if (!a.delivery.done && b.delivery.done) return -1;

          if (a.delivery.estimated && !b.delivery.estimated) return 1;
          if (!a.delivery.estimated && b.delivery.estimated) return -1;

          if (a.delivery.estimated && b.delivery.estimated) {
            if (a.delivery.estimated.toDate() > b.delivery.estimated.toDate()) return 1;
            if (a.delivery.estimated.toDate() < b.delivery.estimated.toDate()) return -1;
          }
        }

        if (a.updatedAt.toDate() < b.updatedAt.toDate()) return 1;
        break;
      default: break;
    }
  } else if (a.interrupted && !b.interrupted) return 1;

  return -1;
}

export function filter(supplier, allProducts, search) {
  let match = true;

  const params = new URLSearchParams(search);
  const products = params.get('products') && params.get('products').split(',');
  const categories = params.get('categories') && params.get('categories').split(',');
  const responsibles = params.get('responsibles') && params.get('responsibles').split(',');
  const value = { min: params.get('valueMin'), max: params.get('valueMax') };
  const currentBiggerForecast = params.get('currentBiggerForecast');
  const paymentLate = params.get('paymentLate');
  const deliveryLate = params.get('deliveryLate');

  if (products) {
    let productsMatch = false;
    products.forEach((product) => {
      if (supplier.products.indexOf(product) >= 0) productsMatch = true;
    });
    if (!productsMatch) match = false;
  }

  if (categories) {
    let categoryMatch = false;
    categories.forEach((category) => {
      supplier.products.forEach((spId) => {
        const supplierProduct = allProducts.find((p) => p.id === spId);
        if (supplierProduct && supplierProduct.category === category) {
          categoryMatch = true;
        }
      });
    });
    if (!categoryMatch) match = false;
  }

  if (responsibles) {
    responsibles.forEach((responsible) => {
      if (supplier.responsible !== responsible) match = false;
    });
  }

  if (value.min) {
    if (supplier.payment
      && (supplier.payment.total < value.min || supplier.payment.total > value.max)) {
      match = false;
    } else if (supplier.budget
      && (supplier.budget.total < value.min || supplier.budget.total > value.max)) {
      match = false;
    } else if (!supplier.budget && !supplier.payment) {
      match = false;
    }
  }

  if (currentBiggerForecast) {
    const supplierProducts = supplier.products.map((pId) => allProducts.find((p) => p.id === pId));
    const forecast = supplierProducts.reduce((f, p) => f + p.valueForecast, 0);
    if (supplier.payment && supplier.payment.total <= forecast) {
      match = false;
    } else if (supplier.budget && supplier.budget.total <= forecast) {
      match = false;
    } else if (!supplier.budget && !supplier.payment) {
      match = false;
    }
  }

  if (paymentLate) {
    if (supplier.status === 3) {
      const noLate = supplier.payment.installments.find((i) => i.due.toDate() > new Date());
      if (noLate) match = false;
    } else {
      match = false;
    }
  }

  if (deliveryLate) {
    if (supplier.delivery && supplier.delivery.estimated && !supplier.delivery.done) {
      if (supplier.delivery.estimated.toDate() > new Date()) match = false;
    } else {
      match = false;
    }
  }

  return match;
}
