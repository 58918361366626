// Main Components
import Create from 'app/create';

// Routes Components
import PrivateRoute from '../components/PrivateRoute';


export default [
  {
    path: '/create',
    component: Create,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/create/:eventId',
    component: Create,
    routerComponent: PrivateRoute,
    exact: true,
  },
];
