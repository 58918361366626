import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import FlatfileImporter from 'flatfile-csv-importer';

// Material UI Components
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
// import Supplier from 'store/supplier/controller';

// Iconsl, Commons and Styles
import { ChevronDown, Edit2, Plus } from 'react-feather';
import { getCurrentTotal } from 'common/utils/suppliers';
import styles from './style';

// Components
import ExpansionContact from './contact';
import ExpansionBudget from './budget';
import ExpansionRegister from './register';
import ExpansionPayment from './payment';
import ExpansionDelivery from './delivery';

// const importer = new FlatfileImporter(
//   process.env.REACT_APP_FLATFILE,
//   {
//     fields: [
//       {
//         label: 'Produto',
//         key: 'product',
//         isRequired: true,
//       },
//       {
//         label: 'Valor unitário',
//         key: 'value',
//         isRequired: true,
//         validators: [{
//           validate: 'regex_matches',
//           regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
//           error: 'Não é um número válido (retire a moeda, caso existir)',
//         }],
//       },
//       {
//         label: 'Quantidade',
//         key: 'amount',
//         isRequired: true,
//         validators: [{
//           validate: 'regex_matches',
//           regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
//           error: 'Não é um número válido',
//         }],
//       },
//     ],
//   },
// );

function ExpansionInfo({ supplier, edit, setEdit }) {
  const classes = styles();
  // const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  // const { products } = useSelector((store) => store.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  const handleEdit = (e, id) => {
    e.stopPropagation();
    setExpanded(id);
    setEdit(id);
  };

  useEffect(() => {
    const panels = ['contact', 'negotiation', 'register', 'payment', 'delivery'];
    setExpanded(panels[supplier.status]);
  }, [supplier.status]);

  // const importNegotiation = (e) => {
  //   e.stopPropagation();

  //   const convertNumber = (number) => parseFloat(
  //     number.trim().split('.')
  //       .join('')
  //       .split(',')
  //       .join('.'),
  //   );

  //   importer.requestDataFromUser().then(async ({ data }) => {
  //     const bdgProducts = {};
  //     for (let i = 0; i < data.length; i += 1) {
  //       const item = data[i];

  //       const productBdg = products.find(
  //         (c) => c.name.toLowerCase().indexOf(item.product.trim().toLowerCase()) >= 0,
  //       );

  //       if (productBdg) {
  //         bdgProducts[productBdg.id] = {
  //           value: convertNumber(item.value),
  //           amount: convertNumber(item.amount),
  //         };
  //       }
  //     }

  //     dispatch(Supplier.update(supplier.id, { budget: { products: bdgProducts } }));
  //     importer.displaySuccess('Todos os dados importados!');
  //   }).catch((error) => {
  //     console.info(error || 'window close');
  //   });
  // };

  const budgetTotal = getCurrentTotal({ budget: supplier.budget });

  return (
    <>
      <ExpansionPanel
        key="contact"
        expanded={expanded === 'contact'}
        className={classes.expansionContainer}
        onChange={(e, ex) => {
          if (edit) e.stopPropagation();
          else setExpanded(ex ? 'contact' : false);
        }}
      >
        <ExpansionPanelSummary className={classes.expansionSummary} expandIcon={<ChevronDown />}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className={classes.expansionTitle}>Contact</Typography>
            {permissionEdit && !supplier.interrupted && supplier.status === 0 && !edit && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={(e) => handleEdit(e, 'contact')}
                onFocus={(e) => e.stopPropagation()}
                className={classes.editButton}
              >
                <Edit2 size={16} />
                <p>Edit</p>
              </Button>
            )}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <ExpansionContact contact={supplier.contact} edit={edit === 'contact'} setEdit={setEdit} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {supplier.status >= 1 && (
        <ExpansionPanel
          key="negotiation"
          expanded={expanded === 'negotiation'}
          className={classes.expansionContainer}
          onChange={(e, ex) => {
            if (edit) e.stopPropagation();
            else setExpanded(ex ? 'negotiation' : false);
          }}
        >
          <ExpansionPanelSummary className={classes.expansionSummary} expandIcon={<ChevronDown />}>
            <Grid container justify="space-between" alignItems="center">
              <Typography className={classes.expansionTitle}>Budget</Typography>
              {permissionEdit && !supplier.interrupted && supplier.status === 1 && !edit && (
                <div>
                  {/* <Button
                    color="secondary"
                    variant="outlined"
                    onClick={importNegotiation}
                    className={classes.editButton}
                    style={{ marginRight: 8 }}
                  >
                    <LogIn style={{ transform: 'rotate(180deg)' }} size={16} />
                    <p>Importar</p>
                  </Button> */}
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={(e) => handleEdit(e, 'negotiation')}
                    onFocus={(e) => e.stopPropagation()}
                    className={classes.editButton}
                  >
                    <Edit2 size={16} />
                    <p>Edit</p>
                  </Button>
                </div>
              )}
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetails}>
            <ExpansionBudget supplier={supplier} edit={edit === 'negotiation'} setEdit={setEdit} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {supplier.status >= 2 && (
        <ExpansionPanel
          key="register"
          expanded={expanded === 'register'}
          className={classes.expansionContainer}
          onChange={(e, ex) => {
            if (edit) e.stopPropagation();
            else setExpanded(ex ? 'register' : false);
          }}
        >
          <ExpansionPanelSummary className={classes.expansionSummary} expandIcon={<ChevronDown />}>
            <Typography className={classes.expansionTitle}>Register</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetails}>
            <ExpansionRegister supplier={supplier} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {supplier.status >= 1 && !!supplier.budget && budgetTotal > 0 && (
        <>
          {(supplier.payment || supplier.status === 1) && (
            <ExpansionPanel
              key="payment"
              expanded={expanded === 'payment'}
              className={classes.expansionContainer}
              onChange={(e, ex) => {
                if (edit) e.stopPropagation();
                else setExpanded(ex ? 'payment' : false);
              }}
            >
              <ExpansionPanelSummary
                className={classes.expansionSummary}
                expandIcon={<ChevronDown />}
              >
                <Grid container justify="space-between" alignItems="center">
                  <Typography className={classes.expansionTitle}>Payment</Typography>
                  {permissionEdit && !supplier.interrupted
                    && (supplier.status === 1 || supplier.status === 3) && !edit
                    && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={(e) => handleEdit(e, 'payment')}
                        onFocus={(e) => e.stopPropagation()}
                        className={classes.editButton}
                      >
                        {!supplier.payment || !supplier.payment.total ? (
                          <>
                            <Plus size={16} />
                            <p>Add</p>
                          </>
                        ) : (
                          <>
                            <Edit2 size={16} />
                            <p>Edit</p>
                          </>
                        )}
                      </Button>
                    )}
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <ExpansionPayment
                  supplier={supplier}
                  edit={edit === 'payment'}
                  setEdit={setEdit}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}

          <ExpansionPanel
            key="delivery"
            expanded={expanded === 'delivery'}
            className={classes.expansionContainer}
            onChange={(e, ex) => {
              if (edit) e.stopPropagation();
              else setExpanded(ex ? 'delivery' : false);
            }}
          >
            <ExpansionPanelSummary
              className={classes.expansionSummary}
              expandIcon={<ChevronDown />}
            >
              <Grid container justify="space-between" alignItems="center">
                <Typography className={classes.expansionTitle}>Delivery</Typography>
                {permissionEdit && !supplier.interrupted
                  && (supplier.status === 1 || supplier.status >= 3)
                  && (!supplier.delivery || !supplier.delivery.done)
                  && !edit
                  && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={(e) => handleEdit(e, 'delivery')}
                      onFocus={(e) => e.stopPropagation()}
                      className={classes.editButton}
                    >
                      {!supplier.delivery || !supplier.delivery.estimated ? (
                        <>
                          <Plus size={16} />
                          <p>Add</p>
                        </>
                      ) : (
                        <>
                          <Edit2 size={16} />
                          <p>Edit</p>
                        </>
                      )}
                    </Button>
                  )}
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionDetails}>
              <ExpansionDelivery
                supplier={supplier}
                edit={edit === 'delivery'}
                setEdit={setEdit}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      )}
    </>
  );
}

ExpansionInfo.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    interrupted: PropTypes.bool,
    contact: PropTypes.object,
    budget: PropTypes.object,
    payment: PropTypes.object,
    delivery: PropTypes.object,
    status: PropTypes.number,
  }).isRequired,
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setEdit: PropTypes.func.isRequired,
};

export default ExpansionInfo;
