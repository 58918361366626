// Categories Components
import CategoryForm from 'app/home/register/categories/form';

// Account Components
import AccountForm from 'app/home/register/accounts/form';

// Contact Components
import ContactForm from 'app/home/register/contacts/form';

// CostCenter Components
import CostCenterForm from 'app/home/register/cost-center/form';

// Routes Components
import PrivateRoute from 'routes/components/PrivateRoute';

export default (baseUrl) => [
  // Categories
  {
    path: `${baseUrl}/categories/new`,
    component: CategoryForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${baseUrl}/categories/:categoryId`,
    component: CategoryForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  // Accounts
  {
    path: `${baseUrl}/accounts/new`,
    component: AccountForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${baseUrl}/accounts/:accountId`,
    component: AccountForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  // Contacts
  {
    path: `${baseUrl}/contacts/new`,
    component: ContactForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${baseUrl}/contacts/:contactId`,
    component: ContactForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  // Cost Center
  {
    path: `${baseUrl}/cost-center/new`,
    component: CostCenterForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: `${baseUrl}/cost-center/:costId`,
    component: CostCenterForm,
    routerComponent: PrivateRoute,
    exact: true,
  },
];
