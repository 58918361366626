import React from 'react';
import { useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { acceptBanks } from 'services/tecnospeed';
import styles from './style';

function FormConfig() {
  const classes = styles();
  const location = useLocation();
  const { eventId, cashId } = useParams();

  const { accounts } = useSelector((store) => store.company);


  return [
    {
      name: 'account',
      type: 'select',
      label: 'Account',
      className: classes.accountMenu,
      options: accounts
        ? accounts.map((a) => ({
          value: a.id,
          className: classes.accountMenu,
          label: (
            <>
              {acceptBanks.find((b) => b.code === a.bank).name}
              <span>{`${a.agency} / ${a.code}`}</span>
            </>
          ),
        }))
        : [],
      addOption: {
        label: 'Add account',
        url: location.pathname.indexOf('dashboard') >= 0
          ? '/dashboard/accounts/new'
          : `/manager/${eventId}/financial/accounts/new`,
      },
    },
    {
      name: 'method',
      type: 'select',
      label: 'Method',
      options: [
        { label: 'Bill', value: 'billet' },
        { label: 'Transfer', value: 'transference' },
        { label: 'Cash', value: 'cash' },
      ],
      validation: { required: 'Select payment method' },
    },
    {
      name: 'completed.value',
      type: 'currency',
      label: 'Value',
      validation: { required: 'Choose the value to be paid' },
    },
    {
      name: 'completed.date',
      type: 'datetime',
      keyboard: true,
      label: 'Date',
      pickerProps: { disableFuture: true },
      validation: {
        required: 'Choose the date to be paid',
        future: 'Date must be less than or equal to today',
      },
    },
    {
      name: 'completed.receipt',
      type: 'upload',
      inputProps: {
        path: `/financial-temp/${eventId}/cash-flow/receipts`,
        formats: 'document/*;image/*',
        defaultDocuments: [{ label: 'Attach receipt', name: cashId }],
      },
      render: (documents) => documents,
    },
  ];
}

export default FormConfig;
