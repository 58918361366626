import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 16px)',
    padding: `${theme.spacing(1.5)}px 0`,
    transition: 'height 0.3s ease-out',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
  },
  editButton: {
    borderRadius: 30,
    padding: 8,
    minWidth: 42,
    transition: 'all 0.2s ease-out',
    '& p': {
      maxWidth: 0,
      margin: 0,
      overflow: 'hidden',
      transition: 'all 0.2s ease-out',
    },
    '&:hover': {
      padding: '8px 16px',
      '& p': {
        maxWidth: 100,
        marginLeft: 8,
      },
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeader: {
    color: `${theme.palette.tertiary.main} !important`,
    textTransform: 'uppercase !important',
    fontSize: '16px !important',
    padding: `${theme.spacing(2)}px ${theme.spacing()}px ${theme.spacing()}px !important`,
    whiteSpace: 'nowrap !important',
    '&:first-child': {
      paddingLeft: `${theme.spacing(2)}px !important`,
    },
    '&:last-child': {
      paddingRight: `${theme.spacing(2)}px !important`,
    },
  },
  tableCell: {
    padding: `${theme.spacing(0.5)}px !important`,
    border: '0 !important',
    height: 65,
    fontSize: '14px !important',
    '&:first-child': {
      paddingLeft: `${theme.spacing(2)}px !important`,
    },
    '&:last-child': {
      paddingRight: `${theme.spacing(2)}px !important`,
    },
  },
  color: {
    width: 18,
    height: 18,
    borderRadius: 9,
    marginRight: theme.spacing(),
  },
  progress: {
    flex: 1,
    height: theme.spacing(),
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  progressBar: {
    height: theme.spacing(),
    borderRadius: theme.spacing(0.5),
  },
}));
