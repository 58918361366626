import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus, Trash2, ArrowLeft, ArrowRight, Save } from 'react-feather';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function BrainstormingForm({ data, next, step, save, onChange, isDraft }) {
  const classes = styles();
  const history = useHistory();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [benchmarking, setBenchmarking] = useState(data.benchmarking);
  const [startDate, setStartDate] = useState(data.dates.start);
  const [initialForm, setInitialForm] = useState(data);
  const [saved, setSaved] = useState(initialForm);
  const [schema, setSchema] = useState(
    FormConfig({ benchmarking, setBenchmarking, startDate, setStartDate }),
  );

  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(saved);
    }, [500]);
  }, [onChange, saved]);

  useEffect(() => {
    setSchema(FormConfig({ benchmarking, setBenchmarking, startDate, setStartDate }));
  }, [benchmarking, startDate]);

  const benchmarkingAction = {
    new: () => {
      const newBench = [...saved.benchmarking];
      newBench.push({ name: '', url: '', notes: '' });
      setBenchmarking(newBench);
      setInitialForm((i) => ({ ...saved, benchmarking: newBench }));
    },
    delete: (id) => (e) => {
      if (e) e.stopPropagation();
      const newBench = [...saved.benchmarking];
      newBench.splice(id, 1);
      setBenchmarking(newBench);
      setInitialForm((i) => ({ ...saved, benchmarking: newBench }));
    },
  };
  
  return (
    <Grid container className={classes.container}>
      <Form
        id="brainstorming"
        schema={schema}
        initialState={initialForm}
        onSubmit={next}
        onChange={setSaved}
        onKeyPress={(e) => {
          const code = e.keyCode || e.which;
          if (code === 13) e.preventDefault();
        }}
      >
        {(inputs) => (
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.description}>
                <p>#HYBRI TIP</p>
                Memorable experiences are born from flawless dreams. Take a minute to think with your team or by yourself 
                about what you want this event to mean. What do we want to achieve? How do we want to be remembered?
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                Describe your event using a maximum of 140 characters. *
              </Typography>
              {inputs.description}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                Why are we organizing this event?
              </Typography>
              {inputs.objective}
            </Grid>

            <Grid container alignItems="center" justify="space-between" wrap="nowrap" item xs={12}>
              <Typography className={classes.title}>
                Which events inspire us?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {inputs.benchmarking && benchmarking.map((persona, i) => inputs.benchmarking[i] && (
                <Card>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item container spacing={1} justify="space-between" alignItems="center" wrap="nowrap">
                        <Grid item xs={12} sm={6}>{inputs.benchmarking[i].name}</Grid>
                        <Grid item xs={12} sm={6}>{inputs.benchmarking[i].url}</Grid>
                        <Trash2
                          size={16}
                          className={classes.btnRed}
                          onClick={benchmarkingAction.delete(i)}
                        />
                      </Grid>
                      <Grid item xs={12}>{inputs.benchmarking[i].notes}</Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                onClick={benchmarkingAction.new}
                style={benchmarking.length > 0 ? { marginTop: 16 } : {}}
              >
                <Plus size={18} style={{ marginRight: 16 }} />
                Add an event as inspiration
              </Button>
            </Grid>
            
            <Grid item xs={12} style={{ marginBottom: -12 }}>
              <Typography className={classes.label}>
                What is the best day and time for this event? *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>{inputs.dates.start}</Grid>
            <Grid item xs={12} sm={6}>{inputs.dates.end}</Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.description}>
                <p>#HYBRI TIP</p>
                As webinars, streamings, and general online events are becoming even more popular, 
                it is important to plan your event with respect to the proximity of similar events. 
                For example, if attendees enjoyed one particular event then they may be more willing to 
                enjoy another similar event. Don't forget to take holidays, day of the week, and starting 
                time as factors to maximize the event experience for attendees.
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                What is a good name for your event: *
              </Typography>
              {inputs.name}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                What should be the URL for the event website?
              </Typography>
              {inputs.site}
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.label}>
                What did we learn about previous events? What attracted our attention?
              </Typography>
              {inputs.learnings}
            </Grid>

            <Grid
              container
              item
              justify="space-between"
              alignItems="center"
              style={mobile ? { marginBottom: 60 } : {}}
            >
              {step === 0 && isDraft ? (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={history.goBack}
                >
                  <ArrowLeft size={20} />
                  <p>Back</p>
                </Button>
              ) : !mobile && <div style={{ width: 128 }} />}
              <Tooltip title="Save progress">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={save}
                  style={!isDraft
                    ? {
                      position: 'fixed',
                      bottom: mobile ? 16 : 32,
                      left: mobile ? 12 : 'calc(50% + 450px)',
                    } : {}}
                >
                  <Save size={20} />
                </Button>
              </Tooltip>
              {isDraft && (
                <Button
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                >
                  <p>Next</p>
                  <ArrowRight size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
}

export default BrainstormingForm;
