import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  button: {
    margin: 20,
  },
  dialog: {
    paddingRight: '0px !important',
  },
  container: {
    marginBottom: theme.spacing(),
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  value: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
  labelColor: {
    width: 5,
    borderRadius: 4,
    marginRight: theme.spacing(0.5),
  },
  priority: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: 'white',
    background: 'red',
    fontWeight: 600,
  },
  btnGreen: {
    color: theme.palette.success.main,
    '&:hover': {
      background: lighten(theme.palette.success.main, 0.8),
    },
  },
  btnRed: {
    color: theme.palette.common.grey,
    cursor: 'pointer',
    marginLeft: theme.spacing(),
    minWidth: 18,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },

  // Checklist
  expansion: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    margin: '0 !important',
    marginTop: '-1px !important',
    '&:before': {
      content: 'none',
    },
  },
  checkedItem: {
    textDecoration: 'line-through',
    color: theme.palette.common.grey,
  },
  btnOrder: {
    padding: 4,
    minWidth: 0,
    width: 24,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none',
    },
  },

  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  categoryColor: {
    position: 'absolute',
    width: 5,
    height: 'calc(100% - 22px)',
    borderRadius: 3,
    top: 10,
    left: theme.spacing(-1),
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  actionMenu: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  actions: {
    justifyContent: 'center',
  },
}));
