import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
  },
  value: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontSize: 65,
    fontWeight: 600,
    lineHeight: 1.5,
    '& span': {
      fontSize: 18,
      color: theme.palette.common.grey,
      fontWeight: 400,
      marginLeft: theme.spacing(0.5),
    },
  },
  progress: {
    flex: 1,
    height: theme.spacing(),
    borderRadius: theme.spacing(0.5),
    background: lighten(theme.palette.success.main, 0.8),
    marginRight: theme.spacing(0.5),
  },
  progressBar: {
    height: theme.spacing(),
    borderRadius: theme.spacing(0.5),
    background: theme.palette.success.main,
  },
  percent: {
    color: theme.palette.success.main,
  },
  congratulations: {
    width: 40,
    height: 40,
    marginBottom: 24,
  },
}));
