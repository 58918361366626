import React from 'react';
import PropTypes from 'prop-types';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { DollarSign } from 'react-feather';
import { installmentWithCashFlow } from 'common/utils/relationships';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import { cashFlow } from '../../../fake-data';
import styles from './style';

function Closed({ payment }) {
  const classes = styles();

  // const { cashFlow } = useSelector((store) => store.company);

  const installments = payment.installments.map((i) => installmentWithCashFlow(i, cashFlow));

  const installment = installments.find((i) => i.cashFlow && !i.cashFlow.completed.on);
  const payedInstallments = installments.filter(
    (i) => i.cashFlow && i.cashFlow.completed.on,
  ).length;
  const totalInstallments = installments.length;

  if (installment) {
    const now = new Date();
    // const due = installment.due.toDate();
    const { due } = installment;
    const date = format(due, 'dd MMM yyyy', { locale: ptBR });

    installment.class = classes.moreInfoSuccess;

    if (due < now) {
      installment.title = (
        <>
          Payment was due on
          <strong>{` ${date}`}</strong>
        </>
      );
      installment.class = classes.moreInfoError;
    } else if (subDays(due, 7) <= now) {
      installment.title = (
        <>
          Payment is close to due:
          <strong>{` ${date}`}</strong>
        </>
      );
      installment.class = classes.moreInfoWarning;
    } else {
      installment.title = (
        <>
          The payment is due on 
          <strong>{` ${date}`}</strong>
        </>
      );
    }

    return (
      <Grid container alignItems="center" className={classes.moreContent}>
        <DollarSign size={16} className={classes.moreInfoIcon} />
        <Tooltip title={installment.title}>
          <Typography className={installment.class}>
            {numberToReal(installment.value)}
            {totalInstallments > 1 && ` (${payedInstallments + 1}/${totalInstallments})`}
          </Typography>
        </Tooltip>
      </Grid>
    );
  }

  return <></>;
}

Closed.propTypes = {
  payment: PropTypes.shape({
    installments: PropTypes.array,
  }).isRequired,
};

export default Closed;
