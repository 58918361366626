import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Checklist from 'store/productivity/checklist/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X, Trash2, Edit2 } from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function TaskForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { tasks, steps, labels } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [checked, setChecked] = useState([]);
  const [unchecked, setUnchecked] = useState([]);
  const [destroy, setDestroy] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (checklist && tasks && steps) {
      const newChecked = [];
      const newUnchecked = [];

      const stepsCompleted = steps.filter((s) => s.type === 'done' || s.type === 'archive')
        .map((s) => s.id);

      checklist.forEach((item) => {
        const itemTask = tasks.filter((t) => t.parent === item.id);
        const completed = itemTask.filter((t) => stepsCompleted.indexOf(t.step) >= 0);
        if (itemTask.length === completed.length && itemTask.length > 0) {
          newChecked.push({ ...item, totalTasks: itemTask.length, percent: 100 });
        } else {
          newUnchecked.push({
            ...item,
            totalTasks: itemTask.length,
            percent: itemTask.length > 0
              ? (completed.length / itemTask.length) * 100
              : 0,
          });
        }
      });

      setChecked(newChecked);
      setUnchecked(newUnchecked);
      setIsLoaded(true);
    }
  }, [checklist, tasks, steps]);

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>Event Checklist</span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isLoaded && labels ? (
          <List dense className={classes.list}>
            {labels.filter((l) => unchecked.find((u) => u.tag === l.id)).map((label) => [
              <ListItem dense key={label.id}>
                <ListItemText
                  primary={label.name}
                  primaryTypographyProps={{
                    style: { fontSize: 14, fontWeight: 600, textTransform: 'uppercase', color: label.color },
                  }}
                />
              </ListItem>,
              unchecked.filter((u) => u.tag === label.id).map((task, i) => (
                <ListItem dense key={task.id} style={{ position: 'relative' }}>
                  <ListItemIcon style={{ minWidth: 42 }}>
                    <Checkbox
                      disableRipple
                      edge="start"
                      tabIndex={-1}
                      checked={false}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={task.label}
                    secondary={`(${task.totalTasks} activities)`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      color="primary"
                      onClick={() => history.push(`/planner/${eventId}/productivity/checklist/${task.id}`)}
                    >
                      <Edit2 size={16} />
                    </IconButton>
                    {task.totalTasks === 0 && (
                      <IconButton color="primary" onClick={() => setDestroy(task)}>
                        <Trash2 size={16} />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )),
            ])}

            <Button
              fullWidth
              color="secondary"
              style={{ marginTop: 16 }}
              onClick={() => history.push(`/planner/${eventId}/productivity/checklist/new`)}
            >
              Add new item
            </Button>

            {checked.length > 0 && <Divider style={{ margin: '16px 0' }} />}

            {labels.filter((l) => checked.find((u) => u.tag === l.id)).map((label) => [
              <ListItem dense key={label.id}>
                <ListItemText
                  primary={label.name}
                  primaryTypographyProps={{
                    style: { fontSize: 14, fontWeight: 600, textTransform: 'uppercase', color: label.color },
                  }}
                />
              </ListItem>,
              checked.filter((u) => u.tag === label.id).map((task) => (
                <ListItem dense key={task.id}>
                  <ListItemIcon style={{ minWidth: 42 }}>
                    <div style={{ width: 40 }} />
                    <Checkbox
                      checked
                      disableRipple
                      edge="start"
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={task.label}
                    secondary={`(${task.totalTasks} activities)`}
                    primaryTypographyProps={{ className: classes.checked }}
                  />
                </ListItem>
              )),
            ])}
          </List>
        ) : (
          <Loading ready={isLoaded && !!labels} content={400} />
        )}

        {destroy && (
          <ConfirmDialog
            title={`Do you really want to delete the item "${destroy.label}" from checklist?`}
            onConfirm={() => {
              dispatch(Log.store('Deleted an item from the event checklist'));
              checklist.filter((c) => c.order > destroy.order).forEach((c) => {
                dispatch(Checklist.update(c.id, { order: c.order - 1 }));
              });
              dispatch(Checklist.destroy(destroy.id));
            }}
            onClose={() => setDestroy(null)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default TaskForm;
