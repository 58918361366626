import React, { useState, useEffect, useRef } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { ArrowLeft, Check, Plus, Trash2, Save } from 'react-feather';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function GoalsForm({ data, prev, next, save, showFinish, onChange, isDraft }) {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [initialForm, setInitialForm] = useState(data);
  const [saved, setSaved] = useState(initialForm);

  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(saved);
    }, [500]);
  }, [onChange, saved]);

  const successAction = {
    new: () => {
      const newSuccess = [...saved.successList];
      newSuccess.push('');
      setSaved({ ...saved, successList: newSuccess });
      setInitialForm((i) => ({ ...saved, successList: newSuccess }));
    },
    delete: (id) => (e) => {
      if (e) e.stopPropagation();
      const newSuccess = [...saved.successList];
      newSuccess.splice(id, 1);
      setSaved({ ...saved, successList: newSuccess });
      setInitialForm((i) => ({ ...saved, successList: newSuccess }));
    },
  };

  const schema = FormConfig({ success: saved.successList, setSaved });
  
  return (
    <Grid container className={classes.container}>
      <Form
        id="goals"
        schema={schema}
        initialState={initialForm}
        onSubmit={next}
        onChange={setSaved}
      >
        {(inputs) => (
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.description}>
                <p>#HYBRI TIP</p>
                Measuring the success of your event is important. 
                Invest the time defining your goals and remember to keep your team and yourself motivated throughout the process.
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                How do you define the success of your event?
              </Typography>
              {inputs.success}
            </Grid>

            <Grid container alignItems="center" justify="space-between" wrap="nowrap" item xs={12}>
              <Typography className={classes.title} style={{ fontSize: 16 }}>
                What are the goals that will help you to achieve success?
              </Typography>
              <Tooltip title="Add goal">
                <IconButton color="secondary" className={classes.add} onClick={successAction.new}>
                  <Plus size={18} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {inputs.successList && saved.successList.map((s, i) => inputs.successList[i] && (
                <Card>
                  <CardContent>
                    <Grid container alignItems="center" wrap="nowrap">
                      {inputs.successList[i]}
                      <Trash2
                        size={16}
                        className={classes.btnRed}
                        onClick={successAction.delete(i)}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            <Grid container item justify="space-between" alignItems="center">
            {isDraft && (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={prev}
                >
                  <ArrowLeft size={20} />
                  <p>Back</p>
                </Button>
              )}
              <Tooltip title="Save progress">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={save}
                  style={!isDraft
                    ? {
                      position: 'fixed',
                      bottom: mobile ? 16 : 32,
                      left: mobile ? 12 : 'calc(50% + 450px)',
                    } : {}}
                >
                  <Save size={20} />
                </Button>
              </Tooltip>
              {isDraft && (
                <Tooltip title={!showFinish && 'Conclua todas as etapas para finalizar a criação'}>
                  <div>
                    <Button
                      size="large"
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.btnController}
                      disabled={!showFinish}
                    >
                      <p>Finish</p>
                      <Check size={20} />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
}

export default GoalsForm;
