import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Controllers
import User from 'store/user/controller';
import Log from 'store/log/controller';

// Icons, Assets and Styles
import { Trash2, Edit2 } from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function TeamDesktop() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { current: user } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.company);

  const [userDeleting, setUserDeleting] = useState(false);

  const permissionEdit = !user.permissions || !user.permissions.team || user.permissions.team.edit;

  const deleteUser = () => {
    dispatch(Log.store(`Deleted the collaborator ${userDeleting.name}`));
    dispatch(User.destroy(userDeleting.id));
    setUserDeleting(false);
  };

  return (
    <>
      {userDeleting && (
        <ConfirmDialog
          title={`Do you really want to remove ${userDeleting.name} from the team?`}
          onConfirm={deleteUser}
          onClose={() => setUserDeleting(false)}
        />
      )}
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow className={classes.rowHeader}>
                  <TableCell className={classes.cellHeader}>Name</TableCell>
                  <TableCell className={classes.cellHeader}>E-mail</TableCell>
                  <TableCell className={classes.cellHeader} />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.row} style={{ height: 8 }} />
                {users.filter((u) => u.id !== user.id && !u.deletedAt)
                  .map((u) => (
                    <TableRow className={classes.row} key={u.id}>
                      <TableCell className={classes.cell}>
                        <Grid container alignItems="center" wrap="nowrap">
                          <Avatar alt={u.name} src={u.avatar} className={classes.avatar}>
                            {u.initials}
                          </Avatar>
                          <Typography className={classes.nameUser}>{u.name}</Typography>
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.cell}>{u.email}</TableCell>
                      <TableCell className={classes.cell} align="right">
                        {permissionEdit && !u.admin && (
                          <>
                            <IconButton color="primary" onClick={() => history.push(`/team/${u.id}`)}>
                              <Edit2 size={20} />
                            </IconButton>
                            <IconButton color="primary" onClick={() => setUserDeleting(u)}>
                              <Trash2 size={20} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {users.filter((u) => u.id !== user.id && !u.deletedAt).length === 0 && (
                  <TableRow className={classes.row}>
                    <TableCell colSpan={3} className={classes.cell}> 
                      <Typography className={classes.noData}>
                        INVITE YOUR TEAM
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default TeamDesktop;
