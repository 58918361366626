function FormConfig({ saved, setSaved }) {
  const themesInputs = [];

  saved.engagement.forEach((theme, i) => {
    themesInputs.push({
      name: `engagement[${i}].when`,
      type: 'datetime',
      keyboard: true,
      showTime: true,
      size: 'small',
      label: 'When?',
      onChange: (value, state) => {
        const newEngagement = [...saved.engagement];
        newEngagement[i].when = value;
        setSaved({ ...saved, engagement: newEngagement });
        return state;
      },
    });
    themesInputs.push({
      name: `engagement[${i}].what`,
      type: 'text',
      size: 'small',
      label: 'What?',
      onChange: (value, state) => {
        const newEngagement = [...saved.engagement];
        newEngagement[i].what = value;
        setSaved({ ...saved, engagement: newEngagement });
        return state;
      },
    });
    themesInputs.push({
      name: `engagement[${i}].why`,
      type: 'text',
      multiline: true,
      size: 'small',
      label: 'Why?',
      onChange: (value, state) => {
        const newEngagement = [...saved.engagement];
        newEngagement[i].why = value;
        setSaved({ ...saved, engagement: newEngagement });
        return state;
      },
    });
    themesInputs.push({
      name: `engagement[${i}].how`,
      type: 'text',
      multiline: true,
      size: 'small',
      label: 'How?',
      onChange: (value, state) => {
        const newEngagement = [...saved.engagement];
        newEngagement[i].how = value;
        setSaved({ ...saved, engagement: newEngagement });
        return state;
      },
    });
  });

  return [
    {
      name: 'approach',
      type: 'select',
      options: [
        { label: 'Through one or more speakers', value: 'speakers' },
        { label: 'Discussions with guests', value: 'debate' },
        { label: 'Open discussion with participants', value: 'brainstorming' },
        { label: 'You will speak or present', value: 'owner' },
        { label: 'Other ideas?', value: 'other' },
      ],
    },
    {
      name: 'approachComplement',
      type: 'text',
      multiline: true,
    },
    ...themesInputs,
  ];
}

export default FormConfig;
