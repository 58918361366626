import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// MaterialUI's Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons and Styles
import { Paperclip } from 'react-feather';
import UploadDocument from './document';
import ButtonUpload from './button';
import styles from './style';

function Upload({ id, name, attachments }) {
  const classes = styles();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const onChange = (newAttachments) => {
    const attachs = newAttachments.map((a) => ({ label: a.label, name: a.name, url: a.url }));
    const uploading = attachs.find((a) => !a.url);
    if (!uploading) {
      dispatch(Log.store(`Changed task attachments "${name}"`));
      dispatch(Task.update(id, { attachments: attachs }));
    }
  };

  return (
    <>
      <Grid item container alignItems="center" justify="space-between">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Paperclip size={16} style={{ marginRight: 8, width: 24 }} />
          <Typography className={classes.title}>ATTACHMENTS</Typography>
        </div>
        <ButtonUpload
          fileProps={{
            multiple: true,
            accept: 'document/*;image/*',
            path: `/productivity/${eventId}/tasks`,
          }}
          documents={attachments}
          onChange={onChange}
        />
      </Grid>
      <Grid container item>
        {attachments.map((file, index) => (
          <UploadDocument
            key={file.name}
            index={index}
            document={file}
            files={attachments}
            onChange={onChange}
          />
        ))}
      </Grid>
    </>
  );
}

Upload.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Upload;
