import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Calendar } from 'react-feather';
import styles from './style';

function FormConfig({ userId, eventsAllow }) {
  const classes = styles();
  const { events } = useSelector((store) => store.company);

  const [eventsView, setEventsView] = useState(eventsAllow);

  useEffect(() => {
    setEventsView(eventsAllow);
  }, [eventsAllow]);

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      disabled: !!userId,
      validation: { required: 'Type the collaborators name' },
    },
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      disabled: !!userId,
      validation: {
        required: 'Type the collaborators e-mail',
        email: 'Must be a valid e-mail',
      },
    },
    // Events
    {
      name: 'permissions.events.edit',
      type: 'switch',
      color: 'secondary',
    },
    {
      name: 'permissions.events.view',
      type: 'select-chip',
      label: (
        <Grid container alignItems="center" className={classes.item}>
          <Calendar size={18} style={{ marginRight: 16 }} />
          <Typography>Access to the events</Typography>
        </Grid>
      ),
      options: [
        { label: 'All events', value: 'all', className: classes.add },
        ...eventsView[0] !== 'all'
          ? events.filter((e) => !e.draft).map((e) => ({ label: e.name, value: e.id }))
          : [],
      ],
      onChange: (value, state) => {
        if (value.find((v) => v === 'all')) {
          _.set(state, 'permissions.events.view', ['all']);
          setEventsView(['all']);
        } else {
          setEventsView(value);
        }
        return state;
      },
    },
    // Events: Drafts
    {
      name: 'permissions.events.drafts.view',
      type: 'switch',
      color: 'secondary',
    },
    // Team
    {
      name: 'permissions.team.edit',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (value) _.set(state, 'permissions.team.view', true);
        return state;
      },
    },
    {
      name: 'permissions.team.view',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (!value) _.set(state, 'permissions.team.edit', false);
        return state;
      },
    },
    // Planner: Financial
    {
      name: 'permissions.planner.financial.edit',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (value) _.set(state, 'permissions.planner.financial.view', true);
        return state;
      },
    },
    {
      name: 'permissions.planner.financial.view',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (!value) _.set(state, 'permissions.planner.financial.edit', false);
        return state;
      },
    },
    // Manager: Financial
    {
      name: 'permissions.manager.financial.edit',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (value) _.set(state, 'permissions.manager.financial.view', true);
        return state;
      },
    },
    {
      name: 'permissions.manager.financial.view',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (!value) _.set(state, 'permissions.manager.financial.edit', false);
        return state;
      },
    },
    // Manager: Sponsors
    // {
    //   name: 'permissions.manager.sponsors.edit',
    //   type: 'switch',
    //   color: 'secondary',
    //   onChange: (value, state) => {
    //     if (value) _.set(state, 'permissions.manager.sponsors.view', true);
    //     return state;
    //   },
    // },
    // {
    //   name: 'permissions.manager.sponsors.view',
    //   type: 'switch',
    //   color: 'secondary',
    //   onChange: (value, state) => {
    //     if (!value) _.set(state, 'permissions.manager.sponsors.edit', false);
    //     return state;
    //   },
    // },
    // Manager: Procurement
    {
      name: 'permissions.manager.procurement.edit',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (value) _.set(state, 'permissions.manager.procurement.view', true);
        return state;
      },
    },
    {
      name: 'permissions.manager.procurement.view',
      type: 'switch',
      color: 'secondary',
      onChange: (value, state) => {
        if (!value) _.set(state, 'permissions.manager.procurement.edit', false);
        return state;
      },
    },
  ];
}

export default FormConfig;
