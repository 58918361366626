import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Commons, Icons and Styles
import { DollarSign } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import styles from './style';

function Negotiation({ supplier }) {
  const classes = styles();

  const { products } = useSelector((state) => state.event);

  const supplierProducts = products.filter((p) => supplier.products.indexOf(p.id) >= 0);
  const forecast = supplierProducts.reduce((c, p) => c + p.valueForecast, 0);

  if (!supplier.budget) {
    return (
      <Grid container alignItems="center" className={classes.moreContent}>
        <Typography variant="overline" className={classes.moreInfoTitle}>
          Add budget
        </Typography>
      </Grid>
    );
  }

  let current = supplier.budget.total;
  if (supplier.payment && supplier.payment.total) {
    current = supplier.payment.total;
  }

  return (
    <Grid container alignItems="center" className={classes.moreContent}>
      <DollarSign size={16} className={classes.moreInfoIcon} />
      {forecast < current ? (
        <Tooltip title="Value higher than expected">
          <Typography className={classes.moreInfoError}>{numberToReal(current)}</Typography>
        </Tooltip>
      ) : (
        <Typography className={classes.moreInfoSuccess}>{numberToReal(current)}</Typography>
      )}
    </Grid>
  );
}

Negotiation.propTypes = {
  supplier: PropTypes.shape({
    budget: PropTypes.object,
    payment: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Negotiation;
