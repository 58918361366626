import { storage, ERRORS } from 'services/firebase';
import axios from 'axios';

async function transferFile(file, temp, path) {
  try {
    const document = await storage.ref(temp).child(file.name).getDownloadURL();
    // console.log(document);
    const blob = await axios.get(document).then((response) => response.blob());
    await storage.ref(path).child(file.name).put(blob);
    const url = await storage.ref(path).child(file.name).getDownloadURL();
    storage.ref(temp).child(file.name).delete();
    return {
      name: file.name, url, label: file.label,
    };
  } catch (error) {
    return ERRORS[error.code] || error.message;
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function transferFiles(files, temp, path) {
  const newFiles = [...files];

  for (let i = 0; i < newFiles.length; i += 1) {
    if (newFiles[i].uploaded) {
      // eslint-disable-next-line no-await-in-loop 
      // newFiles[i] = await transferFile(newFiles[i], temp, path);
      const url = await storage.ref(temp).child(newFiles[i].name).getDownloadURL();
      newFiles[i] = { name: newFiles[i].name, url, label: newFiles[i].label };
    }
  }

  return newFiles;
}
