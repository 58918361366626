import { acceptBanks } from 'services/tecnospeed';

export default () => [
  {
    name: 'bank',
    type: 'select',
    label: 'Bank',
    options: acceptBanks.map((b) => ({ label: b.name, value: b.code })),
    validation: { required: 'Bank account is required' },
  },
  {
    name: 'agency',
    type: 'text',
    label: 'Agency',
    accept: /^[0-9]+(=?-\d?|-|)/g,
    validation: { required: 'Agency is required' },
  },
  {
    name: 'code',
    type: 'text',
    label: 'Code',
    accept: /^[0-9]+(=?-\d?|-|)/g,
    validation: { required: 'Account code is required' },
  },
  {
    name: 'initialValue',
    type: 'currency',
    label: 'Valor Inicial',
  },
  {
    name: 'autoPayment',
    type: 'switch',
  },
];
