import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
// import createImporter from 'services/importer';

// Material UI components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useEventController from 'store/event/controller';
import Checklist from 'store/productivity/checklist/controller';
import Task from 'store/productivity/task/controller';
import Label from 'store/productivity/label/controller';

// Icons, Commons and Styles
import { AlertTriangle } from 'react-feather';
import { Planning, Financial, Productivity } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import Gantt from './components/gantt';
import styles from './style';

function ActionPlan() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { eventId } = useParams();

  // Controllers
  const Event = useEventController(dispatch);

  const { current: company, labels, tasks } = useSelector((state) => state.company);
  const { current: event, checklist } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [isLoading, setisLoading] = useState(false);
  // const [importer, setImporter] = useState(null);

  const permissionView = company.plan !== 'free';

  // useEffect(() => {
  //   setImporter(createImporter({
  //     fields: [
  //       {
  //         label: 'Nome da tarefa',
  //         key: 'label',
  //         isRequired: true,
  //       },
  //       {
  //         label: 'Início da tarefa',
  //         key: 'startAt',
  //         validators: [{
  //           validate: 'regex_matches',
  //           regex: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
  //           error: 'Não é uma data válida (22/03/2020)',
  //         }],
  //       },
  //       {
  //         label: 'Entrega da tarefa',
  //         key: 'endAt',
  //         validators: [{
  //           validate: 'regex_matches',
  //           regex: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
  //           error: 'Não é uma data válida (22/03/2020)',
  //         }],
  //       },
  //       {
  //         label: 'Responsáveis',
  //         key: 'responsibles',
  //       },
  //     ],
  //   }));
  // }, []);

  // const importChecklist = () => {
  //   const convertDate = (date) => {
  //     if (date) {
  //       const day = date.slice(0, 2);
  //       const month = date.slice(3, 5);
  //       const year = date.slice(6, 10);

  //       return new Date(`${year}-${month}-${day}`);
  //     }

  //     return null;
  //   };

  //   importer.requestDataFromUser().then(async ({ data }) => {
  //     const lastOrder = checklist.length;
  //     for (let i = 0; i < data.length; i += 1) {
  //       const item = data[i];
  //       const startAt = convertDate(item.startAt);
  //       const endAt = convertDate(item.endAt);

  //       const responsiblesName = item.responsibles.split(',');
  //       let responsibles = responsiblesName.map((r) => users.find(
  //         (u) => u.name.toLowerCase().indexOf(r.trim().toLowerCase()) >= 0,
  //       ));
  //       responsibles = responsibles.filter((u) => !!u);
  //       responsibles = responsibles.map((u) => u.id);

  //       dispatch(Checklist.store({
  //         ...item, startAt, endAt, responsibles, order: lastOrder + i,
  //       }));
  //     }

  //     importer.displaySuccess('Todos os dados importados!');
  //   }).catch((error) => {
  //     console.info(error || 'window close');
  //   });
  // };

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
      setisLoading(false);
    }
  }, [Event, event, eventId]);

  // Load data to use in this module
  useEffect(() => {
    if (!isLoading && !checklist) dispatch(Checklist.index(eventId));
  }, [isLoading, checklist, eventId, dispatch]);

  useEffect(() => {
    if (!labels) dispatch(Label.index());
    if (!tasks) dispatch(Task.index());
  }, [dispatch, labels, tasks]);

  const tabs = [{
    id: 'action-plan',
    label: 'Action plan',
    icon: Planning,
    selected: true,
  }];

  if (!permissions || !permissions.planner || permissions.planner.financial.view) {
    tabs.push({
      id: 'financial',
      label: 'Financial planning',
      icon: Financial,
      path: `/planner/${eventId}/financial`,
    });
  }

  tabs.push({
    id: 'productivity',
    label: 'Productivity',
    icon: Productivity,
    path: `/planner/${eventId}/productivity`,
  });

  return (
    <Grid container>
      <Toolbar avatar title="ACTION PLAN" sidebar="/planner/action-plan" />

      {mobile ? (
        <Grid container direction="column" alignItems="center" justify="center" style={{ height: '100vh', padding: 32 }}>
          <AlertTriangle size={100} style={{ color: '#f59658' }} />
          <Typography style={{ fontSize: 22, textAlign: 'center', margin: '16px 0', fontWeight: 600 }}>
            Action plan is not available for mobile
          </Typography>
          <Typography style={{ color: '#777', textAlign: 'center' }}>
            To view and make your planning, access through your computer
          </Typography>
        </Grid>
      ) : (
        <Grid container justify="center" className={classes.row} style={{ padding: 0, paddingTop: 48 }}>
          <Gantt />
        </Grid>
      )}

      <Tools />

      <Onboarding id="plan" title="Plan your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="plan"
          frameborder="0"
          src="https://www.youtube.com/embed/lGYodgSyAL8"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>
    </Grid>
  );
}

export default ActionPlan;
