// Main Components
import procurement from 'app/manager/procurement';
import Items from 'app/manager/procurement/items';
import Negotiations from 'app/manager/procurement/negotiations';

import ProductForm from 'app/manager/procurement/children/products';
import ProductSelect from 'app/manager/procurement/children/products/select';
import SupplierForm from 'app/manager/procurement/children/supplier/form';
import SupplierView from 'app/manager/procurement/children/supplier/view';
import SupplierRegister from 'app/manager/procurement/children/register';

// Cash Flow Components
import CashFlowPay from 'app/manager/financial/children/cash-flow/pay';

// Routes Components
import PrivateRoute from 'routes/components/PrivateRoute';
import PublicRoute from 'routes/components/PublicRoute';

// Common Routes
import CommonRoutes from '../commons';

function commonRoutes(baseUrl) {
  return [
    // Commons
    ...CommonRoutes(baseUrl),
    // Products
    {
      path: `${baseUrl}/products/new`,
      component: ProductForm,
      routerComponent: PrivateRoute,
      exact: true,
    },
    {
      path: `${baseUrl}/products/select`,
      component: ProductSelect,
      routerComponent: PrivateRoute,
      exact: true,
    },
    {
      path: `${baseUrl}/products/:productId`,
      component: ProductForm,
      routerComponent: PrivateRoute,
      exact: true,
    },
    // Negotiations
    {
      path: `${baseUrl}/new`,
      component: SupplierForm,
      routerComponent: PrivateRoute,
      exact: true,
    },
  ];
}

export default [
  {
    path: '/manager/:eventId/procurement/negotiations/register/:supplierId',
    component: SupplierRegister,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/manager/:eventId/procurement/register/:token',
    component: SupplierRegister,
    routerComponent: PublicRoute,
    exact: true,
  },
  {
    path: '/manager/:eventId/procurement/negotiations',
    component: Negotiations,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      ...commonRoutes('/manager/:eventId/procurement/negotiations'),
      {
        path: '/manager/:eventId/procurement/negotiations/:supplierId',
        component: SupplierView,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/manager/:eventId/procurement/negotiations/:supplierId/edit',
        component: SupplierForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/manager/:eventId/procurement/negotiations/:supplierId/pay/:cashId',
        component: CashFlowPay,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
  {
    path: '/manager/:eventId/procurement/items',
    component: Items,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      ...commonRoutes('/manager/:eventId/procurement/items'),
    ],
  },
  {
    path: '/manager/:eventId/procurement',
    component: procurement,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      ...commonRoutes('/manager/:eventId/procurement'),
    ],
  },
];
