import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme, lighten } from '@material-ui/core/styles';

// Commons, Components and Styles
import Tooltip from 'common/tooltip';
import MoreInfo from './more-infos';
import styles from './style';

function CardSupplier({ supplier, showCategories, setShowCategories }) {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const theme = useTheme();

  const { products } = useSelector((state) => state.event);
  const { users, categories } = useSelector((state) => state.company);

  const [stickers, setStickers] = useState([]);

  useEffect(() => {
    if (categories && products) {
      const newStickers = [];
      supplier.products.forEach((sp) => {
        const product = products.find((p) => p.id === sp);
        if (product) {
          const category = categories.find((c) => c.id === product.category);
          if (category) {
            const index = newStickers.indexOf(category);
            if (index < 0) newStickers.push(category);
          }
        }
      });
      setStickers(newStickers);
    }
  }, [categories, products, supplier.products]);

  const responsible = users.find((u) => u.id === supplier.responsible);

  return (
    <Card
      className={classes.root}
      style={{
        opacity: supplier.interrupted ? 0.7 : 1,
        background: lighten(theme.palette.primary.main, 0.9)
      }}
    >
      <CardContent style={{ padding: 0 }}>
        <Grid container style={{ position: 'relative', padding: '16px 16px 0' }}>
          <Grid container alignItems="center" style={{ paddingRight: 40 }}>
            {stickers.map((category) => (
              <Tooltip title={!showCategories && category.name} key={category.id}>
                <Grid
                  className={classes.categoryColor}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCategories(!showCategories);
                  }}
                  style={{
                    color: theme.palette.getContrastText(category.color),
                    backgroundColor: category.color,
                    maxWidth: showCategories ? 300 : 32,
                    width: showCategories ? 'fit-content' : 32,
                    maxHeight: showCategories ? 32 : 8,
                    height: showCategories ? 'fit-content' : 8,
                    padding: showCategories ? '4px 8px' : 0,
                  }}
                >
                  {showCategories && category.name}
                </Grid>
              </Tooltip>
            ))}
          </Grid>
          <Grid
            item
            style={{
              position: 'absolute', top: 16, right: 16, zIndex: 1,
            }}
          >
            {!supplier.interrupted && responsible && (
              <Tooltip title={responsible.name}>
                <Avatar
                  alt={responsible.name}
                  src={responsible.avatar}
                  className={classes.avatar}
                >
                  {responsible.initials}
                </Avatar>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <CardActionArea
          onClick={() => {
            history.push(`/manager/${eventId}/procurement/negotiations/${supplier.id}`);
          }}
          className={classes.actionArea}
        >

          <Typography variant="h6" className={classes.supplierName}>
            {supplier.name}
          </Typography>

          {supplier.interrupted ? (
            <MoreInfo.Interrupted />
          ) : (
            <>
              {supplier.status === 1 && <MoreInfo.Negotiation supplier={supplier} />}
              {supplier.status === 2 && <MoreInfo.Register supplier={supplier} />}
              {supplier.status === 3 && <MoreInfo.Closed payment={supplier.payment} />}
              {supplier.status === 4 && <MoreInfo.Payed supplier={supplier} />}
            </>
          )}
        </CardActionArea>
      </CardContent>
    </Card>
  );
}

CardSupplier.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    budget: PropTypes.object,
    payment: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.string),
    responsible: PropTypes.string,
    status: PropTypes.number,
    interrupted: PropTypes.bool,
  }).isRequired,
  showCategories: PropTypes.bool.isRequired,
  setShowCategories: PropTypes.func.isRequired,
};

export default CardSupplier;
