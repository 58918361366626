import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Icons, Common and Styles
import { Trash2, Plus } from 'react-feather';
import { numberToReal, currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import { categoryWithProducts, productWithCategory } from 'common/utils/relationships';
import Tooltip from 'common/tooltip';
import styles from './style';

function ProductsTable({
  edit, budget, products, onChange,
}) {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const { products: allProducts } = useSelector((state) => state.event);
  const { categories } = useSelector((state) => state.company);

  const categoriesAndProducts = categories
    .filter((c) => c.type === 'out')
    .map((c) => {
      const category = categoryWithProducts(c, allProducts);

      category.products = category.products
        .filter((p) => products && !products.find((sp) => sp.id === p.id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      return category;
    })
    .filter((c) => c.products.length > 0);

  const [openMenu, setOpenMenu] = useState(false);

  const addProduct = (product) => {
    const newProducts = products;
    newProducts.push(productWithCategory(product, categories));

    onChange({ newProducts });
    setOpenMenu(false);
  };

  const removeProduct = (productId) => {
    const newBudget = budget;
    newBudget.products[productId] = null;

    const newProducts = products.filter((p) => p.id !== productId);

    onChange({ newBudget, newProducts });
  };

  return [
    products
      && products.map((product) => {
        const budgetProduct = budget.products[product.id] || { amount: 0, value: 0 };
        return (
          <TableRow className={classes.row} key={product.id}>
            <TableCell className={classes.cellBody} style={{ minWidth: 116 }}>
              <Tooltip title={product.category.name}>
                <Grid container alignItems="center" wrap="nowrap">
                  <div
                    className={classes.categoryTab}
                    style={{ background: product.category.color }}
                  />
                  <Typography style={{ fontSize: 13, whiteSpace: 'normal' }}>
                    {product.name}
                  </Typography>
                </Grid>
              </Tooltip>
            </TableCell>

            <TableCell className={classes.cellBody} align="center">
              {edit ? (
                <TextField
                  error={budgetProduct.value === 0}
                  variant="outlined"
                  value={currencyInput(budgetProduct.value)}
                  onChange={(e) => {
                    const value = revertCurrencyInput(e.target.value);
                    budgetProduct.value = value;
                    onChange({
                      newBudget: {
                        ...budget,
                        products: { ...budget.products, [product.id]: budgetProduct },
                      },
                    });
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { className: classes.field },
                  }}
                  style={{ width: 150 }}
                />
              ) : (
                budgetProduct.value > 0 && numberToReal(budgetProduct.value)
              )}
            </TableCell>

            <TableCell className={classes.cellBody} align="center">
              {edit ? (
                <TextField
                  type="number"
                  error={budgetProduct.amount <= 0}
                  variant="outlined"
                  value={budgetProduct.amount}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    budgetProduct.amount = e.target.value ? value : 0;
                    onChange({
                      newBudget: {
                        ...budget,
                        products: { ...budget.products, [product.id]: budgetProduct },
                      },
                    });
                  }}
                  onClick={(e) => e.target.select()}
                  InputProps={{ inputProps: { className: classes.field, min: 1 } }}
                  style={{ width: 70 }}
                />
              ) : (
                budgetProduct.amount > 0 && budgetProduct.amount
              )}
            </TableCell>

            <TableCell className={classes.cellBody} align="right">
              {products.length > 1 ? (
                <Tooltip
                  title={`${
                    budgetProduct.amount * budgetProduct.value > product.valueForecast
                      ? 'Over'
                      : 'Within'
                  } forecast value of ${numberToReal(product.valueForecast)}`}
                >
                  <Typography
                    className={
                      budgetProduct.amount * budgetProduct.value > product.valueForecast
                        ? classes.colorError
                        : classes.colorSuccess
                    }
                    style={{ fontSize: 14 }}
                  >
                    {(budgetProduct.value > 0 || edit)
                      && numberToReal(budgetProduct.amount * budgetProduct.value)}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  className={
                    budgetProduct.amount * budgetProduct.value > product.valueForecast
                      ? classes.colorError
                      : classes.colorSuccess
                  }
                  style={{ fontSize: 14 }}
                >
                  {(budgetProduct.value > 0 || edit)
                    && numberToReal(budgetProduct.amount * budgetProduct.value)}
                </Typography>
              )}
            </TableCell>

            {edit && products.length > 1 && (
              <TableCell className={classes.cellBody} align="right">
                <Trash2
                  size={14}
                  className={classes.iconProduct}
                  onClick={() => removeProduct(product.id)}
                />
              </TableCell>
            )}
          </TableRow>
        );
      }),
    edit && (
      <TableRow className={classes.row} key="add-product">
        <TableCell className={classes.cellBody} colSpan={4}>
          <Button color="primary" size="small" onClick={(e) => setOpenMenu(e.currentTarget)}>
            <Plus size={16} style={{ marginRight: 8 }} />
            Add product
          </Button>

          {openMenu && (
            <Menu
              anchorEl={openMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(openMenu)}
              PaperProps={{ style: { maxHeight: 350, width: 300 } }}
              onClose={() => setOpenMenu(false)}
            >
              {categoriesAndProducts.map((category) => [
                <ListSubheader
                  key={category.id}
                  className={classes.categoryLabel}
                  style={{ color: category.color }}
                >
                  <div className={classes.categoryColor} style={{ background: category.color }} />
                  {category.name}
                </ListSubheader>,
                category.products.map((product) => (
                  <ListItem button key={product.id} onClick={() => addProduct(product)}>
                    <ListItemText primary={product.name} />
                  </ListItem>
                )),
              ])}
              <MenuItem
                onClick={() => history.push(`/manager/${eventId}/procurement/negotiations/products/new`)}
                className={classes.actionMenu}
              >
                Add product
              </MenuItem>
            </Menu>
          )}
        </TableCell>
      </TableRow>
    ),
  ];
}

ProductsTable.propTypes = {
  edit: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  budget: PropTypes.shape({
    products: PropTypes.object,
    discount: PropTypes.object,
    freight: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

ProductsTable.defaultProps = {
  budget: null,
  products: null,
};

export default ProductsTable;
