import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Material components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';

// Styles
import { formatRelativeDate } from 'common/utils/dates';
import { Send } from 'react-feather';
import { Timestamp } from 'services/firebase';
import styles from '../style';

function RegisterChat({ supplier, chat }) {
  const classes = styles();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const [message, setMessage] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (content) content.scrollTop = content.scrollHeight;
  }, [content, chat]);

  const send = () => {
    if (message !== '') {
      const newChat = [...chat];
      newChat.push({
        message,
        author: 'company',
        sendAt: Timestamp.fromDate(new Date()),
        viewed: false,
      });
      dispatch(Supplier.sendRegisterChat(eventId, supplier.id, newChat));
      dispatch(
        Supplier.sendRegisterMail({
          ...supplier,
          register: { ...supplier.register, chat: newChat },
        }),
      );
      setMessage('');
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.chatContent} ref={setContent}>
        {chat.map((msg) => (
          <Grid
            container
            justify={msg.author === 'company' ? 'flex-end' : 'flex-start'}
            key={msg.sendAt.toDate()}
          >
            <Grid
              item
              className={`${classes.chatMessage} ${
                msg.author === 'company' ? classes.chatMessageSend : classes.chatMessageReceive
              }`}
            >
              {msg.message}
              <p>{formatRelativeDate(msg.sendAt.toDate(), new Date())}</p>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {chat.length === 0 && (
        <Grid container justify="center" style={{ marginTop: 16 }}>
          <Typography variant="overline">Start a conversation</Typography>
        </Grid>
      )}

      <Grid item xs={12} className={classes.chatSender}>
        <TextField
          autoFocus
          fullWidth
          multiline
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();
              send();
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={send}>
                <Send />
              </IconButton>
            ),
            style: { padding: 16 },
          }}
        />
      </Grid>
    </Grid>
  );
}

RegisterChat.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    register: PropTypes.object,
  }).isRequired,
  chat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RegisterChat;
