import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  row: {
    position: 'relative',
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    margin: 0,
    marginTop: 32,
  },
  container: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(3),
    width: 'calc(100% - 96px)',
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(),
      width: 'calc(100% - 16px)',
    },
  },
  tabs: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    boxShadow: '1px 2px 20px 2px rgba(81,77,92,0.2)',
  },
  tab: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px ${theme.spacing()}px 70px`,
    marginLeft: -48,
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    border: 0,
    backgroundColor: lighten(theme.palette.primary.main, 0.4),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tabWHover: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
    },
  },
  tabMobile: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: 'calc(100% - 16px)',
  },
  tabSelected: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    border: 0,
    marginLeft: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  tabTitle: {
    color: 'white',
    fontWeight: 500,
  },
  select: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 500,
    minWidth: 150,
    '& svg': {
      color: 'white',
    },
    '&:before, &:after': {
      content: 'none',
    },
  },
  rightFab: {
    position: 'absolute',
    right: 0,
  },
  addButton: {
    position: 'fixed',
    right: theme.spacing(),
    bottom: theme.spacing(),
    zIndex: 1300,
  },
}));
