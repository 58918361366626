import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Components and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function Filter({ onClose }) {
  const classes = styles();

  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [initialForm, setInitialForm] = useState({
    date: { min: null, max: null },
    steps: [],
    priorities: [],
    labels: [],
    checklist: [],
    responsibles: [],
    taskLate: false,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const date = {
      min: params.get('dateMin') && new Date(parseInt(params.get('dateMin'), 10)),
      max: params.get('dateMax') && new Date(parseInt(params.get('dateMax'), 10)),
    };
    const filterSteps = params.get('steps') && params.get('steps').split(',');
    const filterPriorities = params.get('priorities') && params.get('priorities').split(',');
    const filterLabels = params.get('labels') && params.get('labels').split(',');
    const filterChecklist = params.get('checklist') && params.get('checklist').split(',');
    const responsibles = params.get('responsibles') && params.get('responsibles').split(',');

    const taskLate = Boolean(params.get('taskLate'));

    setInitialForm({
      date: date.max ? date : { min: null, max: null },
      steps: filterSteps || [],
      priorities: filterPriorities || [],
      labels: filterLabels || [],
      checklist: filterChecklist || [],
      responsibles: responsibles || [],
      taskLate: taskLate || false,
    });
  }, [location.search]);

  const clear = () => {
    history.push(location.pathname);
    onClose();
  };

  const filter = (data) => {
    const params = [];

    if (data.date.min && data.date.max) {
      params.push(`dateMin=${data.date.min.getTime()}&dateMax=${data.date.max.getTime()}`);
    }
    if (data.steps.length > 0) {
      params.push(`steps=${data.steps.join(',')}`);
    }
    if (data.priorities.length > 0) {
      params.push(`priorities=${data.priorities.join(',')}`);
    }
    if (data.labels.length > 0) {
      params.push(`labels=${data.labels.join(',')}`);
    }
    if (data.checklist.length > 0) {
      params.push(`checklist=${data.checklist.join(',')}`);
    }
    if (data.responsibles.length > 0) {
      params.push(`responsibles=${data.responsibles.join(',')}`);
    }
    if (data.taskLate) params.push('taskLate=1');

    history.push(`${history.location.pathname}?${params.join('&')}`);
    onClose();
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="view-product-category-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Filter tasks</span>
          <IconButton onClick={onClose} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form id="filter-tasks" onSubmit={filter} initialState={initialForm} schema={formSchema}>
          {(inputs) => (
            <Grid container alignItems="center" spacing={1}>
              <Grid container item>
                <Typography className={classes.title} style={{ marginTop: 8 }}>
                  BETWEEN DATES
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>{inputs.date.min}</Grid>
              <Grid item xs={12} sm={6}>{inputs.date.max}</Grid>
              <Grid container item>{inputs.responsibles}</Grid>
              <Grid container item>{inputs.priorities}</Grid>
              <Grid container item>{inputs.labels}</Grid>
              <Grid container item>{inputs.steps}</Grid>
              <Grid container item>{inputs.checklist}</Grid>
              <Grid container item>{inputs.taskLate}</Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="secondary" onClick={clear}>
          Clear
        </Button>
        <Button
          type="submit"
          form="filter-tasks"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Filter;
