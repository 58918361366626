import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

// Icon and Styles
import {
  CheckCircle, Archive, Edit, Folder, Search,
} from 'react-feather';
import styles from './style';

export default function () {
  const classes = styles();

  const { steps, labels, users } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);

  const [dateMin, setDateMin] = useState(null);

  const StepIcon = (type) => {
    const icons = {
      done: <CheckCircle size={18} style={{ color: '#777', marginRight: 16 }} />,
      archive: <Archive size={18} style={{ color: '#777', marginRight: 16 }} />,
      working: <Edit size={18} style={{ color: '#777', marginRight: 16 }} />,
      backlog: <Folder size={18} style={{ color: '#777', marginRight: 16 }} />,
      validate: <Search size={18} style={{ color: '#777', marginRight: 16 }} />,
    };

    return icons[type];
  };

  const priorities = [
    { value: '5', name: 'Very high', color: '#DC1F26' },
    { value: '4', name: 'High', color: '#DC1F94' },
    { value: '3', name: 'Medium', color: '#941FDC' },
    { value: '2', name: 'Low', color: '#1F45DC' },
    { value: '1', name: 'Very low', color: '#1FB3DC' },
  ];

  return [
    {
      name: 'date.min',
      type: 'datetime',
      keyboard: true,
      label: 'From',
      onChange: (value, state) => {
        setDateMin(value);
        return state;
      },
    },
    {
      name: 'date.max',
      type: 'datetime',
      keyboard: true,
      label: 'To',
      pickerProps: { minDate: dateMin },
      validation: dateMin && {
        minDate: {
          msg: 'The date must be greater than the start date',
          params: { min: 'date.min' },
        },
      },
    },
    {
      name: 'steps',
      type: 'select-chip',
      label: 'Steps',
      options: steps ? steps.map((step) => ({
        value: step.id,
        chip: { label: step.name },
        label: (
          <Grid container alignItems="center">
            {StepIcon(step.type)}
            {step.name}
          </Grid>
        ),
      })) : [],
    },
    {
      name: 'labels',
      type: 'select-chip',
      label: 'Event area',
      options: labels ? labels.map((label) => ({
        value: label.id,
        chip: { label: label.name, color: label.color },
        label: (
          <Grid container alignItems="center">
            <div className={classes.step} style={{ background: label.color }} />
            {label.name}
          </Grid>
        ),
      })) : [],
    },
    {
      name: 'priorities',
      type: 'select-chip',
      label: 'Priority',
      options: priorities.map((priority) => ({
        value: priority.value,
        chip: { label: priority.name, color: priority.color },
        label: (
          <Grid container alignItems="center">
            <div className={classes.step} style={{ background: priority.color }} />
            {priority.name}
          </Grid>
        ),
      })),
    },
    {
      name: 'checklist',
      type: 'select-chip',
      label: 'Linked tasks',
      options: checklist ? checklist.map((item) => ({ value: item.id, label: item.label })) : [],
    },
    {
      name: 'responsibles',
      type: 'select-chip',
      label: 'Responsibles',
      options: users
        ? users.filter((u) => !u.deletedAt).map((u) => ({
          value: u.id,
          chip: {
            label: u.name,
            avatar: <Avatar alt={u.name} src={u.avatar}>{u.initials}</Avatar>,
          },
          label: (
            <>
              <ListItemAvatar>
                <Avatar alt={u.name} src={u.avatar} className={classes.avatar}>
                  {u.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={u.name} />
            </>
          ),
        }))
        : [],
    },
    {
      name: 'taskLate',
      type: 'checkbox',
      label: 'LATE TASK',
    },
  ];
}
