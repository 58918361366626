import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
  },
  row: {
    height: 'auto',
  },
  cellHeader: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    borderBottomColor: theme.palette.secondary.main,
    padding: `${theme.spacing(0.5)}px`,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  cellBody: {
    fontSize: 14,
    color: theme.palette.common.grey,
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px 0`,
    border: 'none',
    whiteSpace: 'nowrap',
    '&:last-child': {
      paddingRight: theme.spacing(0.5),
    },
  },
  cellTitleHighligth: {
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  categoryTab: {
    width: 16,
    minWidth: 16,
    height: 16,
    marginRight: 8,
    borderRadius: 8,
  },
  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  field: {
    padding: theme.spacing(0.5),
    textAlign: 'right',
  },
  fieldSelect: {
    padding: '8px 24px 8px 8px',
  },
  discountSelect: {
    paddingRight: 24,
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
  discountInput: {
    paddingLeft: 8,
  },
  iconButton: {
    padding: theme.spacing(0.25),
    minWidth: 0,
  },
  iconProduct: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  forecastValue: {
    color: theme.palette.tertiary.main,
    fontSize: 12,
    fontWeight: 800,
    lineHeight: 1.5,
  },
  buttonEdit: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    minWidth: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  btnSubmit: {
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
}));
