/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import Autosuggest from 'react-autosuggest';
import matchHighlight from 'autosuggest-highlight/match';
import parseHighlight from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

// Material UI Components
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

// Styles
import styles from '../style';

function Snippet() {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const [snippet, setSnippet] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const getCurrentModule = () => {
    const { pathname } = history.location;

    const indexEventId = pathname.indexOf(eventId);
    const indexStart = indexEventId + eventId.length + 1;
    const indexBar = pathname.indexOf('/', indexStart);
    const indexEnd = indexBar >= 0 ? indexBar : pathname.length;
    let module = pathname.slice(indexStart, indexEnd);

    const modulePages = {
      planning: [],
      financial: [],
      productivity: ['kanban'],
      suppliers: ['items', 'negotiations'],
    };

    if (indexBar >= 0) {
      const nextBar = pathname.indexOf('/', indexEnd + 1);
      const screen = pathname.slice(indexEnd + 1, nextBar >= 0 ? nextBar : pathname.length);
      if (modulePages[module].indexOf(screen) >= 0) {
        module += `/${screen}`;
      }
    }

    return module;
  };

  const isKanban = () => {
    const { pathname } = history.location;
    return pathname.indexOf('kanban') >= 0;
  };

  const options = [
    // Ver - geral
    { label: 'show categories', link: '/register?tab=categories' },
    { label: 'show cost centers', link: '/register?tab=costCenter' },
    { label: 'show contacts', link: '/register?tab=contacts' },
    { label: 'show bank accounts', link: '/register?tab=accounts' },
    // Ver - plano de ação
    { label: 'show action plan', link: `/planner/${eventId}/action-plan` },
    // Ver - planejamento financeiro
    { label: 'show financial planning', link: `/planner/${eventId}/financial` },
    // Ver - produtividade
    { label: 'show produtivity', link: `/planner/${eventId}/productivity` },
    { label: 'show tasks', link: `/planner/${eventId}/productivity/kanban` },
    // Ver - financeiro
    { label: 'show financial monitoring', link: `/manager/${eventId}/financial` },
    // { label: 'ver contas à receber', link: `/event/${eventId}/financial#table?onlyType=in` },
    // { label: 'ver contas à pagar', link: `/event/${eventId}/financial#table?onlyType=out` },
    // { label: 'ver entradas', link: `/event/${eventId}/financial#table?onlyType=in` },
    // { label: 'ver saídas', link: `/event/${eventId}/financial#table?onlyType=out` },
    // Ver - compras
    { label: 'show procurement', link: `/manager/${eventId}/procurement` },
    { label: 'show procurement items', link: `/manager/${eventId}/procurement/items` },
    { label: 'show negotiations with suppliers', link: `/manager/${eventId}/procurement/negotiations` },
    // Ver - compras
    { label: 'show sponsorships', link: `/manager/${eventId}/sponsors` },
    { label: 'show negotiations with sponsors', link: `/manager/${eventId}/sponsors/negotiations` },
    // Criar - geral
    { label: 'create event', link: `/events/init` },
    // { label: 'criar categoria', link: `/event/${eventId}/${getCurrentModule()}/categories/new` },
    // { label: 'criar contato', link: `/event/${eventId}/${getCurrentModule()}/contacts/new` },
    // { label: 'criar centro de custos', link: `/event/${eventId}/${getCurrentModule()}/cost-center/new` },
    // { label: 'criar conta bancária', link: `/event/${eventId}/${getCurrentModule()}/accounts/new` },
    // Criar - produtividade
    { label: 'create task', link: `/planner/${eventId}/productivity${isKanban() ? '/kanban' : ''}/tasks/new` },
    { label: 'create item from checklist event', link: `/planner/${eventId}/productivity${isKanban() ? '/kanban' : ''}/checklist` },
    { label: 'create category to task', link: `/planner/${eventId}/productivity${isKanban() ? '/kanban' : ''}/settings` },
    { label: 'create step to task', link: `/planner/${eventId}/productivity${isKanban() ? '/kanban' : ''}/settings` },
    // Criar - financeiro
    { label: 'create inflow', link: `/manager/${eventId}/financial/cash-flow?type=in` },
    { label: 'create outflow', link: `/manager/${eventId}/financial/cash-flow?type=out` },
    // Criar - compras
    { label: 'create procument item', link: `/manager/${eventId}/procurement/items/products/new` },
    { label: 'create negotiation', link: `/manager/${eventId}/procurement/negotiations/new` },
  ];

  const submit = (e) => {
    e.preventDefault();
    if (snippet.link) history.push(snippet.link);
    setSnippet('');
  };

  const handleSuggestions = (e) => {
    let suggestionsLoaded = [];

    if (e) {
      const val = deburr(e.value.trim()).toLowerCase();
      const len = val.length;
      let count = 0;

      if (len > 0) {
        suggestionsLoaded = options.filter((item) => {
          const keep = count < 10 && item.label.slice(0, len).toLowerCase() === val;
          if (keep) count += 1;
          return keep;
        });
      }
    }

    setSuggestions(suggestionsLoaded);
  };

  const renderSuggestion = ((item, { query, isHighlighted }) => {
    const matches = matchHighlight(item.label, query);
    const parts = parseHighlight(item.label, matches);

    return (
      <MenuItem
        component="div"
        selected={isHighlighted}
        onClick={() => {
          setSnippet('');
          history.push(item.link);
        }}
      >
        <div>
          {parts.map((part, index) => (
            <span key={String(index)} style={{ fontWeight: part.highlight ? 600 : 300 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  });

  return (
    <form onSubmit={submit}>
      <Autosuggest
        renderInputComponent={(inputProps) => {
          const { inputRef = () => {}, ref, ...other } = inputProps;
          return (
            <TextField
              id="snippet"
              fullWidth
              variant="outlined"
              InputProps={{
                inputRef: (node) => {
                  ref(node);
                  inputRef(node);
                },
                inputProps: { className: classes.snippetInput },
                style: { borderRadius: 30 },
              }}
              {...other}
            />
          );
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestions}
        onSuggestionsClearRequested={handleSuggestions}
        getSuggestionValue={(item) => item}
        renderSuggestion={renderSuggestion}
        inputProps={{
          style: { minWidth: 450 },
          placeholder: 'What am I going to do?',
          value: snippet.label || snippet,
          onChange: (e, { newValue }) => setSnippet(newValue),
          inputRef: (node) => setAnchorEl(node),
        }}
        theme={classes}
        renderSuggestionsContainer={(opt) => (
          <Popper
            placement="bottom-end"
            style={{ zIndex: 1400 }}
            anchorEl={anchorEl}
            open={Boolean(opt.children)}
          >
            <Paper
              square
              {...opt.containerProps}
              style={{
                width: anchorEl ? anchorEl.clientWidth : undefined,
                maxHeight: 150,
                overflow: 'auto',
              }}
            >
              {opt.children}
            </Paper>
          </Popper>
        )}
      />
    </form>
  );
}

export default Snippet;
