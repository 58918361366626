import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  CheckCircle, Archive, Edit, Folder, Search,
} from 'react-feather';
import styles from './style';

function Steps({ id, name, workingAt, step }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { steps } = useSelector((store) => store.company);

  const [openMenu, setOpenMenu] = useState(null);

  const update = (s) => {
    dispatch(Log.store(`Moved task "${name}" to the step ${s.name}`));
    let completedAt = null;
    let startAt = workingAt;
    if (s.type === 'done') {
      completedAt = new Date();
      if (!workingAt) startAt = new Date();
    }
    if (s.type === 'working') startAt = new Date();
    if (s.type === 'backlog') startAt = null;
    dispatch(Task.update(id, { step: s.id, completedAt, workingAt: startAt }));
    setOpenMenu(null);
  };

  const StepIcon = (type) => {
    const icons = {
      done: <CheckCircle size={18} className={classes.stepIcon} />,
      archive: <Archive size={18} className={classes.stepIcon} />,
      working: <Edit size={18} className={classes.stepIcon} />,
      backlog: <Folder size={18} className={classes.stepIcon} />,
      validate: <Search size={18} className={classes.stepIcon} />,
    };

    return icons[type];
  };

  return (
    <>
      <Grid item>
        <Typography className={classes.label}>TASK STEP</Typography>
      </Grid>
      <Grid
        item
        className={classes.priority}
        onClick={(e) => setOpenMenu(e.currentTarget)}
      >
        {StepIcon(step.type)}
        {step.name}
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          className={`${classes.svgIcon} ${classes.icon} ${classes.iconOutlined}`}
        >
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </Grid>

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => setOpenMenu(null)}
      >
        {steps.map((s) => (
          <MenuItem key={s.id} onClick={() => update(s)}>
            {StepIcon(s.type)}
            {s.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Steps.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  workingAt: PropTypes.shape({ toDate: PropTypes.func }),
  step: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

Steps.defaultProps = {
  workingAt: null,
};

export default Steps;
