import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';
import Board from 'store/productivity/board/controller';

import { Actions } from './reducer';

const Event = (dispatch) => ({
  index: (companyId, permissions) => {
    firestore.collection('events')
      .where('company', '==', companyId)
      .where('deletedAt', '==', null)
      .onSnapshot((events) => {
        let allEvents = events.docs.map((event) => ({ id: event.id, ...event.data() }));
        if (permissions && permissions.events && permissions.events.view) {
          allEvents = allEvents.filter((e) => permissions.events.view[0] === 'all'
            || permissions.events.view.indexOf(e.id) >= 0);
        }
        dispatch(Company.loadEvents(allEvents));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  show: (eventId) => {
    dispatch(Actions.loading(true));

    firestore.collection('events').doc(eventId).onSnapshot(
      (event) => {
        dispatch(Actions.loadEvent({ id: eventId, ...event.data() }));
        dispatch(Actions.loading(false));
      },
      (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        dispatch(Actions.loading(false));
      },
    );
  },

  store: async (data) => firestore.collection('events')
    .add({ ...data, deletedAt: null, createdAt: new Date(), updatedAt: new Date() })
    .then((doc) => {
      Mixpanel.track('Create an event');
      if (!data.draft) {
        dispatch(Board.store({ event: doc.id, name: 'Main Board' }));
        dispatch(Alert.show({ message: 'Event created successfully.', type: 'success' }));
      } else {
        dispatch(Alert.show({ message: 'Event saved successfully.', type: 'success' }));
      }
      console.log(doc);
      return doc.id;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    }),

  update: async (id, data, opt = { withoutAlert: false }) => firestore.collection('events').doc(id)
    .set({ ...data, updatedAt: new Date() }, { merge: true })
    .then(() => {
      Mixpanel.track('Update an event');
      if (!opt.withoutAlert) {
        dispatch(Alert.show({ message: 'Event updated successfully.', type: 'success' }));
      }
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  destroy: (id) => firestore.collection('events').doc(id)
    .set({ deletedAt: new Date() }, { merge: true })
    .then(() => {
      Mixpanel.track('Delete an event');
      dispatch(Alert.show({ message: 'Event successfully deleted.', type: 'success' }));
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  forceDestroy: (id) => firestore.collection('events').doc(id)
    .delete()
    .then(() => {
      Mixpanel.track('Force delete an event');
      dispatch(Alert.show({ message: 'Event successfully deleted.', type: 'success' }));
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),

  change: () => {
    dispatch(Actions.clear());
    dispatch(Company.clearEvent());
  },
});

export default Event;
