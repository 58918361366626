// Supplier relationship
export function supplierWithProducts(supplier, products) {
  const supplierProducts = products.filter((p) => supplier.products.includes(p.id));
  return { ...supplier, products: supplierProducts };
}
export function supplierWithResponsible(supplier, users) {
  const responsible = users.find((u) => supplier.responsible === u.id);
  return { ...supplier, responsible };
}

// Category relationship
export function categoryWithProducts(category, products) {
  const categoryProducts = products.filter((p) => category.id === p.category);
  return { ...category, products: categoryProducts };
}

// Product relationship
export function productWithSuppliers(product, suppliers) {
  const productSuppliers = suppliers.filter((s) => s.products.includes(product.id))
    .sort((a, b) => a.status - b.status);
  return { ...product, suppliers: productSuppliers };
}

export function productWithCategory(product, categories) {
  const productCategory = categories.find((c) => c.id === product.category);
  return { ...product, category: productCategory };
}

// Installments Relationship
export function installmentWithCashFlow(installment, cashFlow) {
  const cash = cashFlow.find((c) => c.id === installment.cashFlow);
  return { ...installment, cashFlow: cash || null };
}
