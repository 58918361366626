import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';

const ExternalMembers = (dispatch) => ({
  show: async (id) => {
    return firestore.collection('external-members').doc(id).get()
      .then((doc) => doc.data())
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: async (id) => {
    return firestore.collection('external-members').doc(id).delete()
      .then(() => true);
  },
});
export default ExternalMembers;
