import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles, fade, lighten } from '@material-ui/core/styles';

// Controllers
import Supplier from 'store/supplier/controller';
import Company from 'store/company/controller';

// Icons and Commons
import {
  X, Check, Plus, Trash2,
} from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import Form from 'common/form';

const styles = makeStyles((theme) => ({
  dialogContent: {
    overflow: 'initial',
  },
  list: {
    marginTop: theme.spacing(),
  },
  listAdd: {
    background: lighten(theme.palette.secondary.light, 0.65),
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  listInput: {
    padding: theme.spacing(0.5),
  },
  btnCancel: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.08),
    },
  },
  btnConfirm: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.08),
    },
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  actions: {
    justifyContent: 'center',
  },
}));

function RegisterAttachments({ supplier }) {
  const classes = styles();

  const dispatch = useDispatch();

  const [add, setAdd] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedAttachs, setSelectedAttachs] = useState([]);
  const [document, setDocument] = useState(-1);

  const { current: company } = useSelector((state) => state.company);
  const { current: event } = useSelector((state) => state.event);

  useEffect(() => {
    if (
      company.settings
      && company.settings.suppliers
      && company.settings.suppliers.registerAttachments
    ) {
      setAttachments(company.settings.suppliers.registerAttachments);
    }
    if (
      event.settings
      && event.settings.suppliers
      && event.settings.suppliers.registerAttachments
    ) {
      setSelectedAttachs(event.settings.suppliers.registerAttachments);
    }
    if (supplier.settings && supplier.settings.registerAttachments) {
      setSelectedAttachs(supplier.settings.registerAttachments);
    }
  }, [company.settings, event.settings, supplier.settings]);

  const addDocument = ({ name }) => {
    const all = [...attachments];
    const selecteds = [...selectedAttachs];

    all.push(name);
    selecteds.push(name);

    dispatch(Company.update({ settings: { suppliers: { registerAttachments: all } } }));

    setSelectedAttachs(selecteds);
    setAdd(false);
  };

  const deleteDocument = () => {
    const all = [...attachments];
    const selecteds = [...selectedAttachs];

    all.splice(document, 1);
    selecteds.splice(document, 1);

    dispatch(Company.update({
      settings: { suppliers: { registerAttachments: all } },
    }));

    setSelectedAttachs(selecteds);
    setDocument(-1);
  };

  const submit = (value) => {
    const selecteds = [...selectedAttachs];
    const index = selecteds.indexOf(value);
    if (index < 0) selecteds.push(value);
    else selecteds.splice(index, 1);

    dispatch(Supplier.update(supplier.id, {
      settings: { registerAttachments: selecteds },
    }));
  };

  return (
    <>
      <Typography>
      Select all the documents that the supplier needs to send to proceed with the contract.
      </Typography>
      <List className={classes.list}>
        {attachments.map((value, key) => (
          <ListItem
            key={value}
            dense
            button
            onClick={() => submit(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                disableRipple
                checked={selectedAttachs.indexOf(value) >= 0}
              />
            </ListItemIcon>
            <ListItemText primary={value} />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => setDocument(key)}
              >
                <Trash2 size={16} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}

        {add ? (
          <ListItem dense>
            <Form
              id="document-name"
              schema={[{
                name: 'name',
                type: 'text',
                inputProps: { style: { padding: 8 } },
                validation: { required: true },
              }]}
              onSubmit={addDocument}
            >
              {(inputs) => (
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid container>{inputs.name}</Grid>
                  <IconButton
                    aria-label="cancel"
                    onClick={() => setAdd(false)}
                    className={classes.btnCancel}
                  >
                    <X size={16} />
                  </IconButton>
                  <IconButton
                    aria-label="confirm"
                    type="submit"
                    form="document-name"
                    className={classes.btnConfirm}
                  >
                    <Check size={16} />
                  </IconButton>
                </Grid>
              )}
            </Form>
          </ListItem>
        ) : (
          <ListItem dense button onClick={() => setAdd(true)} className={classes.listAdd}>
            <Plus size={18} style={{ marginRight: 8 }} />
            Add document
          </ListItem>
        )}

        {document >= 0 && (
          <ConfirmDialog
            title={`Deseja mesmo deletar o documento ${attachments[document]}?`}
            onConfirm={deleteDocument}
            onClose={() => setDocument(-1)}
          />
        )}
      </List>
    </>
  );
}

RegisterAttachments.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.object,
  }).isRequired,
};

export default RegisterAttachments;
