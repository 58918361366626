import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { differenceInCalendarDays } from 'date-fns';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Auth from 'store/auth/controller';

// Icons, Commons and Styles
import { MoreVertical, LogOut, User, Info } from 'react-feather';
import Tooltip from 'common/tooltip';
import EventInfoDialog from './events/infos';
import styles from '../../style';

// Default Icons
import EventsMenu from './events';
// import NotificationsMenu from './notifications';

function RenderIcons({ avatar, icons, defaultIcons }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { current: event } = useSelector((state) => state.event);
  const { current: user } = useSelector((state) => state.user);

  const [menuEl, setMenuEl] = useState(null);
  const [profileEl, setProfileEl] = useState(null);
  const [viewEventInfo, setViewEventInfo] = useState(false);
  const iconButtons = [...icons];

  if (upSm) {
    return [
      iconButtons.map((icon) => (
        <span key={icon.label}>
          <Tooltip title={icon.label}>
            <IconButton
              id={icon.id}
              onClick={icon.menu ? (e) => setMenuEl(e.currentTarget) : icon.click}
              className={classes.iconButton}
              color="primary"
              aria-label={icon.label}
              disabled={icon.disabled}
            >
              {icon.badge ? (
                <Badge badgeContent={icon.badge} color="secondary">
                  {icon.icon}
                </Badge>
              ) : (
                icon.icon
              )}
            </IconButton>
          </Tooltip>

          {icon.menu && <icon.menu element={menuEl} onClose={() => setMenuEl(null)} />}
        </span>
      )),
      defaultIcons && (
        <span key={EventsMenu.label}>
          <Tooltip title={!event ? EventsMenu.label : event.name}>
            <Button
              id={EventsMenu.id}
              onClick={(e) => setMenuEl(e.currentTarget)}
              className={`${classes.iconButton} ${classes.btnEvent}`}
              color="primary"
              aria-label={EventsMenu.label}
            >
              {event && (
                <div className={classes.eventLabel}>
                  {event.name}
                  {event.dates && differenceInCalendarDays(event.dates.start.toDate(), new Date()) > 0 && (
                    <p>
                      {`Remaining ${
                        differenceInCalendarDays(event.dates.start.toDate(), new Date())
                      } days`}
                    </p>
                  )}
                </div>
              )}
              <Tooltip title="Event information">
                <Info
                  className={classes.eventInfo}
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewEventInfo(true);
                  }}
                />
              </Tooltip>
            </Button>
          </Tooltip>
          
          <EventInfoDialog open={viewEventInfo} onClose={() => setViewEventInfo(false)} />
          {EventsMenu.menu && <EventsMenu.menu element={menuEl} onClose={() => setMenuEl(null)} />}
        </span>
      ),
      avatar && [(
        <Avatar
          key="avatar"
          className={classes.avatar}
          alt={user.name}
          src={user.avatar}
          onClick={(e) => setProfileEl(e.currentTarget)}
        >
          {user.initials}
        </Avatar>
      ), (
        <Menu
          key="menu-avatar"
          open={!!profileEl}
          anchorEl={profileEl}
          className={classes.popover}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setProfileEl(null)}
        >
          <MenuItem onClick={() => history.push('/profile')}>
            <ListItemIcon style={{ minWidth: 32 }}>
              <User size={16} />
            </ListItemIcon>
            <Typography>Profile</Typography>
          </MenuItem>
          <MenuItem onClick={() => dispatch(Auth.signOut())} className={classes.logout}>
            <ListItemIcon style={{ minWidth: 32 }}>
              <LogOut size={16} />
            </ListItemIcon>
            <Typography>Exit</Typography>
          </MenuItem>
        </Menu>
      )],
    ];
  }

  return (
    <>
      <IconButton
        id="more-menu"
        onClick={(e) => setMenuEl(e.currentTarget)}
        className={classes.iconButtonToolbar}
        color="primary"
        aria-label="More"
      >
        <MoreVertical />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={menuEl}
        open={menuEl ? menuEl.id === 'more-menu' : false}
        onClose={() => setMenuEl(null)}
      >
        {iconButtons.map((icon) => (
          <MenuItem
            key={icon.label}
            id={icon.id}
            onClick={icon.menu ? (e) => setMenuEl(e.currentTarget) : icon.click}
          >
            <ListItemIcon>
              {icon.badge ? (
                <Badge badgeContent={icon.badge} color="secondary">
                  {icon.icon}
                </Badge>
              ) : (
                icon.icon
              )}
            </ListItemIcon>
            <Typography>{icon.label}</Typography>
          </MenuItem>
        ))}
        {avatar && [(
          <MenuItem key="avatar" onClick={(e) => setProfileEl(e.currentTarget)}>
            <ListItemAvatar>
              <Avatar
                className={classes.avatar}
                style={{ marginLeft: 0 }}
              >
                {user.initials}
              </Avatar>
            </ListItemAvatar>
            <Typography>{user.name}</Typography>
          </MenuItem>
        ), (
          <Menu
            key="menu-avatar"
            open={!!profileEl}
            anchorEl={profileEl}
            className={classes.popover}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setProfileEl(null)}
          >
            <MenuItem onClick={() => history.push('/profile')}>
              <ListItemIcon style={{ minWidth: 32 }}>
                <User size={16} />
              </ListItemIcon>
              <Typography>Profile</Typography>
            </MenuItem>
            <MenuItem onClick={() => dispatch(Auth.signOut())} className={classes.logout}>
              <ListItemIcon style={{ minWidth: 32 }}>
                <LogOut size={16} />
              </ListItemIcon>
              <Typography>Exit</Typography>
            </MenuItem>
          </Menu>
        )]}
      </Menu>

      {iconButtons.map(
        (icon) => icon.menu && (
          <icon.menu
            key={icon.id}
            element={menuEl}
            onClose={() => setMenuEl(null)}
            mobile
          />
        ),
      )}
    </>
  );
}

RenderIcons.propTypes = {
  avatar: PropTypes.bool,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      badge: PropTypes.number,
      click: PropTypes.func,
      menu: PropTypes.elementType,
    }),
  ).isRequired,
  defaultIcons: PropTypes.bool.isRequired,
};

RenderIcons.defaultProps = {
  avatar: false,
};

export default RenderIcons;
