import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';

// Material Components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Controller
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons and Styles
import { X } from 'react-feather';
import { Unarchive } from 'assets/custom-icons';
import { productWithCategory } from 'common/utils/relationships';
import Tooltip from 'common/tooltip';
import styles from './style';

function ArchivedMenu({ element, onClose, mobile }) {
  const classes = styles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams();

  const { suppliers, products } = useSelector((state) => state.event);
  const { categories } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [open, setOpen] = useState(false);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  const origin = { vertical: 'top', horizontal: 'center' };
  const steps = ['CONTACT', 'NEGOTIATIONS', 'REGISTER', 'CLOSED', 'PAID'];
  const isLoaded = !!suppliers && !!products && !!categories;

  useEffect(() => {
    setOpen(element ? element.id === 'archive' : false);
  }, [element]);

  const close = () => {
    setOpen(false);
    onClose();
  };

  const unarchiveSupplier = (id) => {
    dispatch(Log.store(`Unarchived supplier ${suppliers.find((s) => s.id === id).name}`));
    dispatch(Supplier.update(id, { archived: false }));
    onClose();
  };

  const item = (supplier) => (
    <MenuItem
      key={supplier.id}
      className={classes.item}
      onClick={() => {
        history.push(`/manager/${eventId}/procurement/negotiations/${supplier.id}`);
        onClose();
      }}
    >
      <div className={classes.text}>
        <Grid container alignItems="center">
          {supplier.products.map((p) => {
            const product = productWithCategory(
              products.find((pr) => pr.id === p),
              categories,
            );
            return (
              <Grid item key={product.id} className={classes.product}>
                <Tooltip title={product.category && product.category.name}>
                  <Grid container alignItems="center">
                    <div
                      className={classes.category}
                      style={{ background: product.category && product.category.color }}
                    />
                    <Typography variant="overline">{product.name}</Typography>
                  </Grid>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="subtitle1" noWrap className={classes.title}>
          {supplier.name}
        </Typography>
        <Grid container alignItems="center">
          <Typography variant="overline" className={classes.step}>
            {steps[supplier.status]}
          </Typography>
          {supplier.interrupted && (
            <Typography
              variant="overline"
              className={`${classes.step} ${classes.colorError}`}
            >
              Interrupted
            </Typography>
          )}
        </Grid>
      </div>
      {permissionEdit && (
        <ListItemSecondaryAction>
          <Tooltip title="Unarchived">
            <IconButton color="primary" onClick={() => unarchiveSupplier(supplier.id)}>
              <Unarchive size={16} />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  );

  if (mobile) {
    return (
      <Dialog
        open={open}
        fullScreen
        fullWidth
        disableEscapeKeyDown
        aria-labelledby="view-archiveds-dialog"
        classes={{ root: classes.dialog }}
        onClose={close}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
            <span>Archived trades</span>
            <IconButton onClick={close} aria-label="Sair">
              <X />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {isLoaded && suppliers
            .filter((s) => s.archived)
            .sort((a, b) => a.interrupted - b.interrupted)
            .map(item)}
          {suppliers && suppliers.length === 0 && (
            <Typography className={classes.emptyLabel}>
              There is no archived supplier
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  if (element) {
    return (
      <Menu
        open={open}
        anchorEl={element}
        anchorOrigin={origin}
        transformOrigin={origin}
        className={classes.popover}
        MenuListProps={{ className: classes.menuList }}
        onClose={close}
      >
        {isLoaded && suppliers
          .filter((s) => s.archived)
          .sort((a, b) => a.interrupted - b.interrupted)
          .map(item)}
        {suppliers && suppliers.length === 0 && (
          <Typography className={classes.emptyLabel}>
            There is no archived supplier
          </Typography>
        )}
      </Menu>
    );
  }

  return <></>;
}

ArchivedMenu.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

ArchivedMenu.defaultProps = {
  element: null,
  mobile: false,
};

export default ArchivedMenu;
