import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

const Category = (dispatch, { company }) => ({
  index: () => {
    firestore.collection('companies').doc(company.id).collection('categories')
      .onSnapshot((ref) => {
        const categories = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        categories.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(Company.loadCategories(categories));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: async (data) => {
    const now = new Date();

    if (data.id) {
      return firestore.collection('companies').doc(company.id)
        .collection('categories').doc(data.id)
        .set({ ...data, updatedAt: now, createdAt: now })
        .then((doc) => {
          Mixpanel.track('Create a category');
          dispatch(Alert.show({ message: 'Category created successfully.', type: 'success' }));
          return doc.id;
        })
        .catch((error) => {
          dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
          return false;
        });
    }

    return firestore.collection('companies').doc(company.id).collection('categories')
      .add({ ...data, updatedAt: now, createdAt: now })
      .then((doc) => {
        Mixpanel.track('Create a category');
        dispatch(Alert.show({ message: 'Category created successfully.', type: 'success' }));
        return doc.id;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  update: async (id, data) => {
    const now = new Date();

    return firestore.collection('companies').doc(company.id)
      .collection('categories').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Edit a category');
        dispatch(Alert.show({ message: 'Category created successfully.', type: 'success' }));
        return true;
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
        return false;
      });
  },

  destroy: async (id) => firestore.collection('companies').doc(company.id)
    .collection('categories').doc(id)
    .delete()
    .then(() => {
      Mixpanel.track('Delete a category');
      dispatch(Alert.show({ message: 'Category successfully deleted.', type: 'success' }));
      return true;
    })
    .catch((error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      return false;
    }),
});
export default Category;
