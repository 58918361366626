import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Controllers
import useEventController from 'store/event/controller';
import Task from 'store/productivity/task/controller';
import Step from 'store/productivity/step/controller';

// Assets and Styles
import shortLogo from 'assets/short-logo.png';
import { BarChart, Trello, ArrowLeft } from 'react-feather';
import {  
  Financial, Suppliers, Productivity, Partners, Planning, Create, Plan, Manage,
} from 'assets/custom-icons/modules';
import styles from './style';

function SidebarMenu({ onClose, actived }) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  // Controller
  const Event = useEventController(dispatch);

  const { eventId } = useParams();

  const { current: company, tasks, steps } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [openMenu, setOpenMenu] = useState(null);
  const [plannerOpen, setPlannerOpen] = useState('action-plan');

  useEffect(() => {
    if (tasks && steps) {
      const backlogStep = steps.filter((s) => s.type === 'backlog');
      const backlogTasks = tasks.filter((t) => backlogStep.find((b) => b.id === t.step));
      if (backlogTasks.length !== tasks.length && tasks.length > 0) {
        setPlannerOpen('productivity');
      }
    } else {
      if (!tasks) dispatch(Task.index());
      if (!steps) dispatch(Step.index());
    }
  }, [dispatch, steps, tasks]);

  const toggleDrawer = (e) => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return;
    onClose();
  };

  const menu = [{
    id: 'create',
    label: 'Idealize',
    icon: Create,
    link: `/create/${eventId}`,
    color: '#77c868',
  }];

  if (company.plan !== 'free') {
    const plannerSubMenu = [{
      id: 'action-plan',name: 'Action Plan', link: `/planner/${eventId}/action-plan`, icon: Planning,
    }];

    if (!permissions || !permissions.planner || permissions.planner.financial.view) {
      plannerSubMenu.push({
        id: 'financial', name: 'Financial', link: `/planner/${eventId}/financial`, icon: Financial,
      });
    }

    plannerSubMenu.push({
      id: 'productivity',
      name: 'Productivity',
      link: `/planner/${eventId}/productivity`,
      icon: Productivity,
      submenu: [
        { id: 'dashboard', name: 'Dashboard', icon: BarChart, link: `/planner/${eventId}/productivity` },
        { id: 'kanban', name: 'Kanban', icon: Trello, link: `/planner/${eventId}/productivity/kanban` },
      ],
    });

    menu.push({
      id: 'planner',
      label: 'Plan',
      icon: Plan,
      link: `/planner/${eventId}/${plannerOpen}`,
      submenu: plannerSubMenu,      
      color: '#4291ef',
    });
  }

  if (company.plan !== 'free' && company.plan !== 'planner'
    && (!permissions || !permissions.manager|| permissions.manager.financial.view
      || permissions.manager.sponsors.view || permissions.manager.procurement.view)
  ) {
    const managerSubMenu = [];

    if (!permissions || !permissions.manager || permissions.manager.financial.view) {
      managerSubMenu.push({
        id: 'financial', name: 'Financial', link: `/manager/${eventId}/financial`, icon: Financial,
      });
    }
    if (!permissions || !permissions.manager || permissions.manager.sponsors.view) {
      managerSubMenu.push({
        id: 'sponsors', name: 'Sponsors', link: `/manager/${eventId}/sponsors`, icon: Partners,
      });
    }
    if (!permissions || !permissions.manager || permissions.manager.procurement.view) {
      managerSubMenu.push({
        id: 'procurement', name: 'Suppliers', link: `/manager/${eventId}/procurement`, icon: Suppliers,
      });
    }

    menu.push({
      id: 'manager',
      label: 'Manage',
      icon: Manage,
      link: managerSubMenu[0].link,
      submenu: managerSubMenu.length > 1 ? managerSubMenu : null,
      color: '#5a33b5',
    });
  }

  return (
    <>
      <div
        className={classes.sidebarShort}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <Grid
          container
          alignItems="flex-end"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            Event.change();
            history.push('/');
          }}
        >
        <IconButton color="primary" style={{ marginLeft: 8 }}>
          <ArrowLeft size={18} />
        </IconButton>
        <img alt="Logo" src={shortLogo} className={classes.logo} />
        </Grid>
        <Grid container direction="column" justify="center" style={{ position: 'relative', flex: 1 }}>
          {menu.map((item) => {
            const isActived = actived.indexOf(item.id) >= 0;

            const render = [(
              <Grid
                key={item.id}
                onClick={() => history.push(item.link)}
                className={`${classes.item} ${isActived && classes.itemActive}`}
                id={item.id}
                onMouseEnter={(e) => setOpenMenu(isActived ? null : e.currentTarget)}
                onMouseLeave={() => setOpenMenu(null)}
                style={isActived ? {
                  background: 'linear-gradient(to right, rgba(66, 145, 239, 0) 0%, rgba(66, 145, 239, 0.07) 100%)',
                } : {}}
              >
                <div>
                  <item.icon size={32} style={{ width: 40, height: 40, marginBottom: 4 }} />
                </div>
                <div className={classes.label} style={{ color: item.color }}>{item.label}</div>
                {item.submenu && item.submenu.length > 1 && (
                  <div
                    className={`${classes.submenu} ${
                      openMenu && openMenu.id === item.id ? classes.submenuOpen : ''
                    }`}
                    onMouseLeave={() => setOpenMenu(null)}
                  >
                    {item.submenu.map((submenu) => (
                      <Grid
                        key={item.id + submenu.id}
                        className={classes.submenuItem}
                        style={{ color: item.color }}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(submenu.link);
                        }}
                      >
                        <submenu.icon style={{ width: 20, height: 20, margin: '0px 16px -6px 0' }} />
                        {submenu.name}
                      </Grid>
                    ))}
                  </div>
                )}
                {item.submenu && item.submenu.length > 1 && isActived
                  && item.submenu.map((submenu) => {
                    const isActivedSub = actived.indexOf(submenu.id) >= 0;
                    return (
                      <Grid
                        key={submenu.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(submenu.link);
                        }}
                        className={`${classes.itemOpened} ${isActivedSub && classes.itemActiveOpened}`}
                        id={submenu.id}
                      >
                        {/* <submenu.icon style={{ width: 20, height: 20, marginRight: 8, minWidth: 20 }} /> */}
                        <div className={classes.labelOpened}>{submenu.name}</div>
                        {isActivedSub && <div className={classes.marker} />}
                      </Grid>
                    );
                  })}
              </Grid>
            )];

            return render;
          })}
        </Grid>
      </div>
    </>
  );
}

SidebarMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  actived: PropTypes.string.isRequired,
};

export default SidebarMenu;
