import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menuList: {
    width: 350,
    maxHeight: 350,
  },
  popover: {
    marginTop: 52,
  },
  item: {
    height: 'auto',
    padding: `${theme.spacing(0.75)}px ${theme.spacing()}px`,
    borderBottom: '1px solid rgb(224, 224, 224)',
    '&:last-child': { border: 0 },
  },
  text: {
    width: 'calc(100% - 56px)',
  },
  title: {
    fontWeight: 500,
  },
  noWrap: {
    whiteSpace: 'initial',
  },
  product: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.75),
    '& span': {
      fontSize: 10,
      fontWeight: 400,
      color: theme.palette.common.grey,
    },
  },
  category: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(0.25),
  },
  step: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    borderRadius: 30,
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    fontSize: 9,
    fontWeight: 600,
  },
  colorError: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  emptyLabel: {
    color: theme.palette.common.grey,
  },
  // Mobile
  dialog: {
    paddingRight: '0px !important',
  },
  dialogContent: {
    padding: 0,
    '& > li': {
      listStyle: 'none',
    },
  },
}));
