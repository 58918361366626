// Get product value of a supplier
export function getProductValue(supplier, productId) {
  if (!supplier.budget) return 0;

  const {
    products, freight, total, discount, exchange,
  } = supplier.budget;
  const countProducts = Object.keys(products).filter((key) => !!products[key]).length;

  if (!products[productId]) return 0;

  let value = products[productId].value * products[productId].amount;

  if (freight > 0) value += freight / countProducts;

  if (discount.value > 0) {
    if (discount.type === 'money') value -= discount.value / countProducts;
    else if (discount.type === 'percent') value *= 1 - discount.value / 100;
  }

  let percentValue = value / total;

  if (exchange) percentValue = value / (total + exchange.value);

  if (supplier.payment) {
    value = supplier.payment.total * percentValue;
  } else {
    value = total * percentValue;
  }

  return value;
}

export function getCurrentTotal({ budget, payment }, { subTotal, withoutDiscount } = {}) {
  let total = 0;

  if (!budget || !budget.products) return 0;

  // Product's total value
  Object.keys(budget.products).forEach((key) => {
    const product = budget.products[key];
    if (product) total += product.amount * product.value;
  });


  // Freight
  if (budget.freight > 0) total += budget.freight;

  // Discount
  if (budget.discount.value > 0 && withoutDiscount !== 'budget') {
    if (budget.discount.type === 'percent') total *= 1 - budget.discount.value / 100;
    else if (budget.discount.type === 'money') total -= budget.discount.value;
  }

  // Budget with exchange
  if (budget.exchange && budget.exchange.value > 0) {
    total -= budget.exchange.value;
    if (total < 0) total = 0;
  }

  // Calc total with payment conditions
  if (payment) {
    // Payment with discount
    if (payment.discount.value > 0 && withoutDiscount !== 'payment') {
      if (payment.discount.type === 'percent') total *= 1 - payment.discount.value / 100;
      else if (payment.discount.type === 'money') total -= payment.discount.value;
    }

    if (!subTotal) {
      // Payment with installments
      total = payment.installments.reduce((c, i) => c + i.value, 0);
    }
  }

  return total;
}

export function createCashFlow(supplier) {
  let categories = supplier.products.map((p) => p.category.id || p.category);
  categories = categories.filter((c, i) => categories.indexOf(c) === i);
  return supplier.payment.installments.map((i) => {
    let description = '';
    if (supplier.products.length > 1) {
      const products = supplier.products.map((p) => p.name);
      description = `Concerning products: ${
        products.slice(0, -1).join(', ')
      } e ${products.slice(-1)}.`;
    } else {
      description = `Concerning products ${supplier.products[0].name}.`;
    }

    return {
      type: 'out',
      value: i.value,
      dueAt: i.due,
      account: null,
      categories,
      contact: supplier.register.cnpj,
      objects: supplier.products.map((p) => ({ ...p, type: 'product' })),
      completed: {
        on: false,
        date: null,
        value: 0,
      },
      description,
      payment: {
        method: supplier.payment.method,
      },
    };
  });
}
