import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  container: {
    maxWidth: 942,
    width: '100%',
    margin: 'auto',
    marginTop: 100,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(),
    boxShadow: '2px 4px 8px #ddd',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
      marginBottom: 0,
    },
  },
  title: {
    width: '100%',
    color: theme.palette.tertiary.main,
    fontWeight: 'bold',
    position: 'relative',
    padding: `${theme.spacing(2)}px 0`,
    textTransform: 'uppercase',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 40,
      height: 3,
      borderRadius: 2,
      left: 'calc(50% - 20px)',
      top: '4.5rem',
      backgroundColor: theme.palette.primary.main,
      marginBottom: 4,
    },
  },
  row: {
    width: '100%',
    padding: `0 ${theme.spacing()}px`,
    margin: 0,
  },
  subtitle: {
    marginTop: theme.spacing(),
    '& > span': {
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  button: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`,
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 20,
  },
  containerAnswered: {
    maxWidth: 700,
    minHeight: '100vh',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(),
    paddingTop: 80 + theme.spacing(2),
  },
  answeredIcon: {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(2),
  },

  // Chat
  chatButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  chatButtonBadge: {
    position: 'fixed',
    top: 'calc(100% - 80px)',
    right: 40,
  },
  chat: {
    marginBottom: theme.spacing(),
    width: '100%',
    // height: '100%',
    maxWidth: 350,
    // maxHeight: 450,
    zIndex: 10000,
  },
  chatHeader: {
    padding: theme.spacing(),
    background: theme.palette.primary.main,
    color: 'white',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  chatContent: {
    height: 350,
    overflow: 'auto',
  },
  chatMessage: {
    position: 'relative',
    width: '100%',
    maxWidth: 'fit-content',
    padding: theme.spacing(),
    margin: theme.spacing(),
    borderRadius: 4,
    '& > p': {
      fontSize: 12,
      color: theme.palette.common.grey,
      margin: 0,
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      borderBottom: `${theme.spacing()}px solid transparent`,
      top: 0,
    },
  },
  chatMessageSend: {
    background: lighten(theme.palette.primary.main, 0.8),
    textAlign: 'right',
    '&:after': {
      borderLeft: `${theme.spacing()}px solid ${lighten(theme.palette.primary.main, 0.8)}`,
      right: -12,
    },
  },
  chatMessageReceive: {
    background: lighten(theme.palette.secondary.main, 0.8),
    '&:after': {
      borderRight: `${theme.spacing()}px solid ${lighten(theme.palette.secondary.main, 0.8)}`,
      left: -12,
    },
  },
  chatSender: {
    padding: theme.spacing(),
  },

  // Upload files
  progress: {
    width: '100%',
    margin: theme.spacing(),
  },
  uploadFile: {
    background: lighten(theme.palette.secondary.main, 0.9),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  uploadFileConfirm: {
    background: lighten(theme.palette.success.main, 0.9),
    border: `1px solid ${theme.palette.success.main}`,
  },
  delete: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  name: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: `calc(100% - ${theme.spacing()}px)`,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  checkedFile: {
    color: theme.palette.success.main,
  },
  buttonUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
