import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  select: {
    paddingRight: 24,
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
}));
