import React from 'react';
import { useHistory } from 'react-router';
import Fab from '@material-ui/core/Fab';
// import SocialMedia from 'common/social-media';

// Assets and Styles
import { NotFound, NotFoundContainer, NotFoundBg } from './style';

function Error404() {
  const history = useHistory();

  const back = () => {
    history.goBack();
  };

  return (
    <NotFound>
      <NotFoundContainer>
        <NotFoundBg>
          <div />
          <div />
          <div />
        </NotFoundBg>

        <h1>oops!</h1>
        <h2>Page not found</h2>

        <Fab variant="extended" color="primary" size="large" onClick={() => back()}>
          BACK
        </Fab>

        {/* <SocialMedia /> */}
      </NotFoundContainer>
    </NotFound>
  );
}

export default Error404;
