import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Components and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function Filter({ onClose }) {
  const classes = styles();

  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [initialForm, setInitialForm] = useState({
    productName: '',
    categories: [],
    responsibles: [],
    currentValue: { min: 0, max: 0 },
    forecastValue: { min: 0, max: 0 },
    currentBiggerForecast: false,
    paymentLate: false,
    deliveryLate: false,
  });

  const { users, categories } = useSelector((state) => state.company);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const product = params.get('product');

    const steps = params.get('steps') && params.get('steps').split(',');
    const filterCategories = params.get('categories') && params.get('categories').split(',');
    const responsibles = params.get('responsibles') && params.get('responsibles').split(',');

    const currentValue = {
      min: parseFloat(params.get('currentMin')),
      max: parseFloat(params.get('currentMax')),
    };
    const forecastValue = {
      min: parseFloat(params.get('forecastMin')),
      max: parseFloat(params.get('forecastMax')),
    };

    const currentBiggerForecast = Boolean(params.get('currentBiggerForecast'));
    const paymentLate = Boolean(params.get('paymentLate'));
    const deliveryLate = Boolean(params.get('deliveryLate'));

    setInitialForm({
      productName: product || '',
      steps: steps || [],
      categories: filterCategories || [],
      responsibles: responsibles || [],
      currentValue: currentValue.max ? currentValue : { min: 0, max: 0 },
      forecastValue: forecastValue.max ? forecastValue : { min: 0, max: 0 },
      currentBiggerForecast: currentBiggerForecast || false,
      paymentLate: paymentLate || false,
      deliveryLate: deliveryLate || false,
    });
  }, [location.search]);

  const clear = () => {
    history.push(location.pathname);
    onClose();
  };

  const filter = (data) => {
    const params = [];

    if (data.productName !== '') params.push(`product=${data.productName}`);
    if (data.steps.length > 0) {
      params.push(`steps=${data.steps.join(',')}`);
    }
    if (data.categories.length > 0) {
      params.push(`categories=${data.categories.join(',')}`);
    }
    if (data.responsibles.length > 0) {
      params.push(`responsibles=${data.responsibles.join(',')}`);
    }
    if (data.currentValue.min > 0 || data.currentValue.max > 0) {
      params.push(`currentMin=${data.currentValue.min}&currentMax=${data.currentValue.max}`);
    }
    if (data.forecastValue.min > 0 || data.forecastValue.max > 0) {
      params.push(`forecastMin=${data.forecastValue.min}&forecastMax=${data.forecastValue.max}`);
    }
    if (data.currentBiggerForecast) params.push('currentBiggerForecast=1');
    if (data.paymentLate) params.push('paymentLate=1');
    if (data.deliveryLate) params.push('deliveryLate=1');

    history.push(`${history.location.pathname}?${params.join('&')}`);
    onClose();
  };

  const getCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        getChildren(c, `${label} > ${c.name}`);
      });
    };

    if (categories) {
      categories.filter((c) => c.parent === 'suppliers').forEach((category) => {
        cat.push(category);
        getChildren(category, category.name);
      });
    }

    return cat;
  };

  const formSchema = FormConfig({
    categories: getCategories(),
    users,
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="view-product-category-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Filter products</span>
          <IconButton onClick={onClose} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form id="filter-products" onSubmit={filter} initialState={initialForm} schema={formSchema}>
          {(inputs) => (
            <Grid container alignItems="center" spacing={1}>
              <Grid container item>
                {inputs.productName}
              </Grid>
              <Grid container item>
                {inputs.steps}
              </Grid>
              <Grid container item>
                {inputs.categories}
              </Grid>
              <Grid container item>
                {inputs.responsibles}
              </Grid>

              <Grid container item>
                <Typography gutterBottom variant="overline" className={classes.title}>
                CURRENT VALUE
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {inputs.currentValue.min}
                  </Grid>
                  <Grid item xs={6}>
                    {inputs.currentValue.max}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item>
                <Typography gutterBottom variant="overline" className={classes.title}>
                ESTIMATED VALUE
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {inputs.forecastValue.min}
                  </Grid>
                  <Grid item xs={6}>
                    {inputs.forecastValue.max}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item xs={12}>
                  {inputs.currentBiggerForecast}
                </Grid>
              </Grid>

              <Grid container item>
                <Typography gutterBottom variant="overline" className={classes.title}>
                DELAYS
                </Typography>
                <Grid item xs={12}>
                  {inputs.paymentLate}
                </Grid>
                <Grid item xs={12}>
                  {inputs.deliveryLate}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="secondary" onClick={clear}>
          Clear
        </Button>
        <Button
          type="submit"
          form="filter-products"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Filter;
