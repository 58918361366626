import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GlobalHotKeys } from 'react-hotkeys';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

// Routes
import routes from 'routes';
import Error404 from 'routes/errors/404';
import { renderRoutes } from 'routes/utils';

import Alert from 'common/alert';

import { defaultTheme, idealize, plan, manage } from './theme';

const keyMap = {
  SNIPPET: 'ctrl+space',
};

const handlers = {
  SNIPPET: (e) => {
    const snippet = document.getElementById('snippet');
    if (snippet) snippet.focus();
  },
};

// const browserLang = navigator.language.toLocaleLowerCase() || navigator.browserLanguage.toLocaleLowerCase();

function App() {
  // if(browserLang === 'pt-br' && window.location.hostname != 'app.hybri.io'){
  //   window.location.href = 'https://app.hybri.io/';
  // } else if (browserLang !== 'pt-br' && window.location.hostname == 'app.hybri.io'){    
  //   window.location.href = 'https://en-app.hybri.io/';
  // }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <MuiThemeProvider theme={defaultTheme}>
        <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
          <BrowserRouter>
            <Switch>
              {renderRoutes(routes)}
              <Route component={Error404} />
            </Switch>
          </BrowserRouter>
        </GlobalHotKeys>
        <Alert />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
