import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import createImporter from 'services/importer';

// Material components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useEventController from 'store/event/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Product from 'store/supplier/product/controller';
import useCategoryController from 'store/category/controller';

// Commons, Icons and styles
import { Plus, Filter, MoreVertical, Box, Bookmark, LogIn } from 'react-feather';
import { Suppliers, Financial, Partners } from 'assets/custom-icons/modules';
import Tooltip from 'common/tooltip';
import ToolbarPage from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import FilterDialog from '../components/filter';
import styles from './style';

// Components
import TableDesktop from './desktop';
import TableMobile from './mobile';

function ProductsTable() {
  const classes = styles();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { eventId } = useParams();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  // Controllers
  const Event = useEventController(dispatch);

  const {
    current: event, suppliers, products, cashFlow,
  } = useSelector((state) => state.event);

  const { current: company, categories, users } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  const [importer, setImporter] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBadge, setFilterBadge] = useState(0);

  const permissionView = !permissions || !permissions.manager || permissions.manager.procurement.view;
  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Nome do item',
          key: 'name',
          isRequired: true,
        },
        {
          label: 'Category',
          key: 'category',
          isRequired: true,
        },
        {
          label: 'Forecast value',
          key: 'valueForecast',
          validators: [{
            validate: 'regex_matches',
            regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
            error: 'Not a valid number (remove coin \'$\', if there is any)',
          }],
        },
        {
          label: 'Responsible',
          key: 'responsible',
        },
      ],
    }));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const filters = [
      'product',
      'steps',
      'categories',
      'responsibles',
      'currentMin',
      'forecastMin',
      'currentBiggerForecast',
      'paymentLate',
      'deliveryLate',
    ];

    let badge = 0;
    filters.forEach((filter) => {
      if (params.get(filter)) badge += 1;
    });

    setFilterBadge(badge);
  }, [location.search]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
    }
  }, [event, eventId, dispatch, Event]);

  // Load data to use in this module
  useEffect(() => {
    if (event && event.id === eventId) {
      if (!suppliers) dispatch(Supplier.indexEvent(event.id));
      if (!products) dispatch(Product.index(event.id));
      if (!cashFlow) dispatch(CashFlow.index());
    }
  }, [event, eventId, suppliers, products, cashFlow, dispatch]);

  const closeMenu = () => {
    setOpenMenu(false);
    setAnchorMenu(null);
  };

  const importItems = () => {
    const convertNumber = (number) => parseFloat(
      number.trim().split('.')
        .join('')
        .split(',')
        .join('.'),
    );

    const randomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    importer.requestDataFromUser().then(async ({ data }) => {
      const createdCategories = [];
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];
        const valueForecast = convertNumber(item.valueForecast);

        let category = categories.find(
          (c) => c.name.toLowerCase() === item.category.toLowerCase(),
        );
        if (!category) {
          category = createdCategories.find(
            (c) => c.name.toLowerCase() === item.category.toLowerCase(),
          );
        }

        if (!category) {
          const categoryData = {
            color: randomColor(),
            type: 'out',
            parent: 'suppliers',
            name: item.category,
          };
          // eslint-disable-next-line no-await-in-loop
          const categoryId = await Category.store(categoryData);
          createdCategories.push({ id: categoryId, name: item.category });
          category = { id: categoryId };
        }

        const responsible = users.find(
          (u) => u.name.toLowerCase() === item.responsible.toLowerCase(),
        );

        const itemData = {
          valueForecast,
          name: item.name,
          category: category.id,
          responsible: responsible ? responsible.id : null,
        };

        dispatch(Product.store(itemData));
      }

      importer.displaySuccess('Todos os dados importados!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
    closeMenu();
  };

  const toolbarIcons = [];
  if (permissionEdit) {
    toolbarIcons.push({
      label: 'Import items',
      icon: <LogIn style={{ transform: 'rotate(180deg)' }} />,
      click: importItems,
      disabled: !users || !categories,
    });
  }

  const tabsToolbar = [
    { title: 'Dashboard', path: `/manager/${eventId}/procurement` },
    { title: 'Items', selected: true },
    { title: 'Negotiations', path: `/manager/${eventId}/procurement/negotiations` },
  ];

  return (
    <div className={classes.container}>
      <ToolbarPage
        avatar
        defaultIcons
        title="COMPRAS"
        position="fixed"
        sidebar="/manager/procurement"
        icons={toolbarIcons}
        tabs={tabsToolbar}
      />

      <Grid container justify="center" className={classes.row} style={{ paddingTop: 122 }}>
        <Paper id="items" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Typography variant="h5" className={classes.toolbarTitle}>
                ITEMS
              </Typography>

              {upSm && permissionEdit && (
                <IconButton
                  color="primary"
                  aria-label="Add item"
                  onClick={() => history.push(`/manager/${eventId}/procurement/items/products/new`)}
                >
                  <Plus size={32} strokeWidth="3" />
                </IconButton>
              )}
            </div>

            {upSm ? (
              <div style={{ display: 'inline-flex' }}>
                <Tooltip title="Filtro">
                  <IconButton
                    color="primary"
                    aria-label="Filter"
                    className={classes.toolbarButton}
                    onClick={() => setOpenFilter(true)}
                  >
                    <Badge badgeContent={filterBadge} color="secondary">
                      <Filter />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={20000}
                  classes={{ popper: classes.legend, tooltip: classes.legendTooltip }}
                  title={(
                    <Grid container direction="column" alignItems="flex-start">
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#dc1f26' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          No supplier
                        </Typography>
                      </div>
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#4eb1d4' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          Get in contact
                        </Typography>
                      </div>
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#e6d539' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          Negotiation
                        </Typography>
                      </div>
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#5c5aa7' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          Hiring
                        </Typography>
                      </div>
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#f59658' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          Closed
                        </Typography>
                      </div>
                      <div className={classes.legendStep}>
                        <div className={classes.step} style={{ background: '#4bb75c' }} />
                        <Typography vartiant="overlay" className={classes.legendStepText}>
                          Paid
                        </Typography>
                      </div>
                    </Grid>
                  )}
                >
                  <Button
                    color="primary"
                    aria-label="Designators"
                    variant="outlined"
                    className={classes.legendButton}
                  >
                    Designators
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <div>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="Opções"
                  aria-haspopup="true"
                  onClick={(e) => {
                    setOpenMenu('items_opt');
                    setAnchorMenu(e.currentTarget);
                  }}
                >
                  <MoreVertical />
                </IconButton>

                <Menu
                  anchorEl={anchorMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={openMenu === 'items_opt'}
                  onClose={closeMenu}
                >
                  <MenuItem
                    onClick={() => {
                      setOpenFilter(true);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <Filter />
                    </ListItemIcon>
                    <ListItemText primary="Filtro" />
                  </MenuItem>
                  {permissionEdit && (
                    <>
                      <MenuItem
                        onClick={() => {
                          history.push(`/manager/${eventId}/procurement/items/categories/new`);
                          closeMenu();
                        }}
                      >
                        <ListItemIcon>
                          <Bookmark />
                        </ListItemIcon>
                        <ListItemText primary="New category" />
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          history.push(`/manager/${eventId}/procurement/items/products/new`);
                          closeMenu();
                        }}
                      >
                        <ListItemIcon>
                          <Box />
                        </ListItemIcon>
                        <ListItemText primary="New item" />
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            )}
          </Toolbar>

          {openFilter && <FilterDialog onClose={() => setOpenFilter(false)} />}

          <div className={classes.tableWrapper}>{upSm ? <TableDesktop /> : <TableMobile />}</div>
        </Paper>
      </Grid>

      <Onboarding id="manager" title="Follow the management of your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="manager"
          frameborder="0"
          src="https://www.youtube.com/embed/x17RaEoauh0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      <Tools />
    </div>
  );
}

export default ProductsTable;
