import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'overlay',
    overflowX: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing()}px ${theme.spacing()}px`,
      maxHeight: 'inherit',
      height: 'auto',
    },
  },
  content: {
    padding: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontWeight: 300,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      marginBottom: theme.spacing(0.5),
    },
  },
  titleExpansion: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 900,
    padding: `${theme.spacing(0.5)}px 0`,
  },
  expanded: {
    margin: '12px 0 !important',
  },

  submenu: {
    padding: 0,
    // top: theme.spacing(0.5),
  },
  submenuItem: {
    background: 'none',
    position: 'relative',
    border: 'none',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    fontSize: 20,
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    padding: theme.spacing(),
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in',
    zIndex: 0,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  itemActived: {
    background: theme.palette.secondary.main,
    boxShadow: '0px 6px 23px 4px rgba(81,77,92,0.3)',
    cursor: 'auto',
    color: 'white',
    zIndex: 1,
    transform: 'scale(1.02)',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  itemIcon: {
    marginRight: theme.spacing(),
    '& path': {
      stroke: theme.palette.secondary.main,
    },
  },
  itemIconActived: {
    '& path': {
      stroke: 'white',
    },
  },

  card: {
    marginLeft: theme.spacing(4),
    padding: theme.spacing(),
  },
}));
