import { makeStyles, lighten, fade, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sidebarShort: {
    position: 'fixed',
    width: 130,
    textAlign: 'center',
    minHeight: '100vh',
    zIndex: 1200,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[2],
    background: 'white',
  },
  logo: {
    height: 40,
    margin: `${theme.spacing(2)}px 0 0`,
  },
  list: {
    position: 'relative',
    textAlign: 'left',
    padding: 0,
    margin: `${theme.spacing(4)}px ${theme.spacing(-2)}px 0`,
  },
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(),
    color: theme.palette.primary.main,
    textDecoration: 'none !important',
    justifyContent: 'center',
    border: 'none',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    background: fade(theme.palette.secondary.main, 0),
    transition: 'background 1s ease',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: `linear-gradient(to right, ${fade(theme.palette.secondary.main, 0)} 0%, ${fade(theme.palette.secondary.main, 0.08)} 100%)`,
    },
  },
  itemOpened : {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    margin: `0 ${theme.spacing(-1)}px`,
    color: theme.palette.primary.main,
    textDecoration: 'none !important',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    background: fade(theme.palette.secondary.main, 0),
    transition: 'background 1s ease',
    overflow: 'hidden',
    boxShadow: 'none !important',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: `linear-gradient(to right, ${fade(theme.palette.secondary.main, 0)} 0%, ${fade(theme.palette.secondary.main, 0.05)} 100%)`,
    },
    '&:last-child': {
      marginBottom: theme.spacing(),
    },
  },
  itemActive: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  itemActiveOpened: {
    fontWeight: 600,
  },
  label: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(),
  },
  labelOpened: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: 'opacity 0.5s ease',
    '&:hover': {
      opacity: 0.4,
    },
  },
  backgroundActive: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 1,
  },
  submenu: {
    position: 'absolute',
    top: '50%',
    right: 8,
    transform: 'translate(100%, -50%)',
    background: 'white',
    padding: '6px 0',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 0,
    overflow: 'hidden',
    boxShadow: theme.shadows[15],
    opacity: 0,
    zIndex: 1000,
    transition: 'opacity 0.2s ease-in',
  },
  submenuOpen: {
    maxWidth: 300,
    minWidth: 150,
    opacity: 1,
  },
  submenuItem: {
    textAlign: 'left',
    fontSize: 12,
    textTransform: 'uppercase',
    color: darken(theme.palette.primary.main, 0.1),
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    textDecoration: 'none !important',
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.9),
    },
  },
  marker: {
    position: 'absolute',
    width: 4,
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    bottom: 0,
    right: 0,
    borderRadius: 4,
    transition: 'top 0.2s cubic-bezier(0.265, 0.080, 1.000, 0.660)',
  },
  trial: {
    fontSize: 10,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px`,
    '& p': {
      fontSize: 30,
      fontWeight: 600,
      color: theme.palette.warning.main,
    },
  },
}));
