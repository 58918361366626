import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chart: {
    height: '100%',
    width: '100%',
    padding: `0 ${theme.spacing(4)}px 0 0`,
    '& svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  nodata: {
    color: theme.palette.common.grey,
  },
  period: {
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  chip: {
    margin: theme.spacing(0.25),
    cursor: 'pointer',
  },
  chipDashed: {
    margin: theme.spacing(0.25),
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.secondary.main}`,
  },
  datepicker: {
    '& input': {
      display: 'none',
    },
  },
  toolbarDatePicker: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  // Custom dialog
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
}));
