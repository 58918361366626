import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flexWrap: 'nowrap',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0 !important',
    },
  },
  chartTitle: {
    position: 'absolute',
    top: 0,
    width: 300,
    color: theme.palette.tertiary.main,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 16,
  },
  chartLegend: {
    position: 'relative',
    margin: 16,
    padding: '0 16px',
    transition: 'all 0.5s',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
  chartSelected: {
    opacity: 0.5,
  },
  dataSelected: {
    opacity: 1,
    transform: 'scale(1.1)',
  },
  chartLegendLabel: {
    fontSize: 12,
  },
  chartLegendValue: {
    fontSize: 18,
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  chartLegendBar: {
    position: 'absolute',
    top: -2,
    bottom: 2,
    left: 0,
    width: 4,
    borderRadius: 2,
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
}));
