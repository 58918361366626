import { createMuiTheme } from '@material-ui/core/styles';

const shadows = [];

for (let i = 1; i < 25; i += 1) {
  shadows.push(
    `${parseInt(1 + (i / 8), 10)}px `
    + `${1 + parseInt(4 + (i / 8), 10)}px `
    + `${24 + i}px 0 `
    + `rgba(81,77,92,${0.1 + parseInt(i / 8, 10) / 10})`,
  );
}

const common = {
  palette: {
    common: {
      black: '#313131',
      grey: '#777',
    },    
    text: {
      primary: '#2d2d2d',
      secondary: '#7b7b7b',
    },
    success: {
      light: '#6fc57c',
      main: '#4bb75c',
      dark: '#348040',
      contrastText: '#313131',
    },
    warning: {
      light: '#f7ab79',
      main: '#f59658',
      dark: '#ab693d',
      contrastText: '#313131',
    },
    waiting: {
      light: '#ebdd60',
      main: '#e6d539',
      dark: '#a19527',
      contrastText: '#313131',
    },
    error: {
      light: '#e34b51',
      main: '#dc1f26',
      dark: '#9a151a',
      contrastText: '#fff',
    },
    // contrastThreshold: 2.6,
  },
  shadows: ['none', ...shadows],
  typography: {
    useNextVariants: true,
  },
  spacing: 16,
};

export const idealize = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    primary: {
      light: '#247516',
      main: '#29801A',
      dark: '#0E4E03',
      contrastText: '#fff',
    },
    secondary: {
      light: '#92DA86',
      main: '#77c868',
      dark: '#55B045',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#798bc7',
      main: '#586eba',
      dark: '#3d4d82',
      contrastText: '#fff',
    },
  },
});

export const plan = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    primary: {
      light: '#2979DA',
      main: '#1262C4',
      dark: '#074DA3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#69ACFE',
      main: '#4291ef',
      dark: '#2777D9',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#798bc7',
      main: '#586eba',
      dark: '#3d4d82',
      contrastText: '#fff',
    },
  },
});

export const manage = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    primary: {
      light: '#8560DC',
      main: '#5a33b5',
      dark: '#431C9E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9E7EE9',
      main: '#815DD5',
      dark: '#6A45C0',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#798bc7',
      main: '#5a33b5',
      dark: '#3d4d82',
      contrastText: '#fff',
    },
  },
});

export const defaultTheme = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    primary: {
      light: '#8560DC',
      main: '#5a33b5',
      dark: '#431C9E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#69ACFE',
      main: '#4291ef',
      dark: '#2777D9',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#798bc7',
      main: '#586eba',
      dark: '#3d4d82',
      contrastText: '#fff',
    },
  },
});