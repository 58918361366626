import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Components and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function Filter({ onClose }) {
  const classes = styles();

  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [onlyType, setOnlyType] = useState(false);
  const [initialForm, setInitialForm] = useState({
    date: { min: null, max: null },
    categories: [],
    costCenter: [],
    value: { min: 0, max: 0 },
    dueLate: false,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const only = params.get('onlyType');

    const date = {
      min: params.get('dateMin') && new Date(parseInt(params.get('dateMin'), 10)),
      max: params.get('dateMax') && new Date(parseInt(params.get('dateMax'), 10)),
    };

    const categories = params.get('categories') && params.get('categories').split(',');
    const costCenter = params.get('costCenter') && params.get('costCenter').split(',');

    const value = {
      min: parseFloat(params.get('valueMin')),
      max: parseFloat(params.get('valueMax')),
    };

    const dueLate = Boolean(params.get('dueLate'));

    setOnlyType(only || false);
    setInitialForm({
      date: date.max ? date : { min: null, max: null },
      categories: categories || [],
      costCenter: costCenter || [],
      value: value.max ? value : { min: 0, max: 0 },
      dueLate: dueLate || false,
    });
  }, [location.search]);

  const clear = () => {
    history.push(location.pathname);
    onClose();
  };

  const filter = (data) => {
    const params = [];

    if (onlyType) {
      params.push(`onlyType=${onlyType}`);
    }
    if (data.date.min && data.date.max) {
      params.push(`dateMin=${data.date.min.getTime()}&dateMax=${data.date.max.getTime()}`);
    }
    if (data.categories.length > 0) {
      params.push(`categories=${data.categories.join(',')}`);
    }
    if (data.costCenter.length > 0) {
      params.push(`costCenter=${data.costCenter.join(',')}`);
    }
    if (data.value.min > 0 || data.value.max > 0) {
      params.push(`valueMin=${data.value.min}&valueMax=${data.value.max}`);
    }
    if (data.dueLate) params.push('dueLate=1');

    history.push(`${history.location.pathname}?${params.join('&')}`);
    onClose();
  };

  const formSchema = FormConfig({ initialForm, onlyType });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableEscapeKeyDown
      open
      onClose={onClose}
      aria-labelledby="view-product-category-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>
            {onlyType === 'in' ? 'Filter entry movement'
              : (onlyType === 'out' && 'Filter output movement')
                || 'Filter cash flow'}
          </span>
          <IconButton onClick={onClose} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form id="filter-products" onSubmit={filter} initialState={initialForm} schema={formSchema}>
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant={!onlyType || onlyType === 'in' ? 'contained' : 'outlined'}
                  className={!onlyType || onlyType === 'in'
                    ? classes.containedGreen : classes.outlinedGreen}
                  onClick={() => {
                    if (!onlyType) setOnlyType('out');
                    else setOnlyType(onlyType === 'in' ? 'out' : false);
                  }}
                >
                  DEPOSITS
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={!onlyType || onlyType === 'out' ? 'contained' : 'outlined'}
                  className={!onlyType || onlyType === 'out'
                    ? classes.containedRed : classes.outlinedRed}
                  onClick={() => {
                    if (!onlyType) setOnlyType('in');
                    else setOnlyType(onlyType === 'out' ? 'in' : false);
                  }}
                >
                  EXPENSES
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="overline" className={classes.title}>
                  PERIOD
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {inputs.date.min}
              </Grid>
              <Grid item xs={12} sm={6}>
                {inputs.date.max}
              </Grid>

              <Grid item xs={12}>
                {inputs.categories}
              </Grid>

              <Grid item xs={12}>
                {inputs.costCenter}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="overline" className={classes.title}>
                  VALUE OF
                  {onlyType === 'in' ? ' DEPOSIT'
                    : (onlyType === 'out' && ' EXPENSE') || ' MOVIMENTATION'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inputs.value.min}
              </Grid>
              <Grid item xs={6}>
                {inputs.value.max}
              </Grid>

              <Grid item xs={12} />
              <Grid item xs={12}>
                {inputs.dueLate}
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="secondary" onClick={clear}>
          Clear
        </Button>
        <Button
          type="submit"
          form="filter-products"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Filter.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Filter;
