import { makeStyles, lighten, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawer: {
    left: 'inherit !important',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  content: {
    width: '100vw',
    maxWidth: 450,
    padding: theme.spacing(),
  },
  title: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: theme.spacing(),
  },
  avatar: {
    background: theme.palette.primary.main,
  },

  // Message Box
  sender: {
    fontSize: '12px !important',
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
  },
  msgBox: {
    position: 'relative',
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    margin: 4,
    borderRadius: 8,
    background: lighten(theme.palette.secondary.main, 0.8),
    width: 'fit-content',
    maxWidth: '100%',
    whiteSpace: 'pre-line',
    '& p': {
      fontSize: 10,
      color: theme.palette.common.grey,
      marginTop: 8,
    },
    '&:before': {
      content: '" "',
      width: 0,
      height: 0,
      position: 'absolute',
      bottom: 0,
      left: -10,
      border: `8px solid ${lighten(theme.palette.secondary.main, 0.8)}`,
      borderTop: '8px solid transparent',
      borderLeft: '8px solid transparent',
    },
  },
  sdrBox: {
    background: lighten(theme.palette.primary.main, 0.8),
    '& p': {
      textAlign: 'right',
    },
    '&:before': {
      left: 'inherit',
      right: -10,
      border: `8px solid ${lighten(theme.palette.primary.main, 0.8)}`,
      borderTop: '8px solid transparent',
      borderRight: '8px solid transparent',
    },
  },
  logBox: {
    padding: '4px 16px',
    margin: theme.spacing(0.5),
    borderRadius: 16,
    background: lighten(theme.palette.tertiary.main, 0.6),
    width: 'fit-content',
    textAlign: 'center',
  },
  msgDoc: {
    padding: 4,
    borderRadius: 4,
    background: lighten(theme.palette.secondary.main, 0.7),
    width: 'calc(100% + 16px)',
    margin: '0 -8px',
    cursor: 'pointer',
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.6),
    },
  },
  docSdr: {
    background: lighten(theme.palette.primary.main, 0.7),
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.6),
    },
  },
  dividerBox: {
    padding: '4px 16px',
    margin: theme.spacing(0.5),
    borderRadius: 16,
    background: lighten(theme.palette.common.grey, 0.8),
    width: 'fit-content',
    textAlign: 'center',
    zIndex: 2,
  },
  divider: {
    position: 'absolute',
    border: `1px solid ${lighten(theme.palette.common.grey, 0.8)}`,
    left: 0,
    right: 0,
    top: '50%',
    zIndex: 1,
  },

  // Senderbox
  attachBack: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: fade('#000000', 0.7),
    zIndex: 100,
    padding: theme.spacing(2),
  },
  attachDialog: {
    background: 'white',
    boxShadow: theme.shadows[24],
    borderRadius: 4,
    padding: theme.spacing(),
  },
  attachs: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 24,
    padding: theme.spacing(),
    marginBottom: -49,
    paddingBottom: 56,
    whiteSpace: 'nowrap',
    width: '100%',
    transition: 'max-height 0.3s ease-out, padding 0.3s ease-out',
  },
  file: {
    display: 'inline-flex',
    background: 'red',
    height: 60,
    width: 60,
    margin: 4,
  },

  labelListUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
