import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import _ from 'lodash';

// Material components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import Product from 'store/supplier/product/controller';
import Log from 'store/log/controller';

// Icons, Commons and styles
import { Trash2, Edit2, ChevronDown } from 'react-feather';
import { installmentWithCashFlow } from 'common/utils/relationships';
import { numberToReal } from 'common/utils/numbers';
import ConfirmDialog from 'common/confirm-dialog';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import styles from './style';
import { generateTableData, filter } from './utils';

function TableMobile() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { eventId } = useParams();

  const { users } = useSelector((state) => state.company);
  const { products, suppliers } = useSelector((state) => state.event);
  const { categories, cashFlow } = useSelector((state) => state.company);
  const isLoaded = !!suppliers && !!products && !!categories && !!cashFlow;

  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [delProduct, setDelProduct] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      const supp = suppliers.map((s) => {
        const supplier = _.cloneDeep(s);

        if (s.payment && s.payment.installments) {
          supplier.payment.installments = s.payment.installments.map(
            (i) => installmentWithCashFlow(i, cashFlow),
          );
        }

        return supplier;
      });

      const dataTable = generateTableData({
        suppliers: supp, products, categories, users,
      });

      if (location.search === '') setData(dataTable);
      else setData(filter(dataTable, location.search));
    }
  }, [isLoaded, suppliers, products, categories, cashFlow, users, location.search]);

  const expandPanel = (panel, exp) => {
    setExpanded(exp ? panel : false);
  };

  if (!isLoaded) return <Loading ready={isLoaded} content={100} />;

  if (data.length === 0) {
    if (location.search === '') {
      return (
        <Typography variant="h5" className={classes.withoutData}>
          Start registering the products needed for your event.
        </Typography>
      );
    }

    return (
      <Typography variant="h5" className={classes.withoutData}>
        No products matching this filter.
      </Typography>
    );
  }

  return (
    <>
      {data.map((category) => (
        <React.Fragment key={category.id}>
          <div className={classes.tableCategory} style={{ color: category.color }}>
            <div className={classes.categoryColor} style={{ background: category.color }} />
            {category.name}
            <IconButton
              color="primary"
              aria-label="Editar"
              aria-haspopup="true"
              style={{ float: 'right', marginTop: -8 }}
              onClick={() => {
                history.push(`/manager/${eventId}/procurement/items/categories/${category.id}`);
              }}
            >
              <Edit2 size={16} />
            </IconButton>
          </div>

          {category.products.map((product) => {
            let currentValueColor = '#4bb75c';
            let paymentTootlip = 'Payment made';
            let deliveryTooltip = 'Performed Delivery';

            if (!product.currentValue.isInsideForecast) {
              if (product.steps.reduce((c, v) => c + v, 0) > 1) {
                currentValueColor = '#dc1f26';
              } else currentValueColor = '#f59658';
            }

            if (!product.payment.payed) {
              if (product.payment.isDue) paymentTootlip = 'Late payment';
              else paymentTootlip = 'Próximo pagamento';
            }

            if (!product.delivery.done) {
              if (product.delivery.isDue) deliveryTooltip = 'Late delivery';
              else deliveryTooltip = null;
            }

            return (
              <ExpansionPanel
                className={classes.expasionPanel}
                expanded={expanded === product.id}
                key={product.id}
                onChange={(e, exp) => expandPanel(product.id, exp)}
              >
                <ExpansionPanelSummary expandIcon={<ChevronDown />}>
                  <Tooltip title={product.formatedSteps.title}>
                    <div className={classes.steps}>
                      {product.formatedSteps.color.map((color) => (
                        <div
                          key={`${product.id}-steps-${color}`}
                          className={classes.step}
                          style={{ background: color }}
                        />
                      ))}
                    </div>
                  </Tooltip>
                  <Typography className={classes.heading}>{product.name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container direction="column">
                    <Grid container className={classes.contentExpandMobile}>
                      <Grid item xs={6}>
                        <Typography variant="caption">Estiamted value</Typography>
                        <Typography variant="body1">
                          {product.valueForecast ? numberToReal(product.valueForecast) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {product.currentValue && (
                          <div>
                            <Typography variant="caption">Current value</Typography>
                            <Typography
                              variant="body1"
                              style={{ color: currentValueColor }}
                            >
                              {!product.currentValue.isInsideForecast ? (
                                <Tooltip
                                  title={
                                    product.steps.reduce((c, v) => c + v, 0) > 1
                                      ? 'Closed value is higher than estimated one.'
                                      : 'The average of the values in negotiation is higher than the predicted value.'
                                  }
                                >
                                  <span>{numberToReal(product.currentValue.value)}</span>
                                </Tooltip>
                              ) : (
                                product.currentValue.value > 0 && (
                                  <span>{numberToReal(product.currentValue.value)}</span>
                                )
                              )}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.contentExpandMobile}>
                      <Grid item xs={6}>
                        <Typography variant="caption">Unit value</Typography>
                        <Typography variant="body1">
                          {product.unitValue ? numberToReal(product.unitValue) : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="caption">Amount</Typography>
                        <Typography variant="body1">
                          {product.unitValue ? product.amount : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.contentExpandMobile}>
                      {product.payment.date && (
                        <Grid item xs={6} className={classes.contentExpandMobile}>
                          <Typography variant="caption">Payment</Typography>
                          <Typography
                            variant="body1"
                            style={{
                              color: product.payment.payed
                                ? '#4bb75c'
                                : product.payment.isDue && '#dc1f26',
                            }}
                          >
                            <Tooltip title={paymentTootlip}>
                              <span>
                                {format(product.payment.date, 'dd/MM/yyyy', { locale: ptBR })}
                              </span>
                            </Tooltip>
                          </Typography>
                        </Grid>
                      )}
                      {product.delivery.date && (
                        <Grid item xs={6}>
                          <Typography variant="caption">Entrega</Typography>
                          <Typography
                            variant="body1"
                            style={{
                              color: product.delivery.done
                                ? '#4bb75c'
                                : product.delivery.isDue && '#dc1f26',
                            }}
                          >
                            <Tooltip title={deliveryTooltip}>
                              <span>
                                {format(product.delivery.date, 'dd/MM/yyyy', { locale: ptBR })}
                              </span>
                            </Tooltip>
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.contentExpandMobile}>
                        {product.responsibles && (
                          <>
                            <Typography variant="caption" style={{ lineHeight: 2.5 }}>
                              Responsibles
                            </Typography>
                            <Grid container alignItems="center">
                              {product.responsibles.map((responsible) => (
                                <Tooltip title={responsible.name} key={responsible.id}>
                                  <Avatar
                                    alt={responsible.name}
                                    src={responsible.avatar}
                                    className={classes.avatar}
                                  >
                                    {responsible.initials}
                                  </Avatar>
                                </Tooltip>
                              ))}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonMobile}
                        onClick={() => {
                          history.push(`/manager/${eventId}/procurement/items/new?products=${product.id}`);
                        }}
                      >
                        New supplier
                      </Button>
                      <div>
                        <IconButton
                          color="primary"
                          aria-label="Edit"
                          onClick={() => {
                            history.push(`/manager/${eventId}/procurement/items/products/${product.id}`);
                          }}
                        >
                          <Edit2 size={18} />
                        </IconButton>
                        {product.steps.length === 0 && (
                          <IconButton
                            color="primary"
                            aria-label="Delete"
                            onClick={() => setDelProduct(product)}
                          >
                            <Trash2 size={18} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </React.Fragment>
      ))}

      {delProduct && (
        <ConfirmDialog
          title={`Do you really want to delete product ${delProduct.name}?`}
          onClose={() => setDelProduct(false)}
          onConfirm={() => {
            dispatch(Log.store(`Deleted product ${delProduct.name}`));
            dispatch(Product.destroy(delProduct.id));
          }}
        />
      )}
    </>
  );
}

export default TableMobile;
