import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons and Styles
import { Trash2, Check, X } from 'react-feather';
import Form from 'common/form';
import styles from './style';

// Components
import DeliveryView from './view';

function ExpansionDelivery({ supplier, edit, setEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.suppliers
    || permissions.suppliers.negotiations.edit;

  const submit = (delivery) => {
    dispatch(Log.store(`The delivery of supplier ${supplier.name} was changed`));
    dispatch(Supplier.update(supplier.id, { delivery }));
    setEdit(false);
  };

  const confirmDelivery = () => {
    dispatch(Log.store(`The delivery of supplier ${supplier.name} was confirmed`));
    dispatch(Supplier.update(supplier.id, {
      delivery: { ...supplier.delivery, done: new Date() },
    }));
  };

  const openDelivery = () => {
    dispatch(Log.store(`The delivery of supplier ${supplier.name} was reopened`));
    dispatch(Supplier.update(supplier.id, {
      delivery: { ...supplier.delivery, done: null },
    }));
  };

  const removeDelivery = () => {
    dispatch(Log.store(`The delivery of supplier ${supplier.name} was removed`));
    dispatch(Supplier.update(supplier.id, {
      delivery: {
        estimated: null, done: null, site: '', code: '',
      },
    }));
    setEdit(false);
  };

  if (edit) {
    const formSchema = [{
      name: 'estimated',
      type: 'datetime',
      label: 'Expected Date',
      keyboard: true,
      pickerProps: { disablePast: true },
      validation: {
        minDate: { msg: '', params: { min: new Date() } },
      },
    }, {
      name: 'code',
      type: 'text',
      label: 'Tracking code',
    }, {
      name: 'site',
      type: 'text',
      label: 'Website for consult',
      validation: { url: 'Must be a valid website' },
    }];

    const initialForm = {
      site: '',
      code: '',
      ...supplier.delivery,
      estimated: supplier.delivery && supplier.delivery.estimated
        ? supplier.delivery.estimated.toDate()
        : null,

    };

    return (
      <Grid container>
        <Form
          id="form-delivery"
          onSubmit={submit}
          schema={formSchema}
          initialState={initialForm}
        >
          {(inputs) => (
            <Grid spacing={1} container justify="center" alignItems="center">
              <Grid item xs={12} sm={supplier.status === 1 ? 12 : 6}>{inputs.estimated}</Grid>
              {supplier.status > 1 && (
                <>
                  <Grid item xs={12} sm={6}>{inputs.code}</Grid>
                  <Grid item xs={12}>{inputs.site}</Grid>
                </>
              )}
            </Grid>
          )}
        </Form>

        <Grid container justify="center" className={classes.containerBtns}>
          {initialForm.estimated && (
            <Button
              color="secondary"
              variant="contained"
              onClick={removeDelivery}
              style={{ marginRight: 16 }}
            >
              <Trash2 size={16} style={{ marginRight: 8 }} />
              Remove
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setEdit(false)}
            className={classes.buttonCancel}
          >
            <X size={16} style={{ marginRight: 8 }} />
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="form-delivery"
            className={classes.buttonConfirm}
          >
            <Check size={16} style={{ marginRight: 8 }} />
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (supplier.delivery && supplier.delivery.estimated) {
    return (
      <Grid container spacing={1}>
        <DeliveryView supplier={supplier} />

        {permissionEdit && (
          <Grid container item justify="center" xs={12}>
            {supplier.status > 2 && !supplier.delivery.done && supplier.delivery.estimated && (
              <Button className={classes.btnConfirm} onClick={confirmDelivery}>
                <Check size={16} style={{ marginRight: 8 }} />
                Confirm delivery
              </Button>
            )}
            {supplier.delivery.done && supplier.delivery.estimated && (
              <Button className={classes.btnCancel} onClick={openDelivery}>
                <X size={16} style={{ marginRight: 8 }} />
                Reopen delivery
              </Button>
            )}
          </Grid>
        )}

      </Grid>
    );
  }

  return (
    <Grid container spacing={1} justify="center">
      <Typography className={classes.noData}>
        {supplier.status === 1
          ? 'No negotiated delivery yet'
          : 'No delivery defined'}
      </Typography>
    </Grid>
  );
}

ExpansionDelivery.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    delivery: PropTypes.object,
    status: PropTypes.number,
  }),
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setEdit: PropTypes.func.isRequired,
};

ExpansionDelivery.defaultProps = {
  supplier: null,
};

export default ExpansionDelivery;
