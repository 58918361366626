import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  label: {
    fontSize: 12,
    color: theme.palette.common.grey,
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  noData: {
    color: theme.palette.common.grey,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));
