import { makeStyles, darken, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  infoTitle: {
    color: theme.palette.primary.main,
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  infoText: {
    color: theme.palette.common.grey,
    fontSize: 16,
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  noData: {
    color: theme.palette.common.grey,
    fontSize: 22,
    fontWeight: 300,
  },
  field: {
    padding: theme.spacing(),
  },
  fieldDate: {
    '& > p': {
      display: 'none',
    },
  },
  btn: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
  },
  containerBtns: {
    position: 'fixed',
    bottom: 0,
    maxWidth: 600,
    background: 'white',
    padding: 16,
    zIndex: 1000,
    marginLeft: -40,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
      marginLeft: 0,
    },
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    marginRight: 16,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
    },
  },
  buttonConfirm: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
    },
  },
  btnConfirm: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.08),
    },
  },
  btnCancel: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.08),
    },
  },
}));
