import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AuthComponent from 'app/auth';

// Material Components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';

// Redux Actions
import Auth from 'store/auth/controller';

// Icons, Commons, Assets and Styles
import Tooltip from 'common/tooltip';
import { Eye, EyeOff, AlertCircle } from 'react-feather';
import styles from './style';

function SnackbarError({ classes, error }) {
  const SpanError = () => (
    <Grid container alignItems="center" wrap="nowrap">
      <AlertCircle size={20} className={classes.iconError} />
      <span>{error}</span>
    </Grid>
  );

  return (
    <SnackbarContent
      className={classes.error}
      aria-describedby="client-snackbar"
      message={<SpanError />}
    />
  );
}

SnackbarError.propTypes = {
  classes: PropTypes.shape({
    error: PropTypes.string.isRequired,
    messageError: PropTypes.string.isRequired,
    iconError: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string.isRequired,
};

function SignIn() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { error } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (error && disableSubmit) {
      setDisableSubmit(false);
    }
  }, [error, disableSubmit]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    dispatch(Auth.clearError());

    setDisableSubmit(true);

    dispatch(Auth.signIn({ email, password, remember }));
  };

  return (
    <AuthComponent>
      <Grid container direction="row" justify="center" alignItems="center">
        {error && <SnackbarError classes={classes} error={error} />}

        <form onSubmit={handleSignIn}>
          <TextField
            type="email"
            id="email"
            label="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          <TextField
            type={showPassword ? 'text' : 'password'}
            id="password"
            variant="outlined"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.passwordInput}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? 'Hide password' : 'Show password'}
                    enterDelay={300}
                  >
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <Link href="/auth/reset-password" className={classes.resetPassword}>
            Forgot your password?
          </Link>

          <FormControlLabel
            checked={remember}
            onChange={(e) => setRemember(e.target.checked)}
            label="Keep me connected"
            control={<Checkbox id="remember" color="primary" />}
          />

          <Grid container direction="column" alignItems="center" justify="center">
            <Fab
              type="submit"
              color="primary"
              variant="extended"
              size="large"
              className={classes.button}
              disabled={disableSubmit}
            >
              {disableSubmit ? <CircularProgress size={24} /> : 'Log in'}
            </Fab>
            {/* <Button
              color="secondary"
              style={{ margin: '16px 0' }}
              onClick={() => history.push('/auth/sign-up')}
            >
              Register
            </Button> */}
          </Grid>
        </form>
      </Grid>
    </AuthComponent>
  );
}

export default SignIn;
