import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import Log from 'store/log/controller';

// Icons, Assets and Styles
import { MapPin, Edit2, Airplay, Eye, Trash2 } from 'react-feather';
import Tooltip from 'common/tooltip';
import { destroyTrialEvent } from '../trial-data';
import styles from './style';

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

function CardEvent({ event }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [cleaningTrial, setCleaningTrial] = useState(false);

  const {
    id, name, dates, address, test, creating, draft,
  } = event;
  const date = dates.start.toDate();

  const { current: company } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);
  const permissionEdit = !permissions || !permissions.events || permissions.events.edit;

  const goEvent = () => {
    if (company.plan === 'free') return `/create/${id}`;
    return `/planner/${id}/productivity`;
  }

  return (
    <Card className={classes.card}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Grid container wrap="nowrap" justify="space-between" alignItems="flex-start">
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            onClick={() => history.push(goEvent())}
            className={classes.title}
          >
            <div className={classes.date}>
              <div className={classes.dateDay}>{date.getDate()}</div>
              <div className={classes.dateMonth}>
                {`${MONTHS[date.getMonth()]} DE ${date.getFullYear()}`}
              </div>
            </div>
            <Typography variant="h5" style={{ fontWeight: 800, wordBreak: 'break-word' }}>
              {name}
            </Typography>
          </Grid>
          {permissionEdit && (
            <Grid item style={{ maxWidth: 30 }}>
              <IconButton
                size="small"
                onClick={() => history.push(`/create/${id}`)}
                style={{ height: 'fit-content' }}
              >
                <Edit2 size={16} className={classes.editIcon} />
              </IconButton>
              {!draft && (
                <Tooltip title="Show scope">
                  <IconButton
                    size="small"
                    onClick={() => history.push(`/create/${id}?scope=1`)}
                    style={{ height: 'fit-content' }}
                  >
                    <Eye size={16} className={classes.editIcon} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        {address && (
          <Grid container wrap="nowrap" alignItems="center" style={{ marginTop: 16 }}>
            <MapPin size={20} className={classes.locationIcon} />
            <Typography className={classes.location}>
              {`${address.localName} em ${address.city} - ${address.state}`}
            </Typography>
          </Grid>
        )}
        {creating && creating.format.type && (
          <Grid container wrap="nowrap" alignItems="center" style={{ marginTop: 16 }}>
            <Airplay size={20} className={classes.locationIcon} />
            <Typography className={classes.location}>
              {`${creating.format.type} `}
              {creating.format.ticket && (
                creating.format.ticket === 'free' ? 'free' : 'paid'
              )}
            </Typography>
          </Grid>
        )}
        {test && (
          <Grid container wrap="nowrap" justify="center" style={{ marginTop: 16 }}>
            <Button
              className={classes.btnRed}
              disabled={cleaningTrial}
              onClick={async () => {
                setCleaningTrial(true);
                await destroyTrialEvent(company, setCleaningTrial);
                dispatch(Log.store('Removed test data'));
                setCleaningTrial(false);
              }}
            >
              {cleaningTrial ? (
                <>
                  {cleaningTrial}
                  <CircularProgress size={18} color="primary" style={{ marginLeft: 16 }} />
                </>
              ) : 'CLEAR DATA TEST'}
            </Button>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

CardEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dates: PropTypes.shape({
      start: PropTypes.object,
    }),
    address: PropTypes.shape({
      localName: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
    test: PropTypes.bool,
  }).isRequired,
};

export default CardEvent;
