import React, { useState, useEffect, useRef } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus, Trash2, ArrowLeft, ArrowRight, Save, ChevronDown } from 'react-feather';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function ContentsForm({ data, prev, next, save, onChange, isDraft }) {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [initialForm, setInitialForm] = useState(data);
  const [tag, setTag] = useState('');
  const [saved, setSaved] = useState(initialForm);

  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(saved);
    }, [1000]);
  }, [onChange, saved]);

  const schema = FormConfig({ saved, setSaved });

  const engagementAction = {
    new: (position) => () => {
      const newEngagement = [...saved.engagement];
      newEngagement.push({ when: '', what: '', why: '', how: '' });
      setSaved({ ...saved, engagement: newEngagement });
      setInitialForm({ ...saved, engagement: newEngagement });
    },
    delete: (id) => (e) => {
      if (e) e.stopPropagation();
      const newEngagement = [...saved.engagement];
      newEngagement.splice(id, 1);
      setSaved({ ...saved, engagement: newEngagement });
      setInitialForm({ ...saved, engagement: newEngagement });
    },
  };

  console.log('updated');
  
  return (
    <Grid container className={classes.container}>
      <Form
        id="contents"
        schema={schema}
        initialState={initialForm}
        onSubmit={next}
        onChange={setSaved}
        onKeyPress={(e) => {
          const code = e.keyCode || e.which;
          if (code === 13) e.preventDefault();
        }}
      >
        {(inputs) => (
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.description}>
                <p>#HYBRI TIP</p>
                Keep in mind that beyond the hundreds of events, your event also competes with e-mails, 
                social networks, WhatsApp, etc. Search and discover trending or relevant market topics and 
                needs in order to optimally engage your public.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.label}>
                WHAT ARE THE MOST IMPORTANT TOPICS TO MY AUDIENCE?
              </Typography>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && tag !== '') {
                    e.preventDefault();
                    e.stopPropagation();
                    const newThemes = [...saved.themes];
                    newThemes.push(tag);
                    setSaved({ ...saved, themes: newThemes });
                    setInitialForm({ ...saved, themes: newThemes });
                    setTag('');
                  }
                }}
              />
              <FormHelperText style={{ fontSize: 14 }}>
                Press enter to create a tag of interest
              </FormHelperText>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {saved.themes && saved.themes.map((item, i) => (
                <Chip
                  color="primary"
                  key={item}
                  label={item}
                  style={{ margin: '0 8px 8px 0' }}
                  onDelete={() => {
                    const newThemes = [...saved.themes];
                    newThemes.splice(i, 1);
                    setSaved({ ...saved, themes: newThemes });
                    setInitialForm({ ...saved, themes: newThemes });
                  }}
                />
              ))}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                How do you intend to present there topics?
              </Typography>
              {inputs.approach}
              {(saved.approach === 'speakers' || saved.approach === 'other') && (
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  <Typography className={classes.label}>
                    {saved.approach === 'speakers'
                      ? 'Who would be a great speaker?'
                      : 'How are you imagining?'}
                  </Typography>
                  {inputs.approachComplement}
                </Grid>
              )}
            </Grid>

            <Grid container alignItems="center" justify="space-between" wrao="nowrap" item xs={12}>
              <Typography className={classes.title} style={{ fontSize: 16 }}>
                How do you retain and engage your audience?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {inputs.engagement && saved.engagement.map((s, i) => inputs.engagement[i] && (
                <ExpansionPanel defaultExpanded className={classes.expand}>
                  <ExpansionPanelSummary expandIcon={<ChevronDown size={18} />} style={{ padding: '0 24px' }}>
                    <Grid item container spacing={1} justify="space-between" alignItems="center" wrap="nowrap">
                      <Grid item xs={4}>
                          {inputs.engagement[i].when}
                        </Grid>
                        <Grid item xs={8}>
                          {inputs.engagement[i].what}
                        </Grid>
                      <Trash2
                        size={16}
                        className={classes.btnRed}
                        onClick={engagementAction.delete(i)}
                      />
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {inputs.engagement[i].why}
                      </Grid>
                      <Grid item xs={12}>
                        {inputs.engagement[i].how}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                onClick={engagementAction.new('bottom')}
                style={{ marginTop: 8 }}
              >
                <Plus size={18} style={{ marginRight: 16 }} />
                Add action
              </Button>
            </Grid>

            <Grid container item justify="space-between" alignItems="center">
            {isDraft && (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={prev}
                >
                  <ArrowLeft size={20} />
                  <p>Back</p>
                </Button>
              )}
              <Tooltip title="Save progress">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={save}
                  style={!isDraft
                    ? {
                      position: 'fixed',
                      bottom: mobile ? 16 : 32,
                      left: mobile ? 12 : 'calc(50% + 450px)',
                    } : {}}
                >
                  <Save size={20} />
                </Button>
              </Tooltip>
              {isDraft && (
                <Button
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                >
                  <p>Next</p>
                  <ArrowRight size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
}

export default ContentsForm;
