import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogContent: {
    overflow: 'initial',
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  actions: {
    justifyContent: 'center',
  },
  colors: {
    marginTop: theme.spacing(),
    '& > div': {
      justifyContent: 'center',
      padding: 8,
    },
  },
}));
