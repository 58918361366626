import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';
import Product from 'store/supplier/product/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

function ProductForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { productId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '',
    category: '',
    valueForecast: 0,
    responsible: '',
    supplier: '',
  });

  const { products } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (productId && products) {
      const product = products.find((e) => e.id === productId);

      if (!product) history.goBack();

      setEdit(true);
      setInitialForm({
        name: product.name,
        category: product.category,
        valueForecast: product.valueForecast,
        responsible: product.responsible,
      });
    }
  }, [products, productId, history]);

  const submit = (data) => {
    if (edit) {
      dispatch(Log.store(`Changed the product ${data.name}`));
      dispatch(Product.update(productId, data));
    } else {
      dispatch(Log.store(`Created the product ${data.name}`));
      dispatch(Product.store(data));
    }
    history.goBack();
  };

  const closeForm = () => {
    dispatch(FormSave.remove('form-product'));
    history.goBack();
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={closeForm}
      classes={{ root: classes.dialog }}
      aria-labelledby="view-product-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>{edit ? `Changing ${initialForm.name}` : 'Create new item'}</span>
          <IconButton onClick={closeForm} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {products ? (
          <Form id="form-product" onSubmit={submit} schema={formSchema} initialState={initialForm}>
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {inputs.name}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {inputs.category}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {inputs.valueForecast}
                </Grid>
                <Grid item xs={12} sm={!productId ? 6 : 12}>
                  {inputs.responsible}
                </Grid>
                {!productId && (
                  <Grid item xs={12} sm={6}>
                    {inputs.supplier}
                  </Grid>
                )}
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!products} content={250} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-product"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductForm;
