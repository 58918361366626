import styled from 'styled-components';

export const Container = styled.div`
  position: ${(props) => (props.content ? 'relative' : 'absolute')};
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.content ? `${props.content}px` : '100%')};
  background-color: white;
  z-index: 5;
  opacity: ${(props) => (props.ready ? 0 : 1)};
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

export const ImageContainer = styled.div`
  position: relative;
  width: ${(props) => (props.content || 200)}px;
  height: ${(props) => (props.content || 200)}px;
  max-width: 200px;
  max-height: 200px;
`;

export const LoaderGif = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoaderLogo = styled.img`
  position: absolute;
  width: 25%;
  height: 25%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
