import { makeStyles, lighten, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  dialogWithoutBackground: {
    paddingRight: '0px !important',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  subtitle: {
    color: theme.palette.common.black,
    fontSize: 14,
    textTransform: 'uppercase',
    marginTop: theme.spacing(),
    fontWeight: 'bold',
  },
  accountMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: 35,
    lineHeight: 1.3,
    padding: '11px 24px 11px 14px',
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 12,
    },
  },
  label: {
    width: '100%',
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginLeft: theme.spacing(0.75),
  },
  text: {
    margin: `${theme.spacing(0.25)}px ${theme.spacing(0.75)}px`,
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: 'bold',
  },
  description: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.75)}px`,
  },
  accountBank: {
    fontSize: 12,
    color: theme.palette.common.grey,
  },
  add: {
    background: lighten(theme.palette.secondary.main, 0.9),
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.8),
    },
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    margin: 0,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },


  buttonInContained: {
    backgroundColor: theme.palette.primary.main,
    borderWidth: 1,
    color: 'white',
    padding: theme.spacing(0.5),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.1),
    },
  },
  buttonInOutlined: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderWidth: 1,
    padding: 7,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  buttonOutContained: {
    backgroundColor: theme.palette.secondary.main,
    borderWidth: 1,
    color: 'white',
    padding: theme.spacing(0.5),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.1),
    },
  },
  buttonOutOutlined: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    borderWidth: 1,
    padding: 7,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.9),
    },
  },
}));
