import React, { useState, useEffect, useRef } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus, Trash2, ArrowLeft, ArrowRight, ChevronDown, Save } from 'react-feather';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function ContentsForm({ data, prev, next, save, onChange, isDraft }) {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tag, setTag] = useState('');
  const [initialForm, setInitialForm] = useState(data);
  const [saved, setSaved] = useState(initialForm);

  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(saved);
    }, [500]);
  }, [onChange, saved]);

  const schema = FormConfig({ saved, setSaved });

  const personaAction = {
    new: (position) => () => {
      const newSaved = [...saved];
      const data = { name: '', description: '', interests: [], expected: '', engagement: '' };
      if (position === 'top') newSaved.unshift(data);
      if (position === 'bottom') newSaved.push(data);
      setSaved(newSaved);
      setInitialForm(newSaved);
    },
    delete: (id) => (e) => {
      if (e) e.stopPropagation();
      const newSaved = [...saved];
      newSaved.splice(id, 1);
      setSaved(newSaved);
      setInitialForm(newSaved);
    },
  };
  
  return (
    <Grid container className={classes.container}>
      <Form
        id="contents"
        schema={schema}
        initialState={initialForm}
        onSubmit={next}
        onChange={setSaved}
        onKeyPress={(e) => {
          const code = e.keyCode || e.which;
          if (code === 13) e.preventDefault();
        }}
      >
        {(inputs) => (
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.description}>
                <p>#HYBRI TIPS</p>
                It is extremely important to know your audience. After all, 
                for whom are the events organized? Don't worry, you got this!
              </Typography>
            </Grid>

            <Grid container alignItems="center" justify="space-between" wrap="nowrap" item xs={12}>
              <Typography className={classes.title}>
              Start by describing the personas, who is the ideal person who will represent your audience.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                onClick={personaAction.new('top')}
                style={{ marginBottom: 8 }}
              >
                <Plus size={18} style={{ marginRight: 16 }} />
                Add persona
              </Button>
              {inputs && saved.map((persona, i) => inputs[i] && (
                <ExpansionPanel defaultExpanded className={classes.expand}>
                  <ExpansionPanelSummary expandIcon={<ChevronDown size={18} />} style={{ padding: '0 24px' }}>
                    <Grid item container spacing={1} justify="space-between" alignItems="center" wrap="nowrap">
                      <Grid item xs={12}>{inputs[i].name}</Grid>
                      <Trash2
                        size={16}
                        className={classes.btnRed}
                        onClick={personaAction.delete(i)}
                      />
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} style={{ margin: '16px 0' }}>
                        <Typography className={classes.label}>
                        Describe them. Where do they live, what do they like, what do they do?
                        </Typography>
                        {inputs[i].description}
                      </Grid>
                      
                      <Grid item xs={12}>
                        <Typography className={classes.label}>
                          What are their interests?
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={tag}
                          onChange={(e) => setTag(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13 && tag !== '') {
                              e.preventDefault();
                              e.stopPropagation();
                              const newSaved = [...saved];
                              newSaved[i].interests.push(tag);
                              setSaved(newSaved);
                              setInitialForm(newSaved);
                              setTag('');
                            }
                          }}
                        />
                        <FormHelperText style={{ fontSize: 14 }}>
                          Press enter to create a tag of interest
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 16 }}>
                        {persona.interests && persona.interests.map((item, j) => (
                          <Chip
                            color="primary"
                            key={item}
                            label={item}
                            style={{ margin: '0 8px 8px 0' }}
                            onDelete={() => {
                              const newSaved = [...saved];
                              newSaved[i].interests.splice(j, 1);
                              setSaved(newSaved);
                              setInitialForm(newSaved);
                            }}
                          />
                        ))}
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Typography className={classes.label}>
                          What does she/he expect from an event like yours?
                        </Typography>
                        {inputs[i].expected}
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Typography className={classes.label}>
                          What are the best channels to communicate with this persona?
                        </Typography>
                        {inputs[i].engagement}
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Typography className={classes.label}>
                          What additional information or data do i need to detail this ideal attendee?
                        </Typography>
                        {inputs[i].data}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              {saved.length > 0 && (
                <Button
                  fullWidth
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={personaAction.new('bottom')}
                  style={{ marginTop: 8 }}
                >
                  <Plus size={18} style={{ marginRight: 16 }} />
                  Add persona
                </Button>
              )}
            </Grid>

            <Grid container item justify="space-between" alignItems="center">
              {isDraft && (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={prev}
                >
                  <ArrowLeft size={20} />
                  <p>Back</p>
                </Button>
              )}
              <Tooltip title="Salvar progresso">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={save}
                  style={!isDraft
                    ? {
                      position: 'fixed',
                      bottom: mobile ? 16 : 32,
                      left: mobile ? 12 : 'calc(50% + 450px)',
                    } : {}}
                >
                  <Save size={20} />
                </Button>
              </Tooltip>
              {isDraft && (
                <Button
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                >
                  <p>Next</p>
                  <ArrowRight size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
}

export default ContentsForm;
