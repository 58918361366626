export default {
  '01469655000176': {
    createdAt: new Date('2020-02-13 19:54:38'),
    document: '01.469.655/0001-76',
    test: true,
    updatedAt: new Date('2020-02-13 19:54:38'),
    types: [
      'Supplier',
    ],
    contact: {
      site: '',
      email: 'test@usevou.com',
      phone: '',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Printing of books, magazines and other periodicals',
      code: '18.11-3-02',
    },
    situation: 'ATIVA',
    companyName: 'GRAPHICS AND EDITOR GRAFNORTE LTDA',
    address: {
      number: '664',
      postalCode: '02.119-000',
      complement: '',
      // neighborhood: 'VILA MARIA',
      city: 'SAO PAULO',
      state: 'SP',
      country: 'Brasil',
      street: 'R MARGARINO TORRES',
    },
    person: 'legal',
    name: 'GRAPHICS AND EDITOR GRAFNORTE LTDA',
  },
  '02964380000100': {
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'johnrichard@johnrichard.com.br',
      phone: '(11) 5698-5300',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Rental of furniture, fixtures and appliances for domestic and personal use; musical instruments',
      code: '77.29-2-02',
    },
    situation: 'ATIVA',
    companyName: 'JOHN RICHARD LOCACAO DE MOVEIS LTDA.',
    address: {
      postalCode: '04.774-040',
      complement: '',
      // neighborhood: 'SOCORRO',
      city: 'SAO PAULO',
      state: 'SP',
      country: 'Brasil',
      street: 'RUA SION',
      number: '66',
    },
    person: 'legal',
    name: 'JOHN RICHARD ALUGUEL DE MOVEIS',
    createdAt: new Date('2020-02-13 19:56:25'),
    document: '02.964.380/0001-00',
    test: true,
    updatedAt: new Date('2020-02-13 19:56:25'),
  },
  '06111559000175': {
    test: true,
    updatedAt: new Date('2020-02-13 19:55:22'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'test@usevou.com',
      phone: '',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Manufacture of special fabrics, including artifacts',
      code: '13.54-5-00',
    },
    situation: 'ATIVA',
    companyName: 'FIGUEIRA TOLDOS E TENDAS LTDA',
    address: {
      number: '143',
      postalCode: '89.258-470',
      complement: '',
      // neighborhood: 'ILHA DA FIGUEIRA',
      city: 'JARAGUA DO SUL',
      state: 'SC',
      country: 'Brasil',
      street: 'RUA ANTONIO KOCHELLA',
    },
    person: 'legal',
    name: 'TOLDOS E TENDAS FIGUEIRA',
    createdAt: new Date('2020-02-13 19:55:22'),
    document: '06.111.559/0001-75',
  },
  '06990590000123': {
    mainActivity: {
      code: '63.19-4-00',
      description: 'Portals, content providers and other information services on the internet',
    },
    situation: 'ATIVA',
    transference: {
      code: '',
      agency: '',
      bank: '',
    },
    companyName: 'GOOGLE BRASIL INTERNET LTDA.',
    address: {
      complement: 'ANDAR 17 A 20 TORRE SUL ANDAR 2 TORRE NORTE ANDAR 18 A 20 TORRE NORTE',
      // neighborhood: 'ITAIM BIBI',
      city: 'SAO PAULO',
      state: 'SP',
      country: 'Brasil',
      street: 'AV BRIGADEIRO FARIA LIMA',
      number: '3477',
      postalCode: '04.538-133',
    },
    person: 'legal',
    name: 'GOOGLE BRASIL INTERNET LTDA.',
    document: '06.990.590/0001-23',
    createdAt: new Date('2020-02-13 20:15:05'),
    test: true,
    updatedAt: new Date('2020-02-13 20:15:05'),
    types: [
      'Patrocinador',
      'Parceiro',
    ],
    contact: {
      email: 'googlebrasil@google.com',
      phone: '(11) 2395-8400',
      site: '',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
  },
  '07434322000198': {
    createdAt: new Date('2020-02-13 19:48:44'),
    document: '07.434.322/0001-98',
    test: true,
    updatedAt: new Date('2020-02-13 19:48:44'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'legalizacao@avcontabilidade.com.br',
      phone: '(31) 3198-2800',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Development of custom computer softwares',
      code: '62.01-5-01',
    },
    situation: 'ATIVA',
    companyName: 'REDE ADOK DE TECNOLOGIA DA INFORMACAO LTDA',
    address: {
      postalCode: '31.270-190',
      complement: '',
      // neighborhood: 'LIBERDADE',
      city: 'BELO HORIZONTE',
      state: 'MG',
      country: 'Brasil',
      street: 'RUA VITAL BRASIL',
      number: '409',
    },
    person: 'legal',
    name: 'ADOK',
  },
  '07924834000132': {
    companyName: 'MONTADORA BRASILEIRA DE EVENTOS LTDA',
    address: {
      postalCode: '60.867-550',
      complement: '478-A',
      // neighborhood: 'BOA VISTA',
      city: 'FORTALEZA',
      state: 'CE',
      country: 'Brasil',
      street: 'RUA EDNO ALVES DE OLIVEIRA',
      number: '478',
    },
    person: 'legal',
    name: 'MONTADORA PAULISTA DE EVENTOS',
    createdAt: new Date('2020-02-13 19:12:29'),
    document: '07.924.834/0001-32',
    test: true,
    updatedAt: new Date('2020-02-13 19:12:29'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'comercial@montadorapaulista.com.br',
      phone: '(85) 3476-22598',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Rental of stages, covers and other temporary use structures, except scaffolding',
      code: '77.39-0-03',
    },
    situation: 'ATIVA',
  },
  15265388000120: {
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'test@usevou.com',
      phone: '(47) 3368-0807 ',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Advertising space agency, except in communication vehicles',
      code: '73.12-2-00',
    },
    situation: 'ATIVA',
    companyName: 'MT PAINEIS E OUTDOORS LTDA',
    address: {
      number: '176',
      postalCode: '88.220-000',
      complement: 'APT 502',
      // neighborhood: 'MEIA PRAIA',
      city: 'ITAPEMA',
      state: 'SC',
      country: 'Brasil',
      street: 'RUA 291',
    },
    person: 'legal',
    name: 'MT PAINEIS E OUTDOORS LTDA',
    createdAt: new Date('2020-02-13 19:30:01'),
    document: '15.265.388/0001-20',
    test: true,
    updatedAt: new Date('2020-02-13 19:30:01'),
  },
  17933937000201: {
    address: {
      postalCode: '89.010-080',
      complement: ': SUBSOLO;',
      // neighborhood: 'CENTRO',
      city: 'BLUMENAU',
      state: 'SC',
      country: 'Brasil',
      street: 'RUA PADRE JACOBS',
      number: '15',
    },
    person: 'legal',
    name: 'STANNIS PUB',
    createdAt: new Date('2020-02-13 19:57:36'),
    document: '17.933.937/0002-01',
    test: true,
    updatedAt: new Date('2020-02-13 19:57:36'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'gerente@stannis.com',
      phone: '(47) 3054-4467',
    },
    legalNature: '230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    mainActivity: {
      description: '********',
      code: '00.00-0-00',
    },
    situation: 'BAIXADA',
    companyName: 'STANNIS PUB EIRELI',
  },
  20431153000109: {
    mainActivity: {
      description: 'Film post-production activities, videos and television programs not otherwise specified',
      code: '59.12-0-99',
    },
    situation: 'ATIVA',
    companyName: 'AGENCIA FIRMA COMUNICACAO LTDA',
    address: {
      postalCode: '02.730-000',
      complement: 'APT: 43 A;',
      // neighborhood: 'VILA ALBERTINA',
      city: 'SAO PAULO',
      state: 'SP',
      country: 'Brasil',
      street: 'R ANTONIO PIRES',
      number: '810',
    },
    person: 'legal',
    name: 'AGENCIA FIRMA COMUNICACAO LTDA',
    createdAt: new Date('2020-02-13 19:53:36'),
    document: '20.431.153/0001-09',
    test: true,
    updatedAt: new Date('2020-02-13 19:53:36'),
    types: [
      'Fornecedor',
    ],
    contact: {
      email: 'vitor.curi@gmail.com',
      phone: '(11) 4304-5977',
      site: '',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
  },
  22143068000162: {
    test: true,
    updatedAt: new Date('2020-02-13 20:12:23'),
    types: [
      'Patrocinador',
    ],
    contact: {
      email: 'test@usevou.com',
      phone: '(46) 3523-0221',
      site: '',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Wholesale of electrical equipment of personal and domestic use',
      code: '46.49-4-01',
    },
    situation: 'ATIVA',
    transference: {
      code: '',
      agency: '',
      bank: '',
    },
    companyName: 'SOL BRASIL EMPREENDIMENTOS ENERGETICOS LTDA',
    address: {
      postalCode: '85.601-000',
      complement: 'ANDAR 1 SALA 01',
      // neighborhood: 'CENTRO',
      city: 'FRANCISCO BELTRAO',
      state: 'PR',
      country: 'Brasil',
      street: 'AVENIDA JULIO ASSIS CAVALHEIRO',
      number: '1054',
    },
    person: 'legal',
    name: 'SOL BRASIL EMPREENDIMENTOS ENERGETICOS LTDA',
    document: '22.143.068/0001-62',
    createdAt: new Date('2020-02-13 20:12:23'),
  },
  23596459000103: {
    address: {
      postalCode: '38.400-106',
      complement: '',
      // neighborhood: 'CENTRO',
      city: 'UBERLANDIA',
      state: 'MG',
      country: 'Brasil',
      street: 'AVENIDA CIPRIANO DEL FAVERO',
      number: '640',
    },
    person: 'legal',
    name: 'CIPRIANNE',
    createdAt: new Date('2020-02-13 19:06:28'),
    document: '23.596.459/0001-03',
    test: true,
    updatedAt: new Date('2020-02-13 19:06:28'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'contabilidade@confidencialcontabil.com.br',
      phone: '(34) 3211-30003',
    },
    legalNature: '206-2 - Sociedade Empresária Limitada',
    mainActivity: {
      description: 'Restaurants and similar',
      code: '56.11-2-01',
    },
    situation: 'ATIVA',
    companyName: 'CIPRIANNE ALIMENTOS LTDA',
  },
  28301990000117: {
    mainActivity: {
      code: '73.19-0-03',
      description: 'Direct marketing',
    },
    situation: 'ATIVA',
    companyName: 'JEFFERSON MARIO WINTER AMENDOLARA',
    address: {
      state: 'SC',
      country: 'Brasil',
      street: 'RUA FELICIANO BORTOLINI',
      number: '1640',
      postalCode: '89.260-180',
      complement: 'ANDAR 1 SALA 7 BOX 23',
      // neighborhood: 'BARRA DO RIO CERRO',
      city: 'JARAGUA DO SUL',
    },
    person: 'legal',
    name: 'VOU EVENTOS E DESCONTOS',
    createdAt: new Date('2020-02-13 19:41:00'),
    document: '28.301.990/0001-17',
    test: true,
    updatedAt: new Date('2020-02-13 19:41:00'),
    types: [
      'Fornecedor',
    ],
    contact: {
      site: '',
      email: 'jefferson@usevou.com',
      phone: '(47) 9612-53134',
    },
    legalNature: '213-5 - Empresário (Individual)',
  },
  84429695000111: {
    document: '84.429.695/0001-11',
    createdAt: new Date('2020-02-13 20:08:29'),
    test: true,
    updatedAt: new Date('2020-02-13 20:08:29'),
    types: [
      'Patrocinador',
      'Parceiro',
    ],
    contact: {
      site: '',
      email: 'contabil1@weg.net',
      phone: '(47) 3276-40004',
    },
    legalNature: '204-6 - Sociedade Anônima Aberta',
    mainActivity: {
      description: 'Holdings of non-financial institutions',
      code: '64.62-0-00',
    },
    situation: 'ATIVA',
    transference: {
      bank: '',
      code: '',
      agency: '',
    },
    companyName: 'WEG SA',
    address: {
      postalCode: '89.256-900',
      complement: '',
      // neighborhood: 'VILA LALAU',
      city: 'JARAGUA DO SUL',
      state: 'SC',
      country: 'Brasil',
      street: 'AVENIDA PREFEITO WALDEMAR GRUBBA',
      number: '3300',
    },
    person: 'legal',
    name: 'WEG',
  },
};
