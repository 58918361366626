import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

function RadioInput({
  value, options, onChange, helperText, error, direction,
}) {
  return (
    <>
      <RadioGroup
        row={direction === 'row'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((opt) => (
          <FormControlLabel
            key={opt.value}
            value={opt.value}
            control={<Radio color="primary" />}
            label={opt.label}
            style={{ marginRight: 32 }}
          />
        ))}
      </RadioGroup>
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

RadioInput.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  direction: PropTypes.string,
};

RadioInput.defaultProps = {
  helperText: '',
  direction: 'row',
  error: false,
};

export default RadioInput;
