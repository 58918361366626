import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Log from 'store/log/controller';

// Icons, Assets, Commons and Styles
import { X } from 'react-feather';
import { transferFiles } from 'common/form/upload/utils';
import { numberToReal } from 'common/utils/numbers';
import Form from 'common/form';
import Loading from 'common/loading';
import styles from './style';
import FormConfig from './form-config';

function CashFlowPayForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, cashId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { accounts, cashFlow } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [value, setValue] = useState(0);
  const [description, setDescription] = useState('');
  const [dueAt, setDueAt] = useState(0);
  const [submited, setSubmited] = useState(false);

  const [initialForm, setInitialForm] = useState({
    account: '',
    method: '',
    completed: {
      on: true,
      value: 0,
      date: new Date(),
      receipt: [],
    },
  });

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;
  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (cashFlow) {
      const cash = cashFlow.find((c) => c.id === cashId);

      if (cash) {
        const cashDueAt = cash.dueAt.toDate();

        setDescription(cash.description);
        setDueAt(cashDueAt);
        setValue(cash.value);
        setInitialForm({
          ...cash,
          completed: {
            ...cash.completed,
            value: cash.value,
            date: new Date(),
          },
        });
      } else {
        history.goBack();
      }
    } else {
      dispatch(CashFlow.index(eventId));
    }
  }, [cashId, cashFlow, history, dispatch, eventId]);

  useEffect(() => {
    if (!accounts) dispatch(Account.index(eventId));
  }, [accounts, dispatch, eventId]);

  const submit = async (data) => {
    setSubmited(true);
    let receipt = [];

    if (data.completed.receipt) {
      receipt = await transferFiles(
        data.completed.receipt,
        `/financial-temp/${eventId}/cash-flow/receipts`,
        `/financial/${eventId}/cash-flow/receipts`,
      );
    }

    dispatch(Log.store(`Received the value of ${numberToReal(initialForm.value)}`));
    dispatch(CashFlow.update(cashId, {
      account: data.account,
      method: data.method,
      completed: {
        ...data.completed,
        on: true,
        receipt,
      },
    }));
    setSubmited(false);
    history.goBack();
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span style={{ marginRight: 16 }}>
          Receiving bills
          </span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {accounts && cashFlow ? (
          <Form
            id={`form-pay-${cashId}`}
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>Description</Typography>
                  <Typography className={classes.description}>
                    {description}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className={classes.label}>Value</Typography>
                  <Typography className={classes.text}>
                    {numberToReal(value)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.label}>Due date</Typography>
                  <Typography className={classes.text}>
                    {dueAt
                      ? format(dueAt, 'dd MMM yyyy', { locale: ptBR })
                      : 'Not defined'}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {inputs.account}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {inputs.method}
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>RECEIVEMENT</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>{inputs.completed.value}</Grid>
                <Grid item xs={12} sm={6}>{inputs.completed.date}</Grid>
                <Grid item xs={12}>{inputs.completed.receipt}</Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!accounts && !!cashFlow} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form={`form-pay-${cashId}`}
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={submited}
        >
          Confirm
          {submited && (
            <CircularProgress size={16} color="primary" style={{ marginLeft: 8 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CashFlowPayForm;
