import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { storage } from 'services/firebase';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';

function UploadButton({
  children, channel, onStart, onSuccess, onChange, className, setRef,
}) {
  const dispatch = useDispatch();

  const { current: company } = useSelector((store) => store.company);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={className}>
      {children}
      <FileUploader
        hidden
        multiple
        randomizeFilename
        accept="images/*"
        ref={setRef}
        storageRef={storage.ref(`/chat/${company.id}/${channel}`)}
        onUploadStart={onStart}
        onUploadSuccess={onSuccess}
        onChange={(e) => {
          const { target: { files } } = e;
          const filesToStore = [];

          for (let i = 0; i < files.length; i += 1) {
            filesToStore.push(files[i]);
          }

          onChange(filesToStore);
        }}
        onUploadError={() => {
          dispatch(Alert.show({ message: 'Attachments could not be sent.' }));
        }}
      />
    </label>
  );
}

UploadButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  channel: PropTypes.string,
  onStart: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  className: PropTypes.string,
};

UploadButton.defaultProps = {
  className: '',
  channel: '',
};

export default UploadButton;
