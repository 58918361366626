// Main Components
import Sponsors from 'app/manager/sponsors';
import Negotiations from 'app/manager/sponsors/negotiations';

// Routes Components
import PrivateRoute from 'routes/components/PrivateRoute';

export default [
  {
    path: '/manager/:eventId/sponsors',
    component: Sponsors,
    routerComponent: PrivateRoute,
    exact: true,
  },
  {
    path: '/manager/:eventId/sponsors/negotiations',
    component: Negotiations,
    routerComponent: PrivateRoute,
    exact: true,
  },
];
