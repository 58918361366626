import React, { useState, useEffect, useRef } from 'react';

// Material UI Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { ArrowLeft, ArrowRight, Save } from 'react-feather';
import Form from 'common/form';
import Tooltip from 'common/tooltip';
import FormConfig from './form-config';
import styles from './style';

function FormatForm({ data, prev, next, save, onChange, isDraft }) {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [initialForm, setInitialForm] = useState(data);
  const [saved, setSaved] = useState(initialForm);

  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(saved);
    }, [500]);
  }, [onChange, saved]);

  const schema = FormConfig();
  
  return (
    <Grid container className={classes.container}>
      <Form
        id="contents"
        schema={schema}
        initialState={initialForm}
        onSubmit={next}
        onChange={setSaved}
        onKeyPress={(e) => {
          const code = e.keyCode || e.which;
          if (code === 13) e.preventDefault();
        }}
      >
        {(inputs) => (
          <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
              <Typography component="div" className={classes.description}>
                <p>#HYBRI TIP</p>
                Proper event format is essential to deliver an excellent online experience. 
                Several free streaming tools are available to assist you. To choose the most applicable tool, 
                check all necessary resources, number of participants, and permissions required for each platform.
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                Which format will the event have?
              </Typography>
              {inputs.type}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                How many attendees do you plan to have?
              </Typography>
              {inputs.subscribers}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Typography className={classes.label}>
                Which platform will you use?
              </Typography>
              {inputs.streaming}
            </Grid>

            <Grid
              container
              item
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.label} style={{ fontSize: 16 }}>
                The tickets will be
              </Typography>
              <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                <Button
                  variant={saved.ticket === 'payed' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSaved({ ...saved, ticket: 'payed' });
                    setInitialForm({ ...saved, ticket: 'payed' });
                  }}
                >
                  Paid
                </Button>
                <Button
                  variant={saved.ticket === 'free' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSaved({ ...saved, ticket: 'free' });
                    setInitialForm({ ...saved, ticket: 'free' });
                  }}
                >
                  Free
                </Button>
                <Button
                  variant={saved.ticket === 'both' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSaved({ ...saved, ticket: 'both' });
                    setInitialForm({ ...saved, ticket: 'both' });
                  }}
                >
                  Both
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid
              container
              item
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.label} style={{ fontSize: 16 }}>
                The event will be
              </Typography>
              <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                <Button
                  variant={saved.future === 'unique' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSaved({ ...saved, future: 'unique' });
                    setInitialForm({ ...saved, future: 'unique' });
                  }}
                >
                  One time only
                </Button>
                <Button
                  variant={saved.future === 'repeat' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSaved({ ...saved, future: 'repeat' });
                    setInitialForm({ ...saved, future: 'repeat' });
                  }}
                >
                  Recurring
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid container item justify="space-between" alignItems="center">
            {isDraft && (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                className={classes.btnController}
                onClick={prev}
              >
                <ArrowLeft size={20} />
                <p>Back</p>
              </Button>
              )}
              <Tooltip title="Save progress">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                  onClick={save}
                  style={!isDraft
                    ? {
                      position: 'fixed',
                      bottom: mobile ? 16 : 32,
                      left: mobile ? 12 : 'calc(50% + 450px)',
                    } : {}}
                >
                  <Save size={20} />
                </Button>
              </Tooltip>
              {isDraft && (
                <Button
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnController}
                >
                  <p>Next</p>
                  <ArrowRight size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Form>
    </Grid>
  );
}

export default FormatForm;
