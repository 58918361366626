/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Commons, Components and styles
import PieChart from 'common/charts/pie-chart';

function RevenueChart({ scenary }) {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [data, setData] = useState([]);

  function hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r; let g; let
      b;
    if (s === 0) {
      // eslint-disable-next-line no-multi-assign
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  useEffect(() => {
    if (scenary) {
      const newData = [];
      const colorRef = Math.round(50 / scenary.revenue.length);

      const ordenedRevenue = scenary.revenue.sort((a, b) => b.total - a.total);

      for (let i = 0; i < ordenedRevenue.length; i += 1) {
        const item = ordenedRevenue[i];

        newData.push({
          id: item.label,
          name: item.label,
          value: item.total,
          color: hslToHex(121, 100 - colorRef * i, 25 + colorRef * i),
        });
      }

      setData(newData);
    }
  }, [scenary]);

  if (data.length === 0) return false;

  return (
    <PieChart
      data={data}
      direction={upSm ? 'right' : 'center'}
      title="REVENUES"
      loaded={!!scenary}
    />
  );
}

RevenueChart.propTypes = {
  scenary: PropTypes.shape({
    revenue: PropTypes.array,
  }),
};

RevenueChart.defaultProps = {
  scenary: null,
};

export default RevenueChart;
