import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import AuthComponent from 'app/auth';

// Material Components
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';

// Redux Actions
import Auth from 'store/auth/controller';

// Icons, Commons, Assets and Styles
import { CheckCircle, AlertCircle } from 'react-feather';
import styles from './style';

function SnackbarInfo({ classes, type, message }) {
  const Icon = type === 'success' ? CheckCircle : AlertCircle;
  return (
    <SnackbarContent
      className={classes[type]}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.messageSnackbar}>
          <Icon size={20} className={classes.icon} />
          {message || 'We have sent an email to reset your password.'}
        </span>
)}
    />
  );
}

SnackbarInfo.propTypes = {
  classes: PropTypes.shape({
    messageSnackbar: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
};

SnackbarInfo.defaultProps = {
  message: null,
};

function ResetPassword() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const { sentResetPassword: status } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [textSubmit, setTextSubmit] = useState('Forgot password');

  useEffect(() => {
    if (disableSubmit && status) {
      setDisableSubmit(false);
      setTextSubmit('Forgot password');
    }
  }, [status, disableSubmit]);

  const resetPassword = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    setTextSubmit('Sending e-mail');

    dispatch(Auth.resetPassword({ email }));
  };

  return (
    <AuthComponent>
      <Grid container direction="row" justify="center" alignItems="center">
        {status && (
          <SnackbarInfo
            classes={classes}
            type={status !== true ? 'error' : 'success'}
            message={status !== true ? status : null}
          />
        )}

        <form style={{ width: '100%' }} onSubmit={resetPassword}>
          <TextField
            type="email"
            id="email"
            label="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          <Grid container alignItems="center" justify="center">
            <Fab
              type="submit"
              color="primary"
              variant="extended"
              size="large"
              className={classes.button}
              disabled={disableSubmit}
            >
              {textSubmit}
            </Fab>
          </Grid>
        </form>

        <Grid container alignItems="center" justify="center">
          <Button
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </AuthComponent>
  );
}

export default ResetPassword;
