import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Material components
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Styles
import { formatRelativeDate } from 'common/utils/dates';
import { Send } from 'react-feather';
import Supplier from 'store/supplier/controller';
import { Timestamp } from 'services/firebase';
import styles from './style';

function SupplierRegisterChat({
  supplier, chat, open, anchorEl,
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const [message, setMessage] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (content) content.scrollTop = content.scrollHeight;
  }, [content, chat]);

  const send = () => {
    if (message !== '') {
      const newChat = [...chat];
      newChat.push({
        message,
        author: 'supplier',
        sendAt: Timestamp.fromDate(new Date()),
        viewed: false,
      });
      dispatch(Supplier.sendRegisterChat(eventId, supplier, newChat));
      setMessage('');
    }
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl || null}
      placement="top-end"
      transition
      className={classes.chat}
    >
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={5}>
            <Grid container>
              <Grid item xs={12} className={classes.chatHeader}>
                <Typography variant="h6">Contracting company</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.chatContent} ref={setContent}>
              {chat.map((msg) => (
                <Grid
                  container
                  justify={msg.author === 'supplier' ? 'flex-end' : 'flex-start'}
                  key={msg.sendAt.toDate()}
                >
                  <Grid
                    item
                    className={`${classes.chatMessage} ${
                      msg.author === 'supplier'
                        ? classes.chatMessageSend
                        : classes.chatMessageReceive
                    }`}
                  >
                    {msg.message}
                    <p>{formatRelativeDate(msg.sendAt.toDate(), new Date())}</p>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {chat.length === 0 && (
              <Grid container justify="center">
                <Typography variant="overline">Start a conversation</Typography>
              </Grid>
            )}

            <Grid item xs={12} className={classes.chatSender}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    e.preventDefault();
                    send();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton color="primary" onClick={send}>
                      <Send />
                    </IconButton>
                  ),
                  style: { padding: 16 },
                }}
              />
            </Grid>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

SupplierRegisterChat.propTypes = {
  supplier: PropTypes.string.isRequired,
  chat: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default SupplierRegisterChat;
