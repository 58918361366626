import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(),
    bottom: theme.spacing(),
    zIndex: 1000,
  },
  badge: {
    right: -8,
    top: -8,
  },
  sidebar: {
    height: '100vh',
    width: 'fit-content',
    background: lighten(theme.palette.secondary.main, 0.9),
    zIndex: 4,
  },
  channel: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  },
  add: {
    margin: `20px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: theme.spacing(),
      right: theme.spacing(),
      width: 56,
      height: 56,
    },
  },
  toolbar: {
    width: '100vw',
    maxWidth: 450,
    padding: `${theme.spacing()}px ${theme.spacing(1.5)}px`,
    boxShadow: '0px 6px 24px 12px #fff',
    zIndex: 3,
  },
  content: {
    width: '100vw',
    maxWidth: 450,
    paddingTop: theme.spacing(),
    overflow: 'overlay',
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  avatar: {
    background: theme.palette.primary.main,
  },
  chatName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  message: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
    color: theme.palette.common.grey,
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(0.5),
  },
  chip: {
    height: 24,
    minWidth: 24,
    marginLeft: 4,
    '& > span': {
      fontSize: 12,
      padding: '0 8px',
    },
  },
  date: {
    textTransform: 'uppercase',
    color: theme.palette.common.grey,
    fontSize: 12,
    whiteSpace: 'nowrap',
    marginLeft: 4,
  },
}));
