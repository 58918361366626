import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import useCategoryController from 'store/category/controller';
import CostCenter from 'store/financial/cost-center/controller';
import Contact from 'store/contact/controller';

// Icons, Commons and Styles
import { ChevronDown, Bookmark, Users } from 'react-feather';
import { Bank, CostCenter as CostCenterIcon } from 'assets/custom-icons';
import Sidebar from 'common/sidebar/home';
import Tools from 'app/tools';
import styles from './style';

// Components
import AccountList from './accounts';
import CategoryList from './categories';
import CostCenterList from './cost-center';
import ContactsList from './contacts';

function Settings() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const tab = params.get('tab');

  const theme = useTheme();
  const expansion = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    current: company, accounts, categories, cashFlow, costCenter, contacts,
  } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  const menu = useRef(null);
  const container = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(tab || 'categories');

  const permissionPlan = company.plan !== 'free' || company.plan !== 'planner';
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.view;
  const permissionProcurement = !permissions || !permissions.manager || permissions.manager.procurement.view;
  const permissionView = (permissionFinancial || permissionProcurement) && permissionPlan;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Load data to use in this module
  useEffect(() => {
    if (!isLoading) {
      if (!accounts) dispatch(Account.index());
      if (!categories) Category.index();
      if (!cashFlow) dispatch(CashFlow.index());
      if (!costCenter) dispatch(CostCenter.index());
      if (!contacts) dispatch(Contact.index());
      setIsLoading(true);
    }
  }, [Category, accounts, cashFlow, categories, contacts, costCenter, dispatch, isLoading]);

  useEffect(() => {
    if (expansion) setExpanded(null);
  }, [expansion]);

  const handleExpanded = (id) => {
    if (expanded === id) setExpanded(null);
    else setExpanded(id);
  };

  const subMenu = [];

  if (permissionView) {
    subMenu.push({ id: 'categories', label: 'Categories', Icon: Bookmark, list: CategoryList });
  }
  if (permissionProcurement || permissionFinancial) {
    subMenu.push({ id: 'contacts', label: 'Contacts', Icon: Users, list: ContactsList });
  }
  if (permissionFinancial) {
    subMenu.push(
      { id: 'accounts', label: 'Accounts', Icon: Bank, list: AccountList },
      { id: 'costCenter', label: 'Cost Center', Icon: CostCenterIcon, list: CostCenterList },
    );
  }

  let ExpandedList = null;

  if (expanded) {
    const item = subMenu.find((i) => i.id === expanded);
    if (item) ExpandedList = <item.list />;
  }

  return (
    <Grid container spacing={0}>
      <Sidebar actived="/register" />
      <Grid item xs={12} sm={8} md={9} lg={10} className={classes.container} ref={container}>
        <Grid
          container
          justify={expansion ? 'center' : 'space-between'}
          alignItems="center"
          style={{ position: expansion ? 'inherit' : 'fixed' }}
        >
          <Typography variant="h3" className={classes.title}>
            REGISTER
          </Typography>
        </Grid>

        <Grid container className={classes.content} style={{ marginTop: expansion ? 16 : 72 }}>
          {expansion ? subMenu.map((item) => (
            <Grid key={item.id} item xs={12} style={{ marginBottom: 16 }}>
              <ExpansionPanel
                expanded={expanded === item.id || !expansion}
                onChange={() => handleExpanded(item.id)}
              >
                <ExpansionPanelSummary
                  expandIcon={<ChevronDown />}
                  aria-controls={`${item.id}-content`}
                  id={`${item.id}-header`}
                  classes={{ expanded: classes.expanded }}
                >
                  <Typography className={classes.titleExpansion}>
                    {item.label}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <item.list />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          )) : (
            <>
              <Grid item md={4} ref={menu}>
                <ul
                  className={classes.submenu}
                  style={{
                    position: 'fixed',
                    width: 'calc((83.333333% - 96px) * 0.333333)',
                  }}
                >
                  {subMenu.map((item) => (
                    <button
                      type="button"
                      key={item.id}
                      className={`${classes.submenuItem} ${
                        expanded === item.id && classes.itemActived
                      }`}
                      onClick={() => setExpanded(item.id)}
                    >
                      <item.Icon
                        color={expanded === item.id ? '#fff' : '#4eb1d4'}
                        className={`${classes.itemIcon} ${
                          expanded === item.id && classes.itemIconActived
                        }`}
                      />
                      {item.label}
                    </button>
                  ))}
                </ul>
              </Grid>
              <Grid item md={8}>
                {ExpandedList && (
                  <Card className={classes.card}>
                    <CardContent>
                      {ExpandedList}
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </>
          )}
        </Grid>

        <Tools />
      </Grid>
    </Grid>
  );
}

export default Settings;
