import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
}));
