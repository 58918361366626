import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  dialogContent: {
    padding: theme.spacing(1.5),
  },
  taskName: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.palette.common.black,
    cursor: 'pointer',
    lineHeight: 1.3,
    '& input': {
      fontSize: 34,
    },
  },
  label: {
    fontSize: 10,
    lineHeight: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.tertiary.main,
    marginBottom: 8,
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  btnGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      background: lighten(theme.palette.success.main, 0.9),
      borderColor: theme.palette.success.main,
    },
  },
  btnRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
      borderColor: theme.palette.error.main,
    },
  },
}));
