import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import {
  Image, Download, Folder, File,
} from 'react-feather';
import styles from '../style';

function RegisterDocuments({ files }) {
  const classes = styles();

  const extensionIcon = (file) => {
    const icons = [Image, Folder, File];
    const extensions = [
      'jpg,jpeg,gif,raw,png,tiff,tif,svg',
      'zip,rar,tar',
      'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
    ];

    if (file.fileName) {
      const extension = file.fileName.split('.').reverse()[0];

      for (let i = 0; i < extensions.length; i += 1) {
        if (extensions[i].indexOf(extension) >= 0) return icons[i];
      }
    }

    return icons[2];
  };

  return (
    <>
      <Grid item xs={12} className={classes.dividerText}>
        <Typography gutterBottom variant="overline">
          DOCUMENTS
        </Typography>
        <Divider variant="middle" className={classes.divider} />
      </Grid>
      <Grid container spacing={1}>
        {files.map((file) => {
          const Icon = extensionIcon(file);
          return (
            <Grid item xs={12} sm={12} key={file.document}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.document}
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon size={20} style={{ marginRight: 8 }} />
                  <Typography className={classes.documentName}>{file.label}</Typography>
                </Grid>
                <Grid item>
                  <a target="_blank" rel="noopener noreferrer" href={file.url}>
                    <Button color="primary">
                      <Download size={20} />
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

RegisterDocuments.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RegisterDocuments;
