import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import Step from 'store/productivity/step/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Trash2, Edit2, Plus, CheckCircle, Archive, Edit, Folder, Search, ChevronUp, ChevronDown,
} from 'react-feather';
import Loading from 'common/loading';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function Settings() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams();

  const { steps, tasks } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [destroy, setDestroy] = useState(null);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  const StepIcon = (type) => {
    const icons = {
      done: <CheckCircle size={18} style={{ color: '#777' }} />,
      archive: <Archive size={18} style={{ color: '#777' }} />,
      working: <Edit size={18} style={{ color: '#777' }} />,
      backlog: <Folder size={18} style={{ color: '#777' }} />,
      validate: <Search size={18} style={{ color: '#777' }} />,
    };

    return icons[type];
  };

  const prevOrder = (id) => {
    const item = steps.find((c) => c.id === id);
    const prev = steps.find((c) => c.order === item.order - 1);

    dispatch(Log.store('Changed the order of the steps'));
    dispatch(Step.update(item.id, { order: item.order - 1 }));
    dispatch(Step.update(prev.id, { order: prev.order + 1 }));
  };

  const nextOrder = (id) => {
    const item = steps.find((c) => c.id === id);
    const prev = steps.find((c) => c.order === item.order + 1);

    dispatch(Log.store('Changed the order of the steps'));
    dispatch(Step.update(item.id, { order: item.order + 1 }));
    dispatch(Step.update(prev.id, { order: prev.order - 1 }));
  };

  const baseUrl = (url) => {
    let base = `/planner/${eventId}/productivity`;
    if (history.location.pathname.includes('kanban')) base += '/kanban';
    return base + url;
  };

  return (
    <Grid container style={{ marginTop: 32 }}>
      <Grid container justify="space-between" alignItems="center">
        <Typography className={classes.title}>STEPS</Typography>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.btnAdd}
          onClick={() => history.push(baseUrl('/steps/new'))}
        >
          <Plus size={18} />
          <p>Add</p>
        </Button>
      </Grid>
      <Grid container>
        {steps && tasks ? (
          <List style={{ width: '100%' }}>
            {steps.map((step, i) => (
              <ListItem key={step.id}>
                <ListItemIcon style={{ minWidth: 58, marginRight: 16, alignItems: 'center' }}>
                  <Grid
                    container
                    direction="column"
                    style={{ width: 'fit-content', marginRight: 16 }}
                  >
                    <Button
                      color="secondary"
                      className={classes.btnOrder}
                      onClick={() => prevOrder(step.id)}
                      disabled={i === 0}
                    >
                      <ChevronUp size={16} />
                    </Button>
                    <Button
                      color="secondary"
                      className={classes.btnOrder}
                      onClick={() => nextOrder(step.id)}
                      disabled={i === steps.length - 1}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </Grid>
                  {StepIcon(step.type)}
                </ListItemIcon>
                <ListItemText primary={step.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    color="primary"
                    onClick={() => history.push(baseUrl(`/steps/${step.id}`))}
                  >
                    <Edit2 size={16} />
                  </IconButton>
                  {!tasks.find((t) => t.step === step.id) && !step.default && (
                    <IconButton color="primary" onClick={() => setDestroy(step)}>
                      <Trash2 size={16} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Loading ready={!!steps && !!tasks} content={150} />
        )}

        {destroy && (
          <ConfirmDialog
            title={`Do you really want to delete this step ${destroy.name}?`}
            onConfirm={() => {
              dispatch(Log.store(`Deleted step ${destroy.name}`));
              steps.filter((s) => s.order > destroy.order).forEach((s) => {
                dispatch(Step.update(s.id, { order: s.order - 1 }));
              });
              dispatch(Step.destroy(destroy.id));
            }}
            onClose={() => setDestroy(null)}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Settings;
