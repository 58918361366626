import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Icons, Components and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import styles from './style';

function CustomPeriod({ customDate, onSubmit, onClose }) {
  const classes = styles();

  const [initialForm, setInitialForm] = useState({ start: null, end: null });
  const [dateMin, setDateMin] = useState(customDate.start || null);

  useEffect(() => {
    setInitialForm(customDate);
  }, [customDate]);

  const formSchema = [
    {
      name: 'start',
      type: 'datetime',
      keyboard: true,
      label: 'From',
      onChange: (value, state) => {
        setDateMin(value);
        return state;
      },
    },
    {
      name: 'end',
      type: 'datetime',
      keyboard: true,
      label: 'To',
      pickerProps: { minDate: dateMin },
      validation: dateMin && {
        minDate: {
          msg: 'The date must be greater than the start date',
          params: { min: 'start' },
        },
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      open
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="view-product-category-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Custom period</span>
          <IconButton onClick={onClose} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form id="form-custom-period" onSubmit={onSubmit} initialState={initialForm} schema={formSchema}>
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>{inputs.start}</Grid>
              <Grid item xs={12} sm={6}>{inputs.end}</Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-custom-period"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Apply period
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomPeriod.propTypes = {
  customDate: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomPeriod;
