import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expansionContainer: {
    boxShadow: 'none',
    marginTop: theme.spacing(),
    '&:before': {
      content: 'none',
    },
  },
  expansionDisable: {
    background: 'white',
  },
  expansionSummary: {
    padding: 0,
  },
  expansionDetails: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px ${theme.spacing()}px`,
  },
  expansionTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.tertiary.main,
    fontWeight: 600,
  },
  editButton: {
    borderRadius: 30,
    padding: 8,
    minWidth: 42,
    transition: 'all 0.2s ease-out',
    '& p': {
      maxWidth: 0,
      overflow: 'hidden',
      transition: 'all 0.2s ease-out',
    },
    '&:hover': {
      padding: '8px 16px',
      '& p': {
        maxWidth: 100,
        marginLeft: 8,
      },
    },
  },
}));
