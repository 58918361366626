import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing()}px ${theme.spacing()}px`,
      maxHeight: 'inherit',
      height: 'auto',
    },
  },
  title: {
    fontWeight: 300,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      marginBottom: theme.spacing(0.5),
    },
  },
  button: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(16),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
}));
