/* eslint-disable no-await-in-loop */
import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS, functions } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Event } from 'store/event/reducer';
import { Actions as Company } from 'store/company/reducer';
import { Actions } from './reducer';

const Supplier = {
  indexEvent: (eventId) => (dispatch) => {
    firestore.collection('events').doc(eventId).collection('suppliers')
      .onSnapshot((ref) => {
        const suppliers = ref.docs.map((s) => ({ id: s.id, ...s.data() }));
        suppliers.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(Event.loadSuppliers(suppliers));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  indexCompany: () => async (dispatch, getState) => {
    try {
      const { current: company } = getState().company;

      const events = await firestore.collection('events')
        .where('company', '==', company.id)
        .where('deletedAt', '==', null)
        .get();
      const companySuppliers = [];

      for (let i = 0; i < events.docs.length; i += 1) {
        const { id, ref } = events.docs[i];

        const suppliersRef = await ref.collection('suppliers').where('status', '>', 2).get();

        for (let j = 0; j < suppliersRef.docs.length; j += 1) {
          const supplier = { id: suppliersRef.docs[j].id, ...suppliersRef.docs[j].data() };
          companySuppliers.push({ ...supplier, event: id });
        }
      }

      companySuppliers.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(Company.loadSuppliers(companySuppliers));
    } catch (error) {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    }
  },

  showToken: (eventId, token) => async (dispatch) => {
    const register = await firestore.collection('supplier-token-register').doc(eventId).get();
    const data = register.data();
    let supplier = null;

    Object.keys(data).forEach((id) => {
      if (data[id] === token) supplier = id;
    });

    if (!supplier) {
      dispatch(Actions.setError('Invalid token'));
      return;
    }

    firestore.collection('events')
      .doc(eventId).collection('suppliers').doc(supplier)
      .onSnapshot((supplierData) => {
        dispatch(Actions.showEvent({ id: supplier, ...supplierData.data() }));
      });
  },

  showEvent: (eventId, id) => (dispatch) => {
    firestore.collection('events')
      .doc(eventId).collection('suppliers').doc(id)
      .onSnapshot((supplier) => {
        dispatch(Actions.showEvent({ id: supplier.id, ...supplier.data() }));
      });
  },

  showRegister: (eventId, cnpj) => async (dispatch) => {
    const company = await firestore.collection('events').doc(eventId).get()
      .then((snapshot) => snapshot.data().company);

    firestore.collection('companies').doc(company).collection('contacts').doc(cnpj)
      .onSnapshot((supplier) => {
        dispatch(Actions.showRegister({ id: supplier.id, ...supplier.data() }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('suppliers').add({
        ...data, updatedAt: now, createdAt: now, archived: false, interrupted: false, status: 0,
      })
      .then(() => {
        Mixpanel.track('Create a supplier');
        dispatch(Alert.show({ message: 'Supplier created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  register: (eventId, cnpj, data) => async (dispatch) => {
    const now = new Date(Date.now());

    const company = await firestore.collection('events').doc(eventId).get()
      .then((snapshot) => snapshot.data().company);

    firestore.collection('companies').doc(company).collection('contacts').doc(cnpj)
      .set({
        ...data, person: 'legal', updatedAt: now, createdAt: now,
      }, { merge: true })
      .catch((error) => {
        Mixpanel.track('Register a supplier');
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  registerEvent: (eventId, supplierId, register) => async (dispatch) => {
    const now = new Date(Date.now());

    const token = await firestore.collection('supplier-token-register').doc(eventId).get();
    const tokenRegister = token.data();
    if (tokenRegister && tokenRegister[supplierId]) {
      delete tokenRegister[supplierId];
      firestore.collection('supplier-token-register').doc(eventId).set(tokenRegister || {});
    }

    firestore.collection('events').doc(eventId)
      .collection('suppliers').doc(supplierId)
      .set({ register, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a supplier register');
        dispatch(Alert.show({ message: 'Supplier updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data, eventId) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event ? event.id : eventId)
      .collection('suppliers').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a supplier');
        dispatch(Alert.show({ message: 'Supplier updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  sendRegisterChat: (eventId, supplierId, chat) => (dispatch) => {
    const now = new Date(Date.now());

    firestore.collection('events').doc(eventId)
      .collection('suppliers').doc(supplierId)
      .set({ register: { chat }, updatedAt: now }, { merge: true })
      .catch((error) => {
        Mixpanel.track('Send a supplier chat');
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  sendRegisterMail: (supplier) => (dispatch, getState) => {
    const { current: company } = getState().company;
    const { current: event } = getState().event;

    const sendMail = functions.httpsCallable('supplier-sendMail');
    sendMail({ supplier, company, event })
      .then(() => {
        Mixpanel.track('Send a supplier register mail');
        dispatch(Alert.show({ message: 'Invalid e-mail.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('suppliers').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a supplier');
        dispatch(Alert.show({ message: 'Supplier successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  clear: () => (dispatch) => {
    dispatch(Actions.clear());
  },
};

export default Supplier;
