import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Diagram } from "@blink-mind/renderer-react";
import { generateSimpleModel } from "./utils";
import "@blink-mind/renderer-react/lib/main.css";

function Mindmap({ data, setData }) {
  const [topics, setTopics] = useState(data);
  const [model, setModel] = useState(generateSimpleModel(topics));
  const [diagram, setDiagram] = useState(null);

  useEffect(() => {
    setModel(generateSimpleModel(topics));
  }, [topics]);

  useEffect(() => {
    if (!_.isEqual(data, topics)) setTopics(data);
  }, [data, topics]);

  useEffect(() => {
    if (diagram) {
      const diagramProps = diagram.getDiagramProps();
      const { controller } = diagramProps;
      controller.run("setTheme", { ...diagramProps, themeKey: 'theme3' });
    }
  }, [diagram]);

  // const onClickUndo = e => {
  //   const props = diagram.getDiagramProps();
  //   const { controller } = props;
  //   controller.run("undo", props);
  // };

  // const onClickRedo = e => {
  //   const props = diagram.getDiagramProps();
  //   const { controller } = props;
  //   controller.run("redo", props);
  // };

  const onChange = (model, callback) => {
    const newTopics = [];
    
    model.topics.map((topic) => {
      const id = topic.key;
      let label = topic.blocks.get(0).data;
      if (label.document) label = label.document.text;
      const parent = topic.parentKey;
      newTopics.push({ id, label, parent });
      return { id, label: '', parent };
    });

    setData(newTopics);

    setModel(model);
    if (callback) callback();
  };

  return (
    <div style={{ width: 'calc(100% - 505px)', height: '100%' }}>
      <Diagram
        ref={setDiagram}
        model={model}
        onChange={onChange}
      />
    </div>
  );
}

export default Mindmap;
