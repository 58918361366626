import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  title: {
    fontSize: 20,
    color: theme.palette.tertiary.main,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  btnAdd: {
    borderRadius: 30,
    padding: 8,
    minWidth: 42,
    transition: 'all 0.2s ease-out',
    '& p': {
      maxWidth: 0,
      overflow: 'hidden',
      margin: 0,
      transition: 'all 0.2s ease-out',
    },
    '&:hover': {
      padding: '8px 16px',
      '& p': {
        maxWidth: 100,
        marginLeft: 8,
      },
    },
  },

  // Labels
  labelColor: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },

  btnOrder: {
    padding: 1,
    minWidth: 0,
    width: 24,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none',
    },
  },
}));
