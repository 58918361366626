/**
 * Types
 */
export const TypesFinancial = {
  LOAD_CASH_FLOW: '@event/LOAD_CASH_FLOW',
};

/**
 * Actions
 */
export const ActionsFinancial = {
  loadCashFlow: (cashFlow) => ({
    type: TypesFinancial.LOAD_CASH_FLOW,
    payload: { cashFlow },
  }),
};

/**
 * Reducer
 */
export const INITIAL_STATE_FINANCIAL = {
  cashFlow: null,
};

export const reducerFinancial = (state, action) => {
  switch (action.type) {
    case TypesFinancial.LOAD_CASH_FLOW: {
      const { cashFlow } = action.payload;
      return { ...state, cashFlow };
    }
    default: {
      return state;
    }
  }
};
