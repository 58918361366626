import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controller
import Auth from 'store/auth/controller';

// Icons, Assets and Styles
import {
  Calendar, Users, MoreVertical, User, LogOut, Menu as MenuIcon, BookOpen, /* , BarChart, */
} from 'react-feather';
import logo from 'assets/logo.png';
import styles from './style';

function Sidebar({ actived }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  let tabPostion = 0;
  let isFindPosition = true;

  const theme = useTheme();
  const drawer = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: company } = useSelector((state) => state.company);
  const { current: user } = useSelector((state) => state.user);

  const [menuEl, setMenuEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);

  const permissionTeam = !user.permissions || !user.permissions.team || user.permissions.team.view;
  const permissionRegister = !user.permissions || !user.permissions.manager
    || user.permissions.manager.financial.view || user.permissions.manager.procurement.view;

  const menu = [];

  // if (permissionDashboard) {
  //   menu.push({
  //     label: 'Dashboard',
  //     icon: <BarChart size={36} />,
  //     link: '/dashboard',
  //   });
  // }

  menu.push(
    {
      label: 'Events',
      icon: <Calendar size={36} />,
      link: '/events',
    },
  );

  if (permissionRegister && company.plan !== 'free' && company.plan !== 'planner') {
    menu.push({
      label: 'Register',
      icon: <BookOpen size={36} />,
      link: '/register',
    });
  }

  if (permissionTeam) {
    menu.push({
      label: 'Team',
      icon: <Users size={36} />,
      link: '/team',
    });
  }

  const content = (
    <Grid item md={3} lg={2} className={classes.sidebar}>
      <Link to="/">
        <img alt="Logo" src={logo} className={classes.logo} />
      </Link>
      <div className={classes.avatar}>
        <div className={classes.avatarText}>
          <Avatar alt={user.name} src={user.avatar} className={classes.avatarImg}>
            {user.initials}
          </Avatar>
        </div>

        <Fab
          size="small"
          color="secondary"
          aria-label="Edit"
          className={classes.avatarAction}
          onClick={(e) => setMenuEl(e.currentTarget)}
        >
          <MoreVertical size={16} />
        </Fab>

        <Menu
          open={!!menuEl}
          anchorEl={menuEl}
          className={classes.popover}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setMenuEl(null)}
        >
          <MenuItem onClick={() => history.push('/profile')}>
            <ListItemIcon style={{ minWidth: 32 }}>
              <User size={16} />
            </ListItemIcon>
            <Typography>Profile</Typography>
          </MenuItem>
          <MenuItem onClick={() => dispatch(Auth.signOut())} className={classes.logout}>
            <ListItemIcon style={{ minWidth: 32 }}>
              <LogOut size={16} />
            </ListItemIcon>
            <Typography>Exit</Typography>
          </MenuItem>
        </Menu>
      </div>
      <ul className={classes.list}>
        {menu.map((item) => {
          const isActived = actived === item.link
            || (item.submenu && item.submenu.find((s) => s.link === actived));
          if (!isActived && isFindPosition) tabPostion += 72;
          else if (isActived) isFindPosition = false;

          return (
            <li key={item.link} className={isActived ? classes.itemActive : classes.item}>
              <Link to={item.link}>
                <div className={isActived ? classes.iconActive : classes.icon}>{item.icon}</div>
                <div className={classes.label}>{item.label}</div>
              </Link>
              {item.submenu && (
                <div className={classes.submenu}>
                  {item.submenu.map((sub) => {
                    const subActived = actived === sub.link;

                    return (
                      <Link
                        to={sub.link}
                        key={sub.link}
                        onClick={(e) => e.stopPropagation()}
                        styles={subActived && { fontWeight: 600 }}
                      >
                        {sub.label}
                      </Link>
                    );
                  })}
                </div>
              )}
              <div className={isActived ? classes.backgroundActive : classes.background} />
            </li>
          );
        })}

        {actived !== '/settings' && (
          <div className={classes.marker} style={{ top: tabPostion }} />
        )}
      </ul>      
    </Grid>
  );

  if (drawer) {
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Grid container justify="space-between" alignItems="center">
              <IconButton
                color="primary"
                aria-label="menu"
                className={classes.backButton}
                onClick={() => setOpenMenu(true)}
              >
                <MenuIcon />
              </IconButton>

              <img src={logo} alt="Logo Vou" className={classes.logoToolbar} />

              <div style={{ width: 48 }} />
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          PaperProps={{ style: { width: '75%' } }}
        >
          {content}
        </Drawer>
      </>
    );
  }

  return content;
}

Sidebar.propTypes = {
  actived: PropTypes.string.isRequired,
};

export default Sidebar;
