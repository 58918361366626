import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

function CheckboxInput({
  value, label, onChange, helperText, error, disabled,
}) {
  return (
    <>
      <FormControlLabel
        control={<Checkbox color="primary" checked={value} onChange={() => onChange(!value)} />}
        label={label}
        disabled={disabled}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

CheckboxInput.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  label: '',
  helperText: '',
  error: false,
  disabled: false,
};

export default CheckboxInput;
