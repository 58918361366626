import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Mail, MapPin, Phone, User, Globe, X, Check,
} from 'react-feather';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function Contact({ contact, edit, setEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { supplierId } = useParams();
  const { suppliers } = useSelector((store) => store.event);

  const cancel = () => {
    setEdit(false);
  };

  const submit = (data) => {
    dispatch(Log.store(`Changed the supplier ${suppliers.find((s) => s.id === supplierId).name}`));
    dispatch(
      Supplier.update(supplierId, { contact: data }),
    );
    setEdit(false);
  };

  const formSchema = FormConfig();

  let columns = [
    !!contact.name,
    !!contact.site,
    !!contact.email,
    !!contact.phone,
    !!contact.location,
  ];

  const countColumns = columns.reduce((count, column) => {
    if (column) return count + 1;
    return count;
  }, 0);

  if (countColumns === 3 || countColumns === 5) {
    if (columns[0]) columns[0] = 12;
    else if (columns[1]) columns[1] = 12;
    else columns[2] = 12;
  }

  columns = columns.map((c) => {
    if (c === true) return countColumns > 1 ? 6 : 12;
    return c;
  });

  return (
    <Grid container spacing={1}>
      {edit ? (
        <Form
          id="supplier-contact"
          schema={formSchema}
          onSubmit={submit}
          initialState={contact}
        >
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>{inputs.name}</Grid>
              <Grid item xs={12} sm={6}>{inputs.email}</Grid>
              <Grid item xs={12} sm={6}>{inputs.phone}</Grid>
              <Grid item xs={12} style={{ marginBottom: 24 }}>{inputs.site}</Grid>

              <Grid container justify="center" className={classes.containerBtns}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={cancel}
                  className={classes.buttonCancel}
                >
                  <X size={16} style={{ marginRight: 8 }} />
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  form="supplier-contact"
                  className={classes.buttonConfirm}
                >
                  <Check size={16} style={{ marginRight: 8 }} />
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </Form>
      ) : (
        <>
          {contact.name && (
            <Grid item sm={columns[0]} xs={12}>
              <Grid container className={classes.infoContent}>
                <Typography variant="overline" className={classes.infoTitle}>
                  Name
                </Typography>
              </Grid>
              <Grid container alignItems="center" className={classes.infoContent}>
                <User size={18} className={classes.infoIcon} />
                <Typography className={classes.infoText}>{contact.name}</Typography>
              </Grid>
            </Grid>
          )}

          {contact.email && (
            <Grid item sm={columns[2]} xs={12}>
              <Grid container className={classes.infoContent}>
                <Typography variant="overline" className={classes.infoTitle}>
                  E-mail
                </Typography>
              </Grid>
              <Grid container alignItems="center" className={classes.infoContent}>
                <Mail size={18} className={classes.infoIcon} />
                <Typography className={classes.infoText}>{contact.email}</Typography>
              </Grid>
            </Grid>
          )}

          {contact.phone && (
            <Grid item sm={columns[3]} xs={12}>
              <Grid container className={classes.infoContent}>
                <Typography variant="overline" className={classes.infoTitle}>
                  Phone
                </Typography>
              </Grid>
              <Grid container alignItems="center" className={classes.infoContent}>
                <Phone size={18} className={classes.infoIcon} />
                <Typography className={classes.infoText}>{contact.phone}</Typography>
              </Grid>
            </Grid>
          )}

          {contact.location && (
            <Grid item sm={columns[4]} xs={12}>
              <Grid container className={classes.infoContent}>
                <Typography variant="overline" className={classes.infoTitle}>
                  City
                </Typography>
              </Grid>
              <Grid container alignItems="center" className={classes.infoContent}>
                <MapPin size={18} className={classes.infoIcon} />
                <Typography className={classes.infoText}>{contact.location}</Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {contact.site && (
        <Grid item sm={columns[1]} xs={12}>
          <Grid container className={classes.infoContent}>
            <Typography variant="overline" className={classes.infoTitle}>
              Website
            </Typography>
          </Grid>
          <Grid container alignItems="center" className={classes.infoContent}>
            <Globe size={18} className={classes.infoIcon} />
            <a
              href={`http://${contact.site}`.replace('http://http', 'http')}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.site}
            >
              <Typography className={classes.infoText}>{contact.site}</Typography>
            </a>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

Contact.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    site: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired,
};

export default Contact;
