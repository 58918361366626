import React from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons, Components and Styles
import { SalesConvertion } from 'assets/custom-icons';
import styles from './style';

function Budget() {
  const classes = styles();

  return (
    <Grid container wrap="nowrap" alignItems="center" justify="center" style={{ height: '100%' }}>
      <Grid item className={classes.iconContainer}>
        <SalesConvertion className={classes.icon} />
      </Grid>
      <Grid container direction="column" justify="center">
        <Grid item style={{ marginLeft: 16 }}>
          <Typography className={classes.value}>
            58
            <span>%</span>
            <span className={classes.title}>of convertion</span>
          </Typography>
          <Typography className={classes.details}>
            <b>11</b>
            {' '}
            of
            {' '}
            <b>19</b>
            {' '}
            quotes
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Budget;
