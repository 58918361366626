import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  menuList: {
    width: 350,
    maxHeight: 350,
  },
  popover: {
    marginTop: 52,
  },
  item: {
    height: 'auto',
    padding: `${theme.spacing(0.75)}px ${theme.spacing()}px`,
    borderBottom: '1px solid rgb(224, 224, 224)',
    '&:last-child': { border: 0 },
  },
  highlightItem: {
    background: lighten(theme.palette.secondary.light, 0.85),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.65),
    },
  },
  text: {
    width: 'calc(100% - 56px)',
  },
  title: {
    fontWeight: 500,
  },
  noWrap: {
    whiteSpace: 'initial',
  },

  // Info dialog
  dialogContent: {
    padding: '16px 24px',
  },
  valueDate: {
    fontSize: 18,
    '& > svg': {
      color: theme.palette.primary.main,
      marginRight: 16,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    '& > svg': {
      marginRight: 8,
    },
  },
  value: {
    fontSize: 18,
    marginTop: 8,
  }
}));
