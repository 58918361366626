import React from 'react';
import PropTypes from 'prop-types';

import { SliderPicker } from 'react-color';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';

function ColorPicker({
  value, error, helperText, onChange,
}) {
  return (
    <>
      <div style={{ width: '100%' }}>
        <SliderPicker color={value} onChange={(e) => onChange(e.hex)} />
      </div>
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
};

ColorPicker.defaultProps = {
  helperText: '',
  error: false,
};

export default ColorPicker;
