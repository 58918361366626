import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    margin: '0 auto',
  },
  logo: {
    display: 'block',
    height: '6rem',
    margin: `${theme.spacing(2)}px auto`,
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    width: '100%',
    margin: `${theme.spacing()}px 0`,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    width: '100%',
    margin: `${theme.spacing()}px 0`,
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  messageSnackbar: {
    display: 'flex',
    alignItems: 'center',
  },
}));
