import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Controllers
import Supplier from 'store/supplier/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Log from 'store/log/controller';

// Icons and Styles
import { installmentWithCashFlow } from 'common/utils/relationships';
import { createCashFlow } from 'common/utils/suppliers';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function ButtonsInfo({ supplier }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { cashFlow } = useSelector((store) => store.company);

  const [removeSupplier, setRemoveSupplier] = useState(null);

  const steps = ['CONTACT', 'NEGOTIATIONS', 'REGISTER', 'CLOSED', 'PAID'];

  useEffect(() => {
    if (supplier.status === 3 && cashFlow) {
      const { installments } = supplier.payment;
      if (installments.length > 1) {
        if (!installments.find((i) => !i.cashFlow)) {
          const cashInstallments = installments.map((i) => i.cashFlow);
          cashInstallments.forEach((cId) => {
            const cash = cashFlow.find((c) => c.id === cId);
            if (cash && (!cash.installments || cash.installments.length !== installments.length)) {
              dispatch(CashFlow.update(cId, {
                installments: cashInstallments.map((c) => (c === cId ? null : c)),
              }));
            }
          });
        }
      }
    }
  }, [supplier, cashFlow, dispatch]);

  const newCashFlow = () => {
    const cashFlows = createCashFlow(supplier);

    cashFlows.forEach((cash, i) => {
      dispatch(CashFlow.storeSupplier(supplier, i, cash));
    });
  };

  const removeCashFlow = () => {
    supplier.payment.installments.forEach((i) => {
      dispatch(CashFlow.destroy(i.cashFlow));
    });
    dispatch(Supplier.update(supplier.id, {
      payment: {
        ...supplier.payment,
        installments: supplier.payment.installments.map((i) => ({ ...i, cashFlow: null })),
      },
    }));
  };

  const nextStep = () => {
    dispatch(Log.store(`Moved the supplier ${supplier.name} to the step ${steps[supplier.status + 1]}`));
    dispatch(Supplier.update(supplier.id, { status: supplier.status + 1 }));
  };

  const archive = () => {
    dispatch(Log.store(`Archived the supplier ${supplier.name}`));
    dispatch(Supplier.update(supplier.id, { archived: true }));
  };

  const unarchived = () => {
    dispatch(Log.store(`Unarchived the supplier ${supplier.name}`));
    dispatch(Supplier.update(supplier.id, { archived: false }));
  };

  const interrupt = () => {
    dispatch(Log.store(`Interrupted negotiation with the supplier ${supplier.name}`));
    dispatch(Supplier.update(supplier.id, { interrupted: true }));
    if (supplier.status === 3) removeCashFlow();
  };

  const uninterrupt = () => {
    dispatch(Log.store(`Negotiation with supplier ${supplier.name} resumed`));
    dispatch(Supplier.update(supplier.id, { interrupted: false, archived: false }));
    if (supplier.status === 3) newCashFlow();
  };

  const resendMail = () => {
    dispatch(Log.store(`Sent a register e-mail to the supplier ${supplier.name}`));
    dispatch(Supplier.sendRegisterMail(supplier));
    dispatch(Supplier.update(supplier.id, { register: { answered: false, sending: true } }));
  };

  const approve = () => {
    dispatch(Log.store(`Supplier ${supplier.name} approved`));
    if (supplier.payment) newCashFlow();
    dispatch(Supplier.update(supplier.id, { status: supplier.payment ? 3 : 4 }));
  };

  if (supplier.interrupted) {
    return (
      <Grid container justify="space-between" className={classes.buttonContent}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={!supplier.archived ? archive : unarchived}
        >
          {!supplier.archived ? 'Archive' : 'Unarchive'}
        </Button>
        <Button variant="outlined" color="primary" onClick={uninterrupt}>
          Resume negotiation
        </Button>
      </Grid>
    );
  }

  if (supplier.status === 0) {
    console.log(supplier.products.length);
    return (
      <Grid container justify="space-between" className={classes.buttonContent}>
        <Button
          variant="outlined"
          className={classes.errorColor}
          onClick={() => setRemoveSupplier(supplier)}
        >
          Remove
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={nextStep}
          disabled={supplier.products.length === 0}
        >
          Add budget
        </Button>

        {removeSupplier && (
          <ConfirmDialog
            title={`Deseja mesmo excluir a negociação com ${removeSupplier.name}`}
            onConfirm={() => dispatch(Supplier.destroy(supplier.id))}
            onClose={() => setRemoveSupplier(null)}
          />
        )}
      </Grid>
    );
  }

  if (supplier.status === 1) {
    const readyToNextStep = supplier.budget && (
      (supplier.payment && supplier.payment.total) || supplier.budget.total === 0);
    let allProductsComplete = supplier.budget && supplier.budget.products;

    if (allProductsComplete) {
      supplier.products.forEach((product) => {
        if (!supplier.budget.products[product.id]) allProductsComplete = false;
        else {
          const { amount, value } = supplier.budget.products[product.id];
          if (amount === 0) allProductsComplete = false;
          if (value === 0) allProductsComplete = false;
        }
      });
    }

    return (
      <Grid
        container
        justify={readyToNextStep ? 'space-between' : 'center'}
        className={classes.buttonContent}
      >
        <Button variant="outlined" className={classes.errorColor} onClick={interrupt}>
          Stop negotiating
        </Button>
        {readyToNextStep && allProductsComplete && (
          <Button variant="outlined" color="primary" onClick={nextStep}>
            {supplier.register ? 'Continuar contratação' : 'Fechar contrato'}
          </Button>
        )}
      </Grid>
    );
  }

  if (supplier.status === 2) {
    const readyToNextStep = supplier.register && supplier.register.cnpj;
    return (
      <Grid
        container
        justify={readyToNextStep ? 'space-between' : 'center'}
        className={classes.buttonContent}
      >
        {readyToNextStep ? (
          <>
            <Button variant="outlined" className={classes.errorColor} onClick={interrupt}>
              {supplier.register.answered || supplier.register.chat
                ? 'Disapprove'
                : 'Cancel hiring'}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={supplier.register.answered || supplier.register.chat ? approve : resendMail}
              disabled={supplier.register.sending}
            >
              {supplier.register.answered || supplier.register.chat
                ? 'Approve'
                : 'Resend request'}
            </Button>
          </>
        ) : (
          <Button variant="outlined" className={classes.errorColor} onClick={interrupt}>
            Cancel hiring
          </Button>
        )}
      </Grid>
    );
  }

  let isPayed = false;

  if (supplier.payment) {
    const installments = supplier.payment.installments.map(
      (i) => installmentWithCashFlow(i, cashFlow || []),
    );
    isPayed = installments.find((i) => i.cashFlow && i.cashFlow.completed.on);
  }

  if (supplier.status === 3 && !isPayed) {
    return (
      <Grid container justify="center" className={classes.buttonContent}>
        <Button variant="outlined" className={classes.errorColor} onClick={interrupt}>
          Cancel hiring
        </Button>
      </Grid>
    );
  }

  if (supplier.status === 4
    && (!supplier.delivery || !supplier.delivery.estimated || supplier.delivery.done)
  ) {
    return (
      <Grid container justify="center" className={classes.buttonContent}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={!supplier.archived ? archive : unarchived}
        >
          {!supplier.archived ? 'Archive' : 'Unarchive'}
        </Button>
      </Grid>
    );
  }

  return <></>;
}

ButtonsInfo.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    budget: PropTypes.object,
    payment: PropTypes.object,
    delivery: PropTypes.object,
    register: PropTypes.object,
    products: PropTypes.array,
    archived: PropTypes.bool,
    interrupted: PropTypes.bool,
    status: PropTypes.number,
  }).isRequired,
};

export default ButtonsInfo;
