import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Commons, Icons and Styles
import { DollarSign } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import styles from './style';

function Negotiation({ contact }) {
  const classes = styles();

  if (!contact.budget) {
    return (
      <Grid container alignItems="center" className={classes.moreContent}>
        <Typography variant="overline" className={classes.moreInfoTitle}>
          Add budget
        </Typography>
      </Grid>
    );
  }

  let current = 0;
  if (contact.payment && contact.payment.total) {
    current = contact.payment.total;
  }

  return (
    <Grid container alignItems="center" className={classes.moreContent}>
      <DollarSign size={16} className={classes.moreInfoIcon} />
      <Typography className={classes.moreInfoSuccess}>{numberToReal(current)}</Typography>
    </Grid>
  );
}

Negotiation.propTypes = {
  contact: PropTypes.shape({
    budget: PropTypes.object,
    payment: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Negotiation;
