import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import { categoryWithProducts } from 'common/utils/relationships';
import styles from './style';

function FormConfig({ filteredCategories, setFilteredCategories }) {
  const classes = styles();

  const { categories, users } = useSelector((store) => store.company);
  const { products } = useSelector((store) => store.event);

  const getCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        getChildren(c, `${label} > ${c.name}`);
      });
    };

    if (categories) {
      categories
        .filter((c) => c.parent === 'suppliers')
        .forEach((category) => {
          cat.push(category);
          if (filteredCategories.indexOf(category.id) < 0) {
            getChildren(category, category.name);
          }
        });
    }

    return cat;
  };

  return [
    {
      name: 'categories',
      type: 'select-chip',
      label: 'Categories',
      options: getCategories().map((category) => ({
        value: category.id,
        chip: { label: category.name },
        label: (
          <Grid container>
            <div className={classes.categoryColor} style={{ background: category.color }} />
            <div className={classes.category}>
              {category.parentLabel && <span>{category.parentLabel}</span>}
              <p>{category.name}</p>
            </div>
          </Grid>
        ),
      })),
      onChange: (value, state) => {
        setFilteredCategories(value);
        const filteredProducts = _.get(state, 'products');
        if (filteredProducts.length > 0) {
          const newProducts = filteredProducts.filter((pId) => {
            const product = products.find((p) => p.id === pId);
            return product && value.indexOf(product.category) >= 0;
          });
          _.set(state, 'products', newProducts);
        }
        return state;
      },
    },
    {
      name: 'products',
      type: 'select-chip',
      label: 'Products',
      headers: categories
        ? categories
          .filter((c) => (filteredCategories.length > 0
            ? filteredCategories.indexOf(c.id) >= 0
            : true))
          .map((c) => categoryWithProducts(c, products))
          .map((c) => ({
            id: c.id,
            className: classes.categoryLabel,
            style: { color: c.color },
            title: c.name,
            items: c.products
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((p) => ({ value: p.id, label: p.name })),
          }))
        : [],
      options: products ? products.map((p) => ({ value: p.id, label: p.name })) : [],
    },
    {
      name: 'responsibles',
      type: 'select-chip',
      label: 'Responsibles',
      options: users
        ? users.filter((u) => !u.deletedAt).map((u) => ({
          value: u.id,
          chip: {
            label: u.name,
            avatar: <Avatar alt={u.name} src={u.avatar}>{u.initials}</Avatar>,
          },
          label: (
            <>
              <ListItemAvatar>
                <Avatar alt={u.name} src={u.avatar} className={classes.avatar}>
                  {u.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={u.name} />
            </>
          ),
        }))
        : [],
    },
    {
      name: 'value.min',
      type: 'currency',
      label: 'From',
    },
    {
      name: 'value.max',
      type: 'currency',
      label: 'To',
      validation: {
        minValue: {
          msg: 'The minimum value must be less than the maximum value',
          params: { min: 'value.min' },
        },
      },
    },
    {
      name: 'currentBiggerForecast',
      type: 'checkbox',
      label: 'CURRENT VALUE HIGHER THAN ESTIMATED VALUE',
    },
    {
      name: 'paymentLate',
      type: 'checkbox',
      label: 'LATE PAYMENT',
    },
    {
      name: 'deliveryLate',
      type: 'checkbox',
      label: 'LATE DELIVERY',
    },
  ];
}

export default FormConfig;
