import React from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Commons and Styles
import { currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import styles from './style';

function InputCurrencyTypes({
  label, value, error, helperText, onChange, disabled, options, className,
}) {
  const classes = styles();

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={!!error}
        label={label}
        disabled={disabled}
        value={currencyInput(value.value)}
        onChange={(e) => {
          onChange({ ...value, value: revertCurrencyInput(e.target.value) });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <Select
                value={value.type}
                style={{ paddingRight: 8 }}
                className={classes.select}
                classes={{ select: classes.select }}
                onChange={(e) => {
                  onChange({ ...value, type: e.target.value });
                }}
              >
                {options.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
          inputProps: { className },
        }}
      />
      {((error && typeof error === 'string') || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

InputCurrencyTypes.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

InputCurrencyTypes.defaultProps = {
  label: '',
  error: false,
  helperText: '',
  disabled: false,
  className: null,
};

export default InputCurrencyTypes;
