import { formatRelative, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatRelativeDate(date, dateCompare) {
  function formatDate(dt) {
    const day = dt.substr(0, 2);
    const month = dt.substr(3, 2);
    const year = dt.substr(6, 4);

    return `${year}-${month}-${day}`;
  }

  const formatedDate = formatRelative(date, dateCompare, { locale: ptBR });
  const dateToVerify = formatDate(formatedDate);

  if (new Date(dateToVerify).toString() !== 'Invalid Date') {
    return format(date, "dd MMM yyyy 'at' HH:mm", { locale: ptBR }).toLowerCase();
  }

  return formatedDate;
}

export function anotherFunc() { }
