import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  timeline: {
    position: 'relative',
    width: '100%',
    height: `calc(100% - 162px)`,
    marginTop: theme.spacing(2),
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      marginTop: 0,
      height: 'fit-content',
    },
  },
  control: {
    position: 'absolute',
    top: 12,
    zIndex: 1,
  },
  day: {
    width: '23%',
    height: '100%',
    padding: theme.spacing(),
    display: 'inline-flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'fit-content',
    },
  },
  title: {
    position: 'relative',
    width: '100%',
    height: 'fit-content',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main,
    '& > p': {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.common.grey,
    },
    '&:before': {
      content: '" "',
      position: 'absolute',
      bottom: -12,
      left: -16,
      right: -16,
      height: 4,
      background: theme.palette.primary.main,
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: -18,
      left: '50%',
      marginLeft: -8,
      width: 16,
      height: 16,
      borderRadius: '50%',
      background: theme.palette.primary.main,
      zIndex: 1,
    },
  },
  text: {
    backgroundColor: '#c9c8c8',
    opacity: 0.5,
    width: 150,
    height: 24,
    margin: 'auto',
  },
  cardGrid: {
    top: 0,
    width: '100%',
    overflow: 'overlay',
    height: '100%',
    padding: `${theme.spacing()}px ${theme.spacing()}px 0`,
    marginTop: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()}px 0 0`,
    },
  },
  noData: {
    width: '100%',
    color: theme.palette.common.grey,
    fontSize: 18,
    textAlign: 'center',
    marginTop: theme.spacing(),
  },
  step: {
    boxShadow: 'none',
    marginBottom: theme.spacing(),
    '&:before': {
      content: 'none',
    },
  },
  stepTitle: {
    padding: '0 12px',
    minHeight: '48px !important',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 12,
      paddingLeft: theme.spacing(0.5),
    },
  },
  stepTitleExpanded: {
    margin: '12px 0 !important',
  },
  stepCards: {
    padding: theme.spacing(0.25),
  },

  fab: {
    position: 'absolute',
    top: -16,
    left: 0,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      top: 16,
      right: '0 !important',
    },
  },

  // Task
  card: {
    position: 'relative',
    marginTop: theme.spacing(),
    width: '100%',
    overflow: 'initial',
  },
  actionArea: {
    '& > span': {
      backgroundColor: 'white',
      '&:last-child': {
        background: 'none',
      },
    },
  },
  priority: {
    fontSize: 12,
    width: 20,
    minWidth: 20,
    borderRadius: 30,
    height: 20,
    textAlign: 'center',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
    zIndex: 1,
  },
  label: {
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 12,
    fontSize: 11,
    fontWeight: 600,
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
  },
  taskName: {
    marginTop: theme.spacing(0.5),
    fontSize: 18,
    whiteSpace: 'normal',
  },
  avatar: {
    background: theme.palette.primary.main,
    marginRight: theme.spacing(-0.5),
    width: 24,
    height: 24,
    fontSize: 12,
    '&:first-child': {
      marginRight: 0,
    },
  },
  checked: {
    width: 'fit-content',
    padding: '4px 8px',
    background: theme.palette.success.main,
    borderRadius: 8,
  },
  due: {
    width: 'fit-content',
    padding: '4px 8px',
    background: theme.palette.error.main,
    borderRadius: 8,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  badge: {
    right: -6,
    top: -6,
  },
}));
