import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    overflow: 'visible',
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 18,
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  listHeader: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  expansion: {
    margin: `${theme.spacing(0.25)}px 0`,
    '&:before': {
      content: 'none',
    },
  },
  titleExpansion: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    margin: 20,
  },
  actions: {
    justifyContent: 'center',
  },
}));
