import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus } from 'react-feather';
import Sidebar from 'common/sidebar/home';
import Tools from 'app/tools';
import styles from './style';

// Components
import TeamTable from './table';

function Team() {
  const classes = styles();
  const history = useHistory();

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: { permissions } } = useSelector((state) => state.user);

  const permissionEdit = !permissions || !permissions.team || permissions.team.edit;
  const permissionView = !permissions || !permissions.team || permissions.team.view;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  return (
    <Grid container spacing={0}>
      <Sidebar actived="/team" />
      <Grid item xs={12} sm={8} md={9} lg={10} className={classes.container}>
        <Grid container justify={downSM ? 'center' : 'space-between'} alignItems="center">
          <Typography variant="h3" className={classes.title}>
            TEAM
          </Typography>
          {permissionEdit && (
            <Button
              color="primary"
              aria-label="Add event"
              variant="contained"
              className={classes.button}
              onClick={() => history.push('/team/new')}
            >
              <Plus style={{ marginRight: 8 }} strokeWidth={3} />
              New Collaborator
            </Button>
          )}
        </Grid>

        <TeamTable />

        <Tools />
      </Grid>
    </Grid>
  );
}

export default Team;
