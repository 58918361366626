import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// MaterialUI's Components
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as Form } from 'store/form/reducer';

// Icons and Styles
import { Plus } from 'react-feather';
import styles from './style';

function SelectChips({
  id,
  name,
  state,
  label,
  value,
  disabled,
  error,
  helperText,
  options,
  headers,
  addOption,
  onChange,
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();

  const [menuEl, setMenuEl] = useState(null);

  return (
    <Grid container className={classes.container}>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          {typeof label === 'string' ? (
            <Typography gutterBottom variant="overline" className={classes.title}>
              {label}
            </Typography>
          ) : label}
        </Grid>
        <Grid item>
          <IconButton
            id={name}
            color="primary"
            disabled={disabled}
            onClick={(e) => setMenuEl(e.currentTarget)}
          >
            <Plus />
          </IconButton>
        </Grid>
      </Grid>
      {menuEl && (
        <Menu
          anchorEl={menuEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(menuEl)}
          PaperProps={{ style: { maxHeight: 350, width: 300 } }}
          onClose={() => setMenuEl(false)}
        >
          {addOption && (
            <MenuItem
              onClick={addOption.url ? () => {
                dispatch(Form.save({ id, state }));
                history.push(addOption.url);
              } : addOption.onClick}
              className={classes.add}
            >
              {addOption.label}
            </MenuItem>
          )}
          {headers
            ? headers
              .filter((h) => h.items.filter((i) => value.indexOf(i.value) < 0).length > 0)
              .map((header) => [
                <ListSubheader
                  key={header.id}
                  className={header.className}
                  style={header.style}
                >
                  {header.title}
                </ListSubheader>,
                header.items.filter((i) => value.indexOf(i.value) < 0).map((item) => (
                  <ListItem
                    button
                    key={item.value}
                    onClick={() => {
                      onChange([...value, item.value]);
                      setMenuEl(null);
                    }}
                  >
                    {typeof item.label === 'string' ? (
                      <ListItemText primary={item.label} />
                    ) : item.label}
                  </ListItem>
                )),
              ])
            : options.filter((i) => value.indexOf(i.value) < 0).map((item) => (
              <MenuItem
                button
                key={item.value}
                className={item.className}
                onClick={() => {
                  onChange([...value, item.value]);
                  setMenuEl(null);
                }}
              >
                {item.label}
              </MenuItem>
            ))}
        </Menu>
      )}
      <Grid item xs={12}>
        {value.map((v, key) => {
          const item = options.find((o) => o.value === v);
          return (
            <Chip
              className={classes.chip}
              color="primary"
              key={v}
              style={item.chip && item.chip.color ? {
                background: item.chip.color,
                color: theme.palette.getContrastText(item.chip.color),
              } : {}}
              avatar={(!!item && !!item.chip && item.chip.avatar) || null}
              label={!!item && (typeof item.label === 'string' ? item.label : item.chip.label)}
              onDelete={!!item && (!item.chip || !item.chip.noRemove) && !disabled && (() => {
                const newValue = [...value];
                newValue.splice(key, 1);
                onChange(newValue);
              })}
            />
          );
        })}
      </Grid>
      {(error || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </Grid>
  );
}

SelectChips.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  addOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
};

SelectChips.defaultProps = {
  headers: null,
  addOption: null,
  label: '',
  disabled: false,
  error: false,
  helperText: '',
};

export default SelectChips;
