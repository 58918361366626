import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Contact from 'store/contact/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

function CategoryForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { contactId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionProcurement = !permissions || !permissions.manager
    || permissions.manager.procurement.edit;
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const permissionEdit = permissionProcurement || permissionFinancial;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  const [edit, setEdit] = useState(false);
  const [person, setPerson] = useState('legal');
  const [initialState, setInitialState] = useState({
    person: 'legal',
    document: '',
    name: '',
    // For CNPJ
    companyName: '',
    situation: '',
    legalNature: '',
    mainActivity: { code: '', description: '' },
    // Commons
    transference: { bank: '', agency: '', code: '' },
    types: [],
    contact: { email: '', phone: '', site: '' },
    address: {
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      // neighborhood: '',
      city: '',
      state: '',
      country: '',
    },
  });

  const { contacts } = useSelector((state) => state.company);

  useEffect(() => {
    if (!contacts) dispatch(Contact.index());
  }, [contacts, dispatch]);

  useEffect(() => {
    if (contactId && contacts) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setPerson(contact.person);
        setInitialState((s) => ({ ...s, ...contact }));
        setEdit(true);
      } else {
        history.goBack();
      }
    }
  }, [contactId, contacts, history]);

  const submit = (data) => {
    if (edit) {
      dispatch(Log.store(`Changed a contact ${data.name}`));
      dispatch(Contact.update(contactId, data));
    } else {
      dispatch(Log.store(`Created contact ${data.name}`));
      dispatch(Contact.store(data));
    }
    history.goBack();
  };

  const formSchema = FormConfig({ edit, person, setPerson });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={() => history.goBack()}
      aria-labelledby="form-product-category-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>{edit ? `Changing ${initialState.name}` : 'Create a new contact'}</span>
          <IconButton onClick={() => history.goBack()} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {contacts ? (
          <Form id="form-contact" onSubmit={submit} schema={formSchema} initialState={initialState}>
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>{inputs.person}</Grid>

                <Grid item xs={12}>{inputs.document}</Grid>
                <Grid item xs={12}>{inputs.name}</Grid>
                {person === 'legal' && (
                  <>
                    <Grid item xs={12}>{inputs.companyName}</Grid>

                    <Grid item xs={12} sm={6}>{inputs.situation}</Grid>
                    <Grid item xs={12} sm={6}>{inputs.legalNature}</Grid>


                    <Grid item xs={12} sm={12} className={classes.subtitle}>
                      <Typography variant="overline">MAIN ACTIVITY</Typography>
                    </Grid>
                    <Grid item xs={5} sm={3}>{inputs.mainActivity.code}</Grid>
                    <Grid item xs={7} sm={9}>{inputs.mainActivity.description}</Grid>
                  </>
                )}

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">CONTACT</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>{inputs.contact.email}</Grid>
                <Grid item xs={12} sm={6}>{inputs.contact.phone}</Grid>
                <Grid item xs={12}>{inputs.contact.site}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">
                    DATA BANK
                    <span>(OPCIONAL)</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>{inputs.transference.bankName}</Grid>
                <Grid item xs={6} sm={3}>{inputs.transference.agency}</Grid>
                <Grid item xs={6} sm={3}>{inputs.transference.code}</Grid>

                <Grid item xs={12}>{inputs.types}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">ADDRESS</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>{inputs.address.postalCode}</Grid>
                <Grid item xs={12} sm={8}>{inputs.address.street}</Grid>
                <Grid item xs={3} sm={3}>{inputs.address.number}</Grid>
                <Grid item xs={9} sm={5}>{inputs.address.complement}</Grid>
                {/* <Grid item xs={12} sm={4}>{inputs.address.neighborhood}</Grid> */}
                <Grid item xs={12} sm={6}>{inputs.address.city}</Grid>
                <Grid item xs={6} sm={3}>{inputs.address.state}</Grid>
                <Grid item xs={6} sm={3}>{inputs.address.country}</Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!contacts} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-contact"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategoryForm;
