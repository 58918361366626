import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  s: {
    maxHeight: 400,
  },
}));
