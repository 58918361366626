import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Label from 'store/productivity/label/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

function LabelForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { labelId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [initialForm, setInitialForm] = useState({ name: '', color: '' });

  const { labels } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (labels && labelId) {
      const label = labels.find((l) => l.id === labelId);

      if (label) {
        setInitialForm({ name: label.name, color: label.color });
        setEdit(true);
      } else {
        history.goBack();
      }
    }
  }, [labels, labelId, history]);

  const submit = async (data) => {
    if (edit) {
      dispatch(Log.store(`Changed the tag ${initialForm.name}`));
      dispatch(Label.update(labelId, data));
    } else {
      dispatch(Log.store(`Created the tag ${data.name}`));
      dispatch(Label.store(data));
    }
    history.goBack();
  };

  const formSchema = FormConfig({ labels, labelId });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={history.goBack}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>{edit ? `Editing tag ${initialForm.name}` : 'Create new tag'}</span>
          <IconButton onClick={history.goBack} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {labels ? (
          <Form
            id="form-label"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  {inputs.name}
                </Grid>
                <Grid item xs={12}>
                  {inputs.color}
                </Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!labels} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="form-label"
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LabelForm;
