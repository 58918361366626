import { addDays, isSameDay, differenceInCalendarDays, isWeekend } from 'date-fns';

export const generateData = (tasks, users) => {
  const data = {}
  
  users.filter((u) => !u.deletedAt).forEach((user) => {
    const userTasks = tasks.filter((t) => t.responsibles.indexOf(user.id) >= 0 && t.completedAt);
    const [firstTask] = userTasks.sort((a, b) => a.completedAt.toDate() - b.completedAt.toDate());
    const days = [];

    if (firstTask) {
      const firstDay = firstTask.completedAt.toDate();
      const diff = differenceInCalendarDays(new Date(), firstDay);
  
      for (let i = 0; i <= diff; i += 1) {
        const currentDay = addDays(firstDay, i);
        const dayTasks = userTasks.filter((t) => isSameDay(currentDay, t.completedAt.toDate()));
        const len = dayTasks.length;

        if(len > 0 || !isWeekend(currentDay)) days.push(dayTasks.length);
      }
    }

    data[user.id] =  days;
  });

  return data;
};

export const getOptions = (data) => ({
  tooltip: {
    show: false,
  },
  grid: {
    left: '1%',
    right: '1%',
    bottom: '2px',
    top: '2px',
    containLabel: false,
  },
  xAxis: {
    type: 'category',
    show: false,
    boundaryGap: false,
    data: data.map((v, i) => i),
  },
  yAxis: {
    show: false,
    type: 'value',
  },
  color: ['#4291ef'],
  series: [
    {
        name: 'Feitos',
        type: 'line',
        stack: 'tasks',
        symbol: 'circle',
        symbolSize: 0,
        smooth: true,
        // smoothMonotone: 'x',
        lineStyle: { width: 2 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: 'rgba(66, 145, 239, 0.6)' // color at 0% position
            }, {
              offset: 1, color: 'rgba(66, 145, 239, 0)' // color at 100% position
            }],
            global: false // false by default
          }
        },
        data,
    },
  ],
});
