function FormConfig({ success, setSaved }) {
  const successInputs = [];

  success.forEach((p, i) => {
    successInputs.push({
      name: `successList[${i}]`,
      type: 'text',
      size: 'small',
      onChange: (value, state) => {
        const newSuccess = [...success];
        newSuccess[i] = value;
        setSaved((s) => ({ ...s, success: newSuccess }));
        return state;
      },
    });
  });

  return [
    {
      name: 'success',
      type: 'text',
    },
    ...successInputs,
  ];
}

export default FormConfig;
