import _ from 'lodash';
import styles from './style';

function FormConfig({ cost, revenue, setCost, setRevenue }) {
  const classes = styles();

  const inputs = [];

  const createInputs = (prefix) => (item, i) => {
    inputs.push({
      name: `${prefix}[${i}].label`,
      type: 'text',
      className: classes.input,
      validation: { required: true },
    });
    if (!item.subitems || item.subitems.length === 0) {
      inputs.push({
        name: `${prefix}[${i}].amount`,
        type: 'text',
        inputType: 'number',
        className: classes.input,
        onChange: (amount, state) => {
          const calculateTotal = (local, first) => {
            if (local.indexOf('subitems') >= 0) {
              const path = local.slice(0, -9);
              let total = 0;
              const array = _.get(state, path);
              array.subitems.forEach((st, index) => {
                if (first && index === i) {
                  total += amount * st.value;
                  _.set(state, `${path}.subitems[${index}].total`, amount * st.value);
                } else total += st.total;
              });
              _.set(state, `${path}.total`, total);
              calculateTotal(path.slice(0, -3));
            } else if (first) {
              const obj = _.get(state, `${local}[${i}]`);
              obj.total = amount * obj.value;
              _.set(state, `${local}[${i}]`, obj);
            }
          };

          calculateTotal(prefix, true);

          if (prefix.indexOf('cost') >= 0) setCost(state.cost);
          else if (prefix.indexOf('revenue') >= 0) setRevenue(state.revenue);

          return state;
        },
        validation: { required: true },
      });

      inputs.push({
        name: `${prefix}[${i}].value`,
        type: 'currency',
        className: classes.input,
        onChange: (value, state) => {
          const calculateTotal = (local, first) => {
            if (local.indexOf('subitems') >= 0) {
              const path = local.slice(0, -9);
              let total = 0;
              const array = _.get(state, path);
              array.subitems.forEach((st, index) => {
                console.log(st);
                if (first && index === i) {
                  total += st.amount * value;
                  _.set(state, `${path}.subitems[${index}].total`, st.amount * value);
                } else total += st.total;
              });
              console.log(total);
              _.set(state, `${path}.total`, total);
              calculateTotal(path.slice(0, -3));
            } else if (first) {
              const obj = _.get(state, `${local}[${i}]`);
              obj.total = obj.amount * value;
              _.set(state, `${local}[${i}]`, obj);
            }
          };

          calculateTotal(prefix, true);

          if (prefix.indexOf('cost') >= 0) setCost(state.cost);
          else if (prefix.indexOf('revenue') >= 0) setRevenue(state.revenue);

          return state;
        },
        validation: { required: true },
      });
    } else {
      item.subitems.forEach(createInputs(`${prefix}[${i}].subitems`));
    }
  };

  cost.forEach(createInputs('cost'));
  revenue.forEach(createInputs('revenue'));

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Give a name for this scenario',
    },
    {
      name: 'budget',
      type: 'currency',
      label: 'What is the initial investment of the event?',
    },
    ...inputs,
  ];
}

export default FormConfig;
