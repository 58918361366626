import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Material components
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinkMaterial from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import useCategoryController from 'store/category/controller';
import CostCenter from 'store/financial/cost-center/controller';
import Contact from 'store/contact/controller';

// Components, Commons, Icons, Assets and Styles
import { ArrowLeft, Menu } from 'react-feather';
import { Financial } from 'assets/custom-icons/modules';
import logoVou from 'assets/logo.png';
import Sidebar from 'common/sidebar/maker';
import styles from './style';
import RenderIcons from './components/iconButtons';
import RenderTabs from './components/tabs';
import Snippet from './components/snippets';

function ToolbarCommon({
  position,
  logo,
  sidebar,
  back,
  title,
  shadow,
  tabs,
  icons,
  snippet,
  defaultIcons,
  avatar,
}) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const drawer = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    current: company, accounts, categories, costCenter, contacts,
  } = useSelector((store) => store.company);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  useEffect(() => {
    if (!accounts) dispatch(Account.index());
    if (!categories) Category.index();
    if (!costCenter) dispatch(CostCenter.index());
    if (!contacts) dispatch(Contact.index());
  }, [accounts, categories, costCenter, contacts, dispatch, Category]);

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Grid>
      <AppBar
        position={position}
        className={classes.appBar}
        style={{
          boxShadow: shadow,
          width: drawer || !sidebar ? '100%' : 'calc(100% - 130px)',
          height: drawer && tabs && tabs.find((t) => t.selected && t.subtabs) ? 100 : 'auto',
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            <Financial size={32} style={{ width: 32, height: 32, display: 'none' }} />
            {back && (
              <IconButton
                className={classes.backButton}
                color="primary"
                aria-label="back"
                onClick={() => history.goBack()}
              >
                <ArrowLeft />
              </IconButton>
            )}

            {sidebar && drawer && (
              <IconButton
                color="primary"
                aria-label="menu"
                className={classes.backButton}
                onClick={() => setOpenMenu(true)}
              >
                <Menu />
              </IconButton>
            )}

            {title && !drawer && (
              <Grid item>
                <Typography className={classes.title}>{title}</Typography>
                {tabs && (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ position: 'absolute', lineHeight: 1, color: '#777', margin: '0 -4px' }}
                  >
                    {tabs.map((tab, i) => (
                      <>
                        <Button
                          size="small"
                          disabled={tab.selected}
                          color={tab.selected ? 'primary' : 'secondary'}
                          style={tab.selected ? { color: '#313131', fontSize: 12 } : { fontSize: 12 }}
                          onClick={!tab.selected
                            ? tab.click || (() => history.push(tab.path))
                            : () => {}}
                        >
                          {tab.icon && <tab.icon size={16} style={{ marginRight: 8 }} />}
                          {tab.title}
                        </Button>
                        {i + 1 < tabs.length && (
                          <span style={{ padding: '0 12px', marginTop: -2 }}>›</span>
                        )}
                      </>
                    ))}
                  </Grid>
                )}
              </Grid>
            )}

            {logo && !title && (
              <Link to="/events">
                <img src={logoVou} alt="Logo Vou" className={classes.logo} />
              </Link>
            )}

            {tabs && !title && <RenderTabs tabs={tabs} />}
          </Grid>

          {title && !logo && drawer && (
            <>
              <Typography className={classes.title}>{title}</Typography>
              {tabs && (
                <ButtonGroup fullWidth size="small" variant="outlined" className={classes.subtabsContainer}>
                  {tabs.map((tab) => (
                    <Button
                      key={tab.id}
                      color={tab.selected ? 'secondary' : 'primary'}
                      className={`${classes.subbutton} ${tab.selected ? classes.subbuttonDisabled : ''}`}
                      onClick={!tab.selected ? tab.click || (() => history.push(tab.path)) : null}
                    >
                      {tab.title}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </>
          )}

          {snippet && !drawer && <Snippet />}

          {logo && !tabs && title && (
            <Link to="/events">
              <img src={logoVou} alt="Logo Vou" className={classes.logo} />
            </Link>
          )}

          <Grid container alignItems="center" justify="flex-end">
            <RenderIcons avatar={avatar} icons={icons} defaultIcons={defaultIcons} />
          </Grid>
        </Toolbar>
      </AppBar>

      {sidebar && (
        <Sidebar open={openMenu} onClose={() => setOpenMenu(false)} actived={sidebar} />
      )}
    </Grid>
  );
}

ToolbarCommon.propTypes = {
  position: PropTypes.string,
  logo: PropTypes.bool,
  sidebar: PropTypes.string,
  back: PropTypes.bool,
  title: PropTypes.string,
  shadow: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  icons: PropTypes.arrayOf(PropTypes.object),
  snippet: PropTypes.bool,
  defaultIcons: PropTypes.bool,
  avatar: PropTypes.bool,
};

ToolbarCommon.defaultProps = {
  position: 'fixed',
  logo: false,
  sidebar: null,
  back: false,
  title: null,
  shadow: '0px 12px 12px #FFFFFF',
  tabs: null,
  icons: [],
  snippet: true,
  defaultIcons: true,
  avatar: false,
};

export default ToolbarCommon;
