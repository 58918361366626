/**
 * Types
 */
const Types = {
  LOAD_COMPANY: '@company/LOAD_COMPANY',
  // Geral
  LOAD_EVENTS: '@company/LOAD_EVENTS',
  LOAD_USERS: '@company/LOAD_USERS',
  LOAD_CONTACTS: '@company/LOAD_CONTACTS',
  LOAD_CATEGORIES: '@company/LOAD_CATEGORIES',
  LOAD_LOGS: '@company/LOAD_LOGS',
  LOAD_CHANNELS: '@company/LOAD_CHANNELS',
  LOAD_MESSAGES: '@company/LOAD_MESSAGES',
  LOAD_UNREADS: '@company/LOAD_UNREADS',
  // Productivity
  LOAD_TASKS: '@company/LOAD_TASKS',
  LOAD_TASKS_BOARDS: '@company/LOAD_TASKS_BOARDS',
  LOAD_TASKS_LABELS: '@company/LOAD_TASKS_LABELS',
  LOAD_TASKS_STEPS: '@company/LOAD_TASKS_STEPS',
  // Financial
  LOAD_ACCOUNTS: '@company/LOAD_ACCOUNTS',
  LOAD_CASH_FLOW: '@company/LOAD_CASH_FLOW',
  LOAD_COST_CENTER: '@company/LOAD_COST_CENTER',
  // Suppliers Module
  LOAD_SUPPLIERS: '@company/LOAD_SUPPLIERS',
  LOAD_PRODUCTS: '@company/LOAD_PRODUCTS',

  CLEAR_EVENT: '@company/CLEAR_EVENT',
  CLEAR_COMPANY: '@company/CLEAR_COMPANY',
};

/**
 * Actions
 */
export const Actions = {
  loadCompany: (current) => ({ type: Types.LOAD_COMPANY, payload: { current } }),

  // Geral
  loadEvents: (events) => ({ type: Types.LOAD_EVENTS, payload: { events } }),
  loadUsers: (users) => ({ type: Types.LOAD_USERS, payload: { users } }),
  loadCategories: (categories) => ({ type: Types.LOAD_CATEGORIES, payload: { categories } }),
  loadContacts: (contacts) => ({ type: Types.LOAD_CONTACTS, payload: { contacts } }),
  loadLogs: (logs) => ({ type: Types.LOAD_LOGS, payload: { logs } }),
  loadChannels: (channels) => ({ type: Types.LOAD_CHANNELS, payload: { channels } }),
  loadMessages: (channel, messages) => ({
    type: Types.LOAD_MESSAGES,
    payload: { channel, messages },
  }),
  loadUnreads: (channel, unreads) => ({
    type: Types.LOAD_UNREADS,
    payload: { channel, unreads },
  }),

  // Productivity
  loadTasks: (tasks) => ({ type: Types.LOAD_TASKS, payload: { tasks } }),
  loadBoards: (boards) => ({ type: Types.LOAD_TASKS_BOARDS, payload: { boards } }),
  loadLabels: (labels) => ({ type: Types.LOAD_TASKS_LABELS, payload: { labels } }),
  loadSteps: (steps) => ({ type: Types.LOAD_TASKS_STEPS, payload: { steps } }),

  // Financial
  loadAccounts: (accounts) => ({ type: Types.LOAD_ACCOUNTS, payload: { accounts } }),
  loadCashFlow: (cashFlow) => ({ type: Types.LOAD_CASH_FLOW, payload: { cashFlow } }),
  loadCostCenter: (costCenter) => ({ type: Types.LOAD_COST_CENTER, payload: { costCenter } }),

  // Suppliers Module
  loadSuppliers: (suppliers) => ({ type: Types.LOAD_SUPPLIERS, payload: { suppliers } }),
  loadProducts: (products) => ({ type: Types.LOAD_PRODUCTS, payload: { products } }),

  clearEvent: () => ({ type: Types.CLEAR_EVENT }),
  clearCompany: () => ({ type: Types.CLEAR_COMPANY }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  // Geral
  events: null,
  users: null,
  categories: null,
  contacts: null,
  logs: null,
  channels: null,
  // Productivity
  tasks: null,
  boards: null,
  labels: null,
  steps: null,
  // Financial
  accounts: null,
  cashFlow: null,
  costCenter: null,
  // Suppliers module
  suppliers: null,
  products: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD_COMPANY: {
      const { current } = action.payload;
      return { ...state, current };
    }

    // Geral
    case Types.LOAD_EVENTS: {
      const { events } = action.payload;
      return { ...state, events };
    }
    case Types.LOAD_USERS: {
      const { users } = action.payload;
      return { ...state, users };
    }
    case Types.LOAD_CATEGORIES: {
      const { categories } = action.payload;
      return { ...state, categories };
    }
    case Types.LOAD_CONTACTS: {
      const { contacts } = action.payload;
      return { ...state, contacts };
    }
    case Types.LOAD_LOGS: {
      const { logs } = action.payload;
      return { ...state, logs };
    }
    case Types.LOAD_CHANNELS: {
      const { channels } = action.payload;
      return { ...state, channels };
    }
    case Types.LOAD_MESSAGES: {
      const { channel: id, messages } = action.payload;
      const { channels } = state;

      const channel = channels.find((c) => c.id === id);
      if (channel) {
        const index = channels.indexOf(channel);
        channels[index].messages = messages;
        [channels[index].lastMessage] = messages;
      }

      return { ...state, channels };
    }
    case Types.LOAD_UNREADS: {
      const { channel: id, unreads } = action.payload;
      const { channels } = state;

      const channel = channels.find((c) => c.id === id);
      if (channel) {
        const index = channels.indexOf(channel);
        channels[index].unreads = unreads;
      }

      return { ...state, channels };
    }

    // Productivity
    case Types.LOAD_TASKS: {
      const { tasks } = action.payload;
      return { ...state, tasks };
    }
    case Types.LOAD_TASKS_BOARDS: {
      const { boards } = action.payload;
      return { ...state, boards };
    }
    case Types.LOAD_TASKS_LABELS: {
      const { labels } = action.payload;
      return { ...state, labels };
    }
    case Types.LOAD_TASKS_STEPS: {
      const { steps } = action.payload;
      return { ...state, steps };
    }

    // Financial
    case Types.LOAD_ACCOUNTS: {
      const { accounts } = action.payload;
      return { ...state, accounts };
    }
    case Types.LOAD_CASH_FLOW: {
      const { cashFlow } = action.payload;
      return { ...state, cashFlow };
    }
    case Types.LOAD_COST_CENTER: {
      const { costCenter } = action.payload;
      return { ...state, costCenter };
    }

    // Suppliers Module
    case Types.LOAD_SUPPLIERS: {
      const { suppliers } = action.payload;
      return { ...state, suppliers };
    }
    case Types.LOAD_PRODUCTS: {
      const { products } = action.payload;
      return { ...state, products };
    }
    case Types.CLEAR_EVENT: {
      return {
        ...state, products: null, suppliers: null, cashFlow: null,
      };
    }
    case Types.CLEAR_COMPANY: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
