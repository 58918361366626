import React, { useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useDispatch } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

// Controllers
import Supplier from 'store/supplier/controller';

// Icons and Commons
import { X } from 'react-feather';

const styles = makeStyles((theme) => ({
  dialogContent: {
    overflow: 'initial',
  },
  list: {
    marginTop: theme.spacing(),
  },
  listAdd: {
    background: lighten(theme.palette.secondary.light, 0.65),
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  listInput: {
    padding: theme.spacing(0.5),
  },
  listButton: {
    padding: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  actions: {
    justifyContent: 'center',
  },
}));

function RegisterEmail({ supplier, onClose }) {
  const classes = styles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const submit = async () => {
    let isValid = true;

    if (email === '') {
      setError('Enter an e-mail to request registration.');
      isValid = false;
    } else if (!validator.isEmail(email.trim())) {
      setError('Must be a valid email');
      isValid = false;
    }

    if (isValid) {
      dispatch(Supplier.update(supplier, { contact: { email } }));
      onClose();
    }
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      aria-labelledby="view-attachments-settings-dialog"
      onClose={onClose}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>E-mail</span>
          <IconButton onClick={onClose} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          fullWidth
          error={!!error}
          variant="outlined"
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" className={classes.buttonDestroy} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" className={classes.button} onClick={submit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RegisterEmail.propTypes = {
  supplier: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegisterEmail;
