import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { Edit2, Key } from 'react-feather';
import Toolbar from 'common/toolbar';
import * as Forms from './forms';
import UploadAvatar from './avatar';
import styles from './style';

function Profile() {
  const classes = styles();
  // const history = useHistory();

  const { current: user } = useSelector((store) => store.user);

  const [edit, setEdit] = useState(null);
  const [changePassword, setChangePassword] = useState(false);

  return (
    <Grid container>
      <Toolbar logo back title="Profile" snippet={false} defaultIcons={false} />
      <Grid item xs={12} sm={10} md={8} className={classes.container}>
        <Grid container>
          <Grid container alignItems="center" wrap="nowrap" className={classes.profile}>
            <Grid item style={{ position: 'relative' }}>
              <Avatar
                alt={user.name}
                src={user.avatar || null}
                className={classes.avatar}
              >
                {user.initials}
              </Avatar>
              <UploadAvatar />
            </Grid>
            <Grid container className={classes.infos}>
              {edit === 'infos' ? (
                <Forms.EditInfos onClose={() => setEdit(null)} />
              ) : (
                <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="h2" className={classes.name}>
                      {user.name}
                    </Typography>
                    <Typography variant="h4" className={classes.office}>
                      {user.office || 'Define your position in the team...'}
                    </Typography>
                  </Grid>
                  <IconButton color="primary" onClick={() => setEdit('infos')}>
                    <Edit2 />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.sensitive}>
            <Grid item xs={12} sm={6}>
              <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                {edit === 'email' ? (
                  <Forms.EditEmail onClose={() => setEdit(null)} />
                ) : (
                  <>
                    <Grid item>
                      <Typography variant="overline" className={classes.title}>
                        E-mail
                      </Typography>
                      <Typography className={classes.text}>
                        {user.email}
                      </Typography>
                      <FormHelperText>
                      You are going to use this e-mail to log in the platform.
                      </FormHelperText>
                    </Grid>
                    <Grid item>
                      <IconButton color="primary" onClick={() => setEdit('email')}>
                        <Edit2 size={18} />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              justify="flex-end"
              alignItems="center"
              className={classes.changePassword}
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.btnPassword}
                onClick={() => setChangePassword(true)}
              >
                <Key size={18} style={{ marginRight: 16 }} />
                Change password
              </Button>

              {changePassword && <Forms.EditPassword onClose={() => setChangePassword(false)} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Profile;
