import firebase, {
  firestore, functions, fireauth, ERRORS,
} from 'services/firebase';
import Mixpanel from 'services/mixpanel';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';
import { Actions } from './reducer';

const User = {
  index: (companyId) => (dispatch, getState) => {
    const { company } = getState().company;

    firestore.collection('users')
      .where('company', '==', companyId || company.id)
      .onSnapshot((usrs) => {
        const users = usrs.docs.map((user) => ({ id: user.id, ...user.data() }));
        dispatch(Company.loadUsers(users));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  show: (uid) => (dispatch) => {
    Mixpanel.identify(uid);
    firestore.collection('users').doc(uid).onSnapshot((user) => {
      const userData = user.data();
      if (userData) {
        Mixpanel.people.set({
          $name: userData.name,
          $email: userData.email,
          $company: userData.company,
        });
        window.OneSignal.sendTag('uid', uid);
        dispatch(Actions.load({ id: user.id, ...userData }));
      } else {
        dispatch(Actions.load(null));
      }
    }, (error) => {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    });
  },

  store: ({ user, company }) => (dispatch) => {
    dispatch(Actions.store({ creating: true }));

    const storeUser = functions.httpsCallable('user-store');

    storeUser({ user, company })
      .then(() => {
        Mixpanel.track('Create an user');
        dispatch(Alert.show({ message: 'Contributor successfully added.', type: 'success' }));
        dispatch(Actions.store({ creating: false }));
      })
      .catch((error) => {
        const message = ERRORS[error.code] || error.message;
        dispatch(Alert.show({ message }));
        dispatch(Actions.store({ creating: false, error: message }));
      });
  },

  update: (data, userId) => (dispatch, getState) => {
    const now = new Date(Date.now());

    const { current: user } = getState().user;

    firestore.collection('users').doc(userId || user.id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update an user');
        dispatch(Alert.show({ message: 'Information updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  updateEmail: (email) => (dispatch) => {
    const now = new Date(Date.now());

    const user = firebase.auth().currentUser;

    user.updateEmail(email)
      .then(() => {
        firestore.collection('users').doc(user.uid)
          .set({ email, updatedAt: now }, { merge: true })
          .then(() => {
            Mixpanel.track('Update an user e-mail');
            dispatch(Alert.show({ message: 'E-mail updated successfully.', type: 'success' }));
          })
          .catch((error) => {
            dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
          });
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  updatePassword: ({ currentPassword, newPassword }) => (dispatch) => {
    const user = firebase.auth().currentUser;

    fireauth.signInWithEmailAndPassword(user.email, currentPassword)
      .then(() => {
        user.updatePassword(newPassword)
          .then(() => {
            Mixpanel.track('Update an user password');
            dispatch(Alert.show({ message: 'Password updated successfully.', type: 'success' }));
          })
          .catch((error) => {
            dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
          });
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (uid) => (dispatch) => {
    const now = new Date(Date.now());

    firestore.collection('users').doc(uid)
      .set({ deletedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Delete an user');
        dispatch(Alert.show({ message: 'Collaborator successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default User;
