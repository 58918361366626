import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
// import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Commons and Styles
import { Edit2, Check, X } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import { Typography } from '@material-ui/core';
import styles from './style';

function DesktopTable({ data }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const isFiltered = location.search !== '';
  const { eventId } = useParams();

  const { contacts, suppliers, cashFlow } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);
  // const [selected, setSelected] = useState([]);
  // const [selectedType, setSelectedType] = useState('');

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  const confirmPayment = (account) => {
    dispatch(Log.store(`Confirmed an ${account.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(account.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === account.id) match = true;
        });
      }
      return match;
    });

    if (supplier) {
      const lastCash = supplier.payment.installments.filter((i) => {
        const cf = cashFlow.find((c) => c.id === i.cashFlow);
        return cf && !cf.completed.on;
      }).length === 1;
      if (lastCash) dispatch(Supplier.update(supplier.id, { status: 4 }));
    }

    dispatch(CashFlow.update(account.id, {
      completed: { ...account.completed, status: null, on: true },
    }));
  };

  const openPayment = (account) => {
    dispatch(Log.store(`Reopened an ${account.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(account.value)
    }`));
    dispatch(CashFlow.update(account.id, {
      completed: { ...account.completed, status: null, on: false },
    }));
  };

  // const accountsSelectedTypes = payableReceivables.filter(
  //   (p) => p.type === selectedType && !p.completed.status,
  // );

  return (
    <Table aria-labelledby="products">
      <TableHead>
        <TableRow>
          {/* <TableCell classes={{ root: classes.tableHeader }} style={{ width: 16 }}>
            {selected.length > 0 && (
              <Checkbox
                indeterminate={selected.length < accountsSelectedTypes.length}
                checked={selected.length === accountsSelectedTypes.length}
                style={{ margin: '-4px 0' }}
                onChange={() => {
                  if (selected.length < accountsSelectedTypes.length) {
                    setSelected(accountsSelectedTypes.map((p) => p.id));
                  } else {
                    setSelected([]);
                    setSelectedType('');
                  }
                }}
              />
            )}
          </TableCell> */}
          <TableCell classes={{ root: classes.tableHeader }} style={{ width: 16 }} />
          <TableCell classes={{ root: classes.tableHeader }} align="center">
            Date
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }} align="center">
            Category
          </TableCell>
          <TableCell classes={{ root: classes.tableHeader }}>Payee / Payer</TableCell>
          <TableCell classes={{ root: classes.tableHeader }}>Objects</TableCell>
          <TableCell classes={{ root: classes.tableHeader }} align="right">
            Value
          </TableCell>
          {permissionEdit && (
            <TableCell classes={{ root: classes.tableHeader }} align="center" style={{ width: 150 }}>
              {/* {selected.length > 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={selectedType === 'in' ? classes.buttonReceive : classes.buttonPay}
                  onClick={selectedType === 'out'
                    ? () => history.push(
                        `/event/${event}/financial/pay?ids=${selected.join(',')}`,
                      )
                    : () => {}}
                >
                  {selectedType === 'in' ? 'Receber Todos' : 'Pagar Todos'}
                </Button>
              )} */}
            </TableCell>
          )}
          <TableCell classes={{ root: classes.tableHeader }} style={{ width: 16 }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((account) => {
          // const isSelected = selected.indexOf(account.id) >= 0;
          const contact = contacts.find((c) => c.id === account.contact);

          let status = false;

          if (account.completed.status === 'WAITING_RECEIVEMENT') {
            status = 'Waiting payment';
          }
          if (account.completed.status === 'RECEIVED') {
            status = 'Making payments';
          }
          if (account.completed.status === 'WAITING_CONFIRMATION') {
            status = (
              <Grid container wrap="nowrap" alignItems="center" justify="center">
                <Typography
                  style={{
                    fontSize: 10, textAlign: 'center', lineHeight: 1.2,
                  }}
                >
                  Waiting supplier confirmation
                </Typography>
                <Tooltip title="Supplier confirmed">
                  <Button
                    variant="outlined"
                    className={classes.buttonGreen}
                    disabled={!suppliers}
                    onClick={(e) => {
                      e.stopPropagation();
                      confirmPayment(account);
                    }}
                  >
                    <Check size={16} />
                  </Button>
                </Tooltip>
                <Tooltip title="Reopen parcel">
                  <Button
                    variant="outlined"
                    className={classes.buttonRed}
                    disabled={!suppliers}
                    onClick={(e) => {
                      e.stopPropagation();
                      openPayment(account);
                    }}
                  >
                    <X size={16} />
                  </Button>
                </Tooltip>
              </Grid>
            );
          }

          return (
            <TableRow
              hover
              key={account.id}
              role="checkbox"
              onClick={() => history.push(`/manager/${eventId}/financial/cash-flow/${account.id}`)}
              style={{ cursor: 'pointer' }}
              // selected={isSelected}
            >
              {/* <TableCell
                className={classes.tableCell}
                style={{ lineHeight: '10px' }}
                align="center"
              >
                <Checkbox
                  disabled={(selectedType !== '' && selectedType !== account.type)
                    || !!status}
                  checked={isSelected}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const newSelected = [...selected];

                    if (e.target.checked) {
                      newSelected.push(account.id);
                      if (selectedType === '') setSelectedType(account.type);
                    } else {
                      const index = newSelected.indexOf(account.id);
                      newSelected.splice(index, 1);
                      if (newSelected.length === 0) setSelectedType('');
                    }

                    setSelected(newSelected);
                  }}
                />
              </TableCell> */}
              <TableCell
                className={classes.tableCell}
                style={{ lineHeight: '10px' }}
                align="center"
              >
                {account.type === 'in' ? (
                  <Typography className={classes.typeReceive}>Ⓔ</Typography>
                ) : (
                  <Typography className={classes.typePay}>Ⓢ</Typography>
                )}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ fontWeight: 600, fontSize: 16 }}
                align="center"
              >
                {account.dueAt.toDate() <= new Date() ? (
                  <Tooltip
                    title={`${account.type === 'in' ? 'Receivement' : 'Payment'} late`}
                  >
                    <span className={classes.colorError}>
                      {format(account.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                    </span>
                  </Tooltip>
                ) : (
                  format(account.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })
                )}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  wrap="nowrap"
                  direction={account.categories.length === 1 ? 'column' : 'row'}
                >
                  {account.categories.map((category) => category && (
                    <div className={classes.category} key={category.id}>
                      <Tooltip title={category.name}>
                        <div
                          className={classes.categoryColors}
                          style={{ background: category.color }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </Grid>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="left"
                style={{ whiteSpace: 'normal' }}
              >
                {contact ? contact.name : 'Not defined'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="left"
                style={{ whiteSpace: 'normal' }}
              >
                {account.description}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ fontWeight: 600, fontSize: 16 }}
                align="right"
              >
                {account.installments ? (
                  <Badge
                    color="primary"
                    className={classes.badge}
                    badgeContent={`${
                      account.installments.indexOf(null) + 1
                    }/${account.installments.length}`}
                  >
                    {numberToReal(account.value)}
                  </Badge>
                ) : numberToReal(account.value)}
              </TableCell>
              {permissionEdit ? (
                <>
                  <TableCell align="center" className={classes.tableCell} style={{ whiteSpace: 'normal' }}>
                    {!status ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        // disabled={
                        //   (selected.length === 1 && selected[0] !== account.id)
                        //   || selected.length > 1
                        // }
                        className={account.type === 'in' ? classes.buttonReceive : classes.buttonPay}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `/manager/${eventId}/financial/${
                              account.type === 'in' ? 'receive' : 'pay'
                            }/${account.id}`,
                          );
                        }}
                      >
                        {account.type === 'in' ? 'Receive' : 'Pay'}
                      </Button>
                    ) : (
                      <Typography style={{ lineHeight: 1.5 }} variant="overline">
                        {status}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <IconButton
                      color="primary"
                      disabled={!!account.completed.status}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/manager/${eventId}/financial/cash-flow/${account.id}/edit`);
                      }}
                    >
                      <Edit2 size={16} />
                    </IconButton>
                  </TableCell>
                </>
              ) : <TableCell className={classes.tableCell} />}
            </TableRow>
          );
        })}
        {data.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} className={classes.noData}>
              <Typography variant="h5">
                {!isFiltered
                  ? 'Start registering your accounts payable and receivable'
                  : 'No pending accounts found with this filter'}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

DesktopTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DesktopTable;
