/**
 * Types
 */
const Types = {
  SHOW_EVENT: '@suppliers/SHOW_EVENT',
  SHOW_REGISTER: '@suppliers/SHOW_REGISTER',
  ERROR: '@suppliers/ERROR',
  CLEAR: '@suppliers/CLEAR',
};

/**
 * Actions
 */
export const Actions = {
  showEvent: (current) => ({ type: Types.SHOW_EVENT, payload: { current } }),
  showRegister: (register) => ({ type: Types.SHOW_REGISTER, payload: { register } }),
  setError: (error) => ({ type: Types.ERROR, payload: { error } }),
  clear: () => ({ type: Types.CLEAR }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  current: null,
  register: null,
  error: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SHOW_EVENT: {
      const { current } = action.payload;
      return { ...state, current, error: null };
    }
    case Types.SHOW_REGISTER: {
      const { register } = action.payload;
      return { ...state, register, error: null };
    }
    case Types.ERROR: {
      const { error } = action.payload;
      return { ...state, error };
    }
    case Types.CLEAR: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
