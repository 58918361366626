import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import styles from './style';

function Labels({ id, name, labels }) {
  const classes = styles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const { eventId } = useParams();

  const { labels: allLabels } = useSelector((store) => store.company);

  const [openMenu, setOpenMenu] = useState(null);

  const add = (lId) => {
    setOpenMenu(null);
    dispatch(Log.store(`Changed task tags ${name}`));
    dispatch(Task.update(id, { labels: [...labels.map((l) => l.id), lId] }));
  };

  const baseUrl = (url) => {
    let base = `/planner/${eventId}/productivity`;
    if (history.location.pathname.includes('kanban')) base += '/kanban';
    return base + url;
  };

  return (
    <Grid container alignItems="center">
      {labels.map((label) => (
        <div
          key={label.id}
          className={classes.label}
          style={{ background: label.color, color: theme.palette.getContrastText(label.color) }}
        >
          <p>{label.name}</p>
        </div>
      ))}

      <Menu open={!!openMenu} anchorEl={openMenu} onClose={() => setOpenMenu(null)}>
        <MenuItem
          className={classes.add}
          onClick={() => history.push(baseUrl('/labels/new'))}
        >
          Add a new tag
        </MenuItem>
        {allLabels.filter((al) => !labels.find((l) => l.id === al.id)).map((label) => (
          <MenuItem key={label.id} onClick={() => add(label.id)}>
            <div className={classes.labelColor} style={{ background: label.color }} />
            {label.name}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}

Labels.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Labels;
