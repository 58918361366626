import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  // Upload files
  uploadFile: {
    background: lighten(theme.palette.secondary.main, 0.9),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.common.black,
    cursor: 'pointer',
    flexWrap: 'nowrap',
  },
  uploadFileConfirm: {
    background: lighten(theme.palette.success.main, 0.9),
    border: `1px solid ${theme.palette.success.main}`,
  },
  checkedFile: {
    color: theme.palette.success.main,
  },
  buttonUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
