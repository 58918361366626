import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import createImporter from 'services/importer';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import useCategoryController from 'store/category/controller';

// Icons, Commons and Styles
import { Plus, LogIn } from 'react-feather';
import Loading from 'common/loading';
import Product from 'store/supplier/product/controller';
import CategoryList from './list';
import styles from './style';

function Settings() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    current: company, products, cashFlow, categories,
  } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  const permissionProcurement = !permissions || !permissions.manager
    || permissions.manager.procurement.edit;
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const permissionEdit = permissionProcurement || permissionFinancial;

  const [importer, setImporter] = useState(null);

  useEffect(() => {
    if (!products) dispatch(Product.indexCompany());
  }, [products, dispatch]);

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Category name',
          key: 'name',
          isRequired: true,
        },
        {
          label: 'Tipo',
          key: 'type',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^(Revenue|Expense)$/,
            error: 'Use only "Revenue" or "Expense"',
          }],
        },
        {
          label: 'Cor',
          key: 'color',
          validators: [{
            validate: 'regex_matches',
            regex: /^#([0-9a-f]{3}){1,2}$/i,
            error: 'Set the color to hexadecimal (Ex: #000000)',
          }],
        },
        {
          label: 'Subcategory of',
          key: 'parent',
        },
      ],
    }));
  }, []);

  const importCategories = () => {
    const randomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    importer.requestDataFromUser().then(async ({ data }) => {
      const createdCategories = [];
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];

        let parent = categories.find(
          (c) => c.name.toLowerCase() === item.parent.toLowerCase(),
        );
        if (!parent) {
          parent = createdCategories.find(
            (c) => c.name.toLowerCase() === item.parent.toLowerCase(),
          );
        }

        const categoryData = {
          parent: parent ? parent.id : null,
          name: item.name,
          type: item.type === 'Revenue' ? 'in' : 'out',
          color: item.color || randomColor(),
        };

        // eslint-disable-next-line no-await-in-loop
        const categoryId = await Category.store(categoryData);
        createdCategories.push({ id: categoryId, name: item.name });
      }

      importer.displaySuccess('All data imported!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
  };

  const isLoaded = !!products && !!cashFlow;

  const types = [
    {
      value: 'in',
      label: 'Revenue',
      color: '#4bb75c',
    },
    {
      value: 'out',
      label: 'Expenses',
      color: '#dc1f26',
    },
    // {
    //   value: 'mix',
    //   label: 'Misto',
    //   color: '#586eba',
    // },
  ];

  return (
    <Grid container spacing={1}>
      {isLoaded ? types.map((type) => (
        <Grid item xs={12} key={type.value}>
          <Grid container justify="space-between" alignItems="center">
            <Typography
              className={classes.title}
              style={{ color: type.color }}
              id={type.value}
            >
              {type.label}
            </Typography>
            {permissionEdit && (
              <Button
                variant="outlined"
                className={classes.add}
                color="secondary"
                onClick={() => history.push(`/register/categories/new?type=${type.value}`)}
              >
                <Plus style={{ strokeWidth: 2.5 }} />
                <p>New category</p>
              </Button>
            )}
          </Grid>
          <CategoryList type={type.value} />
        </Grid>
      )) : (
        <Loading ready={isLoaded} content={150} />
      )}
      {permissionEdit && (
        <Grid container justify="center" style={{ marginTop: 16 }}>
          <Button variant="outlined" color="primary" onClick={importCategories} disabled={!isLoaded}>
            <LogIn style={{ transform: 'rotate(180deg)', marginRight: 8 }} size={20} />
            Import category
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default Settings;
