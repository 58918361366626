function FormConfig() {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'Event Name',
      validation: { required: true },
    },
    {
      name: 'dates.start',
      type: 'datetime',
      label: 'Start date',
      keyboard: true,
      showTime: true,
      validation: { required: true },
    },
    {
      name: 'dates.end',
      type: 'datetime',
      label: 'End date',
      keyboard: true,
      showTime: true,
      validation: { required: true },
    },
    {
      name: 'creating.idealization.site',
      type: 'text',
      label: 'Website event',
      validation: { url: true },
    },
    {
      name: 'creating.format.type',
      type: 'text',
      label: 'What will be the format of the event?',
      placeholder: 'Ex: Webinar, Seminar, Open discussion, Meetup, etc.',
      validation: { required: true },
    },
  ];
}

export default FormConfig;
