import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Timestamp } from 'services/firebase';
import { addMonths } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Components and Styles
import { Check, X } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import { getCurrentTotal, createCashFlow } from 'common/utils/suppliers';
import { installmentWithCashFlow } from 'common/utils/relationships';
import Tooltip from 'common/tooltip';
import PaymentTypeInfos from './typeInfos';
import PaymentMethods from './method';
import PaymentInstallments from './installments';
import styles from './style';

function ExpansionPayment({ supplier, edit, setEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { cashFlow } = useSelector((store) => store.company);

  const [payment, setPayment] = useState({
    type: '',
    method: '',
    installments: [{ value: 0, due: Timestamp.fromDate(addMonths(new Date(), 1)) }],
    total: 0,
    discount: { type: 'money', value: 0 },
  });
  const [enableConfirm, setEnableConfirm] = useState(false);

  const forecast = supplier.products
    ? supplier.products.reduce((c, p) => c + p.valueForecast, 0)
    : 0;
  const total = getCurrentTotal({ budget: supplier.budget, payment });
  const subTotal = getCurrentTotal(
    { budget: supplier.budget, payment },
    { subTotal: true, withoutDiscount: 'payment' },
  );

  const reload = useCallback(() => {
    if (supplier.payment && supplier.payment.total) {
      setPayment({
        ...supplier.payment,
        installments: supplier.payment.installments.map(
          (i) => installmentWithCashFlow(i, cashFlow),
        ),
      });
    }
  }, [supplier.payment, cashFlow]);

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    let isValid = true;

    if (payment.type === '') isValid = false;
    if (payment.method === '') isValid = false;
    payment.installments.forEach((i) => {
      if (i.value < 0 || !i.due) isValid = false;
      if (i.due && i.due.toDate() <= new Date()) isValid = false;
    });

    setEnableConfirm(isValid);
  }, [payment]);

  const submit = () => {
    payment.total = getCurrentTotal({ budget: supplier.budget, payment });
    const installments = payment.installments.map((i) => ({
      ...i, cashFlow: i.cashFlow ? i.cashFlow.id : null,
    }));

    if (supplier.status === 3) {
      // Delete cashFlows
      supplier.payment.installments.forEach((i) => {
        const cash = cashFlow.find((c) => c.id === i.cashFlow);
        if (cash && !cash.completed.on && !cash.completed.status) {
          dispatch(CashFlow.destroy(cash.id));
        }
      });

      // Update cashFlows
      const cashFlows = createCashFlow({ ...supplier, payment });
      const newSupplier = { ...supplier, payment: { ...payment, installments } };
      installments.forEach((i, index) => {
        const cash = cashFlow.find((c) => c.id === i.cashFlow);
        if (!cash || (!cash.completed.on && !cash.completed.status)) {
          dispatch(CashFlow.storeSupplier(
            newSupplier, index, cashFlows[index], supplier.status,
          ));
        }
      });
    }

    dispatch(Log.store(`Changed the payment of supplier ${supplier.name}`));
    dispatch(Supplier.update(supplier.id, { payment: { ...payment, installments } }));
    setEdit(false);
  };

  if ((supplier.payment && supplier.payment.total) || edit) {
    return (
      <Grid container justify="center">
        <Grid container spacing={1}>
          <PaymentTypeInfos
            edit={edit}
            total={subTotal}
            payment={payment}
            onChange={setPayment}
          />
          <PaymentMethods edit={edit} payment={payment} onChange={setPayment} />
        </Grid>

        <Grid item xs={12} className={classes.dividerText}>
          <Typography gutterBottom variant="overline">
            PARCELS
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <Grid container spacing={1}>
          <PaymentInstallments
            edit={edit}
            subTotal={subTotal}
            payment={payment}
            status={supplier.status}
            onChange={setPayment}
          />
        </Grid>

        {supplier.status === 3
          && !!payment.installments.find((i) => i.cashFlow && i.cashFlow.completed.on)
          && (
            <Grid container alignItems="center" style={{ marginTop: 32 }}>
              <Grid container item xs={12} sm={4} alignItems="center" direction="column">
                <Typography className={classes.titleTotalInstallments}>
                  PAY
                </Typography>
                <Typography className={classes.totalInstallments}>
                  {numberToReal(
                    payment.installments.reduce(
                      (c, i) => (i.cashFlow && i.cashFlow.completed.on ? c + i.value : c), 0,
                    ) || 0,
                  )}
                </Typography>
              </Grid>

              <Grid container item xs={12} sm={4} alignItems="center" direction="column">
                <Typography className={classes.titleTotalInstallments}>
                REMAINING
                </Typography>
                <Typography className={classes.totalInstallments}>
                  {numberToReal(payment.installments.reduce(
                    (c, i) => (!i.cashFlow || !i.cashFlow.completed.on) && c + i.value, 0,
                  ) || 0)}
                </Typography>
              </Grid>

              <Grid container item xs={12} sm={4} alignItems="center" direction="column">
                <Typography className={classes.titleTotalInstallments}>
                  TOTAL
                </Typography>
                <Tooltip
                  title={`${total > forecast ? 'Over' : 'Within'} forecast value of ${numberToReal(
                    forecast,
                  )}`}
                >
                  <Typography
                    className={`${classes.totalInstallments} ${
                      total > forecast ? classes.colorErrorTotal : classes.colorSuccessTotal
                    }`}
                  >
                    {numberToReal(total)}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          )}

        {(supplier.status !== 3
          || !payment.installments.find((i) => i.cashFlow && i.cashFlow.completed.on))
          && (
          <Grid container item xs={12} alignItems="center" direction="column">
            <Typography className={classes.titleTotal}>
              {supplier.status === 4 ? 'TOTAL PAID' : 'TOTAL TO PAY'}
            </Typography>
            <Tooltip
              title={`${total > forecast ? 'Over' : 'Within'} forecast value of ${numberToReal(
                forecast,
              )}`}
            >
              <Typography
                className={`${classes.total} ${
                  total > forecast ? classes.colorErrorTotal : classes.colorSuccessTotal
                }`}
              >
                {numberToReal(total)}
              </Typography>
            </Tooltip>
          </Grid>
          )}

        {edit && (
          <Grid container justify="center" className={classes.containerBtns}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                reload();
                setEdit(false);
              }}
              className={classes.buttonCancel}
            >
              <X size={16} style={{ marginRight: 8 }} />
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={submit}
              className={classes.buttonConfirm}
              disabled={!enableConfirm}
            >
              <Check size={16} style={{ marginRight: 8 }} />
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container justify="center">
      <Typography className={classes.noData}>
        No payment methods negotiated yet
      </Typography>
    </Grid>
  );
}

ExpansionPayment.propTypes = {
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    budget: PropTypes.object,
    payment: PropTypes.object,
    products: PropTypes.array,
    status: PropTypes.number,
  }).isRequired,
  setEdit: PropTypes.func.isRequired,
};

export default ExpansionPayment;
