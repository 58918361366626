import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1.25),
  },
  actions: {
    justifyContent: 'center',
  },
  colors: {
    marginTop: theme.spacing(),
    overflow: 'hidden',
    '& > div': {
      justifyContent: 'center',
      padding: 8,
    },
  },
  parentButton: {
    color: theme.palette.tertiary.main,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '4px 0',
  },
  removeButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(),
    cursor: 'pointer',
  },
  menu: {
    width: 350,
    maxHeight: 400,
  },
  categoryLabel: {
    lineHeight: 1,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 10,
      textTransform: 'uppercase',
    },
    '& p': {
      marginTop: 4,
    },
  },
  categoryColor: {
    width: 8,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));
