import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Controllers
import useCategoryController from 'store/category/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  Edit2, Trash2, Plus, ChevronDown,
} from 'react-feather';
import Tooltip from 'common/tooltip';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function CategoryList({ type }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    current: company, categories, products, cashFlow,
  } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  const permissionProcurement = !permissions || !permissions.manager
    || permissions.manager.procurement.edit;
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const permissionEdit = permissionProcurement || permissionFinancial;

  const [delCategory, setDelCategory] = useState(false);

  const showRemove = (category) => (category.default || (
    !categories.find((c) => c.parent === category)
    && !products.find((p) => p.category === category)
    && !cashFlow.find((c) => c.categories.indexOf(category) >= 0)
  ));

  const CategoryTemplate = ({ category }) => (
    <Grid container justify="space-between" wrap="nowrap" alignItems="center" style={{ height: 40 }}>
      <Grid container wrap="nowrap">
        <div
          className={classes.categoryColor}
          style={{ background: category.color }}
        />
        <Typography>{category.name}</Typography>
      </Grid>

      {permissionEdit && (
        <Grid container justify="flex-end">
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => history.push(`/register/categories/${category.id}`)}
          >
            <Edit2 size={16} />
          </IconButton>
          {showRemove(category.id) && (
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => setDelCategory(category)}
            >
              <Trash2 size={16} />
            </IconButton>
          )}
          <Tooltip title="Add subcategory">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => history.push(`/register/categories/new?parent=${category.id}`)}
            >
              <Plus size={16} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );

  const CategoryItem = ({ category }) => (categories.find((c) => c.parent === category.id)
    ? (
      <ExpansionPanel className={classes.expansion} defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ChevronDown size={16} />}
          style={{ minHeight: 0, padding: '0 12px 0 0' }}
          classes={{ content: classes.expansionContent }}
        >
          <CategoryTemplate category={category} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <div className={classes.subCategories}>
            {categories.filter((c) => c.parent === category.id).map((c) => (
              <CategoryItem key={c.id} category={c} />
            ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ) : (
      <div style={{ padding: '4px 0' }}>
        <CategoryTemplate category={category} />
      </div>
    ));

  const propTypes = {
    category: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
      default: PropTypes.bool,
    }).isRequired,
  };
  CategoryItem.propTypes = propTypes;
  CategoryTemplate.propTypes = propTypes;

  return (
    <>
      {categories.filter((c) => !c.parent && c.type === type).map((category) => (
        <CategoryItem key={category.id} category={category} />
      ))}
      {categories.filter((c) => !c.parent && c.type === type).length === 0 && (
        <Grid className={classes.noData}>
          <Typography>No category registered</Typography>
        </Grid>
      )}
      {delCategory && (
        <ConfirmDialog
          title={`Do you really want to delete the category ${delCategory.name}?`}
          onClose={() => setDelCategory(false)}
          onConfirm={() => {
            dispatch(Log.store(`Category ${delCategory.name} was deleted`));
            Category.destroy(delCategory.id);
          }}
        />
      )}
    </>
  );
}

CategoryList.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CategoryList;
