import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Assets, Commons and Styles
import { X } from 'react-feather';
import { transferFiles } from 'common/form/upload/utils';
import { numberToReal } from 'common/utils/numbers';
import Form from 'common/form';
import Loading from 'common/loading';
import styles from './style';
import FormConfig from './form-config';

function CashFlowPayForm({ id, style, onSubmit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, cashId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { accounts, cashFlow, suppliers } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [payed] = useState(true);
  const [method, setMethod] = useState(0);
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState('');
  const [dueAt, setDueAt] = useState(0);
  // const [autoPayment, setAutoPayment] = useState(false);

  const [submited, setSubmited] = useState(false);

  const [initialForm, setInitialForm] = useState({
    account: '',
    payment: {
      method: '',
      billet: '',
      transference: {
        name: '',
        document: '',
        bankName: '',
        bank: '',
        agency: '',
        account: '',
        accountType: '',
      },
    },
    completed: {
      on: false,
      value: 0,
      date: new Date(),
      receipt: [],
    },
  });

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;
  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  useEffect(() => {
    if (cashFlow) {
      const cash = cashFlow.find((c) => c.id === id || c.id === cashId);

      if (cash) {
        const cashDueAt = cash.dueAt.toDate();

        setMethod((!!cash.payment && cash.payment.method) || '');
        setDescription(cash.description);
        setDueAt(cashDueAt);
        setValue(cash.value);
        setInitialForm({
          ...cash,
          completed: {
            ...cash.completed,
            value: cash.value,
            date: new Date(),
          },
        });
      } else {
        history.goBack();
      }
    } else {
      dispatch(CashFlow.index());
    }
  }, [id, cashId, cashFlow, history, dispatch]);

  useEffect(() => {
    if (!accounts) dispatch(Account.index());
  }, [accounts, dispatch]);

  const submit = async (data) => {
    setSubmited(true);
    const cash = data;

    if (payed) {
      if (!cash.completed.on) {
        _.set(cash, 'completed.status', 'WAITING_CONFIRMATION');
      }
    } else {
      _.set(cash, 'completed.status', 'WAITING_RECEIPT');
    }

    if (cash.completed.receipt) {
      cash.completed.receipt = await transferFiles(
        cash.completed.receipt,
        `/financial-temp/${eventId}/cash-flow/receipts`,
        `/financial/${eventId}/cash-flow/receipts`,
      );
    }

    dispatch(Log.store(`Paid ${numberToReal(cash.completed.value)}`));

    if (cash.completed.on) {
      const supplier = suppliers.find((s) => {
        let match = false;
        if (s.payment && s.payment.installments) {
          s.payment.installments.forEach((i) => {
            if (i.cashFlow === cashId) match = true;
          });
        }
        return match;
      });

      if (supplier) {
        const lastCash = supplier.payment.installments.filter((i) => {
          const cf = cashFlow.find((c) => c.id === i.cashFlow);
          return cf && !cf.completed.on;
        }).length === 1;
        if (lastCash) dispatch(Supplier.update(supplier.id, { status: 4 }));
      }
    }

    dispatch(CashFlow.update(cashId || id, cash));
    setSubmited(true);
    history.goBack();
  };

  const formSchema = FormConfig({
    payed, method, setMethod, /* setAutoPayment, */
  });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={!id ? history.goBack : () => {}}
      style={style || {}}
      classes={{ root: !id ? classes.dialog : classes.dialogWithoutBackground }}
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span style={{ marginRight: 16 }}>
            Bill payment
          </span>
          <IconButton onClick={history.goBack} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {accounts && cashFlow ? (
          <Form
            id={`form-pay-${id || cashId}`}
            onSubmit={onSubmit || submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>Description</Typography>
                  <Typography className={classes.description}>
                    {description}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className={classes.label}>Value</Typography>
                  <Typography className={classes.text}>
                    {numberToReal(value)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.label}>Due date</Typography>
                  <Typography className={classes.text}>
                    {dueAt
                      ? format(dueAt, 'dd MMM yyyy', { locale: ptBR })
                      : 'Not defined'}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {inputs.account}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {inputs.payment.method}
                </Grid>

                {/* {method === 'billet' && (
                  <Grid item xs={12}>
                    {inputs.payment.billet}
                  </Grid>
                )} */}

                {/* {method === 'transference' && (
                  <>
                    <Grid item xs={12}>
                      <Typography className={classes.subtitle}>Transferência</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {inputs.payment.transference.name}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {inputs.payment.transference.cpfCnpj}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {inputs.payment.transference.bankName}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {inputs.payment.transference.agency}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {inputs.payment.transference.accountNumber}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {inputs.payment.transference.accountType}
                    </Grid>
                  </>
                )} */}

                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>PAYMENT</Typography>
                </Grid>

                {/* {autoPayment && (
                  <>
                    <Grid item xs={12} sm={6} style={{ paddingRight: 0 }}>
                      <Button
                        fullWidth
                        variant={!payed ? 'contained' : 'outlined'}
                        className={!payed ? classes.buttonInContained : classes.buttonInOutlined}
                        onClick={() => setPayed(false)}
                      >
                        Pagar pela plataforma
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingLeft: 0 }}>
                      <Button
                        fullWidth
                        variant={payed ? 'contained' : 'outlined'}
                        className={payed ? classes.buttonOutContained : classes.buttonOutOutlined}
                        onClick={() => setPayed(true)}
                      >
                        Já está pago
                      </Button>
                    </Grid>
                  </>
                )}
                {inputs.completed.status} */}

                <Grid item xs={12} sm={6}>{inputs.completed.value}</Grid>
                <Grid item xs={12} sm={6}>{inputs.completed.date}</Grid>
                {payed && (
                  <>
                    <Grid item xs={12}>{inputs.completed.receipt}</Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justify="space-between"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      Payment confirmed by supplier
                      {inputs.completed.on}
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!accounts && !!cashFlow} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {/* {id && (
          <>
            {!first && (
              <Button
                variant="outlined"
                color="secondary"
                disabled={done === 'WAITING'}
                className={classes.button}
                onClick={goBack}
              >
                <ArrowLeft size={16} style={{ marginRight: 8 }} />
                Voltar
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              disabled={done === 'WAITING'}
              className={classes.buttonError}
            >
              Remover da lista
            </Button>
          </>
        )} */}
        <Button
          type="submit"
          form={`form-pay-${id || cashId}`}
          variant="outlined"
          color="primary"
          disabled={submited || !suppliers}
          className={classes.button}
        >
          PAID
          {(submited || !suppliers) && (
            <CircularProgress size={16} color="primary" style={{ marginLeft: 8 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CashFlowPayForm.propTypes = {
  id: PropTypes.string,
  style: PropTypes.shape({}),
  onSubmit: PropTypes.func,
};

CashFlowPayForm.defaultProps = {
  id: null,
  style: null,
  onSubmit: null,
};

export default CashFlowPayForm;
