import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

// Controllers
import Message from 'store/chat/message/controller';

// Icons, Commons and Styles
import { ArrowLeft, Edit2 } from 'react-feather';
import Loading from 'common/loading';
import ConversationForm from './form';
import UserProfile from './profile';
import MessageBox from './message';
import SenderBox from './senderbox';
import styles from './style';

function Chat({
  chat, chatUser, messages, onClose, openChat,
}) {
  const classes = styles();
  const dispatch = useDispatch();

  const { current: user } = useSelector((store) => store.user);
  const { users } = useSelector((store) => store.company);

  const [edit, setEdit] = useState(false);
  const [openProfile, setOpenProfile] = useState(null);
  const [channel, setChannel] = useState({
    name: '', image: null, initials: '', isAdmin: false,
  });
  const [firstUnread, setFirstUnread] = useState(-1);
  const [unreadEl, setUnreadEl] = useState(null);
  const [chatEl, setChatEl] = useState(null);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (chat) {
      const members = chat.members.filter((m) => m !== user.id)
        .map((m) => users.find((u) => u.id === m));

      setChannel({
        id: chat.id,
        name: chat.group ? chat.group.name : members[0].name,
        image: chat.group ? chat.group.avatar : members[0].avatar,
        initials: chat.group ? chat.group.initials : members[0].initials,
        isAdmin: chat.group ? chat.admins.indexOf(user.id) >= 0 : false,
      });
    } else {
      setOpenProfile(null);
      setEdit(false);
    }
  }, [chat, user, users]);

  useEffect(() => {
    if (chatUser) {
      const data = users.find((u) => u.id === chatUser);
      if (data) {
        setChannel({ name: data.name, image: data.avatar, initials: data.initials });
      } else onClose();
    } else {
      setOpenProfile(null);
      setEdit(false);
    }
  }, [chatUser, onClose, users]);

  useEffect(() => {
    if (messages) {
      const first = [...messages].reverse().find((m) => m.unread.indexOf(user.id) >= 0);
      if (first && firstUnread !== first.id) {
        setFirstUnread(first.id);
        const unreads = messages.filter((m) => m.unread.indexOf(user.id) >= 0);
        unreads.forEach((m) => {
          dispatch(Message.readed(chat.id, m.id, m.unread));
        });
        setTimeout(() => {
          setFirstUnread(-1);
        }, 3000);
      }
    }
  }, [messages, firstUnread, chat, dispatch, user.id]);

  useEffect(() => {
    if (chatEl && unreadEl) {
      chatEl.scrollTop = (chatEl.scrollHeight - chatEl.clientHeight) + unreadEl.offsetTop - 86;
    }
  }, [chatEl, unreadEl]);

  useEffect(() => {
    const listener = () => {
      if (chatEl.scrollTop === 0) setLimit((l) => l + 20);
    };

    if (chatEl) chatEl.addEventListener('scroll', listener);

    return () => {
      if (chatEl) {
        chatEl.removeEventListener('scroll', listener);
        setLimit(20);
      }
    };
  }, [chatEl]);

  useEffect(() => {
    if (chat) dispatch(Message.index(chat.id, limit));
  }, [chat, limit, dispatch]);

  useEffect(() => {
    if (chatEl && chat && channel && chat.id !== channel.id) {
      setLimit(20);
      setOpenProfile(null);
      setEdit(false);
      setFirstUnread(-1);
      chatEl.scrollTop = chatEl.scrollHeight - chatEl.clientHeight;
    }
  }, [chat, channel, chatEl]);

  return (
    <Drawer anchor="right" open={!!chat || !!chatUser} className={classes.drawer}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        className={classes.content}
        style={{ boxShadow: '0px 6px 24px 12px #fff', zIndex: 3 }}
      >
        <Grid container alignItems="center">
          <IconButton color="primary" onClick={onClose} style={{ margin: '0 8px 0 -8px' }}>
            <ArrowLeft />
          </IconButton>
          <Avatar className={classes.avatar} src={channel.image} alt={channel.name}>
            {channel.initials}
          </Avatar>
          <Typography className={classes.title}>{channel.name}</Typography>
        </Grid>
        {chat && channel.isAdmin && (
          <IconButton color="primary" onClick={() => setEdit(true)}>
            <Edit2 size={18} />
          </IconButton>
        )}
      </Grid>
      <Grid
        container
        direction="column-reverse"
        wrap="nowrap"
        className={classes.content}
        style={{ padding: '0 16px', height: 'calc(100% - 166px)', overflow: 'auto' }}
        ref={setChatEl}
      >
        {!!chat && !!messages
          ? messages.map((msg, i) => [
            <MessageBox channel={chat} key={msg.id} data={msg} openProfile={setOpenProfile} />,
            msg.id === firstUnread && (
              <Grid key="unread" container justify="center" style={{ position: 'relative' }} ref={setUnreadEl}>
                <div className={classes.divider} />
                <div className={classes.dividerBox}>New Messages</div>
              </Grid>
            ),
            i === (messages.length - 1) && <div key="space" style={{ minHeight: 12, width: 12 }} />,
          ])
          : <Loading content={200} ready={(!!chat && !!messages) || !!chatUser} />}
      </Grid>
      <SenderBox channel={chat} chatUser={chatUser} />

      {chat && (
        <ConversationForm channel={chat.id} open={!!edit} onClose={() => setEdit(false)} />
      )}

      <UserProfile
        user={openProfile}
        onClose={() => setOpenProfile(null)}
        openChat={openChat}
      />
    </Drawer>
  );
}

Chat.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    group: PropTypes.object,
    members: PropTypes.array,
    admins: PropTypes.array,
    unreads: PropTypes.number,
  }),
  chatUser: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  chat: null,
  chatUser: null,
  messages: null,
};

export default Chat;
