import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// Controllers
import useExternalMembersController from 'store/external-members/controller';
import Channel from 'store/chat/channel/controller';

// Icons and Styles
import { Trash2 } from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import ExternalMemberDialog from './dialog';
import styles from './style';


const ExternalMember = useExternalMembersController();

function ConversationForm({ chat }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [members, setMembers] = useState([]);
  const [isDeleteMember, setIsDeleteMember] = useState(null);

  useEffect(() => {
    if (chat.externalMembers) {
      async function fetchMembers() {
        const newMembers = [];

        for (let i = 0; i < chat.externalMembers.length; i++) {
          const id = chat.externalMembers[i];
          const data = await ExternalMember.show(id);
          if (data) newMembers.push({ id, ...data });
        }

        setMembers(newMembers);
      }

      fetchMembers();
    }
  }, [chat.externalMembers]);

  const deleteMember = (member) => () => {
    const newMembers = [...members];
    const index = newMembers.indexOf(member);
    newMembers.splice(index, 1);
    
    ExternalMember.destroy(member.id);
    dispatch(Channel.update(chat.id, { externalMembers: newMembers }));
  };

  return (
    <>
      <Button fullWidth color="primary" variant="outlined" onClick={() => setOpenDialog(true)}>
        Add external member
      </Button>

      <ExternalMemberDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        chat={chat}
      />

      <List style={{ width: '100%' }}>
        {members.map((member) => (
          <>
            <Divider component="li" />
            <ListItem button key={member.id} style={{ padding: '8px 0' }}>
              <ListItemAvatar>
                <Avatar className={classes.avatarList}>
                  {member.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={member.name} secondary={member.email} />
              <ListItemSecondaryAction>
                <IconButton className={classes.btnRed} onClick={() => setIsDeleteMember(member)}>
                  <Trash2 size={18} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </>
        ))}
        <Divider component="li" />
      </List>
      
      {isDeleteMember && (
        <ConfirmDialog
          title={`Do you really want to remove access from ${isDeleteMember.name}?`}
          onConfirm={deleteMember(isDeleteMember)}
          onClose={() => setIsDeleteMember(null)}
        />
      )}
    </>
  );
}

ConversationForm.propTypes = {
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  admins: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAdmins: PropTypes.func.isRequired,
};

export default ConversationForm;
