import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Controllers
import Channel from 'store/chat/channel/controller';
import Log from 'store/log/controller';

// Icons and Styles
import { ArrowLeft, Trash2 } from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import UploadAvatar from './avatar';
import UsersList from './users-list';
import styles from './style';

function ConversationForm({ open, channel, onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { current: currentUser } = useSelector((store) => store.user);
  const { channels } = useSelector((store) => store.company);

  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');
  const [members, setMembers] = useState([currentUser.id]);
  const [admins, setAdmins] = useState([currentUser.id]);
  const [errors, setErrors] = useState({ name: false, members: false });
  const [deleteChannel, setDeleteChannel] = useState(false);

  useEffect(() => {
    if (channels && channel) {
      const chat = channels.find((c) => c.id === channel);
      if (chat && chat.group) {
        setName(chat.group.name);
        setAvatar(chat.group.avatar);
        setInitials(chat.group.initials);
        setMembers(chat.members);
        setAdmins(chat.admins);
      }
    }
  }, [channel, channels]);

  const handleName = (e) => {
    const { value } = e.target;

    if (value !== '') {
      const separated = value.trim().toUpperCase().split(' ');
      const len = separated.length;

      if (len > 1) {
        setInitials(separated[0].charAt(0) + separated[len - 1].charAt(0));
      } else {
        setInitials(separated[0].charAt(0) + separated[0].charAt(1));
      }

      setErrors({ ...errors, name: false });
    } else setErrors({ ...errors, name: true });

    setName(value);
  };

  const handleMembers = () => {
    if (members[0] === 'open') setMembers([]);
    else setMembers(['open']);
  };

  const submit = () => {
    const newErrors = { name: false, members: false };

    if (name === '') newErrors.name = true;
    if (members.length === 0) newErrors.members = true;

    if (!newErrors.name && !newErrors.members) {
      if (channel) {
        dispatch(Log.store(`Changed channel data ${name}`));
        dispatch(Channel.update(channel, {
          group: { name, initials, avatar }, members, admins,
        }));
      } else {
        dispatch(Log.store('Created a new channel'));
        dispatch(Channel.store({
          group: { name, initials, avatar }, members, admins,
        }));
      }
      onClose();
    } else setErrors(newErrors);
  };

  return (
    <Drawer anchor="right" open={open} className={classes.drawer}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        className={classes.content}
        style={{ padding: 16 }}
      >
        <Grid container alignItems="center">
          <IconButton color="primary" onClick={onClose}>
            <ArrowLeft />
          </IconButton>
          <Typography className={classes.title}>CREATE CHANNEL</Typography>
        </Grid>
        {channel && (
          <IconButton color="secondary" onClick={() => setDeleteChannel(true)}>
            <Trash2 />
          </IconButton>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        direction="column"
        wrap="nowrap"
        spacing={2}
        className={classes.content}
        style={{ margin: 0, height: 'calc(100% - 122px)', overflow: 'overlay' }}
      >
        <Grid item style={{ position: 'relative' }}>
          <Avatar alt={name} src={avatar} className={classes.avatar}>
            {initials}
          </Avatar>
          <UploadAvatar avatar={avatar} onChange={setAvatar} />
        </Grid>
        <Grid item container>
          <TextField
            error={errors.name}
            fullWidth
            variant="outlined"
            label="Channel name"
            value={name}
            onChange={handleName}
          />
          {errors.name && (
            <FormHelperText error>Channel name is required</FormHelperText>
          )}
        </Grid>
        <Grid item container direction="column">
          <Typography className={classes.subtitle}>MEMBERS</Typography>
          {errors.members && (
            <FormHelperText error>Select at least one collaborator</FormHelperText>
          )}
        </Grid>
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Typography>Make channel public to all collaborators</Typography>
          <Switch
            edge="end"
            onChange={handleMembers}
            checked={members[0] === 'open'}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
        </Grid>
        <UsersList members={members} onChange={setMembers} admins={admins} setAdmins={setAdmins} />
      </Grid>
      <Grid container justify="center" className={classes.actions}>
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={submit}
          style={{ borderRadius: 0 }}
        >
          {channel ? 'SAVE' : 'CREATE'}
        </Button>
      </Grid>

      {deleteChannel && (
        <ConfirmDialog
          title="Do you really want to delete this channel?"
          onConfirm={() => {
            dispatch(Channel.destroy(channel));
            onClose();
          }}
          onClose={() => setDeleteChannel(false)}
        />
      )}
    </Drawer>
  );
}

ConversationForm.propTypes = {
  open: PropTypes.bool.isRequired,
  channel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ConversationForm.defaultProps = {
  channel: null,
};

export default ConversationForm;
