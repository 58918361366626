/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { storage } from 'services/firebase';

// Emoji
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

// Material UI Components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
// import Tab from '@material-ui/core/Tab';
// import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Channel from 'store/chat/channel/controller';
import Message from 'store/chat/message/controller';

// Icons and Styles
import { Send, Smile, Paperclip } from 'react-feather';
import AttachDialog from './attachments/dialog';
import UploadButton from './attachments/button';
import styles from './style';

function TabPanel({ children, value, index }) {
  const theme = useTheme();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      dir={theme.direction}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function SenderBox({ channel, chatUser }) {
  const classes = styles();
  const dispatch = useDispatch();

  const { current: user } = useSelector((store) => store.user);
  const { users } = useSelector((store) => store.company);

  const [content, setContent] = useState('');
  const [openEmoji, setOpenEmoji] = useState(null);
  const [openAttachs, setOpenAttachs] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [uploadRef, setUploadRef] = useState(null);
  // const [tab, setTab] = useState(0);

  const sendMessage = () => {
    if (content !== '') {
      if (channel) {
        dispatch(Message.store(channel, {
          content,
          attachments,
          unread: channel.members[0] === 'open'
            ? users.map((u) => u.id).filter((u) => u !== user.id)
            : channel.members.filter((u) => u !== user.id),
        }));
      } else if (chatUser) {
        dispatch(Channel.storeWithMessage(
          { members: [chatUser, user.id] },
          { content, unread: [chatUser], attachments },
        ));
      }
      setContent('');
    }
  };

  const sendAttachments = async (fileName, e) => {
    const { name, size } = e.blob_.data_;
    const { fullPath } = e.metadata_;

    const file = attachments.find((a) => a.name === name && a.size === size);
    if (file) {
      const url = await storage.ref(fullPath).getDownloadURL();

      if (channel) {
        dispatch(Message.store(channel, {
          content: '',
          attachments: [{
            name: fileName, label: name, size, url,
          }],
          unread: channel.members[0] === 'open'
            ? users.map((u) => u.id).filter((u) => u !== user.id)
            : channel.members.filter((u) => u !== user.id),
        }));
      } else if (chatUser) {
        dispatch(Channel.storeWithMessage(
          { members: [chatUser, user.id] },
          {
            content: '',
            unread: [chatUser],
            attachments: [{
              name: fileName, label: name, size, url,
            }],
          },
        ));
      }

      if (file === attachments.sort((a, b) => b.size - a.size)[0]) {
        setAttachments([]);
        setOpenAttachs(false);
      }
    }
  };

  const addEmoji = (e) => {
    console.log(e);
    const emoji = e.native;
    setContent(content + emoji);
  };

  const emojiLabels = {
    search: 'Procurar',
    clear: 'Limpar', // Accessible label on "clear" button
    notfound: 'None emoji was found',
    categories: {
      search: 'Found results',
      recent: 'Recents',
      people: 'People & Emotions',
      nature: 'Animals & Nature',
      foods: 'Food & Drinks',
      activity: 'Activities',
      places: 'Travels & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Customized',
    },
    categorieslabel: 'Categories',
  };

  return (
    <Grid container style={{ padding: 16, maxWidth: 450 }}>
      {openAttachs && (
        <AttachDialog
          channel={channel}
          chatUser={chatUser}
          attachments={attachments}
          onChange={setAttachments}
          send={() => {
            if (uploadRef) {
              attachments.forEach((file) => {
                uploadRef.startUpload(file);
              });
            }
          }}
          onClose={() => setOpenAttachs(false)}
        />
      )}

      <TextField
        multiline
        fullWidth
        autoFocus
        variant="outlined"
        placeholder="Write something..."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          }
        }}
        InputProps={{
          style: {
            padding: '14px 0', lineHeight: 1.4, borderRadius: 25, background: 'white',
          },
          startAdornment: (
            <InputAdornment poistion="start">
              <IconButton onClick={(e) => setOpenEmoji(e.currentTarget)}>
                <Smile />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {content === '' && (
                <InputAdornment poistion="end">
                  <IconButton>
                    <UploadButton
                      className={classes.labelListUpload}
                      channel={channel ? channel.id : chatUser}
                      onChange={(files) => {
                        setAttachments(files);
                        if (files.length > 0) setOpenAttachs(true);
                      }}
                      onStart={() => {}}
                      onSuccess={sendAttachments}
                      setRef={setUploadRef}
                    >
                      <Paperclip size={18} />
                    </UploadButton>
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment poistion="end">
                <IconButton color="primary" onClick={sendMessage}>
                  <Send />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <Popover
        open={!!openEmoji}
        anchorEl={openEmoji}
        onClose={() => setOpenEmoji(null)}
        style={{ marginTop: -48 }}
      >
        {/* <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newTab) => setTab(newTab)}
          aria-label="disabled tabs example"
        >
          <Tab label="EMOJI" />
          <Tab label="GIF" />
        </Tabs>

        <TabPanel value={tab} index={0}> */}
        <Picker
          onSelect={addEmoji}
          showPreview={false}
          showSkinTones={false}
          i18n={emojiLabels}
        />
        {/* </TabPanel>
        <TabPanel value={tab} index={1}>
          Item Two
        </TabPanel> */}
      </Popover>
    </Grid>
  );
}

SenderBox.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string,
    group: PropTypes.object,
    members: PropTypes.array,
  }),
  chatUser: PropTypes.string,
};

SenderBox.defaultProps = {
  chatUser: null,
  channel: null,
};

export default SenderBox;
