import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

// Icons
import { Eye, EyeOff } from 'react-feather';

// Input Mask
import CNPJMasked from 'common/inputs-mask/cnpj';
import PhoneMasked from 'common/inputs-mask/phone';
import CEPMasked from 'common/inputs-mask/cep';
import CPFMasked from 'common/inputs-mask/cpf';

import Tooltip from 'common/tooltip';

function InputText({
  label,
  inputType,
  value,
  mask,
  multiline,
  disabled,
  size,
  error,
  helperText,
  onChange,
  accept,
  maxLength,
  charCount,
  placeholder,
  className,
  InputPropsDefault,
  inputProps,
}) {
  const masks = {
    cpf: CPFMasked,
    cnpj: CNPJMasked,
    phone: PhoneMasked,
    cep: CEPMasked,
  };

  const InputProps = { ...InputPropsDefault };

  if (mask) InputProps.inputComponent = masks[mask];
  if (maxLength) InputProps.inputProps = { maxLength };
  if (multiline && !size) {
    InputProps.style = { padding: '12px 14px', minHeight: 56 };
    InputProps.inputProps = {
      ...InputProps.inputProps,
      style: { lineHeight: 1.5 },
    };
  }

  const [showPassword, setShowPassword] = useState(false);
  if (inputType === 'password') {
    InputProps.endAdornment = (
      <InputAdornment position="end">
        <Tooltip
          title={showPassword ? 'Hide password' : 'Show password'}
          enterDelay={300}
        >
          <IconButton
            aria-label={showPassword ? 'Hide password' : 'Show password'}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff /> : <Eye />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }

  InputProps.inputProps = { ...InputProps.inputProps, className, ...inputProps };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        type={showPassword ? 'text' : inputType}
        error={!!error}
        disabled={disabled}
        multiline={multiline}
        size={size}
        placeholder={placeholder}
        label={label || undefined}
        value={value}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onChange={(e) => {
          if (accept) {
            const v = e.target.value.match(accept);
            onChange(v ? v[0] : '');
          } else onChange(e.target.value);
        }}
        InputProps={InputProps}
      />
      {charCount && (
        <FormHelperText error={charCount < value.length} style={{ textAlign: 'right', fontSize: 10 }}>
          {`${value.length}/${charCount}`}
        </FormHelperText>
      )}
      {((error && typeof error === 'string') || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

InputText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  inputType: PropTypes.string,
  mask: PropTypes.string,
  size: PropTypes.string,
  multiline: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  accept: PropTypes.instanceOf(RegExp),
  InputPropsDefault: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
};

InputText.defaultProps = {
  label: null,
  inputType: 'text',
  helperText: '',
  mask: null,
  className: null,
  placeholder: null,
  accept: null,
  maxLength: null,
  size: null,
  error: false,
  multiline: false,
  disabled: false,
  InputPropsDefault: {},
  inputProps: {},
};

export default InputText;
