function FormConfig({ benchmarking, setBenchmarking, startDate, setStartDate }) {
  const benchmarkingInputs = [];

  benchmarking.forEach((p, i) => {
    benchmarkingInputs.push({
      name: `benchmarking[${i}].name`,
      type: 'text',
      label: 'Event name',
      size: 'small',
      onChange: (value, state) => {
        const newBench = [...benchmarking];
        newBench[i].name = value;
        setBenchmarking(newBench);
        return state;
      },
      validation: { required: true },
    });
    benchmarkingInputs.push({
      name: `benchmarking[${i}].url`,
      type: 'text',
      label: 'Website',
      size: 'small',
      onChange: (value, state) => {
        const newBench = [...benchmarking];
        newBench[i].url = value;
        setBenchmarking(newBench);
        return state;
      },
      validation: { url: true },
    });
    benchmarkingInputs.push({
      name: `benchmarking[${i}].notes`,
      type: 'text',
      label: 'Notes',
      multiline: true,
      size: 'small',
      onChange: (value, state) => {
        const newBench = [...benchmarking];
        newBench[i].notes = value;
        setBenchmarking(newBench);
        return state;
      },
    });
  });

  return [
    {
      name: 'description',
      type: 'text',
      multiline: true,
      charCount: 140,
      validation: { required: true, maxLength: { msg: true, params: { max: 140 } } },
    },
    {
      name: 'objective',
      type: 'text',
      multiline: true,
      validation: { required: true },
    },
    ...benchmarkingInputs,
    {
      name: 'dates.start',
      type: 'datetime',
      label: 'Start in',
      showTime: true,
      keyboard: true,
      onChange: (value, state) => {
        // setStartDate(value);
        return state;
      },
    },
    {
      name: 'dates.end',
      type: 'datetime',
      label: 'Finish in',
      showTime: true,
      keyboard: true,
      pickerProps: { minDate: startDate },
      validation: { minDate: { msg: true, params: { min: startDate } } }
    },
    {
      name: 'name',
      type: 'text',
    },
    {
      name: 'site',
      type: 'text',
      validation: { url: true },
    },
    {
      name: 'learnings',
      type: 'text',
      multiline: true,
    },
  ];
}

export default FormConfig;
