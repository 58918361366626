import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'overlay',
    overflowX: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing()}px ${theme.spacing()}px`,
      maxHeight: 'inherit',
      height: 'auto',
    },
  },
  content: {
    padding: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  value: {
    fontSize: 45,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    lineHeight: 1.2,
    '& > span': {
      paddingLeft: 8,
      fontWeight: 400,
    },
  },
  details: {
    '& > div': {
      display: 'flex',
      flexWrap: 'nowrap',
      color: theme.palette.common.grey,
    },
  },
  iconContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    padding: 22,
    marginRight: 8,
    borderRadius: 50,
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'relative',
      left: 50,
      top: -12,
      height: 6,
      width: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: '10px solid white',
      [theme.breakpoints.down('xs')]: {
        left: 32,
        top: -10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 80,
      padding: 17,
    },
  },
  icon: {
    width: 50,
    height: 50,
    marginLeft: -2,
    marginRight: 4,
    '& path': {
      fill: theme.palette.tertiary.main,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
}));
