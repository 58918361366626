import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons ans Styles
import {
  CheckCircle, MessageCircle, X, ArrowLeft,
} from 'react-feather';
import Form from 'common/form';
import { transferFiles } from 'common/form/upload/utils';
import Toolbar from 'common/toolbar';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

// Components
import SupplierRegisterChat from './chat';

const INITIAL_SUPPLIER = {
  document: '',
  name: '',
  companyName: '',
  situation: '',
  legalNature: '',
  // stateRegistration: '',
  // cityRegistration: '',
  mainActivity: { code: '', description: '' },
  contact: { email: '', phone: '', site: '' },
  address: {
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    // neighborhood: '',
    city: '',
    state: '',
    country: '',
  },
  types: ['Fornecedor'],
};

const INITIAL_SUPPLIER_EVENT = {
  answered: false,
  chat: [],
  attachments: [],
  notes: '',
};

function SupplierRegister() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, supplierId, token } = useParams();

  const { register: supplierRegister, current: currentSupplier, error } = useSelector(
    (state) => state.supplier,
  );

  const [openChat, setOpenChat] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [submited, setSubmited] = useState(false);

  const [supplier, setSupplier] = useState(INITIAL_SUPPLIER);
  const [supplierEvent, setSupplierEvent] = useState(INITIAL_SUPPLIER_EVENT);

  const isReadyToLoad = !supplierRegister && !currentSupplier;
  useEffect(() => {
    if (isReadyToLoad) {
      if (token) dispatch(Supplier.showToken(eventId, token));
      if (supplierId) dispatch(Supplier.showEvent(eventId, supplierId));
    }

    if (((!token && !supplierId) || !!error) && !supplierEvent.answered) history.replace('/auth/sign-in');
  }, [isReadyToLoad, supplierId, token, eventId, dispatch, history, error, supplierEvent]);

  useEffect(() => {
    if (currentSupplier && !supplierRegister) {
      if (currentSupplier.register && currentSupplier.register.cnpj) {
        dispatch(Supplier.showRegister(eventId, currentSupplier.register.cnpj));
      }

      setSupplierEvent((s) => ({
        ...s,
        ...currentSupplier.register,
        id: currentSupplier.id,
        settings: currentSupplier.settings,
      }));
      setIsLoaded(true);
    }
  }, [currentSupplier, supplierRegister, eventId, dispatch]);

  useEffect(() => {
    if (supplierRegister) setSupplier((s) => ({ ...s, ...supplierRegister }));
  }, [supplierRegister]);

  useEffect(
    () => () => {
      dispatch(Supplier.clear());
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    const { chat } = supplierEvent;

    if (chat && openChat) {
      const newChat = chat.map((msg) => {
        const viewed = (msg.author === 'company' && !msg.viewed) || msg.viewed || false;
        return { ...msg, viewed };
      });

      if (newChat.length > 0 && chat.find((m) => m.author === 'company' && !m.viewed)) {
        dispatch(Supplier.sendRegisterChat(eventId, supplierEvent.id, newChat));
      }
    }
    // eslint-disable-next-line
  }, [openChat]);

  const submit = async (data) => {
    setSubmited(true);

    const { attachments: files, notes, ...contact } = data;

    let attachments = [];
    if (files) {
      attachments = await transferFiles(
        files,
        `suppliers-temp/${eventId}/${supplierEvent.id}`,
        `suppliers/${eventId}/${supplierEvent.id}`,
      );
    }

    const cnpj = data.document.trim().split(/\.|\/|-/).join('');

    if (!token) dispatch(Log.store(`Registered a supplier ${contact.name}`));
    dispatch(Supplier.register(eventId, cnpj, contact));
    dispatch(
      Supplier.registerEvent(eventId, supplierEvent.id, {
        answered: true, notes: notes || '', attachments, cnpj,
      }),
    );
  };

  const chatBadged = supplierEvent.chat.filter((m) => m.author === 'company' && !m.viewed).length;

  const formSchema = FormConfig({
    path: `suppliers-temp/${eventId}/${supplierEvent.id}`,
    supplierEvent,
  });

  return (
    <Grid container style={{ background: '#f7f7f7' }}>
      <Toolbar logo back={!token} snippet={false} defaultIcons={false} shadow="0px 1px 15px 1px rgba(81,77,92,0.1)" />
      <Loading ready={isLoaded} />
      {isLoaded && !supplierEvent.answered ? (
        <Grid container direction="row" className={classes.container}>
          <Typography variant="h5" align="center" className={classes.title}>
            {token ? 'NEW SUPPLIER' : 'REGISTER SUPPLIER'}
          </Typography>
          <Form id="supplier" schema={formSchema} initialState={supplier} onSubmit={submit}>
            {(inputs) => (
              <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.row}
              >
                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">COMPANY DATA</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>{inputs.document}</Grid>
                <Grid item xs={12} sm={8}>{inputs.name}</Grid>
                <Grid item xs={12} sm={12}>{inputs.companyName}</Grid>
                <Grid item xs={12} sm={6}>{inputs.situation}</Grid>
                <Grid item xs={12} sm={6}>{inputs.legalNature}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">MAIN ACTIVITY</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>{inputs.mainActivity.code}</Grid>
                <Grid item xs={12} sm={8}>{inputs.mainActivity.description}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">CONTACT</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>{inputs.contact.email}</Grid>
                <Grid item xs={12} sm={6}>{inputs.contact.phone}</Grid>
                <Grid item xs={12}>{inputs.contact.site}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">ADDRESS</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>{inputs.address.postalCode}</Grid>
                <Grid item xs={12} sm={5}>{inputs.address.street}</Grid>
                <Grid item xs={4} sm={2}>{inputs.address.number}</Grid>
                <Grid item xs={8} sm={3}>{inputs.address.complement}</Grid>
                {/* <Grid item xs={12} sm={3}>{inputs.address.neighborhood}</Grid> */}
                <Grid item xs={12} sm={4}>{inputs.address.city}</Grid>
                <Grid item xs={6} sm={2}>{inputs.address.state}</Grid>
                <Grid item xs={6} sm={3}>{inputs.address.country}</Grid>

                <Grid item xs={12} sm={12} className={classes.subtitle}>
                  <Typography variant="overline">NOTES</Typography>
                </Grid>
                <Grid item xs={12}>{inputs.notes}</Grid>

                <Grid item xs={12} className={classes.subtitle}>
                  <Typography variant="overline">FILES</Typography>
                </Grid>
                <Grid item xs={12}>{inputs.attachments}</Grid>

                <Grid container justify="center">
                  <Button
                    size="large"
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    disabled={submited}
                  >
                    {submited && <CircularProgress size={18} style={{ marginRight: 16 }} />}
                    {submited ? 'Sending' : 'Send'}
                    {supplierEvent.repproved ? ' changes' : ' register'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>

          {token && (
            <>
              {chatBadged > 0 ? (
                <Badge badgeContent={chatBadged} color="secondary" classes={{ badge: classes.chatButtonBadge }}>
                  <Fab
                    color="primary"
                    onClick={(e) => setOpenChat(openChat ? false : e.currentTarget)}
                    className={classes.chatButton}
                  >
                    {openChat ? <X /> : <MessageCircle />}
                  </Fab>
                </Badge>
              ) : (
                <Fab
                  color="primary"
                  onClick={(e) => setOpenChat(openChat ? false : e.currentTarget)}
                  className={classes.chatButton}
                >
                  {openChat ? <X /> : <MessageCircle />}
                </Fab>
              )}

              <SupplierRegisterChat
                supplier={supplierEvent.id}
                chat={supplierEvent.chat}
                open={Boolean(openChat)}
                anchorEl={openChat}
              />
            </>
          )}
        </Grid>
      ) : (
        supplierEvent.answered && (
          <Grid
            container
            alignItems="center"
            direction="column"
            className={classes.containerAnswered}
          >
            <CheckCircle size={50} className={classes.answeredIcon} />
            <Typography variant="h4" style={{ marginBottom: 32, fontWeight: 600 }}>
              All right! Informations
              {token ? ' sent to the contracting company' : ' registered at supplier'}
            </Typography>
            {token ? (
              <Typography variant="h6" style={{ fontWeight: 400 }}>
                Now wait for the evaluation of your data and the return of the company to continue with the hiring.
              </Typography>
            ) : (
              <Button variant="contained" color="primary" onClick={history.goBack}>
                <ArrowLeft size={16} style={{ marginRight: 8 }} />
                Back
              </Button>
            )}
          </Grid>
        )
      )}
    </Grid>
  );
}

export default SupplierRegister;
