/* eslint-disable no-loop-func */
import { addDays, isSameDay, format, differenceInCalendarDays } from 'date-fns';

function minorOrSameDay(firstDate, lastDate) {
  return firstDate < lastDate || isSameDay(firstDate, lastDate)
}

export const generateData = (allTasks, userFilter, userId) => {
  const days = [];
  const done = [];
  const late = [];
  const working = [];
  const backlog = [];

  let tasks = allTasks;
  if (userFilter === 'me') tasks = allTasks.filter((t) => t.responsibles.indexOf(userId) >= 0)

  const [firstTask] = tasks.sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());
  console.log(firstTask);

  if (firstTask) {
    const firstDay = firstTask.createdAt.toDate()
    const diff = differenceInCalendarDays(new Date(), firstDay);
  
    for (let i = 0; i <= diff; i += 1) {
      const currentDate = addDays(firstDay, i);
      
      const tasksOnDay = tasks.filter((t) => {
        const createdAt = t.createdAt.toDate()
        return minorOrSameDay(createdAt, currentDate);
      });

      let dayCompleted = 0;
      let dayLate = 0;
      let dayWorking = 0;
      let dayBacklog = 0;

      tasksOnDay.forEach((task) => {
        const endAt = task.endAt ? task.endAt.toDate() : null;
        const completedAt = task.completedAt ? task.completedAt.toDate() : null;
        const workingAt = task.workingAt ? task.workingAt.toDate() : null;

        if (completedAt && minorOrSameDay(completedAt, currentDate)) {
          dayCompleted += 1;
          return;
        }

        if (!completedAt && endAt < currentDate && !isSameDay(endAt, currentDate)) {
          dayLate += 1;
          return;
        }

        if (!completedAt && workingAt && minorOrSameDay(workingAt, currentDate)) {
          dayWorking += 1;
          return;
        }

        dayBacklog += 1;
      });

      days.push(format(currentDate, 'dd/MM/yyyy'));
      done.push(dayCompleted);
      late.push(dayLate);
      working.push(dayWorking);
      backlog.push(dayBacklog);
    }
  }

  return [days, done, late, working, backlog];
};

export const getOptions = (data) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'none' },
    show: true,
    backgroundColor: 'white',
    padding: 16,
    enterable: true,
    textStyle: { color: '#777' },
    extraCssText: 'box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);',
    formatter: (params) => {
      const total = params[0].value + params[1].value + params[2].value + params[3].value;
      return '<div>'
            + '<div style="margin-bottom: 16px; text-align: center">'
              + `<b style="font-size: 16px; color: #5C5AA7;">${params[0].name}</b>`
            + '</div>'
            + '<div style="margin-bottom: 16px; text-align: center">'
              + `<b style="font-size: 16px;">${total} tasks</b>`
            + '</div>'
            + '<div style="display: flex; align-items: center">'
              // + '<div style="width: 12px; height: 12px; border-radius: 50%; background: #4BB75C"></div>'
              + '<span style="color: #4BB75C;">'
                + `<b>${params[0].value}</b> finished${params[0].value > 1 ? 's' : ''}`
              + '</span><br>'
            + '</div>'
            + '<div style="display: flex; align-items: center">'
              + '<span style="color: #DC1F26;">'
                + `<b>${params[1].value}</b> late${params[1].value > 1 ? 's' : ''}`
              + '</span><br>'
            + '</div>'
            + '<div style="display: flex; align-items: center">'
              + '<span style="color: #f59658;">'
                + `<b>${params[2].value}</b> working`
              + '</span><br>'
            + '</div>'
            + '<div style="display: flex; align-items: center">'
              + '<span style="color: #4291ef;">'
                + `<b>${params[3].value}</b> to do`
              + '</span><br>'
            + '</div>'
          + '</div>';
    },
  },
  grid: {
    left: '1%',
    right: '1%',
    bottom: '2%',
    top: '2%',
    containLabel: false,
  },
  xAxis: {
    type: 'category',
    show: false,
    boundaryGap: false,
    data: data[0],
  },
  yAxis: {
    show: false,
    type: 'value'
  },
  color: ['#4bb75c', '#dc1f26', '#f59658', '#4291ef'],
  series: [
    {
        name: 'Feitos',
        type: 'line',
        stack: 'tasks',
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: { width: 2 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(75, 183, 92, 0.6)' // color at 0% position
            }, {
                offset: 1, color: 'rgba(75, 183, 92, 0)' // color at 100% position
            }],
            global: false // false by default
          }
        },
        data: data[1],
    },
    {
        name: 'Late',
        type: 'line',
        stack: 'tasks',
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: { width: 2 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(220, 31, 38, 0.6)' // color at 0% position
            }, {
                offset: 1, color: 'rgba(220, 31, 38, 0)' // color at 100% position
            }],
            global: false // false by default
          }
        },
        data: data[2]
    },
    {
        name: 'Working',
        type: 'line',
        stack: 'tasks',
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: { width: 2 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(247, 171, 121, 0.6)' // color at 0% position
            }, {
                offset: 1, color: 'rgba(247, 171, 121, 0)' // color at 100% position
            }],
            global: false // false by default
          }
        },
        data: data[3]
    },
    {
        name: 'To do',
        type: 'line',
        stack: 'tasks',
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: { width: 2 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(66, 145, 239, 0.6)' // color at 0% position
            }, {
                offset: 1, color: 'rgba(66, 145, 239, 0)' // color at 100% position
            }],
            global: false // false by default
          }
        },
        data: data[4]
    },
  ],
});
