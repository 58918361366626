import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  label: {
    fontSize: 10,
    lineHeight: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.tertiary.main,
    marginBottom: 4,
  },
  late: {
    background: lighten(theme.palette.error.main, 0.8),
    padding: '4px 8px',
    borderRadius: 4,
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  confirmed: {
    background: lighten(theme.palette.success.main, 0.8),
    padding: '4px 8px',
    borderRadius: 4,
    color: theme.palette.common.grey,
    cursor: 'pointer',
  },
  normal: {
    background: lighten(theme.palette.common.grey, 0.95),
    padding: '4px 8px',
    borderRadius: 4,
    color: theme.palette.common.grey,
    cursor: 'pointer',
  },
  select: {
    paddingRight: 24,
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
}));
