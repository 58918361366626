/* eslint-disable no-loop-func */
import {
  addMonths, addDays, startOfMonth, endOfMonth, subMonths, isSameDay, format, differenceInDays,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { numberToReal } from 'common/utils/numbers';

export const generateData = (cashFlow, period, customDate) => {
  const data = [[], [], [], [], []];

  let cashInValue = 0;
  let cashOutValue = 0;
  let cashInEstimatedValue = 0;
  let cashOutEstimatedValue = 0;

  let dateInit = null;
  let dateEnd = null;

  const dataCash = cashFlow.sort((a, b) => {
    if (a.completed.on && b.completed.on) {
      return a.completed.date.toDate() - b.completed.date.toDate();
    }
    if (a.completed.on && !b.completed.on) {
      return a.completed.date.toDate() - b.dueAt.toDate();
    }
    if (!a.completed.on && b.completed.on) {
      return a.dueAt.toDate() - b.completed.date.toDate();
    }
    return a.dueAt.toDate() - b.dueAt.toDate();
  }).map((c) => {
    if (c.completed.on) {
      return {
        ...c.completed, date: c.completed.date.toDate(), completed: true, type: c.type,
      };
    }
    return {
      value: c.value, date: c.dueAt.toDate(), completed: false, type: c.type,
    };
  });

  let firstCompleted = dataCash.find((c) => c.completed);
  if (!firstCompleted) [firstCompleted] = dataCash;
  if (!firstCompleted) return data;

  let start = dataCash[0].date;
  const end = dataCash[dataCash.length - 1].date;
  const today = new Date();

  while (start < end || isSameDay(start, end)) {
    const todayCash = dataCash.filter((c) => isSameDay(c.date, start));

    todayCash.forEach((c) => {
      if (c.completed) {
        if (c.type === 'in') cashInValue += c.value;
        if (c.type === 'out') cashOutValue += c.value;
      } else {
        if (c.type === 'in') cashInEstimatedValue += c.value;
        if (c.type === 'out') cashOutEstimatedValue += c.value;
      }
    });


    if (start > firstCompleted.date || isSameDay(start, firstCompleted.date)) {
      if (start < today || isSameDay(today, start)) {
        data[1].push(cashInValue);
        data[2].push(cashOutValue);
      }

      if (isSameDay(today, start)) {
        data[3].push(0);
        data[4].push(0);
        // data[3].push(cashInValue);
        // data[4].push(cashOutValue);
        cashInEstimatedValue += cashInValue;
        cashOutEstimatedValue += cashOutValue;
      } else if (start < today) {
        data[3].push(null);
        data[4].push(null);
      } else {
        data[3].push(cashInEstimatedValue);
        data[4].push(cashOutEstimatedValue);
      }

      data[0].push(start);
    }

    start = addDays(start, 1);
  }

  switch (period) {
    case 'all period': {
      dateInit = firstCompleted.date;
      dateEnd = end;
      break;
    }
    case 'this month': {
      dateInit = startOfMonth(new Date());
      dateEnd = endOfMonth(new Date());
      break;
    }
    case 'last month': {
      const month = subMonths(new Date(), 1);
      dateInit = startOfMonth(month);
      dateEnd = endOfMonth(month, 10);
      break;
    }
    case 'next month': {
      const month = addMonths(new Date(), 1);
      dateInit = startOfMonth(month);
      dateEnd = endOfMonth(month, 10);
      break;
    }
    case 'custom': {
      dateInit = customDate.start;
      dateEnd = customDate.end;
      break;
    }
    default:
      break;
  }

  const indexStart = data[0].indexOf(data[0].find((d) => isSameDay(d, dateInit)));
  let indexEnd = data[0].indexOf(data[0].find((d) => isSameDay(d, dateEnd)));

  if (indexStart >= 0 && indexEnd < 0) {
    const lastDay = data[0][data[0].length - 1];
    if (lastDay < dateEnd) {
      for (let i = 1; i <= differenceInDays(dateEnd, lastDay) + 1; i += 1) {
        const day = addDays(lastDay, i);
        data[0].push(day);
        if (day < today || isSameDay(today, day)) {
          data[1].push(cashInValue);
          data[2].push(cashOutValue);
        }

        if (isSameDay(today, day)) {
          data[3].push(0);
          data[4].push(0);
          // data[3].push(cashInValue);
          // data[4].push(cashOutValue);
          cashInEstimatedValue += cashInValue;
          cashOutEstimatedValue += cashOutValue;
        } else if (day < today) {
          data[3].push(null);
          data[4].push(null);
        } else {
          data[3].push(cashInEstimatedValue);
          data[4].push(cashOutEstimatedValue);
        }
      }
    }

    indexEnd = data[0].length - 1;
  }

  return data.map((row, index) => {
    const newData = row.filter((r, i) => i >= indexStart && i <= indexEnd);

    if (index === 0) {
      return newData.map((date) => format(date, 'dd MMM yyyy', { locale: ptBR }));
    }

    return newData;
  });
};

export const getOptions = (data, mobile) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none',
    },
    show: true,
    backgroundColor: 'white',
    padding: 16,
    enterable: true,
    textStyle: {
      color: '#777',
    },
    position: (point, params, dom, rect, size) => {
      if (mobile) {
        const { contentSize, viewSize } = size;
        const x = (viewSize[0] - contentSize[0]) / 2;
        return [x, '101%'];
      }

      return 'inside';
    },
    formatter: (params) => {
      if (params[2]) {
        return '<div>'
          + '<div style="margin-bottom: 16px; text-align: center">'
          + `<b style="font-size: 16px; color: #5C5AA7;">${params[0].name}</b>`
          + '</div>'
          + '<div style="display: flex; align-items: center">'
          + '<div>'
          + `<span style="color: #DC1F26">${params[0].seriesName}</span><br>`
          + `<b style="font-size: 18px;color: #DC1F26">${numberToReal(params[0].value)}</b>`
          + '</div>'
          + '<div style="margin: 0 16px">'
          + '<b style="font-size: 18px;">X</b>'
          + '</div>'
          + '<div style="text-align: right">'
          + `<span style="color: #4BB75C">${params[2].seriesName}</span><br>`
          + `<b style="font-size: 18px;color: #4BB75C">${numberToReal(params[2].value)}</b>`
          + '</div>'
          + '</div>'
          + '</div>';
      }

      return '<div>'
        + '<div style="margin-bottom: 16px; text-align: center">'
        + `<b style="font-size: 16px; color: #5C5AA7;">${params[0].name}</b>`
        + '</div>'
        + '<div style="display: flex; align-items: center">'
        + '<div>'
        + `<span style="color: #DC1F26">${params[0].seriesName}</span><br>`
        + `<b style="font-size: 18px;color: #DC1F26">${numberToReal(params[0].value)}</b>`
        + '</div>'
        + '<div style="margin: 0 16px">'
        + '<b style="font-size: 18px;">X</b>'
        + '</div>'
        + '<div style="text-align: right">'
        + `<span style="color: #4BB75C">${params[1].seriesName}</span><br>`
        + `<b style="font-size: 18px;color: #4BB75C">${numberToReal(params[1].value)}</b>`
        + '</div>'
        + '</div>'
        + '</div>';
    },
    extraCssText: 'box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);',
  },
  grid: {
    left: '3%',
    right: '3%',
    bottom: '3%',
    top: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    show: false,
    boundaryGap: false,
    data: data[0],
  },
  yAxis: {
    type: 'value',
    show: false,
    offset: 100,
  },
  color: ['#DC1F26', '#DC1F26', '#4BB75C', '#4BB75C'],
  series: [
    {
      name: 'Investiment',
      type: 'line',
      stack: 'Investiment',
      data: data[2],
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { width: 5 },
      // areaStyle: {
      //   color: 'rgba(220, 31, 38, 0.4)',
      // },
    },
    {
      name: 'Expected Investiment',
      type: 'line',
      stack: 'Investiment',
      data: data[4],
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { width: 5, type: mobile ? 'none' : 'dashed' },
      // areaStyle: {
      //   color: 'rgba(220, 31, 38, 0.4)',
      // },
    },
    {
      name: 'Return',
      type: 'line',
      stack: 'Return',
      data: data[1],
      lineStyle: { width: 5 },
      symbol: 'circle',
      symbolSize: 5,
      // areaStyle: {
      //   color: 'rgba(75, 183, 92, 0.4)',
      // },
    },
    {
      name: 'Expected Return',
      type: 'line',
      stack: 'Return',
      data: data[3],
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { width: 5, type: mobile ? 'none' : 'dashed' },
      // areaStyle: {
      //   color: 'rgba(75, 183, 92, 0.4)',
      // },
    },
  ],
});
