import React, { useState } from 'react';

// Material components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Commons, Components and styles
import PieChart from './pie-chart';

function StepsChart() {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [data] = useState([
    {
      id: 'payed',
      value: 100000,
      name: 'Diamond Package',
      color: '#49E5CE',
    },
    {
      id: 'closed',
      value: 25000,
      name: 'Gold Package',
      color: '#E0D425',
    },
    {
      id: 'dealering',
      value: 80000,
      name: 'Silver Package',
      color: '#D2D2D2',
    },
    {
      id: 'remaining',
      value: 100000,
      name: 'Exchange',
      color: '#5c5aa7',
    },
  ]);

  return (
    <PieChart
      data={data}
      direction={upSm ? 'right' : 'center'}
      title="by Package"
      // loaded={isLoaded}
    />
  );
}

export default StepsChart;
