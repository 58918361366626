import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  drawer: {
    left: 'inherit !important',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  content: {
    width: '100vw',
    maxWidth: 450,
    padding: `${theme.spacing()}px ${theme.spacing(1.5)}px`,
  },
  title: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: theme.spacing(),
  },
  avatar: {
    width: 130,
    height: 130,
    backgroundColor: theme.palette.primary.main,
    fontSize: 50,
  },
  name: {
    fontSize: 28,
    fontWeight: 600,
  },
  office: {
    color: theme.palette.common.grey,
    marginTop: theme.spacing(0.5),
    fontSize: 18,
  },
  email: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));
