import { firestore, ERRORS } from 'services/firebase';
import sendNotification from 'services/notification';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as User } from 'store/user/reducer';

const Notification = {
  index: () => (dispatch, getState) => {
    const { current: user } = getState().user;

    firestore.collection('users').doc(user.id).collection('notifications')
      .orderBy('createdAt', 'desc')
      .limit(50)
      .onSnapshot((ref) => {
        const notifys = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(User.loadNotifys(notifys));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (user, data) => (dispatch) => {
    const now = new Date(Date.now());

    firestore.collection('users').doc(user)
      .collection('notifications').add({ ...data, readAt: null, createdAt: now })
      .then(() => sendNotification(user, data))
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  readed: (id) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: user } = getState().user;

    firestore.collection('users').doc(user.id)
      .collection('notifications').doc(id)
      .set({ readAt: now }, { merge: true })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Notification;
