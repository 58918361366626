import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 64px)',
    padding: `${theme.spacing(3)}px ${theme.spacing()}px`,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
    },
  },
  calendarTitle: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 22,
    textTransform: 'uppercase',
  },
  day: {
    width: 'calc(100% / 7)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #f6f6f6',
    flexDirection: 'column',
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px`,
    minHeight: 130,
    borderBottom: 0,
    borderLeft: 0,
    '&:nth-child(-n+7)': {
      borderTop: 0,
    },
    '&:nth-child(7n)': {
      borderRight: 0,
    },
  },
  weekDay: {
    width: 'calc(100% / 7)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(),
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dayLabel: {
    color: theme.palette.common.black,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
  },
  dayLabelDisabled: {
    color: '#bcbcbc',
    fontSize: 16,
    textAlign: 'center',
  },
  dayLabelToday: {
    position: 'relative',
    '&:after': {
      content: "' '",
      background: fade(theme.palette.secondary.main, 0.3),
      width: 38,
      height: 38,
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50%',
      transform: 'translate(-24%, -18%)',
    },
  },
  cash: {
    margin: '4px 0',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: fade(theme.palette.primary.main, 0.075),
    },
  },
  typePay: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.5),
    fontSize: 18,
    marginTop: 2,
    lineHeight: 1,
    fontWeight: 600,
  },
  typeReceive: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(0.5),
    fontSize: 18,
    marginTop: 2,
    lineHeight: 1,
    fontWeight: 600,
  },
  badge: {
    '& span': {
      position: 'inherit',
      transform: 'inherit',
      marginLeft: theme.spacing(0.5),
    },
  },
  contact: {
    color: theme.palette.common.grey,
    fontSize: 12,
    marginTop: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
