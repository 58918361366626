import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

const Account = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('accounts')
      .onSnapshot((ref) => {
        const accounts = ref.docs.map((a) => ({ id: a.id, ...a.data() }));
        dispatch(Company.loadAccounts(accounts));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('accounts').add({
        ...data, updatedAt: now, createdAt: now,
      })
      .then(() => {
        Mixpanel.track('Create an account');
        dispatch(Alert.show({ message: 'Account successfully created.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('accounts').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update an account');
        dispatch(Alert.show({ message: 'Account updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('accounts').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete an account');
        dispatch(Alert.show({ message: 'Account deleted successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Account;
