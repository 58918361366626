import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@material-ui/core/Grid';

// Icons, Commons, Assets and Styles
import logo from 'assets/logo.png';
import styles from './style';

function Auth({ children }) {
  const classes = styles();
  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      xs={11}
      sm={6}
      md={4}
    >
      <img alt="Logo" src={logo} className={classes.logo} />

      {children}
    </Grid>
  );
}

Auth.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Auth;
