import React from 'react';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import styles from './style';

export default function ({ categories, users }) {
  const classes = styles();

  const steps = [
    { value: '-1', label: 'No supplier', color: '#dc1f26' },
    { value: '0', label: 'Contact', color: '#4eb1d4' },
    { value: '1', label: 'Negotiation', color: '#e6d539' },
    { value: '2', label: 'Register', color: '#5c5aa7' },
    { value: '3', label: 'Closed', color: '#f59658' },
    { value: '4', label: 'Paid', color: '#4bb75c' },
  ];

  return [
    {
      name: 'productName',
      type: 'text',
      label: 'Product name',
    },
    {
      name: 'steps',
      type: 'select-chip',
      label: 'Steps',
      options: steps.map((step) => ({
        value: step.value,
        chip: { label: step.label },
        label: (
          <Grid container alignItems="center">
            <div className={classes.step} style={{ background: step.color }} />
            {step.label}
          </Grid>
        ),
      })),
    },
    {
      name: 'categories',
      type: 'select-chip',
      label: 'Categories',
      options: categories
        ? categories.map((c) => ({
          value: c.id,
          chip: { label: c.name },
          label: (
            <Grid container>
              <div className={classes.categoryColor} style={{ background: c.color }} />
              <div className={classes.categoryLabel}>
                {c.parentLabel && <span>{c.parentLabel}</span>}
                <p>{c.name}</p>
              </div>
            </Grid>
          ),
        }))
        : [],
    },
    {
      name: 'responsibles',
      type: 'select-chip',
      label: 'Responsibles',
      options: users
        ? users.filter((u) => !u.deletedAt).map((u) => ({
          value: u.id,
          chip: {
            label: u.name,
            avatar: <Avatar alt={u.name} src={u.avatar}>{u.initials}</Avatar>,
          },
          label: (
            <>
              <ListItemAvatar>
                <Avatar alt={u.name} src={u.avatar} className={classes.avatar}>
                  {u.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={u.name} />
            </>
          ),
        }))
        : [],
    },
    {
      name: 'currentValue.min',
      type: 'currency',
      label: 'From',
    },
    {
      name: 'currentValue.max',
      type: 'currency',
      label: 'To',
      validation: {
        minValue: {
          msg: 'The minimum value must be lower than the maximum one',
          params: { min: 'currentValue.min' },
        },
      },
    },
    {
      name: 'forecastValue.min',
      type: 'currency',
      label: 'From',
    },
    {
      name: 'forecastValue.max',
      type: 'currency',
      label: 'To',
      validation: {
        minValue: {
          msg: 'The minimum value must be lower than the maximum one',
          params: { min: 'forecastValue.min' },
        },
      },
    },
    {
      name: 'currentBiggerForecast',
      type: 'checkbox',
      label: 'CURRENT VALUE GREATER THAN ESTIMATED VALUE',
    },
    {
      name: 'paymentLate',
      type: 'checkbox',
      label: 'LATE PAYMENT',
    },
    {
      name: 'deliveryLate',
      type: 'checkbox',
      label: 'LATE DELIVERY',
    },
  ];
}
