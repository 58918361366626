import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { addDays, addWeeks, addMonths } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import { transferFiles } from 'common/form/upload/utils';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import Checklists from './checklist';
import styles from './style';

function TaskForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();
  const params = new URLSearchParams(history.location.search);
  const step = params.get('step');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [checklistMenu, setChecklistMenu] = useState(null);
  const [checklists, setChecklists] = useState([]);
  const [parent, setParent] = useState(null);
  const [parentError, setParentError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '',
    description: '',
    startAt: new Date(),
    endAt: null,
    duration: { type: 'hours', value: 1 },
    step: step || '',
    priority: 0,
    labels: [],
    attachments: [],
    responsibles: [],
    repeat: { on: false, type: 'dayly', times: 1 },
  });
  const [repeatOn, setRepeatOn] = useState(false);

  const { labels, users, steps } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.productivity || permissions.productivity.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (steps && !step) {
      const step = steps[0];
      if (step) setInitialForm((i) => ({ ...i, step: step.id }));
    }
  }, [step, steps]);

  const submit = async (data) => {
    if (!parent) return;
    setSubmitted(true);
    const attachments = await transferFiles(
      data.attachments,
      `/productivity-temp/${eventId}/tasks`,
      `/productivity/${eventId}/tasks`,
    );

    let workingAt = null;
    let completedAt = null;

    const completedSteps = steps.filter((s) => s.type === 'done').map((s) => s.id);
    const workingSteps = steps.filter((s) => s.type === 'working').map((s) => s.id);

    if (workingSteps.indexOf(data.step) >= 0) workingAt = new Date();
    if (completedSteps.indexOf(data.step) >= 0) {
      workingAt = new Date();
      completedAt = new Date();
    }

    dispatch(Log.store(`Created a new task ${data.repeat.on ? ' recurrent': ''} "${data.name}"`));
    dispatch(Task.store({
      ...data,
      event: eventId,
      checklists,
      parent: parent ? parent.id : null,
      labels: [parent.tag],
      workingAt,
      completedAt,
      attachments,
    }));

    if (data.repeat.on) {
      let { startAt, endAt } = data;
      const taskStep = steps.filter((s) => s.type === 'backlog').sort((a, b) => a.order - b.order)[0];
      for (let j = 0; j < data.repeat.times; j += 1) {
        if (data.repeat.type === 'dayly') {
          startAt = addDays(startAt, 1);
          endAt = addDays(endAt, 1);
        }
        if (data.repeat.type === 'weekly') {
          startAt = addWeeks(startAt, 1);
          endAt = addWeeks(endAt, 1);
        }
        if (data.repeat.type === 'monthly') {
          startAt = addMonths(startAt, 1);
          endAt = addMonths(endAt, 1);
        }
        dispatch(Task.store({
          ...data,
          startAt,
          endAt,
          step: taskStep.id,
          event: eventId,
          checklists,
          parent: parent ? parent.id : null,
          labels: [parent.tag],
          workingAt: null,
          completedAt: null,
          attachments,
        }));
      }
    }

    setSubmitted(false);
    history.goBack();
  };

  const closeForm = () => {
    dispatch(FormSave.remove('form-task'));
    history.goBack();
  };

  const baseUrl = (url) => {
    let base = `/planner/${eventId}/productivity`;
    if (history.location.pathname.includes('kanban')) base += '/kanban';
    return base + url;
  };

  const formSchema = FormConfig({ repeat: [repeatOn, setRepeatOn] });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={closeForm}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>Create new task</span>
          <IconButton onClick={closeForm} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {labels && users && steps && checklist ? (
          <Form
            id="form-task"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.name}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.description}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Subtask of</Typography>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Typography
                        className={classes.value}
                        onClick={(e) => setChecklistMenu(e.currentTarget)}
                      >
                        {parent ? parent.label : 'Select a checklist'}
                      </Typography>
                    </Grid>
                    <FormHelperText error={parentError}>
                      Link this task to an event Checklist item
                    </FormHelperText>

                    <Menu
                      anchorEl={checklistMenu}
                      open={!!checklistMenu}
                      onClose={() => setChecklistMenu(null)}
                    >
                      <MenuItem
                        className={classes.add}
                        onClick={() => history.push(baseUrl('/checklist'))}
                      >
                        Edit event checklist
                      </MenuItem>
                      {labels.map((l) => [
                        (
                          <ListSubheader
                            key={l.id}
                            className={classes.categoryLabel}
                            style={{ color: l.color }}
                          >
                            {l.name}
                          </ListSubheader>
                        ),
                        checklist.filter((c) => c.tag === l.id).map((c) => (
                          <MenuItem
                            key={c.id}
                            value={c.id}
                            onClick={() => {
                              setParent(c);
                              setChecklistMenu(null);
                              setParentError(false);
                            }}
                          >
                            {c.label}
                          </MenuItem>
                        )),
                      ])}
                    </Menu>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.step}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} sm={6}>
                    {inputs.startAt}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {inputs.endAt}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} sm={6}>
                    {inputs.duration}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {inputs.priority}
                  </Grid>
                </Grid>
                
                <Grid item xs={12} style={{ marginBottom: -8 }}>
                  <ExpansionPanel
                    className={classes.expand}
                    expanded={repeatOn}
                  >
                    <ExpansionPanelSummary classes={{ content: classes.expandSummary }}>
                      {inputs.repeat.on}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{ root: classes.expandDetail }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={9}>{inputs.repeat.type}</Grid>
                        <Grid item xs={12} sm={3}>{inputs.repeat.times}</Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.labels}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.responsibles}
                  </Grid>
                </Grid>

                <Checklists value={checklists} onChange={setChecklists} />

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.attachments}
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        ) : (
          <Loading ready={!!labels && !!users && !!steps && !!checklist} content={400} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="form-task"
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={submitted}
          onClick={() => setParentError(!parent)}
        >
          {submitted ? 'Creating' : 'Create'}
          {submitted && (
            <CircularProgress size={16} color="primary" style={{ marginLeft: 8 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskForm;
