import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';
import CostCenter from 'store/financial/cost-center/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import {
  X, Edit2, ArrowLeft, Check, Trash2,
} from 'react-feather';
import {
  supplierWithProducts,
  productWithCategory,
  supplierWithResponsible,
} from 'common/utils/relationships';
import Loading from 'common/loading';
import CashFlow from 'store/financial/cash-flow/controller';
import * as Form from './form';
import Responsibles from './responsibles';
import styles from './style';

// Components
import ExpansionInfo from './components/expansions';
import ButtonsInfo from './components/buttons';

function SupplierView() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, supplierId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    users, categories, cashFlow, costCenter,
  } = useSelector((state) => state.company);
  const { suppliers, products } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [supplier, setSupplier] = useState(null);
  const [supplierCategories, setSupplierCategories] = useState([]);
  const [edit, setEdit] = useState(false);

  const steps = ['CONTACT', 'NEGOTIATION', 'REGISTER', 'CLOSED', 'PAID'];

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  const isLoaded = !!categories && !!products && !!suppliers && !!cashFlow && !!costCenter;

  useEffect(() => {
    if (!costCenter) dispatch(CostCenter.index());
  }, [costCenter, dispatch]);

  useEffect(() => {
    if (supplierId && isLoaded) {
      let data = suppliers.find((s) => s.id === supplierId);

      if (!data) {
        history.replace(`/manager/${eventId}/procurement/negotiations`);
        return;
      }

      data = supplierWithProducts(data, products);
      data = supplierWithResponsible(data, users);

      const suppCategories = [];
      data.products.forEach((product) => {
        const { category } = productWithCategory(product, categories);
        const index = suppCategories.indexOf(suppCategories.find((c) => c.id === category.id));

        if (index >= 0) {
          suppCategories[index].products.push(product);
        } else {
          suppCategories.push({ ...category, products: [product] });
        }
      });

      setSupplierCategories(suppCategories);
      setSupplier(data);
    }
  }, [categories, eventId, history, isLoaded, products, supplierId, suppliers, users]);

  const removeCashFlow = (installments) => {
    const isPayed = installments.find((i) => {
      const cash = cashFlow.find((c) => c.id === i.cashFlow);
      return cash && cash.completed.on;
    });

    if (isPayed) {
      dispatch(Alert.show({
        message: 'You cannot return a supplier that has already been paid',
        type: 'error',
      }));
    } else {
      dispatch(Supplier.update(supplier.id, {
        status: supplier.status - 1,
        payment: {
          ...supplier.payment,
          installments: supplier.payment.installments.map((i) => ({ ...i, cashFlow: null })),
        },
      }));
      installments.forEach((i) => {
        const cash = cashFlow.find((c) => c.id === i.cashFlow);
        if (cash) dispatch(CashFlow.destroy(i.cashFlow));
      });
    }
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      scroll="body"
      fullScreen={fullScreen}
      onClose={() => history.goBack()}
      classes={{ root: classes.dialog }}
      aria-labelledby="view-supplier-dialog"
    >
      <DialogContent className={classes.dialogContent}>
        {supplier ? (
          <>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap">
              <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                <Grid container>
                  {edit === 'name' ? (
                    <Form.EditName supplier={supplier} onSubmit={() => setEdit(null)} />
                  ) : (
                    <Typography variant="h4" className={classes.supplierName}>
                      {supplier.name}
                    </Typography>
                  )}
                </Grid>
                {edit !== 'name' ? (
                  permissionEdit && supplier.status < 2 && (
                    <IconButton onClick={() => setEdit('name')}>
                      <Edit2 size={18} />
                    </IconButton>
                  )
                ) : (
                  <>
                    <IconButton
                      onClick={() => setEdit(null)}
                      className={classes.btnCancel}
                      style={{ margin: '0 0 0 8px' }}
                    >
                      <X size={16} />
                    </IconButton>
                    <IconButton
                      type="submit"
                      form="form-supplier-name"
                      className={classes.btnConfirm}
                      style={{ margin: 0 }}
                    >
                      <Check size={16} />
                    </IconButton>
                  </>
                )}
              </Grid>
              <IconButton onClick={() => history.goBack()} aria-label="Sair">
                <X />
              </IconButton>
            </Grid>

            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              wrap="nowrap"
              style={{ margin: '24px 0' }}
            >
              <Grid container>
                {supplierCategories.map((category, i) => (
                  <Grid item key={category.id} className={classes.categoryContainer}>
                    <div
                      className={classes.categoryColor}
                      style={{ backgroundColor: category.color }}
                    />
                    <Typography className={classes.categoryLabel}>{category.name}</Typography>
                    {category.products.map((product, j) => (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          component="button"
                          className={classes.categoryProduct}
                          onClick={() => history.replace(`/manager/${eventId}/procurement/negotiations?products=${product.id}`)}
                        >
                          {product.name}
                        </Link>
                        {permissionEdit && supplier.status === 0 && supplier.products.length > 1
                          && (
                            <Trash2
                              size={12}
                              className={classes.productButton}
                              onClick={() => {
                                const newProducts = supplier.products.map((p) => p.id || p);
                                newProducts.splice(i, 1);
                                dispatch(Supplier.update(supplier.id, { products: newProducts }));
                              }}
                            />
                          )}
                        {j + 2 === category.products.length ? ' e ' : j + 1 !== category.products.length && ', '}
                      </>
                    ))}
                  </Grid>
                ))}

                {permissionEdit && supplier.status === 0 && (
                  <Form.EditProducts supplier={supplier} onSubmit={() => setEdit(null)} />
                )}
              </Grid>

              <Responsibles
                id={supplier.id}
                name={supplier.name}
                responsible={supplier.responsible}
              />
            </Grid>

            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
              style={{ margin: '0 0 12px' }}
            >
              <Grid container>
                <Grid container>
                  <Typography variant="overline" className={classes.title} style={{ marginBottom: 8 }}>
                    Notes
                  </Typography>
                </Grid>

                {edit === 'notes' ? (
                  <>
                    <Form.EditNotes supplier={supplier} onSubmit={() => setEdit(null)} />
                    <Button type="submit" form="form-supplier-notes" className={classes.btnConfirm}>
                      <Check size={16} style={{ marginRight: 8 }} />
                      Save
                    </Button>
                    <Button onClick={() => setEdit(null)} className={classes.btnCancel}>
                      <X size={16} style={{ marginRight: 8 }} />
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Typography className={!supplier.notes ? classes.emptyNote : ''}>
                    {supplier.notes || 'Write down some notes about this supplier here...'}
                  </Typography>
                )}
              </Grid>
              {permissionEdit && edit !== 'notes' && (
                <IconButton onClick={() => setEdit('notes')}>
                  <Edit2 size={18} />
                </IconButton>
              )}
            </Grid>

            <ExpansionInfo supplier={supplier} edit={edit} setEdit={setEdit} />

            {permissionEdit && !edit && (
              <>
                <ButtonsInfo supplier={supplier} />

                {!supplier.interrupted
                  && supplier.status > 0 && supplier.status < 4 && !supplier.archive
                  && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        dispatch(Log.store(`Kicked back the supplier ${supplier.name} to the step ${
                          steps[supplier.status - 1]
                        }`));
                        if (supplier.status === 3) {
                          removeCashFlow(supplier.payment.installments);
                        } else {
                          dispatch(Supplier.update(supplier.id, {
                            status: supplier.status - 1,
                          }));
                        }
                      }}
                      className={classes.prevButton}
                    >
                      <ArrowLeft size={16} style={{ marginRight: 8 }} />
                      Previous step
                    </Button>
                  )}
              </>
            )}
          </>
        ) : (
          <Loading ready={isLoaded} content={400} />
        )}
      </DialogContent>
    </Dialog>
  );
}
export default SupplierView;
