import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Commons, Components and styles
import PieChart from 'common/charts/pie-chart';

function generateData(suppliers, event, products, cashFlow) {
  // Set default chart's data
  const stepsSuppliers = [
    {
      id: 'payed',
      value: 0,
      name: 'Paid',
      color: '#4bb75c',
    },
    {
      id: 'closed',
      value: 0,
      name: 'Closed',
      color: '#f59658',
    },
    {
      id: 'dealering',
      value: 0,
      name: 'Negotiation',
      color: '#e6d539',
    },
    {
      id: 'remaining',
      value: 0,
      name: 'Unallocated',
      color: '#4eb1d4',
    },
  ];

  // Set step's values
  suppliers.forEach((supplier) => {
    if (supplier.budget) {
      if (supplier.payment && supplier.payment.installments) {
        supplier.payment.installments.forEach((i) => {
          const cash = cashFlow.find((c) => c.id === i.cashFlow);
          if (cash && cash.completed.on) {
            stepsSuppliers[0].value += cash.completed.value;
          } else if (supplier.status === 3) {
            stepsSuppliers[1].value += i.value;
          } else if (supplier.status < 3) {
            stepsSuppliers[2].value += i.value;
          }
        });
      } else {
        stepsSuppliers[2].value += supplier.budget.total;
      }
    }
  });

  // Calculate total
  const total = stepsSuppliers.reduce((count, step) => count + step.value, 0);

  // Calculate budget forecast
  let budgetForecastValue = products.reduce((c, p) => c + p.valueForecast, 0);

  if (event.settings && event.settings.suppliers && event.settings.suppliers.budget) {
    const { type, value } = event.settings.suppliers.budget;
    if (type === 'default') budgetForecastValue = value;
  }

  // Define remaining budget
  const remaining = budgetForecastValue - total;
  stepsSuppliers[3].value = remaining >= 0 ? remaining : 0;

  return stepsSuppliers;
}

function StepsChart() {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { products, suppliers, current: event } = useSelector((state) => state.event);
  const { cashFlow } = useSelector((state) => state.company);

  const isLoaded = !!suppliers && !!products && !!cashFlow;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (isLoaded) {
      setData(generateData(suppliers, event, products, cashFlow));
    }
  }, [isLoaded, products, suppliers, event, cashFlow]);

  if (data.length === 0) return false;

  return (
    <PieChart
      data={data}
      direction={upSm ? 'right' : 'center'}
      title="By step"
      loaded={isLoaded}
    />
  );
}

export default StepsChart;
