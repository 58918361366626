import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    margin: `${theme.spacing()}px 0`,
    color: theme.palette.primary.main,
  },
  stepIcon: {
    marginRight: 12,
  },
  label: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600,
  },
  priority: {
    position: 'relative',
    fontSize: 16,
    padding: '4px 28px 4px 0',
    borderRadius: 32,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: 16,
  },
  addButton: {
    padding: 7,
    marginBottom: 8,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.9),
    },
  },

  iconOutlined: {
    right: 7,
  },
  icon: {
    top: 'calc(50% - 12px)',
    color: 'rgba(0, 0, 0, 0.54)',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
  },
  svgIcon: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
  },
}));
