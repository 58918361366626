import { getProductValue } from 'common/utils/suppliers';

const STEPS = [
  { color: '#dc1f26', name: 'No suppleir' },
  { color: '#4eb1d4', name: 'Getting in touch' },
  { color: '#e6d539', name: 'Negotiation' },
  { color: '#5c5aa7', name: 'Hiring' },
  { color: '#f59658', name: 'Closed' },
  { color: '#4bb75c', name: 'Paid' },
  { color: '#4bb75c', name: 'Paid' },
];

export function formatSteps(steps) {
  let formatedSteps = { title: '', colors: [] };
  if (steps.length > 1) {
    const stepsNames = steps.map((s) => STEPS[s + 1].name);
    formatedSteps = {
      title: `${stepsNames.slice(0, -1).join(', ')} e ${stepsNames.slice(-1)}`,
      color: steps.map((s) => STEPS[s + 1].color),
    };
  } else {
    const step = steps.length === 1 ? steps[0] + 1 : 0;
    formatedSteps = { title: STEPS[step].name, color: [STEPS[step].color] };
  }

  return formatedSteps;
}

export function generateTableData({
  suppliers, products, categories, users,
}) {
  const tableData = categories.filter((c) => c.type === 'out').map((category) => {
    const productsCategory = products.filter((p) => p.category === category.id)
      .map((item) => {
        const { responsible, ...product } = item;
        const productSuppliers = suppliers.filter(
          (s) => s.products.includes(product.id) && !s.interrupted,
        );

        const productResponsible = users.find((r) => r.id === responsible);

        const steps = [];
        const responsibles = productResponsible ? [productResponsible] : [];
        let unitValue = 0;
        let amount = 0;
        let currentValue = { value: 0, isInsideForecast: true };
        let payment = { date: null, payed: false, isDue: true };
        let delivery = { date: null, done: false, isDue: true };

        // Function to sum steps values
        const reducer = (count, value) => count + value;
        let countStepNegotiation = 0;
        let countClosedSuppliers = 0;

        productSuppliers.sort((a, b) => a.status - b.status);

        productSuppliers.forEach((supplier) => {
          // Set product's step
          let newStep = false;
          if (!steps.includes(supplier.status)) {
            const oldSteps = steps.reduce(reducer, 0);

            steps.push(supplier.status);
            const newSteps = steps.reduce(reducer, 0);

            /**
             * Stes reduce
             * <= 1: include 0 and 1
             * > 1: include 0, 1, 2, 3 and 4
             */
            if (oldSteps <= 1 && newSteps > 1) newStep = true;
          }

          // Set product's responsibles
          if (supplier.responsible && !responsibles.find((r) => r.id === supplier.responsible)) {
            const user = users.find((r) => r.id === supplier.responsible);
            if (user) responsibles.push(user);
          }

          if (supplier.status === 0) return supplier;
          if (supplier.status === 1) countStepNegotiation += 1;
          if (supplier.status > 1) countClosedSuppliers += 1;

          // Set product's currentValue
          if (supplier.budget) {
            let value = getProductValue(supplier, product.id);
            if (!newStep) {
              value += currentValue.value;
              if (supplier.budget.products[product.id]) {
                amount += supplier.budget.products[product.id].amount;
                unitValue += supplier.budget.products[product.id].value;
              }
            } else if (supplier.budget.products[product.id]) {
              amount = supplier.budget.products[product.id].amount;
              unitValue = supplier.budget.products[product.id].value;
            }

            currentValue = { value, isInsideForecast: value <= product.valueForecast };
          }

          const now = new Date();
          // Set product's payment
          if (supplier.status >= 2 && supplier.payment) {
            if (supplier.status === 4) {
              const installment = supplier.payment.installments.sort((a, b) => {
                if (a.cashFlow.completed.date.toDate() > b.cashFlow.completed.date.toDate) {
                  return 1;
                }
                return -1;
              })[0];
              const paymentDate = installment.cashFlow.completed.date.toDate();

              if (
                !payment.date
                || (!steps.includes(2) && !steps.includes(3) && payment.date < paymentDate)
              ) {
                payment = { date: paymentDate, payed: true };
              }
            } else {
              const installment = supplier.payment.installments.filter((i) => !i.payed)[0];
              const paymentDate = installment.due.toDate();

              if (!payment.date || payment.date > paymentDate) {
                payment = { date: paymentDate, payed: false, isDue: paymentDate <= now };
              }
            }
          }

          // Set product's delivery
          if (supplier.status >= 3 && supplier.delivery) {
            if (supplier.delivery.estimated) {
              let deliveryDate = supplier.delivery.estimated.toDate();

              if (!supplier.delivery.done) {
                if (!delivery.date || delivery.done || delivery.date < deliveryDate) {
                  delivery = { date: deliveryDate, done: false, isDue: deliveryDate <= now };
                }
              } else {
                deliveryDate = supplier.delivery.done.toDate();

                if (!delivery.date || (delivery.done && delivery.date < deliveryDate)) {
                  delivery = { date: deliveryDate, done: true };
                }
              }
            }
          }

          return true;
        });

        // Stes reduce === 1: include all then contain 0 and/or 1
        if (steps.reduce(reducer, 0) === 1) {
          const value = currentValue.value / countStepNegotiation;
          currentValue = { value, isInsideForecast: value <= product.valueForecast };
          unitValue /= countStepNegotiation;
        } else {
          unitValue /= countClosedSuppliers;
        }


        steps.sort();

        return {
          ...product,
          steps,
          formatedSteps: formatSteps(steps),
          responsibles,
          unitValue,
          amount,
          currentValue,
          payment,
          delivery,
        };
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return -1;
      });

    return { ...category, products: productsCategory };
  });

  return tableData.filter((c) => c.products.length > 0)
    .sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return -1;
    });
}

export function filter(data, search) {
  let filteredData = data;

  const params = new URLSearchParams(search);
  const product = params.get('product');
  const steps = params.get('steps') && params.get('steps').split(',');
  const categories = params.get('categories') && params.get('categories').split(',');
  const responsibles = params.get('responsibles') && params.get('responsibles').split(',');
  const currentValue = { min: params.get('currentMin'), max: params.get('currentMax') };
  const forecastValue = { min: params.get('forecastMin'), max: params.get('forecastMax') };
  const currentBiggerForecast = params.get('currentBiggerForecast');
  const paymentLate = params.get('paymentLate');
  const deliveryLate = params.get('deliveryLate');

  if (product) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter(
        (p) => p.name.toLowerCase().indexOf(product.toLowerCase()) >= 0,
      );
      return category;
    });
  }

  if (steps) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter((p) => {
        let match = false;
        steps.forEach((s) => {
          if (s !== '-1') {
            if (p.steps.indexOf(parseInt(s, 10)) >= 0) match = true;
          } else if (p.steps.length === 0) match = true;
        });
        return match;
      });
      return category;
    });
  }

  if (categories) {
    filteredData = filteredData.filter((c) => categories.includes(c.id));
  }

  if (responsibles) {
    filteredData = filteredData
      .map((c) => {
        const category = c;
        category.products = c.products.filter(
          (p) => p.responsibles.some((r) => responsibles.includes(r.id)),
        );
        return category;
      });
  }

  if (currentValue.min) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter(
        (p) => p.currentValue.value >= currentValue.min
          && p.currentValue.value <= currentValue.max,
      );
      return category;
    });
  }

  if (forecastValue.min) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter(
        (p) => p.valueForecast >= forecastValue.min && p.valueForecast <= forecastValue.max,
      );
      return category;
    });
  }

  if (currentBiggerForecast) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter((p) => !p.currentValue.isInsideForecast);
      return category;
    });
  }

  if (paymentLate) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter((p) => p.payment.date && p.payment.isDue);
      return category;
    });
  }

  if (deliveryLate) {
    filteredData = filteredData.map((c) => {
      const category = c;
      category.products = c.products.filter((p) => p.delivery.date && p.delivery.isDue);
      return category;
    });
  }

  return filteredData.filter((c) => c.products.length > 0);
}
