function FormConfig({ labels, labelId }) {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'Tag name',
      validation: {
        required: 'Tag name is required',
        unique: {
          msg: 'There is a tag with this name already',
          params: {
            list: labels
              ? labels
                .filter((c) => c.id !== labelId)
                .map((c) => c.name)
              : [],
          },
        },
      },
    },
    {
      name: 'color',
      type: 'color-picker',
      validation: { required: 'A color tag is required' },
    },
  ];
}

export default FormConfig;
