// Main Components
import Financial from 'app/manager/financial';

// Cash Flow Components
import CashFlowForm from 'app/manager/financial/children/cash-flow/form';
import CashFlowPay from 'app/manager/financial/children/cash-flow/pay';
// import CashFlowPayBatch from 'app/manager/financial/children/cash-flow/pay-batch';
import CashFlowReceive from 'app/manager/financial/children/cash-flow/receive';
import CashFlowView from 'app/manager/financial/children/cash-flow/view';

import ContactForm from 'app/home/register/contacts/form';

// Routes Components
import PrivateRoute from 'routes/components/PrivateRoute';

// Common Routes
import CommonRoutes from '../commons';

export default [
  {
    path: '/manager/:eventId/financial',
    component: Financial,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      // Commons
      ...CommonRoutes('/manager/:eventId/financial'),
      {
        path: '/manager/:eventId/financial/contact',
        component: ContactForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      // Cash Flow
      {
        path: '/manager/:eventId/financial/cash-flow',
        component: CashFlowForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/manager/:eventId/financial/cash-flow/:cashId',
        component: CashFlowView,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/manager/:eventId/financial/cash-flow/:cashId/edit',
        component: CashFlowForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      // {
      //   path: '/manager/:eventId/financial/pay',
      //   component: CashFlowPayBatch,
      //   routerComponent: PrivateRoute,
      //   exact: true,
      // },
      {
        path: '/manager/:eventId/financial/pay/:cashId',
        component: CashFlowPay,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/manager/:eventId/financial/receive/:cashId',
        component: CashFlowReceive,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
];
