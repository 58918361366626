import { makeStyles, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  infoTitle: {
    color: theme.palette.primary.main,
    fontSize: 12,
    lineHeight: 1.5,
  },
  infoContent: {
    flexWrap: 'nowrap',
  },
  infoIcon: {
    marginRight: theme.spacing(),
    flex: 'none',
  },
  infoText: {
    color: theme.palette.common.grey,
    fontSize: 16,
    wordBreak: 'break-word',
  },
  site: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.primary.main,
    },
  },
  containerBtns: {
    position: 'fixed',
    bottom: 0,
    maxWidth: 600,
    background: 'white',
    padding: 24,
    marginLeft: -24,
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
    },
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    marginRight: 16,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
    },
  },
  buttonConfirm: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
    },
  },
}));
