import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    margin: '0 auto',
  },
  logo: {
    display: 'block',
    height: '6rem',
    margin: `${theme.spacing(2)}px auto`,
  },
}));
