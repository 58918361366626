import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    position: 'relative',
    marginTop: theme.spacing(),
    width: '100%',
    overflow: 'initial',
  },
  actionArea: {
    '& > span': {
      backgroundColor: 'white',
      '&:last-child': {
        background: 'none',
      },
    },
  },
  priority: {
    fontSize: 12,
    width: 20,
    minWidth: 20,
    borderRadius: 30,
    height: 20,
    textAlign: 'center',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
    zIndex: 1,
  },
  label: {
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 12,
    fontSize: 11,
    fontWeight: 600,
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
  },
  taskName: {
    marginTop: theme.spacing(0.5),
    fontSize: 18,
    whiteSpace: 'normal',
  },
  avatar: {
    background: theme.palette.primary.main,
    marginRight: theme.spacing(-0.5),
    width: 24,
    height: 24,
    fontSize: 12,
    '&:first-child': {
      marginRight: 0,
    },
  },
  checked: {
    width: 'fit-content',
    padding: '4px 8px',
    background: theme.palette.success.main,
    borderRadius: 8,
  },
  due: {
    width: 'fit-content',
    padding: '4px 8px',
    background: theme.palette.error.main,
    borderRadius: 8,
    color: 'white',
    whiteSpace: 'nowrap',
  },
  badge: {
    right: -6,
    top: -6,
  },
}));
