import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import matchHighlight from 'autosuggest-highlight/match';
import parseHighlight from 'autosuggest-highlight/parse';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// Commons and Styles
import { categoryWithProducts } from 'common/utils/relationships';
import styles from './style';

export default function ({ initialForm, edit }) {
  const classes = styles();
  const history = useHistory();
  const { eventId } = useParams();

  const { products } = useSelector((store) => store.event);
  const { categories, contacts } = useSelector((store) => store.company);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const listCities = useCallback(async (state) => {
    if (states.length > 0) {
      const stateData = states.find((s) => s.sigla === state);
      if (stateData) {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateData.id}/municipios`,
        );
        setCities(await response.json());
      }
    }
  }, [states]);

  const fetchStates = async () => {
    const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    setStates(await response.json());
  };

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (initialForm.contact.state !== '') listCities(initialForm.contact.state);
  }, [initialForm.contact.state, listCities]);

  const baseUrl = (to) => {
    let url = `/manager/${eventId}/procurement`;
    if (history.location.pathname.indexOf('negotiations') >= 0) url += '/negotiations';
    return url + to;
  };

  return [
    {
      name: 'name',
      type: 'autosuggest',
      label: 'Company name',
      placeholder: 'Search in the contact list',
      keyList: 'name',
      disabled: edit && initialForm.existentContact,
      list: contacts ? contacts.filter((c) => c.types.indexOf('Supplier') >= 0) : [],
      addMore: {
        label: 'Add contact',
        onClick: () => history.push(`/manager/${eventId}/financial/contact`),
      },
      onChange: (value, state) => {
        if (value.name) {
          _.set(state, 'existentContact', true);
          _.set(state, 'contact.email', value.contact.email || '');
          _.set(state, 'contact.phone', value.contact.phone || '');
          _.set(state, 'contact.site', value.contact.site || '');
          if (value.address.postalCode) {
            _.set(state, 'contact.city', value.address.city || '');
            _.set(state, 'contact.state', value.address.state || '');
          }
        } else if (_.get(state, 'existentContact')) {
          _.set(state, 'existentContact', false);
          _.set(state, 'contact.email', '');
          _.set(state, 'contact.phone', '');
          _.set(state, 'contact.site', '');
          _.set(state, 'contact.city', '');
          _.set(state, 'contact.state', '');
        }
        return state;
      },
      renderItem: (item, { query, isHighlighted }) => {
        const matches = matchHighlight(item.name, query);
        const parts = parseHighlight(item.name, matches);

        return (
          <MenuItem
            selected={isHighlighted}
            component="div"
            className={item.id && item.id === 'autosuggestAddMore' ? classes.add : ''}
          >
            <Grid container direction="column">
              <Grid container justify="space-between" alignItems="center">
                <p>
                  {parts.map((part, index) => (
                    <span
                      key={String(index)}
                      style={{
                        fontWeight: part.highlight && item.id !== 'autosuggestAddMore' ? 600 : 300,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </p>
                <span style={{ fontSize: 12, color: '#777', fontWeight: 500 }}>
                  {item.document}
                </span>
              </Grid>
              {item.address && item.address.postalCode && (
                <span style={{ fontSize: 10, color: '#777', fontWeight: 300 }}>
                  {/* {`${item.address.neighborhood},  */}
                  {`${item.address.city} - ${item.address.state}`}
                </span>
              )}
            </Grid>
          </MenuItem>
        );
      },
      validation: { required: 'Supplier name is required' },
    },
    {
      name: 'products',
      type: 'select-chip',
      label: 'Products',
      headers: categories && products
        ? categories
          .filter((c) => c.type === 'out')
          .map((c) => categoryWithProducts(c, products))
          .map((c) => ({
            id: c.id,
            className: classes.categoryLabel,
            style: { color: c.color },
            title: c.name,
            items: c.products
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((p) => ({
                value: p.id,
                label: p.name,
              })),
          }))
        : [],
      options: products ? products.map((p) => ({ value: p.id, label: p.name })) : [],
      validation: { required: 'At least one product is required' },
      addOption: {
        label: 'Add product',
        url: baseUrl('/products/new'),
      },
    },
    {
      name: 'contact.name',
      type: 'text',
      label: 'Name',
    },
    {
      name: 'contact.email',
      type: 'text',
      label: 'E-mail',
      validation: {
        required: 'The e-mail is required',
        email: 'Must be a valid e-mail',
      },
    },
    {
      name: 'contact.phone',
      type: 'text',
      label: 'Phone',
      mask: 'phone',
      validation: { phone: 'Must be a valid phone' },
    },
    {
      name: 'contact.site',
      type: 'text',
      label: 'Website',
      validation: { url: 'Must be a valid url' },
    },
    {
      name: 'notes',
      type: 'text',
      multiline: true,
    },
    {
      name: 'contact.city',
      type: 'autosuggest',
      label: 'City',
      disabled: cities.length === 0,
      placeholder: 'Search for city',
      keyList: 'nome',
      list: cities,
      helperText: cities.length === 0
        ? 'Select the state before selecting a city' : '',
    },
    {
      name: 'contact.state',
      type: 'select',
      label: 'State',
      options: states.sort((a, b) => a.sigla - b.sigla)
        .map((state) => ({ value: state.sigla, label: state.sigla })),
      onChange: (value, state) => {
        listCities(value);
        _.set(state, 'contact.city', '');
        return state;
      },
    },
  ];
}
