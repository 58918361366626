function FormConfig({ saved, setSaved }) {
  const themesInputs = [];

  saved.forEach((theme, i) => {
    themesInputs.push({
      name: `[${i}].name`,
      type: 'text',
      label: 'Persona name',
      size: 'small',
      onChange: (value, state) => {
        const newSaved = [...saved];
        newSaved[i].name = value;
        setSaved(newSaved);
        return state;
      },
    });
    themesInputs.push({
      name: `[${i}].description`,
      type: 'text',
      multiline: true,
      size: 'small',
      onChange: (value, state) => {
        const newSaved = [...saved];
        newSaved[i].description = value;
        setSaved(newSaved);
        return state;
      },
    });
    themesInputs.push({
      name: `[${i}].expected`,
      type: 'text',
      multiline: true,
      size: 'small',
      onChange: (value, state) => {
        const newSaved = [...saved];
        newSaved[i].expected = value;
        setSaved(newSaved);
        return state;
      },
    });
    themesInputs.push({
      name: `[${i}].engagement`,
      type: 'text',
      multiline: true,
      placeholder: 'Ex: Instagram, e-mail, linkedin, etc.',
      size: 'small',
      onChange: (value, state) => {
        const newSaved = [...saved];
        newSaved[i].engagement = value;
        setSaved(newSaved);
        return state;
      },
    });
    themesInputs.push({
      name: `[${i}].data`,
      type: 'text',
      multiline: true,
      size: 'small',
      onChange: (value, state) => {
        const newSaved = [...saved];
        newSaved[i].data = value;
        setSaved(newSaved);
        return state;
      },
    });
  });

  return themesInputs;
}

export default FormConfig;
