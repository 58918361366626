import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  passwordInput: {
    width: '100%',
  },
  button: {
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    width: '100%',
    margin: `${theme.spacing()}px 0`,
  },
  iconError: {
    opacity: 0.9,
    marginRight: theme.spacing(),
    minWidth: 20,
  },
  resetPassword: {
    float: 'right',
    margin: '10px 0',
  },
}));
