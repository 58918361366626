import { makeStyles, darken, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(),
    right: theme.spacing(),
    '& .MuiSpeedDial-fab': {
      background: theme.palette.secondary.main,
      '&:hover': {
        background: darken(theme.palette.secondary.main, 0.2),
      },
    },
  },

  mainFab: {
    position: 'fixed',
    bottom: theme.spacing(),
    right: theme.spacing(),
    zIndex: 1100,
    boxShadow: theme.shadows[1],
    
    animationName: 'space',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
  },
  actionFab: {
    position: 'fixed',
    bottom: theme.spacing(),
    right: theme.spacing(),
    width: 50,
    height: 50,
    zIndex: 1000,
    opacity: 0,
    boxShadow: theme.shadows[1],
    transform: 'scale(0)',
    transition: 'all 0.2s ease-in',
  },
  disabledFab: {
    background: `${lighten(theme.palette.primary.main, 0.7)} !important`,
  },

  badge: {
    right: -8,
    top: -8,
  },
  btnAction: {
    color: 'white',
    background: theme.palette.primary.main,
    width: 56,
    height: 56,
    '&:hover': {
      background: darken(theme.palette.primary.main, 0.2),
    },
  },
}));
