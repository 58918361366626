import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Material Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Styles and Options
import Loading from 'common/loading';
import { Typography } from '@material-ui/core';
import styles from './style';
import { getOptions, generateData } from './utils';

function CashFlowChart() {
  const classes = styles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { eventId } = useParams();

  const { current: user } = useSelector((store) => store.user);
  const { tasks } = useSelector((store) => store.company);

  const [chartData, setChartData] = useState([[], [], [], [], []]);
  const [userFilter, setUserFilter] = useState('team');

  useEffect(() => {
    if (tasks) {
      const evtTasks = tasks.filter((c) => c.event === eventId);
      setChartData(generateData(evtTasks, userFilter, user.id));
    }
  }, [eventId, tasks, user.id, userFilter]);

  return (
    <Grid container style={{ height: '100%' }}>
      {!tasks ? (
        <Loading ready={!!tasks} content={300} />
      ) : (
        <>
          <Grid
            container
            direction={mobile ? 'column' : 'row'}
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Typography className={classes.title}>EVOLUTION OF TASKS</Typography>
            <ButtonGroup
              size="small"
              color="secondary"
              aria-label="small outlined primary button group"
            >
              <Button
                variant={userFilter === 'me' ? 'contained' : 'outlined'}
                onClick={() => setUserFilter('me')}
              >
                ME
              </Button>
              <Button
                variant={userFilter === 'team' ? 'contained' : 'outlined'}
                onClick={() => setUserFilter('team')}
              >
                Team
              </Button>
            </ButtonGroup>
          </Grid>
          {chartData[0].length > 0 ? (
            <ReactEcharts
              option={getOptions(chartData)}
              className={classes.chart}
              notMerge
              lazyUpdate
              showLoading={false}
              opts={{ renderer: 'svg' }}
            />
          ) : (
            <Grid container justify="center" alignItems="center" style={{ width: '100%' }}>
              <Typography variant="h4" className={classes.nodata}>
                No data to show on the chart.
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default CashFlowChart;
