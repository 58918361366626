import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 130px)',
    marginLeft: 130,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  row: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    margin: 0,
  },
  rowColumn: {
    width: '100%',
    padding: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    margin: theme.spacing(-1),
  },
}));
