import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

function FormConfig({
  edit, disabledType, setType, isDefault,
}) {
  const { categoryId } = useParams();
  const { categories } = useSelector((store) => store.company);

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Category name',
      disabled: isDefault,
      validation: !edit ? {
        required: 'Category name is required',
        unique: {
          msg: 'A category with that name already exists',
          params: {
            list: categories
              ? categories
                .filter((c) => c.id !== categoryId)
                .map((c) => c.name)
              : [],
          },
        },
      } : { required: 'Category name is required' },
    },
    {
      name: 'type',
      type: 'select',
      label: 'Tipo',
      disabled: disabledType,
      options: [
        { value: 'in', label: 'Revenue' },
        { value: 'out', label: 'Expenses' },
      ],
      onChange: (value, state) => {
        setType(value);
        return state;
      },
      validation: { required: 'Select one type' },
    },
    {
      name: 'color',
      type: 'color-picker',
      validation: { required: 'Category color is required' },
    },
  ];
}

export default FormConfig;
