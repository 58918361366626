import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  label: {
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 24,
    padding: `0 ${theme.spacing()}px`,
    '& > p': {
      position: 'relative',
      display: 'inline-block',
      zIndex: 1,
      lineHeight: '32px',
      margin: 0,
    },
    '& > button': {
      display: 'inline-block',
      marginLeft: -16,
      transition: 'margin 0.2s ease-out',
      padding: 8,
      opacity: 0,
    },
    '&:hover > button': {
      marginLeft: 8,
      opacity: 1,
    },
  },
  addButton: {
    padding: 7,
    marginBottom: 8,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.9),
    },
  },
  menu: {
    marginTop: 32,
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
  labelColor: {
    width: 8,
    borderRadius: 6,
    height: 24,
    marginRight: 8,
  },
}));
