import { isSameDay } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export function filter(cashFlow, search, allCategories) {
  const params = new URLSearchParams(search);

  const onlyType = params.get('onlyType');
  const date = {
    min: params.get('dateMin') && new Date(parseInt(params.get('dateMin'), 10)),
    max: params.get('dateMax') && new Date(parseInt(params.get('dateMax'), 10)),
  };
  const categories = params.get('categories') && params.get('categories').split(',');
  const costCenter = params.get('costCenter') && params.get('costCenter').split(',');
  const value = {
    min: parseFloat(params.get('valueMin')),
    max: parseFloat(params.get('valueMax')),
  };
  const dueLate = Boolean(params.get('dueLate'));

  return cashFlow.filter((cash) => {
    let isMatch = true;

    if (onlyType) {
      if (onlyType !== cash.type) isMatch = false;
    }

    if (date.min && date.max) {
      const compareDate = (dt) => {
        const okMin = dt > date.min || isSameDay(dt, date.min);
        const okMax = dt < date.max || isSameDay(dt, date.max);

        return okMin && okMax;
      };

      if (cash.completed.on) {
        if (!compareDate(cash.completed.date.toDate())) isMatch = false;
      } else if (!compareDate(cash.dueAt.toDate())) {
        isMatch = false;
      }
    }

    if (categories) {
      let okCategory = false;

      const matchCategory = (cId) => {
        const category = allCategories.find((c) => c.id === cId);
        if (category && category.parent) {
          return categories.indexOf(category.parent) >= 0 || matchCategory(category.parent);
        }
        return false;
      };

      cash.categories.forEach((c) => {
        if (categories.indexOf(c) >= 0 || matchCategory(c)) {
          okCategory = true;
        }
      });

      if (!okCategory) isMatch = false;
    }

    if (costCenter) {
      let okCenter = false;
      costCenter.forEach((c) => {
        if (cash.costCenter.indexOf(c) >= 0) {
          okCenter = true;
        }
      });
      if (!okCenter) isMatch = false;
    }

    if (value.min && value.max) {
      if (cash.completed.on
        && (cash.completed.value < value.min || cash.completed.value > value.max)
      ) {
        isMatch = false;
      } else if (cash.value < value.min || cash.value > value.max) {
        isMatch = false;
      }
    }

    if (dueLate) {
      if (cash.completed.on) {
        isMatch = false;
      } else if (cash.dueAt > new Date() && !isSameDay(cash.dueAt, new Date())) {
        isMatch = false;
      }
    }

    return isMatch;
  });
}
