import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(),
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
}));
