import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

// Material UI components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import useEventController from 'store/event/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Product from 'store/supplier/product/controller';

// Icons, Commons and Styles
import { Suppliers, Financial, Partners } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import styles from './style';

// Components
import Budget from './components/budget';
import StepsChart from './components/charts/steps';
import CategoriesChart from './components/charts/categories';

function Procurement() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { eventId } = useParams();

  // Controllers
  const Event = useEventController(dispatch);

  const {
    current: event, suppliers, products, cashFlow,
  } = useSelector((state) => state.event);

  const { current: { permissions } } = useSelector((store) => store.user);

  const [isLoading, setisLoading] = useState(false);

  const permissionView = !permissions || !permissions.suppliers
    || permissions.suppliers.dashboard.view || permissions.suppliers.negotiations.view;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
      setisLoading(false);
    }
  }, [event, eventId, Event]);

  // Load data to use in this module
  useEffect(() => {
    if (event && event.id === eventId && !isLoading) {
      if (!suppliers) dispatch(Supplier.indexEvent(event.id));
      if (!products) dispatch(Product.index(event.id));
      if (!cashFlow) dispatch(CashFlow.index());
      setisLoading(true);
    }
  }, [event, eventId, suppliers, products, cashFlow, dispatch, isLoading]);

  const tabsToolbar = [
    { title: 'Dashboard', selected: true },
    { title: 'Items', path: `/manager/${eventId}/procurement/items` },
    { title: 'Negotiations', path: `/manager/${eventId}/procurement/negotiations` },
  ];

  return (
    <div className={classes.container}>
      <Toolbar
        avatar
        defaultIcons
        title="PROCUREMENT"
        position="fixed"
        sidebar="/manager/procurement"
        tabs={tabsToolbar}
      />

      <Grid container justify="center" className={classes.row} style={{ paddingTop: 122 }}>
        <Budget />
      </Grid>

      {suppliers && suppliers.length > 0 ? (
        <Grid container spacing={3} justify="center" className={classes.row}>
          <StepsChart />
          <CategoriesChart />
        </Grid>
      ) : (
        <Grid container spacing={1} direction="column" justify="center" alignItems="center" className={classes.row}>
          <Grid item>
            <Typography className={classes.noData}>
              START ITEMIZING WHAT YOU NEED TO HIRE FOR YOUR EVENT
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={() => history.push(`/manager/${eventId}/procurement/items`)}>
              SHOW ITEMS
            </Button>
          </Grid>
        </Grid>
      )}

      <Onboarding id="manager" title="Follow the management of your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="manager"
          frameborder="0"
          src="https://www.youtube.com/embed/x17RaEoauh0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      <Tools />
    </div>
  );
}

export default Procurement;
