export default {
  '04UMaUGTDjswDxi1qZKs': {
    name: 'Banner de entrada',
    responsible: '',
    category: 'oEqSm0BXSDerrblqn4Cy',
    createdAt: new Date('2020-02-13 18:57:18'),
    updatedAt: new Date('2020-02-13 18:57:18'),
    valueForecast: 100,
  },
  AG3EHs1wCaiuwRDX8zFu: {
    updatedAt: new Date('2020-02-13 18:57:05'),
    valueForecast: 400,
    name: 'Facebook Sponsorship',
    responsible: '',
    category: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:57:05'),
  },
  AMzRgPlC6aVhuNpZBEgA: {
    name: 'Chairs',
    responsible: '',
    category: 'GA0yHOTPV7LJ6pCWxWdn',
    createdAt: new Date('2020-02-13 18:56:07'),
    updatedAt: new Date('2020-02-13 18:56:07'),
    valueForecast: 1000,
  },
  F5M1dPH9XNQXsRDVbwx4: {
    updatedAt: new Date('2020-02-13 18:58:20'),
    valueForecast: 150,
    name: 'Firing e-mails',
    responsible: '',
    category: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:58:20'),
  },
  GYmbV0KTtykhorYkazBU: {
    updatedAt: new Date('2020-02-13 18:55:44'),
    valueForecast: 500,
    name: 'Chopp',
    responsible: '',
    category: 'ZsJUAz4SEFGFdDEC0jHw',
    createdAt: new Date('2020-02-13 18:55:44'),
  },
  Gpbv73tg39cBDR00A4Ez: {
    updatedAt: new Date('2020-02-13 18:56:33'),
    valueForecast: 1500,
    name: 'Tents',
    responsible: '',
    category: 'GA0yHOTPV7LJ6pCWxWdn',
    createdAt: new Date('2020-02-13 18:56:33'),
  },
  Lhk1SbAWr3oAfM4B6gWv: {
    responsible: '',
    category: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:56:58'),
    updatedAt: new Date('2020-02-13 18:56:58'),
    valueForecast: 1000,
    name: 'Instagram Sponsorship',
  },
  SFBCtKpH59sHP3uPApIN: {
    createdAt: new Date('2020-02-13 18:56:23'),
    updatedAt: new Date('2020-02-13 18:56:23'),
    valueForecast: 800,
    name: 'Stage structure',
    responsible: '',
    category: 'GA0yHOTPV7LJ6pCWxWdn',
  },
  TlqJB3agtetrFQ9YeSbZ: {
    name: 'Management platform',
    responsible: '',
    category: 'gi1CSApS3dGxsUVqVYbx',
    createdAt: new Date('2020-02-13 18:58:04'),
    updatedAt: new Date('2020-02-13 18:58:04'),
    valueForecast: 5000,
  },
  VYrHao2FgQnyZEPnKXsj: {
    name: 'Outdoor',
    responsible: '',
    category: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:56:51'),
    updatedAt: new Date('2020-02-13 18:56:51'),
    valueForecast: 4000,
  },
  WKzj3BtCzmGSkfBZjoQY: {
    responsible: '',
    category: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:58:34'),
    updatedAt: new Date('2020-02-13 18:58:34'),
    valueForecast: 1000,
    name: 'Event website',
  },
  qi7hn9UvwEIYAev9DDpG: {
    updatedAt: new Date('2020-02-13 18:57:26'),
    valueForecast: 80,
    name: 'Content Banner',
    responsible: '',
    category: 'oEqSm0BXSDerrblqn4Cy',
    createdAt: new Date('2020-02-13 18:57:26'),
  },
  wi3pZQEJWnzedcOxm7oP: {
    responsible: '',
    category: 'GA0yHOTPV7LJ6pCWxWdn',
    createdAt: new Date('2020-02-13 18:55:55'),
    updatedAt: new Date('2020-02-13 18:55:55'),
    valueForecast: 500,
    name: 'Stage',
  },
  xn0Mw5lKy8POfcowoAkt: {
    responsible: '',
    category: 'ZsJUAz4SEFGFdDEC0jHw',
    createdAt: new Date('2020-02-13 18:55:32'),
    updatedAt: new Date('2020-02-13 18:55:32'),
    valueForecast: 1500,
    name: 'Coffe break',
  },
  zTyyT6YS7uw0KmXlBkVO: {
    responsible: '',
    category: 'oEqSm0BXSDerrblqn4Cy',
    createdAt: new Date('2020-02-13 18:57:43'),
    updatedAt: new Date('2020-02-13 18:57:43'),
    valueForecast: 200,
    name: 'Nameplates',
  },
};
