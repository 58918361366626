import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import User from 'store/user/controller';
import Label from 'store/productivity/label/controller';
import Step from 'store/productivity/step/controller';
import useCategoryController from 'store/category/controller';
import { Actions } from './reducer';


const Company = {
  show: (companyId) => (dispatch) => {
    firestore.collection('companies').doc(companyId)
      .onSnapshot((company) => {
        dispatch(Actions.loadCompany({ id: company.id, ...company.data() }));
      });
  },

  store: (data) => (dispatch) => {
    // const { current: user } = getState().user;

    firestore.collection('companies').add(data)
      .then((company) => {
        dispatch(User.update({ company: company.id }));

        const Category = useCategoryController(dispatch, { company });

        const labels = [
          { color: '#40bf67', name: 'Tickets' },
          { color: '#d0d279', name: 'Content' },
          { color: '#DCAC33', name: 'Speeches' },
          { color: '#8540bf', name: 'Partners' },
          { color: '#4093bf', name: 'Hiring' },
          { color: '#E92DE9', name: 'Marketing' },
        ];

        const categories = [
          { color: '#40bf67', name: 'Tickets', type: 'in', default: true },
          { color: '#8540bf', name: 'Sponsors', type: 'in', default: true },
          { id: 'suppliers', color: '#4093bf', name: 'Suppliers', type: 'out', default: true },
          { color: '#d0d279', name: 'People', type: 'out', default: true },
        ];

        const steps = [
          { type: 'backlog', name: 'To do', default: true, order: 0 },
          { type: 'working', name: 'Working', default: true, order: 1 },
          { type: 'done', name: 'Done', default: true, order: 2 },
        ];

        setTimeout(() => {
          labels.forEach((l) => dispatch(Label.store(l)));
          steps.forEach((s) => dispatch(Step.store(s)));
          categories.forEach((c) => Category.store(c));
        }, [2000]);


        // if (user.mailerLite) {
        //   const mailerSubscribed = functions.httpsCallable('mailer-subscriberUpdate');
        //   mailerSubscribed({ subId: user.mailerLite, data: { fields: { company: data.name } } });
        // }
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (data) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).set({
      ...data, updatedAt: new Date(Date.now()),
    }, { merge: true })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Company;
