import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Timestamp } from 'services/firebase';
import { format, addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Commons and Styles
import { numberToReal } from 'common/utils/numbers';
import PaymentInstallmentsDefault from './default';
import PaymentInstallmentsCustom from './custom';
import PaymentInstallmentsView from './view';
import { DateField } from './utils';
import styles from '../style';

function PaymentInstallments({
  edit, subTotal, payment, status, onChange,
}) {
  const classes = styles();

  const [type, setType] = useState('default');

  useEffect(() => {
    if (!edit && payment.type !== 'cash' && payment.installments[0].due) {
      let isSame = true;

      const { value, due } = payment.installments[0];
      const day = due.toDate().getDate();

      payment.installments.forEach((i) => {
        const currentDay = i.due.toDate().getDate();

        if (i.value !== value) isSame = false;
        if (currentDay !== day) isSame = false;
      });

      if (!isSame || status > 1) setType('custom');
      else setType('default');
    }
  }, [payment, edit, status]);

  const handleType = (value) => {
    let { installments } = payment;

    if (value === 'default') {
      installments = installments.map((i) => ({ ...i, value: subTotal / installments.length }));
    }

    onChange({ ...payment, installments });
    setType(value);
  };

  const handleTimes = (e) => {
    const value = parseInt(e.target.value, 10);

    const payedInstallments = payment.installments.filter(
      (i) => i.cashFlow && (i.cashFlow.completed.on || !!i.cashFlow.completed.status),
    );

    if (!value || value < 2 || value < payedInstallments.length + 1 || value > 72) return false;

    let { installments } = payment;
    const len = installments.length;

    const payedTotal = payedInstallments.reduce((c, i) => c + i.value, 0);

    const remaining = subTotal - payedTotal;

    if (value < len) {
      installments.splice(value, len - value);

      installments = installments.map((i) => {
        const v = i.cashFlow && (i.cashFlow.completed.on || !!i.cashFlow.completed.status)
          ? i.value
          : remaining / (value - payedInstallments.length);
        return { ...i, value: v };
      });
    } else {
      let { due } = installments[len - 1];

      installments = installments.map((i) => {
        const v = i.cashFlow && (i.cashFlow.completed.on || !!i.cashFlow.completed.status)
          ? i.value
          : remaining / (value - payedInstallments.length);
        return { ...i, value: v };
      });

      for (let i = installments.length; i < value; i += 1) {
        due = due && Timestamp.fromDate(addMonths(due.toDate(), 1));
        installments.push({ value: remaining / (value - payedInstallments.length), due });
      }
    }

    onChange({ ...payment, installments });
    return true;
  };

  if (payment.type && payment.type !== 'cash' && (status < 3 || edit)) {
    return (
      <>
        {edit && status < 3 && (
          <Grid item xs={12} sm={7}>
            <RadioGroup
              row
              aria-label="installments"
              value={type}
              onChange={(e) => handleType(e.target.value)}
            >
              <FormControlLabel
                value="default"
                control={<Radio color="primary" />}
                label="Fixed"
                style={{ marginRight: 32 }}
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="Variable"
              />
            </RadioGroup>
          </Grid>
        )}

        <Grid item xs={12} sm={edit ? 5 : 2}>
          {edit ? (
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              label="Times"
              value={payment.installments.length}
              onChange={handleTimes}
              onClick={(e) => e.target.select()}
              InputProps={{
                inputProps: { className: classes.field, min: 1, max: 72 },
              }}
            />
          ) : (
            type === 'default' && (
              <>
                <Typography variant="overline" className={classes.infoTitle}>
                  Vezes
                </Typography>
                <Typography className={classes.infoText}>{payment.installments.length}</Typography>
              </>
            )
          )}
        </Grid>

        {type === 'default' && (
          <PaymentInstallmentsDefault
            edit={edit}
            subTotal={subTotal}
            payment={payment}
            onChange={onChange}
          />
        )}
        {type === 'custom' && (
          <PaymentInstallmentsCustom
            edit={edit}
            subTotal={subTotal}
            payment={payment}
            onChange={onChange}
          />
        )}
      </>
    );
  }

  const { value, due } = payment.installments[0];

  if (status < 3 || edit) {
    return (
      <Grid container item alignItems="center" spacing={1}>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="center"
          className={classes.installmentLabel}
        >
          <Typography variant="overline" className={classes.infoTitle}>
            Value
          </Typography>
          {!edit && (
            <Typography variant="overline" className={classes.infoTitle}>
              Due date
            </Typography>
          )}
        </Grid>

        <Grid item xs={edit ? 12 : 6} sm={6}>
          <Typography className={classes.infoText}>{numberToReal(value)}</Typography>
        </Grid>

        <Grid container item xs={edit ? 12 : 6} sm={6} justify="flex-end">
          {edit ? (
            <DateField
              value={due && due.toDate()}
              onChange={(date) => {
                const { installments } = payment;
                installments[0].due = date ? Timestamp.fromDate(date) : null;
                onChange({ ...payment, installments });
              }}
            />
          ) : (
            <Typography className={classes.infoText}>
              {due && format(due.toDate(), 'dd MMM yyyy', { locale: ptBR })}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  return <PaymentInstallmentsView installments={payment.installments} status={status} />;
}

PaymentInstallments.propTypes = {
  edit: PropTypes.bool.isRequired,
  subTotal: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    discount: PropTypes.object,
    exchange: PropTypes.object,
    installments: PropTypes.array,
    method: PropTypes.string,
    type: PropTypes.string,
  }),
  status: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

PaymentInstallments.defaultProps = {
  payment: null,
};

export default PaymentInstallments;
