import {
  TypesSuppliers, ActionsSuppliers, INITIAL_STATE_SUPPLIERS, reducerSuppliers,
} from './modules/supplier';
import {
  TypesFinancial, ActionsFinancial, INITIAL_STATE_FINANCIAL, reducerFinancial,
} from './modules/financial';
import {
  TypesProductivity, ActionsProductivity, INITIAL_STATE_PRODUCTIVITY, reducerProductivity,
} from './modules/productivity';

/**
 * Types
 */
const Types = {
  LOADING: '@event/LOADING',
  LOAD_EVENT: '@event/LOAD_EVENT',
  LOAD_SHORTCUTS: '@event/LOAD_SHORTCUTS',
  LOAD_SCENARIES: '@event/LOAD_SCENARIES',
  CLEAR: '@event/CLEAR',
  ...TypesSuppliers,
  ...TypesFinancial,
  ...TypesProductivity,
};

/**
 * Actions
 */
export const Actions = {
  loading: (loading) => ({ type: Types.LOADING, payload: { loading } }),
  loadEvent: (current) => ({ type: Types.LOAD_EVENT, payload: { current } }),
  loadShortcuts: (shortcuts) => ({ type: Types.LOAD_SHORTCUTS, payload: { shortcuts } }),
  loadScenaries: (scenaries) => ({ type: Types.LOAD_SCENARIES, payload: { scenaries } }),
  clear: () => ({ type: Types.CLEAR }),
  ...ActionsSuppliers,
  ...ActionsFinancial,
  ...ActionsProductivity,
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  loading: false,
  current: null,
  shortcuts: null,
  scenaries: null,
  ...INITIAL_STATE_SUPPLIERS,
  ...INITIAL_STATE_FINANCIAL,
  ...INITIAL_STATE_PRODUCTIVITY,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING: {
      const { loading } = action.payload;
      return { ...state, loading };
    }
    case Types.LOAD_EVENT: {
      const { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_SHORTCUTS: {
      const { shortcuts } = action.payload;
      return { ...state, shortcuts };
    }
    case Types.LOAD_SCENARIES: {
      const { scenaries } = action.payload;
      return { ...state, scenaries };
    }
    case Types.CLEAR: {
      return INITIAL_STATE;
    }
    // Supplier module
    case Types.LOAD_SUPPLIERS:
    case Types.LOAD_PRODUCTS: {
      return reducerSuppliers(state, action);
    }
    // Financial module
    case Types.LOAD_CASH_FLOW: {
      return reducerFinancial(state, action);
    }
    // Productivity module
    case Types.LOAD_CHECKLIST: {
      return reducerProductivity(state, action);
    }
    default: {
      return state;
    }
  }
};
