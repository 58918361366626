import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isSameDay, differenceInCalendarDays, format } from 'date-fns';

// Material UI Components
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Notification from 'store/notification/controller';

// Icons and Styles
import { ArrowLeft, X } from 'react-feather';
import { Financial, Suppliers, Productivity } from 'assets/custom-icons/modules';
import Loading from 'common/loading';
import styles from './style';

function Notify({ open, onClose }) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { notifys } = useSelector((store) => store.user);

  useEffect(() => {
    if (!notifys) dispatch(Notification.index());
  }, [notifys, dispatch]);

  const close = () => {
    const unreads = notifys.filter((n) => !n.readAt);
    unreads.forEach((n) => {
      dispatch(Notification.readed(n.id));
    });
    onClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container justify="space-between" alignItems="center" className={classes.toolbar}>
          <IconButton color="primary" onClick={close}>
            {mobile ? <ArrowLeft /> : <X />}
          </IconButton>
          <Typography className={classes.title}>NOTIFICATIONS</Typography>
          <div style={{ width: 48 }} />
        </Grid>
        <Grid container className={classes.content}>
          <List style={{ width: '100%' }}>
            {notifys ? notifys.map((not) => {
              const icons = {
                suppliers: Suppliers,
                financial: Financial,
                productivity: Productivity,
              };

              const Icon = icons[not.type];

              let date = '';
              const now = new Date();
              const createdAt = not.createdAt.toDate();

              if (isSameDay(now, createdAt)) {
                date = format(createdAt, "'Today at 'HH:mm");
              } else {
                const diffDays = differenceInCalendarDays(now, createdAt);
                if (diffDays === 1) date = format(createdAt, "'Yesterday at 'HH:mm");
                else date = format(createdAt, "dd/MM/yyyy' at 'HH:mm");
              }

              return [
                <Divider component="li" key={`divider-${not.id}`} />,
                <ListItem
                  button
                  key={not.id}
                  style={{ padding: '8px 24px' }}
                  className={!not.readAt ? classes.unread : ''}
                  onClick={() => {
                    close();
                    history.push(not.link);
                  }}
                >
                  <ListItemAvatar>
                    <Icon className={classes.icon} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={not.description}
                    secondary={date}
                    secondaryTypographyProps={{ style: { fontSize: 12 } }}
                  />
                </ListItem>,
              ];
            }) : (
              <Loading ready={!!notifys} content={400} />
            )}
            {notifys && <Divider component="li" />}
            {notifys && notifys.length === 0 && (
              <Typography style={{ color: '#777', fontSize: 18, padding: 24 }}>
                No new notifications, all right here!
              </Typography>
            )}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}

Notify.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notify;
