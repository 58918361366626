import React from 'react';

// Material Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { lighten, withStyles } from '@material-ui/core/styles';

// Icons, Commons and Styles
import { Plus, Edit2 } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import styles from './style';

function CompletedTasks() {
  const classes = styles();

  const quotas = [
    {
      id: 'quota1',
      name: 'Diamond Package',
      color: '#49E5CE',
      value: 50000,
      limit: 5,
      sales: 2,
    },
    {
      id: 'quota2',
      name: 'Gold Package',
      color: '#E0D425',
      value: 25000,
      limit: 7,
      sales: 1,
    },
    {
      id: 'quota3',
      name: 'Silver Package',
      color: '#D2D2D2',
      value: 10000,
      limit: 10,
      sales: 8,
    },
  ];

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between" alignItems="center" style={{ padding: '0 32px' }}>
        <Typography className={classes.title}>Sponsorship Packages</Typography>
        <Button
          color="secondary"
          variant="outlined"
          // onClick={(e) => handleEdit(e, 'contact')}
          onFocus={(e) => e.stopPropagation()}
          className={classes.editButton}
        >
          <Plus size={16} />
          <p>Add</p>
        </Button>
      </Grid>
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>PACKAGE NAME</TableCell>
              <TableCell className={classes.tableHeader} align="center">VALUE</TableCell>
              <TableCell className={classes.tableHeader}>SALES</TableCell>
              <TableCell className={classes.tableHeader} style={{ width: 40 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {quotas.map((quota) => {
              const Progress = withStyles({
                colorPrimary: {
                  backgroundColor: lighten(quota.color, 0.6),
                },
                barColorPrimary: {
                  backgroundColor: quota.color,
                },
              })(LinearProgress);

              return (
                <TableRow key={quota.id}>
                  <TableCell className={classes.tableCell} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.color} style={{ background: quota.color }} />
                    {quota.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {numberToReal(quota.value)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip title={`${quota.sales} of ${quota.limit} sold packages (${
                      Math.round((quota.sales / quota.limit) * 100)
                    }%)`}
                    >
                      <Progress
                        variant="determinate"
                        value={(quota.sales / quota.limit) * 100}
                        classes={{ root: classes.progress, bar: classes.progressBar }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IconButton color="primary">
                      <Edit2 size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

export default CompletedTasks;
