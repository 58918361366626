import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

// Commons, Components and Styles
import Tooltip from 'common/tooltip';
import { quotas } from '../../fake-data';
import MoreInfo from './more-infos';
import styles from './style';

function CardSupplier({ contact, showCategories, setShowCategories }) {
  const classes = styles();
  const theme = useTheme();

  const { users } = useSelector((state) => state.company);

  const [stickers, setStickers] = useState([]);

  useEffect(() => {
    const newStickers = [];
    contact.quotas.forEach((qId) => {
      const quota = quotas.find((q) => q.id === qId);
      if (quota) {
        const index = newStickers.indexOf(quota);
        if (index < 0) newStickers.push(quota);
      }
    });
    setStickers(newStickers);
  }, [contact.quotas]);

  const responsible = users.find((u) => u.id === contact.responsible);

  return (
    <Card className={classes.root} style={contact.interrupted ? { opacity: 0.7 } : {}}>
      <CardContent style={{ padding: 0 }}>
        <Grid container style={{ position: 'relative', padding: '16px 16px 0' }}>
          <Grid container alignItems="center" style={{ paddingRight: 40 }}>
            {stickers.map((quota) => (
              <Tooltip title={!showCategories && quota.name} key={quota.id}>
                <Grid
                  className={classes.categoryColor}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCategories(!showCategories);
                  }}
                  style={{
                    color: theme.palette.getContrastText(quota.color),
                    backgroundColor: quota.color,
                    maxWidth: showCategories ? 300 : 32,
                    width: showCategories ? 'fit-content' : 32,
                    maxHeight: showCategories ? 32 : 8,
                    height: showCategories ? 'fit-content' : 8,
                    padding: showCategories ? '4px 8px' : 0,
                  }}
                >
                  {showCategories && quota.name}
                </Grid>
              </Tooltip>
            ))}
          </Grid>
          <Grid
            item
            style={{
              position: 'absolute', top: 16, right: 16, zIndex: 1,
            }}
          >
            {!contact.interrupted && responsible && (
              <Tooltip title={responsible.name}>
                <Avatar
                  alt={responsible.name}
                  src={responsible.avatar}
                  className={classes.avatar}
                >
                  {responsible.initials}
                </Avatar>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <CardActionArea className={classes.actionArea}>
          <Typography variant="h6" className={classes.supplierName}>
            {contact.name}
          </Typography>

          {contact.interrupted ? (
            <MoreInfo.Interrupted />
          ) : (
            <>
              {contact.status === 1 && <MoreInfo.Negotiation contact={contact} />}
              {contact.status === 2 && <MoreInfo.Register contact={contact} />}
              {contact.status === 3 && <MoreInfo.Closed payment={contact.payment} />}
              {contact.status === 4 && <MoreInfo.Payed contact={contact} />}
            </>
          )}
        </CardActionArea>
      </CardContent>
    </Card>
  );
}

CardSupplier.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    budget: PropTypes.object,
    payment: PropTypes.object,
    quotas: PropTypes.arrayOf(PropTypes.string),
    responsible: PropTypes.string,
    status: PropTypes.number,
    interrupted: PropTypes.bool,
  }).isRequired,
  showCategories: PropTypes.bool.isRequired,
  setShowCategories: PropTypes.func.isRequired,
};

export default CardSupplier;
