import { makeStyles, darken, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: 700,
    margin: `0 auto`,
    flex: 1,
    overflow: 'overlay',
  },
  step: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.primary.main,
    lineHeight: 1,
  },
  description: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.common.grey,
    lineHeight: 1.3,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(1.5),
    padding: `20px 16px 16px`,
    borderRadius: 4,
    background: lighten(theme.palette.secondary.main, 0.9),
    '& > p': {
      position: 'absolute',
      top: -12,
      background: theme.palette.primary.main,
      color: 'white',
      padding:'4px 16px',
      fontSize: 10,
      textTransform: 'uppercase',
      borderRadius: 30,
      fontWeight: 600,
    },
  },
  add: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: 8,
    marginRight: 8,
  },
  title: {
    fontSize: 18,
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  label: {
    fontSize: 12,
    color: theme.palette.common.black,
    marginBottom: 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  persona: {
    '&:before': {
      content: 'none',
    },
  },
  btnRed: {
    color: theme.palette.error.main,
    opacity: 0.6,
    cursor: 'pointer',
    margin: '0 16px',
    '&:hover': {
      opacity: 1,
    },
  },
  btnSave: {
    background: lighten(theme.palette.tertiary.main, 0.2),
    color: 'white',
    '&:hover': {
      background: darken(theme.palette.tertiary.main, 0.1),
    },
  },
  btnController: {
    borderRadius: 50,
    marginTop: theme.spacing(2),
    padding: theme.spacing(),
    zIndex: 100,
    minWidth: 0,
    transition: 'all 0.2s ease-out',
    '& p': {
      margin: '0 8px',
    },
  },
}));
