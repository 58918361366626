function FormConfig() {
  return [
    {
      name: 'streaming',
      type: 'text',
      placeholder: 'Zoom, Google Meet, Whereby, etc.',
    },
    {
      name: 'type',
      type: 'text',
      placeholder: 'Ex: Webinar, Seminar, Open discussion, Meetup, etc.',
    },
    {
      name: 'subscribers',
      type: 'select',
      options: [
        { label: 'up to 100 people', value: '<100' },
        { label: 'up to 250 people', value: '<250' },
        { label: 'up to 1000 people', value: '<1000' },
        { label: 'more than 1000 people', value: '>1000' },
      ],
      multiline: true,
    },
  ];
}

export default FormConfig;
