import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    overflow: 'visible',
  },
  title: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  input: {
    padding: theme.spacing(0.5),
  },
  button: {
    margin: 20,
  },
  btnRemove: {
    color: theme.palette.error.main,
    padding: theme.spacing(0.5),
  },
  actions: {
    justifyContent: 'center',
  },
}));
