import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 32px)',
    padding: `${theme.spacing()}px 0 0`,
    transition: 'height 0.3s ease-out',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  title: {
    padding: `0 ${theme.spacing()}px 0 ${theme.spacing(1.5)}px`,
    marginBottom: theme.spacing(),
    '& > p': {
      color: theme.palette.primary.main,
      fontSize: 18,
      fontWeight: 600,
    },
  },
  list: {
    width: '100%',
    height: 'calc(100% - 63px)',
    overflow: 'overlay',
    padding: `0 ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  circularBar: {
    background: lighten(theme.palette.secondary.main, 0.8),
  },
  checked: {
    color: theme.palette.common.grey,
    textDecoration: 'line-through',
  },
  noData: {
    fontSize: 18,
    color: theme.palette.common.grey,
    textAlign: 'center',
  },
  btnX: {
    position: 'absolute',
    right: 12,
    background: 'white',
    ':hover': {
      color: theme.palette.error.main,
    },
  },
}));
