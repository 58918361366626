import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  steps: {
    width: 'calc(100% - 130px)',
    position: 'fixed',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    boxShadow: '0px 12px 12px #FFFFFF',
    marginTop: 85,
    paddingBottom: 20,
    zIndex: 1000,
    '@media (max-width:1100px)': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  step: {
    position: 'relative',
    width: 'fit-content',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: theme.spacing(),
    cursor: 'pointer',
    height: 84,
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 'calc(50% + 24px)',
      right: 'calc(-50% - 94px)',
      height: 8,
      background: lighten(theme.palette.secondary.main, 0.6),
      borderRadius: 2,
      border: '2px solid white',
      bottom: -1,
    },
    '&:last-child:before': {
      background: 'white',
      right: '0',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: 12,
      height: 12,
      borderRadius: 6,
      bottom: -3,
      left: 'calc(50% + 24px)',
      transform: 'translateX(-50%)',
      background: lighten(theme.palette.secondary.main, 0.6),
    },
  },
  stepDisabled: {
    color: '#a7a7a7',
    cursor: 'inherit',
  },
  stepCompleted: {
    '&:after': {
      border: `4px solid ${theme.palette.secondary.main}`,
      borderRadius: 10,
      bottom: -5,
      width: 16,
      height: 16,
    },
  },
  stepPast: {
    '&:after': {
      border: `4px solid ${theme.palette.secondary.main}`,
      borderRadius: 10,
      bottom: -5,
      width: 16,
      height: 16,
    },
    '&:before': {
      background: theme.palette.secondary.main,
      height: 6,
      bottom: 0,
      border: 'none',
    },
  },
  divider: {
    width: 'fit-content',
    fontSize: 32,
    lineHeight: 1,
    marginTop: -6,
    color: theme.palette.secondary.main,
    padding: `0 ${theme.spacing()}px`
  },
  content: {
    position: 'relative',
    paddingTop: 184,
    height: '100vh',
    '& .sc-cSHVUG.sc-Rmtcm.hwrDPC': {
      display: 'none',
    },
    '& .evvxJK': {
      right: 'inherit',
      left: 30,
    },
  },
  map: {
    position: 'fixed',
    bottom: 20,
    right: 90,  
    zIndex: 100,
    background: 'white',
    color: theme.palette.primary.main,
    padding: '0 4px 0 16px',
    '&:hover': {
      background: 'white',
    },
  },
  chatFab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
}));
