import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import User from 'store/user/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Trash2, ChevronDown, Edit2 } from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import styles from './style';

function TeamMobile() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { current: user } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.company);

  const [expanded, setExpanded] = useState(false);
  const [userDeleting, setUserDeleting] = useState(false);

  const permissionEdit = !user.permissions || !user.permissions.team || user.permissions.team.edit;

  const expandPanel = (panel, exp) => {
    setExpanded(exp ? panel : false);
  };

  const deleteUser = () => {
    dispatch(Log.store(`Deleted the collaborator ${userDeleting.name}`));
    dispatch(User.destroy(userDeleting.id));
    setUserDeleting(false);
  };

  return (
    <>
      {userDeleting && (
        <ConfirmDialog
          title={`Do you really want to remove ${userDeleting.name} from the team?`}
          onConfirm={deleteUser}
          onClose={() => setUserDeleting(false)}
        />
      )}
      <Card className={classes.card}>
        <CardContent style={{ padding: 0 }}>
          {users
            .filter((u) => u.id !== user.id && !u.deletedAt)
            .map((u) => (
              <ExpansionPanel
                className={classes.expasionPanel}
                expanded={expanded === u.id}
                key={u.id}
                onChange={(e, exp) => expandPanel(u.id, exp)}
              >
                <ExpansionPanelSummary expandIcon={<ChevronDown />}>
                  <Grid container alignItems="center">
                    <Avatar alt={u.name} src={u.avatar} className={classes.avatar}>
                      {u.initials}
                    </Avatar>
                    <Typography className={classes.nameUser}>{u.name}</Typography>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid container direction="column">
                      <Typography variant="overline" className={classes.title}>
                        E-mail
                      </Typography>
                      <Typography variant="body1" className={classes.text}>
                        {u.email}
                      </Typography>
                    </Grid>
                    {permissionEdit && !u.admin && (
                      <>
                        <Grid item>
                          <IconButton color="primary" onClick={() => history.push(`/team/${u.id}`)}>
                            <Edit2 size={20} />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton color="primary" onClick={() => setUserDeleting(u)}>
                            <Trash2 size={20} />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          {users.filter((u) => u.id !== user.id && !u.deletedAt).length === 0 && (
            <Grid container>
              <Typography className={classes.noData}>
                INVITE YOUR TEAM
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default TeamMobile;
