import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Commons and Styles
import { Check, X } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import ConfirmDialog from 'common/confirm-dialog';
import Tooltip from 'common/tooltip';
import styles from './style';

function PaymentView({ installments, status }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { eventId, supplierId } = useParams();
  const { suppliers } = useSelector((store) => store.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [openInstallment, setOpenInstallment] = useState(null);

  const permissionEdit = !permissions || !permissions.manager|| permissions.manager.procurement.edit;
  const permissionPay = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (installments[0].value > 0) {
      if (status === 3) {
        const allPayed = !installments.find(
          (i) => !i.cashFlow || !i.cashFlow.completed.on,
        );

        if (allPayed) dispatch(Supplier.update(supplierId, { status: 4 }));
      } else if (status === 4) {
        const noPayed = installments.find(
          (i) => !i.cashFlow || !i.cashFlow.completed.on,
        );

        if (noPayed) dispatch(Supplier.update(supplierId, { status: 3 }));
      }
    }
  }, [supplierId, installments, status, dispatch]);

  const confirmPayment = (key) => {
    const { cashFlow } = installments[key];

    dispatch(Log.store(`Confirmed payment of supplier ${suppliers.find((s) => s.id === supplierId).name}`));
    dispatch(CashFlow.update(cashFlow.id, {
      completed: { ...cashFlow.completed, status: null, on: true },
    }));
  };

  const openPayment = (key) => {
    const { cashFlow } = installments[key];

    dispatch(Log.store(`Reopened payment of supplier ${suppliers.find((s) => s.id === supplierId).name}`));
    dispatch(Supplier.update(supplierId, { status: 3 }));
    dispatch(CashFlow.update(cashFlow.id, {
      completed: { ...cashFlow.completed, on: false, status: null },
    }));
  };

  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell className={classes.cellHeader} />
            <TableCell align="center" className={classes.cellHeader}>Value</TableCell>
            <TableCell align="center" className={classes.cellHeader}>Due date</TableCell>
            <TableCell align="center" className={classes.cellHeader} style={{ width: 100 }}>
              Payment
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {installments.map((installment, k) => {
            const { value, due } = installment;
            const key = `installment-${k}`;
            const date = due && due.toDate();

            let stt = 'OPEN';
            if (installment.cashFlow && installment.cashFlow.completed) {
              if (installment.cashFlow.completed.on) stt = 'PAYED';
              else if (installment.cashFlow.completed.status) stt = 'WAITING';
            }

            const lastCell = {
              OPEN: (
                <Button
                  variant="outlined"
                  className={classes.payButton}
                  disabled={!permissionPay}
                  onClick={() => history.push(`/manager/${eventId}/procurement/negotiations/${supplierId}/pay/${installment.cashFlow.id}`)}
                >
                  Pay
                </Button>
              ),
              PAYED: (
                <Grid container wrap="nowrap" alignItems="center" justify="center">
                  <Typography className={classes.payedLabel}>PAGO</Typography>
                  {permissionPay && (
                    <Tooltip title="Reabrir parcela">
                      <Button
                        variant="outlined"
                        className={classes.buttonRed}
                        onClick={() => setOpenInstallment({ key: k })}
                      >
                        <X size={16} />
                      </Button>
                    </Tooltip>
                  )}
                </Grid>

              ),
              WAITING: (
                <Grid container wrap="nowrap" alignItems="center" justify="center">
                  <Typography
                    style={{
                      fontSize: 10, textAlign: 'center', lineHeight: 1.2, fontWeight: 600,
                    }}
                  >
                    Awaiting supplier confirmation
                  </Typography>
                  {permissionEdit && (
                    <>
                      <Tooltip title="Supplier confirmed">
                        <Button
                          variant="outlined"
                          className={classes.buttonGreen}
                          onClick={() => confirmPayment(k)}
                        >
                          <Check size={16} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Reopen parcel">
                        <Button
                          variant="outlined"
                          className={classes.buttonRed}
                          onClick={() => setOpenInstallment({ key: k })}
                        >
                          <X size={16} />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              ),
            };

            return (
              <TableRow
                key={key}
                className={`${classes.row} ${stt === 'PAYED' && classes.rowPayed}`}
              >
                <TableCell align="center" className={classes.cellBodyHighlight}>
                  {`${k + 1}°`}
                </TableCell>

                <TableCell align="center" className={classes.cellBody}>
                  <Typography>{numberToReal(value)}</Typography>
                </TableCell>

                <TableCell align="center" className={classes.cellBody}>
                  <Typography>{format(date, 'dd MMM yyyy', { locale: ptBR })}</Typography>
                </TableCell>

                <TableCell
                  align="center"
                  className={`${classes.cellBody} ${classes.cellWrap}`}
                  style={{ minWidth: stt === 'WAITING' ? 200 : 'auto' }}
                >
                  {lastCell[stt]}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!!openInstallment && (
        <ConfirmDialog
          title="Do you really want to reopen the parcel?"
          onConfirm={() => openPayment(openInstallment.key)}
          onClose={() => setOpenInstallment(null)}
        />
      )}
    </div>
  );
}

PaymentView.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.number.isRequired,
};

PaymentView.defaultProps = {
  installments: [],
};

export default PaymentView;
