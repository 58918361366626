import { makeStyles, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  divider: {
    margin: `0 0 ${theme.spacing()}px`,
    background: theme.palette.secondary.main,
  },
  dividerText: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  field: {
    padding: theme.spacing(),
  },
  fieldNumber: {
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  fieldDate: {
    '& > p': {
      display: 'none',
    },
  },
  discountSelect: {
    paddingRight: 24,
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
  button: {
    padding: theme.spacing(0.5),
    minWidth: 0,
  },
  infoTitle: {
    color: theme.palette.primary.main,
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  infoText: {
    color: theme.palette.common.grey,
    fontSize: 16,
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  noData: {
    color: theme.palette.common.grey,
    fontSize: 22,
    fontWeight: 300,
  },
  titleTotal: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    marginTop: theme.spacing(2),
  },
  total: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  titleTotalInstallments: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.5,
    marginTop: theme.spacing(2),
  },
  totalInstallments: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  colorErrorTotal: {
    color: theme.palette.error.main,
  },
  colorSuccessTotal: {
    color: theme.palette.success.main,
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  buttonEdit: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    minWidth: 0,
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  installmentLabel: {
    paddingBottom: '0 !important',
  },
  iconDate: {
    color: theme.palette.common.grey,
    padding: theme.spacing(0.5),
  },
  containerBtns: {
    position: 'fixed',
    bottom: 0,
    maxWidth: 600,
    background: 'white',
    padding: 16,
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
    },
  },
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    marginRight: 16,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
    },
  },
  buttonConfirm: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
    },
  },
}));
