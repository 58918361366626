import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import styles from './style';

function Subtask({ id, name, parent }) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams();

  const { labels } = useSelector((store) => store.company);
  const { checklist } = useSelector((store) => store.event);

  const [openMenu, setOpenMenu] = useState(null);

  const update = (pId) => {
    dispatch(Log.store(`Changed the link of task "${name}"`));
    dispatch(Task.update(id, { parent: pId }));
    setOpenMenu(null);
  };

  const baseUrl = (url) => {
    let base = `/planner/${eventId}/productivity`;
    if (history.location.pathname.includes('kanban')) base += '/kanban';
    return base + url;
  };

  return (
    <>
      <Grid container>
        <Typography className={classes.label}>Subtask</Typography>
        <Grid container alignItems="center">
          <Typography className={classes.parentName} onClick={(e) => setOpenMenu(e.currentTarget)}>
            {parent ? parent.label : 'Select an item from the event checklist'}
          </Typography>
        </Grid>
      </Grid>

      <Menu anchorEl={openMenu} open={!!openMenu} onClose={() => setOpenMenu(null)}>
        <MenuItem
          className={classes.add}
          onClick={() => history.push(baseUrl('/checklist'))}
        >
          Edit event checklist
        </MenuItem>
        {labels.map((l) => [
          (
            <ListSubheader
              key={l.id}
              className={classes.categoryLabel}
              style={{ color: l.color }}
            >
              {l.name}
            </ListSubheader>
          ),
          checklist.filter((c) => c.tag === l.id).map((c) => (
            <MenuItem key={c.id} onClick={() => update(c.id)}>{c.label}</MenuItem>
          )),
        ])}
      </Menu>
    </>
  );
}

Subtask.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parent: PropTypes.shape({ label: PropTypes.string }),
};

Subtask.defaultProps = {
  parent: null,
};

export default Subtask;
