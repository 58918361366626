import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import CostCenter from 'store/financial/cost-center/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import useEventController from 'store/event/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import styles from './style';

function CategoryForm() {
  const classes = styles();
  const dispatch = useDispatch();

  // Controller
  const Event = useEventController(dispatch);

  const history = useHistory();
  const { centerId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [initialState, setInitialState] = useState({ name: '', events: [], categories: [] });
  const [allEvents, setAllEvents] = useState(false);
  const [allCategories, setAllCategories] = useState(false);
  const [costCat, setCostCat] = useState([]);
  const [formSchema, setFormSchema] = useState([]);

  const {
    costCenter: allCostCenter, categories, cashFlow, events,
  } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (allCostCenter && centerId) {
      const center = allCostCenter.find((c) => c.id === centerId);

      if (!center) {
        history.goBack();
        return;
      }

      setEdit(true);
      setInitialState(center);
      setCostCat(center.categories);
    }
  }, [allCostCenter, centerId, history]);

  const getCategories = useCallback(() => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        if (costCat.indexOf(c.id) < 0) {
          getChildren(c, `${label} > ${c.name}`);
        }
      });
    };

    if (categories) {
      categories.filter((c) => !c.parent).forEach((category) => {
        cat.push(category);
        if (costCat.indexOf(category.id) < 0) {
          getChildren(category, category.name);
        }
      });
    }

    return cat;
  }, [categories, costCat]);

  useEffect(() => {
    setFormSchema([{
      name: 'code',
      type: 'text',
      label: 'Code',
    }, {
      name: 'name',
      type: 'text',
      label: 'Name',
      validation: { required: 'Define a name for the cost center' },
    }, {
      name: 'events',
      type: 'select-chip',
      label: (
        <>
          <Typography className={classes.title}>EVENTS</Typography>
          <Typography className={classes.description}>
            Select the events that will participate in this cost center
          </Typography>
        </>
      ),
      options: !allEvents ? [
        { value: 'all', label: 'All events', className: classes.allItem },
        ...events.map((e) => ({ value: e.id, label: e.name })),
      ] : [{ value: 'all', label: 'All events' }],
      onChange: (value, state) => {
        const isAll = !!value.find((v) => v === 'all');
        setAllEvents(isAll);
        if (isAll) _.set(state, 'events', ['all']);
        return state;
      },
    }, {
      name: 'categories',
      type: 'select-chip',
      label: (
        <>
          <Typography className={classes.title}>CATEGORIES</Typography>
          <Typography className={classes.description}>
          Select the categories that will participate in this cost center
          </Typography>
        </>
      ),
      options: !allCategories ? [
        { value: 'all', label: 'All categories', className: classes.allItem },
        ...getCategories().map((c) => ({
          value: c.id,
          chip: { label: c.name },
          label: (
            <Grid container>
              <div className={classes.categoryColor} style={{ background: c.color }} />
              <div className={classes.categoryLabel}>
                {c.parentLabel && <span>{c.parentLabel}</span>}
                <p>{c.name}</p>
              </div>
            </Grid>
          ),
        })),
      ] : [{ value: 'all', label: 'All categories' }],
      onChange: (value, state) => {
        setCostCat(value);
        const isAll = !!value.find((v) => v === 'all');
        setAllCategories(isAll);
        if (isAll) _.set(state, 'categories', ['all']);
        return state;
      },
    }]);
  }, [categories, costCat, allEvents, allCategories, classes, events, getCategories]);

  const submit = (data) => {
    if (edit) {
      // Add Cost Center to new cash flow
      cashFlow.forEach((c) => {
        const eventMatch = data.events.indexOf(c.event) >= 0 || data.events[0] === 'all';
        let categoryMatch = false;

        const isMatchCategory = (id) => {
          const category = categories.find((cat) => cat.id === id);
          if (category) {
            if (c.categories.indexOf(id) < 0) {
              let match = false;
              const children = categories.filter((cat) => cat.parent === id);
              children.forEach((cat) => {
                if (isMatchCategory(cat.id)) match = true;
              });
              return match;
            }
            return true;
          }
          return false;
        };

        data.categories.forEach((category) => {
          if (category === 'all' || isMatchCategory(category)) {
            categoryMatch = true;
          }
        });

        const centers = c.costCenter || [];

        if (eventMatch && categoryMatch) {
          if (centers.indexOf(centerId) < 0) {
            dispatch(CashFlow.update(c.id, { costCenter: [...centers, centerId] }));
          }
        } else if (centers.indexOf(centerId) >= 0) {
          const index = centers.indexOf(centerId);
          centers.splice(index, 1);
          dispatch(CashFlow.update(c.id, { costCenter: centers }));
        }
      });

      events.forEach((event) => {
        const centers = event.costCenter || [];

        if (centers.indexOf(centerId) < 0) {
          if (data.events.indexOf(event.id) >= 0 || data.events[0] === 'all') {
            centers.push(centerId);
            Event.update(event.id, { costCenter: centers });
          }
        } else if (data.events.indexOf(event.id) < 0 && data.events[0] !== 'all') {
          const index = centers.indexOf(centerId);
          centers.splice(index, 1);
          Event.update(event.id, { costCenter: centers });
        }
      });

      dispatch(Log.store(`Changed the cost center ${data.name}`));
      dispatch(CostCenter.update(centerId, data));
    } else {
      dispatch(Log.store(`Created the cost center ${data.name}`));
      dispatch(CostCenter.store(data));
    }
    history.goBack();
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={() => history.goBack()}
      aria-labelledby="form-product-category-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>{edit ? `Changing ${initialState.name}` : 'Create a new cost center'}</span>
          <IconButton onClick={() => history.goBack()} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {categories && cashFlow ? (
          <Form id="form-category" onSubmit={submit} schema={formSchema} initialState={initialState}>
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>{inputs.code}</Grid>
                <Grid item xs={12} sm={9}>{inputs.name}</Grid>
                <Grid item xs={12}>{inputs.events}</Grid>
                <Grid item xs={12}>{inputs.categories}</Grid>
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!categories} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-category"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategoryForm;
