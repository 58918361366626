// Main Components
import Scenaries from 'app/planner/financial';
import ScenariesForm from 'app/planner/financial/children/scenaries';

// Routes Components
import PrivateRoute from '../../components/PrivateRoute';


export default [
  {
    path: '/planner/:eventId/financial',
    component: Scenaries,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      {
        path: '/planner/:eventId/financial/scenaries/new',
        component: ScenariesForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/planner/:eventId/financial/scenaries/:scenaryId',
        component: ScenariesForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
];
