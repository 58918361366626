import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Material components
import Grid from '@material-ui/core/Grid';

// Commons, Assets and styles
import { BudgetForecast, BudgetUsed, BudgetRemaining } from 'assets/custom-icons';
import { numberToReal } from 'common/utils/numbers';
import Loading from 'common/loading';
import { IconButton } from '@material-ui/core';
import { Edit2 } from 'react-feather';
import DialogForecast from './forecast';
import styles from './style';

function getBudgetUsed(suppliers) {
  let usedValue = 0;

  suppliers.forEach((supplier) => {
    if (supplier.budget) {
      if (supplier.payment && supplier.payment.installments) {
        supplier.payment.installments.forEach((i) => {
          if (supplier.status >= 3) {
            usedValue += i.value;
          }
        });
      }
    }
  });

  return usedValue;
}

function Budget() {
  const classes = styles();

  const { current: event, suppliers, products } = useSelector((state) => state.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const isLoaded = !!suppliers && !!products;
  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  const [budgetForecast, setBudgetForecast] = useState(0);
  const [budgetUsed, setBudgetUsed] = useState(0);
  const [budgetRemaining, setBudgetRemaining] = useState(0);

  const [dialogForecast, setDialogForecast] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      let budgetForecastValue = products.reduce((c, p) => c + p.valueForecast, 0);

      if (event.settings && event.settings.suppliers && event.settings.suppliers.budget) {
        const { type, value } = event.settings.suppliers.budget;
        if (type === 'default') budgetForecastValue = value;
      }

      const budgetUsedValue = getBudgetUsed(suppliers);

      setBudgetForecast(budgetForecastValue);
      setBudgetUsed(budgetUsedValue);
      setBudgetRemaining(budgetForecastValue - budgetUsedValue);
    }
  }, [isLoaded, products, suppliers, event]);

  return (
    <>
      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <BudgetForecast className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <Grid container alignItems="flex-start" justify="space-between">
              <div className={classes.budgetLabel}>Budget estimate</div>
              {permissionEdit && (
                <IconButton
                  color="primary"
                  className={classes.editButton}
                  onClick={() => setDialogForecast(true)}
                >
                  <Edit2 size={16} />
                </IconButton>
              )}
            </Grid>
            {!isLoaded && <Loading ready={isLoaded} content={50} />}
            {isLoaded && <div className={classes.budgetValue}>{numberToReal(budgetForecast)}</div>}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <BudgetUsed className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <div className={classes.budgetLabel}>Allocated budget</div>
            {!isLoaded && <Loading ready={isLoaded} content={50} />}
            {isLoaded && (
              <div className={`${classes.budgetValue} ${classes.colorError}`}>
                {numberToReal(budgetUsed)}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.budget}>
        <Grid container>
          <Grid item className={classes.budgetIconContainer}>
            <BudgetRemaining className={classes.budgetIcon} />
          </Grid>
          <Grid item className={classes.budgetContent}>
            <div className={classes.budgetLabel}>Unallocated budget</div>
            {!isLoaded && <Loading ready={isLoaded} content={50} />}
            {isLoaded && (
              <div
                className={`${classes.budgetValue} ${
                  budgetRemaining >= 0 ? classes.colorSuccess : classes.colorError
                }`}
              >
                {numberToReal(budgetRemaining)}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      {dialogForecast && <DialogForecast onClose={() => setDialogForecast(false)} />}
    </>
  );
}

export default Budget;
