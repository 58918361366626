import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginRight: 8,
    flexDirection: 'row-reverse',
    cursor: 'pointer',
  },
  avatar: {
    marginRight: -8,
    background: theme.palette.primary.main,
  },
  avatarMenu: {
    width: 32,
    height: 32,
    marginRight: 8,
    fontSize: 16,
    background: theme.palette.primary.main,
  },
  check: {
    marginLeft: theme.spacing(1.5),
  },
  addButton: {
    padding: 7,
    marginBottom: 8,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.9),
    },
  },
}));
