import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { differenceInCalendarDays, addDays } from 'date-fns';
import { gantt } from 'dhtmlx-gantt';

// Material UI Components
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Controllers
import ChecklistController from 'store/productivity/checklist/controller';
import Task from 'store/productivity/task/controller';

// Icons, Commons, Components and Styles
import { Bookmark } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import Gantt from './chart';
import Checklist from './checklist';
import styles from './style';

function App() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();

  const { labels, tasks } = useSelector((store) => store.company);
  const { checklist } = useSelector((store) => store.event);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [data, setData] = useState({ data: [], links: [] });
  const [closedTasks, setClosedTasks] = useState([]);
  const [viewMode, setViewMode] = useState('Days');
  const [filter, setFilter] = useState('all');

  const [scrollPos, setScrollPos] = useState(0);

  const permissionEdit = !permissions || !permissions.planning || permissions.planning.edit;

  useEffect(() => {
    if (checklist && labels && tasks) {
      const newData = { data: [], links: [] };

      labels.filter((l) => filter !== 'all' ? l.id === filter : true).forEach((label) => {
        newData.data.push({
          id: label.id,
          text: label.name,
          color: label.color,
          type: gantt.config.types.project,
          open: !closedTasks.includes(label.id),
        });
      });

      checklist.filter((c) => filter !== 'all' ? c.tag === filter : true).forEach((item) => {
        let duration = 1;
        let startDate = new Date();

        if (item.endAt && item.startAt) {
          duration = differenceInCalendarDays(item.endAt.toDate(), item.startAt.toDate()) + 1;
        }

        if (item.startAt) {
          startDate = item.startAt.toDate();
        }

        const children = tasks.filter((t) => t.parent === item.id);

        if (children.length > 0) {
          newData.data.push({
            id: item.id,
            text: item.label,
            parent: item.tag,
            responsible: item.responsible,
            type: gantt.config.types.project,
            open: !closedTasks.includes(item.id),
          });
        } else {
          newData.data.push({
            id: item.id,
            text: item.label,
            start_date: startDate,
            duration,
            progress: 0,
            parent: item.tag,
            responsible: item.responsible,
          });
        }

        children.forEach((t) => {
          let durationTask = 1;
          let startDateTask = new Date();
  
          if (t.endAt && t.startAt) {
            durationTask = differenceInCalendarDays(t.endAt.toDate(), t.startAt.toDate()) + 1;
          }
  
          if (t.startAt) {
            startDateTask = t.startAt.toDate();
          }
          
          newData.data.push({
            id: t.id,
            text: t.name,
            start_date: startDateTask,
            duration: durationTask,
            progress: 0,
            parent: t.parent,
            responsibles: t.responsibles,
            isTask: true,
          });
        });
      });
      
      setData(newData);
    }
  }, [checklist, closedTasks, filter, labels, tasks]);

  const handleChecklist = (type, action, item, id) => {
    if (checklist) {
      if (type === 'task') {
        const startAt = new Date(item.start_date);
        const endAt = addDays(startAt, item.duration - 1);
        if (item.isTask) {
          const check = checklist.find((c) => c.id === item.parent);
          if (check) {
            const parents = tasks.filter((t) => t.parent === check.id && t.id !== item.id)
              .map((t) => ({ startAt: t.startAt.toDate(), endAt: t.endAt.toDate() }));
            parents.push({ startAt, endAt });
  
            const [first] = parents.sort((a, b) => a.startAt - b.startAt);
            const [last] = parents.sort((a, b) => b.endAt - a.endAt);

            dispatch(ChecklistController.update(item.parent, {
              startAt: first.startAt, endAt: last.endAt,
            }));
          }

          dispatch(Task.update(id, { startAt, endAt }));
        } else {
          dispatch(ChecklistController.update(id, { startAt, endAt }));
        }
      }
    }
  };

  const viewModes = [
    { id: 'Days', name: 'Days' },
    { id: 'Weeks', name: 'Weeks' },
    { id: 'Months', name: 'Months' },
  ];

  let matchTab = false;

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container className={classes.tabsContainer} wrap="nowrap">
        {permissionEdit && (
          <Tooltip title="Manage categories">
            <Fab
              color="primary"
              style={{ marginRight: 24, width: 48, height: 48 }}
              onClick={() => history.push(`/planner/${eventId}/action-plan/settings`)}
            >
              <Bookmark size={20} />
            </Fab>
          </Tooltip>
        )}

        <div className={classes.tabs}>
          <div className={classes.tabSelected} style={{ zIndex: 2 }}>
            <Typography className={classes.tabTitle}>EVENT ACTION PLAN</Typography>
          </div>
          <div
            className={classes.tab}
            style={filter !== 'all' && labels
              ? { zIndex: 1, background: labels.find((l) => l.id === filter).color }
              : { zIndex: 1 }}
          >
            <Select value={filter} onChange={(e) => setFilter(e.target.value)} className={classes.select}>
              <MenuItem value="all">GENERAL</MenuItem>
              {labels && labels.map((label) => (
                <MenuItem key={label.id} value={label.id}>{label.name}</MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={classes.tabs} style={{ right: 0, position: 'absolute' }}>
          <div
            className={`${classes.tab} ${classes.tabWHover}`}
            style={{
              zIndex: 0,
              padding: '12px 70px 12px 32px',
              marginRight: '-48px',
              marginLeft: 0,
              cursor: 'inherit',
            }}
          >
            <Typography className={classes.tabTitle}>View by</Typography>
          </div>
          {viewModes.map((tab, i) => {
            const index = matchTab ? viewModes.length - i : i;
            const match = viewMode === tab.id;
            if (match) matchTab = true;

            let padding = '12px 18px';
            if (!match) {
              if (!matchTab) padding = '12px 60px 12px 18px';
              else padding = '12px 18px 12px 60px';
            }

            return (
              <button
                key={tab.id}
                type="button"
                className={match ? classes.tabSelected : classes.tab}
                style={{
                  zIndex: match ? viewModes.length : index,
                  padding,
                  marginLeft: !match && (matchTab ? '-48px' : 0),
                  marginRight: !match && (!matchTab ? '-48px' : 0),
                }}
                onClick={!match ? () => setViewMode(tab.id) : null}
              >
                <Typography className={classes.tabTitle} style={{ lineHeight: 1 }}>
                  {tab.name}
                </Typography>
              </button>
            );
          })}
        </div>
      </Grid>

      <div className={classes.ganttContainer}>
        {checklist && labels && tasks ? (
          <Gantt
            tasks={data}
            viewMode={viewMode}
            onChange={handleChecklist}
            scrollPos={scrollPos}
            setScrollPos={setScrollPos}
          />
        ) : (
          <Loading content={200} ready={!!checklist && !!labels && !!tasks} />
        )}
      </div>
      <Checklist
        tasks={data}
        setClosedTasks={setClosedTasks}
        scrollPos={scrollPos}
        setScrollPos={setScrollPos}
      />
    </Grid>
  );
}

export default App;
