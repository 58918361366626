import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 'calc(100% - 130px)',
    height: '100%',
    marginLeft: 130,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      overflow: 'auto',
    },
  },
  message: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    background: fade('#fff', 0.9),
  },
  messageContent: {
    width: '100%',
    maxWidth: 600,
  },
  title: {
    fontSize: 40,
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: theme.spacing(),
    lineHeight: 1,
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.common.grey,
    margin: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  btnX: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
  },
  row: {
    width: '100%',
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    margin: 0,
  },
}));
