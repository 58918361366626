function FormConfig({ labels, labelId }) {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'Category name',
      validation: {
        required: 'Category name is required',
        unique: {
          msg: 'A category with that name already exists',
          params: {
            list: labels
              ? labels
                .filter((c) => c.id !== labelId)
                .map((c) => c.name)
              : [],
          },
        },
      },
    },
    {
      name: 'color',
      type: 'color-picker',
      validation: { required: 'Category color is required' },
    },
  ];
}

export default FormConfig;
