import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Event } from 'store/event/reducer';

const Checklist = {
  index: (eventId) => (dispatch) => {
    firestore.collection('events').doc(eventId).collection('checklist-items')
      .onSnapshot((ref) => {
        const checklist = ref.docs.map((a) => ({ id: a.id, ...a.data() }))
          .sort((a, b) => {
            if (a.startAt && b.startAt) return a.startAt.toDate() - b.startAt.toDate();
            if (!a.startAt) return new Date() - b.startAt.toDate();
            if (!b.startAt) return a.startAt.toDate() - new Date();
            return 1;
          });
        dispatch(Event.loadChecklist(checklist));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('checklist-items').add({
        ...data, updatedAt: now, createdAt: now,
      })
      .then(() => {
        Mixpanel.track('Create a checklist item');
        dispatch(Alert.show({ message: 'Item checklist created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('checklist-items').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a checklist item');
        dispatch(Alert.show({ message: 'Item checklist updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('checklist-items').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a checklist item');
        dispatch(Alert.show({ message: 'Item checklist successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Checklist;
