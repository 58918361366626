import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

// Icons and Styles
import { Send, Upload } from 'react-feather';
import Tooltip from 'common/tooltip';
import Document from './document';
import UploadButton from './button';
import styles from './style';

function AttachDialog({
  attachments, onChange, send, onClose, channel, chatUser,
}) {
  const classes = styles();

  const [sending, setSending] = useState(false);

  const removeAttach = (i) => {
    const newFiles = [...attachments];
    newFiles.splice(i, 1);
    onChange(newFiles);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.attachBack}
      onClick={onClose}
    >
      <Grid
        container
        direction="column"
        justify="center"
        wrap="nowrap"
        className={classes.attachDialog}
        onClick={(e) => e.stopPropagation()}
      >
        {attachments.map((attach, i) => (
          <Document
            name={attach.name}
            size={attach.size}
            onRemove={() => removeAttach(i)}
          />
        ))}

        <Grid container justify="space-between" alignItems="center">
          <Tooltip title="Add files">
            <IconButton color="secondary" disabled={sending}>
              <UploadButton
                className={classes.labelListUpload}
                channel={channel ? channel.id : chatUser}
                onChange={(files) => {
                  onChange(_.unionWith(attachments, files, _.isEqual));
                }}
                onStart={() => {}}
                onSuccess={() => {}}
              >
                <Upload size={18} />
              </UploadButton>
            </IconButton>
          </Tooltip>
          <Tooltip title="Submit">
            <IconButton
              color="primary"
              disabled={sending}
              onClick={() => {
                setSending(true);
                send();
              }}
            >
              <Send size={18} />
            </IconButton>
          </Tooltip>
        </Grid>
        {sending && <LinearProgress className={classes.progress} color="primary" />}
      </Grid>
    </Grid>
  );
}

AttachDialog.propTypes = {
  channel: PropTypes.string.isRequired,
  chatUser: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AttachDialog;
