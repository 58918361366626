import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';

import styles from './style';

export default function ({ initialForm, onlyType }) {
  const classes = styles();

  const { categories, costCenter } = useSelector((store) => store.company);

  const [dateMin, setDateMin] = useState(null);

  useEffect(() => {
    setDateMin(initialForm.date.min);
  }, [initialForm]);

  const getCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories.filter((c) => c.parent === category.id).forEach((c) => {
        cat.push({ ...c, parentLabel: label });
        getChildren(c, `${label} > ${c.name}`);
      });
    };

    if (categories) {
      categories.filter((c) => (onlyType ? c.type === onlyType && !c.parent : !c.parent))
        .forEach((category) => {
          let parentLabel = '';
          if (!onlyType) parentLabel = category.type === 'in' ? 'Revenue' : 'Expense';
          cat.push({ ...category, parentLabel });
          getChildren(category, category.name);
        });
    }

    return cat;
  };

  return [
    {
      name: 'date.min',
      type: 'datetime',
      keyboard: true,
      label: 'From',
      onChange: (value, state) => {
        setDateMin(value);
        return state;
      },
    },
    {
      name: 'date.max',
      type: 'datetime',
      keyboard: true,
      label: 'To',
      pickerProps: { minDate: dateMin },
      validation: dateMin && {
        minDate: {
          msg: 'The date must be greater than the start date',
          params: { min: 'date.min' },
        },
      },
    },
    {
      name: 'categories',
      type: 'select-chip',
      label: 'Categories',
      options: categories
        ? getCategories().map((c) => ({
          value: c.id,
          chip: { label: c.name },
          label: (
            <Grid container>
              <div className={classes.categoryColor} style={{ background: c.color }} />
              <div className={classes.categoryLabel}>
                {c.parentLabel && <span>{c.parentLabel}</span>}
                <p>{c.name}</p>
              </div>
            </Grid>
          ),
        }))
        : [],
    },
    {
      name: 'costCenter',
      type: 'select-chip',
      label: 'Cost Centers',
      options: costCenter
        ? costCenter.map((c) => ({ value: c.id, label: c.name }))
        : [],
    },
    {
      name: 'value.min',
      type: 'currency',
      label: 'From',
    },
    {
      name: 'value.max',
      type: 'currency',
      label: 'To',
      validation: {
        minValue: {
          msg: 'The value must be greater than the initial value',
          params: { min: 'value.min' },
        },
      },
    },
    {
      name: 'dueLate',
      type: 'checkbox',
      label: onlyType === 'in' ? 'LATE RECEIPT'
        : (onlyType === 'out' && 'LATE PAYMENT')
          || 'LATE PAYMENT OR RECEIPT',
    },
  ];
}
