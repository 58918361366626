import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// Icon and Styles
import {
  Paperclip, CheckCircle, Archive, Edit, Folder, Search,
} from 'react-feather';
import styles from './style';

export default function ({ repeat }) {
  const classes = styles();
  const { eventId } = useParams();

  const { steps, users } = useSelector((store) => store.company);

  const [startAt, setStartAt] = useState(new Date());
  const [repeatOn, setRepeatOn] = repeat;

  const priority = [
    { value: 5, label: 'Very high', color: '#DC1F26' },
    { value: 4, label: 'High', color: '#DC1F94' },
    { value: 3, label: 'Medium', color: '#941FDC' },
    { value: 2, label: 'Low', color: '#1F45DC' },
    { value: 1, label: 'Very low', color: '#1FB3DC' },
  ];

  const StepIcon = (type) => {
    const icons = {
      done: <CheckCircle size={18} style={{ color: '#777' }} />,
      archive: <Archive size={18} style={{ color: '#777' }} />,
      working: <Edit size={18} style={{ color: '#777' }} />,
      backlog: <Folder size={18} style={{ color: '#777' }} />,
      validate: <Search size={18} style={{ color: '#777' }} />,
    };

    return icons[type];
  };

  return [
    {
      name: 'name',
      type: 'text',
      label: 'Task name',
      validation: { required: 'Task name is required' },
    },
    {
      name: 'description',
      type: 'text',
      multiline: true,
      label: 'Description',
    },
    {
      name: 'startAt',
      type: 'datetime',
      keyboard: true,
      label: 'Start date',
      showTime: true,
      pickerProps: { disablePast: true },
      onChange: (value, state) => {
        setStartAt(value);
        return state;
      },
      validation: { required: 'Define task start date' },
    },
    {
      name: 'endAt',
      type: 'datetime',
      keyboard: true,
      label: 'Delivery date',
      showTime: true,
      pickerProps: { min: startAt },
      validation: { required: 'Define task delivery date' },
    },
    {
      name: 'duration',
      type: 'text-types',
      inputType: 'number',
      label: 'Duration',
      options: [
        { value: 'minutes', label: 'Minute(s)' },
        { value: 'hours', label: 'Hour(s)' },
        { value: 'days', label: 'Day(s)' },
      ],
    },
    {
      name: 'step',
      type: 'select',
      label: 'Step',
      options: steps ? steps.map((step) => ({
        value: step.id,
        label: (
          <Grid container justify="space-between" alignItems="center">
            <Typography>{step.name}</Typography>
            {StepIcon(step.type)}
          </Grid>
        ),
      })) : [],
      addOption: { label: 'Add step', url: `/planner/${eventId}/productivity/steps/new` },
      validation: { required: 'Define the step that the task belong' },
    },
    {
      name: 'priority',
      type: 'select',
      label: 'Priority',
      options: priority.map((p) => ({
        value: p.value,
        label: (
          <Grid container alignItems="center">
            <div className={classes.priority} style={{ background: p.color }}>
              {p.value}
            </div>
            {p.label}
          </Grid>
        ),
      })),
      validation: { required: 'Select task priority' },
    },
    {
      name: 'responsibles',
      type: 'select-chip',
      label: 'Responsibles',
      options: users ? users.filter((u) => !u.deletedAt).map((user) => ({
        value: user.id,
        chip: {
          label: user.name,
          avatar: <Avatar alt={user.name} src={user.avatar}>{user.initials}</Avatar>,
        },
        label: (
          <>
            <ListItemAvatar>
              <Avatar alt={user.name} src={user.avatar} className={classes.avatar}>
                {user.initials}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} />
          </>
        ),
      })) : [],
    },
    {
      name: 'attachments',
      type: 'upload',
      inputProps: {
        path: `/productivity-temp/${eventId}/tasks`,
        formats: 'document/*;image/*',
      },
      render: (documents, button) => (
        <>
          <Grid item container alignItems="center" justify="space-between">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Paperclip size={16} style={{ marginRight: 8, width: 24 }} />
              <Typography className={classes.title}>ATTACHMENTS</Typography>
            </div>
            {button}
          </Grid>

          <Grid container item xs={12}>{documents}</Grid>
        </>
      ),
    },
    {
      name: 'repeat.on',
      type: 'checkbox',
      label: <Typography className={classes.heading}>Repeat</Typography>,
      onChange: (value, state) => {
        setRepeatOn(value);
        return state;
      },
    },
    {
      name: 'repeat.type',
      type: 'select',
      label: 'Repeat',
      options: [
        { label: 'Dayly', value: 'dayly' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
      ],
      validation: repeatOn && { required: 'Select the repeat type' },
    },
    {
      name: 'repeat.times',
      type: 'text',
      inputType: 'number',
      label: 'Frequency',
      validation: {
        required: repeatOn,
        min: {
          msg: 'To repeat it is necessary to be at least once',
          params: { min: 1 },
        },
      },
    },
  ];
}
