import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: 200,
    padding: `${theme.spacing()}px 0`,
    marginTop: theme.spacing(-1),
    transition: 'height 0.3s ease-out',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(),
      position: 'relative',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
    padding: `0 ${theme.spacing(1.5)}px`,
    minHeight: 42,
  },
  list: {
    width: '100%',
    height: 'calc(100% - 70px)',
    overflow: 'overlay',
    padding: 0,
  },
  avatar: {
    background: theme.palette.primary.main,
  },
}));
