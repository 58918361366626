import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import { KeyboardDatePicker } from '@material-ui/pickers';

// Icons and styles
import { Calendar } from 'react-feather';
import styles from '../style';

// eslint-disable-next-line import/prefer-default-export
export function DateField({ value, onChange, disabled }) {
  const classes = styles();

  return (
    <KeyboardDatePicker
      autoOk
      clearable
      fullWidth
      disablePast
      ampm={false}
      inputVariant="outlined"
      format="dd/MM/yyyy"
      label="Due date"
      disabled={disabled}
      className={classes.fieldDate}
      KeyboardButtonProps={{ className: classes.iconDate }}
      keyboardIcon={<Calendar size={20} />}
      value={value}
      onChange={onChange}
      InputProps={{
        inputProps: { className: classes.field },
      }}
    />
  );
}

DateField.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DateField.defaultProps = {
  value: '',
  disabled: false,
};
