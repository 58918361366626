import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import Loading from 'common/loading';

// Redux Actions
import Auth from 'store/auth/controller';

function SignRoute({ component: Component, path, exact }) {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const { isAuth, isLoaded } = useSelector((state) => state.auth);

  useEffect(() => {
    async function checkAuth() {
      if (!isLoaded) await dispatch(Auth.check());
    }
    checkAuth();
  });

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirectUrl');

  return (
    <div>
      <Loading ready={isLoaded} />
      {isLoaded && (
        <Route
          path={path}
          exact={exact}
          render={() => (isAuth ? <Redirect to={redirectUrl || '/'} /> : <Component />)}
        />
      )}
    </div>
  );
}

SignRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

SignRoute.defaultProps = {
  exact: false,
};

export default SignRoute;
