import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addChip: {
    margin: theme.spacing(0.5),
    border: `1px dashed ${theme.palette.secondary.main}`,
    fontSize: 18,
    padding: theme.spacing(0.5),
    height: 'auto',
    borderRadius: 50,
  },
  scenaryChip: {
    fontSize: 18,
    padding: theme.spacing(0.5),
    height: 'auto',
    borderRadius: 50,
    '& .hiddenButton': {
      maxWidth: 0,
      marginLeft: 0,
      overflow: 'hidden',
      transition: 'all 0.2s ease-in',
    },
    '&:hover .hiddenButton': {
      maxWidth: 30,
      marginLeft: theme.spacing(0.75),
    },
  },
  btnChip: {
    opacity: 0.6,
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 1,
    },
  },
  selected: {
    width: 16,
    height: 16,
    background: theme.palette.secondary.main,
    borderRadius: 8,
    marginRight: theme.spacing(),
  },
  unselected: {
    width: 16,
    height: 16,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    marginRight: theme.spacing(),
  },
  unselectedChip: {
    background: `${lighten(theme.palette.primary.main, 0.5)} !important`,
    '&:hover': {
      background: `${lighten(theme.palette.primary.main, 0.4)} !important`,
    },
  },
  badge: {
    '& > span': {
      right: '50%',
      top: 2,
    },
  },
}));
