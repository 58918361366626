import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

// Icons, Commons and Styles
import { Plus, Edit2, Copy } from 'react-feather';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import styles from './style';

function Scenaries({ scenary, setScenary }) {
  const classes = styles();
  const history = useHistory();

  const { eventId } = useParams();

  const { scenaries } = useSelector((store) => store.event);

  return (
    <Grid container justify="space-between" wrap="nowrap" style={{ padding: '0 48px' }}>
      <Chip
        color="secondary"
        variant="outlined"
        className={classes.addChip}
        onClick={() => history.push(`/planner/${eventId}/financial/scenaries/new`)}
        label={(
          <Grid container alignItems="center" wrap="nowrap">
            <Plus size={16} style={{ marginRight: 8 }} />
            Create scenario
          </Grid>
        )}
      />
      <div style={{ width: 168 }} />
      <Grid container item justify="center" spacing={1} alignItems="center">
        {scenaries ? scenaries.map((s) => {
          const selected = scenary && scenary.id === s.id;

          return (
            <Chip
              color="primary"
              key={s.id}
              label={(
                <Grid container alignItems="center">
                  <div className={selected ? classes.selected : classes.unselected} />
                  {s.name}
                  <Edit2
                    size={16}
                    className={classes.btnChip}
                    style={{ marginLeft: 12 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/planner/${eventId}/financial/scenaries/${s.id}`);
                    }}
                  />
                  <Tooltip title="Duplicar cenário">
                    <div className="hiddenButton">
                      <Copy
                        size={16}
                        className={classes.btnChip}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/planner/${eventId}/financial/scenaries/new?duplicated=${s.id}`);
                        }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              )}
              style={{ margin: 8 }}
              className={`${classes.scenaryChip} ${!selected ? classes.unselectedChip : ''}`}
              onClick={() => setScenary(s)}
            />
          );
        }) : (
          <Loading ready={!!scenaries} content={60} />
        )}
      </Grid>
      <div style={{ width: 168 }} />
    </Grid>
  );
}

Scenaries.propTypes = {
  scenary: PropTypes.shape({ id: PropTypes.string }),
  setScenary: PropTypes.func.isRequired,
};

Scenaries.defaultProps = {
  scenary: { id: '' },
};

export default Scenaries;
