import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    margin: '0 auto',
    padding: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      paddingTop: 96,
    },
  },
  profile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  avatar: {
    width: 200,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    fontSize: 80,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 150,
      height: 150,
      marginRight: 0,
      fontSize: 60,
    },
  },
  avatarAction: {
    position: 'absolute',
    bottom: 0,
    right: 24,
    [theme.breakpoints.down('sm')]: {
      width: 48,
      height: 48,
      right: -8,
    },
  },
  labelListUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  labelFabUpload: {
    width: '100%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
  },
  infos: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  name: {
    fontWeight: 800,
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  office: {
    fontWeight: 300,
    color: theme.palette.common.grey,
    marginTop: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
      fontSize: 18,
    },
  },
  sensitive: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontSize: 14,
    lineHeight: 1.2,
    color: theme.palette.primary.main,
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  text: {
    fontSize: 24,
    wordBreak: 'break-word',
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  changePassword: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      justifyContent: 'center',
    },
  },
  btnPassword: {
    fontSize: 18,
    fontWeight: 600,
    padding: '8px 32px',
  },
  btnCancel: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.08),
    },
  },
  btnConfirm: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.08),
    },
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
}));
