import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fireauth, ERRORS } from 'services/firebase';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';
import User from 'store/user/controller';

// Icons, Commons and styles
import { Check, X } from 'react-feather';
import Form from 'common/form';
import styles from './style';

export function EditInfos({ onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: user } = useSelector((store) => store.user);

  const formSchema = [{
    name: 'name',
    type: 'text',
    inputProps: { style: { padding: 8, fontSize: phone ? 30 : '3.75rem', fontWeight: 'bold' } },
    validation: { required: true },
  }, {
    name: 'office',
    type: 'text',
    placeholder: 'Define your position in the team...',
    inputProps: {
      style: {
        padding: 8, fontSize: phone ? 18 : '2.125rem', fontWeight: 300,
      },
    },
  }];

  const submit = ({ name, office }) => {
    const names = name.split(' ');
    let initials = names[0].charAt(0).toUpperCase();

    if (names.length === 1) initials += names[0].charAt(1).toUpperCase();
    else initials += names.reverse()[0].charAt(0).toUpperCase();

    dispatch(User.update({ name, initials, office }));
    onClose();
  };

  return (
    <Form
      id="form-user-infos"
      onSubmit={submit}
      schema={formSchema}
      initialState={user}
    >
      {(inputs) => (
        <Grid container>
          <Grid container>{inputs.name}</Grid>
          <Grid container>{inputs.office}</Grid>
          <Grid item>
            <Button className={classes.btnCancel} onClick={onClose}>
              <X style={{ marginRight: 8 }} />
              Cancel
            </Button>
            <Button className={classes.btnConfirm} type="submit" form="form-user-infos">
              <Check style={{ marginRight: 8 }} />
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
}

export function EditEmail({ onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: user } = useSelector((store) => store.user);

  const [isLogged, setIsLogged] = useState(false);

  const login = ({ password }) => {
    fireauth.signInWithEmailAndPassword(user.email, password)
      .then(() => setIsLogged(true))
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  };

  const submit = ({ email }) => {
    dispatch(User.updateEmail(email));
    onClose();
  };

  return (
    <>
      <Form
        id="form-user-email"
        onSubmit={submit}
        schema={[{
          name: 'email',
          type: 'text',
          inputProps: { style: { padding: '8px 12px', fontSize: 24 } },
          validation: {
            required: true,
            email: true,
          },
        }]}
        initialState={user}
      >
        {(inputs) => (
          <>
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                E-mail
              </Typography>
              {inputs.email}
            </Grid>
            <Button className={classes.btnCancel} onClick={onClose}>
              <X style={{ marginRight: 8 }} />
              Cancelar
            </Button>
            <Button className={classes.btnConfirm} type="submit" form="form-user-email">
              <Check style={{ marginRight: 8 }} />
              Confirmar
            </Button>
          </>
        )}
      </Form>

      <Dialog
        open={!isLogged}
        fullWidth
        disableEscapeKeyDown
        fullScreen={fullScreen}
        onClose={onClose}
        classes={{ root: classes.dialog }}
        aria-labelledby="form-user-login-dialog"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
            <span>Enter your password to change your email</span>
            <IconButton onClick={onClose} aria-label="Sair">
              <X />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Form
            id="form-user-login"
            onSubmit={login}
            schema={[{
              name: 'password',
              type: 'text',
              inputType: 'password',
              label: 'Password',
            }]}
            initialState={user}
          >
            {(inputs) => inputs.password}
          </Form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            type="submit"
            form="form-user-login"
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            Edit e-mail
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function EditPassword({ onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formSchema = [{
    name: 'currentPassword',
    type: 'text',
    inputType: 'password',
    label: 'Current password',
    validation: { required: 'Type your password' },
  }, {
    name: 'newPassword',
    type: 'text',
    inputType: 'password',
    label: 'New password',
    validation: { required: 'Type your new password' },
  }];

  const submit = (data) => {
    dispatch(User.updatePassword(data));
    onClose();
  };

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby="form-user-password-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Change password</span>
          <IconButton onClick={onClose} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form
          id="form-user-password"
          onSubmit={submit}
          schema={formSchema}
          initialState={{ currentPassword: '', newPassword: '' }}
        >
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>{inputs.currentPassword}</Grid>
              <Grid item xs={12}>{inputs.newPassword}</Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-user-password"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Change password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

EditInfos.propTypes = propTypes;
EditEmail.propTypes = propTypes;
EditPassword.propTypes = propTypes;
