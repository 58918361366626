import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Channel from 'store/chat/channel/controller';
import Log from 'store/log/controller';

// Icons, Assets and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import styles from './style';

function FormExternalMember({ chat, open, onClose }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: company } = useSelector((store) => store.company);

  const [initialForm] = useState({ name: '', email: '' });

  const submit = (member) => {
    dispatch(Log.store(`Added the external member ${member.name} to the chat ${chat.name}`));
    dispatch(Channel.addExternalMember({ member, chat, companyId: company.id }));
    onClose();
  };

  const formSchema = [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      validation: { required: 'Enter the collaborator\'s name' },
    },
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      validation: {
        required: 'Enter the collaborator\'s email',
        email: 'Must be a valid email',
      },
    },
  ];

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="new-user-dialog"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <span>Add external member</span>
          <IconButton onClick={onClose} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Form id="new-extern-member" schema={formSchema} initialState={initialForm} onSubmit={submit}>
          {(inputs) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>{inputs.name}</Grid>
              <Grid item xs={12}>{inputs.email}</Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="new-extern-member"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormExternalMember;
