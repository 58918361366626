import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

import sendNotification from 'services/notification';

const Category = {
  index: (channel, limit = 20) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(channel)
      .collection('messages')
      .orderBy('sendAt', 'desc')
      .limit(limit)
      .onSnapshot((ref) => {
        const messages = ref.docs.map((m) => ({ id: m.id, ...m.data() }));
        dispatch(Company.loadMessages(channel, messages));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  unread: (channel) => (dispatch, getState) => {
    const { current: company } = getState().company;
    const { current: user } = getState().user;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(channel)
      .collection('messages')
      .where('unread', 'array-contains', user.id)
      .onSnapshot((ref) => {
        const unreads = ref.docs.length;
        dispatch(Company.loadUnreads(channel, unreads));
      }, (error) => {
        console.log(error);
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (channel, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;
    const { current: user } = getState().user;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(channel.id)
      .collection('messages')
      .add({
        sender: user.id, ...data, sendAt: now, updatedAt: now,
      })
      .then(() => {
        Mixpanel.track('Send a message');
        if (data.unread) {
          const [firstName] = user.name.split(' ');
          let { content } = data;
          if (content === '') content = 'Sent a file';

          const notify = {
            title: channel.group ? channel.group.name : user.name,
            description: `${channel.group ? `${firstName}: ` : ''}${content}`,
          };

          data.unread.forEach((u) => sendNotification(u, notify));
        }
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  readed: (channel, id, unread) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;
    const { current: user } = getState().user;

    const index = unread.indexOf(user.id);
    unread.splice(index, 1);

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(channel)
      .collection('messages')
      .doc(id)
      .set({ unread, updatedAt: now }, { merge: true })
      .then(() => Mixpanel.track('Read a message'))
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (channel, id) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('channels').doc(channel)
      .collection('messages')
      .doc(id)
      .set({ deletedAt: now }, { merge: true })
      .then(() => Mixpanel.track('Delete a message'))
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Category;
