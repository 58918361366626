import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router';

// Material components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Assets and Styles
import CreateAnimated from 'assets/animations/create.json';
import PlannerAnimated from 'assets/animations/planner.json';
import MakerAnimated from 'assets/animations/maker.json';
import styles from './style';

function EventSwitch() {
  const classes = styles();
  const history = useHistory();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      spacing={4}
      className={classes.container}
      onClick={history.goBack}
    >
      <Grid item sm={12} md={4} container>
        <Card
          onClick={(e) => {
            e.stopPropagation();
            history.push('/create');
          }}
          className={classes.card}
        >
          <CardContent>
            <Grid container>
              <Typography className={classes.title}>
                I want to turn my idea into an event
              </Typography>
              <Lottie
                isClickToPauseDisabled
                height={200}
                width="100%"
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: CreateAnimated,
                  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={12} md={4}>
        <Card
          onClick={(e) => {
            e.stopPropagation();
            history.push('/events/new?next=planner');
          }}
          className={classes.card}
        >
          <CardContent>
            <Grid container>
              <Typography className={classes.title}>
                I already have the scope and I'm ready to plan
              </Typography>
              <Lottie
                isClickToPauseDisabled
                height={200}
                width="100%"
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PlannerAnimated,
                  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={12} md={4}>
        <Card
          onClick={(e) => {
            e.stopPropagation();
            history.push('/events/new?next=manager');
          }}
          className={classes.card}
        >
          <CardContent>
            <Grid container>
              <Typography className={classes.title}>
              I already have planned and I'm ready to execute
              </Typography>
              <Lottie
                isClickToPauseDisabled
                height={200}
                width="100%"
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: MakerAnimated,
                  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EventSwitch;
