import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory, useParams, useRouteMatch, useLocation,
} from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';
import Contact from 'store/contact/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import styles from './style';

function SupplierForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { eventId, supplierId } = useParams();

  const search = new URLSearchParams(location.search);
  const urlProducts = search.get('products');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [initialForm, setInitialForm] = useState({
    name: '',
    contact: {
      name: '',
      email: '',
      phone: '',
      site: '',
      city: '',
      state: '',
    },
    products: urlProducts ? urlProducts.split(',') : [],
  });

  const { suppliers, products } = useSelector((state) => state.event);
  const { contacts } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (!contacts) dispatch(Contact.index());
  }, [contacts, dispatch]);

  useEffect(() => {
    if (supplierId && !!suppliers) {
      const supplier = suppliers.find((s) => s.id === supplierId);

      if (!supplier) {
        let url = `/manager/${eventId}/procurement`;
        if (match.url.indexOf('negotiations') >= 0) url += '/negotiations';
        history.replace(url);
        return;
      }

      setEdit(true);
      setInitialForm({
        name: supplier.name,
        existentContact: supplier.existentContact,
        contact: {
          name: supplier.contact.name || '',
          email: supplier.contact.email || '',
          phone: supplier.contact.phone || '',
          site: supplier.contact.site || '',
          city: supplier.contact.location.split(' - ')[0] || '',
          state: supplier.contact.location.split(' - ')[1] || '',
        },
        products: supplier.products,
      });
    }
  }, [suppliers, supplierId, history, eventId, match.url]);

  const submit = ({ contact: oldContact, ...data }) => {
    const { city, state, ...contact } = oldContact;
    let { name } = data;
    contact.location = city.nome ? `${city.nome} - ${state}` : '';
    let register = null;
    let payment = null;

    if (name.name) {
      register = { cnpj: name.id, answered: true, notes: '' };
      payment = { transference: name.transference };
      name = name.name;
    }

    if (!edit) {
      let responsible = null;
      let differentsResponsibles = false;

      data.products.forEach((productId) => {
        const product = products.find((p) => p.id === productId);
        if (product && product.responsible) {
          if (!responsible) {
            responsible = product.responsible;
          } else if (responsible !== product.responsible) {
            differentsResponsibles = true;
          }
        }
      });

      responsible = !differentsResponsibles ? responsible : null;

      dispatch(Log.store(`Created a supplier ${name}`));
      dispatch(Supplier.store({
        ...data, name, contact, responsible, register, payment,
      }));
    } else {
      dispatch(Log.store(`Changed a supplier ${name}`));
      dispatch(Supplier.update(supplierId, {
        ...data, name, contact, register, payment,
      }));
    }
    history.goBack();
  };

  const closeForm = () => {
    dispatch(FormSave.remove('form-supplier'));
    history.goBack();
  };

  const formSchema = FormConfig({ initialForm, edit });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={closeForm}
      classes={{ root: classes.dialog }}
      aria-labelledby="new-supplier-dialog"
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>
            {edit ? `Changing ${initialForm.name}` : 'Create a new supplier'}
          </span>
          <IconButton onClick={closeForm} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!!suppliers && !!products ? (
          <Form
            id="form-supplier"
            onSubmit={submit}
            schema={formSchema}
            initialState={initialForm}
          >
            {(inputs) => (
              <>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    {inputs.name}
                  </Grid>
                </Grid>

                {inputs.products}

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography variant="overline" className={classes.title}>
                      Contact
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {inputs.contact.name}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {inputs.contact.email}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {inputs.contact.phone}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {inputs.contact.site}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography variant="overline" className={classes.title}>
                      Notes
                    </Typography>
                    {inputs.notes}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12}>
                    <Typography variant="overline" className={classes.title}>
                      Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {inputs.contact.city}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {inputs.contact.state}
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        ) : (
          <Loading ready={!!suppliers && !!products} content={400} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          form="form-supplier"
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SupplierForm;
