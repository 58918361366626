import firebase, {
  fireauth, firestore, functions, ERRORS,
} from 'services/firebase';
import User from 'store/user/controller';
import Mixpanel from 'services/mixpanel';
import { Actions } from './reducer';
import { Actions as Company } from '../company/reducer';
import { Actions as UserActions } from '../user/reducer';

const Auth = {
  check: () => (dispatch) => {
    fireauth.onAuthStateChanged((auth) => {
      if (auth) dispatch(User.show(auth.uid));
      dispatch(Actions.load(auth));
    });
  },

  signIn: ({ email, password, remember }) => async (dispatch) => {
    const persistence = [
      firebase.auth.Auth.Persistence.SESSION,
      firebase.auth.Auth.Persistence.LOCAL,
    ];

    // '+remember': transform boolean in integer
    await fireauth.setPersistence(persistence[+remember]);

    fireauth.signInWithEmailAndPassword(email, password)
      .then(() => {
        Mixpanel.track('Login in platform');
      })
      .catch((error) => {
        dispatch(Actions.error(ERRORS[error.code]));
      });
  },

  signUp: ({
    name, email, password, agreedTerms,
  }) => async (dispatch) => {
    if (!agreedTerms) {
      dispatch(Actions.error('You must accept the Terms of Use and Privacy Policies'));
      return;
    }
    if (!name) {
      dispatch(Actions.error('Enter your full name'));
      return;
    }
    if (!password) {
      dispatch(Actions.error('Set your password'));
      return;
    }

    fireauth.createUserWithEmailAndPassword(email, password)
      .then(async (auth) => {
        const now = new Date();
        Mixpanel.track('Sign up in platform');

        const mailerSubscribed = functions.httpsCallable('mailer-subscribeInGroup');
        mailerSubscribed({ groupId: '100026328', user: { uid: auth.user.uid, name, email } });

        const names = name.toUpperCase().split(' ');
        const len = names.length;
        let initials = '';

        if (len > 1) {
          initials = names[0].charAt(0) + names[len - 1].charAt(0);
        } else {
          initials = names[0].charAt(0) + names[0].charAt(1);
        }

        firestore.collection('mail-send').add({ template: { name: 'new-user-en' }, to: email });

        firestore.collection('users').doc(auth.user.uid).set({
          name,
          email,
          initials,
          company: '',
          admin: true,
          createdAt: now,
          updatedAt: now,
          deletedAt: null,
          agreedTerms: true,
        });
      })
      .catch((error) => {
        dispatch(Actions.error(ERRORS[error.code]));
      });
  },

  signOut: () => (dispatch) => {
    dispatch(Company.clearCompany());
    dispatch(UserActions.clear());
    fireauth.signOut();
  },

  resetPassword: ({ email }) => async (dispatch) => {
    try {
      await fireauth.sendPasswordResetEmail(email);
      dispatch(Actions.resetPassword(true));
    } catch (error) {
      dispatch(Actions.resetPassword(ERRORS[error.code]));
    }
  },

  clearError: () => (dispatch) => {
    dispatch(Actions.error(null));
  },
};

export default Auth;
