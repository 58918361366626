import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonContent: {
    margin: `${theme.spacing()}px 0 0`,
    '& > button': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
  },
  errorColor: {
    color: `${theme.palette.error.main} !important`,
    borderColor: `${theme.palette.error.main} !important`,
    '&:hover': {
      backgroundColor: 'rgba(220, 31, 38, 0.08)',
    },
  },
}));
