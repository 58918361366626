import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Controller
import ChecklistController from 'store/productivity/checklist/controller';
import Task from 'store/productivity/task/controller';

// Styles
import {
  Edit2, MoreVertical, Plus, CornerDownRight, Trash2, MinusSquare, PlusSquare,
} from 'react-feather';
import ConfirmDialog from 'common/confirm-dialog';
import Tooltip from 'common/tooltip';
import styles from './style';

function Checklist({ tasks, setClosedTasks, scrollPos, setScrollPos }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId } = useParams();

  const { users } = useSelector((store) => store.company);

  const checkRef = useRef(null);

  const [taskDelete, setTaskDelete] = useState(null);
  const [menu, setMenu] = useState({ anchor: null });

  useEffect(() => {
    if (checkRef.current && scrollPos !== checkRef.current.scrollTop) {
      checkRef.current.scrollTo(0, scrollPos);
    }
  }, [scrollPos]);

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.addEventListener('scroll', () => {
        setScrollPos(checkRef.current.scrollTop);
      });
    }
  }, [setScrollPos]);

  const handleEdit = (task) => {
    let url = `/planner/${eventId}/action-plan`;
    if (task.parent) {
      if (task.isTask) url += `/tasks/${task.parent}/subtasks/${task.id}`;
      else url += `/tasks/${task.id}`;
    } else url += `/labels/${task.id}`;
    history.push(url);
    setMenu({ anchor: null });
  };

  const handleOpen = (task, i) => () => {
    setClosedTasks((closed) => {
      const newClosed = [...closed];
      if (task.open) newClosed.push(task.id);
      else {
        const index = newClosed.indexOf(task.id);
        newClosed.splice(index, 1);
      }
      return newClosed;
    });
  };

  const renderTask = (task, i) => {
    const user = users.find((u) => u.id === task.responsible);
    const responsibles = task.isTask && task.responsibles.map((r) => users.find((u) => u.id === r));
    let margin = user ? 16 : 0;
    if (responsibles) {
      const len = responsibles.length;
      if (len === 0) margin = 31;
      else if (len === 1) margin = 16;
      else margin = 1;
    }

    let avatars = [];

    if (user) {
      avatars = (
        <Tooltip title={user.name}>
          <Avatar src={user.avatar} alt={user.name} className={classes.avatar}>
            {user.initials}
          </Avatar>
        </Tooltip>
      );
    }

    if (responsibles) {
      if (responsibles.length < 3) {
        avatars = responsibles.map((u) => (
          <Tooltip key={u.id} title={u.name}>
            <Avatar src={u.avatar} alt={u.name} className={classes.avatar}>
              {u.initials}
            </Avatar>
          </Tooltip>
        ));
      } else {
        const [firstRes, ...restRes] = responsibles;
        avatars = (
          <>
            <Tooltip title={firstRes.name}>
              <Avatar src={firstRes.avatar} alt={firstRes.name} className={classes.avatar}>
                {firstRes.initials}
              </Avatar>
            </Tooltip>
            <Tooltip title={`${restRes.splice(0, -1).map((u) => u.name).join(', ')} e ${restRes.splice(-1).map((u) => u.name)}`}>
              <Avatar className={classes.avatar}>
                {`+${responsibles.length - 1}`}
              </Avatar>
            </Tooltip>
          </>
        )
      }
    }

    return (
      <React.Fragment key={task.id}>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="space-between"
          className={classes.checklistItems}
        >
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ overflow: 'hidden' }}
          >
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              style={{ width: 'fit-content', marginRight: margin }}
            >
              {avatars}
            </Grid>
            <Tooltip title={task.text.length > 39 ? task.text : ''}>
              <Typography
                className={classes.checklistItemName}
                style={task.color
                  ? { color: task.color, textTransform: 'uppercase', fontWeight: 600 }
                  : []}
              >
                {task.isTask && (
                  <CornerDownRight size={14} style={{ margin: '0 8px 0 12px' }} />
                )}
                {!task.isTask && task.open && tasks.data.find((t) => t.parent === task.id) && (
                  <MinusSquare size={14} className={classes.expandIcon} onClick={handleOpen(task, i)} />
                )}
                {!task.isTask && !task.open && tasks.data.find((t) => t.parent === task.id) && (
                  <PlusSquare size={14} className={classes.expandIcon} onClick={handleOpen(task, i)} />
                )}
                {!task.isTask && !tasks.data.find((t) => t.parent === task.id) && !task.color && (
                  <span style={{ width: 22, display: 'inline-block' }} />
                )}
                {task.text}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid container alignItems="center" wrap="nowrap" style={{ width: 'fit-content' }}>
            {task.color ? (
              <Tooltip title="Add checklist">
                <div>
                  <Plus
                    className={classes.iconButton}
                    size={16}
                    onClick={() => history.push(`/planner/${eventId}/action-plan/tasks/new?category=${task.id}`)}
                  />
                </div>
              </Tooltip>
            ) : (
              <>
                {!task.isTask && (
                  <Tooltip title="Add task">
                    <div>
                      <Plus
                        className={classes.iconButton}
                        size={16}
                        onClick={() => history.push(`/planner/${eventId}/action-plan/tasks/${task.id}/subtasks/new`)}
                      />
                    </div>
                  </Tooltip>
                )}

                {!task.isTask && tasks.data.find((t) => t.parent === task.id) ? (
                  <Tooltip title="Editar">
                    <div>
                      <Edit2
                        className={classes.iconButton}
                        size={16}
                        onClick={() => history.push(`/planner/${eventId}/action-plan/tasks/${task.id}`)}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <MoreVertical
                    className={classes.iconButton}
                    size={16}
                    onClick={(e) => setMenu({ anchor: e.currentTarget, task })}
                  />
                )}

                {menu.anchor && (
                  <Menu
                    open={!!menu.anchor}
                    anchorEl={menu.anchor}
                    onClose={() => setMenu({ anchor: null })}
                    PaperProps={{ style: { minWidth: 200, boxShadow: 'none', border: '1px solid #b1b1b1' } }}
                  >
                    <MenuItem onClick={() => handleEdit(menu.task)}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <Edit2 size={16} />
                      </ListItemIcon>
                      <ListItemText primary="Edit" />
                    </MenuItem>
                    {(menu.task.isTask || !tasks.data.find((t) => t.parent === menu.task.id)) && (
                      <MenuItem onClick={() => {
                        setTaskDelete(menu.task);
                        setMenu({ anchor: null });
                      }}>
                        <ListItemIcon style={{ minWidth: 32 }}>
                          <Trash2 size={16} />
                        </ListItemIcon>
                        <ListItemText primary="Remove" />
                      </MenuItem>
                    )}
                  </Menu>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {task.open && tasks.data.filter((t) => t.parent === task.id).map(renderTask)}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.checklist}>
      <div className={classes.checklistHeader} />
      <div className={classes.checklistTasks} ref={checkRef}>
        {tasks.data.filter((t) => t.color).map(renderTask)}
        <div style={{ height: document.getElementsByClassName('gantt_hor_scroll').length > 0 ? 18 : 2 }} />
      </div>

      {tasks.data.length === 0 && (
        <Typography className={classes.noData}>
          Start by adding tasks to your action plan
        </Typography>
      )}

      {taskDelete && (
        <ConfirmDialog
          title={`Do you really want to remove the task "${taskDelete.text}"?`}
          onConfirm={() => {
            if (taskDelete.isTask) dispatch(Task.destroy(taskDelete.id));
            else dispatch(ChecklistController.destroy(taskDelete.id));
          }}
          onClose={() => setTaskDelete(null)}
        />
      )}
    </div>
  );
}

Checklist.propTypes = {
  tasks: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
};

export default Checklist;
