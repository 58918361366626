import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Material UI Components
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';

// Controllers
import Channel from 'store/chat/channel/controller';
import Message from 'store/chat/message/controller';
import Notification from 'store/notification/controller';

// Icons and Styles
import { MessageCircle, Bell, X, HelpCircle } from 'react-feather';
import { Tools } from 'assets/custom-icons';
import Tooltip from 'common/tooltip';
import Chat from './chat';
import Notify from './notification';
// import Reports from './reports';
import HelpCenter from './help-center';
import styles from './style';

function ToolsDial() {
  const classes = styles();
  const dispatch = useDispatch();

  const { notifys } = useSelector((store) => store.user);
  const { channels } = useSelector((store) => store.company);

  const [openSpeed, setOpenSpeed] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  // const [openReports, setOpenReports] = useState(false);
  const [openHelpCenter, setOpenHelpCenter] = useState(false);

  useEffect(() => {
    if (!notifys) dispatch(Notification.index());
    if (!channels) dispatch(Channel.index());
    else {
      channels.forEach((channel) => {
        if (!channel.message) dispatch(Message.index(channel.id));
        if (!channel.unreads) dispatch(Message.unread(channel.id));
      });
    }
  }, [channels, notifys, dispatch]);

  let unreads = 0;
  if (channels && channels.find((c) => c.unreads)) {
    unreads = channels.reduce((a, b) => a + b.unreads, 0);
  }


  let notifyBadge = 0;
  if (notifys) notifyBadge = notifys.filter((n) => !n.readAt).length;

  return (
    <>      
      <Fab color="secondary" className={classes.mainFab} onClick={() => setOpenSpeed(!openSpeed)}>
        {openSpeed ? (
          <X />
        ) : (
          <Badge color="primary" badgeContent={unreads + notifyBadge} classes={{ badge: classes.badge }}>
            <Tools color="#fff" />
          </Badge>
        )}
      </Fab>
      
      <Tooltip title="Chat" placement="left">
        <Fab
          color="primary"
          className={classes.actionFab}
          onClick={() => {
            setOpenChat(true);
            setOpenSpeed(false);
          }}
          style={openSpeed ? {
            right: 74, bottom: 74, transform: 'scale(1)', opacity: 1,
          } : {}}
        >
          <Badge color="secondary" badgeContent={unreads} classes={{ badge: classes.badge }}>
            <MessageCircle />
          </Badge>
        </Fab>
      </Tooltip>

      <Tooltip title="Notifications" placement="top">
        <Fab
          color="primary"
          className={classes.actionFab}
          onClick={() => {
            setOpenNotify(true);
            setOpenSpeed(false);
          }}
          style={openSpeed ? {
            right: 16, bottom: 96, transform: 'scale(1)', opacity: 1,
          } : {}}
        >
          <Badge color="secondary" badgeContent={notifyBadge} classes={{ badge: classes.badge }}>
            <Bell />
          </Badge>
        </Fab>
      </Tooltip>

      {/* <Tooltip title="Relatórios" placement="left">
        <Fab
          color="primary"
          className={classes.actionFab}
          onClick={() => {
            setOpenReports(true);
            setOpenSpeed(false);
          }}
          style={openSpeed ? {
            right: 96, bottom: 16, transform: 'scale(1)', opacity: 1,
          } : {}}
          classes={{ disabled: classes.disabledFab }}
        >
          <FileText color="#fff" />
        </Fab>
      </Tooltip> */}

      <Tooltip title="Help Center" placement="left">
        <Fab
          id="help-center-button"
          color="primary"
          className={classes.actionFab}
          onClick={() => {
            setOpenHelpCenter(true);
            setOpenSpeed(false);
          }}
          style={openSpeed ? {
            right: 96, bottom: 16, transform: 'scale(1)', opacity: 1,
          } : {}}
          classes={{ disabled: classes.disabledFab }}
        >
          <HelpCircle color="#fff" />
        </Fab>
      </Tooltip>

      <Chat open={openChat} onClose={() => setOpenChat(false)} />
      <Notify open={openNotify} onClose={() => setOpenNotify(false)} />
      <HelpCenter open={openHelpCenter} onClose={() => setOpenHelpCenter(null)} />
      {/* <Reports open={openReports} onClose={() => setOpenReports(null)} /> */}
    </>
  );
}

export default ToolsDial;
