export const numberToReal = (n) => {
  const number = n.toFixed(2).split('.');
  number[0] = `$ ${number[0].split(/(?=(?:...)*$)/).join(' ')}`;
  return number.join(',');
};

export const currencyInput = (number) => {
  let numberFormated = (typeof number === 'number'
    ? number.toFixed(2).replace('.', ',') : number.replace(/\D/g, ''));

  if (!numberFormated) return '0,00';

  numberFormated = numberFormated.split(',').join('').split(' ').join('');
  numberFormated = `${parseInt(numberFormated, 10)}`;

  const len = numberFormated.length;
  let currency = [];

  if (len < 3) currency = `0,${(`00${numberFormated}`).slice(-2)}`;
  else {
    for (let i = len - 1; i >= 0; i -= 1) {
      const diff = len - i - 2;
      currency.unshift(numberFormated[i]);

      if (diff === 0) currency.unshift(',');
      else if (diff % 3 === 0) currency.unshift(' ');
    }
    currency = currency.join('');
  }

  return currency.trim();
};

export const revertCurrencyInput = (currency) => {
  const number = currencyInput(currency);
  return parseFloat(number.replace(',', '.').split(' ').join(''));
};
