import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    margin: 0,
    zIndex: 10000,
    top: 0,
    background: 'rgba(0,0,0,0.5)',
    overflow: 'overlay',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  card: {
    width: '100%',
    padding: theme.spacing(),
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[5],
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 600,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  }
}));
