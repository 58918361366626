import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Material UI Components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import {
  X, Plus, Trash2, ChevronDown, ChevronUp,
} from 'react-feather';
import styles from './style';

function Checklists({ value, onChange }) {
  const classes = styles();

  const [checkEdit, setCheckEdit] = useState(null);
  const [checkName, setCheckName] = useState('');
  const [checkExpanded, setCheckExpanded] = useState('');

  const handleItem = (check, id) => {
    const newChecklists = _.cloneDeep(value);
    return {
      add: () => {
        newChecklists[check].items.push({ label: '', checked: false });
        const index = newChecklists[check].items.length - 1;
        setCheckEdit(`c${check}i${index}`);
        setCheckName('');
        onChange(newChecklists);
      },
      edit: (e) => {
        e.stopPropagation();
        setCheckEdit(`c${check}i${id}`);
        setCheckName(newChecklists[check].items[id].label);
      },
      submit: (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (checkName !== '') {
          newChecklists[check].items[id].label = checkName;
          if (e.type === 'submit') {
            newChecklists[check].items.push({ label: '', checked: false });
            const index = newChecklists[check].items.length - 1;
            setCheckName('');
            setCheckEdit(`c${check}i${index}`);
          } else {
            setCheckEdit(null);
          }
          onChange(newChecklists);
          setCheckName('');
        } else if (newChecklists[check].items[id].label === '') {
          handleItem(check, id).remove();
          setCheckEdit(null);
        }
      },
      unsubmit: (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (newChecklists[check].items[id].label === '') {
          handleItem(check, id).remove();
          setCheckEdit(null);
        } else {
          setCheckEdit(null);
        }
      },
      check: (e) => {
        newChecklists[check].items[id].checked = e.target.checked;
        onChange(newChecklists);
      },
      prevOrder: () => {
        const [item] = newChecklists[check].items.splice(id, 1);
        newChecklists[check].items.splice(id - 1, 0, item);
        onChange(newChecklists);
      },
      nextOrder: () => {
        const [item] = newChecklists[check].items.splice(id, 1);
        newChecklists[check].items.splice(id + 1, 0, item);
        onChange(newChecklists);
      },
      remove: () => {
        newChecklists[check].items.splice(id, 1);
        onChange(newChecklists);
      },
    };
  };

  const handleCheck = (id) => {
    const newChecklists = _.cloneDeep(value);
    return {
      add: () => {
        newChecklists.push({ name: 'Checklist', items: [] });
        const index = newChecklists.length - 1;
        setCheckEdit(`checkname${index}`);
        setCheckName('Checklist');
        setCheckExpanded(index);
        onChange(newChecklists);
      },
      expand: () => {
        if (checkExpanded === id) setCheckExpanded('');
        else setCheckExpanded(id);
      },
      edit: (e) => {
        e.stopPropagation();
        setCheckEdit(`checkname${id}`);
        setCheckName(newChecklists[id].name);
        setCheckExpanded(id);
      },
      submit: (e) => {
        e.stopPropagation();
        e.preventDefault();
        newChecklists[id].name = checkName;
        setCheckExpanded(id);
        if (newChecklists[id].items.length === 0) {
          newChecklists[id].items.push({ label: '', checked: false });
          const index = newChecklists[id].items.length - 1;
          setCheckEdit(`c${id}i${index}`);
        } else {
          setCheckEdit(null);
        }
        setCheckName('');
        onChange(newChecklists);
      },
      delete: () => {
        newChecklists.splice(id, 1);
        onChange(newChecklists);
      },
    };
  };

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid container item justify="space-between" alignItems="center">
        <Typography variant="overline" className={classes.title}>
          Checklist
        </Typography>
        <IconButton color="primary" onClick={handleCheck().add}>
          <Plus />
        </IconButton>
      </Grid>
      <Grid container item>
        {value.map((check, i) => (
          <ExpansionPanel
            // eslint-disable-next-line react/no-array-index-key
            key={`checklist-${i}`}
            expanded={checkExpanded === i}
            onChange={handleCheck(i).expand}
            className={classes.expansion}
          >
            <ExpansionPanelSummary expandIcon={<ChevronDown size={18} />} style={{ padding: '0 16px' }}>
              {checkEdit === `checkname${i}` ? (
                <form style={{ width: '100%' }} onSubmit={handleCheck(i).submit}>
                  <TextField
                    autoFocus
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={checkName}
                    onChange={(e) => setCheckName(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={(e) => e.stopPropagation()}
                    onBlur={handleCheck(i).submit}
                    onKeyDown={(e) => (e.keyCode === 27 ? handleCheck(i).submit(e) : null)}
                  />
                </form>
              ) : (
                <Grid container item justify="space-between" alignItems="center">
                  <Typography onClick={handleCheck(i).edit}>
                    {check.name}
                  </Typography>
                  <IconButton color="primary" onClick={handleCheck(i).delete}>
                    <Trash2 size={16} />
                  </IconButton>
                </Grid>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: '0 24px' }}>
              <Grid container>
                {check.items.map((item, j) => (
                  <Grid container justify="space-between" wrap="nowrap" alignItems="center" key={item.label}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid
                        container
                        direction="column"
                        style={{ width: 'fit-content', marginRight: 16 }}
                      >
                        <Button
                          color="secondary"
                          className={classes.btnOrder}
                          onClick={handleItem(i, j).prevOrder}
                          disabled={j === 0}
                        >
                          <ChevronUp size={16} />
                        </Button>
                        <Button
                          color="secondary"
                          className={classes.btnOrder}
                          onClick={handleItem(i, j).nextOrder}
                          disabled={j === check.items.length - 1}
                        >
                          <ChevronDown size={16} />
                        </Button>
                      </Grid>
                      <Checkbox
                        edge="start"
                        checked={item.checked}
                        onChange={handleItem(i, j).check}
                        tabIndex={-1}
                        disableRipple
                      />
                      {checkEdit === `c${i}i${j}` ? (
                        <form style={{ width: '100%' }} onSubmit={handleItem(i, j).submit}>
                          <TextField
                            autoFocus
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={checkName}
                            onChange={(e) => setCheckName(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={(e) => e.stopPropagation()}
                            onBlur={handleItem(i, j).submit}
                            onKeyDown={(e) => (e.keyCode === 27
                              ? handleItem(i, j).unsubmit(e)
                              : null)}
                          />
                        </form>
                      ) : (
                        <Typography
                          className={item.checked ? classes.checkedItem : ''}
                          onClick={handleItem(i, j).edit}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.label}
                        </Typography>
                      )}
                    </Grid>
                    {checkEdit !== `c${i}i${j}` && (
                      <IconButton onClick={handleItem(i, j).remove}>
                        <X size={16} />
                      </IconButton>
                    )}
                  </Grid>
                ))}
              </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button fullWidth color="secondary" onClick={handleItem(i).add}>
                Add item
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}
      </Grid>
    </Grid>
  );
}

Checklists.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checklists;
