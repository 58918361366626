import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons and Styles
import { X } from 'react-feather';
import styles from './style';

function ParentComponent({
  parent, categoryId, type, onChange, supplierModule,
}) {
  const classes = styles();

  const [menu, setMenu] = useState(null);

  const { categories } = useSelector((state) => state.company);

  const ordernedCategories = () => {
    const cat = [];

    const getChildren = (category, label) => {
      categories
        .filter((c) => c.parent === category.id && c.id !== categoryId)
        .forEach((c) => {
          cat.push({ ...c, parentLabel: label });
          getChildren(c, `${label} > ${c.name}`);
        });
    };

    categories
      .filter((c) => c.type === type && c.id !== categoryId)
      .filter((c) => (supplierModule ? c.id === 'suppliers' : !c.parent))
      .forEach((category) => {
        cat.push(category);
        getChildren(category, category.name);
      });

    return cat;
  };

  if (categories) {
    return (
      <Grid container alignItems="center">
        <button
          type="button"
          className={classes.parentButton}
          onClick={(e) => setMenu(e.currentTarget)}
        >
          {parent ? `Sub-category of ${parent.name}` : 'Link to an existing category'}
        </button>

        {(!supplierModule && parent) && (
          <X size={16} onClick={() => onChange(null)} className={classes.removeButton} />
        )}

        <Menu
          anchorEl={menu}
          open={!!menu}
          PaperProps={{ className: classes.menu }}
          onClose={() => setMenu(null)}
        >
          {ordernedCategories().map((category) => (
            <MenuItem
              key={category.id}
              onClick={() => {
                onChange(category);
                setMenu(null);
              }}
            >
              <Grid container>
                <div className={classes.categoryColor} style={{ background: category.color }} />
                <div className={classes.categoryLabel}>
                  {category.parentLabel && <span>{category.parentLabel}</span>}
                  <p>{category.name}</p>
                </div>
              </Grid>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    );
  }

  return <></>;
}

ParentComponent.propTypes = {
  parent: PropTypes.shape({
    name: PropTypes.string,
  }),
  categoryId: PropTypes.string,
  type: PropTypes.string,
  supplierModule: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ParentComponent.defaultProps = {
  parent: null,
  type: null,
  categoryId: null,
  supplierModule: false,
};

export default ParentComponent;
