import React from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Styles
import styles from './style';

function Register({ supplier }) {
  const classes = styles();

  if (!supplier.register) {
    return (
      <Grid container alignItems="center" className={classes.moreContent}>
        <Typography variant="overline" className={classes.moreInfoTitle}>
        Request supplier registration
        </Typography>
      </Grid>
    );
  }

  if (!supplier.register.answered && supplier.register.sendAt && !supplier.register.chat) {
    return (
      <Grid container alignItems="center" className={classes.moreContent}>
        <Typography variant="overline" className={classes.moreInfoTitle}>
          Registration request sent 
          {` ${formatDistance(supplier.register.sendAt.toDate(), new Date(), { locale: ptBR })}`}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center" className={classes.moreContent}>
      <Typography variant="overline" className={classes.moreInfoTitle}>
        Evaluate supplier data
      </Typography>
    </Grid>
  );
}

Register.propTypes = {
  supplier: PropTypes.shape({
    register: PropTypes.object,
  }).isRequired,
};

export default Register;
