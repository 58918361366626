import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';

// Material Components
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Styles and Options
import Loading from 'common/loading';
import { Typography } from '@material-ui/core';
import { filter } from '../../utils';
import CustomDialog from './custom';
// import LineChart from './line-chart';
import styles from './style';
import { getOptions, generateData } from './utils';

function CashFlowChart() {
  const classes = styles();

  const location = useLocation();
  const { eventId } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { cashFlow, categories } = useSelector((store) => store.company);

  const [params, setParams] = useState(new URLSearchParams(location.search));
  const [periodFilter, setPeriodFilter] = useState(!!params.get('dateMin'));
  const [period, setPeriod] = useState(!periodFilter ? 'this month' : 'custom');
  const [customDate, setCustomDate] = useState({ start: null, end: null });
  const [openCustom, setOpenCustom] = useState(false);
  const [chartData, setChartData] = useState([[], [], [], [], []]);

  useEffect(() => {
    const newParams = new URLSearchParams(location.search);
    setParams(newParams);
    setPeriodFilter(!!newParams.get('dateMin'));
  }, [location.search]);

  useEffect(() => {
    if (cashFlow && categories) {
      let flow = cashFlow.filter((c) => c.event === eventId);
      if (location.search !== '') flow = filter(flow, location.search, categories);

      if (periodFilter) {
        const date = {
          start: params.get('dateMin') && new Date(parseInt(params.get('dateMin'), 10)),
          end: params.get('dateMax') && new Date(parseInt(params.get('dateMax'), 10)),
        };
        setChartData(generateData(flow, 'custom', date));
      } else {
        setChartData(generateData(flow, period, customDate));
      }
    }
  }, [cashFlow, categories, period, eventId, customDate, params, periodFilter, location.search]);

  useEffect(() => {
    if (period !== 'custom') setCustomDate({ start: null, end: null });
  }, [period]);

  const periods = [
    { id: 'all period', label: 'All period' },
    { id: 'last month', label: 'Last month' },
    { id: 'this month', label: 'This month' },
    { id: 'next month', label: 'Next month' },
    { id: 'custom', label: 'Custom' },
  ];

  return (
    <Grid container justify="center">
      {!cashFlow ? (
        <Loading ready={!!cashFlow} content={300} />
      ) : (
        <>
          {chartData[0].length > 0 && (
            <ReactEcharts
              option={getOptions(chartData, mobile)}
              className={classes.chart}
              notMerge
              lazyUpdate
              showLoading={false}
              opts={{ renderer: 'svg' }}
            />
          )}
          {/* {chartData[0].length > 0 && (
            <div style={{ width: '100%', height: 300 }}>
              <LineChart data={chartData} />
            </div>
          )} */}

          {chartData[0].length === 0 && (
            <Grid container justify="center" alignItems="center" style={{ width: '100%', height: 300 }}>
              <Typography variant="h4" className={classes.nodata}>
                No data to show on the chart.
              </Typography>
            </Grid>
          )}
        </>
      )}

      {openCustom && (
        <CustomDialog
          customDate={customDate}
          onSubmit={(dates) => {
            setCustomDate(dates);
            setPeriod('custom');
            setOpenCustom(false);
          }}
          onClose={() => setOpenCustom(false)}
        />
      )}

      {!params.get('dateMin') && (
        <Grid item className={classes.period}>
          {periods.map((p) => (
            <Chip
              key={p.id}
              color="secondary"
              label={p.label}
              onClick={p.id === 'custom' ? () => setOpenCustom(true) : () => setPeriod(p.id)}
              variant={period === p.id ? 'default' : 'outlined'}
              className={p.id === 'custom' ? classes.chipDashed : classes.chip}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default CashFlowChart;
