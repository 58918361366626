import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    width: '100%',
    margin: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing()}px 0`,
    },
  },
  date: {
    textAlign: 'center',
    marginRight: theme.spacing(),
    color: theme.palette.primary.main,
  },
  dateDay: {
    fontSize: 36,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  dateMonth: {
    fontSize: 10,
    fontWeight: 200,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  title: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    '& h5': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  locationIcon: {
    color: theme.palette.common.grey,
    marginRight: theme.spacing(),
    minWidth: 20,
  },
  location: {
    fontSize: 16,
    color: theme.palette.common.grey,
  },
  editIcon: {
    margin: theme.spacing(0.25),
  },
  trashIcon: {
    margin: theme.spacing(0.25),
    '&:hover': {
      color: theme.palette.error.main,
    }
  },
  btnRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
      borderColor: theme.palette.error.main,
    },
  },
}));
