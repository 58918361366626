import { makeStyles, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing()}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 16,
      justifyContent: 'center',
    },
  },
  iconContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    width: 80,
    minWidth: 80,
    height: 80,
    borderRadius: 40,
    '&:after': {
      content: '" "',
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: 6,
      width: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: '10px solid white',
    },
  },
  iconContainerROI: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    width: 95,
    minWidth: 95,
    height: 95,
    borderRadius: 50,
    '&:after': {
      content: '" "',
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: 6,
      width: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: '10px solid white',
    },
  },
  icon: {
    width: 40,
    height: 40,
    '& svg': {
      width: 40,
      height: 40,
    },
  },
  iconROI: {
    width: 48,
    height: 48,
    '& svg': {
      width: 48,
      height: 48,
    },
  },
  iconButtonContained: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    color: 'white',
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
  iconButtonOutlined: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(),
  },
  contentROI: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
  },
  label: {
    overflow: 'hidden',
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:after': {
      content: '" "',
      display: 'block',
      width: 60,
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.primary.main,
      margin: '4px 0 8px',
      [theme.breakpoints.down('xs')]: {
        width: 40,
        height: 2,
        borderRadius: 1,
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  value: {
    overflow: 'hidden',
    fontSize: 34,
    lineHeight: 1.2,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  precision: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginTop: 14,
    '& b': {
      marginLeft: theme.spacing(),
    },
  },
  labelROI: {
    overflow: 'hidden',
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  valueROI: {
    overflow: 'hidden',
    fontSize: 43,
    lineHeight: '48px',
    fontWeight: 'bold',
    textAlign: 'end',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 35,
    },
  },
  estimated: {
    width: '100%',
    textAlign: 'end',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content',
      marginLeft: 96,
      fontSize: 14,
    },
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
}));
