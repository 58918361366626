import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

// Firebase Config
import { configDev, configMaster } from './accounts-key';

if (process.env.NODE_ENV === 'development') {
  firebase.initializeApp(configDev);
} else {
  firebase.initializeApp(configMaster);
}

export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const fireauth = firebase.auth();

export const { Timestamp } = firebase.firestore;

export const ERRORS = {
  'auth/invalid-email': 'We need a valid email',
  'auth/user-not-found': 'We didn\'t find that user. Check with our support team.',
  'auth/wrong-password': 'This password does not match the one we have here',
  'auth/too-many-requests': 'Several unsuccessful attempts. Wait some minutes and try again.',
  'auth/email-already-in-use': 'There is already a user with this email on our platform.',
  'permission-denied': 'You are not allowed to access this data.',
  'already-exists': 'There is already a user with this email on our platform.',
};

export default firebase;
