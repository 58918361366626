import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useCategoryController from 'store/category/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import Form from 'common/form';
import Loading from 'common/loading';
import FormConfig from './form-config';
import Parent from './parent';
// import SubCategories from './subcategories';
import styles from './style';

function CategoryForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { eventId, categoryId } = useParams();
  const supplierModule = !!eventId;
  const params = new URLSearchParams(history.location.search);
  const parentId = params.get('parent');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [edit, setEdit] = useState(false);
  const [type, setType] = useState(supplierModule ? 'out' : params.get('type'));
  const [initialForm, setInitialForm] = useState({ name: '', type: type || '', color: '' });
  const [parent, setParent] = useState(
    supplierModule ? { id: 'suppliers', name: 'Suppliers' } : null,
  );
  // const [subCategories, setSubCategories] = useState([]);

  const { current: company, categories } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  // Controllers
  const Category = useCategoryController(dispatch, { company });

  const permissionProcurement = !permissions || !permissions.manager
    || permissions.manager.procurement.edit;
  const permissionFinancial = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const permissionEdit = permissionProcurement || permissionFinancial;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  useEffect(() => {
    if (categories && categoryId) {
      const category = categories.find((c) => c.id === categoryId);

      if (!category) {
        history.goBack();
        return;
      }

      setEdit(true);
      setInitialForm(category);
      setType(category.type);
      if (category.parent) {
        const parentData = categories.find((c) => c.id === category.parent);
        if (parentData) setParent(parentData);
      }
    }
  }, [categories, categoryId, history]);

  useEffect(() => {
    if (parentId && categories) {
      const parentData = categories.find((c) => c.id === parentId);
      if (parentData) {
        setParent(parentData);
        setType(parentData.type);
        setInitialForm((f) => ({ ...f, type: parentData.type }));
      }
    }
  }, [parentId, categories]);

  const insideSuppliers = () => {
    const isInside = (id) => {
      if (categories) {
        const category = categories.find((c) => c.id === id);
        if (category && category.parent) {
          if (category.parent === 'suppliers') return true;
          return isInside(category.parent);
        }
      }
      return false;
    };

    return isInside(categoryId);
  };

  const submit = (data) => {
    if (edit) {
      dispatch(Log.store(`Changed a category ${data.name}`));
      Category.update(categoryId, { ...data, parent: parent ? parent.id : null });
    } else {
      dispatch(Log.store(`Created a category ${data.name}`));
      Category.store({ ...data, parent: parent ? parent.id : null });
    }
    history.goBack();
  };

  const formSchema = FormConfig({
    edit,
    disabledType: edit || supplierModule || !!parentId,
    isDefault: initialForm.default,
    setType,
  });

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      fullScreen={fullScreen}
      onClose={() => history.goBack()}
      aria-labelledby="form-product-category-dialog"
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <div>
            <p>{edit ? `Changing ${initialForm.name}` : 'Create new category'}</p>
            <Parent
              type={type}
              parent={parent}
              categoryId={categoryId}
              onChange={setParent}
              supplierModule={supplierModule || insideSuppliers()}
            />
          </div>
          <IconButton onClick={() => history.goBack()} aria-label="Sair">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {categories ? (
          <Form id="form-category" onSubmit={submit} schema={formSchema} initialState={initialForm}>
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>{inputs.name}</Grid>
                <Grid item xs={12} sm={6}>{inputs.type}</Grid>
                <Grid container className={classes.colors} justify="center">
                  {inputs.color}
                </Grid>
                {/* <SubCategories subCategories={subCategories} onChange={setSubCategories} /> */}
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!categories} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-category"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {edit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategoryForm;
