import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { isEqual } from 'lodash';

function generateOptions(data) {
  // Set default chart's data
  const stepsSuppliers = [
    {
      id: 'revenue',
      value: data.revenue,
      name: 'Revenue',
      color: '#4bb75c',
    },
    {
      id: 'revenueExpected',
      value: data.revenueExpected,
      name: 'Expected Revenue',
      color: '#7ece8a',
    },
    {
      id: 'revenueGoal',
      value: data.revenueGoal,
      name: 'Goal Revenue',
      color: '#ddfde1',
    },
  ];

  const colorChart = stepsSuppliers.map((d) => d.color);

  return {
    color: colorChart,
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    series: [
      {
        type: 'pie',
        radius: ['65%', '80%'],
        label: { normal: { show: false } },
        animationEasing: 'elasticOut',
        animationDelay: (idx) => idx * 10,
        animationDelayUpdate: (idx) => idx * 10,
        data: stepsSuppliers,
      },
    ],
  };
}

const Chart = React.memo(
  ({ data, handleEmphasis }) => (
    <ReactEcharts
      option={generateOptions(data)}
      style={{ width: 128, height: 128 }}
      notMerge
      lazyUpdate
      showLoading={false}
      onEvents={{
        mouseover: handleEmphasis,
        mouseout: () => handleEmphasis(null),
      }}
      opts={{ renderer: 'svg' }}
    />
  ),
  (prevProps, nextProps) => {
    const { data: prevData } = prevProps;
    const { data: nextData } = nextProps;

    return (isEqual(prevData, nextData));
  },
);

Chart.propTypes = {
  data: PropTypes.shape({
    revenue: PropTypes.number,
    revenueExpected: PropTypes.number,
    revenueGoal: PropTypes.number,
  }).isRequired,
  handleEmphasis: PropTypes.func.isRequired,
};

export default Chart;
