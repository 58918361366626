import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(),
  },
  actionArea: {
    padding: `0 ${theme.spacing()}px ${theme.spacing()}px`,
    '& > span': {
      backgroundColor: 'white',
      '&:last-child': {
        background: 'none',
      },
    },
  },
  categoryColor: {
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 12,
    fontSize: 11,
    fontWeight: 600,
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
  },
  avatar: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    fontSize: 12,
    color: 'white',
    background: theme.palette.primary.main,
  },
  supplierName: {
    margin: `${theme.spacing(0.5)}px 0 0`,
    width: '100%',
    paddingRight: theme.spacing(2),
    fontSize: 18,
  },
  buttonActions: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  },
}));
