import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
  },
  row: {
    height: 'auto',
  },
  rowPayed: {
    height: 'auto',
    '& p': {
      textDecoration: 'line-through',
    },
  },
  rowWaiting: {
    height: 'auto',
    '& p': {
      opacity: 0.7,
    },
  },
  cellHeader: {
    fontSize: 12,
    color: theme.palette.common.black,
    border: 0,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  cellBody: {
    fontSize: 14,
    color: theme.palette.common.grey,
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    border: 'none',
    whiteSpace: 'nowrap',
    '&:last-child': {
      paddingRight: theme.spacing(0.5),
    },
  },
  cellWrap: {
    whiteSpace: 'normal',
  },
  cellBodyHighlight: {
    fontSize: 14,
    color: theme.palette.common.black,
    padding: `${theme.spacing()}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    border: 'none',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    textAlign: 'center',
  },
  payButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.9),
    },
  },
  payedLabel: {
    color: theme.palette.success.main,
    fontWeight: 900,
    textDecoration: 'none !important',
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    padding: theme.spacing(0.25),
    minWidth: 'inherit',
    marginLeft: theme.spacing(0.25),
    '&:hover': {
      background: lighten(theme.palette.success.main, 0.9),
    },
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    padding: theme.spacing(0.25),
    minWidth: 'inherit',
    marginLeft: theme.spacing(0.25),
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
    },
  },
}));
