import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';
import Notification from 'store/notification/controller';

const Task = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('tasks').orderBy('startAt', 'asc')
      .onSnapshot((ref) => {
        const tasks = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        dispatch(Company.loadTasks(tasks));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;
    const { current: event } = getState().event;

    firestore.collection('companies').doc(company.id)
      .collection('tasks').add({ ...data, updatedAt: now, createdAt: now })
      .then((doc) => {
        const notify = {
          description: `A new event task ${event.name} was assigned to you`,
          link: `/planner/${event.id}/productivity/tasks/${doc.id}`,
          type: 'productivity',
        };

        if (data.responsibles) {
          data.responsibles.forEach((res) => {
            dispatch(Notification.store(res, notify));
          });
        }

        Mixpanel.track('Create a task');
        dispatch(Alert.show({ message: 'Task created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => async (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;
    const { current: event } = getState().event;

    const { responsibles: prevResp } = await firestore.collection('companies').doc(company.id)
      .collection('tasks').doc(id)
      .get()
      .then((doc) => doc.data());

    firestore.collection('companies').doc(company.id)
      .collection('tasks').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        const notify = {
          description: `A new task event ${event.name} was assigned to you`,
          link: `/planner/${event.id}/productivity/tasks/${id}`,
          type: 'productivity',
        };

        if (data.responsibles) {
          data.responsibles.forEach((res) => {
            if (prevResp.indexOf(res) < 0) {
              dispatch(Notification.store(res, notify));
            }
          });
        }

        Mixpanel.track('Update a task');
        dispatch(Alert.show({ message: 'Task updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('tasks').doc(id)
      .delete()
      .then(() => {
        dispatch(Alert.show({ message: 'Task successfully deleted.', type: 'success' }));
        Mixpanel.track('Delete a task');
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Task;
