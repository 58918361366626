import React from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import { AlertCircle } from 'react-feather';
import styles from './style';

function MoreInterrupted() {
  const classes = styles();

  return (
    <Grid container alignItems="center" className={classes.moreContent}>
      <AlertCircle size={14} className={classes.moreInfoIconError} />
      <Typography variant="overline" className={classes.moreInfoTitleError}>
        Interrupted negotiation
      </Typography>
    </Grid>
  );
}

export default MoreInterrupted;
