import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: 800,
    margin: '96px auto 32px',
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  eventName: {
    color: theme.palette.common.black,
    fontSize: 45,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  date: {
    color: theme.palette.common.grey,
    fontSize: 14,
  },
  description: {
    fontSize: 20,
    margin: '32px 0',
  },
  value: {
    fontSize: 18,
  },
  benchName: {
    fontSize: 20,
    color: theme.palette.secondary.main,
    margin: '16px 0 0',
  },
  stepTitleIcon: {
    position: 'absolute',
    color: theme.palette.primary.main,
    opacity: 0.2,
  },
  stepTitle: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: 54,
    marginTop: 4,
  },
  btnController: {
    margin: 16,
    borderRadius: 50,
    fontSize: 18,
    padding: theme.spacing(),
    '& p': {
      margin: '0 8px',
      whiteSpace: 'nowrap',
    },
  },
}));
// #3f51b5;