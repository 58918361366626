import { isSameDay } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export function filter(data, search) {
  let filteredData = data;

  const params = new URLSearchParams(search);
  const date = {
    min: params.get('dateMin') && new Date(parseInt(params.get('dateMin'), 10)),
    max: params.get('dateMax') && new Date(parseInt(params.get('dateMax'), 10)),
  };
  const steps = params.get('steps') && params.get('steps').split(',');
  const priorities = params.get('priorities') && params.get('priorities').split(',').map((p) => parseInt(p, 10));
  const labels = params.get('labels') && params.get('labels').split(',');
  const checklist = params.get('checklist') && params.get('checklist').split(',');
  const responsibles = params.get('responsibles') && params.get('responsibles').split(',');
  const taskLate = params.get('taskLate');

  if (date.max) {
    const compareDate = (dt) => {
      const okMin = dt > date.min || isSameDay(dt, date.min);
      const okMax = dt < date.max || isSameDay(dt, date.max);

      return okMin && okMax;
    };

    filteredData = filteredData.filter((t) => compareDate(t.startAt.toDate()));
  }

  if (steps) {
    filteredData = filteredData.filter((t) => steps.indexOf(t.step) >= 0);
  }

  if (priorities) {
    filteredData = filteredData.filter((t) => priorities.indexOf(t.priority) >= 0);
  }

  if (labels) {
    filteredData = filteredData.filter((t) => {
      let match = false;
      t.labels.forEach((label) => {
        if (labels.indexOf(label) >= 0) match = true;
      });
      return match;
    });
  }

  if (checklist) {
    filteredData = filteredData.filter((t) => checklist.indexOf(t.parent) >= 0);
  }

  if (responsibles) {
    filteredData = filteredData.filter((t) => {
      let match = false;
      t.responsibles.forEach((user) => {
        if (responsibles.indexOf(user.id || user) >= 0) match = true;
      });
      return match;
    });
  }

  if (taskLate) {
    filteredData = filteredData.filter((t) => {
      const today = new Date();
      const end = t.endAt.toDate();

      if (end < today && !isSameDay(end, today) && !t.completedAt) return true;

      return false;
    });
  }

  return filteredData;
}
