import axios from 'axios';

export default async function getCNPJ(cnpj) {
  const response = await axios.get(
    'https://cors-anywhere.herokuapp.com/'
    + `https://www.receitaws.com.br/v1/cnpj/${cnpj}`,
  );

  if (response.data.status === 'OK') {
    const {
      nome,
      fantasia,
      natureza_juridica: naturezaJuridica,
      atividade_principal: atividadePrincipal,
      email,
      telefone,
      situacao,
      cep,
      logradouro,
      numero,
      complemento,
      bairro,
      municipio,
      uf,
    } = response.data;

    return {
      businessName: fantasia || nome,
      companyName: nome,
      situation: situacao,
      legalNature: naturezaJuridica,
      mainActivity: atividadePrincipal[0] && {
        code: atividadePrincipal[0].code,
        description: atividadePrincipal[0].text,
      },
      contact: { email, phone: telefone },
      address: {
        postalCode: cep,
        street: logradouro,
        number: numero,
        complement: complemento,
        // neighborhood: bairro,
        city: municipio,
        state: uf,
        country: 'Brasil',
      },
    };
  }

  throw new Error(response.data.message);
}
