import { subMonths, addMonths } from 'date-fns';

const now = new Date();
const month = (`0${now.getDate() > 13 ? now.getMonth() + 1 : subMonths(now, 1).getMonth() + 1}`).slice(-2);
const month1 = (`0${addMonths(now, now.getDate() > 13 ? 1 : 0).getMonth() + 1}`).slice(-2);
const month2 = (`0${addMonths(now, now.getDate() > 13 ? 2 : 1).getMonth() + 1}`).slice(-2);
const month3 = (`0${addMonths(now, now.getDate() > 13 ? 3 : 2).getMonth() + 1}`).slice(-2);

export default function (eventId) {
  return {
    '15YYRoBtKhYDRF3VWH9R': {
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 20:04:24'),
      contact: '17933937000201',
      installments: [
        null,
        'sMdw316Nzz7oEtBHbeW4',
      ],
      type: 'out',
      payment: {
        method: 'transference',
      },
      completed: {
        date: new Date(`2020-${month}-10 20:04:19`),
        on: true,
        value: 450,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'ZsJUAz4SEFGFdDEC0jHw',
      ],
      description: 'Product reference Chopp.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-07 19:07:06`),
      event: eventId,
      id: '15YYRoBtKhYDRF3VWH9R',
      value: 450,
      createdAt: new Date('2020-02-13 19:57:43'),
    },
    '2sAokrzeS0M2h2h0A2lL': {
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:30:11'),
      contact: '15265388000120',
      installments: [
        '9KYiRvZb6pqKMDxmsDSG',
        null,
      ],
      type: 'out',
      payment: {
        method: 'transference',
      },
      account: null,
      completed: {
        on: false,
        date: null,
        value: 0,
      },
      categories: [
        'l4ZemT7d9v84EyyW5Srl',
      ],
      description: 'Product reference Outdoor.',
      created: 'supplier',
      dueAt: new Date(`2020-${month2}-15 19:28:22`),
      event: eventId,
      value: 2500,
      createdAt: new Date('2020-02-13 19:30:07'),
    },
    '5YVYbvZbGPozNPetQPIU': {
      updatedAt: new Date('2020-02-13 20:15:37'),
      contact: '06990590000123',
      type: 'in',
      emmittedAt: null,
      account: 'eRvnUsYp4pmiLb2Nobrf',
      completed: {
        on: false,
        date: null,
        value: 0,
        status: null,
      },
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month1}-13 20:15:05`),
      event: eventId,
      value: 5000,
      category: '',
      createdAt: new Date('2020-02-13 20:15:37'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
    },
    '6EIceum9CjrZCibBbaWV': {
      contact: '28301990000117',
      type: 'out',
      payment: {
        method: 'billet',
      },
      completed: {
        date: new Date(`2020-${month}-07 19:49:04`),
        on: true,
        value: 5000,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'gi1CSApS3dGxsUVqVYbx',
      ],
      description: 'Referring to the Product Management platform.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-13 19:40:21`),
      event: eventId,
      id: '6EIceum9CjrZCibBbaWV',
      value: 5000,
      createdAt: new Date('2020-02-13 19:42:02'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:49:07'),
    },
    '78UwBKnupk3KNyluvaoz': {
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:12:50'),
      contact: '07924834000132',
      type: 'out',
      payment: {
        method: 'cash',
      },
      completed: {
        date: new Date(`2020-${month}-10 19:12:46`),
        on: true,
        value: 990,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'GA0yHOTPV7LJ6pCWxWdn',
      ],
      description: 'Products reference: Stage structure and Stage.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-10 19:11:00`),
      event: eventId,
      id: '78UwBKnupk3KNyluvaoz',
      value: 990,
      createdAt: new Date('2020-02-13 19:12:34'),
    },
    '9KYiRvZb6pqKMDxmsDSG': {
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:30:20'),
      contact: '15265388000120',
      installments: [
        null,
        '2sAokrzeS0M2h2h0A2lL',
      ],
      type: 'out',
      payment: {
        method: 'transference',
      },
      completed: {
        date: new Date(`2020-${month}-05 19:30:16`),
        on: true,
        value: 2500,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'l4ZemT7d9v84EyyW5Srl',
      ],
      description: 'Product reference Outdoor.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-15 19:28:22`),
      event: eventId,
      id: '9KYiRvZb6pqKMDxmsDSG',
      value: 2500,
      createdAt: new Date('2020-02-13 19:30:07'),
    },
    GgPN0IW6ByLH7DhOXaFT: {
      completed: {
        date: new Date(`2020-${month}-05 19:30:54`),
        on: true,
        value: 1187.5,
      },
      account: 'nVEmCAmkf36DsggffRjM',
      categories: [
        'ZsJUAz4SEFGFdDEC0jHw',
      ],
      description: 'Product reference Coffe break.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-10 19:05:00`),
      event: eventId,
      id: 'GgPN0IW6ByLH7DhOXaFT',
      value: 1187.5,
      createdAt: new Date('2020-02-13 19:06:36'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:30:57'),
      contact: '23596459000103',
      type: 'out',
      payment: {
        method: 'billet',
      },
    },
    WI2ddg60JbuIe55RTiI0: {
      type: 'out',
      payment: {
        method: 'card',
      },
      completed: {
        date: new Date(`2020-${month}-07 19:55:42`),
        on: true,
        value: 150,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'l4ZemT7d9v84EyyW5Srl',
      ],
      description: 'Product reference Disparo de e-mails.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-13 19:47:54`),
      event: eventId,
      id: 'WI2ddg60JbuIe55RTiI0',
      value: 150,
      createdAt: new Date('2020-02-13 19:48:57'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:55:45'),
      contact: '07434322000198',
    },
    euqzgE5CyD9D0LKUh6ZA: {
      type: 'in',
      emmittedAt: null,
      account: 'eRvnUsYp4pmiLb2Nobrf',
      completed: {
        on: false,
        date: null,
        value: 0,
        status: null,
      },
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month2}-13 20:15:05`),
      event: eventId,
      value: 5000,
      category: '',
      createdAt: new Date('2020-02-13 20:15:37'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:15:37'),
      contact: '06990590000123',
      objects: [

      ],
    },
    gKxfB4OrfUqE8lzRgNxC: {
      event: eventId,
      value: 1500,
      createdAt: new Date('2020-02-13 19:55:30'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:55:30'),
      contact: '06111559000175',
      type: 'out',
      payment: {
        method: 'cash',
      },
      account: null,
      completed: {
        on: false,
        date: null,
        value: 0,
      },
      categories: [
        'GA0yHOTPV7LJ6pCWxWdn',
      ],
      description: 'Product reference Tents.',
      created: 'supplier',
      dueAt: new Date(`2020-${month2}-10 19:20:00`),
    },
    h8Yqdah0FWxLwWkdFt3i: {
      event: eventId,
      value: 5000,
      category: '',
      createdAt: new Date('2020-02-13 20:15:37'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:15:37'),
      contact: '06990590000123',
      type: 'in',
      emmittedAt: null,
      account: 'eRvnUsYp4pmiLb2Nobrf',
      completed: {
        on: false,
        date: null,
        value: 0,
        status: null,
      },
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month}-13 20:15:05`),
      objects: [

      ],
    },
    jbqOQz4ssOKAf2H419pR: {
      description: 'Product reference event website.',
      created: 'supplier',
      dueAt: new Date(`2020-${month2}-13 19:51:10`),
      event: eventId,
      value: 450,
      createdAt: new Date('2020-02-13 19:53:45'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:53:51'),
      contact: '20431153000109',
      installments: [
        'rIFnxNVvVfbBnW9rozVD',
        null,
      ],
      type: 'out',
      payment: {
        method: 'card',
      },
      account: null,
      completed: {
        on: false,
        date: null,
        value: 0,
      },
      categories: [
        'l4ZemT7d9v84EyyW5Srl',
      ],
    },
    rIFnxNVvVfbBnW9rozVD: {
      installments: [
        null,
        'jbqOQz4ssOKAf2H419pR',
      ],
      type: 'out',
      payment: {
        method: 'card',
      },
      account: null,
      completed: {
        on: false,
        date: null,
        value: 0,
      },
      categories: [
        'l4ZemT7d9v84EyyW5Srl',
      ],
      description: 'Product reference event website.',
      created: 'supplier',
      dueAt: new Date(`2020-${month1}-13 19:51:10`),
      event: eventId,
      value: 450,
      createdAt: new Date('2020-02-13 19:53:45'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 19:53:51'),
      contact: '20431153000109',
    },
    sMdw316Nzz7oEtBHbeW4: {
      event: eventId,
      id: 'sMdw316Nzz7oEtBHbeW4',
      value: 450,
      createdAt: new Date('2020-02-13 19:57:43'),
      costCenter: [

      ],
      test: true,
      objects: [

      ],
      updatedAt: new Date('2020-02-13 20:04:29'),
      contact: '17933937000201',
      installments: [
        '15YYRoBtKhYDRF3VWH9R',
        null,
      ],
      type: 'out',
      payment: {
        method: 'transference',
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      completed: {
        on: true,
        date: new Date(`2020-${month}-13 20:04:27`),
        value: 450,
      },
      categories: [
        'ZsJUAz4SEFGFdDEC0jHw',
      ],
      description: 'Product reference Chopp.',
      created: 'supplier',
      dueAt: new Date(`2020-${month2}-07 19:07:06`),
    },
    uP2n1Obllbe7CeFEGoF7: {
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month3}-13 20:15:05`),
      event: eventId,
      value: 5000,
      category: '',
      createdAt: new Date('2020-02-13 20:15:37'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:15:37'),
      contact: '06990590000123',
      type: 'in',
      emmittedAt: null,
      account: 'eRvnUsYp4pmiLb2Nobrf',
      completed: {
        on: false,
        date: null,
        value: 0,
        status: null,
      },
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      objects: [

      ],
    },
    xwqOfpZGSoUnzt5KpMBf: {
      type: 'in',
      emmittedAt: {
        _seconds: 1581635280,
        _nanoseconds: 0,
      },
      account: 'nVEmCAmkf36DsggffRjM',
      completed: {
        on: false,
        date: null,
        value: 0,
        status: null,
      },
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month2}-13 20:08:00`),
      event: eventId,
      value: 2500,
      category: '',
      createdAt: new Date('2020-02-13 20:09:24'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:09:24'),
      contact: '84429695000111',
      objects: [

      ],
    },
    yB6wu7Kko4qQTKrw9yTt: {
      value: 2500,
      category: '',
      createdAt: new Date('2020-02-13 20:09:24'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:09:47'),
      contact: '84429695000111',
      type: 'in',
      emmittedAt: {
        _seconds: 1581635280,
        _nanoseconds: 0,
      },
      method: 'transference',
      completed: {
        on: true,
        receipt: [

        ],
        date: new Date(`2020-${month}-11 20:09:00`),
        value: 2500,
        status: null,
      },
      account: 'nVEmCAmkf36DsggffRjM',
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month1}-13 20:08:00`),
      event: eventId,
      objects: [

      ],
    },
    zxXHKLmu1UPeegJ2UO2t: {
      value: 10000,
      category: '',
      createdAt: new Date('2020-02-13 20:12:56'),
      costCenter: [

      ],
      test: true,
      updatedAt: new Date('2020-02-13 20:13:05'),
      contact: '22143068000162',
      type: 'in',
      emmittedAt: {
        _seconds: 1579129920,
        _nanoseconds: 0,
      },
      method: 'transference',
      completed: {
        status: null,
        date: new Date(`2020-${month}-06 20:12:00`),
        on: true,
        receipt: [

        ],
        value: 10000,
      },
      account: 'eRvnUsYp4pmiLb2Nobrf',
      categories: [
        'R3YTZAD1gTcv9s3LTv5i',
      ],
      description: 'Event Sponsorship',
      created: 'manual',
      dueAt: new Date(`2020-${month}-11 20:12:00`),
      event: eventId,
      objects: [

      ],
    },
  };
}
