import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// Material UI Components
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Controllers
import Contact from 'store/contact/controller';
import Supplier from 'store/supplier/controller';

// Styles
import { MessageCircle, ChevronDown } from 'react-feather';
import styles from '../style';

// Components
import RegisterAddress from './address';
import RegisterDocuments from './documents';
import RegisterChat from './chat';

function RegisterData({ supplier }) {
  const classes = styles();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const { contacts } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [register, setRegister] = useState(null);

  const permissionEdit = !permissions || !permissions.suppliers
    || permissions.suppliers.negotiations.edit;

  useEffect(() => {
    if (contacts && supplier && supplier.register && supplier.register.cnpj) {
      const suppRegister = contacts.find((c) => c.id === supplier.register.cnpj);
      if (suppRegister) setRegister(suppRegister);
    }
  }, [supplier, contacts, dispatch]);

  useEffect(() => {
    if (!contacts) dispatch(Contact.index());
  }, [dispatch, contacts]);

  const handleChat = (e, expanded) => {
    const { chat } = supplier.register;

    if (chat && expanded) {
      const newChat = chat.map((msg) => {
        const viewed = (msg.author === 'supplier' && !msg.viewed) || msg.viewed || false;
        return { ...msg, viewed };
      });

      if (newChat.length > 0 && chat.find((m) => m.author === 'supplier' && !m.viewed)) {
        dispatch(Supplier.sendRegisterChat(eventId, supplier.id, newChat));
      }
    }
  };

  let chatBadged = 0;

  if (supplier.register && supplier.register.chat) {
    chatBadged = supplier.register.chat.filter((m) => m.author === 'supplier' && !m.viewed).length;
  }

  if (register) {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.dividerText} style={{ marginTop: 0 }}>
          <Typography gutterBottom variant="overline">
            COMPANY DATA
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Typography variant="overline" className={classes.infoTitle}>
              CNPJ
            </Typography>
            <Typography className={classes.infoText}>{register.document}</Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="overline" className={classes.infoTitle}>
              Fantasy name
            </Typography>
            <Typography className={classes.infoText}>{register.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="overline" className={classes.infoTitle}>
              Company name
            </Typography>
            <Typography className={classes.infoText}>{register.companyName}</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography variant="overline" className={classes.infoTitle}>
              Legal Nature
            </Typography>
            <Typography className={classes.infoText}>{register.legalNature}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="overline" className={classes.infoTitle}>
              CNPJ status
            </Typography>
            <Typography className={classes.infoText}>{register.situation}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.dividerText}>
          <Typography gutterBottom variant="overline">
            MAIN ACTIVITY
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Typography variant="overline" className={classes.infoTitle}>
              Code
            </Typography>
            <Typography className={classes.infoText}>{register.mainActivity.code}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant="overline" className={classes.infoTitle}>
              Description
            </Typography>
            <Typography className={classes.infoText}>
              {register.mainActivity.description}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.dividerText}>
          <Typography gutterBottom variant="overline">
            CONTACT
          </Typography>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <Grid container spacing={1}>
          {register.contact.email && (
            <Grid item xs={12} sm={register.contact.phone ? 6 : 12}>
              <Typography variant="overline" className={classes.infoTitle}>
                E-mail
              </Typography>
              <Typography className={classes.infoText}>{register.contact.email}</Typography>
            </Grid>
          )}
          {register.contact.phone && (
            <Grid item xs={12} sm={register.contact.email ? 6 : 12}>
              <Typography variant="overline" className={classes.infoTitle}>
                Phone
              </Typography>
              <Typography className={classes.infoText}>{register.contact.phone}</Typography>
            </Grid>
          )}
        </Grid>

        <RegisterAddress address={register.address} />

        {supplier.register.notes && (
          <>
            <Grid item xs={12} className={classes.dividerText}>
              <Typography gutterBottom variant="overline">
                NOTES
              </Typography>
              <Divider variant="middle" className={classes.divider} />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.infoText}>{supplier.register.notes}</Typography>
              </Grid>
            </Grid>
          </>
        )}

        {supplier.register.attachments && supplier.register.attachments.length > 0 && (
          <RegisterDocuments files={supplier.register.attachments} />
        )}

        {permissionEdit && (
          <>
            <Grid item xs={12} className={classes.dividerText}>
              <Typography gutterBottom variant="overline">
                REQUEST ADJUSTMENTS
              </Typography>
              <Divider variant="middle" className={classes.divider} />
            </Grid>
            <ExpansionPanel className={classes.chat} onChange={handleChat}>
              <ExpansionPanelSummary
                id="chat"
                aria-controls="chat"
                expandIcon={<ChevronDown />}
                className={classes.chatHeader}
                classes={{ expandIcon: classes.chatIcon, content: classes.chatHeaderContent }}
              >
                {chatBadged > 0 ? (
                  <Badge badgeContent={chatBadged} color="secondary">
                    <MessageCircle />
                  </Badge>
                ) : (
                  <MessageCircle />
                )}

                <Typography style={{ fontWeight: 500, marginLeft: 16 }}>
                  Talk to a supplier
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: 0 }}>
                <RegisterChat supplier={supplier} chat={supplier.register.chat || []} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </>
        )}
      </Grid>
    );
  }

  return <></>;
}

RegisterData.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    register: PropTypes.object,
  }).isRequired,
};

export default RegisterData;
