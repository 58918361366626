import React from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Icons and Styles
import {
  Folder, Image, File, X,
} from 'react-feather';
import styles from './style';

function UploadDocument({
  index, document, files, onChange,
}) {
  const classes = styles();

  const uploaded = !!document.uploaded || !!document.url || false;

  const removeFile = (e) => {
    e.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(newFiles);
  };

  const getIcon = () => {
    const icons = [Image, Folder, File];
    const extensions = [
      'jpg,jpeg,gif,raw,png,tiff,tif,svg',
      'zip,rar,tar',
      'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
    ];

    if (document.extension) {
      for (let i = 0; i < extensions.length; i += 1) {
        if (extensions[i].indexOf(document.extension) >= 0) return icons[i];
      }
    }

    return icons[2];
  };

  const Icon = getIcon();

  return (
    <Grid
      container
      justify="space-between"
      className={`${classes.uploadFile} ${uploaded && classes.uploadFileConfirm}`}
      key={document.name}
      onClick={() => !!document.url && window.open(document.url)}
    >
      <Grid
        item
        style={{
          display: 'flex', alignItems: 'center', overflow: 'hidden', flexWrap: 'nowrap',
        }}
      >
        <Icon size={20} style={{ marginRight: 8, minWidth: 20 }} />
        <Typography className={classes.label} style={document.url ? { cursor: 'pointer' } : {}}>
          {document.label}
        </Typography>
      </Grid>

      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {!uploaded ? (
          <div style={{ padding: '8px 24px', height: 32 }}>
            <CircularProgress color="primary" size={18} />
          </div>
        ) : (
          <IconButton color="primary" style={{ padding: 8 }} onClick={removeFile}>
            <X size={18} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

UploadDocument.propTypes = {
  index: PropTypes.number.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    extension: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    default: PropTypes.bool,
    uploading: PropTypes.bool,
    uploaded: PropTypes.bool,
    editing: PropTypes.bool,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UploadDocument;
