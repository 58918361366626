import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Icons, Common and Styles
import styles from './style';

// Components
import ProductsTable from './productsTable';
import Freigth from './freigth';
import Discount from './discount';

function ProductsView({
  edit, budget, products, onChange,
}) {
  const classes = styles();

  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell className={classes.cellHeader}>Product</TableCell>
            <TableCell className={classes.cellHeader} align="center">
              Value
            </TableCell>
            <TableCell className={classes.cellHeader} align="center">
              Amount
            </TableCell>
            <TableCell className={classes.cellHeader} align="right">
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ProductsTable edit={edit} budget={budget} products={products} onChange={onChange} />

          <TableRow className={classes.row}>
            <TableCell className={classes.cellBody} colSpan={edit ? 2 : 3} align="right">
              <span className={classes.cellTitleHighligth}>Frete</span>
            </TableCell>
            <TableCell className={classes.cellBody} colSpan={edit ? 2 : 1} align="right">
              <Freigth
                edit={edit}
                budget={budget}
                onChange={(newBudget) => onChange({ newBudget })}
              />
            </TableCell>
          </TableRow>

          <TableRow className={classes.row}>
            <TableCell className={classes.cellBody} colSpan={edit ? 2 : 3} align="right">
              <span className={classes.cellTitleHighligth}>Discount</span>
            </TableCell>
            <TableCell className={classes.cellBody} colSpan={edit ? 2 : 1} align="right">
              <Discount
                edit={edit}
                budget={budget}
                onChange={(newBudget) => onChange({ newBudget })}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

ProductsView.propTypes = {
  edit: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  budget: PropTypes.shape({
    products: PropTypes.object,
    discount: PropTypes.object,
    freight: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

ProductsView.defaultProps = {
  budget: {
    discount: { value: 0, type: 'money' },
    freight: 0,
    products: {},
    total: 0,
  },
  products: null,
};

export default ProductsView;
