import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'overlay',
    overflowX: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing()}px ${theme.spacing()}px`,
      maxHeight: 'inherit',
      height: 'auto',
    },
  },
  cards: {
    padding: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontWeight: 300,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  subtitle: {
    fontWeight: 700,
    color: theme.palette.secondary.main,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '&:after': {
      content: '""',
      height: 2,
      backgroundColor: theme.palette.secondary.main,
      flex: `1 0 ${theme.spacing()}px`,
      margin: `0 0 0 ${theme.spacing()}px`,
      borderRadius: 2,
    },
  },
  empty: {
    color: theme.palette.common.grey,
    margin: theme.spacing(),
    fontSize: 18,
  },
  button: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(16),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
}));
