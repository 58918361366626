import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isSameDay, differenceInCalendarDays, format } from 'date-fns';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// Icons and Styles
import { Image, Folder, FileText } from 'react-feather';
import styles from './style';

function MessageBox({ channel, data, openProfile }) {
  const classes = styles();

  const { current: user } = useSelector((store) => store.user);
  const { users } = useSelector((store) => store.company);

  let date = '';
  const now = new Date();
  const sendAt = data.sendAt.toDate();

  if (isSameDay(now, sendAt)) {
    date = format(sendAt, 'HH:mm');
  } else {
    const diffDays = differenceInCalendarDays(now, sendAt);
    if (diffDays === 1) date = format(sendAt, "'Yesterday at 'HH:mm");
    else date = format(sendAt, "dd/MM/yyyy' às 'HH:mm");
  }

  const getIcon = (extension) => {
    const icons = [Image, Folder, FileText];
    const extensions = [
      'jpg,jpeg,gif,raw,png,tiff,tif,svg',
      'zip,rar,tar',
      'doc,docx,txt,odt,pdf,xls,xlsx,ods,ppt,pptx',
    ];

    if (extension) {
      for (let i = 0; i < extensions.length; i += 1) {
        if (extensions[i].indexOf(extension) >= 0) return icons[i];
      }
    }

    return icons[2];
  };

  const formatSize = (size) => {
    const b = size;
    if (b > 1000) {
      const kb = b / 1000;
      if (kb > 1000) {
        const mb = kb / 1000;
        if (mb > 1000) {
          const gb = mb / 1000;
          if (gb > 1000) {
            return `${(gb / 1000).toFixed(1)} TB`;
          }
          return `${gb.toFixed(1)} GB`;
        }
        return `${mb.toFixed(1)} MB`;
      }
      return `${kb.toFixed(1)} KB`;
    }
    return `${b.toFixed(1)} B`;
  };

  const MessageAttachment = (sdr) => (doc) => {
    const [extension] = doc.name.split('.').reverse();
    const Icon = getIcon(extension);
    const extImage = 'jpg,jpeg,gif,raw,png,tiff,tif,svg';

    if (extImage.indexOf(extension) >= 0) {
      return (
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="space-between"
          className={`${classes.msgDoc} ${sdr ? classes.docSdr : ''}`}
          onClick={() => window.open(doc.url)}
        >
          <img style={{ maxWidth: '100%' }} alt={doc.label} src={doc.url} />
        </Grid>
      );
    }

    return (
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
        className={`${classes.msgDoc} ${sdr ? classes.docSdr : ''}`}
        onClick={() => window.open(doc.url)}
      >
        <Grid container alignItems="center" wrap="nowrap" style={{ overflow: 'hidden', marginRight: 8 }}>
          <Icon size={16} style={{ marginRight: 8 }} />
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {doc.label}
          </div>
        </Grid>
        <div style={{ fontSize: 12, color: '#777', whiteSpace: 'nowrap' }}>
          {formatSize(doc.size)}
        </div>
      </Grid>
    );
  };

  let MessageContent = [];
  if (data.sender === user.id) {
    MessageContent = (
      <Grid container justify="flex-end">
        <div className={`${classes.msgBox} ${classes.sdrBox}`}>
          {data.attachments && data.attachments.map(MessageAttachment(true))}
          {data.content}
          <p>{date}</p>
        </div>
      </Grid>
    );
  } else if (data.sender === 'log') {
    MessageContent = (
      <Grid container justify="center">
        <div className={classes.logBox}>{data.content}</div>
      </Grid>
    );
  } else {
    const sender = users.find((u) => data.sender === u.id);

    MessageContent = (
      <Grid container>
        <div className={classes.msgBox}>
          {channel.group && (
            <>
              {!sender.deletedAt ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  component="button"
                  className={classes.sender}
                  onClick={() => openProfile(sender.id)}
                >
                  {sender.name}
                </Link>
              ) : (
                <div style={{ color: '#777', fontSize: 12, fontWeight: 600 }}>
                  {sender.name}
                </div>
              )}
            </>
          )}
          {data.attachments && data.attachments.map(MessageAttachment())}
          <div>{data.content}</div>
          <p>{date}</p>
        </div>
      </Grid>
    );
  }


  return MessageContent;
}

MessageBox.propTypes = {
  channel: PropTypes.shape({
    group: PropTypes.object,
  }).isRequired,
  data: PropTypes.shape({
    content: PropTypes.string,
    sender: PropTypes.string,
    sendAt: PropTypes.object,
  }).isRequired,
  openProfile: PropTypes.func.isRequired,
};

export default MessageBox;
