/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

// Financial
import { ReactComponent as CostCenterIcon } from './cost-center.svg';
import { ReactComponent as BankIcon } from './bank.svg';
import { ReactComponent as WaitingIcon } from './stopwatch.svg';
import { ReactComponent as AdminIcon } from './admin.svg';
import { ReactComponent as ToolsIcon } from './tools.svg';

// Financial
export { ReactComponent as ROI } from './roi.svg';
export { ReactComponent as CashIn } from './cash-in.svg';
export { ReactComponent as CashOut } from './cash-out.svg';

// Suppliers
export { ReactComponent as BudgetUsed } from './budget-used.svg';
export { ReactComponent as BudgetForecast } from './budget-forecast.svg';
export { ReactComponent as BudgetRemaining } from './budget-remaining.svg';
export { ReactComponent as Unarchive } from './unarchive.svg';

// Partners
export { ReactComponent as Faster } from './faster.svg';
export { ReactComponent as Slower } from './slower.svg';
export { ReactComponent as SalesConvertion } from './sales-convertion.svg';
export { ReactComponent as NegotiationTime } from './negotiation-time.svg';

// Commons
export { ReactComponent as Import } from './import.svg';
export { ReactComponent as Medal } from './medal.svg';
export { ReactComponent as WorkingColor } from './working-color.svg';

export function CostCenter({ size, color, style, ...rest }) {
  return <CostCenterIcon style={{ width: size, height: size, fill: color, ...style }} {...rest} />;
}

export function Bank({ size, color, style, ...rest }) {
  return <BankIcon style={{ width: size, height: size, stroke: color, ...style }} {...rest} />;
}

export function Waiting({ size, color, style, ...rest }) {
  return <WaitingIcon style={{ width: size, height: size, stroke: color, ...style }} {...rest} />;
}

export function Admin({ size, color, style, ...rest }) {
  return <AdminIcon style={{ width: size, height: size, stroke: color, ...style }} {...rest} />;
}

export function Tools({ size, color, style, ...rest }) {
  return <ToolsIcon style={{ width: size, height: size, strokeColor: color, ...style }} {...rest} />;
}

const propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

const defaultProps = {
  size: 24,
  color: 'grey',
};

CostCenter.propTypes = propTypes;
CostCenter.defaultProps = defaultProps;

Bank.propTypes = propTypes;
Bank.defaultProps = defaultProps;

Waiting.propTypes = propTypes;
Waiting.defaultProps = defaultProps;

Admin.propTypes = propTypes;
Admin.defaultProps = defaultProps;

Tools.propTypes = propTypes;
Tools.defaultProps = defaultProps;
