export default function Form() {
  return [
    {
      name: 'name',
      type: 'text',
      label: 'Nome',
    },
    {
      name: 'email',
      type: 'text',
      inputType: 'email',
      label: 'E-mail',
      validation: {
        required: 'The e-mail is required',
        email: 'Must be a valid e-mail',
      },
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Phone',
      mask: 'phone',
      validation: { phone: 'Must be a valid phone number' },
    },
    {
      name: 'site',
      type: 'text',
      label: 'Website',
      validation: { url: 'Must be a valid url' },
    },
  ];
}
