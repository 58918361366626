import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import FlatfileImporter from 'flatfile-csv-importer';

// Material UI components
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useEventController from 'store/event/controller';
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Product from 'store/supplier/product/controller';

// Icons, Commons and Styles
import { Filter, Archive, Plus, LogIn } from 'react-feather';
import { Suppliers, Financial, Partners } from 'assets/custom-icons/modules';
import Loading from 'common/loading';
import Toolbar from 'common/toolbar';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import ArchivedMenu from './components/archived';
import Card from './components/card';
import FilterDialog from './components/filter';
import { sortSuppliers, filter } from './utils';
import styles from './style';

const COLUMNS = [
  {
    name: 'Contact',
    description:
      'Here are all the suppliers that must be contacted and quote requested.',
    add: true,
  },
  {
    name: 'Negotiation',
    description:
      'Hmmm, what will be the best option for my event? Here you can compare quotes and hire the supplier you like the most.',
  },
  {
    name: 'Register',
    description:
      'Is this the supplier you want? Input their employer tax information and let\'s hire them.',
  },
  {
    name: 'Closed',
    description:
      'Deal! Upload contract information.',
  },
  {
    name: 'Delivery',
    description: 'Everything is in order, you can be sure that your event will be a success.',
  },
];

const importer = new FlatfileImporter(
  process.env.REACT_APP_FLATFILE,
  {
    fields: [
      {
        label: 'Supplier name',
        key: 'name',
        isRequired: true,
      },
      {
        label: 'Products',
        key: 'products',
        isRequired: true,
      },
      {
        label: 'Contact name',
        key: 'contactName',
      },
      {
        label: 'Contact e-mail',
        key: 'contactEmail',
        isRequired: true,
        validators: [{
          validate: 'regex_matches',
          regex: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          error: 'Must be a valid e-mail',
        }],
      },
      {
        label: 'Contact phone',
        key: 'contactPhone',
        validators: [{
          validate: 'regex_matches',
          regex: /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/,
          error: 'Not a valid phone',
        }],
      },
      {
        label: 'Contact website',
        key: 'contactSite',
        validators: [{
          validate: 'regex_matches',
          regex: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
          error: 'Not a valid url',
        }],
      },
      {
        label: 'Location (City / State)',
        key: 'contactLocation',
      },
    ],
  },
);

function Negotiations() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { eventId } = useParams();

  // Controllers
  const Event = useEventController(dispatch);

  const { products, suppliers, current: event } = useSelector((state) => state.event);
  const { cashFlow } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [openFilter, setOpenFilter] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showCategories, setShowCategories] = useState(false);
  const [filterBadge, setFilterBadge] = useState(0);

  const permissionView = !permissions || !permissions.manager || permissions.manager.procurement.view;
  const permissionEdit = !permissions || !permissions.manager || permissions.manager.procurement.edit;

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [permissionView, history]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const filters = [
      'products',
      'categories',
      'responsibles',
      'valueMin',
      'currentBiggerForecast',
      'paymentLate',
      'deliveryLate',
    ];

    let badge = 0;
    filters.forEach((f) => {
      if (params.get(f)) badge += 1;
    });

    setFilterBadge(badge);
  }, [history.location.search]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
      setIsLoading(false);
    }
  }, [event, eventId, dispatch, Event]);

  // Load data to use in this module
  useEffect(() => {
    if (event && !isLoading) {
      if (!suppliers) dispatch(Supplier.indexEvent(event.id));
      if (!products) dispatch(Product.index(event.id));
      if (!cashFlow) dispatch(CashFlow.index());
      setIsLoading(true);
    }
  }, [event, suppliers, products, cashFlow, dispatch, isLoading]);

  const importItems = () => {
    importer.requestDataFromUser().then(async ({ data }) => {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];

        const contact = {
          name: item.contactName,
          email: item.contactEmail,
          phone: item.contactPhone,
          site: item.contactSite,
          location: item.contactLocation,
        };

        const productsName = item.products.split(',');
        let productsItem = productsName.map((cc) => products.find(
          (c) => c.name.toLowerCase().indexOf(cc.trim().toLowerCase()) >= 0,
        ));
        productsItem = productsItem.filter((c) => !!c);
        productsItem = productsItem.map((c) => c.id);

        const itemData = { name: item.name, contact, products: productsItem };

        dispatch(Supplier.store(itemData));
      }

      importer.displaySuccess('All data imported!');
    }).catch((error) => {
      console.info(error || 'window close');
    });
  };

  const toolbarIcons = [
    {
      label: 'Filter',
      icon: <Filter />,
      badge: filterBadge,
      click: () => setOpenFilter(true),
    },
    {
      id: 'archive',
      label: 'File',
      icon: <Archive />,
      menu: ArchivedMenu,
    },
  ];

  if (permissionEdit) {
    toolbarIcons.unshift({
      label: 'Import negotiation',
      icon: <LogIn style={{ transform: 'rotate(180deg)' }} />,
      click: importItems,
      disabled: !products,
    });
  }

  const tabsToolbar = [
    { title: 'Dashboard', path: `/manager/${eventId}/procurement` },
    { title: 'Items', path: `/manager/${eventId}/procurement/items` },
    { title: 'Negotiations', selected: true },
  ];

  const isLoaded = !!suppliers && !!products && !!cashFlow;

  return (
    <div className={classes.containerPage}>
      <Toolbar
        avatar
        defaultIcons
        title="PROCUREMENT"
        position="fixed"
        shadow="none"
        sidebar="/manager/procurement"
        tabs={tabsToolbar}
        icons={toolbarIcons}
      />

      {upSm ? (
        <Grid container direction="column" className={classes.container}>
          <Grid container className={classes.root} spacing={2} alignItems="flex-start">
            {COLUMNS.map((col, key) => (
              <Grid item className={classes.steps} key={col.name}>
                <Typography variant="h5" align="center" className={classes.textStatus}>
                  {col.name}
                </Typography>
                <div className={classes.cardGrid}>
                  {!isLoaded && <Loading content={150} ready={isLoaded} />}
                  {permissionEdit && col.add && (
                    <Plus
                      size={32}
                      strokeWidth="3"
                      className={classes.addSupplier}
                      onClick={() => {
                        history.push(`/manager/${eventId}/procurement/negotiations/new`);
                      }}
                    />
                  )}
                  {isLoaded
                    && suppliers
                      .filter((s) => s.status === key && !s.archived)
                      .filter((s) => filter(s, products, history.location.search))
                      .sort(sortSuppliers)
                      .map((supplier) => (
                        <Card
                          key={supplier.id}
                          supplier={supplier}
                          showCategories={showCategories}
                          setShowCategories={setShowCategories}
                        />
                      ))}
                  <Typography className={classes.stepDescription}>
                    {col.description}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <>
          <Tabs
            value={tabIndex}
            textColor="primary"
            classes={{ flexContainer: classes.tabs }}
            onChange={(e, i) => setTabIndex(i)}
          >
            {COLUMNS.map((col) => (
              <Tab key={col.name} label={col.name} className={classes.tab} />
            ))}
          </Tabs>
          <Grid container>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={(i) => setTabIndex(i)}
              enableMouseEvents
            >
              {COLUMNS.map((col, key) => (
                <Grid key={col.name} item xs className={classes.cardGrid}>
                  {!isLoaded && <Loading content={150} ready={isLoaded} />}
                  {isLoaded
                    && suppliers
                      .filter((s) => s.status === key && !s.archived)
                      .filter((s) => filter(s, products, history.location.search))
                      .sort(sortSuppliers)
                      .map((supplier) => (
                        <Card
                          key={supplier.id}
                          supplier={supplier}
                          showCategories={showCategories}
                          setShowCategories={setShowCategories}
                        />
                      ))}
                  <Typography className={classes.stepDescription}>
                    {col.description}
                  </Typography>
                </Grid>
              ))}
            </SwipeableViews>
          </Grid>
        </>
      )}

      <Onboarding id="manager" title="Monitor the management of your event">
        <iframe
          allowfullscreen
          width="100%"
          height="315"
          title="manager"
          frameborder="0"
          src="https://www.youtube.com/embed/x17RaEoauh0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />  
      </Onboarding>

      <Tools />

      {openFilter && <FilterDialog onClose={() => setOpenFilter(false)} />}
    </div>
  );
}

export default Negotiations;
