/**
 * Types
 */
const Types = {
  LOAD: '@user/LOAD',
  LOAD_NOTIFYS: '@user/LOAD_NOTIFYS',
  STORE: '@user/STORE',
  CLEAR: '@user/CLEAR',
};

/**
 * Actions
 */
export const Actions = {
  load: (current) => ({ type: Types.LOAD, payload: { current } }),
  loadNotifys: (notifys) => ({ type: Types.LOAD_NOTIFYS, payload: { notifys } }),
  store: (store) => ({ type: Types.STORE, payload: { store } }),
  clear: () => ({ type: Types.CLEAR }),
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  store: { creating: false, error: null },
  current: null,
  notifys: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOAD: {
      const { current } = action.payload;
      return { ...state, current };
    }
    case Types.LOAD_NOTIFYS: {
      const { notifys } = action.payload;
      return { ...state, notifys };
    }
    case Types.STORE: {
      const { store } = action.payload;
      return { ...state, store };
    }
    case Types.CLEAR: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
