import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import createImporter from 'services/importer';
import getCNPJ from 'services/receitaws';

// Material Components
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controller
import Contact from 'store/contact/controller';
import CashFlow from 'store/financial/cash-flow/controller';

// Icons and Styles
import {
  Plus, PlusCircle, MinusCircle, Download, Calendar, List,
} from 'react-feather';
import Tooltip from 'common/tooltip';
// import ButtonToTop from 'common/button-to-top';
import CashFlowTable from '../cash-flow-table';
import PayablesReceivablesTable from '../payables-receivables-table';
import CashFlowCalendar from '../calendar';
import styles from './style';

function TabsTable() {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const { eventId } = useParams();

  const { contacts, categories: allCategories, costCenter: allCostCenter } = useSelector(
    (store) => store.company,
  );
  const { current: { permissions } } = useSelector((store) => store.user);

  const [importer, setImporter] = useState(null);
  const [menu, setMenu] = useState(null);
  const [tabTable, setTabTable] = useState('accounts');
  const [tabVisualization, setTabVisualization] = useState('list');

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    setImporter(createImporter({
      fields: [
        {
          label: 'Payer/Payee (Tax Id or Social Security Number)',
          key: 'contact',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /(^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$)|(^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|(^[0-9]{14}$)|(^[0-9]{11}$)/,
            error: 'Not a valid Tax Id or Social Security Number',
          }],
        },
        {
          label: 'Description',
          key: 'description',
        },
        {
          label: 'Value',
          key: 'value',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
            error: 'Not a valid number (remove the coin \'$\', if there is any)',
          }],
        },
        {
          label: 'Issued in',
          key: 'emmittedAt',
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
            error: 'Not a valid date (22/03/2020)',
          }],
        },
        {
          label: 'Due date',
          key: 'dueAt',
          isRequired: true,
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
            error: 'Not a valid date (22/03/2020)',
          }],
        },
        {
          label: 'Categories',
          key: 'categories',
          isRequired: true,
        },
        {
          label: 'Cost Center',
          key: 'costCenter',
        },
        {
          label: 'Notes',
          key: 'notes',
        },
        {
          label: 'Received/Paid',
          key: 'completedOn',
          alternates: ['Yes', 'No'],
          validators: [{
            validate: 'regex_matches',
            regex: /^(Sim|Não)$/,
            error: 'Use only "Yes" or "No"',
          }],
        },
        {
          label: 'Received/Paid Value',
          key: 'completedValue',
          validators: [{
            validate: 'regex_matches',
            regex: /^(-|)[.0-9]*(,[0-9]{1,2}|)$/,
            error: 'Not a valid number (remove the coin \'$\', if there is any)',
          }],
        },
        {
          label: 'Received/Paid Date',
          key: 'completedDate',
          validators: [{
            validate: 'regex_matches',
            regex: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
            error: 'Not a valid date (22/03/2020)',
          }],
        },
      ],
    }));
  }, []);

  const importSheet = () => {
    const convertDate = (date) => {
      if (date) {
        const day = date.slice(0, 2);
        const month = date.slice(3, 5);
        const year = date.slice(6, 10);

        return new Date(`${year}-${month}-${day}`);
      }

      return null;
    };

    const convertNumber = (number) => parseFloat(
      number.trim().split('.')
        .join('')
        .split(',')
        .join('.'),
    );

    importer
      .requestDataFromUser()
      .then(({ data }) => {
        data.forEach(async (cash) => {
          const contactDoc = cash.contact.trim().split(/\.|\/|-/).join('');
          const contact = contactDoc;
          if (!contacts.find((c) => c.id === contactDoc) && contactDoc.length > 11) {
            try {
              const contactData = await getCNPJ(contactDoc);
              const contactCreate = {
                document: cash.contact,
                name: contactData.businessName,
                companyName: contactData.companyName,
                situation: contactData.situation,
                legalNature: contactData.legalNature,
                mainActivity: contactData.mainActivity,
                contact: {
                  email: contactData.contact.email,
                  phone: contactData.contact.phone,
                },
                address: contactData.address,
                types: ['Others'],
              };
              dispatch(Contact.store(contactCreate));
            } catch (error) {
              console.log(error);
            }
          }

          let value = convertNumber(cash.value);

          let type = 'in';
          if (value < 0) {
            value *= -1;
            type = 'out';
          }

          const emmittedAt = convertDate(cash.emmittedAt);
          const dueAt = convertDate(cash.dueAt);

          const categoriesName = cash.categories.split(',');
          let categories = categoriesName.map((cc) => allCategories.find(
            (c) => c.name.toLowerCase().indexOf(cc.trim().toLowerCase()) >= 0,
          ));
          categories = categories.filter((c) => !!c);
          categories = categories.map((c) => c.id);

          const costCenterName = cash.costCenter.split(',');
          let costCenter = costCenterName.map((cc) => allCostCenter.find(
            (c) => c.name.toLowerCase().indexOf(cc.trim().toLowerCase()) >= 0
              || c.code.toLowerCase().indexOf(cc.trim().toLowerCase()) >= 0,
          ));
          costCenter = costCenter.filter((c) => !!c);
          costCenter = costCenter.map((c) => c.id);

          const completed = {
            on: cash.completedOn === 'Yes',
            value: convertNumber(cash.completedValue),
            date: convertDate(cash.completedDate),
          };

          if (type === 'out') completed.value *= -1;

          const cashData = {
            type,
            value,
            dueAt,
            contact,
            completed,
            emmittedAt,
            categories,
            costCenter,
            account: '',
            notes: cash.notes,
            description: cash.description,
          };

          dispatch(CashFlow.store(cashData));
        });

        importer.displaySuccess('All data imported!');
      })
      .catch((error) => {
        console.info(error || 'window close');
      });
    setMenu(null);
  };

  const handleAddMenu = (type) => {
    history.push(`/manager/${eventId}/financial/cash-flow?type=${type}`);
    setMenu(null);
  };

  const tabs = [
    { name: 'ACCOUNTS PAYABLE RECEIVE', id: 'accounts' },
    { name: 'INFLOW AND OUTFLOW', id: 'cash-flow' },
  ];

  const tabsVisualization = [
    { name: <List />, id: 'list', tooltip: 'See list' },
    { name: <Calendar />, id: 'calendar', tooltip: 'See calendar' },
  ];

  let matchTab = false;

  return (
    <Grid
      container
      justify="center"
      className={classes.row}
      style={{ marginBottom: mobile ? 32 : 0 }}
    >
      <Grid container className={classes.container} wrap="nowrap">
        {!mobile && permissionEdit && (
          <Fab color="primary" style={{ marginRight: 24 }} onClick={(e) => setMenu(e.currentTarget)}>
            <Plus />
          </Fab>
        )}

        {mobile && permissionEdit ? (
          <Fab
            color="primary"
            className={classes.addButton}
            onClick={(e) => setMenu(e.currentTarget)}
          >
            <Plus />
          </Fab>
        ) : ''}
        {/* ) : !mobile && <ButtonToTop />} */}

        <Menu open={!!menu} anchorEl={menu} onClose={() => setMenu(null)}>
          <MenuItem onClick={() => handleAddMenu('in')}>
            <ListItemIcon style={{ minWidth: 34 }}>
              <PlusCircle size={18} />
            </ListItemIcon>
            <ListItemText primary="Add inflow" />
          </MenuItem>
          <MenuItem onClick={() => handleAddMenu('out')}>
            <ListItemIcon style={{ minWidth: 34 }}>
              <MinusCircle size={18} />
            </ListItemIcon>
            <ListItemText primary="Add outflow" />
          </MenuItem>
          {!mobile && (
            <MenuItem onClick={importSheet}>
              <Grid container justify="space-between" alignItems="center">
                <Typography>Import spreadsheet</Typography>
                <Tooltip title="Download spreadsheet example">
                  <IconButton
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open('https://firebasestorage.googleapis.com/v0/b/ezvent-vou.appspot.com/o/template-imports%2FModelo%20Entradas%20e%20Sa%C3%ADdas.xlsx?alt=media&token=fbe6bcc7-5d01-424c-9944-cc50637c4af6');
                    }}
                  >
                    <Download size={16} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </MenuItem>
          )}
        </Menu>

        {tabVisualization === 'list' && (
          <>
            {mobile ? (
              <div className={classes.tabMobile}>
                {tabs.map((tab, key) => {
                  const match = tabTable === tab.id;
                  if (match) matchTab = true;

                  return (
                    <Button
                      key={tab.id}
                      variant={match ? 'contained' : 'outlined'}
                      color={match ? 'primary' : 'secondary'}
                      onClick={!match ? () => setTabTable(tab.id) : null}
                      style={key === 0
                        ? {
                          flex: 1,
                          borderRadius: '16px 0 0 16px',
                          background: match ? '#5c5aa7' : 'white',
                        }
                        : {
                          flex: 1,
                          borderRadius: '0 16px 16px 0',
                          background: match ? '#5c5aa7' : 'white',
                        }}
                    >
                      {tab.name}
                    </Button>
                  );
                })}
              </div>
            ) : (
              <div className={classes.tabs}>
                {tabs.map((tab, i) => {
                  const index = matchTab ? tabs.length - i : i;
                  const match = tabTable === tab.id;
                  if (match) matchTab = true;

                  return (
                    <button
                      key={tab.id}
                      type="button"
                      className={match ? classes.tabSelected : classes.tab}
                      style={{
                        zIndex: match ? tabs.length : index,
                        padding: !match && (!matchTab ? '16px 70px 16px 32px' : '16px 32px 16px 70px'),
                        marginLeft: !match && (matchTab ? '-48px' : 0),
                        marginRight: !match && (!matchTab ? '-48px' : 0),
                      }}
                      onClick={!match ? () => setTabTable(tab.id) : null}
                    >
                      <Typography className={classes.tabTitle}>{tab.name}</Typography>
                    </button>
                  );
                })}
              </div>
            )}
          </>
        )}


        {tabVisualization === 'calendar' && (
          <div className={classes.tabs}>
            <div
              className={classes.tabSelected}
              style={{ zIndex: 2 }}
            >
              <Typography className={classes.tabTitle}>CALENDAR</Typography>
            </div>
          </div>
        )}

        {/* {!mobile && tabTable !== 'calendar' ? (
          <Tooltip title="Ver no calendário">
            <Fab
              color="primary"
              className={classes.rightFab}
              onClick={() => setTabTable('calendar')}
            >
              <Calendar />
            </Fab>
          </Tooltip>
        ) : !mobile && (
          <Tooltip title="Ver em lista">
            <Fab
              color="primary"
              className={classes.rightFab}
              onClick={() => setTabTable('accounts')}
            >
              <List />
            </Fab>
          </Tooltip>
        )} */}

        {!mobile && (
          <div className={classes.tabs} style={{ right: 0, position: 'absolute' }}>
            <div
              className={`${classes.tab} ${classes.tabWHover}`}
              style={{
                zIndex: 0,
                padding: '16px 70px 16px 32px',
                marginRight: '-48px',
                marginLeft: 0,
                cursor: 'inherit',
              }}
            >
              <Typography className={classes.tabTitle}>Sort by</Typography>
            </div>
            {tabsVisualization.map((tab, i) => {
              const index = matchTab ? tabsVisualization.length - i : i;
              const match = tabVisualization === tab.id;
              if (match) matchTab = true;

              let padding = '16px 18px';
              if (!match) {
                if (!matchTab) padding = '16px 60px 16px 18px';
                else padding = '16px 18px 16px 60px';
              }

              return (
                <Tooltip title={tab.tooltip} key={tab.id}>
                  <button
                    type="button"
                    className={match ? classes.tabSelected : classes.tab}
                    style={{
                      zIndex: match ? tabsVisualization.length : index,
                      padding,
                      marginLeft: !match && (matchTab ? '-48px' : 0),
                      marginRight: !match && (!matchTab ? '-48px' : 0),
                    }}
                    onClick={!match ? () => setTabVisualization(tab.id) : null}
                  >
                    <Typography className={classes.tabTitle} style={{ lineHeight: 1 }}>
                      {tab.name}
                    </Typography>
                  </button>
                </Tooltip>
              );
            })}
          </div>
        )}
      </Grid>

      {tabVisualization === 'list' && (
        <>
          {tabTable === 'accounts' && <PayablesReceivablesTable />}
          {tabTable === 'cash-flow' && <CashFlowTable />}
        </>
      )}
      {tabVisualization === 'calendar' && <CashFlowCalendar />}
    </Grid>
  );
}

export default TabsTable;
