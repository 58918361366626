import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons, Commons, Components and Styles
import { AlertTriangle } from 'react-feather';
import { Suppliers, Financial, Partners } from 'assets/custom-icons/modules';
import Toolbar from 'common/toolbar';
import Tools from 'app/tools';
import Revenue from './components/revenue';
import NegotiationTime from './components/negotiation-time';
import Convertion from './components/convertion';
import QuotaChart from './components/quota-chart';
import QuotaTable from './components/quota-table';
import styles from './style';

function Productivity() {
  const classes = styles();
  const history = useHistory();
  const initialHideSpoiler = window.localStorage.getItem('spoiler-partners');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { eventId } = useParams();

  const { current: company } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const [hideSpoiler, setHideSpoiler] = useState(initialHideSpoiler);

  const permissionView = company.plan !== 'free' && company.plan !== 'planner' && (
    !permissions || !permissions.manager || permissions.manager.sponsors.view
  );

  useEffect(() => {
    if (!permissionView) history.goBack();
  }, [history, permissionView]);

  const hideSpoilerAlert = () => {
    window.localStorage.setItem('spoiler-partners', 'hide');
    setHideSpoiler('hide');
  };
  
  const openFeedback = () => {
    document.getElementById('help-center-button').click();
  };

  const tabsToolbar = [
    { title: 'Dashboard', selected: true },
    { title: 'Negotiations', path: `/manager/${eventId}/sponsors/negotiations` },
  ];

  return (
    <div
      className={classes.container}
      style={(!hideSpoiler || hideSpoiler !== 'hide') ? { overflow: 'hidden' } : {}}
    >
      <Toolbar
        avatar
        defaultIcons
        title="SPONSORSHIPS"
        position="fixed"
        sidebar="/manager/sponsors"
        tabs={tabsToolbar}
      />

      {mobile ? (
        <Grid container direction="column" alignItems="center" justify="center" style={{ height: '100vh', padding: 32 }}>
          <AlertTriangle size={100} style={{ color: '#f59658' }} />
          <Typography style={{ fontSize: 22, textAlign: 'center', margin: '16px 0', fontWeight: 600 }}>
            Sponsorships are not available on mobile
          </Typography>
          <Typography style={{ color: '#777', textAlign: 'center' }}>
          This module is being developed and we have a spoiler for you.
          To view access through your computer..
          </Typography>
        </Grid>
      ) : (
        <>
          {(!hideSpoiler || hideSpoiler !== 'hide') && (
            <Grid container justify="center" alignItems="center" className={classes.message}>
              <Grid container direction="column" alignItems="center" className={classes.messageContent}>
                <AlertTriangle size={150} color="#dc1f26" />
                <Typography className={classes.title}>SPOILER ALERT</Typography>
                <Typography className={classes.description}>
                  There's something new in the works! A brand new feature is being created to meet your end to end 
                  event needs. So take a sneak peek
                  {' '}
                  <span role="img" aria-label="emoji">🙈</span>
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={hideSpoilerAlert}
                >
                  I WANT SPOILERS
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.row} style={{ padding: '96px 48px 32px' }}>
            <Grid container item xs={12} sm={4} alignItems="center">
              <Revenue />
            </Grid>
            <Grid container item xs={12} sm={4} alignItems="center">
              <NegotiationTime />
            </Grid>
            <Grid container item xs={12} sm={4} alignItems="center">
              <Convertion />
            </Grid>
          </Grid>

          <Grid container justify="center" className={classes.row} style={{ padding: '32px 0' }}>
            <QuotaChart />
            <Grid item xs={12} sm={6} style={{ padding: '0 32px' }}>
              <QuotaTable />
            </Grid>
          </Grid>
    
          <Grid container alignItems="center" justify="center" className={classes.feedback}>
            <span>This module is under development</span>
            <Button variant="contained" color="primary" onClick={openFeedback}>
            send feedback
            </Button>
          </Grid>
        </>
      )}


      <Tools />
    </div>
  );
}

export default Productivity;
