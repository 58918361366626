/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import matchHighlight from 'autosuggest-highlight/match';
import parseHighlight from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

// Controllers
import { Actions as Form } from 'store/form/reducer';

// Styles
import styles from './style';

function AutosuggestInput({
  id,
  state,
  list,
  keyList,
  value,
  match,
  onChange,
  placeholder,
  label,
  disabled,
  addMore,
  helperText,
  error,
  renderItem,
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSuggestions = (e) => {
    let suggestionsLoaded = [];

    if (e) {
      const val = deburr(e.value.trim()).toLowerCase();
      const len = val.length;
      let count = 0;
      
      if (len > 0) {
        suggestionsLoaded = list ? list.filter((item) => {
          let keep = false;
          
          if (match === 'anyPosition') {
            const index = deburr(item[keyList]).toLowerCase().indexOf(val);
            keep = count < 5 && index >= 0;
          } else {
            keep = count < 5 && deburr(item[keyList].slice(0, len)).toLowerCase() === val;
          }

          if (keep) count += 1;
          return keep;
        }) : [];
      }
    }

    if (addMore) suggestionsLoaded.unshift({ id: 'autosuggestAddMore', [keyList]: addMore.label });

    setSuggestions(suggestionsLoaded);
  };

  const renderSuggestion = renderItem || ((item, { query, isHighlighted }) => {
    const matches = matchHighlight(item[keyList], query);
    const parts = parseHighlight(item[keyList], matches);

    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        className={item.id && item.id === 'autosuggestAddMore' ? classes.add : ''}
      >
        <div>
          {parts.map((part, index) => (
            <span key={String(index)} style={{ fontWeight: part.highlight ? 500 : 300 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  });

  return (
    <>
      <Autosuggest
        renderInputComponent={(inputProps) => {
          const { inputRef = () => {}, ref, ...other } = inputProps;
          return (
            <TextField
              variant="outlined"
              error={!!error}
              InputProps={{
                inputRef: (node) => {
                  ref(node);
                  inputRef(node);
                },
                endAdornment: !list && !disabled && (
                  <CircularProgress size={24} color="primary" />
                ),
              }}
              fullWidth
              {...other}
            />
          );
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestions}
        onSuggestionsClearRequested={handleSuggestions}
        getSuggestionValue={(item) => item}
        renderSuggestion={renderSuggestion}
        inputProps={{
          label,
          placeholder,
          disabled,
          value: value[keyList] || value,
          onChange: (e, { newValue }) => {
            if (newValue.id && newValue.id === 'autosuggestAddMore') {
              dispatch(Form.save({ id, state }));
              addMore.onClick();
            } else {
              onChange(newValue);
            }
          },
          inputRef: (node) => setAnchorEl(node),
        }}
        theme={classes}
        renderSuggestionsContainer={(options) => (
          <Popper
            placement="bottom-end"
            style={{ zIndex: 1400 }}
            anchorEl={anchorEl}
            open={Boolean(options.children)}
          >
            <Paper
              square
              {...options.containerProps}
              style={{
                width: anchorEl ? anchorEl.clientWidth : undefined,
                maxHeight: 150,
                overflow: 'auto',
              }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
      {((error && typeof error === 'string') || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

AutosuggestInput.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  keyList: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  match: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  renderItem: PropTypes.func,
  addMore: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
};

AutosuggestInput.defaultProps = {
  value: '',
  error: '',
  helperText: '',
  placeholder: '',
  match: 'initial',
  label: '',
  disabled: false,
  renderItem: null,
  addMore: null,
};

export default AutosuggestInput;
