import React from 'react';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

function FormConfig({
  startAt, setStartAt, classes, users, labels, checklist, taskId, disabled,
}) {
  return [
    {
      name: 'label',
      type: 'text',
      label: 'Checklist description',
      validation: {
        required: 'Describe the checklist',
        unique: {
          msg: 'A checklist with this description already exists',
          params: {
            list: checklist
              ? checklist.filter((i) => i.id !== taskId).map((i) => i.label)
              : [],
          },
        },
      },
    },
    {
      name: 'startAt',
      type: 'datetime',
      keyboard: true,
      label: 'Start of the checklist',
      disabled,
      onChange: (value, state) => {
        setStartAt(value);
        return state;
      },
      validation: !disabled && { required: 'Set the checklist start date' },
    },
    {
      name: 'endAt',
      type: 'datetime',
      keyboard: true,
      label: 'Delivery of the checklist',
      disabled,
      pickerProps: { min: startAt || new Date('1900-01-01') },
      validation: !disabled && {
        required: 'Set the checklist delivery date',
        minDate: {
          msg: 'The delivery date must be greater than the start date',
          params: { min: startAt },
        },
      },
    },
    {
      name: 'tag',
      type: 'select',
      label: 'Category',
      options: labels
        ? labels.filter((u) => !u.deletedAt).map((tag) => ({
          value: tag.id,
          label: (
            <Grid container>
              <div className={classes.categoryColor} style={{ background: tag.color }} />
              <div>{tag.name}</div>
            </Grid>
          ),
        }))
        : [],
      validation: { required: 'Select which category this checklist belongs to' }
    },
    {
      name: 'responsible',
      type: 'select',
      label: 'Responsible',
      options: users
        ? users.filter((u) => !u.deletedAt).map((user) => ({
          value: user.id,
          label: (
            <Grid container wrap="nowrap" alignItems="center" justify="space-between">
              <ListItemAvatar>
                <Avatar alt={user.name} src={user.avatar} className={classes.avatar}>
                  {user.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText className={classes.responsibleName} primary={user.name} />
            </Grid>
          ),
        }))
        : [],
    },
  ];
}

export default FormConfig;
