import { makeStyles, fade, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    margin: 20,
  },
  dialog: {
    paddingRight: '0px !important',
  },
  dialogContent: {
    padding: theme.spacing(1.5),
  },
  categoryContainer: {
    position: 'relative',
    float: 'left',
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.75),
    paddingLeft: 10,
    paddingRight: 20,
  },
  categoryColor: {
    position: 'absolute',
    width: 4,
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 4,
  },
  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  categoryName: {
    fontSize: 9,
    lineHeight: 1,
  },
  categoryProduct: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1,
  },
  categoryAdd: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    marginTop: 6,
  },
  productButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    margin: '0 8px',
  },
  supplierName: {
    fontWeight: 600,
  },
  supplierCNPJ: {
    fontWeight: 300,
    marginTop: theme.spacing(0.5),
    color: theme.palette.common.grey,
  },
  emptyNote: {
    color: theme.palette.common.grey,
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
    fontSize: 10,
    lineHeight: 1,
  },
  prevButton: {
    margin: `${theme.spacing()}px -24px -24px`,
    width: 'calc(100% + 48px)',
    borderRadius: 0,
    padding: theme.spacing(),
  },
  btnCancel: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.08),
    },
  },
  btnConfirm: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.08),
    },
  },
  // Products Menu
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
}));
