import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Commons and Styles
import styles from './style';

function PaymentMethods({ edit, payment, onChange }) {
  const classes = styles();

  const paymentMethods = {
    '': 'Not defined',
    billet: 'Bill',
    transference: 'Bank transfer',
    card: 'Credit / Debit Card',
    cash: 'Cash',
  };

  return (
    <Grid
      item
      xs={12}
      sm={payment.type !== 'cash' || (payment.discount.value === 0 && !edit) ? 6 : 12}
    >
      {edit ? (
        <TextField
          select
          fullWidth
          variant="outlined"
          label="Payment method"
          value={payment.method}
          onChange={(e) => onChange({ ...payment, method: e.target.value })}
          InputProps={{ inputProps: { className: classes.field } }}
        >
          <MenuItem value="billet">Bill</MenuItem>
          <MenuItem value="transference">Bank transfer</MenuItem>
          <MenuItem value="card">Credit / Debit Card</MenuItem>
          <MenuItem value="cash">Cash</MenuItem>
        </TextField>
      ) : (
        <>
          <Typography variant="overline" className={classes.infoTitle}>
            Payment method
          </Typography>
          <Typography className={classes.infoText}>{paymentMethods[payment.method]}</Typography>
        </>
      )}
    </Grid>
  );
}

PaymentMethods.propTypes = {
  edit: PropTypes.bool.isRequired,
  payment: PropTypes.shape({
    type: PropTypes.string,
    method: PropTypes.string,
    exchange: PropTypes.object,
    discount: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
};

PaymentMethods.defaultProps = {
  payment: null,
};

export default PaymentMethods;
