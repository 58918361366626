import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  checked: {
    color: theme.palette.common.grey,
    textDecoration: 'line-through',
  },
  btnOrder: {
    padding: 4,
    minWidth: 0,
    width: 24,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
      background: 'none',
    },
  },
}));
