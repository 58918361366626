import React, { useState } from 'react';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Icons, Commons, Components and Styles
import { Medal } from 'assets/custom-icons';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import Chart from './chart';
import styles from './style';

function Budget() {
  const classes = styles();

  const [data] = useState({
    total: 290000, revenue: 205000, revenueExpected: 85000, revenueGoal: 235000,
  });
  const [emphasis, setEmphasis] = useState('total');
  const [percent, setPercent] = useState(55);
  const [hitTarget] = useState(false);

  const handleEmphasis = (e) => {
    if (!e) {
      setPercent(Math.round((data.total / (data.total + data.revenueGoal)) * 100));
      setEmphasis('total');
    } else {
      setPercent((Math.round(e.percent)));
      setEmphasis(e.data.id);
    }
  };

  const label = {
    total: 'TOTAL COLLECTED',
    revenue: 'CONFIRMED COLLECTION',
    revenueExpected: 'ESTIMATED COLLECTION',
    revenueGoal: 'UNALLOCATED COLLECTION',
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <div style={{ position: 'relative', width: 128, height: 128 }}>
          <Chart data={data} handleEmphasis={handleEmphasis} />
          <div className={classes.chartLabel} style={{ color: '#4bb75c' }}>
            {`${percent}%`}
          </div>
        </div>
      </Grid>
      <Grid item style={{ marginLeft: 16 }}>
        <Grid container justify="space-between" alignItems="center">
          <Typography className={classes.title}>{label[emphasis]}</Typography>
          {hitTarget && (
          <Tooltip title="Goal accomplished">
            <Medal style={{ width: 24, height: 24 }} />
          </Tooltip>
          )}
        </Grid>
        <Typography className={classes.value} style={{ color: '#348040' }}>
          {numberToReal(data[emphasis])}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Budget;
