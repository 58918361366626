import { makeStyles, darken, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  label: {
    width: '100%',
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
  },
  accountMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: 35,
    lineHeight: 1.3,
    padding: '11px 24px 11px 14px',
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 12,
    },
  },
  categoryMenu: {
    maxHeight: 400,
  },
  categoryColor: {
    width: 6,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  categoryLabel: {
    lineHeight: 1,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 10,
      textTransform: 'uppercase',
    },
    '& p': {
      marginTop: 4,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  description: {
    color: theme.palette.common.grey,
    fontSize: 11,
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: `${lighten(theme.palette.secondary.light, 0.45)} !important`,
    },
  },
  buttonInSelected: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    padding: theme.spacing(0.5),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.1),
    },
  },
  buttonIn: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    border: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 40,
    backgroundColor: lighten(theme.palette.success.main, 0.8),
    '&:hover': {
      backgroundColor: lighten(theme.palette.success.main, 0.7),
    },
  },
  buttonOutSelected: {
    backgroundColor: theme.palette.error.main,
    borderWidth: 1,
    color: 'white',
    padding: theme.spacing(0.5),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
  buttonOut: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    border: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 40,
    backgroundColor: lighten(theme.palette.error.main, 0.8),
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.7),
    },
  },
  textarea: {
    '& textarea': {
      lineHeight: 1.5,
    },
  },
  iconDate: {
    color: theme.palette.common.grey,
  },
  expand: {
    boxShadow: 'none',
    '& > div': {
      padding: 0,
    },
  },
  expandSummary: {
    alignItems: 'center',
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    margin: `${theme.spacing(0.5)}px 0 !important`,
    '& p': {
      fontWeight: 600,
    },
  },
  expandDetail: {
    padding: `${theme.spacing(0.25)}px 0 ${theme.spacing(0.5)}px 0`,
    paddingRight: 0,
  },
  dividerText: {
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  uploadFile: {
    background: lighten(theme.palette.secondary.main, 0.9),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  uploadFileConfirm: {
    background: lighten(theme.palette.success.main, 0.9),
    border: `1px solid ${theme.palette.success.main}`,
  },
  uploadName: {

  },
  checkedFile: {
    color: theme.palette.success.main,
  },
  buttonUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonDel: {
    margin: theme.spacing(1.25),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
      borderColor: theme.palette.error.main,
    },
  },
}));
