import React from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

// Commons
import { currencyInput, revertCurrencyInput } from 'common/utils/numbers';

function InputCurrency({
  name, label, value, error, helperText, onChange, disabled, className,
}) {
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={!!error}
        name={name}
        label={label || undefined}
        disabled={disabled}
        value={currencyInput(value)}
        onChange={(e) => {
          onChange(revertCurrencyInput(e.target.value));
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputProps: { className },
        }}
      />
      {((error && typeof error === 'string') || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

InputCurrency.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

InputCurrency.defaultProps = {
  label: null,
  className: null,
  error: false,
  helperText: '',
  disabled: false,
};

export default InputCurrency;
