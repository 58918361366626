import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  moreContent: {
    margin: `${theme.spacing()}px 0 0`,
    flexWrap: 'nowrap',
  },
  moreInfoTitle: {
    color: theme.palette.primary.main,
    fontSize: 10,
    lineHeight: 'normal',
  },
  moreInfoTitleError: {
    color: theme.palette.error.main,
    fontSize: 12,
    lineHeight: 'normal',
  },
  moreInfoIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  moreInfoIconError: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.5),
  },
  moreInfo: {
    color: theme.palette.common.black,
    fontSize: 15,
    fontWeight: 600,
  },
  moreInfoSuccess: {
    color: theme.palette.success.main,
    fontSize: 15,
    fontWeight: 600,
  },
  moreInfoWarning: {
    color: theme.palette.warning.main,
    fontSize: 15,
    fontWeight: 600,
  },
  moreInfoError: {
    color: theme.palette.error.main,
    fontSize: 15,
    fontWeight: 600,
  },
}));
