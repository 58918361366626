import React from 'react';
import PropTypes from 'prop-types';

// Material components
import Avatar from '@material-ui/core/Avatar';

// Commons and Styles
import Tooltip from 'common/tooltip';
import styles from '../style';

function TableResponsible({ responsibles }) {
  const classes = styles();

  let newResponsibles = [];

  if (responsibles) {
    if (responsibles.length > 2) {
      newResponsibles.push(responsibles[0]);

      const joinResponsible = {
        id: 'join-responsible',
        name: [],
        initials: `+${responsibles.length - 1}`,
      };

      responsibles.forEach((responsible, key) => {
        if (key > 0) {
          joinResponsible.name.push(
            <React.Fragment key={responsible.name}>
              {responsible.name}
              <br />
            </React.Fragment>,
          );
        }
      });

      newResponsibles.push(joinResponsible);
    } else newResponsibles = responsibles;
  }

  return newResponsibles.map((responsible) => (
    <Tooltip title={responsible.name} key={responsible.id}>
      <Avatar alt={responsible.name} src={responsible.avatar} className={classes.avatar}>
        {responsible.initials}
      </Avatar>
    </Tooltip>
  ));
}

TableResponsible.propTypes = {
  responsibles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableResponsible;
