import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  titleSecond: {
    fontSize: 16,
    color: theme.palette.common.grey,
    fontWeight: 300,
    paddingLeft: theme.spacing(0.5),
  },
  expansion: {
    boxShadow: 'none',
    width: '100%',
    '&:before': { content: 'none' },
  },
  expansionExpanded: {
    borderBottom: '0.5px solid #ddd',
    borderTop: '0.5px solid #ddd',
  },
  contactName: {
    fontSize: 16,
    fontWeight: 600,
  },
  contactTypes: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.primary.main,
    display: 'inline-flex',
  },
  contactDoc: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.common.grey,
    display: 'inline-flex',
  },
  divider: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(),
  },
  label: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  noData: {
    color: theme.palette.common.grey,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));
