// Main Components
import ActionPlan from 'app/planner/action-plan';

import CheckTaskForm from 'app/planner/action-plan/children/checklist';
import TaskForm from 'app/planner/action-plan/children/task';

import SettingsView from 'app/planner/action-plan/children/settings';
import LabelsForm from 'app/planner/action-plan/children/label';

// Routes Components
import PrivateRoute from '../../components/PrivateRoute';


export default [
  {
    path: '/planner/:eventId/action-plan',
    component: ActionPlan,
    routerComponent: PrivateRoute,
    childrenRoutes: [
      {
        path: '/planner/:eventId/action-plan/tasks/new',
        component: CheckTaskForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/planner/:eventId/action-plan/tasks/:taskId',
        component: CheckTaskForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/planner/:eventId/action-plan/tasks/:checkId/subtasks/new',
        component: TaskForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: '/planner/:eventId/action-plan/tasks/:checkId/subtasks/:taskId',
        component: TaskForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: `/planner/:eventId/action-plan/settings`,
        component: SettingsView,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: `/planner/:eventId/action-plan/labels/new`,
        component: LabelsForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
      {
        path: `/planner/:eventId/action-plan/labels/:labelId`,
        component: LabelsForm,
        routerComponent: PrivateRoute,
        exact: true,
      },
    ],
  },
];
