import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  menu: {
    maxHeight: 350,
    width: 300,
  },
  categoryColor: {
    width: 6,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  category: {
    lineHeight: 1,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 10,
      textTransform: 'uppercase',
    },
    '& p': {
      marginTop: 4,
    },
  },
  categoryLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    background: 'white',
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  avatar: {
    background: theme.palette.primary.main,
    width: 32,
    height: 32,
    fontSize: 16,
    lineHeight: 16,
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
}));
