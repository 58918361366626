import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { storage } from 'services/firebase';

// MaterialUI's Components
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// Controllers
import { Actions as Alert } from 'store/alert/reducer';

// Icons and Styles
import { Upload } from 'react-feather';

function ButtonUpload({
  buttonProps, fileProps, documents, onChange,
}) {
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const handleButton = () => {
    inputFile.current.click();
  };

  const handleDocs = (docs) => {
    const newDocuments = [...documents];
    if (fileProps.index >= 0) [newDocuments[fileProps.index]] = docs;
    else newDocuments.push(...docs);
    onChange(newDocuments);
  };

  const handleFile = async (e) => {
    const { files } = e.target;

    let docs = [];
    for (let i = 0; i < files.length; i += 1) {
      const names = files[i].name.split('.');
      names.splice(-1);

      let label = names.join('.');
      let name = [...Array(5)].map(() => (Math.random() * 36).toString(36)).join('');
      const isDefault = fileProps.index >= 0 ? documents[fileProps.index].default : false;

      if (fileProps.index >= 0) {
        name = documents[fileProps.index].name;
        label = documents[fileProps.index].label;
      }

      docs.push({
        name, label, uploading: true, uploaded: false, default: isDefault,
      });
    }

    handleDocs(docs);

    for (let i = 0; i < files.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await storage.ref(`${fileProps.path}/${docs[i].name}`).put(files[i]);
      } catch (error) {
        dispatch(Alert.show({ message: 'The file could not be added. Try again.' }));
      }
    }

    docs = docs.map((d) => ({ ...d, uploading: false, uploaded: true }));
    handleDocs(docs);
  };

  let ButtonComponent = IconButton;
  if (buttonProps.label) ButtonComponent = Button;

  return (
    <>
      <ButtonComponent
        color="primary"
        variant="outlined"
        onClick={handleButton}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      >
        {buttonProps.icon || <Upload size={20} />}
        {buttonProps.label && (
          <span style={{ marginLeft: 8 }}>{buttonProps.label}</span>
        )}
      </ButtonComponent>

      <input
        multiple={!fileProps.index && fileProps.multiple}
        accept={fileProps.accept}
        type="file"
        ref={inputFile}
        onChange={handleFile}
        style={{ display: 'none' }}
      />
    </>
  );
}

ButtonUpload.propTypes = {
  fileProps: PropTypes.shape({
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    path: PropTypes.string,
    name: PropTypes.string,
    index: PropTypes.number,
  }),
  buttonProps: PropTypes.shape({
    icon: PropTypes.element,
    label: PropTypes.string,
    hoverButton: PropTypes.bool,
  }),
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

ButtonUpload.defaultProps = {
  buttonProps: {},
  fileProps: {
    multiple: false,
    accept: '*',
    path: '',
    name: null,
    index: null,
  },
};

export default ButtonUpload;
