import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router';
import Loading from 'common/loading';
import { renderRoutes } from 'routes/utils';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { defaultTheme, idealize, plan, manage } from 'app/theme';

// Controllers
import Auth from 'store/auth/controller';
import Company from 'store/company/controller';
import useEventController from 'store/event/controller';
import User from 'store/user/controller';

function PrivateRoute({ component: Component, path, exact, childrenRoutes }) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Controller
  const Event = useEventController(dispatch);

  const { isAuth, isLoaded: isLoadedAuth } = useSelector((state) => state.auth);
  const { current: user } = useSelector((state) => state.user);
  const { current: event, loading } = useSelector((state) => state.event);
  const { current: company, users, events } = useSelector((state) => state.company);

  const checkAuth = () => {
    if (!isLoadedAuth && !user) dispatch(Auth.check());
  };

  const load = useCallback(() => {
    const isNewCompany = history.location.pathname.indexOf('/company/new') >= 0;
    if (user.company) {
      if (isNewCompany) history.replace('/team');

      if (!company) dispatch(Company.show(user.company));
      if (!events) Event.index(user.company, user.permissions);
      if (!users) dispatch(User.index(user.company));
    } else if (!isNewCompany) {
      history.push('/company/new');
    }
  }, [history, user, company, dispatch, events, Event, users]);

  useEffect(() => {
    checkAuth(path);
  });

  useEffect(() => {
    if (user) load();
  }, [user, load]);

  const isNewCompany = history.location.pathname.indexOf('/company/new') >= 0;
  const isLoaded = (!!company && !!users && !!events) || (isLoadedAuth && !isAuth) || isNewCompany;

  const currentPath = history.location.pathname;

  return (
    <>
      <Loading ready={isLoaded} />
      {isLoaded && (
        <Route
          path={path}
          exact={exact}
          render={(props) => {
            const { eventId } = props.match.params;
            const { pathname } = props.location;
            const path = pathname.split('/');

            if (eventId && !loading && !event) Event.show(eventId);
           
            if (isAuth) {
              let theme = defaultTheme;
              if (path[1] === 'create') theme = idealize;
              if (path[1] === 'planner') theme = plan;
              if (path[1] === 'manager') theme = manage;
              return (
                <MuiThemeProvider theme={theme}>
                  <Component />
                </MuiThemeProvider>
              )
            }

            return (
              <Redirect
                to={`/auth/sign-in${currentPath !== '/' ? `?redirectUrl=${currentPath}` : ''}`}
              />
            );
          }}
        />
      )}
      {isLoaded && <Switch>{renderRoutes(childrenRoutes)}</Switch>}
    </>
  );
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  childrenRoutes: PropTypes.arrayOf(PropTypes.object),
};

PrivateRoute.defaultProps = {
  exact: false,
  childrenRoutes: [],
};

export default PrivateRoute;
