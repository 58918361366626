import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Commons and Styles
import {
  Edit2, Check, X, ChevronDown,
} from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import { Typography } from '@material-ui/core';
import styles from './style';

function MobileTable({ data }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const isFiltered = location.search !== '';
  const { eventId } = useParams();

  const { contacts, cashFlow, suppliers } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);
  const [expanded, setExpanded] = useState([]);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  const expandPanel = (panel, exp) => {
    setExpanded(exp ? panel : false);
  };

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  const confirmPayment = (account) => {
    dispatch(Log.store(`Confirmed an ${account.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(account.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === account.id) match = true;
        });
      }
      return match;
    });

    if (supplier) {
      const lastCash = supplier.payment.installments.filter((i) => {
        const cf = cashFlow.find((c) => c.id === i.cashFlow);
        return cf && !cf.completed.on;
      }).length === 1;
      if (lastCash) dispatch(Supplier.update(supplier.id, { status: 4 }));
    }

    dispatch(CashFlow.update(account.id, {
      completed: { ...account.completed, status: null, on: true },
    }));
  };

  const openPayment = (account) => {
    dispatch(Log.store(`Reopened an ${account.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(account.value)
    }`));
    dispatch(CashFlow.update(account.id, {
      completed: { ...account.completed, status: null, on: false },
    }));
  };

  // const accountsSelectedTypes = payableReceivables.filter(
  //   (p) => p.type === selectedType && !p.completed.status,
  // );

  return (
    <>
      {data.map((account) => {
        // const isSelected = selected.indexOf(account.id) >= 0;
        const contact = contacts.find((c) => c.id === account.contact);

        let status = false;

        if (account.completed.status === 'WAITING_RECEIVEMENT') {
          status = 'Waiting payment';
        }
        if (account.completed.status === 'RECEIVED') {
          status = 'Making payment';
        }
        if (account.completed.status === 'WAITING_CONFIRMATION') {
          status = (
            <Grid container wrap="nowrap" alignItems="center" justify="center">
              <Typography
                style={{
                  fontSize: 10, textAlign: 'center', lineHeight: 1.2,
                }}
              >
                Waiting supplier confirmation
              </Typography>
              <Tooltip title="Supplier confirmed">
                <Button
                  variant="outlined"
                  className={classes.buttonGreen}
                  disabled={!suppliers}
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmPayment(account);
                  }}
                >
                  <Check size={16} />
                </Button>
              </Tooltip>
              <Tooltip title="Reopen parcel">
                <Button
                  variant="outlined"
                  className={classes.buttonRed}
                  disabled={!suppliers}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPayment(account);
                  }}
                >
                  <X size={16} />
                </Button>
              </Tooltip>
            </Grid>
          );
        }

        return (
          <ExpansionPanel
            className={classes.expasionPanel}
            expanded={expanded === account.id}
            key={account.id}
            onChange={(e, exp) => expandPanel(account.id, exp)}
          >
            <ExpansionPanelSummary expandIcon={<ChevronDown />} style={{ padding: '0 16px' }}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {account.type === 'in' ? (
                    <Typography className={classes.typeReceive}>Ⓔ</Typography>
                  ) : (
                    <Typography className={classes.typePay}>Ⓢ</Typography>
                  )}

                  {account.categories.map((category) => category && (
                    <div className={classes.category} key={category.id}>
                      <Tooltip title={category.name} onClick={(e) => e.stopPropagation()}>
                        <div
                          className={classes.categoryColors}
                          style={{ background: category.color }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </Grid>

                <Grid item style={{ marginLeft: 8, fontWeight: 600 }}>
                  {account.installments && expanded === account.id ? (
                    <Badge
                      color="primary"
                      className={classes.badge}
                      badgeContent={`${
                        account.installments.indexOf(null) + 1
                      }/${account.installments.length}`}
                    >
                      {numberToReal(account.value)}
                    </Badge>
                  ) : numberToReal(account.value)}
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>
                    {account.type === 'in' ? 'Payer' : 'Payee'}
                  </Typography>
                  <Typography className={classes.value}>
                    {contact ? contact.name : 'Not defined'}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ color: '#777', fontSize: 14, paddingTop: 0 }}>
                  {account.description}
                </Grid>

                <Grid item xs={6}>
                  <Typography className={classes.label}>Due date</Typography>
                  <Typography className={classes.value}>
                    {account.dueAt.toDate() <= new Date() ? (
                      <Tooltip
                        title={`${account.type === 'in' ? 'Receivement' : 'Payment'} late`}
                      >
                        <span className={classes.colorError}>
                          {format(account.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                        </span>
                      </Tooltip>
                    ) : (
                      format(account.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 36 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/manager/${eventId}/financial/cash-flow/${account.id}`);
                    }}
                  >
                    See more
                  </Button>
                </Grid>

                {permissionEdit && (
                  <>
                    <Grid item container justify="space-between">
                      {!status ? (
                        <Button
                          variant="outlined"
                          className={account.type === 'in' ? classes.buttonReceive : classes.buttonPay}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(
                              `/manager/${eventId}/financial/${
                                account.type === 'in' ? 'receive' : 'pay'
                              }/${account.id}`,
                            );
                          }}
                        >
                          {account.type === 'in' ? 'Receive' : 'Pay'}
                        </Button>
                      ) : (
                        <Typography style={{ lineHeight: 1.5 }} variant="overline">
                          {status}
                        </Typography>
                      )}

                      <IconButton
                        color="primary"
                        disabled={!!account.completed.status}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/manager/${eventId}/financial/cash-flow/${account.id}/edit`);
                        }}
                      >
                        <Edit2 size={16} />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}

      {data.length === 0 && (
        <Typography variant="h5" className={classes.noData}>
          {!isFiltered
            ? 'Start registering your accounts payable and receivable'
            : 'No pending accounts found with this filter'}
        </Typography>
      )}
    </>
  );
}

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MobileTable;
