import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Commons, Components and styles
import { numberToReal } from 'common/utils/numbers';
import Loading from 'common/loading';
import styles from './style';
import Chart from './chart';

function getOptionsCharts(color, data) {
  return {
    color,
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    series: [
      {
        type: 'pie',
        radius: ['35%', '85%'],
        label: {
          normal: {
            show: true,
            position: 'inside',
            formatter: '{d}%',
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        animationEasing: 'elasticOut',
        animationDelay: (idx) => idx * 10,
        animationDelayUpdate: (idx) => idx * 10,
        data,
      },
    ],
  };
}

function PieChart({
  data, direction, title, loaded,
}) {
  const [emphasis, setEmphasis] = useState(null);
  const classes = styles();

  let containerDirection = 'row';

  if (direction === 'right') {
    containerDirection = 'row-reverse';
  }

  if (direction === 'center') {
    containerDirection = 'column';
  }

  const colorChart = data.map((d) => d.color);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      item
      sm={12}
      md={6}
      className={classes.container}
    >
      <Typography variant="h5" className={classes.chartTitle}>
        {title.toUpperCase()}
      </Typography>
      {!loaded && <Loading ready={loaded} content={300} />}
      {loaded && (
        <Grid container direction={containerDirection} alignItems="center" justify="center">
          <Chart
            handleEmphasis={(id) => setEmphasis(id)}
            option={getOptionsCharts(colorChart, data)}
          />
          <div style={direction === 'right' ? { textAlign: 'right' } : {}}>
            {data.map((item, key) => (
              <div
                className={`
                    ${classes.chartLegend}
                    ${emphasis ? classes.chartSelected : ''}
                    ${emphasis === item.id ? classes.dataSelected : ''}
                  `}
                style={{ color: colorChart[key] }}
                key={item.id}
                id={item.id}
              >
                <div
                  className={classes.chartLegendBar}
                  style={
                    direction === 'right'
                      ? { background: colorChart[key], right: 0, left: 'inherit' }
                      : { background: colorChart[key] }
                  }
                />
                <div className={classes.chartLegendLabel}>{item.name.toUpperCase()}</div>
                <div className={classes.chartLegendValue}>{numberToReal(item.value)}</div>
              </div>
            ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  direction: PropTypes.string,
  loaded: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

PieChart.defaultProps = {
  direction: null,
  loaded: true,
};

export default PieChart;
