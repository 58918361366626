/**
 * Types
 */
export const TypesProductivity = {
  LOAD_CHECKLIST: '@event/LOAD_CHECKLIST',
};

/**
 * Actions
 */
export const ActionsProductivity = {
  loadChecklist: (checklist) => ({
    type: TypesProductivity.LOAD_CHECKLIST,
    payload: { checklist },
  }),
};

/**
 * Reducer
 */
export const INITIAL_STATE_PRODUCTIVITY = {
  checklist: null,
};

export const reducerProductivity = (state, action) => {
  switch (action.type) {
    case TypesProductivity.LOAD_CHECKLIST: {
      const { checklist } = action.payload;
      return { ...state, checklist };
    }
    default: {
      return state;
    }
  }
};
