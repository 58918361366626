import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

// Material components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import LinkMaterial from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Styles
import { ChevronDown } from 'react-feather';
import styles from '../style';

function RenderTabs({ tabs }) {
  const classes = styles();
  const history = useHistory();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuEl, setMenuEl] = useState(null);

  if (mobile) {
    const tabSelected = tabs.find((t) => t.selected);
    return (
      <>
        <div className={classes.tabsContainer}>
          <Button
            size="large"
            color="secondary"
            className={`${classes.button} ${classes.buttonDisabled}`}
            onClick={(e) => setMenuEl(e.currentTarget)}
          >
            <Grid container alignItems="center" justify="center" wrap="nowrap" style={{ overflow: 'hidden' }}>
              {tabSelected ? (
                <>
                  <tabSelected.icon size={32} style={{ width: 32, height: 32, marginRight: 16 }} />
                  <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {tabSelected.label}
                  </span>
                </>
              ) : 'Selecione'}
              <ChevronDown size={12} style={{ marginLeft: 16 }} />
            </Grid>
          </Button>

          <Menu
            open={!!menuEl}
            anchorEl={menuEl}
            onClose={() => setMenuEl(null)}
            PaperProps={{ style: { width: 'calc(100% - 128px)', marginTop: 36 } }}
          >
            {tabs.filter((t) => !t.selected).map((tab) => (
              <MenuItem key={tab.id} onClick={tab.click || (() => history.push(tab.path))}>
                <tab.icon size={32} style={{ width: 32, minWidth: 32, height: 32, marginRight: 16 }} />
                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {tab.label}
                </span>
              </MenuItem>
            ))}
          </Menu>
        </div>

        {tabSelected.subtabs && tabSelected.subtabs.length > 0 && (
          <ButtonGroup fullWidth size="small" variant="outlined" className={classes.subtabsContainer}>
            {tabSelected.subtabs.map((tab) => (
              <Button
                key={tab.id}
                color={tab.selected ? 'secondary' : 'primary'}
                className={`${classes.subbutton} ${tab.selected ? classes.subbuttonDisabled : ''}`}
                onClick={!tab.selected ? tab.click || (() => history.push(tab.path)) : null}
              >
                {tab.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </>
    )
  }

  return (
    <div className={classes.tabsContainer}>
      {tabs.map((tab, i) => (
        <Button
          key={tab.id}
          size="large"
          color="secondary"
          className={`${classes.button} ${tab.selected ? classes.buttonDisabled : ''}`}
          style={tab.selected && tab.subtabs ? { marginBottom: -4 } : {}}
          onClick={!tab.selected ? tab.click || (() => history.push(tab.path)) : null}
        >
          <Grid container alignItems="center">
            {/* <tab.icon size={32} style={{ width: 32, height: 32, marginRight: 16 }} /> */}
            {tab.label}
          </Grid>
          {tab.selected && tab.subtabs && (
            <Grid container className={classes.subtabs}>
              {tab.subtabs.map((t, i) => (
                <React.Fragment key={t.id}>
                  <LinkMaterial
                    component="button"
                    color={t.selected ? 'primary' : 'secondary'}
                    underline={t.selected ? 'none' : 'hover'}
                    style={t.selected ? { cursor: 'inherit', fontSize: 13 } : { fontSize: 13 }}
                    onClick={!t.selected ? (e) => {
                      e.stopPropagation();
                      if (t.click) t.click();
                      else history.push(t.path);
                    } : null}
                  >
                    {t.label}
                  </LinkMaterial>
                  {i + 1 < tab.subtabs.length && (
                    <span style={{ padding: '0 12px' }}>›</span>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Button>
      ))}
    </div>
  );
}

RenderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      selected: PropTypes.bool,
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default RenderTabs;
