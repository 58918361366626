import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  divider: {
    margin: '0 0 0.5rem',
    backgroundColor: theme.palette.secondary.main,
  },
  dividerText: {
    marginTop: '2rem',
    color: theme.palette.secondary.main,
  },
  dialog: {
    paddingRight: '0px !important',
  },
  iconDate: {
    color: theme.palette.common.grey,
  },
  actions: {
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  label: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  description: {
    color: theme.palette.common.grey,
    fontSize: 11,
  },
  button: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    margin: theme.spacing(),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  buttonCancel: {
    color: theme.palette.error.main,
  },
}));
