import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Material Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Icons, Commons and Styles
import { Edit2, Check } from 'react-feather';
import Loading from 'common/loading';
import Tooltip from 'common/tooltip';
import styles from './style';

function CompletedTasks() {
  const classes = styles();

  const history = useHistory();
  const { eventId } = useParams();

  const { tasks, steps, labels } = useSelector((state) => state.company);
  const { checklist } = useSelector((state) => state.event);

  const [checked, setChecked] = useState([]);
  const [unchecked, setUnchecked] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (checklist && tasks && steps) {
      const newChecked = [];
      const newUnchecked = [];

      const stepsCompleted = steps.filter((s) => s.type === 'done' || s.type === 'archive')
        .map((s) => s.id);

      checklist.forEach((item) => {
        const itemTask = tasks.filter((t) => t.parent === item.id);
        const completed = itemTask.filter((t) => stepsCompleted.indexOf(t.step) >= 0);
        if (itemTask.length === completed.length && itemTask.length > 0) {
          newChecked.push({ ...item, percent: 100 });
        } else {
          newUnchecked.push({
            ...item,
            percent: itemTask.length > 0
              ? Math.floor((completed.length / itemTask.length) * 100)
              : 0,
          });
        }
      });

      setChecked(newChecked);
      setUnchecked(newUnchecked);
      setIsLoaded(true);
    }
  }, [checklist, tasks, steps]);

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between" alignItems="center" className={classes.title}>
        <Typography>EVENT CHECKLIST</Typography>
        <IconButton color="primary" onClick={() => history.push(`/planner/${eventId}/productivity/checklist`)}>
          <Edit2 size={18} />
        </IconButton>
      </Grid>
      {isLoaded && labels ? (
        <List dense className={classes.list}>
          {labels.filter((l) => unchecked.find((u) => u.tag === l.id)).map((label) => [
             <ListItem dense key={label.id}>
              <ListItemText
                primary={label.name}
                primaryTypographyProps={{
                  style: { fontSize: 14, fontWeight: 600, textTransform: 'uppercase', color: label.color },
                }}
              />
            </ListItem>,
            unchecked.filter((u) => u.tag === label.id).map((task) => (
              <ListItem dense key={task.id} style={{ position: 'relative' }}>
                <ListItemIcon style={{ minWidth: 42 }}>
                  <Tooltip title={`${task.percent}%`} placement="left">
                    <div style={{ position: 'relative', height: 28 }}>
                      <CircularProgress
                        variant="static"
                        color="secondary"
                        value={task.percent}
                        size={28}
                        thickness={6}
                      />
                      <CircularProgress
                        variant="static"
                        color="secondary"
                        value={100}
                        size={28}
                        thickness={6}
                        style={{ position: 'absolute', opacity: 0.3, left: 0 }}
                      />
                    </div>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={task.label} />
              </ListItem>
            )),
          ])}
          
          {unchecked.length > 0 && checked.length > 0 && (
            <Divider style={{ margin: '16px 0' }} />
          )}
          {labels.filter((l) => checked.find((u) => u.tag === l.id)).map((label) => [
             <ListItem dense key={label.id}>
              <ListItemText
                primary={label.name}
                primaryTypographyProps={{
                  style: { fontSize: 14, fontWeight: 600, textTransform: 'uppercase', color: label.color },
                }}
              />
            </ListItem>,
            checked.filter((u) => u.tag === label.id).map((task) => (
              <ListItem dense key={task.id}>
                <ListItemIcon style={{ minWidth: 42 }}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{
                      position: 'relative',
                      width: 28,
                      height: 28,
                      background: '#4eb1d4',
                      borderRadius: '50%',
                    }}
                  >
                    <Check
                      size={18}
                      style={{
                        color: 'white', position: 'absolute', strokeWidth: 4, left: 6, top: 6,
                      }}
                    />
                  </Grid>
                </ListItemIcon>
                <ListItemText
                  primary={task.label}
                  primaryTypographyProps={{ className: classes.checked }}
                />
              </ListItem>
            ))
          ])}
          {unchecked.length === 0 && checked.length === 0 && (
            <Typography className={classes.noData}>No items defined yet</Typography>
          )}
        </List>
      ) : (
        <Grid container alignItems="center" style={{ height: 'calc(100% - 63px)' }}>
          <Loading content={150} ready={isLoaded && !!labels} />
        </Grid>
      )}
    </Paper>
  );
}

export default CompletedTasks;
