import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialog: {
    paddingRight: '0px !important',
  },
  categoryMenu: {
    maxHeight: 400,
  },
  categoryColor: {
    width: 6,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  categoryLabel: {
    lineHeight: 1,
  },
  avatar: {
    background: theme.palette.primary.main,
    width: 32,
    height: 32,
    fontSize: 16,
    lineHeight: 16,
  },
  responsibleName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1.25),
  },
  buttonDel: {
    margin: theme.spacing(1.25),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
      borderColor: theme.palette.error.main,
    },
  },
}));
