import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  attachBack: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: fade('#000000', 0.7),
    zIndex: 100,
    padding: theme.spacing(2),
  },
  attachDialog: {
    background: 'white',
    boxShadow: theme.shadows[24],
    borderRadius: 4,
    padding: theme.spacing(),
    maxHeight: '100%',
    overflowY: 'overlay',
  },
  labelListUpload: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
