import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sidebar: {
    height: '100vh',
    width: 'fit-content',
    background: lighten(theme.palette.secondary.main, 0.9),
    zIndex: 4,
  },
  add: {
    margin: `20px ${theme.spacing()}px`,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: theme.spacing(),
      right: theme.spacing(),
      width: 56,
      height: 56,
    },
  },
  toolbar: {
    width: '100vw',
    maxWidth: 450,
    padding: `${theme.spacing()}px ${theme.spacing(1.5)}px`,
    boxShadow: '0px 6px 24px 12px #fff',
    zIndex: 3,
  },
  content: {
    width: '100vw',
    maxWidth: 450,
    paddingTop: theme.spacing(),
    overflow: 'overlay',
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
  },
  expansionSummary: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    minHeight: 64,
    padding: '0 32px',
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
  },
}));
