import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dialog: {
    paddingRight: '0px !important',
  },
  divider: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: theme.spacing(1.5),
  },
  item: {
    color: theme.palette.primary.main,
    '& > p': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1,
      textTransform: 'uppercase',
    },
  },
  subitem: {
    textTransform: 'uppercase',
    color: theme.palette.common.grey,
    fontSize: 16,
    paddingLeft: theme.spacing(),
    '& > svg': {
      color: theme.palette.primary.main,
    },
  },
  add: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    margin: theme.spacing(),
  },
}));
