import { makeStyles, lighten, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 64px)',
    padding: `${theme.spacing(1.5)}px 0 0`,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
    },
  },
  filter: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 0,
  },
  tableWrapper: {
    overflowX: 'auto',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  tableHeader: {
    color: `${theme.palette.tertiary.main}`,
    textTransform: 'uppercase',
    fontSize: 16,
    padding: theme.spacing(),
    whiteSpace: 'nowrap',
  },
  tableCell: {
    padding: theme.spacing(),
    border: '0',
    fontSize: 14,
    color: `${theme.palette.common.black}`,
    whiteSpace: 'nowrap',
    '&:first-child': {
      paddingLeft: `${theme.spacing(2)}px`,
    },
    '&:last-child': {
      color: theme.palette.common.grey,
    },
  },
  colorError: {
    color: darken(theme.palette.error.main, 0.3),
  },
  typePay: {
    color: theme.palette.error.main,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1,
  },
  typeReceive: {
    color: theme.palette.success.main,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1,
  },
  category: {
    textTransform: 'uppercase',
    position: 'relative',
  },
  categoryColor: {
    position: 'absolute',
    width: '100%',
    borderRadius: 2,
    height: 3,
    marginTop: 22,
  },
  categoryColors: {
    width: 24,
    borderRadius: 4,
    height: 24,
    margin: 2,
    [theme.breakpoints.down('sm')]: {
      height: 18,
      width: 18,
    },
  },
  badge: {
    '& span': {
      position: 'inherit',
      transform: 'inherit',
      marginLeft: theme.spacing(0.5),
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  value: {
    fontSize: 18,
    color: theme.palette.common.black,
  },
  buttonPay: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    width: 149,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.92),
      borderColor: theme.palette.error.main,
    },
  },
  buttonReceive: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    width: 149,
    '&:hover': {
      backgroundColor: lighten(theme.palette.success.main, 0.92),
      borderColor: theme.palette.success.main,
    },
  },
  buttonGreen: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    padding: theme.spacing(0.25),
    minWidth: 'inherit',
    marginLeft: theme.spacing(0.25),
    '&:hover': {
      background: lighten(theme.palette.success.main, 0.9),
    },
  },
  buttonRed: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    padding: theme.spacing(0.25),
    minWidth: 'inherit',
    marginLeft: theme.spacing(0.25),
    '&:hover': {
      background: lighten(theme.palette.error.main, 0.9),
    },
  },
  buttonPlus: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
  },
  pagination: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  paginationSpacer: {
    display: 'none',
  },
  paginationActions: {
    color: theme.palette.primary.main,
  },
  noData: {
    color: theme.palette.common.grey,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  expasionPanel: {
    boxShadow: 'initial',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
}));
