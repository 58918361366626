import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useTheme, darken } from '@material-ui/core/styles';

// Controllers
import Task from 'store/productivity/task/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Plus } from 'react-feather';
import Tooltip from 'common/tooltip';
import styles from './style';

function Priority({ id, name, priority }) {
  const classes = styles();
  const dispatch = useDispatch();

  const theme = useTheme();

  const [openMenu, setOpenMenu] = useState(null);

  const update = (value) => {
    dispatch(Log.store(`Changed task priority "${name}"`));
    dispatch(Task.update(id, { priority: value }));
    setOpenMenu(null);
  };

  const priorities = [
    { value: 5, label: 'Very high', color: '#DC1F26' },
    { value: 4, label: 'High', color: '#DC1F94' },
    { value: 3, label: 'Medium', color: '#941FDC' },
    { value: 2, label: 'Low', color: '#1F45DC' },
    { value: 1, label: 'Very low', color: '#1FB3DC' },
  ];

  const priorityData = priorities.find((p) => p.value === priority);

  return (
    <>
      {priority > 0 ? (
        <Grid
          item
          className={classes.priority}
          onClick={(e) => setOpenMenu(e.currentTarget)}
          style={{
            background: priorityData.color,
            color: theme.palette.getContrastText(priorityData.color),
          }}
        >
          <div
            className={classes.priorityNumber}
            style={{ background: darken(priorityData.color, 0.15) }}
          >
            {priority}
          </div>
          {`Priority ${priorityData.label.toLowerCase()}`}
          <svg
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            className={`${classes.svgIcon} ${classes.icon} ${classes.iconOutlined}`}
          >
            <path d="M7 10l5 5 5-5z" />
          </svg>
        </Grid>
      ) : (
        <Tooltip title="Add priority">
          <IconButton className={classes.addButton} onClick={(e) => setOpenMenu(e.currentTarget)}>
            <Plus size={18} />
          </IconButton>
        </Tooltip>
      )}

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => setOpenMenu(null)}
      >
        {priorities.map((p) => (
          <MenuItem key={p.value} onClick={() => update(p.value)}>
            <div
              className={classes.menuColor}
              style={{
                background: p.color,
                color: theme.palette.getContrastText(p.color),
              }}
            >
              {p.value}
            </div>
            <Typography>{p.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Priority.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
};

export default Priority;
