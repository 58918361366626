import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Company } from 'store/company/reducer';

const Contact = {
  index: () => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id).collection('contacts')
      .onSnapshot((ref) => {
        const contacts = ref.docs.map((c) => ({ id: c.id, ...c.data() }));
        contacts.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(Company.loadContacts(contacts));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    const id = data.document.trim().split(/\.|\/|-/).join('');

    firestore.collection('companies').doc(company.id)
      .collection('contacts').doc(id)
      .set({ ...data, updatedAt: now, createdAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Create a contact');
        dispatch(Alert.show({ message: 'Contact created successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('contacts').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        Mixpanel.track('Update a contact');
        dispatch(Alert.show({ message: 'Contact updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: company } = getState().company;

    firestore.collection('companies').doc(company.id)
      .collection('contacts').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a contact');
        dispatch(Alert.show({ message: 'Contact successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Contact;
