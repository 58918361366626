import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material Components
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';

// Controllers
import CashFlow from 'store/financial/cash-flow/controller';
import Supplier from 'store/supplier/controller';
import Log from 'store/log/controller';

// Commons and Styles
import { ChevronDown, CornerUpLeft } from 'react-feather';
import { numberToReal } from 'common/utils/numbers';
import Tooltip from 'common/tooltip';
import ConfirmDialog from 'common/confirm-dialog';
import { Typography } from '@material-ui/core';
import styles from './style';

function MobileTable({ data }) {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const isFiltered = location.search !== '';
  const { eventId } = useParams();

  const { contacts, suppliers } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);
  const [expanded, setExpanded] = useState([]);

  const expandPanel = (panel, exp) => {
    setExpanded(exp ? panel : false);
  };

  const [reopen, setReopen] = useState(null);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!suppliers) dispatch(Supplier.indexCompany());
  }, [suppliers, dispatch]);

  const openCash = () => {
    dispatch(Log.store(`Reopened an ${reopen.type === 'in' ? 'in' : 'out'} of ${
      numberToReal(reopen.value)
    }`));

    const supplier = suppliers.find((s) => {
      let match = false;
      if (s.payment && s.payment.installments) {
        s.payment.installments.forEach((i) => {
          if (i.cashFlow === reopen.id) match = true;
        });
      }
      return match;
    });

    if (supplier) dispatch(Supplier.update(supplier.id, { status: 3 }));
    dispatch(CashFlow.update(reopen.id, { completed: { on: false, date: null, value: 0 } }));
  };

  return (
    <>
      {data.map((cash) => {
        const contact = contacts.find((c) => c.id === cash.contact);

        return (
          <ExpansionPanel
            className={classes.expasionPanel}
            expanded={expanded === cash.id}
            key={cash.id}
            onChange={(e, exp) => expandPanel(cash.id, exp)}
          >
            <ExpansionPanelSummary expandIcon={<ChevronDown />}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {CashFlow.type === 'in' ? (
                    <Typography className={classes.typeReceive}>Ⓔ</Typography>
                  ) : (
                    <Typography className={classes.typePay}>Ⓢ</Typography>
                  )}

                  {cash.categories.map((category) => category && (
                    <div className={classes.category} key={category.id}>
                      <Tooltip title={category.name} onClick={(e) => e.stopPropagation()}>
                        <div
                          className={classes.categoryColors}
                          style={{ background: category.color }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </Grid>

                <Grid item style={{ marginLeft: 8, fontWeight: 600 }}>
                  {cash.installments && expanded === cash.id ? (
                    <Badge
                      color="primary"
                      className={classes.badge}
                      badgeContent={`${
                        cash.installments.indexOf(null) + 1
                      }/${cash.installments.length}`}
                    >
                      {numberToReal(cash.value)}
                    </Badge>
                  ) : numberToReal(cash.value)}
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>
                    {cash.type === 'in' ? 'Payer' : 'Payee'}
                  </Typography>
                  <Typography className={classes.value}>
                    {contact ? contact.name : 'Not defined'}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ color: '#777', fontSize: 14, paddingTop: 0 }}>
                  {cash.description}
                </Grid>

                <Grid item xs={6}>
                  <Typography className={classes.label}>Due date</Typography>
                  <Typography className={classes.value}>
                    {cash.dueAt.toDate() <= new Date() ? (
                      <Tooltip
                        title={`${cash.type === 'in' ? 'Receivement' : 'Payment'} late`}
                      >
                        <span className={classes.colorError}>
                          {format(cash.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })}
                        </span>
                      </Tooltip>
                    ) : (
                      format(cash.dueAt.toDate(), 'dd MMM yyyy', { locale: ptBR })
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 36 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/manager/${eventId}/financial/cash-flow/${cash.id}`);
                    }}
                  >
                    See more
                  </Button>
                </Grid>

                {permissionEdit && (
                  <Grid item container justify="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      disabled={!suppliers}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReopen(cash);
                      }}
                    >
                      <CornerUpLeft size={18} style={{ marginRight: 8 }} />
                      {`Reopen an ${cash.type === 'in' ? 'inflow' : 'outflow'}`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}


      {reopen && (
        <ConfirmDialog
          title={`Are you sure you want to reopen this ${
            reopen.type === 'in' ? 'inflow' : 'outflow'
          }?`}
          onConfirm={openCash}
          onClose={() => setReopen(null)}
        />
      )}

      {data.length === 0 && (
        <Typography variant="h5" className={classes.noData}>
          {!isFiltered
            ? 'Start receiving and paying your bills'
            : 'No entry or exit found with this filter'}
        </Typography>
      )}
    </>
  );
}

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MobileTable;
