import React, { useState, useEffect, useCallback } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import useEventController from 'store/event/controller';
import Channel from 'store/chat/channel/controller';
import Message from 'store/chat/message/controller';

// Icons, Commons and Styles
import {
  Target, Airplay, CheckCircle, Users, Mic, Clipboard, Map, MessageCircle,
} from 'react-feather';
import Toolbar from 'common/toolbar';
import Tooltip from 'common/tooltip';
import Loading from 'common/loading';
import Onboarding from 'common/onboarding';
import MindMap from './mindmap';
import styles from './style';

// Components
import Idealization from './steps/idealization';
import Persona from './steps/personas';
import Contents from './steps/contents';
import Format from './steps/format';
import Goals from './steps/goals';
import Scope from './scope';
import Chat from './chat';

function Create() {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Controllers
  const Event = useEventController(dispatch);

  const { eventId } = useParams();
  const params = new URLSearchParams(history.location.search);
  const scope = params.get('scope');

  const { current: event } = useSelector((store) => store.event);
  const { current: company, events, channels } = useSelector((store) => store.company);

  const [loaded, setLoaded] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [showScope, setShowScope] = useState(!!scope);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState(0);
  const [createStep, setCreateStep] = useState(0);
  const [data, setData] = useState({
    idealization: {
      description: '',
      objective: '',
      benchmarking: [],
      dates: { start: null, end: null },
      name: '',
      site: '',
      learnings: '',
    },
    persona: [{ description: '', interests: [], expected: '', engagement: '', data: '' }],
    contents: { themes: [], approach: '', approachComplement: '', engagement: [] },
    format: { streaming: '', subscribers: '', ticket: '', type: '', future: '' },
    goals: { successList: [''], success: '' },
  });
  const [mindmap, setMindmap] = useState([
    { id: 'root', label: 'MY EVENT', root: true },
    // Idealization
    { id: 'idealization', label: 'IDEALIZATION', parent: 'root' },
    { id: 'idealizationDescription', label: 'Describe your event using a maximum of 140 characters.', parent: 'idealization' },
    { id: 'idealizationObjective', label: 'Why are we organizing this event?', parent: 'idealization' },
    { id: 'idealizationBenchmarking', label: 'What events inspire us?', parent: 'idealization' },
    { id: 'idealizationDates', label: 'What is the best day and time for this event?', parent: 'idealization' },
    { id: 'idealizationName', label: 'How will you call the event?', parent: 'idealization' },
    { id: 'idealizationLearnings', label: 'What have we learned from previous events? What are the points of attention?', parent: 'idealization' },
  ]);

  // Reload data if change event
  useEffect(() => {
    if (event && event.id !== eventId) {
      Event.change();
    }
  }, [event, eventId, Event]);

  const save = useCallback(async ({ mindData, newStep }) => {
    const eventData = {
      company: company.id,
      draft: isDraft || false,
      mindmap: mindData || mindmap,
      creating: data,
      createStep: newStep || createStep,
      name: data.idealization.name,
      dates: data.idealization.dates,
    };

    if (eventId) Event.update(eventId, eventData);
    else {
      const id = await Event.store(eventData);
      history.replace(`/create/${id}`);
    }
  }, [Event, company.id, createStep, data, eventId, history, isDraft, mindmap]);

  const prev = useCallback(() => {
    if (step > 0) setStep(step - 1);
    else history.goBack();
  }, [history, step]);

  const next = useCallback(() => {
    if (step + 1 < steps.length) {
      setStep(step + 1);
    } else setShowScope(true);
  }, [step, steps.length]);

  const handleData = useCallback((id) => (dataSave) => {
    if (!_.isEqual(dataSave, data[id])) setData({ ...data, [id]: dataSave });
  }, [data]);

  const disabledFinish = useCallback(() => {
    const { idealization } = data;
    let completed = true;

    // Idealization
    if (!idealization.description || idealization.description.length > 140) completed = false;
    if (!idealization.name) completed = false;
    if (!idealization.dates || !idealization.dates.start || !idealization.dates.end) {
      completed = false;
    }

    return completed;
  }, [data]);

  useEffect(() => {
    if (!channels) dispatch(Channel.index());
    else {
      channels.forEach((channel) => {
        if (!channel.message) dispatch(Message.index(channel.id));
        if (!channel.unreads) dispatch(Message.unread(channel.id));
      });
    }
  }, [channels, dispatch]);

  let unreads = 0;
  if (channels && channels.find((c) => c.unreads)) {
    unreads = channels.reduce((a, b) => a + b.unreads, 0);
  }

  useEffect(() => {
    if (step > createStep) {
      setCreateStep(step);
      const { mindmap: mindData } = steps[step];
      setMindmap([...mindmap, ...mindData]);
      setTimeout(() => save({ mindData: [...mindmap, ...mindData], newStep: step }), 1000);
    }
  }, [createStep, mindmap, save, step, steps]);

  useEffect(() => {
    setSteps([
      {
        id: 'idealization',
        label: 'Idealization',
        icon: Clipboard,
        component: Idealization,
        completed: () => {
          const { idealization } = data;
          let completed = 100;
  
          if (!idealization.description || idealization.description.length > 200) {
            completed -= 20;
          }
          if (!idealization.objective) completed -= 20;
          if (!idealization.dates || !idealization.dates.start || !idealization.dates.end) {
            completed -= 20;
          }
          if (!idealization.name) completed -= 20;
          
          if (!idealization.learnings) completed -= 20;
  
          return completed;
        },
      },
      {
        id: 'persona',
        label: 'Public',
        icon: Users,
        component: Persona,
        completed: () => {
          const { persona } = data;
          let completed = 100;

          if (persona.length > 0) {
            const value = 16.6 / persona.length;
            const name = persona.filter((p) => !p.name).length;
            const desc = persona.filter((p) => !p.description).length;
            const interest = persona.filter((p) => !p.interests || p.interests.length === 0).length;
            const expected = persona.filter((p) => !p.expected).length;
            const engagement = persona.filter((p) => !p.engagement).length;
            const data = persona.filter((p) => !p.data).length;
            completed -= (name + desc + interest + expected + engagement + data) * value;
          } else completed -= 100;
          
          return completed;
        },
        mindmap: [
          { id: 'persona', label: 'AUDIENCE', parent: 'root' },
          {
            id: 'personaDescription',
            label: 'Describe in general terms who this person is. Where does she/he live, what do they eat, what do they like?',
            parent: 'persona',
          },
          { id: 'personaInterest', label: 'What are that person\'s main interests?', parent: 'persona' },
          { id: 'personaExpected', label: 'What does this person expects from an event like yours?', parent: 'persona' },
          { id: 'personaEngagement', label: 'What are the best channels for us to talk with this person?', parent: 'persona' },
          { id: 'personaData', label: 'What additional information or data about your audience do you need to get up?', parent: 'persona' },
        ],
      },
      {
        id: 'contents',
        label: 'Content',
        icon: Mic,
        component: Contents,
        completed: () => {
          const { contents } = data;
          let completed = 100;
  
          if (!contents.themes || contents.themes.length === 0) completed -= 33.3;
          if (!contents.engagement || contents.engagement.length === 0 || !contents.engagement[0]) {
            completed -= 33.3;
          }
          if (!contents.approach) completed -= 33.3;
  
          return completed;
        },
        mindmap: [
          { id: 'contents', label: 'CONTENT', parent: 'root' },
          {
            id: 'contentsThemes',
            label: 'What are the subjects that add the most value?',
            parent: 'contents',
          },
        ],
      },
      {
        id: 'format',
        label: 'Format',
        icon: Airplay,
        component: Format,
        completed: () => {
          const { format } = data;
          let completed = 100;
  
          if (!format.streaming) completed -= 20;
          if (!format.subscribers) completed -= 20;
          if (!format.ticket) completed -= 20;
          if (!format.type) completed -= 20;
          if (!format.future) completed -= 20;
  
          return completed;
        },
        mindmap: [
          { id: 'format', label: 'FORMAT', parent: 'root' },
          { id: 'formatType', label: 'What will be the format of the event? (Ex: Webinar, Seminar)', parent: 'format' },
          { id: 'formatSubscribers', label: 'How many participants are you waiting for?', parent: 'format' },
          { id: 'formatStreaming', label: 'Which tools can you use?', parent: 'format' },
          { id: 'formatTicket', label: 'Will tickets be free or paid?', parent: 'format' },
          { id: 'formatRepeat', label: 'Will the event be unique? Will there be a sequence?', parent: 'format' },
        ],
      },
      {
        id: 'goals',
        label: 'Goals',
        icon: Target,
        component: Goals,
        completed: () => {
          const { goals } = data;
          let completed = 100;
  
          if (!goals.successList || goals.successList.length === 0 || !goals.successList[0]) completed -= 50;
          if (!goals.success) completed -= 50;
  
          return completed;
        },
        mindmap: [
          { id: 'goals', label: 'GOALS', parent: 'root' },
          { id: 'goalsSuccess', label: 'What defines the success of your event?', parent: 'goals' },
          { id: 'goalsSuccessList', label: 'What are the goals that will help you achieve this success?', parent: 'goals' },
        ],
      },
    ]);
  }, [data]);

  useEffect(() => {
    if (events && eventId) {
      const event = events.find((e) => e.id === eventId);
      if (event) {
        if (event.creating) {
          const { creating } = event;
          const { start, end } = creating.idealization.dates;
          creating.idealization.dates.start = start && start.toDate ? start.toDate() : start;
          creating.idealization.dates.end = end && end.toDate ? end.toDate() : end;
  
          setData(creating);
        }
        if (event.createStep) {
          setCreateStep(event.createStep);
          if (event.draft) setStep(event.createStep);
        }
        if (event.mindmap) setMindmap(event.mindmap);
        setIsDraft(event.draft);
        setTimeout(() => setLoaded(true), 100);
      } else {
        history.goBack();
      }
    } else if (!eventId) {
      setLoaded(true);
    }
  }, [eventId, events, history]);

  const handleMindmap = (newMindmap) => {
    if (!_.isEqual(newMindmap, mindmap) && eventId) {
      setMindmap(newMindmap)
      Event.update(eventId, { mindmap: newMindmap }, { withoutAlert: true });
    }
  };

  const finishCreate = async () => {
    const eventData = {
      company: company.id,
      draft: false,
      planner: true,
      mindmap,
      creating: data,
      createStep,
      dates: data.idealization.dates,
      name: data.idealization.name,
    };

    if (eventId) {
      await Event.update(eventId, eventData);
      history.replace(`/planner/${eventId}/action-plan`);
    } else {
      const id = await Event.store(eventData);
      history.replace(`/planner/${id}/action-plan`);
    }
  };

  const Component = steps[step] ? steps[step].component : () => <div />;

  const tabsToolbar = [
    { title: 'Form', selected: !showScope, click: () => setShowScope(false) },
    { title: 'Scope', selected: showScope, click: () => setShowScope(true) },
  ];

  return (
    <Grid container>
      <Toolbar
        avatar
        logo={isDraft}
        back={isDraft}
        sidebar={!isDraft && '/create'}
        tabs={!isDraft && tabsToolbar}
        snippet={!isDraft}
        defaultIcons={false}
        shadow="none"
        title="IDEALIZE"
      />
      {loaded ? (
        <div style={!mobile && !isDraft ? { width: 'calc(100% - 130px)', marginLeft: 130 } : { width: '100%' }}>
          {showScope ? (
            <Grid container wrap="nowrap">
              <Scope data={data} scope={scope || !isDraft} goBack={() => setShowScope(false)} goPlan={finishCreate} />
            </Grid>
          ) : (
            <>
              <ScrollContainer className={classes.steps} vertical={false} style={isDraft ? { width: '100%' } : {}}>
                {steps.map((s, i) => {
                  const percentual = Math.floor(s.completed());
                  let Icon = (
                    <Tooltip title={`${percentual}%`}>
                      <div style={{ position: 'relative', height: 52, marginRight: 16 }}>
                        <s.icon size={20} style={{ margin: 16 }} />
                        <CircularProgress
                          variant="static"
                          color="primary"
                          value={percentual}
                          size={52}
                          thickness={4}
                          style={{ position: 'absolute', left: 0 }}
                        />
                        <CircularProgress
                          variant="static"
                          color="primary"
                          value={100}
                          size={52}
                          thickness={4}
                          style={{ position: 'absolute', opacity: 0.3, left: 0 }}
                        />
                      </div>
                    </Tooltip>
                  );
                  if (createStep >= i) {
                    if (percentual === 100) {
                      Icon = (
                        <CheckCircle
                          size={28}
                          style={{ marginRight: 16, color: '#4bb75c', strokeWidth: 3 }}
                        />
                      );
                    }
                  }
                  return (
                    <React.Fragment key={s.id}>
                      <Grid
                        container
                        wrap="nowrap"
                        justify="center"
                        alignItems="center"
                        onClick={() => setStep(i)}
                        className={`
                          ${classes.step}
                          ${createStep > i && classes.stepPast}
                          ${createStep === i && classes.stepCompleted}
                        `}
                        style={step === i ? { fontWeight: 600 } : {}}
                      >
                        {Icon}
                        {s.label}
                      </Grid>
                      {i + 1 < steps.length && <div className={classes.divider}>»</div>}
                    </React.Fragment>
                  );
                })}
              </ScrollContainer>
              
              <Grid container wrap="nowrap" className={classes.content}>
                {steps[step] && (
                  <Component 
                    data={data[steps[step].id]}
                    next={next}
                    prev={prev}
                    save={save}
                    isDraft={isDraft}
                    showFinish={disabledFinish(data)}
                    onChange={handleData(steps[step].id)}
                  />
                )}
                {showMap && <MindMap data={mindmap} setData={handleMindmap} />}
              </Grid>
              
              {!mobile && (
                <Tooltip title={`${showMap ? 'Hide' : 'Show'} mind map`}>
                  <Fab
                    color="primary"
                    variant="extended"
                    className={classes.map}
                    onClick={() => setShowMap(!showMap)}
                  >
                    <Map style={{ marginRight: 8 }} />
                    <Switch checked={showMap} />
                  </Fab>
                </Tooltip>
              )}

              <Fab
                color="primary"
                className={classes.chatFab}
                onClick={() => setOpenChat(true)}
              >
                <Badge color="secondary" badgeContent={unreads} classes={{ badge: classes.badge }}>
                  <MessageCircle />
                </Badge>
              </Fab>
              <Chat open={openChat} onClose={() => setOpenChat(false)} />
            </>
          )}

          <Onboarding id="create" title="Understanding (CO)Create in a minute">
            <iframe
              allowfullscreen
              width="100%"
              height="315"
              title="create"
              frameborder="0"
              src="https://www.youtube.com/embed/kY2mU8q5P5o"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </Onboarding>
        </div>
      ) : (
        <div style={{ paddingTop: 100, width: '100%' }}>
          <Loading content={300} ready={loaded} />
        </div>
      )}
    </Grid>
  );
}

export default Create;