import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import getCNPJ from 'services/receitaws';
import banks from 'services/brazilian-banks.json';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

function FormConfig({ edit, person, setPerson }) {
  const { contacts } = useSelector((state) => state.company);

  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingCNPJ, setLoadingCNPJ] = useState(false);

  const searchCNPJ = (cnpj) => {
    const search = cnpj.trim().split(/\.|\/|-/).join('');

    setLoadingCNPJ(true);

    return getCNPJ(search)
      .then((data) => {
        setLoadingCNPJ(false);
        return data;
      })
      .catch(() => {
        setLoadingCNPJ(false);
      });
  };

  const handleCEP = async (value, state) => {
    const cep = value.replace('-', '').trim();

    if (cep.length === 8) {
      setLoadingCEP(true);
      await axios.get(`https://viacep.com.br/ws/${cep}/json`).then((response) => {
        const {
          logradouro, bairro, localidade, uf,
        } = response.data;

        _.set(state, 'address.street', logradouro);
        _.set(state, 'address.neighborhood', bairro);
        _.set(state, 'address.city', localidade);
        _.set(state, 'address.state', uf);
        _.set(state, 'address.country', 'Brasil');

        setLoadingCEP(false);
      });
    }

    return state;
  };

  return [
    {
      name: 'person',
      type: 'radio',
      options: [
        { value: 'legal', label: 'Legal person' },
        { value: 'physical', label: 'Physical person' },
      ],
      onChange: (value, state) => {
        setPerson(value);
        return state;
      },
    },
    {
      name: 'document',
      type: 'text',
      label: person === 'legal' ? 'TaxId' : 'Social Security Number',
      mask: person === 'legal' ? 'TaxId' : 'social security number',
      disabled: loadingCNPJ || edit,
      InputPropsDefault: {
        endAdornment: loadingCNPJ && (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ marginRight: 4 }} />
          </InputAdornment>
        ),
      },
      validation: !edit && {
        required: `The ${person === 'legal' ? 'Tax Id' : 'Social Security Number'} of contact is required`,
        unique: {
          msg: `There is already contact with this ${person === 'legal' ? 'Tax Id' : 'Social Security Number'}`,
          params: { list: contacts ? contacts.map((c) => c.id) : [] },
        },
      },
      onChange: async (value, state) => {
        if (value.trim().length === 18) {
          const cnpj = value.trim().split(/\.|\/|-/).join('');
          const isExist = contacts ? contacts.find((c) => c.id === cnpj) : false;

          if (!isExist) {
            const data = await searchCNPJ(value);
            _.set(state, 'name', data.businessName);
            _.set(state, 'companyName', data.companyName);
            _.set(state, 'situation', data.situation);
            _.set(state, 'legalNature', data.legalNature);
            _.set(state, 'mainActivity', data.mainActivity);
            _.set(state, 'contact.email', data.contact.email);
            _.set(state, 'contact.phone', data.contact.phone);
            _.set(state, 'address', data.address);
          }
        }
        return state;
      },
    },
    {
      name: 'name',
      type: 'text',
      label: person === 'legal' ? 'Fantasy name' : 'Name',
      validation: { required: 'Contact name is required' },
    },
    {
      name: 'companyName',
      type: 'text',
      label: 'Company name',
      disabled: true,
    },
    {
      name: 'situation',
      type: 'text',
      label: 'CNPJ situation',
      disabled: true,
    },
    {
      name: 'legalNature',
      type: 'text',
      label: 'Legal Nature',
      disabled: true,
    },
    {
      name: 'mainActivity.code',
      type: 'text',
      label: 'Code',
      disabled: true,
    },
    {
      name: 'mainActivity.description',
      type: 'text',
      label: 'Description',
      disabled: true,
    },
    {
      name: 'contact.email',
      type: 'text',
      label: 'E-mail',
      validation: {
        required: 'Contact email is required',
        email: 'Must be a valid email',
      },
    },
    {
      name: 'contact.phone',
      type: 'text',
      mask: 'phone',
      label: 'Phone / Cellphone',
      validation: { phone: 'Must be a valid number' },
    },
    {
      name: 'contact.site',
      type: 'text',
      label: 'Site',
      validation: { url: 'Must be a valid website' },
    },
    {
      name: 'transference.bankName',
      type: 'autosuggest',
      label: 'Bank',
      placeholder: 'Search by bank',
      match: 'anyPosition',
      keyList: 'slug',
      list: banks.map((b) => ({ ...b, slug: `${b.code} - ${b.name}` })),
      onChange: (value, state) => {
        if (value.code) {
          _.set(state, 'transference.bank', value.code);
          _.set(state, 'transference.bankName', value.name);
        } else {
          _.set(state, 'transference.bank', '');
          _.set(state, 'transference.bankName', value);
        }
        return state;
      },
    },
    {
      name: 'transference.bank',
      type: 'text',
    },
    {
      name: 'transference.agency',
      type: 'text',
      label: 'Agency',
      accept: /^[0-9]+(=?-\d?|-|)/g,
    },
    {
      name: 'transference.code',
      type: 'text',
      label: 'Account',
      accept: /^[0-9]+(=?-\d?|-|)/g,
    },
    {
      name: 'types',
      type: 'select-chip',
      label: 'Contact type',
      options: [
        { label: 'Supllier', value: 'Supllier' },
        { label: 'Partner', value: 'Partner' },
        { label: 'Sponsor', value: 'Sponsor' },
        { label: 'Participant', value: 'Participante' },
        { label: 'Client', value: 'Client' },
        { label: 'Others', value: 'Others' },
      ],
      validation: { required: 'Select at least one contact type' },
    },
    {
      name: 'address.postalCode',
      type: 'text',
      label: 'Zip Code',
      mask: 'cep',
      disabled: loadingCEP,
      InputPropsDefault: {
        endAdornment: loadingCEP && (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ marginRight: 4 }} />
          </InputAdornment>
        ),
      },
      onChange: handleCEP,
    },
    {
      name: 'address.street',
      type: 'text',
      label: 'Street',
      disabled: loadingCEP,
    },
    {
      name: 'address.number',
      type: 'text',
      inputType: 'number',
      label: 'Number',
    },
    {
      name: 'address.complement',
      type: 'text',
      label: 'Complement',
      disabled: loadingCEP,
    },
    // {
    //   name: 'address.neighborhood',
    //   type: 'text',
    //   label: 'Neighborhood',
    // },
    {
      name: 'address.city',
      type: 'text',
      label: 'City',
      disabled: loadingCEP,
    },
    {
      name: 'address.state',
      type: 'text',
      label: 'State',
      disabled: loadingCEP,
      maxLength: 2,
    },
    {
      name: 'address.country',
      type: 'text',
      label: 'Country',
      disabled: loadingCEP,
    },
  ];
}

export default FormConfig;
