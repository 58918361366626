import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chart: {
    height: '50px !important',
    width: '100%',
    '& svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(),
    },
  },
  nodata: {
    color: theme.palette.common.grey,
    fontSize: 16,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18,
  },
  avatar: {
    background: theme.palette.primary.main,
    width: 32,
    height: 32,
    fontSize: 16,
    marginRight: 12,
  },
  name: {
    color: theme.palette.common.grey,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  user: {
    height: 82,
    minHeight: 82,
    padding: 16,
    borderBottom: '1px solid #e6e6e6',
    overflow: 'hidden',
    margin: '0 16px',
    width: 'calc(100% - 32px)',
  },
  currentUser: {
    border: 'none',
    borderRadius: 4,
    boxShadow: theme.shadows[2],
    zIndex: 2,
  },
  userInfo: {
    width: 130,
    minWidth: 130,
    overflow: 'hidden',
    margin: '-16px 0',
    paddingRight: 8,
    boxShadow: '20px 0 16px 0px white',
    zIndex: 1,
  },
}));
