import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { differenceInCalendarDays, format } from 'date-fns';

// Material Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Icons and Styles
import { Calendar, X, FileText, Globe, Home, Airplay } from 'react-feather';
import Loading from 'common/loading';
import styles from './style';

function EventInfoDialog({ open, onClose }) {
  const classes = styles();

  const history = useHistory();
  const { eventId } = useParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { current: event } = useSelector((state) => state.event);

  return (
    <Dialog
      fullScreen={mobile}
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="view-notifications-dialog"
      classes={{ root: classes.dialog }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Typography>
            <b style={{ fontSize: 24 }}>{event && event.name}</b>
            <p>
              {event && event.dates
                && differenceInCalendarDays(event.dates.start.toDate(), new Date()) > 0
                && `Reamaining ${
                  differenceInCalendarDays(event.dates.start.toDate(), new Date())
                } days`}
            </p>
          </Typography>
          <IconButton onClick={onClose} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container direction="column">
          {event ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems="center" className={classes.valueDate}>
                    <Calendar />
                    {format(event.dates.start.toDate(), 'dd/MM/yyyy HH:mm')}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems="center" className={classes.valueDate}>
                    <Calendar />
                    {format(event.dates.end.toDate(), 'dd/MM/yyyy HH:mm')}
                  </Grid>
                </Grid>
                
                {event.creating && event.creating.idealization.description && (
                  <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Grid container alignItems="center" className={classes.label}>
                      <FileText size={18} />
                      DESCRIPTION
                    </Grid>
                    <Grid container className={classes.value}>
                      {event.creating.idealization.description}
                    </Grid>
                  </Grid>
                )}
                
                {event.creating && event.creating.idealization.site && (
                  <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                    <Grid container alignItems="center" className={classes.label}>
                      <Globe size={18} />
                      EVENT WEBSITE
                    </Grid>
                    <Grid container className={classes.value}>
                      <Link
                        target="_blank"
                        href={`http://${event.creating.idealization.site}`.replace('http://http', 'http')}
                      >
                        {event.creating.idealization.site}
                      </Link>
                    </Grid>
                  </Grid>
                )}
                
                <Grid item xs={12} sm={event.address ? 12 : 6} style={{ marginTop: 16 }}>
                  <Grid container alignItems="center" className={classes.label}>
                    {event.address ? <Home size={18} /> : <Airplay size={18} />}
                    {event.address ? 'ADDRESS' : 'FORMAT'}
                  </Grid>
                  <Grid container className={classes.value}>
                    {event.address 
                      && `${event.address.localName} em ${event.address.city} - ${event.address.state}`}
                    {event.creating
                      && `${event.creating.format.type} ${event.creating.format.ticket === 'free'
                      ? 'free' : 'paid'}`}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Loading ready={!!event} content={300} />
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: 16 }}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={() => history.push(`/create/${eventId}`)}
        >
          SEE MORE INFORMATION
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EventInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

EventInfoDialog.defaultProps = {
  open: false,
};

export default EventInfoDialog;
