import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// Material UI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
import Account from 'store/financial/account/controller';
import Log from 'store/log/controller';

// Icons, Commons and Styles
import { X } from 'react-feather';
import { acceptBanks } from 'services/tecnospeed';
import Loading from 'common/loading';
import Form from 'common/form';
import FormConfig from './form-config';
import styles from './style';

function AccountForm() {
  const classes = styles();
  const dispatch = useDispatch();

  const history = useHistory();
  const { accountId } = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { accounts } = useSelector((store) => store.company);
  const { current: { permissions } } = useSelector((store) => store.user);

  const permissionEdit = !permissions || !permissions.manager || permissions.manager.financial.edit;

  useEffect(() => {
    if (!permissionEdit) history.goBack();
  }, [permissionEdit, history]);

  const [initialForm, setInitialForm] = useState({
    bank: '',
    agency: '',
    code: '',
    initialValue: 0,
  });

  useEffect(() => {
    if (accounts && accountId) {
      const account = accounts.find((a) => a.id === accountId);

      if (account) setInitialForm({ ...account });
      else history.goBack();
    }
  }, [accountId, accounts, history]);

  const submit = (data) => {
    if (accountId) {
      dispatch(Log.store(`Edited a bank account ${
        acceptBanks.find((a) => a.code === data.bank).name
      }`));
      dispatch(Account.update(accountId, data));
    } else {
      dispatch(Log.store(`Created a new bank account ${
        acceptBanks.find((a) => a.code === data.bank).name
      }`));
      dispatch(Account.store(data));
    }
    history.goBack();
  };

  const formSchema = FormConfig();

  return (
    <Dialog
      open
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="form-cash-flow-dialog"
      fullScreen={fullScreen}
      onClose={history.goBack}
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <span>
            {accountId ? `Edit account ${initialForm.name}` : 'Create a new account'}
          </span>
          <IconButton onClick={() => history.goBack()} aria-label="Exit">
            <X />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {accounts ? (
          <Form
            id="form-account"
            onSubmit={submit}
            initialState={initialForm}
            schema={formSchema}
          >
            {(inputs) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>{inputs.bank}</Grid>
                <Grid item xs={6} sm={3}>{inputs.agency}</Grid>
                <Grid item xs={6} sm={4}>{inputs.code}</Grid>
                <Grid item xs={12}>{inputs.initialValue}</Grid>
                {typeof initialForm.autoPayment === 'boolean' && (
                  <Grid item container justify="space-between" alignItems="center">
                    <p>Allow payments through the platform</p>
                    {inputs.autoPayment}
                  </Grid>
                )}
              </Grid>
            )}
          </Form>
        ) : (
          <Loading ready={!!accounts} content={200} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          type="submit"
          form="form-account"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {accountId ? 'Save ' : 'Create '}
          account
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AccountForm;
