import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// Controllers
import { Actions as FormSave } from 'store/form/reducer';

// Components
import AutosuggestInput from './autosuggest';
import CheckboxInput from './checkbox';
import ColorPicker from './color-picker';
import DateTimeInput from './datetime';
import HiddenInput from './hidden';
import InputCurrency from './currency';
import InputCurrencyTypes from './currency-types';
import InputText from './textfield';
import InputTextTypes from './text-types';
import Select from './select';
import SelectChips from './select-chips';
import SwitchInput from './switch';
import RadioInput from './radio';
import Upload from './upload';

import Validation from './validate';

const INPUT_TYPE = {
  text: InputText,
  'text-types': InputTextTypes,
  select: Select,
  currency: InputCurrency,
  'currency-types': InputCurrencyTypes,
  autosuggest: AutosuggestInput,
  'color-picker': ColorPicker,
  'select-chip': SelectChips,
  switch: SwitchInput,
  checkbox: CheckboxInput,
  datetime: DateTimeInput,
  radio: RadioInput,
  upload: Upload,
  hidden: HiddenInput,
};

const DEFAULT_VALUE = {
  text: '',
  select: '',
  currency: 0,
  'currency-types': { type: '', value: 0 },
  autosuggest: '',
  'color-picker': '',
  'select-chip': [],
  switch: false,
  checkbox: false,
  datetime: null,
  radio: '',
  upload: [],
  hidden: '',
};

function Form({
  id, onSubmit, schema, children, initialState, onChange, onKeyPress,
}) {
  const dispatch = useDispatch();
  const { save } = useSelector((store) => store.form);

  const initialInputs = {};
  schema.forEach(({ name }) => _.set(initialInputs, name, <></>));

  const [state, setState] = useState(initialState);
  const [inputs, setInputs] = useState(initialInputs);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!_.isEqual(state, initialState)) setState(initialState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  useEffect(() => {
    const form = save.find((f) => f.id === id);
    if (form) setState(form.state);
  }, [save, id]);

  useEffect(() => {
    if (onChange) onChange(state);
  }, [state, onChange]);

  useEffect(() => {
    const newInputs = {};

    schema.forEach(({
      name,
      type,
      defaultValue,
      validation: messages,
      onChange: onChangeInput,
      inputProps,
      ...moreProps
    }) => {
      const Input = INPUT_TYPE[type];
      const newInput = (
        <Input
          id={id}
          state={state}
          error={_.get(errors, name)}
          name={name}
          inputProps={inputProps}
          value={defaultValue || _.get(state, name) || DEFAULT_VALUE[type]}
          onChange={async (value) => {
            let s = _.cloneDeep(state);
            _.set(s, name, value);
            if (onChangeInput) s = await onChangeInput(value, s);
            setState(s);

            if (messages) {
              const e = _.cloneDeep(errors);
              _.set(e, name, Validation(
                type === 'currency-types' ? _.get(s, name).value : _.get(s, name),
                messages,
                s,
              ));
              setErrors(e);
            }
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...moreProps}
        />
      );

      _.set(newInputs, name, newInput);
    });

    setInputs(newInputs);
  }, [schema, state, errors, id]);

  const submit = async (e) => {
    e.preventDefault();
    const newErrors = _.cloneDeep(errors);
    let isValid = true;

    schema.forEach(({ validation: messages, name, type }) => {
      if (messages) {
        const value = _.get(state, name);
        const error = Validation(
          type === 'currency-types' ? value.value : value, messages, state,
        );
        if (isValid && !!error) isValid = false;
        _.set(newErrors, name, error);
      }
    });

    if (isValid) {
      dispatch(FormSave.remove(id));
      onSubmit(state);
    }
    setErrors(newErrors);
  };

  return (
    <form id={id} onSubmit={submit} style={{ width: '100%' }} onKeyPress={onKeyPress} autoComplete="off">
      {children(inputs)}
    </form>
  );
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.func.isRequired,
  initialState: PropTypes.shape({}),
  onChange: PropTypes.func,
};

Form.defaultProps = {
  initialState: {},
  onChange: () => {},
};

export default Form;
