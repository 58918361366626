import React from 'react';
import PropTypes from 'prop-types';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Commons and Styles
import { numberToReal, currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import { addMonths, lastDayOfMonth, format } from 'date-fns';
import { Timestamp } from 'services/firebase';
import styles from '../style';

function PaymentInstallmentsDefault({
  edit, subTotal, payment, onChange,
}) {
  const classes = styles();

  const handleValue = (e) => {
    const value = revertCurrencyInput(e.target.value);
    let { installments } = payment;

    installments = installments.map((installment) => ({ ...installment, value }));

    onChange({ ...payment, installments });
  };

  const handleDay = (e) => {
    const day = parseInt(e.target.value, 10);
    const now = new Date();

    let { installments } = payment;
    let maxDay = lastDayOfMonth(now);
    let month = now.getMonth() + 1;
    let year = now.getFullYear();

    const [firstInstallment] = installments;
    if (firstInstallment.due) {
      maxDay = lastDayOfMonth(firstInstallment.due.toDate());
      month = firstInstallment.due.toDate().getMonth();
      year = firstInstallment.due.toDate().getFullYear();
    }

    if (!day || day > maxDay || day <= 0) return;

    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    let changeYear = false;
    if (currentMonth === month && year > currentYear && day > currentDay) {
      changeYear = true;
    }

    installments = installments.map((installment, i) => {
      const date = installment.due ? installment.due.toDate() : addMonths(now, i);
      date.setDate(day);
      if (changeYear) date.setFullYear(date.getFullYear() - 1);
      return { ...installment, due: Timestamp.fromDate(date) };
    });

    onChange({ ...payment, installments });
  };

  const handleMonth = (e) => {
    const month = parseInt(e.target.value, 10);

    let { installments } = payment;
    const [firstInstallment] = installments;
    let day = new Date().getDate();
    let prevMonth = new Date().getMonth();

    if (firstInstallment) {
      day = firstInstallment.due.toDate().getDate();
      prevMonth = firstInstallment.due.toDate().getMonth();
    }
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth();

    let diff = 0;

    if (
      (month > currentMonth || (month === currentMonth && day > currentDay))
      && prevMonth < currentMonth
    ) {
      diff = month - 12 - prevMonth;
    } else {
      diff = month - prevMonth;
      if (month < currentMonth && prevMonth >= currentMonth) diff += 12;
    }

    installments = installments.map((installment) => {
      const date = addMonths(installment.due.toDate(), diff);
      return { ...installment, due: Timestamp.fromDate(date) };
    });

    onChange({ ...payment, installments });
  };

  const { value, due } = payment.installments[0];

  const total = payment.installments.reduce((c, i) => c + i.value, 0);

  return (
    <>
      <Grid item xs={12} sm={edit ? 5 : 4}>
        {edit ? (
          <TextField
            fullWidth
            error={subTotal > total}
            variant="outlined"
            label="Parcel value"
            value={currencyInput(value)}
            onChange={handleValue}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { className: classes.field },
            }}
          />
        ) : (
          <>
            <Typography variant="overline" className={classes.infoTitle}>
              Parcel value
            </Typography>
            <Typography className={classes.infoText}>{numberToReal(value)}</Typography>
          </>
        )}
      </Grid>

      <Grid item xs={12} sm={3}>
        {edit ? (
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            label="Dia do venc."
            value={due ? due.toDate().getDate() : 1}
            onChange={handleDay}
            onClick={(e) => e.target.select()}
            InputProps={{
              inputProps: { className: classes.field, min: 1 },
            }}
          />
        ) : (
          <>
            <Typography variant="overline" className={classes.infoTitle}>
              Due date
            </Typography>
            <Typography className={classes.infoText}>{format(due.toDate(), 'dd')}</Typography>
          </>
        )}
      </Grid>

      <Grid item xs={12} sm={edit ? 4 : 3}>
        {edit ? (
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Start Month"
            value={due ? due.toDate().getMonth() : new Date().getMonth() + 1}
            onChange={handleMonth}
            InputProps={{ inputProps: { className: classes.field } }}
          >
            <MenuItem value="0">January</MenuItem>
            <MenuItem value="1">February</MenuItem>
            <MenuItem value="2">March</MenuItem>
            <MenuItem value="3">April</MenuItem>
            <MenuItem value="4">May</MenuItem>
            <MenuItem value="5">June</MenuItem>
            <MenuItem value="6">July</MenuItem>
            <MenuItem value="7">August</MenuItem>
            <MenuItem value="8">September</MenuItem>
            <MenuItem value="9">October</MenuItem>
            <MenuItem value="10">November</MenuItem>
            <MenuItem value="11">December</MenuItem>
          </TextField>
        ) : (
          <>
            <Typography variant="overline" className={classes.infoTitle}>
              Parcel start
            </Typography>
            <Typography className={classes.infoText}>
              {format(due.toDate(), 'MMMM', { locale: ptBR }).toUpperCase()}
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
}

PaymentInstallmentsDefault.propTypes = {
  edit: PropTypes.bool.isRequired,
  subTotal: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    discount: PropTypes.object,
    exchange: PropTypes.object,
    installments: PropTypes.array,
    method: PropTypes.string,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

PaymentInstallmentsDefault.defaultProps = {
  payment: null,
};

export default PaymentInstallmentsDefault;
