import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  dialog: {
    overflow: 'visible',
  },
  button: {
    margin: 20,
  },
  select: {
    width: 200,
    maxHeight: 500,
  },
  categoryColor: {
    width: 6,
    marginRight: theme.spacing(),
    borderRadius: 4,
  },
  categoryLabel: {
    lineHeight: 1,
    '& span': {
      color: theme.palette.common.grey,
      fontSize: 10,
      textTransform: 'uppercase',
    },
    '& p': {
      marginTop: 4,
    },
  },
  actionMenu: {
    background: lighten(theme.palette.secondary.light, 0.65),
    '&:hover': {
      background: lighten(theme.palette.secondary.light, 0.45),
    },
  },
  avatar: {
    background: theme.palette.primary.main,
    width: 32,
    height: 32,
    fontSize: 16,
    lineHeight: 16,
  },
  responsibleName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectResponsible: {
    paddingTop: 12,
    paddingBottom: 12,
    minHeight: 32,
  },
  actions: {
    justifyContent: 'center',
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));
