import React from 'react';
import PropTypes from 'prop-types';
import { Timestamp } from 'services/firebase';
import { format, addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Material UI Components
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Commons and Styles
import { numberToReal, currencyInput, revertCurrencyInput } from 'common/utils/numbers';
import { DateField } from './utils';
import styles from '../style';

function PaymentInstallmentsCustom({
  edit, subTotal, payment, onChange,
}) {
  const classes = styles();

  const handleChange = (index, key, value) => {
    const { installments } = payment;
    installments[index][key] = key === 'due' ? Timestamp.fromDate(value) : value;

    if (key === 'due') {
      [...installments].splice(index, installments.length - index).forEach(({ due }, i) => {
        if (!due) installments[index + i].due = Timestamp.fromDate(addMonths(value, i));
      });
    }

    onChange({ ...payment, installments });
  };

  const total = payment.installments.reduce((c, i) => c + i.value, 0);

  return payment.installments.map((installment, k) => {
    const { value, due, cashFlow } = installment;
    const key = `installment-${k}`;
    const date = due && due.toDate();

    return (
      <Grid container item alignItems="center" spacing={1} key={key} style={{ marginBottom: 0 }}>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          alignItems="center"
          className={classes.installmentLabel}
        >
          <Typography variant="overline" className={classes.infoTitle}>
            {`${k + 1}° parcela`}
          </Typography>
          {!edit && (
            <Typography variant="overline" className={classes.infoTitle}>
              Due date
            </Typography>
          )}
        </Grid>

        <Grid item xs={edit ? 12 : 6} sm={6}>
          {edit && payment.installments.length > 1 ? (
            <TextField
              fullWidth
              error={subTotal > total}
              variant="outlined"
              label="Value"
              value={currencyInput(value)}
              disabled={cashFlow && (cashFlow.completed.on || !!cashFlow.completed.status)}
              onChange={(e) => handleChange(k, 'value', revertCurrencyInput(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: { className: classes.field },
              }}
            />
          ) : (
            <Typography className={classes.infoText}>{numberToReal(value)}</Typography>
          )}
        </Grid>

        <Grid container item xs={edit ? 12 : 6} sm={6} justify="flex-end">
          {edit ? (
            <DateField
              value={date}
              onChange={(dt) => handleChange(k, 'due', dt)}
              disabled={cashFlow && (cashFlow.completed.on || !!cashFlow.completed.status)}
            />
          ) : (
            <>
              <Typography className={classes.infoText}>
                {format(date, 'dd MMM yyyy', { locale: ptBR })}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    );
  });
}

PaymentInstallmentsCustom.propTypes = {
  edit: PropTypes.bool.isRequired,
  subTotal: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    discount: PropTypes.object,
    exchange: PropTypes.object,
    installments: PropTypes.array,
    method: PropTypes.string,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

PaymentInstallmentsCustom.defaultProps = {
  payment: null,
};

export default PaymentInstallmentsCustom;
