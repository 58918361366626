import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // New
  button: {
    margin: theme.spacing(0.5),
  },
  buttonDestroy: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  actions: {
    justifyContent: 'center',
  },
  colors: {
    marginTop: theme.spacing(),
    overflow: 'hidden',
    '& > div': {
      justifyContent: 'center',
      padding: 8,
    },
  },

  // List
  add: {
    marginRight: theme.spacing(),
    padding: theme.spacing(0.5),
    borderRadius: 50,
    minWidth: 42,
    '& p': {
      textTransform: 'uppercase',
      margin: 0,
      overflow: 'hidden',
      maxWidth: 0,
      maxHeight: 24,
      transition: 'all 0.2s ease-in',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover p': {
        margin: `0 ${theme.spacing(0.5)}px`,
        maxWidth: 165,
        transition: 'all 0.2s ease-out',
      },
    },
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
    color: theme.palette.tertiary.main,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 900,
  },
  expansion: {
    boxShadow: 'none',
    margin: '0 !important',
    '&:before': { content: 'none' },
  },
  expansionContent: {
    margin: '0 !important',
  },
  categoryColor: {
    width: 8,
    height: 24,
    marginRight: theme.spacing(),
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  subCategories: {
    width: '100%',
    marginLeft: theme.spacing(1.5),
  },
  noData: {
    textAlign: 'center',
    color: theme.palette.common.grey,
  },
}));
