import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

// Icons, Common and Styles
import { numberToReal, revertCurrencyInput, currencyInput } from 'common/utils/numbers';
import styles from './style';

function Freight({ edit, budget, onChange }) {
  const classes = styles();

  if (edit) {
    return (
      <TextField
        value={currencyInput(budget.freight)}
        onChange={(e) => {
          const value = revertCurrencyInput(e.target.value);
          onChange({ ...budget, freight: value });
        }}
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputProps: { className: classes.field },
        }}
      />
    );
  }

  if (budget) return numberToReal(budget.freight);

  return '$ 0,00';
}

Freight.propTypes = {
  edit: PropTypes.bool.isRequired,
  budget: PropTypes.shape({ freight: PropTypes.number }),
  onChange: PropTypes.func.isRequired,
};

Freight.defaultProps = {
  budget: null,
};

export default Freight;
