import Mixpanel from 'services/mixpanel';
import { firestore, ERRORS } from 'services/firebase';
import { Actions as Alert } from 'store/alert/reducer';
import { Actions as Event } from 'store/event/reducer';
import { Actions as Company } from 'store/company/reducer';
import Notification from 'store/notification/controller';
import Supplier from '../controller';

const Product = {
  index: (eventId) => (dispatch) => {
    firestore.collection('events').doc(eventId).collection('products')
      .onSnapshot((ref) => {
        const products = ref.docs.map((p) => ({ id: p.id, ...p.data() }));
        products.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(Event.loadProducts(products));
      }, (error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  indexCompany: (eventId) => async (dispatch, getState) => {
    try {
      const { current: company } = getState().company;

      const events = await firestore.collection('events')
        .where('company', '==', company.id)
        .where('deletedAt', '==', null)
        .get();
      let companyProducts = [];

      for (let i = 0; i < events.docs.length; i += 1) {
        const { id, ref } = events.docs[i];

        // eslint-disable-next-line no-await-in-loop
        const productsRef = await ref.collection('products').get();
        let products = productsRef.docs.map((p) => ({ id: p.id, ...p.data(), event: id }));
        if (id !== eventId) {
          // eslint-disable-next-line no-loop-func
          products = products.filter((s) => !companyProducts.find((a) => a.name === s.name));
          companyProducts = [...companyProducts, ...products];
        } else {
          companyProducts = companyProducts.filter(
            // eslint-disable-next-line no-loop-func
            (p) => !products.find((a) => a.name === p.name),
          );
        }
      }

      companyProducts.sort((a, b) => (a.name > b.name ? 1 : -1));

      dispatch(Company.loadProducts(companyProducts));
    } catch (error) {
      dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
    }
  },

  store: (data) => (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;
    const { supplier, ...product } = data;

    firestore.collection('events').doc(event.id)
      .collection('products').add({ ...product, updatedAt: now, createdAt: now })
      .then((doc) => {
        if (supplier && supplier.id) {
          const {
            contact, name, responsible, register: {
              answered, attachments, cnpj, notes,
            },
          } = supplier;

          Mixpanel.track('Create a exist supplier');
          dispatch(Supplier.store({
            contact,
            name,
            responsible: product.responsible || responsible,
            products: [doc.id],
            register: {
              answered, attachments, cnpj, notes,
            },
          }));
        }

        if (product.responsible) {
          const notify = {
            description: `You have been assigned responsibility for the product ${product.name} in the event ${event.name}`,
            link: `/manager/${event.id}/procurement/items?product=${product.name}`,
            type: 'suppliers',
          };
          dispatch(Notification.store(product.responsible, notify));
        }

        Mixpanel.track('Create a product');
        dispatch(Alert.show({ message: 'Product successfully created.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  update: (id, data) => async (dispatch, getState) => {
    const now = new Date(Date.now());
    const { current: event } = getState().event;

    const { responsible: prevResp } = await firestore.collection('events').doc(event.id)
      .collection('products').doc(id)
      .get()
      .then((doc) => doc.data());

    firestore.collection('events').doc(event.id)
      .collection('products').doc(id)
      .set({ ...data, updatedAt: now }, { merge: true })
      .then(() => {
        if (data.responsible && data.responsible !== prevResp) {
          const notify = {
            description: `You have been assigned responsibility for the product ${data.name} in the event ${event.name}`,
            link: `/manager/${event.id}/procurement/items?product=${data.name}`,
            type: 'suppliers',
          };
          dispatch(Notification.store(data.responsible, notify));
        }

        Mixpanel.track('Update a product');
        dispatch(Alert.show({ message: 'Product updated successfully.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },

  destroy: (id) => (dispatch, getState) => {
    const { current: event } = getState().event;

    firestore.collection('events').doc(event.id)
      .collection('products').doc(id)
      .delete()
      .then(() => {
        Mixpanel.track('Delete a product');
        dispatch(Alert.show({ message: 'Product successfully deleted.', type: 'success' }));
      })
      .catch((error) => {
        dispatch(Alert.show({ message: ERRORS[error.code] || error.message }));
      });
  },
};

export default Product;
