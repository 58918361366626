import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  row: {
    width: 'calc(100% - 130px)',
    height: 'calc(100vh - 60px)',
    marginLeft: 130,
    marginTop: 60,
    padding: `${theme.spacing(2)}px 0`,
  },
  ganttContainer: {
    width: '100%',
    height: '100%',
    padding: '0 48px 32px',
  },
}));
