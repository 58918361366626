import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    textTransform: 'uppercase',
  },
  value: {
    fontSize: 48,
    lineHeight: 1,
    fontWeight: 600,
    color: theme.palette.primary.main,
    '& > span': {
      fontSize: 38,
      paddingLeft: 8,
      fontWeight: 400,
    },
  },
  details: {
    color: theme.palette.secondary.main,
    fontSize: 24,
  },
  iconContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    padding: 22,
    marginRight: 8,
    borderRadius: 50,
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'relative',
      left: 50,
      top: -12,
      height: 6,
      width: 6,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: '10px solid white',
      [theme.breakpoints.down('xs')]: {
        left: 32,
        top: -10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 80,
      padding: 17,
    },
  },
  icon: {
    width: 50,
    height: 50,
    fill: theme.palette.tertiary.main,
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
}));
