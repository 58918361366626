import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// Icons, Common and Styles
import { numberToReal, revertCurrencyInput, currencyInput } from 'common/utils/numbers';
import styles from './style';

function Discount({ edit, budget, onChange }) {
  const classes = styles();

  if (edit) {
    return (
      <TextField
        error={budget.total < 0}
        value={currencyInput(budget.discount.value)}
        onChange={(e) => {
          const discount = { ...budget.discount, value: revertCurrencyInput(e.target.value) };
          onChange({ ...budget, discount });
        }}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <Select
                value={budget.discount.type}
                onChange={(e) => {
                  const discount = { ...budget.discount, type: e.target.value };
                  onChange({ ...budget, discount });
                }}
                style={{ paddingRight: 0 }}
                classes={{ select: classes.discountSelect }}
                className={classes.discountSelect}
              >
                <MenuItem value="money">$</MenuItem>
                <MenuItem value="percent">%</MenuItem>
              </Select>
            </InputAdornment>
          ),
          inputProps: { className: classes.field },
          classes: { root: classes.discountInput },
        }}
      />
    );
  }

  if (budget) {
    return budget.discount.type === 'money'
      ? numberToReal(budget.discount.value)
      : `${budget.discount.value}%`;
  }

  return '$ 0,00';
}

Discount.propTypes = {
  edit: PropTypes.bool.isRequired,
  budget: PropTypes.shape({ discount: PropTypes.object }),
  onChange: PropTypes.func.isRequired,
};

Discount.defaultProps = {
  budget: null,
};

export default Discount;
