import React from 'react';
// import { isSameDay } from 'date-fns';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

// Material UI Components
import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// Controllers
// import Log from 'store/log/controller';

// Icons, Commons and Styles
import { Plus } from 'react-feather';
import Sidebar from 'common/sidebar/home';
import Onboarding from 'common/onboarding';
import Tools from 'app/tools';
import HomeOnboarding from 'assets/home-onboarding.png';
import styles from './style';

// Components
import Card from './card';
import CardDraft from './card/draft';
// import { createTrialEvent } from './trial-data';

function Events() {
  const classes = styles();
  const history = useHistory();
  // const dispatch = useDispatch();

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { events } = useSelector((state) => state.company);
  const { current: { permissions } } = useSelector((state) => state.user);

  const permissionDraft = !permissions || !permissions.events || !permissions.events.drafts
    || permissions.events.drafts.view;

  // const [loadingTrial, setLoadingTrial] = useState(false);
  // const [pastEvents, setPastEvents] = useState([]);
  // const [currentEvents, setCurrentEvents] = useState([]);
  // const [futureEvents, setFutureEvents] = useState([]);

  // useEffect(() => {
  //   if (events) {
  //     const now = new Date();

  //     events.forEach((event) => {
  //       const start = event.dates.start.toDate();
  //       const end = event.dates.end.toDate();

  //       if (isSameDay(start, now) || isSameDay(end, now)) {

  //       }
  //     })
  //   }
  // }, [events]);

  const pastEvents = events.filter((event) => !event.draft && event.dates && event.dates.start.toDate() < new Date());
  const futureEvents = events.filter((event) => !event.draft && event.dates && event.dates.start.toDate() >= new Date());
  const draftEvents = events.filter((event) => event.draft);
  // const currentEvents = events.filter((event) => event.dates.start.toDate() >= new Date());

  return (
    <Grid container spacing={0}>
      <Sidebar actived="/events" />
      <Grid item xs={12} sm={12} md={9} lg={10} className={classes.container}>
        <Grid container justify={downSM ? 'center' : 'space-between'} alignItems="center">
          <Typography variant="h3" className={classes.title}>
            EVENTS
          </Typography>
          {(!permissions || !permissions.events || permissions.events.edit) && (
            <>
            <Button
              color="primary"
              aria-label="Adicionar evento"
              variant="contained"
              className={classes.button}
              onClick={() => history.push('/events/init')}
            >
              <Plus style={{ marginRight: 8 }} strokeWidth={3} />
              New event
            </Button>
          </>
          )}
        </Grid>

        <Grid container className={classes.cards} style={{ marginTop: 16 }}>
          <Typography gutterBottom variant="h6" className={classes.subtitle}>
            UPCOMING
          </Typography>
          {futureEvents
            .filter((e) => !e.deletedAt)
            .sort((a, b) => a.dates.start.toDate() - b.dates.start.toDate())
            .map((event) => (
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={4} alignItems="stretch" key={event.id}>
                <Card event={event} />
              </Grid>
            ))}
          {futureEvents.length === 0 && (
            <>
              <Typography className={classes.empty}>
                Starting
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ margin: '0 8px' }}
                  // disabled={loadingTrial}
                  onClick={() => history.push('/events/init')}
                >
                  your next event
                </Button>
                {/* ou
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ margin: '0 8px' }}
                  disabled={loadingTrial}
                  onClick={async () => {
                    setLoadingTrial(true);
                    await createTrialEvent(company, setLoadingTrial);
                    dispatch(Log.store('Criou um evento para teste'));
                    setLoadingTrial(false);
                  }}
                >
                  {loadingTrial ? (
                    <>
                      {loadingTrial}
                      <CircularProgress size={18} color="primary" style={{ marginLeft: 16 }} />
                    </>
                  ) : 'crie um evento de teste'}
                </Button> */}
              </Typography>
            </>
          )}
        </Grid>

        {draftEvents.length > 0 && permissionDraft && (
          <Grid container className={classes.cards}>
            <Typography gutterBottom variant="h6" className={classes.subtitle}>
              DRAFTS
            </Typography>
            {draftEvents.filter((e) => !e.deletedAt).map((event) => (
              <Grid
                container
                item
                xs={12} sm={12} md={6} lg={4}
                alignItems="stretch"
                key={event.id}
                style={{ opacity: 0.7 }}
              >
                <CardDraft event={event} />
              </Grid>
            ))}
          </Grid>
        )}

        {pastEvents.length > 0 && (
          <Grid container className={classes.cards}>
            <Typography gutterBottom variant="h6" className={classes.subtitle}>
              PREVIOUS
            </Typography>
            {pastEvents
              .filter((e) => !e.deletedAt)
              .sort((a, b) => a.dates.start.toDate() - b.dates.start.toDate())
              .map((event) => (
              <Grid container item xs={12} sm={12} md={6} lg={4} alignItems="stretch" key={event.id}>
                <Card event={event} />
              </Grid>
            ))}
          </Grid>
        )}

        <Onboarding id="home" title="Greetings, human!" image={HomeOnboarding} button="LET'S GO!">
          <p style={{ marginBottom: 16 }}>Dear Earthman, welcome to <b>hybri</b>.</p>
          <p style={{ marginBottom: 16 }}>
            My name is Brian, but you can call me <b>Bri</b>. I came from a far far away galaxy - invited by 
            <a href="https://usevou.com" rel="noopener noreferrer" target="_blank"> Vou</a>,
            to show how easy it is to organize online events on our planet. 
          </p>
          <p style={{ marginBottom: 16 }}>There are three main stages, come close:</p>
          <p style={{ marginBottom: 16 }}>
            <b>- (CO)Create:</b> Create and define how your event ideas, objectives, format and goals.
          </p>
          <p style={{ marginBottom: 16 }}>
            <b>- Plan:</b> Plan the action schedule, project the financial scenario and organize team tasks. 
          </p>
          <p style={{ marginBottom: 16 }}>
            <b>- Manage:</b> Central manage financial and trading management.
          </p>
          <p style={{ marginBottom: 16 }}>I know, I know ... you must be eager to explore further.</p>
          <p style={{ marginBottom: 16 }}>So, <b>let's go!?</b></p>
        </Onboarding>

        <Tools />
      </Grid>
    </Grid>
  );
}

export default Events;
