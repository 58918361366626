import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MaterialUI's Components
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

// Icons and Styles
import UploadDocument from './document';
import ButtonUpload from './button';

function Upload({
  value, inputProps, render, error, helperText, onChange,
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const { defaultDocuments } = inputProps;
    let newFiles = [];
    if (defaultDocuments) {
      newFiles = defaultDocuments.map((f) => ({ ...f, default: true }));
    }

    value.forEach((file) => {
      const exsistFile = newFiles.find((f) => f.label === file.label);
      if (exsistFile) {
        const index = newFiles.indexOf(exsistFile);
        newFiles[index] = file;
      } else {
        newFiles.push({ ...file, default: false });
      }
    });

    setFiles(newFiles);
  }, [value, inputProps]);

  const Documents = (
    <Grid container item xs={12}>
      {files.map((file, index) => (
        <UploadDocument
          key={file.name}
          index={index}
          document={file}
          files={files}
          fileProps={{
            accept: inputProps.formats,
            path: inputProps.path,
            index,
          }}
          onChange={onChange}
        />
      ))}
    </Grid>
  );

  const Button = (
    <ButtonUpload
      buttonProps={inputProps.buttonProps}
      fileProps={{
        multiple: true,
        accept: inputProps.formats,
        path: inputProps.path,
      }}
      documents={files}
      onChange={onChange}
    />
  );

  return (
    <>
      {render && typeof render === 'function'
        ? render(Documents, Button)
        : (
          <Grid container justify="center">
            {Documents}
            {Button}
          </Grid>
        )}
      {((error && typeof error === 'string') || helperText) && (
        <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
      )}
    </>
  );
}

Upload.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  inputProps: PropTypes.shape({
    path: PropTypes.string,
    defaultDocuments: PropTypes.arrayOf(PropTypes.object),
    formats: PropTypes.string,
    buttonProps: PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string,
      hoverButton: PropTypes.bool,
    }),
  }),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
};

Upload.defaultProps = {
  render: null,
  inputProps: {
    path: '/',
    defaultDocuments: [],
    formats: [],
    buttonProps: {},
  },
  helperText: '',
  error: false,
};

export default Upload;
