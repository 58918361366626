export default {
  GA0yHOTPV7LJ6pCWxWdn: {
    type: 'out',
    createdAt: new Date('2020-02-13 18:43:47'),
    color: '#bf7940',
    test: true,
    updatedAt: new Date('2020-02-13 18:43:47'),
    parent: 'suppliers',
    name: 'Infrastructure',
  },
  R3YTZAD1gTcv9s3LTv5i: {
    name: 'Sponsorship',
    type: 'in',
    createdAt: new Date('2020-02-13 18:38:57'),
    color: '#4085bf',
    test: true,
    updatedAt: new Date('2020-02-13 18:38:57'),
    parent: null,
  },
  ZsJUAz4SEFGFdDEC0jHw: {
    name: 'A&B',
    type: 'out',
    createdAt: new Date('2020-02-13 18:43:36'),
    color: '#bbbf40',
    test: true,
    updatedAt: new Date('2020-02-13 18:43:36'),
    parent: 'suppliers',
  },
  gi1CSApS3dGxsUVqVYbx: {
    type: 'out',
    createdAt: new Date('2020-02-13 18:44:39'),
    color: '#4b40bf',
    test: true,
    updatedAt: new Date('2020-02-13 18:44:39'),
    parent: 'suppliers',
    name: 'Tools',
  },
  jbrFWKCk0DFH5elEhNp4: {
    updatedAt: new Date('2020-02-13 18:38:47'),
    parent: null,
    name: 'Tickets',
    type: 'in',
    createdAt: new Date('2020-02-13 18:38:47'),
    color: '#40bf53',
    test: true,
  },
  l4ZemT7d9v84EyyW5Srl: {
    updatedAt: new Date('2020-02-13 18:44:10'),
    parent: 'suppliers',
    name: 'Disclosure',
    type: 'out',
    id: 'l4ZemT7d9v84EyyW5Srl',
    createdAt: new Date('2020-02-13 18:43:55'),
    color: '#d279bf',
    test: true,
  },
  oEqSm0BXSDerrblqn4Cy: {
    type: 'out',
    createdAt: new Date('2020-02-13 18:44:05'),
    color: '#bf4085',
    test: true,
    updatedAt: new Date('2020-02-13 18:44:05'),
    parent: 'suppliers',
    name: 'Decoration',
  },
  suppliers: {
    updatedAt: new Date('2020-02-13 18:43:26'),
    parent: null,
    name: 'Suppliers',
    type: 'out',
    id: 'suppliers',
    color: '#bf4040',
    createdAt: null,
  },
};
