/* eslint-disable no-await-in-loop */
import { firestore } from 'services/firebase';

import Event from './event';
import accounts from './accounts';
import categories from './categories';
import CashFlow from './cashFlow';
import contacts from './contacts';
import products from './products';
import suppliers from './suppliers';

export async function createTrialEvent(company, setLoadingTrial) {
  const id = `trial-event-${company.id}`;
  const eventRef = firestore.collection('events').doc(id);
  const companyRef = firestore.collection('companies').doc(company.id);

  const cashFlow = CashFlow(id);

  setLoadingTrial('Adding bank accounts');
  const accountsId = Object.keys(accounts);
  for (let i = 0; i < accountsId.length; i += 1) {
    const data = accounts[accountsId[i]];
    await companyRef.collection('accounts').doc(accountsId[i]).set(data);
  }

  setLoadingTrial('Adding categories');
  const categoriesId = Object.keys(categories);
  for (let i = 0; i < categoriesId.length; i += 1) {
    const data = categories[categoriesId[i]];
    await companyRef.collection('categories').doc(categoriesId[i]).set(data);
  }

  setLoadingTrial('Creating cash flows');
  const cashFlowId = Object.keys(cashFlow);
  for (let i = 0; i < cashFlowId.length; i += 1) {
    const data = cashFlow[cashFlowId[i]];
    await companyRef.collection('cash-flow').doc(cashFlowId[i]).set(data);
  }

  setLoadingTrial('Adding contacts');
  const contactsId = Object.keys(contacts);
  for (let i = 0; i < contactsId.length; i += 1) {
    const data = contacts[contactsId[i]];
    await companyRef.collection('contacts').doc(contactsId[i]).set(data);
  }

  setLoadingTrial('Creating events');
  await eventRef.set(Event(company.id));

  setLoadingTrial('Adding products');
  const productsId = Object.keys(products);
  for (let i = 0; i < productsId.length; i += 1) {
    const data = products[productsId[i]];
    await eventRef.collection('products').doc(productsId[i]).set(data);
  }

  setLoadingTrial('Adding negotiations');
  const suppliersId = Object.keys(suppliers);
  for (let i = 0; i < suppliersId.length; i += 1) {
    const data = suppliers[suppliersId[i]];
    await eventRef.collection('suppliers').doc(suppliersId[i]).set(data);
  }

  setLoadingTrial('Finishing event creation');
  eventRef.set({ deletedAt: null }, { merge: true });

  return true;
}

export async function destroyTrialEvent(company, setCleaningTrial) {
  const id = `trial-event-${company.id}`;
  const eventRef = firestore.collection('events').doc(id);
  const companyRef = firestore.collection('companies').doc(company.id);

  setCleaningTrial('Searching for data tests');
  const productsTest = await eventRef.collection('products').get()
    .then((snap) => snap.docs.map((doc) => doc.id));
  const suppliersTest = await eventRef.collection('suppliers').get()
    .then((snap) => snap.docs.map((doc) => doc.id));
  const accountsTest = await companyRef.collection('accounts').where('test', '==', true).get()
    .then((snap) => snap.docs.map((doc) => doc.id));
  const cashFlowTest = await companyRef.collection('cash-flow').get()
    .then((snap) => snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  const contactsTest = await companyRef.collection('contacts').where('test', '==', true).get()
    .then((snap) => snap.docs.map((doc) => doc.id));
  const categoriesTest = await companyRef.collection('categories').where('test', '==', true).get()
    .then((snap) => snap.docs.map((doc) => doc.id));

  setCleaningTrial('Removing products');
  for (let i = 0; i < productsTest.length; i += 1) {
    await eventRef.collection('products').doc(productsTest[i]).delete();
  }

  setCleaningTrial('Removing negotiations');
  for (let i = 0; i < suppliersTest.length; i += 1) {
    await eventRef.collection('suppliers').doc(suppliersTest[i]).delete();
  }

  setCleaningTrial('Removing bank accounts');
  for (let i = 0; i < accountsTest.length; i += 1) {
    await companyRef.collection('accounts').doc(accountsTest[i]).delete();
  }

  setCleaningTrial('Removing cash flows');
  const cashFlowReal = [];
  for (let i = 0; i < cashFlowTest.length; i += 1) {
    if (cashFlowTest[i].test) {
      await companyRef.collection('cash-flow').doc(cashFlowTest[i].id).delete();
    } else {
      cashFlowReal.push(cashFlowTest[i]);
    }
  }

  setCleaningTrial('Removing contacts');
  for (let i = 0; i < contactsTest.length; i += 1) {
    await companyRef.collection('contacts').doc(contactsTest[i]).delete();
  }

  setCleaningTrial('Removing categories');
  for (let i = 0; i < categoriesTest.length; i += 1) {
    const existCash = cashFlowReal.find((cash) => cash.categories.indexOf(categoriesTest[i]) >= 0);
    if (!existCash) {
      await companyRef.collection('categories').doc(categoriesTest[i]).delete();
    }
  }

  setCleaningTrial('Removing event');
  await eventRef.delete();

  return true;
}
