import React from 'react';
import { format, isSameDay } from 'date-fns';

// Material UI Components
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// Styles
import { ExternalLink, Users, Mic,Airplay, Target, Check, ArrowLeft } from 'react-feather';
import styles from './style';

function Scope({ data, scope, goBack, goPlan }) {
  const classes = styles();

  const { idealization, persona, contents, format: formatData, goals } = data;

  let date = '';
  if (isSameDay(idealization.dates.start, idealization.dates.end)) {
    date = `Day ${format(idealization.dates.start, 'dd/MM/yyyy')} from ${
      format(idealization.dates.start, 'HH:mm')
    } at ${format(idealization.dates.end, 'HH:mm')}`;
  } else {
    date = `From day ${format(idealization.dates.start, "dd/MM/yyyy ' at 'HH:mm")} to ${
      format(idealization.dates.end, "dd/MM/yyyy ' at 'HH:mm")}`;
  }

  const approachs = {
    speakers: 'Through one or more speakers',
    debate: 'Discussions with guests',
    brainstorming: 'Open discussion with participants',
    owner: 'You will speak or present',
  };

  return (
    <Grid container className={classes.container}>
      <Grid container direction="column">
        <Typography className={classes.label} style={{ fontSize: 16 }}>
          EVENT CONTENT
        </Typography>
        <Typography className={classes.eventName}>
          {idealization.name}
        </Typography>
        <Typography className={classes.date}>{date}</Typography>

        <Typography className={classes.description}>
          {idealization.description}
        </Typography>
        
        {idealization.objective && (
          <>
            <Typography className={classes.label}>OBJECTIVE</Typography>
            <Typography className={classes.value} style={{ marginBottom: 24 }}>
              {idealization.objective}
            </Typography>
          </>
        )}
        
        {idealization.benchmarking.length > 0 && (
          <Grid container direction="column" style={{ marginBottom: 24 }}>
            <Typography className={classes.label}>INSPIRATIONS</Typography>
            {idealization.benchmarking.map((bench) => (
              <>
                {bench.url ? (
                  <Link href={`http://${bench.url}`
                  } target="_blank" color="secondary" className={classes.benchName}>
                    {bench.name}
                    <ExternalLink size={14} style={{ marginLeft: 16, lineHeight: '20px' }} />
                  </Link>
                ) : (
                  <Typography className={classes.benchName}>{bench.name}</Typography>
                )}
                <Typography className={classes.value}>{bench.notes}</Typography>
              </>
            ))}
          </Grid>
        )}
        
        {idealization.learnings && (
          <>
            <Typography className={classes.label}>LEARNINGS</Typography>
            <Typography className={classes.value}>
              {idealization.learnings}
            </Typography>
          </>
        )}

        {/* PERSONA */}
        <Grid container style={{ marginTop: 48, position: 'relative' }}>
          <Users size={40} className={classes.stepTitleIcon} />
          <Typography className={classes.stepTitle}>AUDIENCE</Typography>
        </Grid>
        {persona.map((p) => (
          <Grid container direction="column" style={{ margin: '24px 0' }}>
            <Typography className={classes.label} style={{ fontSize: 18 }}>
              {p.name}
            </Typography>
            <Typography className={classes.value} style={{ marginBottom: 24 }}>
              {p.description}
            </Typography>
            {p.interests && p.interests.length > 0 && (
              <>
                <Typography className={classes.label}>INTERESTS</Typography>
                <Typography className={classes.value} style={{ marginBottom: 24 }}>
                  {p.interests.map((interest) => (
                    <Chip color="secondary" label={interest} style={{ margin: '0 8px 8px 0', fontSize: 16 }} />
                  ))}
                </Typography>
              </>
            )}
            {p.expected && (
              <>
                <Typography className={classes.label}>EXPECTATIONS WITH THE EVENT</Typography>
                <Typography className={classes.value} style={{ marginBottom: 24 }}>
                  {p.expected}
                </Typography>
              </>
            )}
            {p.engagement && (
              <>
                <Typography className={classes.label}>WAYS OF ENGAGEMENT</Typography>
                <Typography className={classes.value} style={{ marginBottom: 24 }}>
                  {p.engagement}
                </Typography>
              </>
            )}
            {p.data && (
              <>
                <Typography className={classes.label}>ADDITIONAL INFORMATIONS</Typography>
                <Typography className={classes.value}>
                  {p.data}
                </Typography>
              </>
            )}
          </Grid>
        ))}

        {/* CONTEÚDOS */}
        <Grid container style={{ margin: '32px 0 24px', position: 'relative' }}>
          <Mic size={40} className={classes.stepTitleIcon} />
          <Typography className={classes.stepTitle}>CONTENT</Typography>
        </Grid>
        {contents.themes && contents.themes.length > 0 && (
          <>
            <Typography className={classes.label}>TOPICS OF GREATEST INTEREST</Typography>
            <Typography className={classes.value} style={{ marginBottom: 24 }}>
              {contents.themes.map((interest) => (
                <Chip color="secondary" label={interest} style={{ margin: '0 8px 8px 0', fontSize: 16 }} />
              ))}
            </Typography>
          </>
        )}
        {contents.approach && (
          <>
            <Typography className={classes.label}>THEME APPROACH</Typography>
            <Typography className={classes.value} style={{ marginBottom: 24 }}>
              {contents.approach !== 'other' ? approachs[contents.approach] : contents.approachComplement}
            </Typography>
          </>
        )}
        {contents.approach === 'speakers' && (
          <>
            <Typography className={classes.label}>IDEAL SPEAKERS</Typography>
            <Typography className={classes.value} style={{ marginBottom: 24 }}>
              {contents.approachComplement}
            </Typography>
          </>
        )}

        {/* FORMATO */}
        <Grid container style={{ marginTop: 32, position: 'relative' }}>
          <Airplay size={40} className={classes.stepTitleIcon} />
          <Typography className={classes.stepTitle}>FORMAT</Typography>
        </Grid>
        <Typography className={classes.value} style={{ margin: '24px 0 0' }}>
          It will be a 
          <b>{formatData.future === 'unique' ? ' unique ' : ' recurrent '}</b>
          event presented in the format of
          <b>{` ${formatData.type} `}</b>
          {formatData.streaming && (
            <>
              streamed by the platform
              <b>{` ${formatData.streaming} `}</b>
            </>
          )}
          {formatData.subscribers && (
            <>
              to
              {formatData.subscribers && formatData.subscribers.indexOf('<') >= 0 ? ' up to ' : ' more than '}
              <b>{`${formatData.subscribers.slice(1)} people `}</b>
            </>
          )}
          and the tickets are
          <b>{formatData.ticket === 'free' ? ' free.' : ' paid.'}</b>
        </Typography>

        {/* METAS */}
        <Grid container style={{ marginTop: 32, position: 'relative' }}>
          <Target size={40} className={classes.stepTitleIcon} />
          <Typography className={classes.stepTitle}>GOALS</Typography>
        </Grid>
        {goals.success && (
          <>
            <Typography className={classes.label} style={{ marginTop: 24 }}>
              How do you define the success of your event?
            </Typography>
            <Typography className={classes.value}>
              {goals.success}
            </Typography>
          </>
        )}
        {goals.successList && goals.successList.length > 0 && (
          <>
            <Typography className={classes.label} style={{ marginTop: 24 }}>
              What goals will help you achieve success?
            </Typography>
            {goals.successList.map((s) => (
              <Grid container wrap="nowrap">
                <Typography className={classes.value} style={{ color: '#4eb1d4' }}>
                  ★
                </Typography>
                <Typography className={classes.value} style={{ margin: '0 8px' }}>
                  {s}
                </Typography>
              </Grid>
            ))}
          </>
        )}
        
        <Grid container alignItems="center" justify="center" style={{ marginTop: 48 }}>
          {!scope && (
            <>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.btnController}
                onClick={goBack}
              >
                <ArrowLeft />
                <p>Back to creation</p>
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.btnController}
                onClick={goPlan}
              >
                <p>Let's plan</p>
                <Check />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Scope;
